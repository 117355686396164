import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAbsentsControllerDelete, useAbsentsControllerFindOne } from '../generated/endpoint';

export const useStudentAbsentDetail = (id: number) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { data: absent, error, isLoading: isGetAbsentLoading } = useAbsentsControllerFindOne(id);

  const { mutate: deleteAbsentMutate, isLoading: isDeleteAbsentLoading } =
    useAbsentsControllerDelete({
      mutation: {
        onSuccess: () => {
          alert('삭제되었습니다');
          history.push('/student/absent');
        },
        onError: (e) => {
          setErrorMessage(e.message);
        },
      },
    });
  const deleteAbsent = () => {
    deleteAbsentMutate({
      id,
    });
  };

  const isLoading = isGetAbsentLoading || isDeleteAbsentLoading;

  return {
    absent,
    error,
    isLoading,
    deleteAbsent,
    errorMessage,
  };
};
