import { useHistory } from 'react-router-dom';

import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { BackButton, Section, TopNavbar } from '../../../reusable';

export const TeacherApplyPage = () => {
  const history = useHistory();
  return (
    <>
      <div className="block md:hidden">
        <TopNavbar title="출결" left={<BackButton />} />

        <Section>
          <div
            className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
            onClick={() => history.push('/teacher/outing')}
          >
            <div className="text-gray-800 font-sfpro font-bold">조퇴/외출/확인증</div>
            <RightArrow />
          </div>
          <div
            className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
            onClick={() => history.push('/teacher/absent')}
          >
            <div className="text-gray-800 font-sfpro font-bold">출결관리</div>
            <RightArrow />
          </div>
        </Section>
      </div>
    </>
  );
};
