import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserControllerResetPassword } from '../generated/endpoint';

export const useResetPassword = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { mutate, isLoading } = useUserControllerResetPassword({
    mutation: {
      onSuccess: () => {
        alert('비밀번호 변경이 완료되었습니다. 다시 로그인해주세요!');
        history.push('/login');
      },
      onError: (err) => {
        setErrorMessage(err.message);
      },
    },
  });

  return { mutate, isLoading, errorMessage };
};
