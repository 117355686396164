import { Blank, Section, Button, Checkbox } from '../../../reusable';
import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Manager } from '../../../types';

interface MatchParams {
  id: string;
}
interface ManagerScheduleDetailPageProps
  extends RouteComponentProps<MatchParams> {
  managers: Manager[];
  userRole: string;
}

export const ManagerScheduleDetailPage: React.FC<
  ManagerScheduleDetailPageProps
> = () => {
  const ref = useRef(null);

  const [changeMode, setChangeMode] = useState(false);
  const [isLoading] = useState(false);

  return (
    <div className="bg-white border rounded-lg py-5 h-screen-14">
      {isLoading && <Blank reversed />}
      <div className="w-auto h-full overflow-scroll">
        <div
          ref={ref}
          className=" bg-white"
          style={{ width: '560px', height: '792px' }}
        >
          <div className=" w-full flex items-end justify-end pt-12"></div>
          <Section
            tw={{ spaceY: 'space-y-6' }}
            style={{ width: '560px', height: '792px' }}
          >
            <div className="w-full text-3xl font-bold min-w-max">타이틀</div>
            <div className="text-lg text-gray-400">2021.10.01(금)</div>
            <div className="flex items-center space-x-2 mb-1">
              <Checkbox id="agree" />
              <label htmlFor="agree">
                <span className="text-lg  cursor-pointer">
                  체험학습 신청 불가
                </span>
              </label>
            </div>
            <div className="px-5 mt-8">
              <span className="font-semibold text-lg"></span>
            </div>
            <div className="flex items-center w-full overflow-x-auto space-x-2 mt-3">
              <Button
                tw={{ width: 'w-full', minWidth: 'min-w-max' }}
                text="수정"
                onClick={() => {
                  setChangeMode(true);
                }}
              />
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-brand-1',
                }}
                text="삭제"
              />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};
