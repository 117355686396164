import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { AbsentAddPage } from './absent/AbsentAddPage';
import { AbsentDetailPage } from './absent/AbsentDetailPage';
import { AbsentPage } from './absent/AbsentPage';
import { ApplyPage } from './absent/ApplyPage';
import { ActivityPage } from './activity/ActivityPage';
import { BoardDetailPage } from './board/BoardDetailPage';
import { CanteenPage } from './canteen/CanteenPage';
import FieldtripAddPage from './fieldtrip/FieldtripAddPage';
import { FieldtripApprovalPage } from './fieldtrip/FieldtripApprovalPage';
import { FieldtripDetailPage } from './fieldtrip/FieldtripDetailPage';
import { FieldtripHomeReportAddPage } from './fieldtrip/FieldtripHomeReportAddPage';
import FieldtripNoticePage from './fieldtrip/FieldtripNoticePage';
import { FieldtripPage } from './fieldtrip/FieldtripPage';
import { FieldtripResultDetailPage } from './fieldtrip/FieldtripResultDetailPage';
import FieldtripSuburbsReportAddPage from './fieldtrip/FieldtripSuburbsReportAddPage';
import { FieldtripSuburbsReportDetailPage } from './fieldtrip/FieldtripSuburbsReportDetailPage';
import { MyPage } from './mypage/MyPage';
import { NewsletterDetailPage } from './newsletter/NewsletterDetailPage';
import { NoticePage } from './notice/NoticePage';
import { OutingAddPage } from './outing/OutingAddPage';
import { OutingDetailPage } from './outing/OutingDetailPage';
import { OutingPage } from './outing/OutingPage';
import { TimetablePage } from './timetable/TimetablePage';
import { ReactComponent as ActivityIconSelected } from '../../assets/svg/bottomview-activity-selected.svg';
import { ReactComponent as ActivityIcon } from '../../assets/svg/bottomview-activity.svg';
import { ReactComponent as CanteenIconSelected } from '../../assets/svg/bottomview-canteen-selected.svg';
import { ReactComponent as CanteenIcon } from '../../assets/svg/bottomview-canteen.svg';
import { ReactComponent as MypageIconSelected } from '../../assets/svg/bottomview-more-selected.svg';
import { ReactComponent as MypageIcon } from '../../assets/svg/bottomview-more.svg';
import { ReactComponent as NoticeIconSelected } from '../../assets/svg/bottomview-notice-selected.svg';
import { ReactComponent as NoticeIcon } from '../../assets/svg/bottomview-notice.svg';
import { ReactComponent as PlannerIconSelected } from '../../assets/svg/bottomview-planner-selected.svg';
import { ReactComponent as PlannerIcon } from '../../assets/svg/bottomview-planner.svg';
import { Role } from '../../generated/model';
import { BottomNavbar, BottomNavbarItem, useAuth, useRecoilValue } from '../../reusable';
import { meStateNew } from '../../store';

export const StudentMainPage = () => {
  const history = useHistory();
  const me = useRecoilValue(meStateNew);
  const { pathname } = useLocation();
  const { authenticated } = useAuth();

  switch (me?.role) {
    case Role.ADMIN:
    case Role.TEACHER:
    case Role.HEAD:
    case Role.PRINCIPAL:
    case Role.SECURITY:
      history.replace('/teacher');
      break;
    case Role.USER:
      if (me?.firstVisit) {
        history.replace('/first-login');
      }
      break;
    case Role.PARENT:
      break;
    default:
      break;
  }

  return (
    <div className="w-full h-screen min-w-full min-h-screen">
      <div className="w-full h-screen-4 overflow-scroll scroll-box">
        <Switch>
          <Route path="/student/board/:id" component={BoardDetailPage} />
          <Route path="/student/newsletter/:id" component={NewsletterDetailPage} />
          <Route path="/student/fieldtrip/notice/:id" component={FieldtripNoticePage} />
          <Route path="/student/fieldtrip/result/:id" component={FieldtripResultDetailPage} />
          <Route
            path="/student/fieldtrip/add/report/suburbs/:id"
            component={FieldtripSuburbsReportAddPage}
          />
          <Route
            path="/student/fieldtrip/add/report/home/:id"
            component={FieldtripHomeReportAddPage}
          />
          <Route
            path="/student/fieldtrip/detail/report/suburbs"
            component={FieldtripSuburbsReportDetailPage}
          />
          <Route path="/student/fieldtrip/add/:type" component={FieldtripAddPage} />
          <Route path="/student/fieldtrip/approve/:id" component={FieldtripApprovalPage} />
          <Route path="/student/fieldtrip/:id" component={FieldtripDetailPage} />
          <Route path="/student/fieldtrip" component={FieldtripPage} />
          <Route path="/student/absent/add" component={AbsentAddPage} />
          <Route path="/student/absent/:id" component={AbsentDetailPage} />
          <Route path="/student/absent" component={AbsentPage} />
          <Route path="/student/apply" component={ApplyPage} />
          <Route path="/student/activity" component={ActivityPage} />
          <Route path="/student/canteen" component={CanteenPage} />
          <Route path="/student/schedule" component={TimetablePage} />
          <Route path="/student/mypage" component={MyPage} />
          <Route path="/student/notice" component={NoticePage} />
          <Route path="/student/outing/add" component={OutingAddPage} />
          <Route path="/student/outing/:id" component={OutingDetailPage} />
          <Route path="/student/outing" component={OutingPage} />
          <Route path="/student">
            <Redirect to="/student/canteen" />
          </Route>
        </Switch>
      </div>

      <BottomNavbar style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
        {me?.role !== Role.PARENT && (
          <BottomNavbarItem
            icon={pathname === '/student/activity' ? ActivityIconSelected : ActivityIcon}
            label="활동"
            twLabel={{
              color: pathname === '/student/activity' ? 'text-gray-800' : 'text-gray-300',
            }}
            twLabelActive={{
              color: pathname === '/student/activity' ? 'text-gray-800' : 'text-gray-300',
            }}
            style={{ fontSize: '11px' }}
            active={true}
            onClick={() => history.push('/student/activity')}
          />
        )}
        <BottomNavbarItem
          icon={pathname === '/student/apply' ? PlannerIconSelected : PlannerIcon}
          label="출결"
          twLabel={{
            color: pathname === '/student/apply' ? 'text-gray-800' : 'text-gray-300',
          }}
          twLabelActive={{
            color: pathname === '/student/apply' ? 'text-gray-800' : 'text-gray-300',
          }}
          style={{ fontSize: '11px' }}
          active={true}
          onClick={() => history.push('/student/apply')}
        />
        <BottomNavbarItem
          icon={pathname === '/student/canteen' ? CanteenIconSelected : CanteenIcon}
          label="일정"
          style={{ fontSize: '11px' }}
          twLabel={{
            color: pathname === '/student/canteen' ? 'text-gray-800' : 'text-gray-300',
          }}
          twLabelActive={{
            color: pathname === '/student/canteen' ? 'text-gray-800' : 'text-gray-300',
          }}
          active={true}
          onClick={() => history.push('/student/canteen')}
        />
        <BottomNavbarItem
          icon={pathname === '/student/notice' ? NoticeIconSelected : NoticeIcon}
          label="공지"
          style={{ fontSize: '11px' }}
          twLabel={{
            color: pathname === '/student/notice' ? 'text-gray-800' : 'text-gray-300',
          }}
          twLabelActive={{
            color: pathname === '/student/notice' ? 'text-gray-800' : 'text-gray-300',
          }}
          active={true}
          onClick={() => history.push('/student/notice')}
        />
        <BottomNavbarItem
          icon={
            pathname === '/student/mypage' || pathname === '/' ? MypageIconSelected : MypageIcon
          }
          label={authenticated ? 'MY' : '로그인'}
          style={{ fontSize: '11px' }}
          twLabel={{
            color: pathname === '/student/mypage' ? 'text-gray-800' : 'text-gray-300',
          }}
          twLabelActive={{
            color: pathname === '/student/mypage' ? 'text-gray-800' : 'text-gray-300',
          }}
          active={true}
          onClick={() => history.push(authenticated ? '/student/mypage' : '/')}
        />
      </BottomNavbar>
    </div>
  );
};
