import { useQueryClient } from 'react-query';

import { useSetRecoilState } from 'recoil';

import { QueryKey } from '../constants/query-key';
import { useUserControllerUpdateMe } from '../generated/endpoint';
import { UpdateUserDto } from '../generated/model';
import { isUpdateMeState } from '../store';

export const useStudentParentMyInfoUpdate = () => {
  const queryClient = useQueryClient();
  const setIsUpdateMe = useSetRecoilState(isUpdateMeState);

  const { mutate: updateMeMutate, isLoading: isUpdateMeLoading } = useUserControllerUpdateMe({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.me);
        setIsUpdateMe(false);
      },
    },
  });

  const handleParentMyInfoUpdate = ({
    name,
    password,
    phone,
    nokName,
    nokPhone,
    birthDate,
  }: Partial<UpdateUserDto>) => {
    const newData = {
      name,
      password,
      phone,
      nokName,
      nokPhone,
      birthDate,
    };
    if (!password) {
      delete newData.password;
    }
    updateMeMutate({ data: newData as UpdateUserDto });
  };

  const handleStudentMyInfoUpdate = ({
    name,
    password,
    phone,
    birthDate,
    hopePath,
    hopeMajor,
  }: Partial<UpdateUserDto>) => {
    const newData = {
      name,
      password,
      phone,
      birthDate,
      hopePath,
      hopeMajor,
    };
    if (!password) {
      delete newData.password;
    }
    updateMeMutate({ data: newData as UpdateUserDto });
  };

  return {
    isLoading: isUpdateMeLoading,
    handleParentMyInfoUpdate,
    handleStudentMyInfoUpdate,
  };
};
