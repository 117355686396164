import { useState } from 'react';
import {
  useSchedulesControllerCreate,
  useSchedulesControllerDelete,
  useSchedulesControllerFindAll,
  useSchedulesControllerUpdate,
} from '../generated/endpoint';
import { makeDateToString } from '../utils';
import { CalendarIdEnum, RequestCreateScheduleDto } from '../generated/model';

export const useTeacherCalendarDetail = () => {
  const [dateRange, setDateRange] = useState<{ startDate: Date; endDate: Date }>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [calendarId, setCalendarId] = useState<CalendarIdEnum>();

  const {
    data: calendarData,
    isLoading: isCalendarLoading,
    refetch: refetchCalendar,
  } = useSchedulesControllerFindAll(
    {
      calendarId,
      startDate: makeDateToString(dateRange?.startDate || new Date()),
      endDate: makeDateToString(dateRange?.endDate || new Date()),
    },
    {
      query: {
        enabled: !!dateRange,
        onError: () => {
          setErrorMessage('일정을 불러오는데 실패했습니다.');
        },
      },
    },
  );

  const { mutateAsync: createCalendar } = useSchedulesControllerCreate({
    mutation: {
      onSuccess: () => {
        refetchCalendar();
      },
      onError: () => {
        setErrorMessage('일정 추가에 실패했습니다.');
      },
    },
  });

  const { mutateAsync: updateCalendar } = useSchedulesControllerUpdate({
    mutation: {
      onSuccess: () => {
        refetchCalendar();
      },
      onError: () => {
        setErrorMessage('일정 수정에 실패했습니다.');
      },
    },
  });

  const { mutate: deleteCalendar } = useSchedulesControllerDelete({
    mutation: {
      onSuccess: () => {
        refetchCalendar();
      },
      onError: () => {
        setErrorMessage('일정 삭제에 실패했습니다.');
      },
    },
  });

  const handleCalendarCreate = (schedule: RequestCreateScheduleDto) => {
    return createCalendar({ data: schedule });
  };

  const handleCalendarUpdate = (scheduleId: number, schedule: RequestCreateScheduleDto) => {
    return updateCalendar({ id: scheduleId, data: schedule });
  };

  const handleCalendarDelete = (scheduleId: number) => {
    deleteCalendar({ id: scheduleId });
  };

  return {
    setDateRange,
    calendarData,
    isCalendarLoading,
    refetchCalendar,
    errorMessage,
    handleCalendarCreate,
    handleCalendarUpdate,
    handleCalendarDelete,
    calendarId,
    setCalendarId,
  };
};
