import { useState } from 'react';

import { createContainer } from './createContainer';
import {
  useGroupsControllerFindAllBySchoolId,
  useSchoolsControllerFindAll,
  useUserControllerFindId,
} from '../generated/endpoint';
import { UserControllerFindIdParams } from '../generated/model';

const initialState = {
  schoolId: 0,
  groupId: 0,
  studentNumber: '',
};

export const useStudentFindIdHook = () => {
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState<UserControllerFindIdParams>(initialState);

  const { schoolId, groupId, studentNumber } = formData;

  const { data: schoolList, isLoading: isSchoolListLoading } = useSchoolsControllerFindAll({});

  const { data: klassList, isLoading: isKlassListLoading } = useGroupsControllerFindAllBySchoolId(
    { 'school-id': schoolId },
    {
      query: {
        enabled: !!schoolId,
      },
    },
  );

  const { data: findIdResult, isLoading: isFindIdResultLoading } = useUserControllerFindId(
    { schoolId, groupId, studentNumber },
    {
      query: {
        queryKey: ['findId'],
        enabled: submitTrigger,
        cacheTime: 0,
        onSuccess: () => {
          setSubmitTrigger(false);
        },
        onError: () => {
          setErrorMessage('입력된 반과 번호에 해당하는 계정을 찾을 수 없습니다.');
          setSubmitTrigger(false);
        },
      },
    },
  );

  const handleFindId = ({ schoolId, groupId, studentNumber }: UserControllerFindIdParams) => {
    if (!schoolId || !groupId || !studentNumber) {
      setErrorMessage('학교, 반, 번호를 모두 입력해주세요.');
      return;
    }
    setSubmitTrigger(true);
  };

  const findId = () => {
    if (!schoolId) {
      alert('학교를 선택해주세요');
      return;
    }
    if (!groupId) {
      alert('학년/반을 선택해주세요');
      return;
    }
    if (!studentNumber) {
      alert('번호를 선택해주세요');
      return;
    }
    handleFindId(formData);
  };

  const handleSchoolChange = (schoolId: number) => {
    setFormData({ schoolId, groupId: 0, studentNumber: '' });
  };

  const handleGroupChange = (groupId: number) => {
    setFormData((prevState) => ({ ...prevState, groupId }));
  };

  const handleStudentNumberChange = (studentNumber: number) => {
    if (studentNumber < 0 || studentNumber > 99) {
      return;
    }
    setFormData((prevState) => ({ ...prevState, studentNumber: studentNumber.toString() }));
  };

  const isLoading = isSchoolListLoading || isKlassListLoading || isFindIdResultLoading;
  const isSubmitButtonDisabled =
    schoolId === 0 || groupId === 0 || studentNumber === '' || isLoading;

  return {
    schoolList: schoolList || [],
    klassList,
    formData,
    errorMessage,
    isLoading,
    findIdResult,
    isSubmitButtonDisabled,
    setErrorMessage,
    findId,
    handleSchoolChange,
    handleGroupChange,
    handleStudentNumberChange,
  };
};

export const StudentFindIdContainer = createContainer(useStudentFindIdHook);
