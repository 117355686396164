import { useStudentActivityControllerUpdate } from '../generated/endpoint';
import { RequestUpdateStudentActivityDto, UploadFileTypeEnum } from '../generated/model';
import { StudentActivity } from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';
import { useImageAndDocumentManagement } from '../hooks/useImageAndDocumentManagement';
import { ImageObject } from '../type';
import { DocumentObject } from '../type/document-file-object';

export const useStudentActivityDetailSubmit = (
  studentActivity: StudentActivity,
  refetch: () => void,
  setReadState: () => void,
  setLoading: (state: boolean) => void,
) => {
  const { mutate: updateStudentActivity } = useStudentActivityControllerUpdate({
    mutation: {
      onSuccess: () => {
        setLoading(false);
        refetch();
        setReadState();
      },
    },
  });

  const {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
  } = useImageAndDocumentManagement({
    initImageObjectMapFn: () => {
      const urlImageMap = new Map<number, ImageObject>();
      studentActivity?.images?.forEach((image, index) => {
        urlImageMap.set(index, { image, isDelete: false });
      });
      return new Map(urlImageMap);
    },
    initDocumentObjectMapFn: () => {
      const urlDocumentMap = new Map<number, DocumentObject>();
      studentActivity?.files?.forEach((file, index) => {
        urlDocumentMap.set(index, { document: file, isDelete: false });
      });
      return new Map(urlDocumentMap);
    },
  });

  const { handleUploadFile } = useFileUpload();

  /**
   * studentActivityId 가 0 이면 새로 생성
   * @param studentActivityId
   * @param data
   */
  const handleStudentActivityUpdate = async (
    studentActivityId: number,
    data: RequestUpdateStudentActivityDto,
  ) => {
    setLoading(true);

    // file image 처리
    const imageFiles = [...imageObjectMap.values()]
      .filter((value) => !value.isDelete && value.image instanceof File)
      .map((value) => value.image) as File[];
    const imageFileNames = await handleUploadFile(
      UploadFileTypeEnum['test/studentactivities/images'],
      imageFiles,
    );

    // url image 처리
    const imageUrlNames = [...imageObjectMap.values()]
      .filter((value) => !value.isDelete && typeof value.image === 'string')
      .map((value) => value.image) as string[];

    const allImageNames = [...imageUrlNames, ...imageFileNames];

    // file document 처리
    const documentFiles = [...documentObjectMap.values()]
      .filter((value) => !value.isDelete && value.document instanceof File)
      .map((value) => value.document) as File[];
    const documentFileNames = await handleUploadFile(
      UploadFileTypeEnum['test/studentactivities/files'],
      documentFiles,
    );

    const documentUrlNames = [...documentObjectMap.values()]
      .filter((value) => !value.isDelete && typeof value.document === 'string')
      .map((value) => value.document) as string[];

    const allDocumentNames = [...documentUrlNames, ...documentFileNames];

    updateStudentActivity({
      id: studentActivityId,
      data: {
        ...data,
        images: allImageNames,
        files: allDocumentNames,
      },
    });
  };

  // test/studentactivities/files
  const uploadFiles = async (files: File[]) => {
    return await handleUploadFile(UploadFileTypeEnum['test/studentactivities/files'], files);
  };

  return {
    imageObjectMap,
    documentObjectMap,
    handleStudentActivityUpdate,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    uploadFiles,
  };
};
