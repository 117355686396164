import { ActivityItem, ErrorBlank } from '../../../components';
import { ActivityFilterEnum } from '../../../constants/activity-filter.enum';
import { useStudentActivity } from '../../../container/student-activity';
import {
  Blank,
  Chip,
  HorizontalScrollView,
  List,
  ListItem,
  Section,
  Tab,
  Tabs,
  TopNavbar,
} from '../../../reusable';

export const ActivityPage = () => {
  const { activities, isError, blankOpen, setBlankOpen, subject, setSubject, filter, setFilter } =
    useStudentActivity();

  if (isError) {
    return <ErrorBlank />;
  }

  return (
    <>
      <TopNavbar
        title="활동"
        left={<div className="w-10 h-15" />}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />

      {blankOpen && <Blank />}
      <Section tw={{ paddingX: 'px-0' }}>
        <HorizontalScrollView>
          <Chip
            text={'전체'}
            onClick={() => setSubject('all')}
            active={!subject}
            tw={{
              paddingY: 'py-2',
              minWidth: 'min-w-max',
              borderRadius: 'rounded-md',
            }}
          />
        </HorizontalScrollView>
      </Section>
      <Tabs>
        <Tab
          full
          text="전체"
          active={filter === ActivityFilterEnum.All}
          onClick={() => setFilter(ActivityFilterEnum.All)}
        />
        <Tab
          full
          text="공지"
          active={filter === ActivityFilterEnum.Notice}
          onClick={() => setFilter(ActivityFilterEnum.Notice)}
        />
        <Tab
          full
          text="과제"
          active={filter === ActivityFilterEnum.Post}
          onClick={() => setFilter(ActivityFilterEnum.Post)}
        />
        <Tab
          full
          text="설문"
          active={filter === ActivityFilterEnum.Survey}
          onClick={() => setFilter(ActivityFilterEnum.Survey)}
        />
        <Tab
          full
          text="마감전"
          active={filter === ActivityFilterEnum.Before}
          onClick={() => setFilter(ActivityFilterEnum.Before)}
        />
      </Tabs>
      <List style={{ marginTop: '0.5rem' }}>
        {activities?.map(
          (a) =>
            a.id && (
              <ActivityItem
                key={a.id}
                id={a.id}
                activity={a}
                subject={a.subject}
                isSubmitted={!!a.studentActivitySubmitted}
              />
            ),
        )}
        {!activities?.length && <ListItem>과제가 없습니다!</ListItem>}
      </List>
    </>
  );
};
