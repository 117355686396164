import { Badge, Blank, utcToLocalDatetime } from '../../reusable';
import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Activity, ActivityType } from '../../generated/model';
import { useActivityControllerFindByGroupId } from '../../generated/endpoint';

interface ActivitiesViewProps {
  groupId: number;
  loadActivitiesView: boolean;
  subjects: any;
  setUpdateState: () => void;
  subject?: string;
}

export const ActivitiesView: FC<ActivitiesViewProps> = ({
  groupId,
  loadActivitiesView,
  setUpdateState,
  subjects,
  subject,
}) => {
  const { pathname } = useLocation();

  const [activities, setActivities] = useState<Activity[]>([]);
  const { isLoading, error, refetch } = useActivityControllerFindByGroupId(groupId, {
    query: {
      onSuccess: (data) => {
        if (subject) {
          data = data.filter((a) => a?.subject === subject);
        } else if (subjects.length > 0) {
          data = data.filter((a) => subjects.includes(a.subject));
        }

        setActivities(data.sort((a, b) => b.id - a.id));
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, loadActivitiesView]);

  if (error) return <div>그룹 데이터를 불러오는 중 오류 발생</div>;

  return (
    <>
      {isLoading && <Blank reversed />}
      {activities?.map((activity) => (
        <div key={activity.id}>
          <div
            className={
              pathname.startsWith(`/teacher/activity/${activity.id}`) ||
              pathname.startsWith(`/teacher/activity/submit/${activity.id}`)
                ? 'px-6 py-4 bg-gray-50'
                : 'px-6 py-4'
            }
          >
            <div className="flex justify-between">
              <Badge
                tw={{
                  backgroundColor: 'bg-light_orange' as any,
                  color: 'text-brand-1',
                }}
              >
                {activity.subject}
              </Badge>
              <div className="text-sm text-gray-500 font-light">
                {activity.createdAt && utcToLocalDatetime(activity.createdAt)}
              </div>
            </div>
            <div className="text-lg font-semibold mt-2">{activity.title}</div>

            {activity?.endDate && (
              <div className="text-sm font-normal text-red-400 space-x-2">
                <span className="font-semibold">마감기한</span>
                <span>
                  {utcToLocalDatetime(activity.endDate)}
                  까지
                </span>
              </div>
            )}
            <div className="mt-5 space-x-3">
              <Link to={`/teacher/activity/${activity.id}`}>
                <button
                  onClick={() => setUpdateState()}
                  className={
                    pathname.startsWith(`/teacher/activity/${activity.id}`)
                      ? 'bg-darkgray text-white rounded-md border border-darkgray text-sm px-4 py-2 focus:outline-none'
                      : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-sm px-4 py-2 focus:outline-none'
                  }
                >
                  상세보기
                </button>
              </Link>
              {activity.type !== ActivityType.NOTICE && (
                <Link to={`/teacher/activity/submit/${activity.id}`}>
                  <button
                    className={
                      pathname.startsWith(`/teacher/activity/submit/${activity.id}`)
                        ? 'bg-darkgray text-white rounded-md border border-darkgray text-sm px-4 py-2 focus:outline-none'
                        : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-sm px-4 py-2 focus:outline-none'
                    }
                  >
                    제출자 보기
                  </button>
                </Link>
              )}

              {activity.type !== ActivityType.NOTICE && (
                <Link to={`/teacher/activity/download/${activity.id}`}>
                  <button
                    className={
                      pathname.startsWith(`/teacher/activity/download/${activity.id}`)
                        ? 'bg-darkgray text-white rounded-md border border-darkgray text-sm px-4 py-2 focus:outline-none'
                        : 'hover:bg-darkgray hover:text-white rounded-md border border-darkgray bg-white text-darkgray text-sm px-4 py-2 focus:outline-none'
                    }
                  >
                    다운로드
                  </button>
                </Link>
              )}
            </div>
          </div>
          <div className="h-0.5 bg-gray-100 w-full" />
        </div>
      ))}
    </>
  );
};
