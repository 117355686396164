import { useState } from 'react';

import { useSummariesControllerDelete, useSummariesControllerUpdate } from '../generated/endpoint';

type Props = {
  id: number;
  recordSummary: string;
  refetch: () => void;
};

export const useTeacherRecordSummaryItem = ({ id, recordSummary, refetch }: Props) => {
  const [errorText, setErrorText] = useState('');

  const { mutate: updateRecordSummaryMutate, isLoading: isUpdateRecordSummaryLoading } =
    useSummariesControllerUpdate({
      mutation: {
        onSuccess: () => {
          setErrorText('');
          refetch();
        },
        onError: (err) => {
          setErrorText(err.message);
        },
      },
    });

  const updateRecordSummary = () => {
    updateRecordSummaryMutate({
      id,
      data: {
        recordSummary,
      },
    });
  };

  const { mutate: deleteRecordSummaryMutate, isLoading: isDeleteRecordSummaryLoading } =
    useSummariesControllerDelete({
      mutation: {
        onSuccess: () => {
          setErrorText('');
          refetch();
        },
        onError: (err) => {
          setErrorText(err.message);
        },
      },
    });

  const deleteRecordSummary = () => {
    deleteRecordSummaryMutate({
      id,
    });
  };

  const isLoading = isUpdateRecordSummaryLoading || isDeleteRecordSummaryLoading;

  return { errorText, updateRecordSummary, deleteRecordSummary, isLoading };
};
