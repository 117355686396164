import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { NoticeAddPage } from './NoticeAddPage';
import { NoticeDetailPage } from './NoticeDetailPage';
import { ErrorBlank, NoticeCard, NoticeTeacherItem } from '../../../components';
import { TeacherNoticeContainer } from '../../../container/teacher-notice';
import { Notice, NoticeCategoryEnum } from '../../../generated/model';
import {
  BackButton,
  Blank,
  Chip,
  HorizontalScrollView,
  List,
  Tab,
  Tabs,
  TopNavbar,
} from '../../../reusable';
import { isUpdateNoticeState, meStateNew } from '../../../store';
import { TabType } from '../../../types';
import { BoardMobilePage } from '../board/BoardMobilePage';
import { NewsletterMobilePage } from '../newsletter/NewsletterMobilePage';

const NoticePageView = () => {
  const { pathname } = useLocation();

  const [tabType, setTabType] = useState<TabType>(TabType.NOTICE);
  const setIsUpdateNotice = useSetRecoilState(isUpdateNoticeState);

  const meRecoil = useRecoilValue(meStateNew);

  const { filteredNoticeList, category, isNoticeListLoading, isNoticeListError, setCategory } =
    TeacherNoticeContainer.useContext();

  useEffect(() => {
    const getLocal = localStorage.getItem('tabType');
    if (!getLocal) {
      return;
    } else if (getLocal === 'BOARD') {
      setTabType(TabType.BOARD);
    } else if (getLocal === 'NOTICE') {
      setTabType(TabType.NOTICE);
    } else if (getLocal === 'NEWSLETTER') {
      setTabType(TabType.NEWSLETTER);
    }
  }, [tabType]);

  const isDetail = !pathname.endsWith('/teacher/notice');

  return (
    <>
      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <div className="md:hidden block">
          <TopNavbar title="공지" left={<BackButton />} />
        </div>
        <Tabs>
          <Tab
            active={tabType === TabType.NOTICE}
            text="공지사항"
            full
            onClick={() => {
              setTabType(TabType.NOTICE);
              localStorage.setItem('tabType', TabType.NOTICE);
            }}
          />
          <Tab
            active={tabType === TabType.BOARD}
            text="학급 게시판"
            full
            onClick={() => {
              setTabType(TabType.BOARD);
              localStorage.setItem('tabType', TabType.BOARD);
            }}
          />
          <Tab
            active={tabType === TabType.NEWSLETTER}
            text="가정통신문"
            full
            onClick={() => {
              setTabType(TabType.NEWSLETTER);
              localStorage.setItem('tabType', TabType.NEWSLETTER);
            }}
          />
        </Tabs>
        {tabType === TabType.NOTICE && (
          <div className="w-full flex-col space-y-2 h-screen-9 overflow-y-auto scroll-box">
            <div className="whitespace-pre-line">
              <List>
                {filteredNoticeList?.map((notice: Notice) => (
                  <NoticeTeacherItem key={notice.id} notice={notice} />
                ))}
              </List>
            </div>
            <div className="w-full h-32" />
          </div>
        )}
        {tabType === TabType.NEWSLETTER && <NewsletterMobilePage />}
        {tabType === TabType.BOARD && <BoardMobilePage />}
      </div>

      {/* Desktop V */}
      {isNoticeListLoading && <Blank reversed />}
      {isNoticeListError && <ErrorBlank />}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="flex justify-between px-6 py-6">
          <h1 className="text-2xl font-semibold">학교 공지사항</h1>
          {meRecoil?.canEditNotice && (
            <Link to="/teacher/notice/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          )}
        </div>
        <div className="w-full space-y-0h-0.5 bg-gray-100"></div>
        <HorizontalScrollView twInner={{ marginY: 'my-4' }}>
          <Chip
            text={'전체'}
            onClick={() => setCategory('전체')}
            active={category === '전체'}
            tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
          />
          {Object.values(NoticeCategoryEnum).map((el) => (
            <Chip
              key={el}
              text={el}
              onClick={() => setCategory(el)}
              tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
              active={el === category}
            />
          ))}
        </HorizontalScrollView>
        <div className="h-screen-5 overflow-y-auto scroll-box">
          {filteredNoticeList?.map((notice: Notice) => (
            <NoticeCard key={notice.id} notice={notice} onClick={() => setIsUpdateNotice(false)} />
          ))}
        </div>
      </div>

      <div className={`bg-gray-50 col-span-4 p-0 h-screen overflow-y-scroll md:p-6 scroll-box`}>
        <Switch>
          <Route path="/teacher/notice/add" component={() => <NoticeAddPage />} />
          <Route path="/teacher/notice/:id" component={() => <NoticeDetailPage />} />
        </Switch>
      </div>
    </>
  );
};

export const NoticePage = () => {
  return (
    <TeacherNoticeContainer.ContextProvider>
      <NoticePageView />
    </TeacherNoticeContainer.ContextProvider>
  );
};
