import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useFieldtripsControllerApprove,
  useFieldtripsControllerGetFieldtripsByTeacher,
} from '../generated/endpoint';
import { FieldtripStatus } from '../generated/model';
import { getEndDate, getStartDate, makeDateToString, MonthAgo } from '../utils';

// SelectMenus의 onChange parameter가 any로 받고 있어서 임시로 생성한 type임
export type SelectMenuFilterType = {
  id: number;
  name: string;
  value: FieldtripStatus | 'ALL';
};

type UseTeacherFieldTripProps = {
  clearSignature: () => void;
  sigPadData: string;
  stampMode: boolean;
  stampImgUrl: string | undefined;
};

export const useTeacherFieldTrip = ({
  clearSignature,
  sigPadData,
  stampMode,
  stampImgUrl,
}: UseTeacherFieldTripProps) => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [startDate, setStartDate] = useState(makeDateToString(MonthAgo(new Date())));
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);
  const [fieldtripId, setFieldtripId] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [filter, setFilter] = useState<SelectMenuFilterType>({
    id: 1,
    name: '모두',
    value: 'ALL',
  });
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const [_studentName, set_studentName] = useState('');
  const limit = Number(params.get('limit') ?? '25') || 25;
  const studentName = params.get('username') || '';

  const {
    data,
    refetch: refetchFieldtrips,
    error,
  } = useFieldtripsControllerGetFieldtripsByTeacher({
    page,
    limit,
    fieldtripStatus: filter?.value === 'ALL' ? undefined : filter.value,
    ...(studentName && { username: studentName }),
    startDate: getStartDate(startDate),
    endDate: getEndDate(endDate),
  });

  const onCompleted = () => {
    setOpen(false);
    clearSignature();
    setLoading(false);
    setErrorMessage('');
    refetchFieldtrips();
  };

  const onError = (error: any) => {
    setOpen(false);
    clearSignature();
    setLoading(false);
    setErrorMessage(error?.message);
    refetchFieldtrips();
  };

  const { mutate: approveFieldtripMutation, mutateAsync: approveFieldtripAsyncMutation } =
    useFieldtripsControllerApprove({
      mutation: {
        onSuccess: onCompleted,
        onError: onError,
      },
    });

  const approveFieldtrip = () => {
    approveFieldtripMutation({
      id: fieldtripId,
      data: {
        signature: stampMode ? stampImgUrl : sigPadData,
      },
    });
  };

  // 일괄 승인하기 버튼
  const approveFieldtrips = async () => {
    if (!data?.items.length) return;

    const approvePromiseList = data?.items.map(({ id }) => {
      return approveFieldtripAsyncMutation({
        id,
        data: {
          signature: stampMode ? stampImgUrl : sigPadData,
        },
      });
    });
    const result = await Promise.allSettled(approvePromiseList);
    result.forEach((settlement) => {
      // 승인 실패하는 경우 Error 번호 Alert으로 표시
      if (settlement.status === 'rejected') {
        const pathElements = settlement.reason.config.url.split('/') ?? []; // url 예시: fieldtrips/approve/22
        const id = pathElements[pathElements.length - 1];
        alert(`ID ${id}번의 승인을 실패하였습니다.\n(${settlement.reason.response.data.message})`);
      }
    });
  };

  return {
    params,
    error,
    errorMessage,
    isLoading,
    setLoading,
    setPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setFilter,
    setOpen,
    setAgreeAll,
    filter,
    set_studentName,
    _studentName,
    data,
    limit,
    page,
    setFieldtripId,
    agreeAll,
    open,

    refetchFieldtrips,
    approveFieldtrip,
    approveFieldtrips,
  };
};
