import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import Viewer from 'react-viewer';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { BackButton, Blank, TopNavbar, useRecoilValue } from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { meStateNew } from '../../../store';
import { makeDateToString, makeMonthDayToString } from '../../../utils';
import { CanteenDetailPage } from './CanteenDetailPage';
import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import { CanteenSubmitPage } from './CanteenSubmitPage';
import { useTeacherCanteen } from '../../../container/teacher-canteen';

export const CanteenPage: FC = () => {
  const me = useRecoilValue(meStateNew);
  const {
    schedulesOrderByDay,
    daysWithSchedule,
    errorMessage,
    selectedDate,
    setSelectedDate,
    selectedCanteen,
    selectedSchedules,
    isLoading,
    refetchCanteen,
  } = useTeacherCanteen();

  const [readState, setReadState] = useState(true);
  const [blankOpen, setBlankOpen] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  if (!me?.id || isLoading) {
    return <Blank />;
  }

  console.log('selectedSchedules', selectedSchedules);
  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        {blankOpen && <Blank />}
        <TopNavbar
          title="일정"
          left={<BackButton />}
          right={
            <div
              className="text-brand-1 text-sm"
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
            >
              새로고침
            </div>
          }
        />

        <div className="px-4 py-4">
          <Calendar
            onChange={(e: any) => {
              setSelectedDate(new Date(e));
            }}
            calendarType="US"
            locale="kr"
            prev2Label={null}
            next2Label={null}
            value={selectedDate}
            className="text-center"
            onActiveStartDateChange={(e) => setSelectedDate(e.activeStartDate)}
            //@ts-ignore
            formatDay={(locale, date) => date.getDate()}
            tileContent={({ date }) => {
              const dateStr = makeDateToString(date);
              const schedules = schedulesOrderByDay[dateStr];
              return (
                <div
                  className="absolute left-1/2 min-w-max flex justify-center space-x-0.5"
                  style={{ transform: 'translateX(-51%)' }}
                >
                  {!!schedules?.length && (
                    <>
                      {!!schedules.find((s) => s.calendarId === '0') && (
                        <div className="rounded-full w-1 h-1 bg-schedule-school bg-opacity-100" />
                      )}
                      {!!schedules.find((s) => s.calendarId === '1') && (
                        <div className="rounded-full w-1 h-1 bg-schedule-teacher bg-opacity-100" />
                      )}
                    </>
                  )}
                </div>
              );
            }}
          />
        </div>
        <div className="bg-gray-50 h-0.5 w-full" />
        <div className="py-4 px-6">
          <div className="text-sm text-grey-5 pb-3">{makeMonthDayToString(selectedDate)}</div>
          <div className="flex flex-col space-y-3">
            {selectedSchedules?.map((schedule: any) => (
              <div id={schedule.id} className="w-full flex space-x-2 items-center">
                <div className="w-2 h-2 rounded-full bg-brand-1" />
                <div className="font-bold">{schedule.title}</div>
              </div>
            ))}
          </div>
        </div>
        {selectedCanteen?.image && (
          <div onClick={() => setImageModalOpen(true)}>
            <div className={`relative pb-3/5 rounded bg-gray-50`}>
              <ExifOrientationImg
                className={`absolute w-full h-full rounded-lg object-cover`}
                src={selectedCanteen.image}
                alt=""
              />
            </div>

            <div className="text-gray-400 text-sm ml-4">이미지를 클릭해서 크게 볼 수 있습니다.</div>
          </div>
        )}
        <div className="absolute">
          {selectedCanteen?.image && (
            <Viewer
              visible={isImageModalOpen}
              rotatable={true}
              noImgDetails={true}
              //drag={false}
              scalable={false}
              images={[
                {
                  src: selectedCanteen.image,
                  alt: '',
                },
              ]}
              onClose={() => setImageModalOpen(false)}
            />
          )}
        </div>
        {selectedCanteen && (
          <div className="flex space-x-2 px-4 py-4 mb-20">
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">중식</div>
              <div className="whitespace-pre-line">{selectedCanteen?.lunch}</div>
            </div>
            <div className="w-full flex-col space-y-2">
              {/* <div className="text-lg font-bold">석식</div> */}
              <div className="text-lg font-bold"></div>
              <div className="whitespace-pre-line">{selectedCanteen?.dinner}</div>
            </div>
          </div>
        )}

        <div className="fixed bottom-16 right-4 bg-grey-8 rounded-full w-16 h-16">
          <Link
            className="w-full h-full flex flex-col justify-center items-center"
            to={'/teacher/v2timetable'}
          >
            <ClockIcon />
            <div className="text-white text-sm">시간표</div>
          </Link>
        </div>
      </div>

      {/* Desktop V */}
      <div className="col-span-6 xl:grid grid-cols-6 hidden md:block">
        {/* {loading && <Blank reversed />} */}
        {errorMessage && <ErrorBlank />}
        <div className="col-span-3 h-screen overflow-y-auto scroll-box">
          <div className="px-6 py-6">
            <Calendar
              onChange={(e: any) => {
                setSelectedDate(new Date(e));
              }}
              calendarType="US"
              prev2Label={null}
              next2Label={null}
              value={selectedDate}
              className="text-center"
              onActiveStartDateChange={(e) => setSelectedDate(e.activeStartDate)}
              //@ts-ignore
              formatDay={(locale, date) => date.getDate()}
              tileContent={({ date }) => {
                const dateStr = makeDateToString(date);
                const schedules = schedulesOrderByDay[dateStr];
                return (
                  <div
                    className="absolute left-1/2 min-w-max flex justify-center space-x-0.5"
                    style={{ transform: 'translateX(-51%)' }}
                  >
                    {!!schedules?.length && (
                      <>
                        {!!schedules.find((s) => s.calendarId === '0') && (
                          <div className="rounded-full w-1 h-1 bg-schedule-school bg-opacity-100" />
                        )}
                        {!!schedules.find((s) => s.calendarId === '1') && (
                          <div className="rounded-full w-1 h-1 bg-schedule-teacher bg-opacity-100" />
                        )}
                      </>
                    )}
                  </div>
                );
              }}
            />
          </div>

          <div className="bg-gray-50 h-0.5 w-full" />
          <div className="py-4 px-6">
            <div className="text-sm text-grey-5 pb-3">{makeMonthDayToString(selectedDate)}</div>
            <div className="flex flex-col space-y-3">
              {selectedSchedules?.map((schedule: any) => (
                <div id={schedule.id} className="w-full flex space-x-2 items-center">
                  <div
                    className={`w-2 h-2 rounded-full ${
                      schedule.calendarId === '1' ? 'bg-schedule-teacher' : 'bg-schedule-school'
                    }`}
                  />
                  <div className="font-bold">{schedule.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="col-span-3 h-screen bg-gray-50 scroll-box ">
          {readState ? (
            <CanteenDetailPage
              selectedDate={selectedDate}
              canteen={selectedCanteen}
              setSubmitState={() => setReadState(false)}
            />
          ) : (
            <CanteenSubmitPage
              selectedDate={selectedDate}
              canteenData={selectedCanteen}
              refetch={() => {
                setReadState(true);
                refetchCanteen();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
