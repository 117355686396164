import React, { useState } from 'react';
import { BackButton, Blank, Section, TopNavbar } from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { TimetableContent } from '../../../types';
import { ReactComponent as UserIcon } from '../../../assets/svg/attendance-user.svg';
import { ReactComponent as KlassIcon } from '../../../assets/svg/attendance-class.svg';
import { useStudentTimetableDetail } from '../../../container/student-timetable-detail';

interface TimetableDetailPageProps {
  setShowTimetablePage: (b: boolean) => void;
}

export const TimetableDetailPage: React.FC<TimetableDetailPageProps> = ({
  setShowTimetablePage,
}) => {
  const { timetable, studentTimetable, error, loading } = useStudentTimetableDetail();

  const [showTimetableDetail, setShowTimetableDetail] = useState(false);
  const [infoTimetableTitle, setinfoTimetableTitle] = useState('');
  const [infoTimetableDetail, setInfoTimetableDetail] = useState<TimetableContent>({
    type: '',
    subject: '',
    teacher: '',
    class: '',
  });
  const [selectedTime, setSelectedTime] = useState('');

  let content = null;
  let contentRow: number;
  const timetableMove = studentTimetable;
  let contentMove: { [key: string]: TimetableContent } = {};

  const timeTableClick = (title: string, info: TimetableContent) => {
    setShowTimetableDetail(info.subject !== '');
    setinfoTimetableTitle(title);
    setInfoTimetableDetail(info);
  };

  try {
    content = JSON.parse(timetable?.content || '[]');
    contentRow = content?.length;

    contentMove = timetableMove?.content ? JSON.parse(timetableMove.content) : {};
  } catch (err) {
    console.error(err);
  }

  const day = new Date().getDay();
  console.log('content', content);
  console.log('contentMove', contentMove);
  return (
    <div className="fixed w-full h-screen inset-0 bg-white overflow-y-scroll scroll-box z-100">
      {loading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        left={
          <div className="h-15">
            <BackButton className="h-15" onClick={() => setShowTimetablePage(false)} />
          </div>
        }
        title="시간표"
      />
      <div className="w-full flex items-center justify-center mt-8">
        <table className="text-center min-w-3/4 mx-auto">
          <thead>
            <tr>
              <td className="" />
              <td className="" />
              <td className="min-w-max  bg-grey-9 rounded-l-xl py-4">월</td>
              <td className="min-w-max  bg-grey-9">화</td>
              <td className="min-w-max  bg-grey-9">수</td>
              <td className="min-w-max  bg-grey-9">목</td>
              <td className="min-w-max bg-grey-9 rounded-r-xl">금</td>
            </tr>
            <td className="pb-4"></td>
          </thead>
          <tbody>
            {content?.map((classtime: any, i: number) => (
              <tr>
                <td
                  key={i + 'NO'}
                  className={` py-2 min-w-9.5 px-1 min-h-10.5 bg-grey-9
                ${i === 0 ? 'rounded-t-xl' : ''} ${i === contentRow - 1 ? 'rounded-b-xl' : ''}`}
                >
                  {i + 1} {}
                </td>
                <div className="px-1"></div>
                <td
                  className={`bg-brand-1 bg-opacity-20  py-2 px-1 min-h-10.5 cursor-pointer ${
                    day === 1 ? 'bg-light_orange bg-opacity-100' : ''
                  } ${classtime.monday.type === 'move' ? 'bg-brand-5' : ''} ${
                    selectedTime === 'mon' + i ? 'text-red-500' : ''
                  }`}
                  onClick={() => {
                    timeTableClick(
                      '월요일' + (i + 1) + '교시',
                      classtime.monday.type === 'move'
                        ? contentMove[`${classtime.monday.subject}`]
                        : classtime.monday,
                    );
                    setSelectedTime('mon' + i);
                  }}
                >
                  {classtime.monday.type === 'move' && contentMove[`${classtime.monday.subject}`]
                    ? contentMove[`${classtime.monday.subject}`]?.subject
                    : classtime.monday.subject}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20  py-2 px-1 min-w-9.5 min-h-10.5 cursor-pointer ${
                    day === 2 ? 'bg-light_orange bg-opacity-100' : ''
                  } ${classtime.tuesday.type === 'move' ? 'bg-brand-5' : ''} ${
                    selectedTime === 'tue' + i ? 'text-red-500' : ''
                  }`}
                  onClick={() => {
                    timeTableClick(
                      '화요일' + (i + 1) + '교시',
                      classtime.tuesday.type === 'move'
                        ? contentMove[`${classtime.tuesday.subject}`]
                        : classtime.tuesday,
                    );
                    setSelectedTime('tue' + i);
                  }}
                >
                  {classtime.tuesday.type === 'move' && contentMove[`${classtime.tuesday.subject}`]
                    ? contentMove[`${classtime.tuesday.subject}`]?.subject
                    : classtime.tuesday.subject}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20  py-2 px-1 min-w-9.5 min-h-10.5 cursor-pointer ${
                    day === 3 ? 'bg-light_orange bg-opacity-100' : ''
                  } ${classtime.wednesday.type === 'move' ? 'bg-brand-5' : ''} ${
                    selectedTime === 'wed' + i ? 'text-red-500' : ''
                  }`}
                  onClick={() => {
                    timeTableClick(
                      '수요일' + (i + 1) + '교시',
                      classtime.wednesday.type === 'move'
                        ? contentMove[`${classtime.wednesday.subject}`]
                        : classtime.wednesday,
                    );
                    setSelectedTime('wed' + i);
                  }}
                >
                  {classtime.wednesday.type === 'move' &&
                  contentMove[`${classtime.wednesday.subject}`]
                    ? contentMove[`${classtime.wednesday.subject}`]?.subject
                    : classtime.wednesday.subject}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20  py-2 px-1 min-w-9.5 min-h-10.5 cursor-pointer ${
                    day === 4 ? 'bg-light_orange bg-opacity-100' : ''
                  } ${classtime.thursday.type === 'move' ? 'bg-brand-5' : ''} ${
                    selectedTime === 'thu' + i ? 'text-red-500' : ''
                  } `}
                  onClick={() => {
                    timeTableClick(
                      '목요일' + (i + 1) + '교시',
                      classtime.thursday.type === 'move'
                        ? contentMove[`${classtime.thursday.subject}`]
                        : classtime.thursday,
                    );
                    setSelectedTime('thu' + i);
                  }}
                >
                  {classtime.thursday.type === 'move' &&
                  contentMove[`${classtime.thursday.subject}`]
                    ? contentMove[`${classtime.thursday.subject}`]?.subject
                    : classtime.thursday.subject}
                </td>
                <td
                  className={`bg-brand-1 bg-opacity-20  py-2 px-1 min-w-9.5 min-h-10.5 cursor-pointer  ${
                    day === 5 ? 'bg-light_orange bg-opacity-100' : ''
                  } ${classtime.friday.type === 'move' ? 'bg-brand-5' : ''} ${
                    selectedTime === 'fri' + i ? 'text-red-500' : ''
                  } `}
                  onClick={() => {
                    timeTableClick(
                      '금요일' + (i + 1) + '교시',
                      classtime.friday.type === 'move'
                        ? contentMove[`${classtime.friday.subject}`]
                        : classtime.friday,
                    );
                    setSelectedTime('fri' + i);
                  }}
                >
                  {classtime.friday.type === 'move' && contentMove[`${classtime.friday.subject}`]
                    ? contentMove[`${classtime.friday.subject}`]?.subject
                    : classtime.friday.subject}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showTimetableDetail && (
        <div className="w-full flex items-center justify-center mt-8">
          <div className="min-w-3/4 rounded-xl border border-grey-5 bg-white px-4">
            <Section>
              {/* <div className="absolute top-5 right-5 cursor-pointer p-3 text-red-500">
                X
              </div> */}

              <div>
                <div className="text-base text-gray-500 flex mb-2">{infoTimetableTitle}</div>
                <div className="text-lg pb-1 font-bold">{infoTimetableDetail?.subject}</div>
              </div>

              <div className="flex items-center space-x-2">
                <UserIcon />
                <div className="text-lg">{infoTimetableDetail?.teacher} 선생님</div>
              </div>

              <div className="flex items-center space-x-2">
                <KlassIcon />
                <div className="text-lg">{infoTimetableDetail?.class}</div>
                {infoTimetableDetail?.type === 'move' ? (
                  <div className="rounded-full px-2.5 py-1.5 bg-blue-50 text-blue-500">
                    이동수업
                  </div>
                ) : (
                  <div className="rounded-full px-2.5 py-1.5 bg-gray-50 text-gray-800">우리반</div>
                )}
              </div>
            </Section>
          </div>
        </div>
      )}
    </div>
  );
};
