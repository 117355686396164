import { TopNavbar } from '../../../reusable';
import React from 'react';

export const TimetablePage = () => {
  return (
    <div>
      <TopNavbar title="일정 관리" right={<div className="w-10 h-15" />} />
    </div>
  );
};
