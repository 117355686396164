import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import FieldtripDetailPage from './FieldtripDetailPage';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import {
  ErrorBlank,
  FieldtripCard,
  SelectMenus,
  SuperModal,
  FrontPagination,
} from '../../../components';
import { SelectMenuFilterType, useTeacherFieldTrip } from '../../../container/teacher-fieldtrip';
import { UserContainer } from '../../../container/user';
import { useSignature } from '../../../hooks/useSignature';
import { useStamp } from '../../../hooks/useStamp';
import { Blank, Button, Section, TextField } from '../../../reusable';
import { isValidDate, makeDateToString } from '../../../utils';

const groups = [
  { id: 1, name: '모두', value: 'ALL' },
  { id: 2, name: '승인 전', value: 'BEFORE_APPROVAL' },
  { id: 3, name: '승인 완료', value: 'PROCESSED' },
  { id: 4, name: '반려됨', value: 'RETURNED' },
];

export const FieldtripMainPage: React.FC = () => {
  const history = useHistory();
  const { canvasRef, sigPadData, clearSignature } = useSignature();

  const { me } = UserContainer.useContext();
  const { stamp, stampImgUrl, stampMode, setStampMode, updateStamp, isUploadStampLoading } =
    useStamp();

  const {
    params,
    error,
    errorMessage,
    isLoading,
    setLoading,
    setPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setFilter,
    setOpen,
    setAgreeAll,
    filter,
    set_studentName,
    _studentName,
    data,
    limit,
    page,
    setFieldtripId,
    agreeAll,
    open,

    refetchFieldtrips,
    approveFieldtrip,
    approveFieldtrips,
  } = useTeacherFieldTrip({ clearSignature, sigPadData, stampMode, stampImgUrl });

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  const onClickApproveByStamp = () => {
    setLoading(true);
    if (!stamp) {
      alert('도장이 등록되어 있지 않습니다.');
      setLoading(false);
    } else {
      if (agreeAll) {
        approveFieldtrips();
      } else {
        approveFieldtrip();
      }
      setStampMode(false);
    }
  };

  return (
    <>
      {error && <ErrorBlank />}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {(isLoading || isUploadStampLoading) && <Blank reversed />}
      <div className="col-span-3 h-screen hidden md:block">
        <div className=" px-6 pb-4 pt-6 overflow-y-scroll scroll-box">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">체험학습 신청서</h1>
          </div>
          <div className="flex space-x-3 my-3 items-center">
            <TextField
              type="date"
              className="cursor-pointer"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div className="flex space-x-2 items-center">
            <div className=" px-2 py-2 min-w-max cursor-pointer">
              <SelectMenus
                allText="모두"
                items={groups}
                onChange={(item: SelectMenuFilterType) => setFilter(item)}
                value={filter}
              ></SelectMenus>
            </div>

            <div className="flex items-center space-x-2 w-full">
              <div className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/fieldtrip`);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      history.replace(`/teacher/fieldtrip?username=${_studentName}`);
                    }
                  }}
                />
              </div>
              <UploadIcon
                className="cursor-pointer"
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    history.replace(`/teacher/fieldtrip?username=${_studentName}`);
                  }
                }}
              />
            </div>
            <Button
              text="일괄 승인하기"
              tw={{
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-auto',
                paddingX: 'px-4',
              }}
              onClick={() => {
                setOpen(true);
                setAgreeAll(true);
              }}
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="h-screen-15 overflow-y-auto scroll-box">
          {data?.items?.map(
            (fieldtrip) => me?.role && <FieldtripCard key={fieldtrip.id} fieldtrip={fieldtrip} />,
          )}
        </div>
        {data && data.total > limit && (
          <div className="grid place-items-center min-w-max">
            <FrontPagination
              basePath="/teacher/fieldtrip"
              total={data.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      {me?.school && (
        <div className="col-span-3 bg-gray-50 p-6">
          <Switch>
            <Route
              path="/teacher/fieldtrip/:id"
              component={() => (
                <FieldtripDetailPage
                  refetchFieldtrips={refetchFieldtrips}
                  setOpen={(b: boolean) => setOpen(b)}
                  setFieldtripId={(n: number) => setFieldtripId(n)}
                  setAgreeAll={(b: boolean) => setAgreeAll(b)}
                  me={me}
                  approvalLine={me.school.approvalLine}
                />
              )}
            />
          </Switch>
        </div>
      )}
      <SuperModal
        modalOpen={open}
        setModalClose={() => {
          clearSignature();
          setOpen(false);
        }}
        width="w-max"
        ablePropragation
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div>
            <div className="text-gray-700 text-xl font-bold">서명란</div>
            <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
          </div>
          <div className="relative">
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            {stampMode ? (
              stampImgUrl ? (
                <div
                  className="absolute inset-0 z-10 rounded overflow-hidden bg-center bg-no-repeat bg-contain"
                  style={{ backgroundImage: `url("${stampImgUrl}")` }}
                ></div>
              ) : (
                <div className="absolute inset-0 z-10 rounded overflow-hidden bg-grey-4">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="min-w-max text-center">도장을 등록해주세요.</div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <label htmlFor="stamp-upload" className="w-1/2">
              <div className="w-full rounded-lg font-bold h-13 px-6 justify-center flex items-center bg-white border border-brandblue-1 text-current cursor-pointer">
                도장등록
              </div>
            </label>
            <input
              id="stamp-upload"
              name="stamp-upload"
              type="file"
              className="sr-only"
              accept=".pdf, .png, .jpeg, .jpg"
              onChange={(e) => {
                if (!e.target?.files) return;
                updateStamp(e.target.files[0]);
                setStampMode(true);
              }}
            />
            {!stampMode ? (
              <Button
                tw={{
                  width: 'w-1/2',
                  backgroundColor: 'bg-brandblue-1',
                }}
                onClick={() => {
                  setStampMode(true);
                  clearSignature();
                }}
              >
                도장 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-1/2',
                  backgroundColor: 'bg-brandblue-1',
                }}
                onClick={onClickApproveByStamp}
              >
                도장으로 승인
              </Button>
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                borderWidth: 'border',
                borderColor: 'border-brand-1',
                color: 'text-current',
                width: 'w-full',
              }}
              onClick={() => clearSignature()}
            >
              사인 다시하기
            </Button>
            {stampMode ? (
              <Button
                tw={{
                  width: 'w-full',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  backgroundColor: 'bg-white',
                  color: 'text-brand-1',
                }}
                onClick={() => setStampMode(false)}
              >
                사인 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-full',
                  backgroundColor: sigPadData ? 'bg-brand-1' : 'bg-brand-5',
                }}
                onClick={() => {
                  if (!sigPadData) {
                    alert('사인 후 승인해 주세요.');
                  } else {
                    setLoading(true);
                    agreeAll ? approveFieldtrips() : approveFieldtrip();
                  }
                }}
              >
                사인으로 승인
              </Button>
            )}
          </div>
        </Section>
      </SuperModal>
    </>
  );
};
