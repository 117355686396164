/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type CalendarIdEnum = typeof CalendarIdEnum[keyof typeof CalendarIdEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarIdEnum = {
  NUMBER_0: '0',
  NUMBER_1: '1',
} as const;
