import React, { FC, useEffect, useState } from 'react';
import { GroupContainer } from '../../../container/group';
import { useLocation } from 'react-router-dom';
import { BackButton, TopNavbar, Select } from '../../../reusable';
import { StudentItem, ErrorBlank } from '../../../components';
import { StudentCardDetailPage } from './StudentCardDetailPage';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';

import { useStudentGroupsControllerFindByGroupId } from '../../../generated/endpoint';
import { StudentGroup } from '../../../generated/model';

export const StudentCardPage: FC = () => {
  const { errorGroups, allKlassGroups } = GroupContainer.useContext();

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(0);
  const [studentGroups, setStudentGroups] = useState<StudentGroup[]>([]);

  const [searchStudentName, setSearchStudentName] = useState('');

  const pathname = useLocation().pathname;
  const selectedUserId = pathname.replace('/teacher/studentcard', '').replace('/', '');

  useStudentGroupsControllerFindByGroupId<StudentGroup[]>(selectedGroupId as number, {
    query: {
      enabled: !!selectedGroupId,
      onSuccess: (res) => {
        if (!res?.length) {
          setStudentGroups([]);
          return;
        }

        setStudentGroups(res.sort((a, b) => a.studentNumber - b.studentNumber));
      },
    },
  });
  let klassName = allKlassGroups.find((item: any) => item?.id === selectedGroupId)?.name || '';

  return (
    <>
      {errorGroups && <ErrorBlank />}

      {/* 모바일, 데스크탑 통합 */}
      <div
        className={`col-span-2 h-screen overflow-y-auto ${
          !selectedUserId || selectedUserId === '' ? '' : 'hidden md:block'
        }`}
      >
        <div className="md:hidden">
          <div className="md:hidden block">
            <TopNavbar title="학생카드" left={<BackButton />} />
          </div>
        </div>
        <div>
          <div className="px-6 py-6">
            <h1 className="text-2xl font-semibold mb-5 hidden md:block">학생카드</h1>

            <div className="flex space-x-2 items-center">
              <div className=" px-2 py-2 min-w-max cursor-pointer">
                <Select
                  value={selectedGroupId}
                  onChange={(e) => {
                    setSelectedGroupId(Number(e.target.value));
                  }}
                  placeholder="반 선택"
                >
                  {allKlassGroups.map((group) => (
                    <option value={group.id}>{group.name}</option>
                  ))}
                </Select>
              </div>
              <div className="flex items-center space-x-2 w-full">
                <form className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
                  <input
                    className="w-full"
                    placeholder="학생이름을 입력해주세요."
                    value={searchStudentName}
                    onChange={(e) => {
                      setSearchStudentName(e.target.value);
                    }}
                  />
                </form>
                <UploadIcon />
              </div>
            </div>
          </div>
          <div className="h-0.5 bg-gray-100"></div>
          <div className="h-screen-15 overflow-y-auto scroll-box pb-20">
            {!studentGroups && <div className="text-center">권한이 없습니다.</div>}
            {studentGroups
              ?.filter(
                (item: any) =>
                  searchStudentName === '' || item?.user?.name.includes(searchStudentName),
              )
              .map((student: any) => (
                <StudentItem
                  studentid={student?.user?.id}
                  studentname={student?.user?.name}
                  klass={klassName}
                  klassnum={student?.studentNumber + '번'}
                />
              ))}
          </div>
        </div>
      </div>

      {selectedUserId && selectedUserId !== '' && (
        <div className={`bg-gray-50 col-span-4 p-0 md:p-6 h-screen overflow-y-scroll  scroll-box`}>
          <StudentCardDetailPage id={selectedUserId} groupId={setSelectedGroupId?.toString()} />
        </div>
      )}
    </>
  );
};
