export class TWCoreProperties {
  alignItems?: string;
  appearance?: string;
  backgroundColor?: string;
  backgroundPosition?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  borderColor?: string;
  borderRadius?: string;
  borderWidth?: string;
  bottom?: string;
  color?: string;
  display?: string;
  fill?: string;
  flex?: string;
  flexDirection?: string;
  flexWrap?: string;
  fontSize?: string;
  fontWeight?: string;
  height?: string;
  inset?: string;
  justifyContent?: string;
  left?: string;
  margin?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginX?: string;
  marginY?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  minWidth?: string;
  outline?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  padding?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingX?: string;
  paddingY?: string;
  placeholderColor?: string;
  position?: string;
  right?: string;
  ring?: string;
  spaceX?: string;
  spaceY?: string;
  textAlign?: string;
  textDecoration?: string;
  top?: string;
  whiteSpace?: string;
  width?: string;
  zIndex?: string;
}

export class TWProperties extends TWCoreProperties {
  disabled?: TWCoreProperties = {};
  focus?: TWCoreProperties = {};
  focusWithin?: TWCoreProperties = {};
}

export class TW extends TWProperties {
  constructor(properties: Partial<TW>) {
    super();
    this.merge(properties);
  }

  merge({ disabled, focus, ...properties }: Partial<TW>) {
    Object.assign(this, properties);
    // @ts-ignore
    Object.assign(this.disabled, disabled);
    // @ts-ignore
    Object.assign(this.focus, focus);
    return this;
  }

  toClassName() {
    return Object.keys(this)
      .map((key) =>
        ['disabled', 'focus', 'focusWithin'].includes(key)
          ? Object.keys(this[key as keyof TW] as TWProperties)
              .map(
                (key2) =>
                  `${kebabize(key)}:${
                    (this[key as keyof TW] as TWProperties)[
                      key2 as keyof TWProperties
                    ]
                  }`,
              )
              .join(' ')
          : (this[key as keyof TWProperties] as string),
      )
      .join(' ');
  }
}

function kebabize(value: string) {
  return value
    .split('')
    .map((letter, i) => {
      return letter.toUpperCase() === letter
        ? `${i !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
}
