export enum AbsentPaperType {
  PARENT = 'parent',
  ABSENT = 'absent',
  IMAGE = 'image',
}

export enum AbsentEvidenceType {
  PARENT = '학부모 확인서',
  OTHER = 'other',
}
