import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { ErrorBlank } from '../../../components';
import { useStudentOuting } from '../../../container/student-outing';
import { UserContainer } from '../../../container/user';
import { BackButton, Blank, Section, TopNavbar } from '../../../reusable';
import { makeDateToString } from '../../../utils';

function getDate(stromg: string) {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[new Date(stromg).getDay()];
  return dayOfWeek + '요일';
}

export const OutingPage: React.FC = () => {
  const { me } = UserContainer.useContext();
  const history = useHistory();
  const { outings, error, isLoading } = useStudentOuting();

  const isNotParent = me?.role !== 'PARENT';

  if (error) {
    return <ErrorBlank />;
  }

  return (
    <div>
      <TopNavbar
        title="조퇴 / 외출 / 확인"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isLoading && <Blank />}
      {error && <ErrorBlank />}

      <Section>
        <div className="pb-12 space-y-4">
          {outings?.map((outing) => {
            return (
              <div
                key={outing.id}
                className="flex justify-between items-center pb-4 border-b border-grey-9 cursor-pointer "
                onClick={() => history.push(`/student/outing/${outing?.id}`)}
              >
                <div>
                  <div className="text-gray-800 font-bold">
                    [{outing?.type}] {outing?.startAt && makeDateToString(new Date(outing.startAt))}{' '}
                    {getDate(outing?.startAt)}
                  </div>
                  <div className="text-gray-600 text-sm">
                    {outing?.reportedAt}{' '}
                    {outing?.outingStatus === 'PROCESSED' ? (
                      '선생님 승인 완료'
                    ) : outing?.outingStatus === 'BEFORE_TEACHER_APPROVAL' ? (
                      '선생님 승인 전'
                    ) : outing?.outingStatus === 'DELETE_APPEAL' ? (
                      <span className="text-red-500 font-bold">삭제 요청</span>
                    ) : (
                      <span className="text-brand-1 font-bold">반려됨</span>
                    )}
                  </div>
                </div>
                <RightArrow />
              </div>
            );
          })}
          {!outings?.length && (
            <div className="w-full h-screen-10 text-center flex items-center justify-center">
              <div className="text-gray-600">
                아직 조퇴/외출/확인증 리스트가 없습니다. <br />
                아래 버튼을 눌러 신청해주세요.
              </div>
            </div>
          )}
        </div>
      </Section>
      {isNotParent && (
        <div className="bottom-16 fixed z-10 w-full px-4">
          <button
            className="bg-brand-1 text-white rounded-lg h-14 px-4 w-full"
            onClick={() => history.push('/student/outing/add')}
          >
            조퇴 / 외출 / 확인 신청하기
          </button>
        </div>
      )}
    </div>
  );
};
