import { useState } from 'react';

import {
  useFieldtripResultControllerDenyResult,
  useFieldtripsControllerFindOne,
  useFieldtripsControllerRequestDelete,
} from '../generated/endpoint';

type UseFieldtripDetailProps = {
  refetchFieldtrips: () => void;
  id: string;
};
export const useTeacherFieldtripResultDetail = ({
  refetchFieldtrips,
  id,
}: UseFieldtripDetailProps) => {
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deny, setDeny] = useState(false);

  const refetchAll = () => {
    refetchFieldtrips();
    return refetchFieldtrip();
  };

  const {
    data: fieldtrip,
    isLoading,
    refetch: refetchFieldtrip,
  } = useFieldtripsControllerFindOne(Number(id));

  const { mutate: denyFieldtripResult } = useFieldtripResultControllerDenyResult({
    mutation: {
      onSuccess: () => {
        setDeny(false);
        setLoading(false);
        return refetchAll();
      },
      onError: () => {
        setLoading(false);
        return refetchAll();
      },
    },
  });

  // 결과보고서 삭제는 신청서 삭제와 동일한 API 사용함
  const { mutate: deleteAppealFieldtripResult } = useFieldtripsControllerRequestDelete({
    mutation: {
      onSuccess: () => {
        setDeleteAppeal(false);
        setLoading(false);
        return refetchAll();
      },
      onError: () => {
        setLoading(false);
        return refetchAll();
      },
    },
  });

  return {
    // api
    denyFieldtripResult,
    deleteAppealFieldtripResult,
    isLoading,
    fieldtrip,

    // state
    deleteAppeal,
    loading,
    deny,

    // set state
    setDeleteAppeal,
    setDeny,
    setLoading,

    // function
    refetchAll,
  };
};
