import { useEffect, useRef } from 'react';

import { AbsentPaper, ParentConfirmPaper } from './';
import { Constants } from '../../constants';
import { Absent } from '../../generated/model';
import { AbsentEvidenceType, AbsentPaperType } from '../types';

interface AbsentPdfProps {
  absent: Absent | any;
  extractReactData: (ref: any, type: AbsentPaperType, absent: Absent) => Promise<null | undefined>;
  extractImageData: (absent: Absent, type: AbsentPaperType) => void;
  nextExtractPdfData: () => void;
  isDownload: boolean;
  approvalLine?: string[];
}

export default function AbsentPdf({
  absent,
  extractReactData,
  extractImageData,
  isDownload,
  nextExtractPdfData,
  approvalLine,
}: AbsentPdfProps) {
  const absentPaperRef = useRef(null);
  const parentConfirmPaperRef = useRef(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const _downloadPdf = async () => {
    if (absentPaperRef.current) {
      await extractReactData(absentPaperRef.current, AbsentPaperType.ABSENT, absent);
      if (absent.evidenceType === AbsentEvidenceType.PARENT && parentConfirmPaperRef.current) {
        await extractReactData(parentConfirmPaperRef.current, AbsentPaperType.PARENT, absent);
      } else if (
        absent.evidenceType !== AbsentEvidenceType.PARENT &&
        absent?.evidenceFiles?.length
      ) {
        console.log('image download');
        await extractImageData(absent, AbsentPaperType.IMAGE);
      } else {
        nextExtractPdfData();
      }
    }
  };

  // 2023년 이전의 결재선은 결재상태와 사인(도장) 여부에 따라 판단
  if (absent?.reportedAt <= '2023-03-01') {
    if (absent?.absentStatus === 'PROCESSED') {
      approvalLine = [];
      if (absent?.teacherSignature) approvalLine?.push('TEACHER');
      if (absent?.preHeadSignature) approvalLine?.push('PREHEAD');
      if (absent?.headSignature) approvalLine?.push('HEAD');
      if (absent?.prePrincipalSignature) approvalLine?.push('PREPRINCIPAL');
      if (absent?.principalSignature) approvalLine?.push('PRINCIPAL');
      if (absent?.viceprincipalSignature) approvalLine?.push('VICEPRINCIPAL');
      if (absent?.headprincipalSignature) approvalLine?.push('HEADPRINCIPAL');
    }
  }

  useEffect(() => {
    if (absent && isDownload) {
      _downloadPdf();
    }
  }, [absent, isDownload]);

  if (!absent) {
    return null;
  }

  return (
    <>
      <AbsentPaper absent={absent} ref={absentPaperRef} approvalLine={approvalLine} />
      {absent.evidenceType === '학부모 확인서' && (
        <ParentConfirmPaper absent={absent} ref={parentConfirmPaperRef} />
      )}
      {absent?.evidenceFiles?.length &&
        absent.evidenceFiles.map(
          (evidenceFile: any) =>
            evidenceFile && (
              <div style={{ width: '560px', height: '792px' }} className="bg-white overflow-hidden">
                <img
                  className="object-cover"
                  ref={imageRef}
                  src={`${Constants.imageUrl}${evidenceFile}`}
                  alt=""
                />
              </div>
            ),
        )}
    </>
  );
}
