import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Linkify from 'react-linkify';
import Viewer from 'react-viewer';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { Badge, Button, Section, utcToLocalDatetime } from '../../../reusable';
import ActivityAddPage from './ActivityAdd';
import { ErrorBlank, FileItem, SuperModal, SurveyComponent } from '../../../components';
import { useTeacherActivityDetail } from '../../../container/teacher-activity-detail';
import { Constants } from '../../../constants';

interface MatchParams {
  id: string;
}

interface ActivityDetailPageProps extends RouteComponentProps<MatchParams> {
  isUpdateState: boolean;
  setUpdateState: (b: boolean) => void;
  refetch: () => void;
}

const ActivityDetailPage: React.FC<ActivityDetailPageProps> = ({
  match,
  isUpdateState,
  setUpdateState,
  refetch,
}) => {
  let { id } = match.params;

  const { activity, images, documents, viewerImages, errorMessage, handleActivityDelete } =
    useTeacherActivityDetail(+id, refetch);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  if (errorMessage) return <ErrorBlank />;

  if (isUpdateState) {
    return (
      <ActivityAddPage
        activityData={{
          ...activity,
          groups: activity?.groupActivities?.map((ga) => ga?.group) || [],
        }}
        refetch={() => {
          refetch();
          setUpdateState(false);
        }}
      />
    );
  }

  return (
    <div className="bg-white border rounded-lg h-screen-3 p-5 m-5 overflow-y-scroll">
      <SuperModal
        modalOpen={isDeleteModalOpen}
        setModalClose={() => setDeleteModalOpen(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            정말 해당 과제를 삭제하시겠습니까?
          </div>
          <Button onClick={() => handleActivityDelete()}>삭제하기</Button>
        </Section>
      </SuperModal>
      <div className="flex justify-between">
        <Badge
          tw={{
            backgroundColor: 'bg-light_orange' as any,
            color: 'text-brand-1',
          }}
        >
          {activity?.subject}
        </Badge>
        <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
          <div className="text-gray-700" onClick={() => setUpdateState(true)}>
            수정
          </div>
          <div className="text-red-400 cursor-pointer" onClick={() => setDeleteModalOpen(true)}>
            삭제
          </div>
        </div>
      </div>
      <div className="space-y-0.5">
        <div className="text-lg font-semibold mt-2">{activity?.title}</div>
        <div className="text-sm text-gray-500 font-light">{activity?.createdAt}</div>
        {activity?.endDate && (
          <div className="text-sm font-normal text-red-400 space-x-2">
            <span className="font-semibold">마감기한</span>
            <span>
              {utcToLocalDatetime(activity?.endDate)}
              까지
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-wrap mt-3">
        {activity?.groupActivities?.map((groupActivity) => (
          <span
            key={groupActivity.id}
            className="rounded-full py-2 px-3 border border-gray-400 font-semibold text-gray-500 text-sm mr-2 mb-2"
          >
            {groupActivity?.group?.name}
          </span>
        ))}
      </div>

      {activity?.type === 'SURVEY' ? (
        <SurveyComponent
          content={activity?.content || '{}'}
          setContent={(data) => console.log(data)}
          btnHide
        />
      ) : (
        <div>
          <Section tw={{ paddingX: 'px-0' }}>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <React.Fragment key={image}>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <ExifOrientationImg
                        className={`absolute w-full h-full rounded object-cover`}
                        src={`${Constants.imageUrl}${image}`}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {documents?.length ? (
              <div>
                {documents?.map((f: any) => (
                  <FileItem file={f} />
                ))}
              </div>
            ) : (
              <></>
            )}

            <div className={`text-gray-500 text-base mt-8 whitespace-pre-line feedback_space`}>
              <Linkify>{activity?.content}</Linkify>
            </div>

            <div className="absolute">
              <Viewer
                visible={hasImagesModalOpen}
                rotatable={true}
                noImgDetails={true}
                //drag={false}
                scalable={false}
                images={viewerImages}
                onClose={() => {
                  setImagesModalOpen(false);
                }}
                activeIndex={activeIndex}
              />
            </div>
          </Section>
        </div>
      )}
    </div>
  );
};

export default withRouter(ActivityDetailPage);
