import { useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  useStudentNewsletterControllerApprove,
  useStudentNewsletterControllerFindOneByUUID,
} from '../generated/endpoint';
import { RequestApproveStudentNewsletterDto } from '../generated/model';

export const useParentStudentNewsletterApproval = (uuid: string) => {
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState('');

  const { data: studentNewsletter, isLoading: isStudentNewsletterLoading } =
    useStudentNewsletterControllerFindOneByUUID(uuid, {
      query: {
        queryKey: ['studentNewsletter', uuid],
      },
    });

  const {
    mutateAsync: approveStudentNewsletterMutateAsync,
    isLoading: isApproveStudentNewsletterLoading,
  } = useStudentNewsletterControllerApprove({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['studentNewsletter', uuid]);
      },
      onError: () => {
        setErrorMessage('제출을 실패했습니다.');
      },
    },
  });

  const approveStudentNewsletter = ({
    uuid,
    data,
  }: {
    uuid: string;
    data: RequestApproveStudentNewsletterDto;
  }) => {
    return approveStudentNewsletterMutateAsync({ uuid, data });
  };

  const isLoading = isStudentNewsletterLoading || isApproveStudentNewsletterLoading;

  return { studentNewsletter, approveStudentNewsletter, isLoading, errorMessage };
};
