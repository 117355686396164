/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type NewsletterCategoryEnum = typeof NewsletterCategoryEnum[keyof typeof NewsletterCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsletterCategoryEnum = {
  기타: '기타',
  교무: '교무',
  연구: '연구',
  학생: '학생',
  학년: '학년',
  정보: '정보',
  행정: '행정',
  교육과정: '교육과정',
  진로진학: '진로진학',
} as const;
