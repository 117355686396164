import React, { useState } from 'react';
import Barcode from 'react-barcode';
import { Link } from 'react-router-dom';

//@ts-ignore
import preval from 'preval.macro';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';

import { StudentIDCard } from '../../../components';
import { Constants } from '../../../constants';
import { useParentGetChildrenInfo } from '../../../container/parent-get-children-info';
import { ResponseUserDto, Role } from '../../../generated/model';
import { Blank, Button, IconButton, TopNavbar, useLogout } from '../../../reusable';
import { meStateNew } from '../../../store';
import { makeDateToString } from '../../../utils';
import { globalEnv } from '../../../utils/global-env';

import 'swiper/swiper.min.css';

export const MyPage = () => {
  const logout = useLogout();

  const meRecoil = useRecoilValue(meStateNew);
  const { childrenInfoList } = useParentGetChildrenInfo();
  const localChildId = +(localStorage.getItem('child-user-id') || '0');

  const [isLoading, setLoading] = useState(false);

  const handleChangeChild = (childId: number) => {
    if (!childId) {
      alert('해당 자녀로 로그인하던 중 문제가 발생했습니다. 잠시 후 다시 시도해주세요');
    }
    localStorage.setItem('child-user-id', JSON.stringify(childId));
  };

  return (
    <div className="overflow-hidden">
      <TopNavbar
        title="MY"
        left={<div className="h-15 w-10"></div>}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setLoading(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />
      {/*{loading && <Blank />}*/}
      {isLoading && <Blank />}

      {meRecoil?.role === Role.PARENT ? (
        <Swiper slidesPerView={1} spaceBetween={10} className="mySwiper">
          {childrenInfoList?.map((child: ResponseUserDto) => (
            <SwiperSlide key={child.id}>
              <div className="card_shadow overflow-hidden w-full">
                <div>
                  <div
                    style={{
                      backgroundColor: '#FAFAFA',
                    }}
                    className="w-full flex flex-col items-center h-80"
                  >
                    <div
                      className="mt-12 mb-2 w-32 h-32 rounded-md bg-white bg-center bg-no-repeat bg-cover cursor-pointer z-10"
                      style={{ backgroundImage: `url(${child?.profile})` }}
                    />

                    <table>
                      <tbody>
                        <tr>
                          <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                            <p>성</p>
                            <p>명</p>
                          </td>
                          <td className="font-bold text-black tracking-widest text-sm">
                            {child?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                            <p>학</p>
                            <p>번</p>
                          </td>

                          <td className="font-bold text-black text-sm">
                            {`${child?.klassGroupName || ''} ${child?.studentNumber || ''}번`}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-xs text-grey-4 pr-3">생년월일</td>
                          <td className="text-black text-sm">
                            {child?.birthDate && makeDateToString(child.birthDate, '.')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="px-6 h-30 flex flex-col justify-center">
                    <div>
                      <div className="flex space-x-2 items-center justify-center font-semibold">
                        {child?.school?.mark && (
                          <IconButton
                            className="w-8 h-8"
                            icon={
                              <img
                                src={`${Constants.imageUrl}${child?.school?.mark}`}
                                alt=""
                                loading="lazy"
                              />
                            }
                          />
                        )}
                        <p className="text-black">{child?.school?.name}</p>
                        {child?.school?.stamp && (
                          <IconButton
                            className="w-8 h-8"
                            icon={
                              <img
                                src={`${Constants.imageUrl}${child?.school?.stamp}`}
                                alt=""
                                loading="lazy"
                              />
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {child?.id === localChildId ? (
                  <Button tw={{ backgroundColor: 'bg-gray-400', width: 'w-full' }} disabled>
                    현재 접속된 자녀
                  </Button>
                ) : (
                  <Button
                    tw={{
                      color: 'text-white',
                      backgroundColor: 'bg-brand-1',
                      width: 'w-full',
                    }}
                    onClick={() => handleChangeChild(child.id)}
                  >
                    자녀 변경
                  </Button>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <>{meRecoil && <StudentIDCard meRecoil={meRecoil} />}</>
      )}

      {meRecoil?.barcode && meRecoil?.role !== Role.PARENT && (
        <div className="w-full min-w-max flex justify-center">
          {/* @ts-ignore */}
          <Barcode value={meRecoil?.barcode || ''} options={{ format: 'CODE128', width: '100%' }} />
        </div>
      )}
      <div className="px-5 py-5 flex flex-col">
        <Link to="/student/info" className="-mx-5 px-5 py-4 font-semibold border-b">
          내 정보
        </Link>

        <Link
          to={`/privacy-policy/${meRecoil?.schoolId}`}
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          개인정보처리방침
        </Link>

        <Link
          to={`/terms-of-use`}
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          이용약관
        </Link>
        <a
          href="https://superstudy.channel.io/lounge"
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          문의하기
        </a>
        <a
          href="https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%95%99%EB%B6%80%EB%AA%A8%EC%9A%A9_2022.10.31.pdf"
          target="blank"
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          사용설명서
        </a>
        <div
          onClick={() => logout()}
          className="-mx-5 px-5 py-4 font-semibold border-b cursor-pointer"
        >
          로그아웃
        </div>

        <div className="my-5 w-full text-sm text-center text-gray-500">
          <div className="text-white">
            v{globalEnv.version} build at{' '}
            {preval`module.exports = new Date().toLocaleString().split("├")[0]`}
          </div>
          {meRecoil?.school.name} <br />
          Copyright 2022. SUPERSCHOOL all right reserved.
        </div>
      </div>
    </div>
  );
};
