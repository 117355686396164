import { useRecoilValue } from 'recoil';

import { UserContainer } from './user';
import { useAbsentsControllerFindAllByStudent } from '../generated/endpoint';
import { childState } from '../store';

export const useStudentAbsent = () => {
  const child = useRecoilValue(childState);
  const { me } = UserContainer.useContext();
  const { data, isLoading, error } = useAbsentsControllerFindAllByStudent({
    request: {
      headers: {
        'child-user-id': child?.id,
      },
    },
  });

  return {
    data,
    isLoading,
    error,
    me,
  };
};
