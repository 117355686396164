import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SignaturePad from 'signature_pad';

import undoArrow from '../../../assets/images/undo-arrow.png';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { ErrorBlank, FieldtripDatePicker, SelectValues } from '../../../components';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { useStudentAbsentAdd } from '../../../container/student-absent-add';
import { UserContainer } from '../../../container/user';
import {
  useAbsentsControllerCreate,
  useAbsentsControllerUpdate,
} from '../../../generated/endpoint';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { useSignature } from '../../../hooks/useSignature';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Checkbox,
  CloseButton,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { Absent, StudentGroup } from '../../../generated/model';
import { isValidDate, makeDateToString, makeTimeToString } from '../../../utils';

const reportType = ['결석', '지각', '조퇴', '결과'];
const descriptionType = ['인정', '질병', '기타', '미인정'];
const overLimitSizeWarning =
  '한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!';

interface AbsentAddPageProps {
  absentData?: Absent;
}

export const AbsentAddPage: React.FC<AbsentAddPageProps> = ({ absentData }) => {
  const history = useHistory();
  const { me } = UserContainer.useContext();

  const { reason: _reasonText = '', reportType: _reportType = '' } = absentData || {};

  const [agree, setAgree] = useState(false);

  const {
    state: {
      reason,
      reasonText,
      report,
      evidenceType,
      evidenceTypeText,
      parentsName,
      parentsPhone,
      startAt,
      endAt,
      description,
      startHour,
      endHour,
      errorMessage,
      isLoading,
      openSignModal,
    },
    setState: {
      setReason,
      setReasonText,
      setReport,
      setEvidenceType,
      setEvidenceTypeText,
      setParentsName,
      setParentsPhone,
      setStartAt,
      setEndAt,
      setDescription,
      setStartMinute,
      setEndMinute,
      setLoading,
      setSignModal,
    },
    signature: { canvasRef, clearSignature },
    desType,
    updateAbsent,
    createAbsent,
    error,
    imageObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    hasNoImageToUpload,
  } = useStudentAbsentAdd(absentData);

  const buttonDisabled =
    !agree ||
    (reason === '학교장 출석인정' || reason === '기타' ? !reasonText : !reason) ||
    !description ||
    (evidenceType === '기타' ? !evidenceTypeText : !evidenceType) ||
    !report ||
    !parentsName ||
    !parentsPhone ||
    !startAt ||
    (report !== '결석' ? !startHour && !endHour : !endAt) ||
    (evidenceType !== '학부모 확인서' && hasNoImageToUpload); // 학부모 확인서가 아닌경우 이미지도 업로드 해야함

  return (
    <>
      {/* {loading && <Blank />} */}
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="출결신고서 작성"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div className="w-full">
          <TextField
            label="학생 이름(본인)"
            id="studentName"
            value={me?.name}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <TextField
            label="학생 학년/반/번호"
            value={me?.klassGroupName + ' / ' + me?.studentNumber + '번'}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <SelectValues
            label="*신고유형"
            placeholder="선택"
            selectValues={reportType}
            value={report}
            onChange={(group) => setReport(group)}
            borderColor={report ? 'border-gray-300' : 'border-red-700'}
          />
          <SelectValues
            placeholder="선택"
            selectValues={descriptionType}
            value={description}
            onChange={(group) => {
              setDescription(group);
              if (!desType[group]?.reasonType?.includes(reason)) {
                setReason('');
              }
              if (!desType[group]?.evidenceFileType?.includes(evidenceType)) {
                setEvidenceType('');
              }
            }}
            borderColor={description ? 'border-gray-300' : 'border-red-700'}
          />
          {/* {description === '기타' && (
            <TextField
              placeholder="신고 유형을 입력해주세요."
              value={descriptionText}
              onChange={(e) => setDescriptionText(e.target.value)}
              tw={{
                marginTop: 'mt-1',
                borderColor: descriptionText
                  ? 'border-gray-300'
                  : 'border-red-700',
              }}
            />
          )} */}
        </div>
        {report === '결석' && (
          <div className="w-full pb-6">
            <label className="mb-1 text-sm text-gray-800">*발생일</label>
            <div className="flex items-center mb-3">
              <input
                type="date"
                lang="ko-KR"
                value={startAt}
                className={`px-4 w-full h-12 border
                 ${startAt ? 'border-gray-300' : 'border-red-700'} rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400 min-w-max`}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  if (!isValidDate(selectedDate)) {
                    return;
                  }
                  if (endAt && selectedDate > new Date(endAt)) {
                    setEndAt(e.target.value);
                  }
                  setStartAt(e.target.value);
                }}
              />
              <span className="flex-shrink-0 ml-3">일 부터</span>
            </div>
            <div className="flex items-center">
              <input
                type="date"
                lang="ko-KR"
                value={endAt}
                min={startAt}
                className={`px-4 w-full h-12 border
                ${endAt ? 'border-gray-300' : 'border-red-700'} rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400 min-w-max`}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  if (!isValidDate(selectedDate)) {
                    return;
                  }
                  if (startAt && selectedDate < new Date(startAt)) {
                    setStartAt(e.target.value);
                  }
                  setEndAt(e.target.value);
                }}
              />
              <span className="flex-shrink-0 ml-3">일 까지</span>
            </div>
          </div>
        )}
        {report !== '결석' && (
          <div className="pb-6">
            <label className="mb-1 text-sm text-gray-800">*발생일</label>
            <input
              type="date"
              lang="ko-KR"
              value={startAt}
              defaultValue={absentData?.startAt}
              className={`${
                startAt ? 'border-gray-300' : 'border-red-700'
              } px-4 w-full h-12 border rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max`}
              onChange={(e) => setStartAt(e.target.value)}
            />
          </div>
        )}
        <div className="w-full">
          <SelectValues
            label="*신고사유 선택"
            placeholder="선택"
            selectValues={desType[description]?.reasonType || []}
            value={reason}
            onChange={(group) => setReason(group)}
            borderColor={reason ? 'border-gray-300' : 'border-red-700'}
            // onChange={(e) => setReason(e.target.value)}
          />
          {(reason === '학교장 출석인정' || reason === '기타') && (
            <TextField
              tw={{
                marginTop: 'mt-1',
                borderColor: reasonText ? 'border-gray-300' : 'border-red-700',
              }}
              placeholder="신고 사유를 입력해주세요."
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
            />
          )}
        </div>
        <div className="w-full"></div>
        <div className="w-full">
          <SelectValues
            label="*증빙서류 선택"
            placeholder="증빙서류를 선택해주세요."
            selectValues={desType[description]?.evidenceFileType || []}
            value={evidenceType}
            onChange={(group) => setEvidenceType(group)}
            borderColor={evidenceType ? 'border-gray-300' : 'border-red-700'}
          />
          {evidenceType === '기타' && (
            <TextField
              placeholder="서류 종류를 입력해주세요."
              value={evidenceTypeText}
              onChange={(e) => setEvidenceTypeText(e.target.value)}
              tw={{
                borderColor: evidenceTypeText ? 'border-gray-300' : 'border-red-700',
              }}
            />
          )}
        </div>

        {evidenceType !== '학부모 확인서' && (
          <>
            <div className="w-full grid grid-flow-row gap-2">
              {[...imageObjectMap]?.map(([key, value]) => (
                <div key={key}>
                  <ImageObjectComponent
                    id={key}
                    imageObjet={value}
                    onDeleteClick={handleImageDeleteToggle}
                  />
                </div>
              ))}
              {/* 이미지 업로드 컴포넌트 */}
              <div>
                <label htmlFor={`image-upload`}>
                  <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                    <div className="absolute w-full h-full rounded object-cover bg-white">
                      <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                        <FileUploadImg />
                        <div className="text-brand-1">이미지를 업로드해주세요!!</div>
                      </div>
                    </div>
                  </div>
                </label>
                <input
                  type="file"
                  id={`image-upload`}
                  className="hidden"
                  accept=".pdf, .png, .jpeg, .jpg"
                  onClick={() => {
                    alert(
                      `진료확인서 및 진단서 내 주민등록번호 정보는 개인정보이므로 꼭 가린 후 업로드 해주세요. (가리지 않을 경우 반려 될 수 있음)
    진료확인서 및 처방전 원본은 반드시 담임선생님께 제출하여 주시기 바랍니다.
                          `,
                    );
                  }}
                  onChange={handleImageAdd}
                />
              </div>
            </div>
          </>
        )}
        <div>
          <div className="text-red-600 text-sm whitespace-pre-line mb-2">
            *민감정보(진료확인서 및 진단서)의 수집/이용/제3자 제공에 동의
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            진료 확인서 등 건강 관련 민감 정보는 소속 학교에 제공되어 출결 관리 목적으로만
            사용됩니다.
          </div>
          <div className="flex items-center space-x-2 mb-1 ">
            <Checkbox id="agree" onChange={() => setAgree(!agree)} checked={agree} />
            <label htmlFor="agree">
              <span className="font-semibold text-lg cursor-pointer ">
                동의하기
                <span className="text-red-600 text-sm whitespace-pre-line mb-2">
                  &nbsp; (체크하지 않으면 다음단계로 넘어가지 않습니다.)
                </span>
              </span>
            </label>
          </div>
        </div>
        {absentData?.absentStatus !== 'RETURNED' && (
          <>
            <div className="w-full">
              <TextField
                // TO-DO merge error
                // disabled={data?.me?.nokName}
                label="보호자 이름"
                value={parentsName}
                onChange={(e) => setParentsName(e.target.value)}
                tw={{
                  borderWidth: parentsName ? 'border' : 'border-2',
                  borderColor: parentsName ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div>

            <div className="w-full">
              <PhoneNumberField
                // TO-DO merge error
                // disabled={
                //   data?.me?.nokPhone !== '' &&
                //   data?.me?.nokPhone !== '010' &&
                //   data?.me?.nokPhone.length >= 10
                // }
                label="보호자 연락처"
                value={parentsPhone || '010'}
                onChange={(e) => setParentsPhone(e.target.value)}
                style={{
                  borderColor: !parentsPhone ? 'rgba(185, 28, 28)' : '',
                }}
              />
            </div>
          </>
        )}

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}

        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-400' : 'bg-brand-1',
          }}
          onClick={() => {
            if (absentData) {
              setLoading(true);
              updateAbsent(imageObjectMap).then(() => window.location.reload());
            } else {
              setSignModal(true);
            }
          }}
        >
          제출하기
        </Button>
      </Section>
      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div className="absolute top-2 right-3" onClick={() => setSignModal(false)}>
            <CloseButton
              onClick={() => {
                setSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  if (parentsPhone === me?.phone) {
                    alert('학생 전화번호와 같은 번호로 신청할 수 없습니다.');
                  } else {
                    setLoading(true);
                    createAbsent(imageObjectMap).then(
                      (res) => res?.id && history.push(`/student/absent/${res.id}`),
                    );
                  }
                }}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>
    </>
  );
};
