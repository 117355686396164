import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useOutingsControllerCreate, useOutingsControllerUpdate } from '../generated/endpoint';
import { Outing, OutingTypeEnum } from '../generated/model';
import { makeDateToString, makeTimeToString } from '../utils';

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

export const useStudentOutingAdd = (outingData: Outing, goDetail: () => void) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [successId, setSuccessId] = useState<number | undefined>();
  const [startAt, setStartAt] = useState(
    outingData ? makeDateToString(new Date(outingData.startAt)) : '',
  );
  const [startHour, setStartHour] = useState(outingData ? getMeridiemHours(outingData.startAt) : 9);
  const [startMinute, setStartMinute] = useState(
    outingData?.startAt ? new Date(outingData.startAt).getMinutes() : 0,
  );
  const [endAt, setEndAt] = useState(
    outingData ? makeDateToString(new Date(outingData.endAt)) : '',
  );
  const [endHour, setEndHour] = useState(outingData ? getMeridiemHours(outingData.endAt) : 16);
  const [endMinute, setEndMinute] = useState(
    outingData?.endAt ? new Date(outingData.endAt).getMinutes() : 40,
  );
  const [report, setReport] = useState<OutingTypeEnum>(
    outingData ? outingData.type : OutingTypeEnum.외출,
  );
  const [reason, setReason] = useState(outingData ? outingData.reason : '');

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    const hour = Number(startHour);
    date.setHours(hour, Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const makeEndAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    if (report === OutingTypeEnum.확인) {
      date = new Date(endAt);
    }
    const hour = Number(endHour);
    date.setHours(hour, Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const { mutate: createOutingMutate, isLoading: isCreateOutingLoading } =
    useOutingsControllerCreate({
      mutation: {
        onError: (e) => {
          setErrorMessage(e.message);
        },
        onSuccess: (res) => {
          if (res?.id) {
            setSuccessId(res?.id);
          } else {
            history.push('/student/outing');
          }
        },
      },
    });

  const createOuting = () => {
    createOutingMutate({
      data: {
        type: report,
        startAt: makeStartAt(),
        endAt: makeEndAt(),
        reason,
      },
    });
  };
  const { mutate: updateOutingMutate, isLoading: isUpdateOutingLoading } =
    useOutingsControllerUpdate({
      mutation: {
        onSuccess: () => {
          goDetail();
        },
        onError: (e) => {
          setErrorMessage(e.message);
        },
      },
    });

  const updateOuting = () => {
    updateOutingMutate({
      id: outingData.id,
      data: {
        type: report,
        startAt: makeStartAt(),
        endAt: makeEndAt(),
        reason,
      },
    });
  };
  const isLoading = isCreateOutingLoading || isUpdateOutingLoading;
  return {
    errorMessage,
    successId,
    startAt,
    setStartAt,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endAt,
    setEndAt,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    report,
    setReport,
    reason,
    setReason,
    isLoading,
    updateOuting,
    createOuting,
  };
};
