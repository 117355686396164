import { FC, useState, useEffect } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher, api } from '../../plugins';
import { useMutation } from '../../reusable';

interface ParentInfoCard {
  studentId: string;
}

export const ParentInfoCard: FC<ParentInfoCard> = ({ studentId }) => {
  const { data } = uQ(`/api/users/parentinfo?studentId=${studentId}`, fetcher);

  const { data: studentData, refetch } = uQ(
    `/api/users/studentinfo?studentId=${studentId}`,
    fetcher,
  );

  const parentUpdate = (parentName: string, parentTel: string) =>
    api({
      method: 'post',
      url: '/api/users/parentUpdate',
      data: {
        studentId,
        parentName,
        parentTel,
      },
    })
      .then(async () => {
        await refetch();
      })
      .catch((err) => {
        console.log('err', err.message);
      });

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold flex justify-between">
        <p>보호자정보카드</p>
      </div>
      <div className="bg-white border rounded-lg pt-3 px-3 relative h-full">
        {!data ? (
          <div className="mb-3 text-center">가입된 보호자가 없습니다.</div>
        ) : (
          data?.map((item: any) => (
            <div
              key={item.id}
              className="bg-white border rounded-lg pb-3 pt-1 px-3 mb-3 relative h-full"
            >
              {studentData?.nokPhone === item.phone ? (
                <div className="text-red-500">주 보호자</div>
              ) : (
                <div className="flex justify-end">
                  <button
                    className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                    onClick={() => {
                      alert(
                        `학생정보의 보호자 전화번호가 ${item?.name} 님의 전화번호로 변경되며, 학생의 결재요청도 ${item?.name}님 에게 보내집니다.`,
                      );

                      parentUpdate(item?.name, item?.phone);
                    }}
                  >
                    주 보호자 지정
                  </button>
                </div>
              )}

              <table className="w-full ">
                <tr>
                  <td className="font-semibold w-32 border-b-2">이름</td>
                  <td className="border-b-2">{item?.name}</td>
                </tr>
                <tr>
                  <td className="font-semibold border-b-2">전화번호</td>
                  <td className="border-b-2">{item?.phone}</td>
                </tr>
                <tr>
                  <td className="font-semibold border-b-2">이메일</td>
                  <td className="border-b-2">{item?.email}</td>
                </tr>
              </table>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
