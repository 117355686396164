import { UserContainer } from './user';
import { useFieldtripsControllerFindOne } from '../generated/endpoint';

export const useStudentFieldtripNotice = (id: number) => {
  const { me } = UserContainer.useContext();
  const { data: fieldtrip, isLoading, error } = useFieldtripsControllerFindOne(id);
  return {
    fieldtrip,
    isLoading,
    error,
    me,
  };
};
