import { useState } from 'react';

import { useUserControllerSendParentSignUp } from '../generated/endpoint';

export const useStudentSendParentSignUp = () => {
  const [enableSendParentSignUp, setEnableSendParentSignUp] = useState(false);
  useUserControllerSendParentSignUp({
    query: {
      enabled: enableSendParentSignUp,
      onSuccess: () => {
        alert('학부모 회원가입 메시지 발송이 완료되었습니다.');
        setEnableSendParentSignUp(false);
      },
      onError: () => {
        alert('메시지 발송 중 오류가 발생하였습니다.');
        setEnableSendParentSignUp(false);
      },
    },
  });

  const handleSendParentSignUp = () => {
    setEnableSendParentSignUp(true);
  };

  return { handleSendParentSignUp };
};
