import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ReactComponent as FileItemIcon } from '../../../assets/svg/file-item-icon.svg';
import { ErrorBlank, Td2 } from '../../../components';
import { Constants } from '../../../constants';
import { useStudentAbsentApprove } from '../../../container/student-absent-approve';
import {
  Blank,
  BottomFixedView,
  Button,
  CloseButton,
  Label,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { makeStartEndToString } from '../../../utils';

interface MatchParams {
  uuid: string;
}

export const AbsentApprovalPage: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { uuid } = match.params;

  const {
    clearSignature,
    canvasRef,
    absent,
    signAbsent,
    isSuccess,
    errorMessage,
    isGetAbsentError,
    comment,
    setComment,
    setSign,
    openSign,
    isLoading,
  } = useStudentAbsentApprove(uuid);

  const student = absent?.student;
  const school = student?.school;
  const approvalLine = school?.approvalLine;

  let isSigned = false;
  if (absent?.parentSignature) {
    isSigned = true;
  }

  const isConfirmType = absent?.evidenceType === '학부모 확인서';

  return (
    <>
      {/* {loading && <Blank />} */}
      {isSuccess && (
        <Blank>
          정상적으로 제출되었습니다.
          <br /> 감사합니다!
        </Blank>
      )}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {isGetAbsentError && (
        <ErrorBlank text={`출결신고서를 불러오는 도중 오류가 발생했습니다. \n UUID : ${uuid}`} />
      )}
      <TopNavbar
        title={isConfirmType ? '학부모 확인서' : '출결사유서'}
        left={<div className="w-10 h-15" />}
      />
      <Section>
        {!isSigned ? (
          <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
            {`[${school?.name || ''} 슈퍼스쿨의 서명 요청]
      ${student?.nokName || ''}님, 귀하의 자녀 ${student?.name || ''} 학생이
      출결신고서를 신청하였습니다.
      내용확인 후 서명을 부탁드립니다.

      서명 요청자 :
      ${school?.name || ''} ${absent?.studentGradeKlass || ''} ${student?.name || ''} 학생

      서명 참여자 :
      ${student?.nokName || ''} 학부모님 (${student?.nokPhone || ''})
      `}
          </div>
        ) : (
          <>
            <div>
              <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
                {`${student?.name || ''} 학생의 출결신청서가 ${
                  student?.nokName || ''
                }님의 서명을 받았습니다.`}
              </div>
              {!isConfirmType && (
                <table>
                  <tr>
                    {approvalLine?.map((role: string) => {
                      switch (role) {
                        case 'TEACHER':
                          return <Td2 style={{ height: '16px' }}>담임</Td2>;
                        case 'HEAD':
                          return <Td2 style={{ height: '16px' }}>학년부장</Td2>;
                        case 'PRINCIPAL':
                          return <Td2 style={{ height: '16px' }}>교무부장</Td2>;
                      }
                    })}
                  </tr>
                  <tr>
                    {approvalLine?.map((role: string) => {
                      switch (role) {
                        case 'TEACHER':
                          return (
                            <Td2>
                              {absent?.teacherSignature ? (
                                <img
                                  style={{ width: '50px', margin: 'auto' }}
                                  src={absent?.teacherSignature}
                                  alt=""
                                />
                              ) : (
                                <div style={{ width: '50px', height: '50px' }} />
                              )}
                            </Td2>
                          );
                        case 'HEAD':
                          return (
                            <Td2>
                              {absent?.headSignature ? (
                                <img
                                  style={{ width: '50px', margin: 'auto' }}
                                  src={absent?.headSignature}
                                  alt=""
                                />
                              ) : (
                                <div style={{ width: '50px', height: '50px' }} />
                              )}
                            </Td2>
                          );
                        case 'PRINCIPAL':
                          return (
                            <Td2>
                              {absent?.principalSignature ? (
                                <img
                                  style={{ width: '50px', margin: 'auto' }}
                                  src={absent?.principalSignature}
                                  alt=""
                                />
                              ) : (
                                <div style={{ width: '50px', height: '50px' }} />
                              )}
                            </Td2>
                          );
                      }
                    })}
                  </tr>
                </table>
              )}
            </div>
            <div className="h-0.5 bg-gray-200" />
          </>
        )}
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">학생 이름</div>
          <div className="w-2/3 text-gray-500">{student?.name}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">학번</div>
          <div className="w-2/3 text-gray-500">
            {absent?.studentGradeKlass} {absent?.studentNumber}번
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">신고유형</div>
          <div className="w-2/3 text-gray-500">{absent?.reportType}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">기간</div>
          <div className="w-2/3 text-gray-500">
            {absent?.startAt &&
              absent?.endAt &&
              absent?.reportType &&
              makeStartEndToString(absent.startAt, absent.endAt, absent.reportType)}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">사유</div>
          <div className="w-2/3 text-gray-500">{absent?.reason}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">증빙서류</div>
          <div className="w-2/3 text-gray-500">{absent?.evidenceType}</div>
        </div>

        {!isConfirmType && absent?.evidenceFiles?.length && (
          <div>
            <div className="text-gray-800 flex-shrink-0">증빙서류 파일</div>
            {absent.evidenceFiles.map((evidenceFile: string) => (
              <div
                className={`m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden`}
              >
                <div className="flex space-x-2 items-center">
                  <FileItemIcon />
                  <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                    <a
                      href={`${Constants.imageUrl}${evidenceFile}`}
                      target="_blank"
                      rel="noreferrer"
                      download={true}
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">보호자 이름</div>
          <div className="w-2/3 text-gray-500">{student?.nokName}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">보호자 연락처</div>
          <div className="w-2/3 text-gray-500">{student?.nokPhone}</div>
        </div>

        {isConfirmType && (
          <>
            <div className="whitespace-pre-line">
              *{student?.name} 학생이{' '}
              {absent?.startAt &&
                absent?.endAt &&
                absent?.reportType &&
                makeStartEndToString(absent.startAt, absent.endAt, absent.reportType)}{' '}
              동안 학교에 미출석함을 확인{isSigned ? '합' : '하였습'}니다.
            </div>
          </>
        )}

        {isSigned && (
          <div className="w-full flex flex-col items-end">
            <div className="min-w-max font-bold mt-4 text-right">
              보호자: {student?.nokName} (인)
            </div>
            <img
              src={absent?.parentSignature ?? undefined}
              alt=""
              style={{ width: '100px', marginTop: '0.5rem' }}
            />
          </div>
        )}

        <div className="whitespace-pre-line pb-12">
          {`*개인정보 수집 및 이용 동의
- 신고자는 학생 본인입니다.
- 출결 신고서는 학교에 보관됩니다.
- 출결 신고서(증빙서류를 포함)를 제출하여, 사유 발생 5일 이내에 전결 승인까지 받아야 인정됩니다.`}
        </div>
        {isConfirmType && (
          <>
            <TextField
              label="학부모 확인서 내용 등록"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="학생의 상태를 구체적으로 적어주세요."
              tw={{
                backgroundColor: isSigned ? 'bg-gray-100' : 'bg-white',
                borderColor: comment ? 'border-gray-300' : 'border-red-700',
                borderWidth: 'border',
              }}
              disabled={!!isSigned}
            />
            <Label>
              예시) 어젯밤부터 감기 몸살 증상이 보여 약을 먹었으나 나아지지 않아 가정에서 안정을
              찾기 위해 결석하였습니다.
              <br />
              <br />
            </Label>
          </>
        )}

        {isSigned ? (
          <>
            <div className="text-gray-500 text-sm">승인 요청되었습니다.</div>
            <Button
              tw={{
                width: 'w-full',
                backgroundColor: 'bg-gray-500',
              }}
              disabled={isConfirmType ? !comment : false}
            >
              사인 다시하기
            </Button>
          </>
        ) : (
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => setSign(true)}
            disabled={isConfirmType ? !comment : false}
          >
            내용 확인하고 서명하기
          </Button>
        )}
      </Section>
      <div className={openSign ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div className="absolute top-2 right-3" onClick={() => setSign(false)}>
            <CloseButton
              onClick={() => {
                setSign(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학부모 서명란</div>
              <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  signAbsent();
                }}
                disabled={isLoading}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
        </BottomFixedView>
      </div>
    </>
  );
};
