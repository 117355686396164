/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type NewsletterType = typeof NewsletterType[keyof typeof NewsletterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NewsletterType = {
  NOTICE: 'NOTICE',
  STUDENT_PARENTS_SURVEY: 'STUDENT_PARENTS_SURVEY',
} as const;
