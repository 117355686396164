import { SelectValues } from '../';
import { Button, Checkbox, CloseButton, Radio, Section, TextField } from '../../reusable';

interface SuperSurveyAddComponentProps {
  setContent: (content: any) => void;
  content: any;
}

const questionTypes = [
  { value: 'text', text: '입력형' },
  { value: 'checkbox', text: '선택형(다중선택형)' },
  { value: 'radiobutton', text: '선택형(1개선택형)' },
];

export const SuperSurveyAddComponent = ({ setContent, content }: SuperSurveyAddComponentProps) => {
  const addContent = () => {
    const _content = JSON.parse(JSON.stringify(content));
    let id = 1;
    _content?.forEach((c: any) => (c.id >= id ? (id = c.id + 1) : ''));
    _content.push({ type: 'text', title: '', id });
    setContent(_content);
  };

  const deleteContent = (id: number) => {
    let _content = JSON.parse(JSON.stringify(content));
    _content = _content.filter((q: any) => q.id !== id);
    setContent(_content);
  };

  return (
    <Section>
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">설문 등록</div>
        <Button text="설문 추가하기 +" onClick={addContent} />
      </div>
      {content?.map((question: any, i: number) => {
        const value = question.title;
        const onChangeType = (value: string) => {
          const _content = JSON.parse(JSON.stringify(content));
          _content[i].type = value;
          if (_content[i].type === 'text') {
            delete _content[i].choices;
          } else if (!_content[i].choices) {
            _content[i].choices = ['', '', '', ''];
          }
          setContent(_content);
        };
        const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
          const _content = JSON.parse(JSON.stringify(content));
          _content[i].title = e.target.value;
          setContent(_content);
        };
        const onChangeChoices = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
          const _content = JSON.parse(JSON.stringify(content));
          _content[i].choices[index] = e.target.value;
          setContent(_content);
        };

        const deleteChoice = (index: number) => {
          const _content = JSON.parse(JSON.stringify(content));
          _content[i].choices.splice(index, 1);
          setContent(_content);
        };
        const addChoice = () => {
          const _content = JSON.parse(JSON.stringify(content));
          _content[i].choices.push('');
          setContent(_content);
        };
        switch (question.type) {
          case 'text':
            return (
              <div className="w-full flex items-center justify-between space-x-4" key={question.id}>
                <TextField
                  label="입력형"
                  onChange={onChangeTitle}
                  value={value}
                  placeholder="제목을 입력해주세요."
                  twOuter={{ width: 'w-full' }}
                />
                <div className="min-w-max flex space-x-2 items-center">
                  <SelectValues
                    selectValues={questionTypes}
                    onChange={onChangeType}
                    value={question.type}
                  />
                  <CloseButton onClick={() => deleteContent(question.id)} />
                </div>
              </div>
            );
          case 'checkbox':
            return (
              <div className="w-full flex items-center justify-between space-x-8" key={question.id}>
                <div className="w-full flex flex-col space-y-2 max-w-md">
                  <TextField
                    label="선택형(다중선택형)"
                    onChange={onChangeTitle}
                    value={value}
                    placeholder="제목을 입력해주세요."
                    twOuter={{ width: 'w-full' }}
                  />
                  {question?.choices?.map((c: any, index: number) => (
                    <div key={index} className="flex items-center justify-between space-x-2">
                      <Checkbox />
                      <TextField onChange={(e) => onChangeChoices(e, index)} value={c} />
                      {question?.choices?.length > 1 && (
                        <CloseButton onClick={() => deleteChoice(index)} />
                      )}
                    </div>
                  ))}
                  <div
                    className="flex justify-center space-x-2 bg-gray-100 items-center hover:bg-brand-5 cursor-pointer py-2 rounded-lg"
                    onClick={addChoice}
                  >
                    <div className="text-2xl flex items-center justify-center text-brand-1 rounded-full border-2 border-brand-1 w-6 h-6 pb-0.5">
                      +
                    </div>
                    <div className="text-brand-1">답변 추가</div>
                  </div>
                </div>
                <div className="min-w-max flex space-x-2 items-center">
                  <SelectValues
                    selectValues={questionTypes}
                    onChange={onChangeType}
                    value={question.type}
                  />
                  <CloseButton onClick={() => deleteContent(question.id)} />
                </div>
              </div>
            );
          case 'radiobutton':
            return (
              <div className="w-full flex items-center justify-between space-x-8" key={question.id}>
                <div className="w-full flex flex-col space-y-2 max-w-md">
                  <TextField
                    label="선택형(1개선택형)"
                    onChange={onChangeTitle}
                    value={value}
                    placeholder="제목을 입력해주세요."
                    twOuter={{ width: 'w-full' }}
                  />
                  {question?.choices?.map((c: any, index: number) => (
                    <div key={index} className="flex items-center justify-between space-x-2">
                      <Radio />
                      <TextField onChange={(e) => onChangeChoices(e, index)} value={c} />
                      {question?.choices?.length > 1 && (
                        <CloseButton onClick={() => deleteChoice(index)} />
                      )}
                    </div>
                  ))}
                  <div
                    className="flex justify-center space-x-2 bg-gray-100 items-center hover:bg-brand-5 cursor-pointer py-2 rounded-lg"
                    onClick={addChoice}
                  >
                    <div className="text-2xl flex items-center justify-center text-brand-1 rounded-full border-2 border-brand-1 w-6 h-6 pb-0.5">
                      +
                    </div>
                    <div className="text-brand-1">답변 추가</div>
                  </div>
                </div>
                <div className="min-w-max flex space-x-2 items-center">
                  <SelectValues
                    selectValues={questionTypes}
                    onChange={onChangeType}
                    value={question.type}
                  />
                  <CloseButton onClick={() => deleteContent(question.id)} />
                </div>
              </div>
            );
        }
      })}
      <div
        className="w-full flex justify-center rounded-lg bg-gray-100 items-center py-3 hover:bg-brand-5 cursor-pointer"
        onClick={addContent}
      >
        <div className="text-2xl flex items-center justify-center text-brand-1 rounded-full border-2 border-brand-1 w-6 h-6 pb-0.5">
          +
        </div>
      </div>
    </Section>
  );
};
