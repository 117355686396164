import React, { useEffect } from 'react';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { ReactComponent as Close } from '../../../assets/svg/Close_Icon.svg';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { AllSelectCheckbox, ErrorBlank, SurveyCreatorComponent } from '../../../components';
import { ActivityType, Group, RequestCreateActivityDto } from '../../../generated/model';
import {
  Blank,
  Button,
  Checkbox,
  Label,
  Section,
  Select,
  TextArea,
  TextField,
} from '../../../reusable';
import { useTeacherActivityAdd } from '../../../container/teacher-activity-add';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { DocumentObjectComponent } from '../../../components/document-object-component';

interface ActivityAddProps {
  refetch: () => void;
  activityData?: any;
}

const ActivityAddPage: React.FC<ActivityAddProps> = ({ refetch, activityData }) => {
  const {
    teacherGroupSubjects,
    title,
    subject,
    content,
    type,
    endDate,
    endDateOff,
    isPhrase,
    explainText,
    phrase,
    isImage,
    isFile,
    isContent,
    selectedGroups,
    firstGroup,
    secondGroup,
    thirdGroup,
    restGroup,
    imageObjectMap,
    documentObjectMap,
    buttonDisabled,
    errorMessage,
    isLoading,
    setTitle,
    setSubject,
    setContent,
    setType,
    setEndDate,
    setEndDateOff,
    setIsPhrase,
    setExplainText,
    setPhrase,
    setIsImage,
    setIsFile,
    setIsContent,
    setSelectedGroups,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    handleSubmit,
  } = useTeacherActivityAdd(activityData?.id);

  const handleSubmitButton = async () => {
    const payload = {
      title,
      content,
      subject,
      type,
      endDate: endDateOff ? null : endDate,
      groupIds: selectedGroups.map((el) => el.id) || [],
      isRecord: isPhrase,
      commonText: phrase,
      isImage,
      isFile,
      isContent,
      explainText,
    } as RequestCreateActivityDto;

    await handleSubmit({
      activityPayload: payload,
      imageObjectMapParam: imageObjectMap,
      documentObjectMapParam: documentObjectMap,
    });

    const typeName =
      type === ActivityType.NOTICE ? '공지' : type === ActivityType.SURVEY ? '설문' : '과제';

    if (activityData?.id) {
      alert(`활동(${typeName})가 수정되었습니다. 확인하려면 해당 반을 선택 후 확인해주세요!`);
    } else {
      alert(`활동(${typeName})가 추가되었습니다. 확인하려면 해당 반을 선택 후 확인해주세요!`);
    }

    await refetch();
    await setSelectedGroups([]);
    await setTitle('');
    await setContent('');
    await setSubject('');
  };

  useEffect(() => {
    if (!activityData) {
      setContent('');
    }
  }, [type]);

  if (errorMessage) {
    return <ErrorBlank />;
  }

  return (
    <div className="p-4 h-screen overflow-auto">
      {isLoading && <Blank reversed />}
      <div>
        <h3 className="text-xl font-semibold">활동기록부 추가하기</h3>
        <div className="bg-white p-4 border rounded-md mt-3">
          <Select
            label="과목"
            placeholder="과목을 선택해주세요"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
              setSelectedGroups([]);
            }}
          >
            {teacherGroupSubjects?.map((subject: string) => (
              <option value={subject} key={subject}>
                {subject}
              </option>
            ))}
          </Select>
        </div>
        <div className="bg-white p-4 border rounded-md mt-3">
          <span>전달 대상 선택</span>
          <div className="flex w-full justify-between mt-1">
            <div>
              <div className="font-bold">
                {!!firstGroup.length && (
                  <AllSelectCheckbox
                    groups={firstGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) => setSelectedGroups(groups)}
                    label="1학년 해당 반 전체"
                  />
                )}
              </div>
              {firstGroup.map((el) => (
                <Checkbox
                  key={el.id}
                  label={el.name}
                  checked={selectedGroups.map((el) => el.id).includes(el.id)}
                  onChange={() =>
                    setSelectedGroups(
                      selectedGroups.map((el) => el.id).includes(el.id)
                        ? selectedGroups.filter((g) => g.id !== el.id)
                        : selectedGroups.concat(el),
                    )
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {!!secondGroup.length && (
                  <AllSelectCheckbox
                    groups={secondGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) => setSelectedGroups(groups)}
                    label="2학년 해당 반 전체"
                  />
                )}
              </div>
              {secondGroup.map((el) => (
                <Checkbox
                  key={el.id}
                  label={el.name}
                  checked={selectedGroups.map((el) => el.id).includes(el.id)}
                  onChange={() =>
                    setSelectedGroups(
                      selectedGroups.map((el) => el.id).includes(el.id)
                        ? selectedGroups.filter((g) => g.id !== el.id)
                        : selectedGroups.concat(el),
                    )
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {!!thirdGroup.length && (
                  <AllSelectCheckbox
                    groups={thirdGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) => setSelectedGroups(groups)}
                    label="3학년 해당 반 전체"
                  />
                )}
              </div>
              {thirdGroup.map((el) => (
                <Checkbox
                  key={el.id}
                  label={el.name}
                  checked={selectedGroups.map((el) => el.id).includes(el.id)}
                  onChange={() =>
                    setSelectedGroups(
                      selectedGroups.map((el) => el.id).includes(el.id)
                        ? selectedGroups.filter((g) => g.id !== el.id)
                        : selectedGroups.concat(el),
                    )
                  }
                />
              ))}
            </div>
          </div>
          <div>
            {restGroup.map((el) => (
              <Checkbox
                key={el.id}
                label={el.name}
                value={el.id}
                checked={selectedGroups.map((el) => el.id).includes(el.id)}
                onChange={() =>
                  setSelectedGroups(
                    selectedGroups.map((el) => el.id).includes(el.id)
                      ? selectedGroups.filter((g) => g.id !== el.id)
                      : selectedGroups.concat(el),
                  )
                }
              />
            ))}
          </div>
          <div className="flex flex-wrap mt-2">
            {selectedGroups
              ?.slice()
              .sort((a: any, b: any) => {
                const aData = a.name.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
                const bData = b.name.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
                if (!aData || !bData) {
                  return 0;
                }

                if (aData[1] && bData[1]) {
                  if (aData[1] === bData[1]) {
                    return Number(aData[2]) - Number(bData[2]);
                  } else {
                    return Number(aData[1]) - Number(bData[1]);
                  }
                } else {
                  return 0;
                }
              })
              .map((group: Group) => (
                <div
                  key={group.id}
                  className="flex items-center w-max rounded-full border border-brand-1 text-brand-1 px-2.5 py-1.5 m-1s mr-2 mt-2 cursor-pointer"
                  onClick={() =>
                    setSelectedGroups(selectedGroups.filter((el) => el.id !== group.id))
                  }
                >
                  {group.name}
                  <Close className="ml-2" />
                </div>
              ))}
          </div>
        </div>

        <div>
          <Section tw={{ paddingX: 'px-0' }}>
            {/* <div className="mt-1">
            <Label>활동기록부 유형</Label>
            <SelectMenus item={PostTypeProps} AllText="게시글 타입" />
          </div> */}
            <TextField
              label="제목"
              id="title"
              placeholder="제목을 입력해주세요"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Select
              label="타입"
              placeholder="타입을 선택해주세요"
              value={type}
              onChange={(e) => setType(e.target.value as ActivityType)}
              disabled={!!activityData?.id}
              tw={!!activityData?.id ? { backgroundColor: 'bg-gray-300' } : {}}
            >
              <option value={ActivityType.POST}>과제</option>
              <option value={ActivityType.NOTICE}>공지</option>
              <option value={ActivityType.SURVEY}>설문</option>
            </Select>

            {type !== ActivityType.NOTICE && (
              <Checkbox
                checked={isPhrase}
                label="생활 기록부 상태 추가"
                onChange={() => setIsPhrase(!isPhrase)}
              />
            )}

            <div>
              <Label htmlFor="end_date">마감기한</Label>
              <div className="flex space-x-2">
                <Checkbox
                  checked={endDateOff}
                  label={endDateOff ? 'off' : 'on'}
                  onChange={() => setEndDateOff(!endDateOff)}
                />
                <div className="outline-none flex items-center px-4 w-full h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 appearance-none rounded-none focus:appearance-none focus:no-underline focus:outline-none">
                  <input
                    id="end_date"
                    type="datetime-local"
                    className="ring-0 focus:ring-0 outline-none focus:outline-none"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    pattern="\d{4}-\d{2}-\d{2}"
                    disabled={endDateOff}
                  />
                </div>
                {/* <SelectMenus item={YearProps} AllText="년도" />
            <SelectMenus item={MonthProps} AllText="월" />
            <SelectMenus item={DayProps} AllText="일" />
            <SelectMenus item={TimeProps} AllText="00시" />
            <SelectMenus item={MinuteProps} AllText="00분" /> */}
              </div>
            </div>
            {isPhrase && type !== ActivityType.NOTICE && (
              <TextField
                label="공통문구"
                placeholder="내용을 입력해주세요."
                style={{ borderWidth: '1px' }}
                value={phrase}
                onChange={(e) => setPhrase(e.target.value)}
              />
            )}
            {isPhrase && type !== ActivityType.NOTICE && (
              <TextField
                label="활동요약 설명"
                placeholder="활동요약에 대한 설명/예시 등을 입력합니다."
                style={{ borderWidth: '1px' }}
                value={explainText}
                onChange={(e) => setExplainText(e.target.value)}
              />
            )}

            {type === ActivityType.SURVEY ? (
              <SurveyCreatorComponent content={content} setContent={(c: string) => setContent(c)} />
            ) : (
              <>
                <div>
                  <Label>이미지</Label>
                  <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
                    {[...imageObjectMap]?.map(([key, value]) => (
                      <div key={key}>
                        <ImageObjectComponent
                          id={key}
                          imageObjet={value}
                          onDeleteClick={handleImageDeleteToggle}
                        />
                      </div>
                    ))}
                    {/* 이미지 업로드 컴포넌트 */}
                    <div>
                      <label htmlFor={`image-upload`}>
                        <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                          <div className="absolute w-full h-full rounded object-cover bg-white">
                            <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                              <FileUploadImg />
                              <div className="text-brand-1">이미지를 업로드해주세요!!</div>
                            </div>
                          </div>
                        </div>
                      </label>
                      <input
                        type="file"
                        id={`image-upload`}
                        className="hidden"
                        accept=".pdf, .png, .jpeg, .jpg"
                        onChange={handleImageAdd}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Label>파일</Label>
                  <div className="bg-white rounded border-2 border-dashed border-grey-5">
                    {[...documentObjectMap]?.map(([key, value]) => (
                      <div key={key}>
                        <DocumentObjectComponent
                          id={key}
                          documentObjet={value}
                          onDeleteClick={handleDocumentDeleteToggle}
                        />
                      </div>
                    ))}
                    <label htmlFor="file-upload">
                      <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                        <span className="text-2xl text-grey-3 mb-1">+</span>
                        <div className="text-brand-1">파일을 업로드해주세요 (다중 업로드 가능)</div>
                      </div>
                    </label>
                  </div>
                  <input
                    type="file"
                    id="file-upload"
                    className="hidden"
                    multiple
                    onChange={handleDocumentAdd}
                  />
                </div>
                <TextArea
                  label="내용"
                  placeholder="내용을 입력해주세요."
                  style={{ borderWidth: '1px' }}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </>
            )}
            {type === ActivityType.POST && (
              <div className="w-full">
                <div className="pb-4 font-bold text-lg">과제제출방식</div>
                <div className="flex w-full justify-between mt-1">
                  <Checkbox
                    label="이미지 업로드"
                    checked={isImage}
                    onChange={() => setIsImage(!isImage)}
                  />
                  <Checkbox
                    label="파일 업로드"
                    checked={isFile}
                    onChange={() => setIsFile(!isFile)}
                  />
                  <Checkbox
                    label="내용 입력"
                    checked={isContent}
                    onChange={() => setIsContent(!isContent)}
                  />
                </div>
              </div>
            )}
          </Section>
        </div>
        <div className="text-center mt-6 flex flex-row space-x-10 justify-center">
          {/* <Button
            text="미리보기"
            tw={{ height: 'h-11' }}
            style={{ width: '45%' }}
            onClick={() => setShowPreview(true)}
          /> */}
          <Button
            text="등록하기"
            tw={{
              backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
              height: 'h-11',
            }}
            style={{ width: '45%' }}
            disabled={buttonDisabled}
            onClick={handleSubmitButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityAddPage;
