import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useActivityControllerCreate, useActivityControllerUpdate } from '../generated/endpoint';
import {
  ActivityType,
  Group,
  RequestCreateActivityDto,
  UploadFileTypeEnum,
} from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';
import { useImageAndDocumentManagement } from '../hooks/useImageAndDocumentManagement';
import { Routes } from '../routes';
import { ImageObject } from '../type';
import { DocumentObject } from '../type/document-file-object';
import { useTeacherActivityDetail } from './teacher-activity-detail';
import { GroupContainer } from './group';
import { DayAfter } from '../utils';

export const useTeacherActivityAdd = (activityId?: number) => {
  const history = useHistory();
  const { teacherKlubGroups } = GroupContainer.useContext();

  const teacherGroupSubjects: string[] = [
    ...new Set(teacherKlubGroups.map((tg) => tg.teacherGroupSubject || '')),
  ];

  const [errorMessage, setErrorMessage] = useState<string>('');
  const { activity } = useTeacherActivityDetail(activityId);

  const [title, setTitle] = useState(activity?.title || '');
  const [content, setContent] = useState(activity?.content || '');
  const [subject, setSubject] = useState(activity?.subject || teacherGroupSubjects?.[0] || '');
  const [type, setType] = useState<ActivityType>(activity?.type || ActivityType.POST);
  const [endDate, setEndDate] = useState<string>(
    activity?.endDate || new Date(DayAfter(new Date())).toISOString(),
  );
  const [endDateOff, setEndDateOff] = useState<boolean>(activity?.endDate === null || true);
  const [isPhrase, setIsPhrase] = useState<boolean>(activity?.isRecord || false);
  const [explainText, setExplainText] = useState(activity?.explainText || '');
  const [phrase, setPhrase] = useState(activity?.commonText || '');
  const [isImage, setIsImage] = useState<boolean>(activity?.isImage || false);
  const [isFile, setIsFile] = useState<boolean>(activity?.isFile || false);
  const [isContent, setIsContent] = useState<boolean>(activity?.isContent || true);

  const {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
  } = useImageAndDocumentManagement({
    initImageObjectMapFn: () => {
      const urlImageMap = new Map<number, ImageObject>();
      activity?.images.forEach((image, index) => {
        urlImageMap.set(index, { image, isDelete: false });
      });
      return new Map(urlImageMap);
    },
    initDocumentObjectMapFn: () => {
      const urlDocumentMap = new Map<number, DocumentObject>();
      activity?.files.forEach((file, index) => {
        urlDocumentMap.set(index, { document: file, isDelete: false });
      });
      return new Map(urlDocumentMap);
    },
  });

  const { isUploadLoading, handleUploadFile } = useFileUpload();

  const { mutateAsync: createActivityMutate, isLoading: isCreateActivityMutate } =
    useActivityControllerCreate({
      mutation: {
        onSuccess: (result) => {
          history.push(`${Routes.teacher.activity.detail.replace(':id', String(result.id))}`);
        },
        onError: () => {
          setErrorMessage('활동 등록에 실패했습니다.');
        },
      },
    });

  const { mutateAsync: updateActivityMutate, isLoading: isUpdateActivityMutate } =
    useActivityControllerUpdate({
      mutation: {
        onSuccess: (result) => {
          history.push(`${Routes.teacher.activity.detail.replace(':id', String(result.id))}`);
        },
        onError: () => {
          setErrorMessage('활동 수정에 실패했습니다.');
        },
      },
    });

  const firstGroup: Group[] = [];
  const secondGroup: Group[] = [];
  const thirdGroup: Group[] = [];
  const restGroup: Group[] = [];

  const [selectedGroups, setSelectedGroups] = useState<Group[]>(
    activity?.groupActivities.map((g) => g.group) || [],
  );

  const groups = teacherKlubGroups
    .filter((tg) => tg?.teacherGroupSubject === subject)
    .slice()
    .filter(
      (g: Group, i: number, groups: Group[]) =>
        !!g && groups.filter((gp: Group) => !!gp && gp?.name === g?.name)[0].id === g.id,
    )
    .filter(
      (g: Group, i: number, groups: Group[]) =>
        !!g && groups.map((el: Group) => el.id).indexOf(g.id) === i,
    )
    .sort((a: any, b: any) => a.id - b.id);

  groups?.map((group) => {
    if (group.name?.includes('1학년')) {
      firstGroup.push(group);
    } else if (group.name?.includes('2학년')) {
      secondGroup.push(group);
    } else if (group.name?.includes('3학년')) {
      thirdGroup.push(group);
    } else {
      restGroup.push(group);
    }
    return group;
  });

  const selectedGroupIds = selectedGroups?.map((el) => el.id) || [];
  const isLoading = isUploadLoading || isCreateActivityMutate || isUpdateActivityMutate;
  const buttonDisabled = !title || !content || !subject || !type || !selectedGroups.length;

  const handleSubmit = async ({
    activityPayload,
    imageObjectMapParam,
    documentObjectMapParam,
  }: {
    activityPayload: RequestCreateActivityDto | undefined;
    imageObjectMapParam: Map<number, ImageObject>;
    documentObjectMapParam: Map<number, DocumentObject>;
  }) => {
    try {
      if (!activityPayload) {
        return;
      }
      // file image 처리
      const imageFiles = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.image instanceof File)
        .map((value) => value.image) as File[];
      const imageFileNames = await handleUploadFile(
        UploadFileTypeEnum['activities/images'],
        imageFiles,
      );

      // url image 처리
      const imageUrlNames = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.image === 'string')
        .map((value) => value.image) as string[];

      const allImageNames = [...imageUrlNames, ...imageFileNames];

      // file document 처리
      const documentFiles = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.document instanceof File)
        .map((value) => value.document) as File[];
      const documentFileNames = await handleUploadFile(
        UploadFileTypeEnum['activities/files'],
        documentFiles,
      );

      const documentUrlNames = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.document === 'string')
        .map((value) => value.document) as string[];

      const allDocumentNames = [...documentUrlNames, ...documentFileNames];

      const payload = activityPayload;
      if (activityId) {
        return updateActivityMutate({
          id: activityId,
          data: {
            ...payload,
            images: allImageNames,
            files: allDocumentNames,
          },
        });
      } else {
        return createActivityMutate({
          data: {
            ...payload,
            images: allImageNames,
            files: allDocumentNames,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    teacherGroupSubjects,
    title,
    subject,
    content,
    type,
    endDate,
    endDateOff,
    isPhrase,
    explainText,
    phrase,
    isImage,
    isFile,
    isContent,
    selectedGroups,
    firstGroup,
    secondGroup,
    thirdGroup,
    restGroup,
    selectedGroupIds,
    imageObjectMap,
    documentObjectMap,
    buttonDisabled,
    errorMessage,
    isLoading,
    setTitle,
    setSubject,
    setContent,
    setType,
    setEndDate,
    setEndDateOff,
    setIsPhrase,
    setExplainText,
    setPhrase,
    setIsImage,
    setIsFile,
    setIsContent,
    setSelectedGroups,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    handleSubmit,
  };
};
