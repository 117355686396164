import { useEffect, useState } from 'react';
import { useQuery as uQ } from 'react-query';
import { useHistory } from 'react-router-dom';

import { UserContainer } from './user';
import {
  useAbsentsControllerCreate,
  useAbsentsControllerFindAllByStudent,
  useAbsentsControllerUpdate,
} from '../generated/endpoint';
import { useSignature } from '../hooks/useSignature';
import { makeDateToString, makeTimeToString } from '../utils';
import { useImageAndDocumentManagement } from '../hooks/useImageAndDocumentManagement';
import { useFileUpload } from '../hooks/useFileUpload';
import { UploadFileTypeEnum, Absent } from '../generated/model';
import { ImageObject } from '../type';

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

const reasonType = [
  '상고',
  '코로나 19 관련',
  '생리',
  '학교장 출석인정',
  '병원진료',
  '가정에서의 안정',
  '보건실 방문',
  '기타',
];

const desType: any = {
  인정: {
    reasonType: ['상고', '코로나 19 관련', '생리', '학교장 출석인정'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  질병: {
    reasonType: ['병원진료', '가정에서의 안정', '보건실 방문'],
    evidenceFileType: ['학부모 확인서', '진료확인서', '처방전', '담임확인서', '보건교사 확인서'],
  },
  기타: {
    reasonType: ['기타'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  미인정: {
    reasonType: ['기타'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
};

type ImageObjectMapParam = Map<number, ImageObject>;

export const useStudentAbsentAdd = (absentData?: Absent) => {
  const history = useHistory();
  const { me } = UserContainer.useContext();
  const { canvasRef, sigPadData, clearSignature } = useSignature();
  const {
    reason: _reasonText = '',
    startAt: _startAt = '',
    endAt: _endAt = '',
    description: _description = '',
    reportType: _reportType = '',
  } = absentData || {};
  const _reason = _reasonText ? (reasonType.includes(_reasonText) ? _reasonText : '기타') : '';

  const { error, data } = useAbsentsControllerFindAllByStudent();

  const [reason, setReason] = useState(_reason);
  const [reasonText, setReasonText] = useState(_reasonText);
  const [report, setReport] = useState(_reportType);
  const [evidenceType, setEvidenceType] = useState(absentData?.evidenceType || '');
  const [evidenceTypeText, setEvidenceTypeText] = useState('');
  const [parentsName, setParentsName] = useState(me?.nokName || '');
  const [parentsPhone, setParentsPhone] = useState(me?.nokPhone || '');
  const [startAt, setStartAt] = useState(_startAt ? makeDateToString(_startAt) : '');
  const [endAt, setEndAt] = useState(_endAt ? makeDateToString(_endAt) : '');
  const [description, setDescription] = useState(_description || '');

  const [startHour, setStartHour] = useState(_startAt ? getMeridiemHours(_startAt) : 9);
  const [startMinute, setStartMinute] = useState(_startAt ? new Date(_startAt).getMinutes() : 0);
  const [endHour, setEndHour] = useState(_endAt ? getMeridiemHours(_endAt) : 16);
  const [endMinute, setEndMinute] = useState(_endAt ? new Date(_endAt).getMinutes() : 40);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [openSignModal, setSignModal] = useState(false);
  const [willRemoveImages, setWillRemoveImages] = useState<string[]>([]);

  const { imageObjectMap, handleImageAdd, handleImageDeleteToggle } = useImageAndDocumentManagement(
    {
      initImageObjectMapFn: () => {
        const urlImageMap = new Map<number, ImageObject>();
        absentData?.evidenceFiles.forEach((image: string, index: number) => {
          urlImageMap.set(index, { image, isDelete: false });
        });
        return new Map(urlImageMap);
      },
    },
  );
  const { handleUploadFile } = useFileUpload();

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    const hour = Number(startHour);
    date.setHours(hour, Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (report !== '결석') {
      startAt && (date = new Date(startAt));
    } else {
      endAt && (date = new Date(endAt));
    }
    const hour = Number(endHour);
    date.setHours(hour, Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const { mutateAsync: createAbsentMutate } = useAbsentsControllerCreate({
    mutation: {
      onSuccess: ({ id }) => {
        setLoading(false);
        setSignModal(false);
        alert(
          '출결신고서 제출이 완료되어 학부모님께 출결신고서 승인을 위한 메세지가 전달되었습니다.',
        );
        clearSignature();
        history.push(`/student/absent/${id}`);
      },
      onError: (e) => {
        setLoading(false);
        setSignModal(false);
        clearSignature();
        setErrorMessage(e.message);
      },
    },
  });

  // 업로드할 이미지가 0개인 경우 체크
  // @example evidenceType이 학부모 확인서가 아닌경우, 이미지를 업로드해야 제출함
  const hasNoImageToUpload =
    [...imageObjectMap.entries()].filter(([key, value]) => !value.isDelete).length === 0;

  const uploadFiles = async (imageObjectMapParam: ImageObjectMapParam) => {
    // file image 처리
    const imageFiles = [...imageObjectMapParam.values()]
      .filter((value) => !value.isDelete && value.image instanceof File)
      .map((value) => value.image) as File[];
    const imageFileNames = await handleUploadFile(UploadFileTypeEnum['absents/images'], imageFiles);

    // url image 처리
    const imageUrlNames = [...imageObjectMapParam.values()]
      .filter((value) => !value.isDelete && typeof value.image === 'string')
      .map((value) => value.image) as string[];

    return [...imageUrlNames, ...imageFileNames];
  };

  const createAbsent = async (imageObjectMapParam: ImageObjectMapParam) => {
    const allImageNames = await uploadFiles(imageObjectMapParam);

    return createAbsentMutate({
      data: {
        reportType: report,
        startAt: report !== '결석' ? makeStartAt() : startAt,
        endAt: report !== '결석' ? makeEndAt() : endAt,
        reason: reason === '학교장 출석인정' || reason === '기타' ? reasonText : reason,
        evidenceType: evidenceType === '기타' ? evidenceTypeText : evidenceType,
        description: description,
        parentsName,
        parentsPhone,
        evidenceFiles: allImageNames,
        studentSignature: sigPadData,
      },
    });
  };

  const { mutateAsync: updateAbsentMutate } = useAbsentsControllerUpdate({
    mutation: {
      onError: (e) => {
        setLoading(false);
        setErrorMessage(e.message);
      },
      onSuccess: ({ id }) => {
        absentData?.absentStatus === 'RETURNED' &&
          alert('수정이 완료되어 선생님께 전송되었습니다.');
        setLoading(false);
        setSignModal(false);
        clearSignature();
        history.push(`/student/absent/${id}`);
      },
    },
  });

  const updateAbsent = async (imageObjectMapParam: ImageObjectMapParam) => {
    if (!absentData) return;

    const allImageNames = await uploadFiles(imageObjectMapParam);
    return updateAbsentMutate({
      id: absentData.id,
      data: {
        reportType: report,
        startAt: report !== '결석' ? makeStartAt() : startAt,
        endAt: report !== '결석' ? makeEndAt() : endAt,
        reason: reason === '학교장 출석인정' ? reasonText : reason,
        evidenceType: evidenceType === '기타' ? evidenceTypeText : evidenceType,
        description: description,
        parentsName,
        parentsPhone,
        studentSignature: sigPadData,
        evidenceFiles: allImageNames,
      },
    });
  };
  useEffect(() => {
    if (!parentsName || !parentsPhone) {
      if (me?.nokName || me?.nokPhone) {
        setParentsName(me.nokName || '');
        setParentsPhone(me.nokPhone || '');
      }
    }
  }, [data]);
  return {
    state: {
      reason,
      reasonText,
      report,
      evidenceType,
      evidenceTypeText,
      parentsName,
      parentsPhone,
      startAt,
      endAt,
      description,
      startHour,
      startMinute,
      endHour,
      endMinute,
      errorMessage,
      isLoading,
      openSignModal,
      willRemoveImages,
    },
    setState: {
      setReason,
      setReasonText,
      setReport,
      setEvidenceType,
      setEvidenceTypeText,
      setParentsName,
      setParentsPhone,
      setStartAt,
      setEndAt,
      setDescription,
      setStartHour,
      setStartMinute,
      setEndHour,
      setEndMinute,
      setErrorMessage,
      setLoading,
      setSignModal,
      setWillRemoveImages,
    },
    signature: {
      canvasRef,
      sigPadData,
      clearSignature,
    },
    reasonType,
    desType,
    updateAbsent,
    createAbsent,
    error,
    imageObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    hasNoImageToUpload,
  };
};
