import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { ErrorBlank, Td } from '../../../components';
import { useStudentFieldtripNotice } from '../../../container/student-fieldtrip-notice';
import { TopNavbar, BackButton, Section, Blank } from '../../../reusable';
import { makeStartEndToString } from '../../../utils';

interface MatchParams {
  id: string;
}

const FieldtripNoticePage: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const { fieldtrip, isLoading, error, me } = useStudentFieldtripNotice(Number(id));

  return (
    <>
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="통보서"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <h1 className="text-xl font-bold text-center underline">학교장허가 교외체험 학습통보서</h1>
        <label className="mb-1 text-sm text-center font-semibold text-gray-800">
          {fieldtrip?.student?.name} {fieldtrip?.studentGradeKlass} {fieldtrip?.studentNumber}번
        </label>
        <table className="w-full text-center font-bold">
          <tr>
            <Td innerClassName="min-w-max">신청기간</Td>
            <Td>
              {fieldtrip?.startAt &&
                fieldtrip?.endAt &&
                makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}{' '}
              ({fieldtrip?.usedDays}
              일간)
            </Td>
          </tr>
          <tr>
            <Td innerClassName="min-w-max">허가기간</Td>
            <Td>
              {fieldtrip?.startAt &&
                fieldtrip?.endAt &&
                makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}{' '}
              ({fieldtrip?.usedDays}
              일간)
            </Td>
          </tr>
        </table>
        <div className="text-sm">
          본교 교외체험학습{' '}
          <span className="font-bold">출석인정 기간 연간 {me?.school?.fieldtripDays}일 중 </span>{' '}
          금회까지
          <span className="font-bold">
            {' '}
            누적 사용기간은{' '}
            {me?.school?.fieldtripDays &&
              fieldtrip?.currentRemainDays &&
              fieldtrip?.usedDays &&
              me?.school.fieldtripDays - fieldtrip.currentRemainDays + fieldtrip.usedDays}
            일
          </span>
          입니다.
        </div>
        <label className="mb-1 text-sm text-center text-gray-800">
          위와 같이 처리되었음을 알려드립니다.
        </label>
        <label className="mb-1 text-sm text-center text-gray-800">{fieldtrip?.noticedAt}</label>
        <div className="w-full flex items-center space-x-4">
          <div className="text-gray-600 w-full text-right min-w-max font-bold">
            {me?.school?.name} {fieldtrip?.studentGradeKlass}
          </div>
        </div>
        <div className="w-full flex justify-end space-x-4 text-gray-600">
          <div className="min-w-max">담임교사 {fieldtrip?.teacher?.name} (인)</div>
        </div>
        <div className="w-full flex justify-end">
          {fieldtrip?.teacherSignature && (
            <img src={fieldtrip?.teacherSignature} alt="" style={{ width: '50px' }} />
          )}
        </div>
        <div className="w-full flex items-center space-x-4">
          <div className="text-gray-600 w-full text-right min-w-max">{me?.nokName} 귀하</div>
        </div>
        <label className="mb-1 text-sm text-center font-semibold text-gray-800">
          *교외체험학습 실시 중에는 보호자와 담당교사 간 연락체계를 유지하고 사안(사고) 발생 시
          보호자는 담당교사에게 연락을 하도록 합니다.
        </label>
        <label className="mb-1 text-sm text-center font-semibold text-gray-800">
          *결과보고서 제출 기한은 체험학습 종료 후 5일 이내입니다.
        </label>
      </Section>
    </>
  );
};

export default withRouter(FieldtripNoticePage);
