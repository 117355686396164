import { useEffect, useRef, useState } from 'react';

import SignaturePad from 'signature_pad';

let signaturePad: SignaturePad | null = null;

export const useSignature = () => {
  const [sigPadData, setSigPadData] = useState('');
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const clearSignature = () => {
    signaturePad && signaturePad.clear();
    setSigPadData('');
  };

  const handleSign = () => {
    if (signaturePad) {
      setSigPadData(signaturePad.toDataURL());
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      signaturePad = new SignaturePad(canvasRef.current);
      signaturePad.addEventListener('endStroke', handleSign);
    }
  }, []);

  return {
    canvasRef,
    sigPadData,
    clearSignature,
  };
};
