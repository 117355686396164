import React, { FC, useEffect, useState } from 'react';
import { TextField } from '../../reusable';
import { ReactComponent as ChevronUpIcon } from '../../assets/svg/icon-chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/svg/icon-chevron-down.svg';

interface TimetableStudentRoleProps {
  editmode: boolean;
  student: any;
  order: number;
  setOrder: (order: number, isUpDir: boolean) => void;
}

export const TimetableStudentRole: FC<TimetableStudentRoleProps> = ({
  editmode,
  student,
  order,
  setOrder,
}) => {
  const [stateRole, setStateRole] = useState(student.role);
  const [stateJob, setStateJob] = useState(student.job);

  useEffect(() => {
    setStateRole(student.role);
    setStateJob(student.job);
  }, [student]);

  return (
    <>
      {editmode && (
        <div>
          <div className="w-full flex justify-between items-center px-4 py-2">
            <div className="flex items-center w-full">
              <div
                className={`rounded-full w-10 h-10 flex items-center justify-center border-2 cursor-pointer`}
                onClick={(e) => {
                  setOrder(order, true);
                }}
              >
                <ChevronUpIcon />
              </div>
              <div
                className={`rounded-full w-10 h-10 flex items-center justify-center border-2 cursor-pointer`}
                onClick={() => {
                  setOrder(order, false);
                }}
              >
                <ChevronDownIcon />
              </div>
              <div className=" hidden text-sm md:block md:text-lg w-64">
                {student.klassname}
              </div>
              <div className="text-sm md:text-lg w-30">
                {student.student_number}번
              </div>
              <div className="text-sm md:text-lg w-48">{student.name}</div>
              <TextField
                placeholder="역할"
                value={stateRole}
                twOuter={{ width: 'w-10' }}
                onChange={(e) => {
                  setStateRole(e.target.value);
                  student.role = e.target.value;
                }}
              />
              <TextField
                placeholder="하는 일"
                value={stateJob}
                twOuter={{ width: 'w-10' }}
                onChange={(e) => {
                  setStateJob(e.target.value);
                  student.job = e.target.value;
                }}
              />
            </div>
          </div>
        </div>
      )}

      {!editmode && (
        <div>
          <div className="w-full flex justify-between items-center px-4 py-2">
            <div className="flex items-center space-x-2">
              <div className="text-sm md:text-base bg-blue-100 text-blue-600 px-2.5 py-1.5 rounded w-40 truncate">
                {student.role ? student.role : '미지정'}
              </div>
              <div className="text-sm md:text-lg">
                {student.klassname} {student.student_number}번
              </div>
              <div className="text-sm md:text-lg">{student.name}</div>
            </div>
          </div>
          {student.job !== '' && (
            <div className="text-sm md:text-base px-4">
              하는 일 : {student.job}
            </div>
          )}
        </div>
      )}
    </>
  );
};
