import { useState } from 'react';

import { useUserControllerFindPassword } from '../generated/endpoint';

export const useFindPassword = (email: string) => {
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoading, refetch } = useUserControllerFindPassword(
    { email },
    {
      query: {
        onSuccess: () => {
          alert('이메일이 발송되었습니다.');
          setSuccess(true);
        },
        onError: (err) => {
          setErrorMessage(err.message);
        },
        enabled: false,
      },
    },
  );

  return { refetch, isLoading, isSuccess, errorMessage, setErrorMessage };
};
