import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorBlank, SelectValues } from '../../../components';
import { useStudentOutingAdd } from '../../../container/student-outing-add';
import { UserContainer } from '../../../container/user';
import { Outing, OutingTypeEnum } from '../../../generated/model';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Checkbox,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';

const reportType = [OutingTypeEnum.조퇴, OutingTypeEnum.외출, OutingTypeEnum.확인];

interface OutingAddPageProps {
  outingData: Outing;
  goDetail: () => void;
}

export const OutingAddPage: React.FC<OutingAddPageProps> = ({ outingData, goDetail }) => {
  const history = useHistory();
  const { me, errorMessage: meErrorMessage } = UserContainer.useContext();
  const studentName = me?.name;
  const [agree, setAgree] = useState(outingData ? true : false);
  const {
    errorMessage,
    successId,
    startAt,
    setStartAt,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endAt,
    setEndAt,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    report,
    setReport,
    reason,
    setReason,
    isLoading,
    updateOuting,
    createOuting,
  } = useStudentOutingAdd(outingData, goDetail);

  const startDateTime = new Date();
  startDateTime.setHours(startHour);
  startDateTime.setMinutes(startMinute);
  startDateTime.setSeconds(0);

  const endDateTime = new Date();
  endDateTime.setHours(endHour);
  endDateTime.setMinutes(endMinute);
  endDateTime.setSeconds(0);

  if (successId) {
    return (
      <>
        <TopNavbar title="조퇴 / 외출 / 확인 작성" left={<BackButton />} />
        <div className="w-full h-screen flex flex-col items-center justify-center text-center">
          <div className="text-gray-600 font-bold text-xl">조퇴/외출/확인증 제출 완료</div>
          <div className="text-gray-400 mt-4">
            조퇴/외출/확인증 제출이 완료되었습니다. <br />
            담임 선생님의 서명 후 승인이 완료됩니다.
          </div>
          <BottomFixedView tw={{ bottom: 'bottom-16', paddingX: 'px-5' }}>
            <Button
              onClick={() => history.push(`/student/outing/${successId}`)}
              tw={{ width: 'w-full' }}
            >
              확인하기
            </Button>
          </BottomFixedView>
        </div>
      </>
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      {meErrorMessage && <ErrorBlank />}
      <TopNavbar
        title="조퇴 / 외출 / 확인 신청"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div className="w-full">
          <TextField
            label="학생 이름(본인)"
            id="studentName"
            value={me?.name}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <TextField
            label="학생 학년/반/번호"
            value={`${me?.klassGroupName ?? ''} / ${me?.studentNumber ?? ''}번`}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <SelectValues
            label="*유형"
            placeholder="신청 유형을 선택해주세요."
            selectValues={reportType}
            value={report}
            onChange={(group) => {
              setReport(group);
              if (group === OutingTypeEnum.외출) {
                setStartHour(12);
                setStartMinute(50);
                setEndHour(13);
                setEndMinute(50);
              }
            }}
          />
        </div>
        <div className="w-full pb-6">
          <label className="mb-1 text-sm text-gray-800">*기간</label>
          <div className="space-y-3 pb-6">
            <div>
              <input
                type="date"
                lang="ko-KR"
                value={startAt}
                className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                onChange={(e) => {
                  const _startAt = e.target.value;
                  if (new Date(_startAt) > new Date(endAt)) {
                    setEndAt(_startAt);
                  }
                  if (_startAt === endAt) {
                    if (startHour > endHour) {
                      setEndHour(startHour);
                      if (startMinute > endMinute) {
                        setEndMinute(startMinute);
                      }
                    }
                    if (startHour === endHour && startMinute > endMinute) {
                      setEndMinute(startMinute);
                    }
                  }
                  setStartAt(_startAt);
                }}
              />
            </div>
            <div className="flex items-center space-x-2">
              <select
                value={startHour}
                onChange={(e) => {
                  const _startHour = Number(e.target.value);
                  if (_startHour > endHour) {
                    setEndHour(_startHour);
                  }
                  if (_startHour === endHour && startMinute > endMinute) {
                    setEndMinute(startMinute);
                  }
                  setStartHour(_startHour);
                }}
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((_, index) => (
                  <option key={index} value={index}>
                    {index}
                  </option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => {
                  const _startMinute = Number(e.target.value);
                  if (startHour === endHour && _startMinute > endMinute) {
                    setEndMinute(_startMinute);
                  }
                  setStartMinute(_startMinute);
                }}
                value={startMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 부터</span>
            </div>
            {report === OutingTypeEnum.확인 && (
              <div>
                <input
                  type="date"
                  lang="ko-KR"
                  value={endAt}
                  className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                  onChange={(e) => {
                    const _endAt = e.target.value;
                    if (new Date(startAt) > new Date(_endAt)) {
                      setStartAt(_endAt);
                    }
                    if (_endAt === startAt) {
                      if (startHour > endHour) {
                        setStartHour(endHour);
                        if (startMinute > endMinute) {
                          setStartMinute(endMinute);
                        }
                      }
                      if (startHour === endHour && startMinute > endMinute) {
                        setStartMinute(endMinute);
                      }
                    }
                    setEndAt(_endAt);
                  }}
                />
              </div>
            )}
            <div className="flex items-center space-x-2">
              <select
                value={endHour}
                onChange={(e) => {
                  const _endHour = Number(e.target.value);
                  if (startHour > _endHour) {
                    setStartHour(_endHour);
                  }
                  if (startHour === _endHour && startMinute > endMinute) {
                    setEndMinute(startMinute);
                  }
                  setEndHour(_endHour);
                }}
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((_, index) => (
                  <option key={index} value={index}>
                    {index}
                  </option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => {
                  const _endMinute = Number(e.target.value);
                  if (startHour === endHour && startMinute > _endMinute) {
                    setStartMinute(_endMinute);
                  }
                  setEndMinute(_endMinute);
                }}
                value={endMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 까지</span>
            </div>
          </div>
          <div className="w-full">
            <TextField
              label="*사유"
              id="reason"
              placeholder="조퇴/외출/확인 사유를 자세하게 입력해주세요"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="text-red-600 text-sm whitespace-pre-line mb-2">
            *아래와 같은 민감정보의 수집/이용/제3자 제공에 동의
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            건강에 관한 정보 제출은 민감정보의 제공에 해당합니다. 슈퍼스쿨은 학생 회원으로부터 제출
            받은 건강에 관한 정보를 학생 회원 소속 각급학교에 제공하여 출결 관리서비스를 제공하고
            있습니다.
          </div>
        </div>
        <div className="flex items-center space-x-2 mb-1 justify-end">
          <Checkbox id="agree" onChange={() => setAgree(!agree)} checked={agree} />
          <label htmlFor="agree">
            <span className="font-semibold text-md cursor-pointer">동의하기</span>
          </label>
        </div>
        <Button
          disabled={
            !reason || !report || !studentName || !startAt || !agree || startDateTime > endDateTime
          }
          tw={{
            backgroundColor:
              !reason || !report || !studentName || !startAt || !agree
                ? 'bg-gray-300'
                : 'bg-brand-1',
          }}
          onClick={() => {
            outingData ? updateOuting() : createOuting();
          }}
        >
          제출하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
    </>
  );
};
