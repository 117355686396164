import { useEffect, useState } from 'react';

import {
  useAbsentsControllerDeny,
  useAbsentsControllerFindOne,
  useAbsentsControllerRequestDelete,
} from '../generated/endpoint';

type Props = { id: number; setAbsentId: (n: number) => void; refetch: () => void };

export const useTeacherAbsentDeatil = ({ id, setAbsentId, refetch }: Props) => {
  const [deny, setDeny] = useState(false);
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');

  const { data: absent, isLoading: isGetAbsentLoading } = useAbsentsControllerFindOne(id, {
    query: {
      enabled: !!id,
    },
  });

  useEffect(() => {
    setAbsentId(Number(id));
  }, [id, setAbsentId]);

  const { mutate: denyAbsentMutate, isLoading: isDenyAbsentLoading } = useAbsentsControllerDeny({
    mutation: {
      onSuccess: () => {
        setDeny(false);
        refetch();
      },
    },
  });

  const denyAbsent = () => {
    denyAbsentMutate({
      id,
      data: {
        notApprovedReason,
      },
    });
  };

  const { mutate: deleteAppealAbsentMutate, isLoading: isDeleteAppealAbsentLoading } =
    useAbsentsControllerRequestDelete({
      mutation: {
        onSuccess: () => {
          setDeleteAppeal(false);
          refetch();
        },
      },
    });

  const deleteAppealAbsent = () => {
    deleteAppealAbsentMutate({
      id,
      data: {
        deleteReason,
      },
    });
  };

  const isLoading = isGetAbsentLoading || isDenyAbsentLoading || isDeleteAppealAbsentLoading;

  return {
    deny,
    setDeny,
    deleteAppeal,
    setDeleteAppeal,
    notApprovedReason,
    setNotApprovedReason,
    deleteReason,
    setDeleteReason,

    absent,
    denyAbsent,
    deleteAppealAbsent,
    isLoading,
  };
};
