import { useState } from 'react';

import {
  useFieldtripsControllerApproveByParent,
  useFieldtripsControllerFindOneByUUID,
} from '../generated/endpoint';

export const useParentFieldtripApprove = ({
  sigPadData,
  uuid,
}: {
  sigPadData: string;
  uuid: string;
}) => {
  const [isShowSignModal, setIsShowSignModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setSuccess] = useState(false);

  const {
    data: fieldtrip,
    isLoading: isGetFieldtripLoading,
    error: fieldtripError,
  } = useFieldtripsControllerFindOneByUUID(uuid);

  const { mutate: approveFieldtripMutate, isLoading: isApproveFieldtripLoading } =
    useFieldtripsControllerApproveByParent({
      mutation: {
        onSuccess: () => {
          hideSignModal();
          setSuccess(true);
        },
        onError: (e) => {
          hideSignModal();
          setErrorMessage(e.message);
        },
      },
    });

  const hideSignModal = () => {
    setIsShowSignModal(false);
  };

  const openSignModal = () => {
    setIsShowSignModal(true);
  };

  const approveFieldtrip = () => {
    approveFieldtripMutate({
      uuid,
      data: { signature: sigPadData },
    });
  };

  const isLoading = isApproveFieldtripLoading || isGetFieldtripLoading;

  return {
    hideSignModal,
    openSignModal,
    isShowSignModal,
    fieldtripError,
    isSuccess,
    isLoading,
    errorMessage,
    approveFieldtrip,
    fieldtrip,
  };
};
