import { ChangeEvent, useState } from 'react';

import { ImageObject } from '../type';
import { DocumentObject } from '../type/document-file-object';
import { checkFileSizeLimit20MB } from '../utils/file-util';

const getAllFiles = ({
  imageObjectMap,
  documentObjectMap,
}: {
  imageObjectMap: Map<number, ImageObject>;
  documentObjectMap: Map<number, DocumentObject>;
}) => {
  const imageFiles = [...imageObjectMap.values()]
    .filter((object) => !object.isDelete && object.image instanceof File)
    .map((object) => object.image) as File[];
  const documentFiles = [...documentObjectMap.values()]
    .filter((object) => !object.isDelete && object.document instanceof File)
    .map((object) => object.document) as File[];
  return [...imageFiles, ...documentFiles];
};
export const useImageAndDocumentManagement = ({
  initImageObjectMapFn = () => new Map<number, ImageObject>(),
  initDocumentObjectMapFn = () => new Map<number, DocumentObject>(),
}: {
  initImageObjectMapFn?: () => Map<number, ImageObject>;
  initDocumentObjectMapFn?: () => Map<number, DocumentObject>;
}) => {
  const [imageObjectMap, setImageObjectMap] =
    useState<Map<number, ImageObject>>(initImageObjectMapFn);
  const [documentObjectMap, setDocumentObjectMap] =
    useState<Map<number, DocumentObject>>(initDocumentObjectMapFn);

  const handleImageAdd = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedImageFiles = (e.target as HTMLInputElement).files;
    if (!selectedImageFiles || !selectedImageFiles.length) {
      return;
    }

    if (!checkFileSizeLimit20MB([...getAllFiles({ imageObjectMap, documentObjectMap })])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!');
      return;
    }

    const newImageObjectMap = new Map(imageObjectMap).set(imageObjectMap.size, {
      image: selectedImageFiles[0],
      isDelete: false,
    });
    setImageObjectMap(newImageObjectMap);
  };

  const handleImageDeleteToggle = (key: number) => {
    const newImageObjectMap = new Map(imageObjectMap);
    const obj = newImageObjectMap.get(key);
    if (obj) {
      obj.isDelete = !obj.isDelete;
      newImageObjectMap.set(key, obj);
    }
    setImageObjectMap(newImageObjectMap);
  };

  const handleDocumentAdd = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedFiles = (e.target as HTMLInputElement).files;
    if (!selectedFiles || !selectedFiles.length) {
      return;
    }

    if (!checkFileSizeLimit20MB([...getAllFiles({ imageObjectMap, documentObjectMap })])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다. 추가 파일은 올린 후 수정해서 넣어주세요!');
      return;
    }

    const newFileObjectMap = new Map(documentObjectMap).set(documentObjectMap.size, {
      document: selectedFiles[0],
      isDelete: false,
    });
    setDocumentObjectMap(newFileObjectMap);
  };

  const handleDocumentDeleteToggle = (key: number) => {
    const newDocumentObjectMap = new Map(documentObjectMap);
    const obj = newDocumentObjectMap.get(key);
    if (obj) {
      obj.isDelete = !obj.isDelete;
      newDocumentObjectMap.set(key, obj);
    }
    setDocumentObjectMap(newDocumentObjectMap);
  };

  return {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
  };
};
