import { useUserControllerUpdateMyInfoAtFirstLogin } from '../generated/endpoint';

export const useTeacherFirstLogin = () => {
  const { mutate: teacherFirstLoginMutate, isLoading: isTeacherFirstLoginMutateLoading } =
    useUserControllerUpdateMyInfoAtFirstLogin({
      mutation: {
        onSuccess: () => {
          alert('정보 설정이 완료되었습니다!');
          window.location.reload();
        },
        onError: () => {
          alert('정보 설정에 실패했습니다.');
        },
      },
    });

  const isLoading = isTeacherFirstLoginMutateLoading;

  const handleTeacherFirstLogin = ({
    password,
    name,
    phone,
  }: {
    password: string;
    name: string;
    phone: string;
  }) => {
    teacherFirstLoginMutate({
      data: {
        password,
        name,
        phone,
        nokName: '',
        nokPhone: '',
        hopeMajor: '',
        hopePath: '',
        birthDate: '',
      },
    });
  };

  return {
    isLoading,
    handleTeacherFirstLogin,
  };
};

