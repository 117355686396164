import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import OutingDetailPage from './OutingDetailPage';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import {
  ErrorBlank,
  FrontPagination,
  OutingCard,
  SelectMenus,
  SuperModal,
} from '../../../components';
import { useTeacherOutgoing } from '../../../container/teacher-outgoing';
import { UserContainer } from '../../../container/user';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  SearchField,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { makeDateToString } from '../../../utils';

export const OutingPage: React.FC = () => {
  const history = useHistory();
  const { me } = UserContainer.useContext();
  const userRole = me?.role;

  const [agreeAll, setAgreeAll] = useState(false);
  const [_studentName, set_studentName] = useState('');

  const {
    signature: { canvasRef, sigPadData, clearSignature },
    stamp: { stampMode, stampImgUrl, updateStamp, setStampMode },
    filters,
    filter,
    setFilter,
    studentName,
    setStudentName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    setPage,
    limit,
    open,
    setOpen,
    setOutingId,

    isLoading,
    outings,
    refetchOutgoings,
    error,
    approveOuting,
    approveOutings,
  } = useTeacherOutgoing();

  const { pathname } = useLocation();
  const isDetail = !pathname.endsWith('/teacher/outing');

  return (
    <>
      {error && <ErrorBlank />}
      {isLoading && <Blank reversed />}

      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <div className="pb-3">
          <TopNavbar title="조퇴 / 외출 / 확인" left={<BackButton />} />
        </div>
        <div className="px-4 py-4">
          <div className="flex space-x-3 mb-4 items-center">
            <TextField
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <div className="px-0 font-bold text-xs">~</div>
            <TextField
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="flex space-x-3 items-center">
            <div className="min-w-max">
              <SelectMenus
                allText="모두"
                items={filters}
                value={filters ? filter : null}
                onChange={(group: any) => setFilter(group)}
              />
            </div>
            <div className="w-full">
              <SearchField
                placeholder="학생이름을 입력해주세요."
                value={studentName}
                onChange={(e) => {
                  setStudentName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className=" h-screen-16 overflow-y-auto scroll-box">
          {outings?.items?.map((outing: any) => (
            <OutingCard outing={outing} />
          ))}
          {outings && outings?.total > limit && (
            <div className="grid place-items-center">
              <FrontPagination
                basePath="/teacher/outing"
                total={outings?.total}
                limit={limit}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
          <div className="w-full h-32" />
        </div>
        <BottomFixedView tw={{ bottom: 'bottom-16', paddingX: 'px-5', zIndex: '100' }}>
          {userRole !== 'SECURITY' && (
            <Button
              text="일괄 승인하기"
              tw={{
                minWidth: 'min-w-max',
                paddingY: 'py-3',
                height: 'h-auto',
                width: 'w-full',
              }}
              onClick={() => {
                setOpen(true);
                setAgreeAll(true);
              }}
            />
          )}
        </BottomFixedView>
      </div>

      <div
        className={`bg-gray-50 block md:hidden ${
          isDetail ? 'h-full' : 'col-span-4 p-6'
        } scroll-box`}
      >
        <Switch>
          <Route
            path="/teacher/outing/:id"
            component={() => (
              <OutingDetailPage
                outings={outings}
                setOutingId={(n: number) => setOutingId(n)}
                setOpen={(b: boolean) => setOpen(b)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                userRole={userRole}
                refetch={() => refetchOutgoings()}
              />
            )}
          />
        </Switch>
      </div>

      {/* Desktop V */}
      <div className="col-span-3 h-screen overflow-y-auto hidden md:block">
        <div className="px-6 py-6">
          <h1 className="text-2xl font-semibold mb-5">조퇴 / 외출 / 확인</h1>
          <div className="flex space-x-3 mb-4 items-center">
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="flex space-x-2 items-center">
            <div className=" px-2 py-2 min-w-max cursor-pointer">
              <SelectMenus
                allText="모두"
                items={filters}
                onChange={(e) => setFilter(e)}
                value={filter}
              ></SelectMenus>
            </div>
            <div className="flex items-center space-x-2 w-full">
              <form
                className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/outing`);
                    }
                    setPage(1);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (_studentName) {
                        history.replace(`/teacher/outing?username=${_studentName}`);
                      }
                    }
                  }}
                />
              </form>
              <UploadIcon
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    history.replace(`/teacher/outing?username=${_studentName}`);
                  }
                }}
              />
            </div>
            <Button
              text="일괄 승인하기"
              tw={{
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-auto',
                paddingX: 'px-4',
              }}
              onClick={() => {
                setOpen(true);
                setAgreeAll(true);
              }}
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="h-screen-15 overflow-y-auto scroll-box">
          {outings?.items?.map((outing: any) => (
            <OutingCard outing={outing} />
          ))}
          {outings && outings?.total > limit && (
            <div className="grid place-items-center">
              <FrontPagination
                basePath="/teacher/outing"
                total={outings?.total}
                limit={limit}
                page={page}
                setPage={setPage}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-span-3 bg-gray-50 p-6 overflow-y-auto scroll-box  hidden md:block">
        <Switch>
          <Route
            path="/teacher/outing/:id"
            component={() => (
              <OutingDetailPage
                outings={outings}
                setOutingId={(n: number) => setOutingId(n)}
                setOpen={(b: boolean) => setOpen(b)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                userRole={userRole}
                refetch={() => refetchOutgoings()}
              />
            )}
          />
        </Switch>
      </div>
      <SuperModal
        modalOpen={open}
        setModalClose={() => {
          clearSignature();
          setOpen(false);
        }}
        width="w-max"
        ablePropragation
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div>
            <div className="text-gray-700 text-xl font-bold">서명란</div>
            <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
          </div>
          <div className="relative">
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            {stampMode ? (
              stampImgUrl ? (
                <div
                  className="absolute inset-0 z-10 rounded overflow-hidden bg-center bg-no-repeat bg-contain"
                  style={{ backgroundImage: `url("${stampImgUrl}")` }}
                ></div>
              ) : (
                <div className="absolute inset-0 z-10 rounded overflow-hidden bg-grey-4">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="min-w-max text-center">도장을 등록해주세요.</div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <label htmlFor="stamp-upload" className="w-1/2">
              <div className="w-full rounded-lg font-bold h-13 px-6 justify-center flex items-center bg-white border border-brandblue-1 text-current cursor-pointer">
                도장등록
              </div>
            </label>
            <input
              id="stamp-upload"
              name="stamp-upload"
              type="file"
              className="sr-only"
              accept=".pdf, .png, .jpeg, .jpg"
              onChange={(e) => {
                if (!e.target?.files) return;
                updateStamp(e.target.files[0]);
                setStampMode(true);
              }}
            />
            {!stampMode ? (
              <Button
                tw={{
                  width: 'w-1/2',
                  borderWidth: 'border',
                  borderColor: 'border-brandblue-1',
                  backgroundColor: 'bg-white',
                  color: 'text-brandblue-1',
                  minWidth: 'min-w-max',
                }}
                onClick={async () => {
                  setStampMode(true);
                  clearSignature();
                }}
              >
                도장 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-1/2',
                  minWidth: 'min-w-max',
                  backgroundColor: stampImgUrl ? 'bg-brandblue-1' : 'bg-brandblue-5',
                }}
                disabled={!stampImgUrl}
                onClick={async () => {
                  if (!stampImgUrl) {
                    alert('도장이 등록되어 있지 않습니다.');
                  } else {
                    if (agreeAll) {
                      await approveOutings();
                    } else {
                      await approveOuting();
                    }
                    await setStampMode(false);
                  }
                }}
              >
                도장으로 승인
              </Button>
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                borderWidth: 'border',
                borderColor: 'border-brand-1',
                color: 'text-current',
                width: 'w-full',
                minWidth: 'min-w-max',
              }}
              onClick={() => clearSignature()}
            >
              사인 다시하기
            </Button>
            {stampMode ? (
              <Button
                tw={{
                  width: 'w-full',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  backgroundColor: 'bg-white',
                  color: 'text-brand-1',
                  minWidth: 'min-w-max',
                }}
                onClick={() => setStampMode(false)}
              >
                사인 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: sigPadData ? 'bg-brand-1' : 'bg-brand-5',
                }}
                onClick={() => {
                  if (!sigPadData) {
                    alert('사인 후 승인해 주세요.');
                  } else {
                    agreeAll ? approveOutings() : approveOuting();
                  }
                }}
              >
                사인으로 승인
              </Button>
            )}
          </div>
        </Section>
      </SuperModal>
    </>
  );
};
