import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { createContainer } from './createContainer';
import {
  useGroupsControllerFindAllKlassBySchool,
  useGroupsControllerFindKlassByUser,
  useGroupsControllerFindKlubBySchoolIdAndUserId,
} from '../generated/endpoint';
import { ResponseGroupDto, Role } from '../generated/model';
import { meStateNew } from '../store';

export const groupHook = () => {
  const me = useRecoilValue(meStateNew);

  const [teacherKlubGroups, setTeacherKlubGroups] = useState<ResponseGroupDto[]>([]);
  const [teacherKlassGroups, setTeacherKlassGroups] = useState<ResponseGroupDto[]>([]);
  const [allKlassGroups, setAllKlassGroups] = useState<ResponseGroupDto[]>([]);

  const {
    error: errorAllKlassGroups,
    refetch: refetchAllKlassGroups,
    isLoading,
  } = useGroupsControllerFindAllKlassBySchool({
    query: {
      enabled: !!me?.id && Role.PARENT !== me.role && Role.USER !== me.role,
      onSuccess: (res) => {
        if (!res?.length) {
          setAllKlassGroups([]);
          return;
        }

        const sorted = res
          .filter((el) => !!el.name)
          .sort((a, b) => {
            if (!a.name || !b.name) {
              return 0;
            }
            const aData = a.name.match('([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반');
            const bData = b.name.match('([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반');

            if (aData?.[1] === bData?.[1]) {
              return Number(aData?.[2]) - Number(bData?.[2]);
            } else {
              return Number(aData?.[1]) - Number(bData?.[1]);
            }
          });

        setAllKlassGroups(sorted);
      },
    },
  });

  const {
    refetch: refetchTeacherKlubGroups,
    error: errorTeacherKlubGroups,
    isLoading: isLoadingTeacherKlubGroups,
  } = useGroupsControllerFindKlubBySchoolIdAndUserId<ResponseGroupDto[]>({
    query: {
      enabled: !!me?.id && Role.PARENT !== me.role && Role.USER !== me.role,
      onSuccess: (res) => {
        if (!res?.length) {
          setTeacherKlubGroups([]);
          return;
        }

        const sorted = res
          .filter((el) => !!el.name)
          .sort((a, b) => {
            if (a?.name && b?.name) {
              return a?.name < b?.name ? -1 : 1;
            }
            return 0;
          });

        setTeacherKlubGroups(sorted);
      },
    },
  });

  const {
    refetch: refetchTeacherKlassGroups,
    error: errorTeacherKlassGroups,
    isLoading: isLoadingTeacherKlassGroups,
  } = useGroupsControllerFindKlassByUser<ResponseGroupDto[]>({
    query: {
      enabled: !!me?.id && Role.PARENT !== me.role && Role.USER !== me.role,
      onSuccess: (res) => {
        if (!res?.length) {
          setTeacherKlassGroups([]);
          return;
        }

        const sorted = res
          .filter((el) => !!el.name)
          .sort((a, b) => {
            if (a?.name && b?.name) {
              return a?.name < b?.name ? -1 : 1;
            }
            return 0;
          });

        setTeacherKlassGroups(sorted);
      },
    },
  });

  const refetchGroups = async () => {
    await refetchAllKlassGroups();
    await refetchTeacherKlubGroups();
    await refetchTeacherKlassGroups();
  };

  useEffect(() => {
    if (!me || me?.role === Role.PARENT || me?.role === Role.USER) {
      return;
    }
    refetchGroups();
  }, [me?.id]);

  return {
    teacherKlubGroups,
    teacherKlassGroups,
    errorGroups: errorTeacherKlubGroups || errorAllKlassGroups || errorTeacherKlassGroups,
    isLoadingGroups: isLoadingTeacherKlubGroups || isLoadingTeacherKlassGroups || isLoading,
    allKlassGroups: allKlassGroups || [],
    refetchGroups,
  };
};

export const GroupContainer = createContainer(groupHook);
