import { useState } from 'react';

import { createContainer } from './createContainer';
import { QueryKey } from '../constants/query-key';
import { useNoticesControllerFindAll } from '../generated/endpoint';
import { NoticeCategoryEnum } from '../generated/model';

export const useTeacherNoticeHook = () => {
  const [pageIngo, setPageInfo] = useState({ page: 1, limit: 10 });
  const [category, setCategory] = useState<NoticeCategoryEnum | '전체'>('전체');

  const {
    data: noticeList,
    isLoading: isNoticeListLoading,
    isError: isNoticeListError,
  } = useNoticesControllerFindAll(
    { ...pageIngo },
    {
      query: {
        queryKey: [...QueryKey.teacher.noticeList, pageIngo],
      },
    },
  );

  const filteredNoticeList =
    category === '전체'
      ? noticeList?.items
      : noticeList?.items.filter((el) => el.category === category);

  return {
    filteredNoticeList,
    category,
    isNoticeListLoading,
    isNoticeListError,
    setCategory,
  };
};

export const TeacherNoticeContainer = createContainer(useTeacherNoticeHook);
