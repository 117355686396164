import { useParams } from 'react-router';

import { Td } from '../../../components';
import { useTeacherNewsletterDetail } from '../../../container/teacher-newsletter-detail';
import { useTeacherNewsletterDownload } from '../../../container/teacher-newsletter-download';
import { Button, Section } from '../../../reusable';

export const NewsletterDownloadPage = () => {
  const { id } = useParams<{ id: string }>();

  const { newsletter: newData } = useTeacherNewsletterDetail({ id: +id });

  const { download, rows } = useTeacherNewsletterDownload({
    newsletterId: +id,
    surveyTitle: newData?.title,
  });

  return (
    <div className="bg-white border rounded-lg p-5">
      <div className="py-3 px-5 bg-white flex items-center space-x-2 w-full border-b border-gray-500">
        <Button text="다운로드" tw={{ height: 'h-11' }} onClick={download} />
      </div>
      <Section tw={{ marginTop: 'mt-2' }}>
        <div className="text-xl font-bold">미리보기</div>
        <table>
          <tbody>
            {rows?.map((row) => (
              <tr>
                {row.map((cell) => (
                  <Td innerClassName="min-w-max">{cell}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
    </div>
  );
};
