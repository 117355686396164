import { FC, useState, useEffect } from 'react';
import { useQuery as uQ } from 'react-query';
import { fetcher } from '../../plugins';
import { TextField, Select, TextArea, useRecoilValue, Button } from '../../reusable';
import { isValidDate, makeDateToString } from '../../utils';
import { api } from '../../plugins';
import { SuperModal } from '../';

import { UserContainer } from '../../container/user';

interface CoulselingCard {
  studentId: string;
}

export const CoulselingCard: FC<CoulselingCard> = ({ studentId }) => {
  const { me } = UserContainer.useContext(); // 왜 못가져오지..

  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);

  const [doneDate, setDoneDate] = useState(makeDateToString(new Date()));
  const [coulselor, setCoulselor] = useState('');
  const [content, setContent] = useState('');
  const [categoryObj, setCategoryObj] = useState({
    key: -1,
    name: '카테고리 선택',
  });
  const [warnMsg, setWarnMsg] = useState('');
  const [alertDelete, setAlertDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(-1);

  const { data: coulselingcategorys } = uQ(`/api/codes?category=coulseling`, fetcher);

  const { error, data, refetch } = uQ(`/api/coulseling?studentId=${studentId}`, fetcher);

  useEffect(() => {
    if (data && coulselingcategorys) {
      const selItem = coulselingcategorys.filter((item: any) => item.name === data?.category);
      if (selItem) {
        setCategoryObj(selItem[0]);
      }
    }
  }, [data, coulselingcategorys]);

  // 신규작성
  const coulselingNew = () =>
    api({
      method: 'post',
      url: '/api/coulseling',
      data: {
        studentId,
        category: categoryObj?.name ? categoryObj?.name : '미선택',
        coulselingAt: doneDate,
        coulselor,
        content,
      },
    })
      .then(() => {
        refetch();
        setIsAddMode(false);
      })
      .catch((err) => {
        console.log('err', err.message);
      });

  const coulselingDelete = (coulselingId: number) => {
    api.delete(`/api/coulseling/${coulselingId}`).then(() => {
      refetch();
    });
  };

  return (
    <div className="mb-5">
      <div className="text-xl font-semibold flex justify-between">
        <p>상담카드</p>
        <p>
          <button
            className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
            onClick={() => {
              //setCoulselor(me?.name);
              setIsAddMode(true);
            }}
          >
            상담추가
          </button>
        </p>
      </div>
      <div className="bg-white border rounded-lg p-3 relative h-full">
        {isAddMode && (
          <div className="mb-5">
            <table className="w-full">
              <tr>
                <div className="grid md:grid-cols-3">
                  <div className="flex">
                    <div className="font-semibold w-20 border-b-2 ">카테고리</div>
                    <div className="border-b-2">
                      <Select
                        value={categoryObj?.key}
                        tw={{
                          height: 'h-6',
                          borderColor: 'border-brand-1',
                        }}
                        onChange={(e) => {
                          const selItem = coulselingcategorys.filter(
                            (item: any) => item.key === Number(e.target.value),
                          );
                          selItem
                            ? setCategoryObj(selItem[0])
                            : setCategoryObj({
                                key: -1,
                                name: '학적변동없음',
                              });
                        }}
                      >
                        {coulselingcategorys.map((item: any) => (
                          <option value={item.key}>{item.name}</option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="flex md:text-center">
                    <div className="font-semibold w-20 border-b-2 pl-2">상담일</div>
                    <div className="border-b-2">
                      <TextField
                        type="date"
                        tw={{
                          height: 'h-6',
                          borderColor: 'border-brand-1',
                        }}
                        value={makeDateToString(new Date(doneDate))}
                        onChange={(e) => {
                          const selectedDate = new Date(e.target.value);
                          if (!isValidDate(selectedDate)) {
                            return;
                          }
                          setDoneDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex md:text-right">
                    <div className="font-semibold w-20 border-b-2  pl-2">상담자</div>
                    <div className="border-b-2">
                      <TextField
                        placeholder="이름 입력"
                        value={coulselor}
                        tw={{
                          height: 'h-6',
                          borderColor: 'border-brand-1',
                        }}
                        onChange={(e) => {
                          setCoulselor(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </tr>
              <tr className="mb-5">
                <td colSpan={6}>
                  <TextArea
                    placeholder="상담내용 입력"
                    tw={{ height: 'h-30', borderColor: 'border-brand-1' }}
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                  />
                </td>
              </tr>
            </table>
            <div className="text-right flex flex-row-reverse">
              {setIsAddMode && (
                <button
                  className="hover:bg-red-50 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                  onClick={() => {
                    setIsAddMode(false);
                    setWarnMsg('');
                  }}
                >
                  취소
                </button>
              )}
              <button
                className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                onClick={() => {
                  if (categoryObj?.key >= 0) {
                    if (isAddMode) {
                      coulselingNew();
                    } else {
                      setIsAddMode(true);
                    }
                  } else {
                    setWarnMsg('* 카테고리를 선택해주세요.');
                  }
                }}
              >
                저장
              </button>
              {warnMsg !== '' && <div className="text-red-500">{warnMsg}</div>}
            </div>
          </div>
        )}
        <div>
          {data?.length === 0 && !isAddMode && '상담 기록이 없습니다.'}
          {data?.map((item: any) => (
            <div className="mb-3">
              <table className="w-full">
                <tr>
                  <div className="grid md:grid-cols-3">
                    <div>카테고리 : {item?.category}</div>
                    <div className="md:text-center">상담일 : {item?.coulselingAt}</div>
                    <div className="md:text-right">상담자 : {item?.coulselor}</div>
                  </div>
                </tr>
                <tr className="mb-5">
                  <td colSpan={3}>
                    <TextArea tw={{ height: 'h-30' }} value={item?.content} disabled />
                  </td>
                </tr>
              </table>
              {false && !isAddMode && (
                <div className="text-right">
                  <button
                    className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                    onClick={() => {
                      setIsEditMode(true);
                    }}
                  >
                    수정
                  </button>
                </div>
              )}
              {item?.writerId === me?.id && !isAddMode && (
                <div className="text-right">
                  <button
                    className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-2 py-1 focus:outline-none"
                    onClick={() => {
                      setDeleteItem(item?.id);
                      setAlertDelete(true);
                    }}
                  >
                    삭제
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <SuperModal modalOpen={alertDelete} setModalClose={() => setAlertDelete(false)} width="w-max">
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            상담 기록을 삭제하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => {
              coulselingDelete(deleteItem);
              setAlertDelete(false);
            }}
          >
            삭제하기
          </Button>
        </div>
      </SuperModal>
    </div>
  );
};
