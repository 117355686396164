import React from 'react';
import CacheBuster from 'react-cache-buster';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  ActivityDetailPage,
  HomePage,
  Login,
  MyInfoPage,
  NoticeDetailPage,
  StudentMainPage,
} from './pages/student';
import { AbsentApprovalPage } from './pages/student/absent/AbsentApprovalPage';
import { ConsentForPromotionalAndMarketingPurposes } from './pages/student/ConsentForPromotionalAndMarketingPurposes';
import { ConsentToProvidePersonalInformationToThirdParties } from './pages/student/ConsentToProvidePersonalInformationToThirdParties';
import { ConsentToUseOfPersonalInformation } from './pages/student/ConsentToUseOfPersonalInformation';
import { FieldtripApprovalPage } from './pages/student/fieldtrip/FieldtripApprovalPage';
import FieldtripParentNoticePage from './pages/student/fieldtrip/FieldtripParentNoticePage';
import { FieldtripResultApprovalPage } from './pages/student/fieldtrip/FieldtripResultApprovalPage';
import { FirstLoginPage } from './pages/student/login/FirstLoginPage';
import { StudentNewsletterApprovalPage } from './pages/student/newsletter/StudentNewsletterApprovalPage';
import { AddChildrenPage } from './pages/student/parent/AddChildrenPage';
import { ParentSignupPage } from './pages/student/parent/ParentSignupPage';
import { FindIdPage } from './pages/student/password/FindIdPage';
import { FindPasswordPage } from './pages/student/password/FindPasswordPage';
import ResetPasswordPage from './pages/student/password/ResetPasswordPage';
import { PrivacyPolicy } from './pages/student/PrivacyPolicy';
import { TermsOfUse } from './pages/student/TermsOfUse';
import TeacherMainPage from './pages/teacher/TeacherMainPage';
import { AuthRoute, Blank } from './reusable';
import version from '../package.json';

import './index.css';

function App() {
  const isProduction = process.env.REACT_APP_STATUS === 'production';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Blank />} //If not pass, nothing appears at the time of new version check.
    >
      <BrowserRouter>
        <Switch>
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/privacy-policy/:schoolId" component={PrivacyPolicy} />
          <Route
            path="/consent-to-use-of-personal-information"
            component={ConsentToUseOfPersonalInformation}
          />
          <Route
            path="/consent-to-provide-personal-information-to-third-parties"
            component={ConsentToProvidePersonalInformationToThirdParties}
          />
          <Route
            path="/consent-for-promotional-and-marketing-purposes"
            component={ConsentForPromotionalAndMarketingPurposes}
          />
          <Route path="/fieldtrip/result/approve/:uuid" component={FieldtripResultApprovalPage} />
          <Route path="/fieldtrip/approve/:uuid" component={FieldtripApprovalPage} />
          <Route path="/fieldtrip/parent/notice/:uuid" component={FieldtripParentNoticePage} />
          <Route
            path="/studentnewsletter/approve/:uuid"
            component={StudentNewsletterApprovalPage}
          />
          <Route path="/absent/approve/:uuid" component={AbsentApprovalPage} />
          <Route path="/student/info" component={MyInfoPage} />
          <Route path="/student/notice/:id" component={NoticeDetailPage} />
          <Route path="/student/activity/:id" component={ActivityDetailPage} />
          <AuthRoute path="/student" component={StudentMainPage} />
          <AuthRoute path="/teacher" component={TeacherMainPage} />
          <Route path="/reset-password/:id" component={ResetPasswordPage} />
          <Route path="/find-password" component={FindPasswordPage} />
          <Route path="/find-id" component={FindIdPage} />
          <AuthRoute path="/add-child/:uuid" component={AddChildrenPage} />
          <Route path="/parent-signup" component={ParentSignupPage} />
          <Route path="/first-login" component={FirstLoginPage} />
          <AuthRoute path="/login" guestOnly component={Login} />
          <Route path="/" component={HomePage} />
        </Switch>
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
