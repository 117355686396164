import {
  TopNavbar,
  BackButton,
  Section,
  TextField,
  TextArea,
  Button,
  PhoneNumberField,
} from '../../../reusable';
import React, { useState } from 'react';
import { SuperModal } from '../../../components';

interface FieldtripSuburbsReportDetailPageProps {}

export const FieldtripSuburbsReportDetailPage: React.FC<
  FieldtripSuburbsReportDetailPageProps
> = () => {
  const [reason, setReason] = useState('');
  const [content, setContent] = useState('');
  const [parentsName, setParentsName] = useState('');
  const [parentsPhone, setParentsPhone] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [modalopen, setModalopen] = useState(false);
  return (
    <>
      {/* {loading && <Blank />}
        {isLoading && <Blank />}
        {error && <ErrorBlank />} */}
      <TopNavbar
        title="교외 체험학습 결과 보고서 확인"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section style={{ backgroundColor: '#F7F7F7' }}>
        <h1 className="text-xl font-semibold">교외 체험학습 결과 보고서</h1>
        <div className=" text-gray-600 text-xs whitespace-pre-line mb-2 text-red-600">
          제출기한 : 체험학습 종료 후 5일 이내
        </div>
        <label className="mb-1 text-sm text-gray-800">*기간</label>
        <div className="space-y-3 pb-6">
          <div>
            <div className="flex space-x-4">
              <input
                type="date"
                lang="ko-KR"
                value={startAt}
                className="px-4 w-80 mb-5 h-12 border
              border-gray-200 rounded-md sm:text-sm
              focus:ring-0 focus:border-brand-1 placeholder-gray-400
              disabled:bg-gray-100 disabled:text-gray-400 flex-1"
                onChange={(e) => setStartAt(e.target.value)}
              />
              <span className="pt-2">부터</span>
            </div>
            <div className="flex space-x-4">
              <input
                type="date"
                lang="ko-KR"
                value={endAt}
                className="px-4 w-80 h-12 border
              border-gray-200 rounded-md sm:text-sm
              focus:ring-0 focus:border-brand-1 placeholder-gray-400
              disabled:bg-gray-100 disabled:text-gray-400 flex-1"
                onChange={(e) => setEndAt(e.target.value)}
              />
              <span className="pt-2">까지</span>
            </div>
          </div>
          <div className="  text-lg whitespace-pre-line mb-2">
            남은 일수
            <span className="text-brand-1 underline">50일 중 OO일을 신청</span>
            합니다.
          </div>
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2">
            ※<span className="font-bold">토,일, 개교기념일 등 학교 휴업일</span>
            은 체험학습 신청 일수에 넣지 않음. <br /> ※ 체험학습 신청은
            신청일보다 <span className="font-bold">3일 이전에 신청</span>해야함.
          </div>
          <div className="w-full">
            <TextField
              label="*체험학습 형태"
              id="reason"
              placeholder="체험활동"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className="w-full">
            <TextField
              label="*목적지"
              id="reason"
              placeholder="목적지를 입력해주세요."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-bold text-gray-800 text-lg">
            *현장학습 결과 보고서 작성
          </div>
          <Button
            text="예시보기"
            onClick={() => setModalopen(true)}
            tw={{
              width: 'w-24',
              paddingY: 'py-2.5',
              height: 'h-10',
              paddingX: 'px-4',
            }}
          />
        </div>
        <TextArea
          placeholder="예시를 보고 내용을 입력해주세요."
          rows={5}
          style={{ height: 'auto', borderWidth: '1px' }}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="w-full">
          <TextField
            disabled
            label="보호자 이름"
            placeholder="보호자 이름을 입력해주세요."
            value={parentsName}
            onChange={(e) => setParentsName(e.target.value)}
            tw={{
              borderColor: parentsName ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            disabled
            label="보호자 연락처"
            value={parentsPhone}
            onChange={(e) => setParentsPhone(e.target.value)}
            style={{
              borderColor: !parentsPhone ? 'rgba(185, 28, 28)' : '',
            }}
          />
        </div>
      </Section>
      <SuperModal
        modalOpen={modalopen}
        setModalClose={() => setModalopen(false)}
      >
        <div className="text-center font-smibold text-brand-1 mt-5 text-lg">
          현장 학습 계획 예시
        </div>
        <div className="text-sm ml-6 mr-6 mb-6 mt-6">
          1.할머니 칠순맞이 대가족과 국내 장거리 여행
          <br />
          숙박, 식사, 관리 등 활동으로 친척과의 관계를 배울 수 있다.
          <br />
          칠순, 고희에 대해 배울 수 있다.
          <br />
          <br />
          2.통영 케이블카 탑승
          <br />
          케이블카 체허뮤하고 한려해상국립공원의 아름다운 자연을 배울 수 있다.
          <br />
          <br />
          3.식사
          <br />
          남해안과 경상도 지역의 식재료 및 음식을 알 수 있다.
        </div>

        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button
            className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold"
            onClick={() => setModalopen(false)}
          >
            닫기
          </button>
        </div>
      </SuperModal>
    </>
  );
};
