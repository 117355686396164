import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStartEndToString, makeStartEndToStringType2 } from '../../utils';

interface OutingCardProps {
  outing: any;
}

export const OutingCard: React.FC<OutingCardProps> = ({ outing }) => {
  const { pathname, search } = useLocation();

  return (
    <>
      <Link to={`/teacher/outing/${outing.id}${search}`}>
        <div
          className={
            pathname.startsWith(`/teacher/outing/${outing.id}`)
              ? 'w-full p-4 relative flex items-center justify-between bg-gray-100'
              : 'w-full p-4 relative flex items-center justify-between'
          }
        >
          <div className="flex items-center space-x-4">
            <div>
              <h3 className="text-lg font-semibold mb-1">
                {outing.student?.name} {outing.studentGradeKlass} {outing.studentNumber}번
              </h3>
              <div className="text-xs text-gray-500">
                기간 :{' '}
                {outing?.startAt &&
                  outing?.endAt &&
                  makeStartEndToStringType2(outing.startAt, outing.endAt)}
              </div>
              <div className="text-xs text-gray-500">신고일 : {outing.reportedAt}</div>
            </div>
          </div>
          <div>
            {outing?.outingStatus === 'BEFORE_TEACHER_APPROVAL' ? (
              <span className="text-red-500 rounded-md md:text-sm text-xs md:px-3 px-1 py-2 md:mr-2 mr-1 inline-block my-1">
                담임 승인 전
              </span>
            ) : outing?.outingStatus === 'PROCESSED' ? (
              <span className="text-gray-600 rounded-md md:text-sm text-xs md:px-3 px-1 py-2 md:mr-2 mr-1 inline-block my-1">
                담임 승인 완료
              </span>
            ) : (
              <span className="text-brand-1 rounded-md md:text-sm text-xs md:px-3 px-1 py-2 md:mr-2 mr-1 inline-block my-1">
                반려됨
              </span>
            )}
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
      </Link>
    </>
  );
};
