import { Button, CloseButton } from '../reusable';
import React, { FC } from 'react';

interface SuperModalProps {
  modalOpen: boolean;
  setModalClose: () => void;
  width?: string;
  ablePropragation?: boolean;
}

export const SuperModal: FC<SuperModalProps> = ({
  modalOpen,
  setModalClose,
  children,
  width = 'w-80',
  ablePropragation = false,
}) => {
  return (
    <div
      className={`fixed overflow-y-auto scroll-box py-20 inset-0 w-full h-screen z-60 bg-littleblack flex items-center justify-center ${
        !modalOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={`${width} bg-white opacity-100 rounded-lg relative`}>
        <div className="absolute top-3 right-3">
          <CloseButton onClick={() => setModalClose()} />
        </div>
        {children}
      </div>
    </div>
  );
};
