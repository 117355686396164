export const checkFileSizeLimit20MB = (files: (File | null | undefined)[]) => {
  const totalSize = files.reduce((acc, file) => {
    if (file === null || file === undefined) {
      return acc;
    }
    if (file instanceof File) {
      return acc + file.size;
    }
    return acc;
  }, 0);
  return totalSize <= 20 * 1024 * 1024;
};

export const getFileNameFromUrl = (url: string) => {
  return url.slice(url.lastIndexOf('/') + 15);
};
