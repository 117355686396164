import { FC } from 'react';

import { useAbsentsControllerDownloadAttendee } from '../../generated/endpoint';
import { Button } from '../../reusable';
import { makeDateToString } from '../../utils';
import { downloadExcel } from '../../utils/download-excel-util';

interface AttendeeInfoDownloadViewProps {
  startDate: string;
  endDate: string;
}

export const AttendeeInfoDownloadView: FC<AttendeeInfoDownloadViewProps> = ({
  startDate,
  endDate,
}) => {
  const { refetch: refetchExcelData } = useAbsentsControllerDownloadAttendee(
    {
      startDate,
      endDate,
    },
    {
      query: {
        enabled: false,
        onSuccess: (data) => {
          downloadExcel(data, `학급별출결현황 (${makeDateToString(new Date())})`);
        },
      },
    },
  );

  return (
    <Button
      tw={{
        backgroundColor: 'bg-green-400',
        minWidth: 'min-w-max',
        paddingY: 'py-2.5',
        height: 'h-auto',
        paddingX: 'px-4',
        width: 'w-full',
      }}
      onClick={() => {
        refetchExcelData();
      }}
    >
      학급별출결현황
    </Button>
  );
};
