import React from 'react';

import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { ErrorBlank } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { useTeacherNoticeAdd } from '../../../container/teacher-notice-add';
import { Notice } from '../../../generated/model';
import { Blank, Button, Label, Section, Select, TextArea, TextField } from '../../../reusable';

interface NoticeAddProps {
  noticeData?: Notice;
}

const categories = ['공지', '일정', '자료', '신청', '평가'];

export const NoticeAddPage = ({ noticeData }: NoticeAddProps) => {
  const {
    notice,
    imageObjectMap,
    documentObjectMap,
    errorMessage,
    isLoading,
    submitButtonDisabled,
    setNotice,
    handleImageAdd,
    handleDocumentAdd,
    handleImageDeleteToggle,
    handleDocumentDeleteToggle,
    handleSubmit,
  } = useTeacherNoticeAdd(noticeData);

  return (
    <div>
      {isLoading && <Blank />}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      <div>
        <TextField
          label="제목"
          id="title"
          placeholder="제목을 입력해주세요"
          onChange={(e) =>
            setNotice((prevState) => ({ ...prevState, title: e.target.value } as Notice))
          }
          value={notice?.title}
        />
      </div>
      <div>
        <Select
          label="구분"
          id="category"
          onChange={(e) =>
            setNotice((prevState) => ({ ...prevState, category: e.target.value } as Notice))
          }
          value={notice?.category}
        >
          <option selected hidden>
            구분을 선택해주세요
          </option>
          {categories.map((el) => (
            <option id={el} value={el} key={el}>
              {el}
            </option>
          ))}
        </Select>
      </div>
      <div>
        <TextArea
          label="내용"
          placeholder="내용을 입력해주세요."
          value={notice?.content}
          onChange={(e) =>
            setNotice((prevState) => ({ ...prevState, content: e.target.value } as Notice))
          }
          style={{ borderWidth: '1px' }}
        />
      </div>
      <Section>
        <div>
          <Label>이미지</Label>
          <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
            {[...imageObjectMap]?.map(([key, value]) => (
              <div key={key}>
                <ImageObjectComponent
                  id={key}
                  imageObjet={value}
                  onDeleteClick={handleImageDeleteToggle}
                />
              </div>
            ))}
            {/* 이미지 업로드 컴포넌트 */}
            <div>
              <label htmlFor={`image-upload`}>
                <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                      <FileUploadImg />
                      <div className="text-brand-1">이미지를 업로드해주세요!!</div>
                    </div>
                  </div>
                </div>
              </label>
              <input
                type="file"
                id={`image-upload`}
                className="hidden"
                accept=".pdf, .png, .jpeg, .jpg"
                onChange={handleImageAdd}
              />
            </div>
          </div>
        </div>

        <div>
          <Label>파일</Label>
          <div className="bg-white rounded border-2 border-dashed border-grey-5">
            {[...documentObjectMap]?.map(([key, value]) => (
              <div key={key}>
                <DocumentObjectComponent
                  id={key}
                  documentObjet={value}
                  onDeleteClick={handleDocumentDeleteToggle}
                />
              </div>
            ))}
            <label htmlFor="file-upload">
              <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                <span className="text-2xl text-grey-3 mb-1">+</span>
                <div className="text-brand-1">파일을 업로드해주세요 (다중 업로드 가능)</div>
              </div>
            </label>
          </div>
          <input
            type="file"
            id="file-upload"
            className="hidden"
            multiple
            onChange={handleDocumentAdd}
          />
        </div>
      </Section>
      <div className="text-center mt-6">
        <Button
          text={notice?.id ? '수정하기' : '등록하기'}
          tw={{
            backgroundColor: submitButtonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          disabled={submitButtonDisabled}
          onClick={() => {
            handleSubmit({
              notice,
              imageObjectMapParam: imageObjectMap,
              documentObjectMapParam: documentObjectMap,
            });
          }}
        />
      </div>
    </div>
  );
};
