import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import { ErrorBlank } from '../../../components';
import { GroupContainer } from '../../../container/group';
import { useTeacherTimetableV2Detail } from '../../../container/teacher-timetable-v2-detail';
import { Badge, Blank, Select } from '../../../reusable';
import { KlassInfo } from '../../../types';

const defaultKlassInfo: KlassInfo = {
  time: '',
  timeCode: '',
  myClass: false,
  subject: '',
  name: '',
  semester: 0,
  teacherName: '',
};

interface TimetableDetailV2PageProps {
  year: number;
  semester: number;
  setKlassInfo: (info: KlassInfo) => void;
}

const week = ['monday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'monday'];

export const TimetableDetailPageV2: FC<TimetableDetailV2PageProps> = ({
  year,
  semester,
  setKlassInfo,
}) => {
  const { allKlassGroups } = GroupContainer.useContext();

  const {
    teachers,
    groupId,
    teacher,
    teacherId,
    classTimeTable,
    teacherTimeTable,
    isLoading,
    error,
    changeTeacher,
    changeKlass,
  } = useTeacherTimetableV2Detail({
    year,
    semester,
  });

  const nowDay = new Date().getDay();
  const dayOfWeek = useMemo(() => week[nowDay], [nowDay]);

  const [selectedMyClass, setSelectedMyClass] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');

  const content: any[] = selectedMyClass ? classTimeTable : teacherTimeTable;
  const contentRow = content?.length;

  const day = new Date().getDay();

  const timeTableClick = (
    klassTimeCode: string,
    klassTime: string,
    klassName: string,
    klassSubject: string,
  ) => {
    setKlassInfo({
      timeCode: klassTimeCode,
      time: klassTime,
      name: klassName,
      myClass: selectedMyClass,
      subject: klassSubject,
      semester: semester,
      teacherName: teacher?.name || '',
    });
  };

  const handleTeacherSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeTeacher(+e.target.value);
    setKlassInfo(defaultKlassInfo);
    setSelectedTime('');
  };

  const handleKlassSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    changeKlass(+e.target.value);
    setKlassInfo(defaultKlassInfo);
    setSelectedTime('');
  };

  return (
    <div className="px-2 max-w-256">
      {isLoading && <Blank reversed />}
      {error && <ErrorBlank />}
      <div className="w-full flex items-center justify-center space-x-2 py-4">
        <Badge
          tw={{
            backgroundColor: !selectedMyClass ? 'bg-black' : 'bg-white',
            color: !selectedMyClass ? 'text-white' : 'text-black',
            paddingY: 'py-1.5',
          }}
          onClick={() => {
            setSelectedMyClass(false);
            setKlassInfo({
              timeCode: dayOfWeek + '_0',
              time: '',
              name: '',
              myClass: false,
              subject: '',
              semester: semester,
              teacherName: '',
            });
            setSelectedTime('');
          }}
        >
          교사별
        </Badge>
        <Badge
          tw={{
            backgroundColor: !selectedMyClass ? 'bg-white' : 'bg-black',
            color: !selectedMyClass ? 'text-black' : 'text-white',
            paddingY: 'py-1.5',
          }}
          onClick={() => {
            //history.push(`/teacher/v2timetable/myclass`);
            console.log('학급별');
            setSelectedMyClass(true);
            setKlassInfo({
              timeCode: dayOfWeek + '_0',
              time: '',
              name: '',
              myClass: false,
              subject: '',
              semester: semester,
              teacherName: '',
            });
            setSelectedTime('');
          }}
        >
          학급별
        </Badge>
      </div>

      <table className="text-center w-full mx-auto">
        <thead>
          <tr>
            <td className="w-20">
              {/* 학급별 */}
              {selectedMyClass && (
                <Select
                  value={groupId}
                  onChange={(e) => handleKlassSelectChange(e)}
                  placeholder="반 선택"
                  tw={{ paddingX: 'text-center' }}
                >
                  <option value="0">선택</option>
                  {allKlassGroups?.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name?.replace('학년 ', '-').replace('반', '')}
                    </option>
                  ))}
                </Select>
              )}

              {/* 교사별 */}
              {!selectedMyClass && (
                <Select
                  value={teacherId}
                  onChange={(e) => handleTeacherSelectChange(e)}
                  placeholder="선생님 선택"
                  tw={{ paddingX: 'text-center' }}
                >
                  <option value="0">선택</option>
                  {teachers?.map((teacher) => (
                    <option key={teacher.id} value={teacher.id}>
                      {teacher?.name}
                    </option>
                  ))}
                </Select>
              )}
            </td>
            <td className="" />
            <td
              className={`min-w-max rounded-l-xl py-4 ${
                day === 1 ? 'bg-grey-1 bg-opacity-10' : 'bg-grey-9'
              }`}
            >
              월
            </td>
            <td className={`min-w-max ${day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}>
              화
            </td>
            <td className={`min-w-max ${day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}>
              수
            </td>
            <td className={`min-w-max ${day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'}`}>
              목
            </td>
            <td
              className={`min-w-max rounded-r-xl ${
                day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-grey-9'
              }`}
            >
              금
            </td>
          </tr>
          <td className="pb-2"></td>
        </thead>
        <tbody>
          {selectedMyClass && (
            <>
              <tr>
                <td></td>
                <div className="px-0.5"></div>
                <td
                  className={`border border-white px-1 min-w-9.5 rounded-tl-xl rounded-bl-xl cursor-pointer ${
                    day === 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  }
                  ${selectedTime === 'mon' ? 'text-red-500' : ''}
                  `}
                  onClick={() => {
                    timeTableClick('monday_0', '월요일', content[0].monday.class, '조회');
                    setSelectedTime('mon');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white px-1 min-w-9.5  cursor-pointer ${
                    day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } ${selectedTime === 'tue' ? 'text-red-500' : ''}`}
                  onClick={() => {
                    timeTableClick('tuesday_0', '화요일', content[0].tuesday.class, '조회');
                    setSelectedTime('tue');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white px-1 min-w-9.5  cursor-pointer ${
                    day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } ${selectedTime === 'wed' ? 'text-red-500' : ''}`}
                  onClick={() => {
                    timeTableClick('wednesday_0', '수요일', content[0].wednesday.class, '조회');
                    setSelectedTime('wed');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white  px-1  cursor-pointer ${
                    day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } 
                  ${selectedTime === 'thu' ? 'text-red-500' : ''}
                  `}
                  onClick={() => {
                    timeTableClick('thursday_0', '목요일', content[0].thursday.class, '조회');
                    setSelectedTime('thu');
                  }}
                >
                  조회
                </td>
                <td
                  className={`border border-white  px-1 min-w-9.5 rounded-tr-xl rounded-br-xl  cursor-pointer ${
                    day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-2'
                  } 
                  ${selectedTime === 'fri' ? 'text-red-500' : ''}`}
                  onClick={() => {
                    timeTableClick('friday_0', '금요일', content[0].friday.class, '조회');
                    setSelectedTime('fri');
                  }}
                >
                  조회
                </td>
              </tr>
              <td className="pb-2"></td>
            </>
          )}

          {content &&
            content?.map((classtime: any, i: number) => (
              <tr key={i}>
                <td
                  className={`py-2 min-w-9.5 px-1 min-h-10.5 bg-grey-9
                ${i === 0 ? 'rounded-t-xl' : ''} ${i === contentRow - 1 ? 'rounded-b-xl' : ''}`}
                >
                  {i + 1} {}
                </td>
                <div className="px-0.5"></div>
                <td
                  className={`border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  }
                  ${i === 0 ? 'rounded-tl-xl border-t-0 border-l-0' : ''} 
                  ${selectedMyClass || classtime.monday.class ? 'cursor-pointer' : ''} 
                  ${i === contentRow - 1 ? 'rounded-bl-xl border-b-0 border-l-0' : ''}
                  ${selectedTime === 'mon' + i ? 'text-red-500' : ''}
                  `}
                  onClick={() => {
                    timeTableClick(
                      'monday_' + (i + 1),
                      '월요일 ' + (i + 1) + '교시',
                      classtime.monday.class,
                      classtime.monday.subject,
                    );
                    setSelectedTime('mon' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.monday.subject
                    : classtime.monday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 2 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } 
                  ${selectedTime === 'tue' + i ? 'text-red-500' : ''}
                  ${selectedMyClass || classtime.tuesday.class ? 'cursor-pointer' : ''} 
                  `}
                  onClick={() => {
                    timeTableClick(
                      'tuesday_' + (i + 1),
                      '화요일 ' + (i + 1) + '교시',
                      classtime.tuesday.class,
                      classtime.tuesday.subject,
                    );
                    setSelectedTime('tue' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.tuesday.subject
                    : classtime.tuesday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 3 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } ${selectedTime === 'wed' + i ? 'text-red-500' : ''}
                  ${selectedMyClass || classtime.wednesday.class ? 'cursor-pointer' : ''}`}
                  onClick={() => {
                    timeTableClick(
                      'wednesday_' + (i + 1),
                      '수요일 ' + (i + 1) + '교시',
                      classtime.wednesday.class,
                      classtime.wednesday.subject,
                    );
                    setSelectedTime('wed' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.wednesday.subject
                    : classtime.wednesday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 4 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } 
                  ${selectedTime === 'thu' + i ? 'text-red-500' : ''}
                  ${selectedMyClass || classtime.thursday.class ? 'cursor-pointer' : ''}
                  `}
                  onClick={() => {
                    timeTableClick(
                      'thursday_' + (i + 1),
                      '목요일 ' + (i + 1) + '교시',
                      classtime.thursday.class,
                      classtime.thursday.subject,
                    );
                    setSelectedTime('thu' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.thursday.subject
                    : classtime.thursday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
                <td
                  className={`border border-grey-50 py-2 px-1 min-w-9.5 min-h-10.5 ${
                    day === 5 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                  } 
                  ${i === 0 ? 'rounded-tr-xl border-t-0 border-r-0' : ''} ${
                    i === contentRow - 1 ? 'rounded-br-xl border-b-0 border-r-0' : ''
                  } ${selectedTime === 'fri' + i ? 'text-red-500' : ''}
                  ${selectedMyClass || classtime.friday.class ? 'cursor-pointer' : ''}`}
                  onClick={() => {
                    timeTableClick(
                      'friday_' + (i + 1),
                      '금요일 ' + (i + 1) + '교시',
                      classtime.friday.class,
                      classtime.friday.subject,
                    );
                    setSelectedTime('fri' + i);
                  }}
                >
                  {selectedMyClass
                    ? classtime.friday.subject
                    : classtime.friday.class
                        .replace('학년', '-')
                        .replace('반', '')
                        .replace(' ', '')}
                </td>
              </tr>
            ))}
          {!selectedMyClass && !content?.length && (
            <tr>
              <td colSpan={2}></td>
              <td colSpan={5}>선생님을 선택해주세요.</td>
            </tr>
          )}
          {selectedMyClass && !classTimeTable?.length && (
            <tr>
              <td colSpan={2}></td>
              <td colSpan={5}>반을 선택해주세요.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
