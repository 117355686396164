import React, { useState } from 'react';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';

import { useRecoilValue } from 'recoil';

import { NewsletterAddPage } from './NewsletterAddPage';
import { SuperSurveyComponent } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { Constants } from '../../../constants';
import { useStudentNewsletterDetail } from '../../../container/student-newsletter-detail';
import { BackButton, Blank, Button, Section, TopNavbar } from '../../../reusable';
import { meStateNew } from '../../../store';
import { makeDateToString } from '../../../utils';

export const NewsletterDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const meRecoil = useRecoilValue(meStateNew);

  const { newsletter, studentNewsletter, isLoading } = useStudentNewsletterDetail(+id);

  const [isUpdateState, setUpdateState] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const isNotParent = meRecoil?.role !== 'PARENT';

  const viewerImages: ImageDecorator[] = [];
  for (const image of newsletter?.images || []) {
    viewerImages.push({
      src: `${Constants.imageUrl}${image}`,
    });
  }

  if (isUpdateState && newsletter) {
    return (
      <NewsletterAddPage
        studentNewsletterData={studentNewsletter}
        newsletterData={newsletter}
        setUpdateState={(b: boolean) => setUpdateState(b)}
      />
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      <TopNavbar
        title="가정통신문"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div>
          <h1 className="text-2xl font-semibold">{newsletter?.title}</h1>
          <div className="text-gray-500 text-sm">
            {newsletter?.createdAt && makeDateToString(new Date(newsletter.createdAt), '.')}
          </div>
        </div>
      </Section>
      <div className="h-0.5 w-full bg-gray-100" />
      <Section>
        {newsletter?.images?.length ? (
          <>
            {newsletter.images.map((image, index) => (
              <div
                key={index}
                className="w-full"
                onClick={() => {
                  setActiveIndex(index);
                  setImagesModalOpen(true);
                }}
              >
                <ImageObjectComponent id={index} imageObjet={{ image, isDelete: false }} />
              </div>
            ))}
          </>
        ) : null}

        {newsletter?.files?.length ? (
          <>
            {newsletter.files.map((file, index) => (
              <DocumentObjectComponent
                key={index}
                id={index}
                documentObjet={{ document: file, isDelete: false }}
              />
            ))}
          </>
        ) : null}

        <div className={`text-grey-2 break-words whitespace-pre-line feedback_space`}>
          <Linkify>{newsletter?.content}</Linkify>
        </div>

        {newsletter?.type === 'STUDENT_PARENTS_SURVEY' && (
          <>
            <SuperSurveyComponent
              surveyContent={newsletter?.surveyContent || ''}
              setContent={(c: any) => {}}
              content={JSON.parse(studentNewsletter?.content || '{}')}
              readOnly
            />
            {studentNewsletter?.parentSignature && (
              <>
                <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
                  {`이 가정통신문의 답변 내용이 부모님의 서명을 받았습니다.`}
                </div>
                <div className="w-full">
                  <img
                    src={studentNewsletter?.parentSignature}
                    alt=""
                    style={{ width: '100px', marginTop: '0.5rem' }}
                  />
                </div>
              </>
            )}
            {isNotParent && <Button onClick={() => setUpdateState(true)}>수정하기</Button>}
          </>
        )}

        <div className="absolute">
          <Viewer
            visible={hasImagesModalOpen}
            rotatable={true}
            noImgDetails={true}
            scalable={false}
            images={viewerImages}
            onClose={() => {
              setImagesModalOpen(false);
            }}
            activeIndex={activeIndex}
          />
        </div>
      </Section>
    </>
  );
};
