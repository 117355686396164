import { useState } from 'react';

import { useFieldtripsControllerFindOne } from '../generated/endpoint';

export const useTeacherFieldtripNoticeDetail = (id: string) => {
  const { data: fieldtrip, isLoading } = useFieldtripsControllerFindOne(Number(id));

  return {
    isLoading,
    fieldtrip,
  };
};
