import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';

import { Constants } from '../constants';
import { QueryKey } from '../constants/query-key';
import { useNoticesControllerDelete, useNoticesControllerFindOne } from '../generated/endpoint';
import { Routes } from '../routes';

export const useTeacherNoticeDetail = (noticeId: number | undefined) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: notice, isLoading: isNoticeLoading } = useNoticesControllerFindOne(
    noticeId as number,
    {
      query: {
        enabled: !!noticeId,
        queryKey: [...QueryKey.teacher.notice, noticeId],
        onError: () => {
          setErrorMessage('공지사항을 불러오는데 실패했습니다.');
        },
      },
    },
  );

  const { mutate: deleteNotice } = useNoticesControllerDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.teacher.noticeList);
        history.push(Routes.teacher.notice);
      },
      onError: () => {
        setErrorMessage('공지사항 삭제에 실패했습니다.');
      },
    },
  });

  const images = notice?.images || [];
  const files = notice?.files || [];

  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    viewerImages.push({
      src: `${Constants.imageUrl}${image}`,
    });
  }

  const handleNoticeDelete = () => {
    deleteNotice({ id: noticeId as number });
  };

  return {
    notice,
    isNoticeLoading,
    images,
    files,
    viewerImages,
    errorMessage,
    handleNoticeDelete,
  };
};
