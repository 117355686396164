import { useHistory } from 'react-router-dom';

import { Board } from '../../generated/model';
import { ListItem, utcToLocalDatetime } from '../../reusable';

interface BoardItemProps {
  board: Board;
}

export const BoardItem = ({ board }: BoardItemProps) => {
  const history = useHistory();

  return (
    <ListItem onClick={() => history.push(`/student/board/${board.id}`)}>
      <div className="text-left flex flex-col space-y-1">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2 items-center">
            <div className="rounded-md px-3 py-1 bg-red-50 text-sm text-red-500 w-max">
              {board?.category || '학급게시판'}
            </div>
          </div>
          <div className="text-sm text-gray-500">{board?.writer?.name}</div>
        </div>
        <div className="text-lg font-bold text-grey-1">{board?.title}</div>
        <div className="text-grey-3 text-sm">
          {utcToLocalDatetime(board?.createdAt?.toString() || '')}
        </div>
      </div>
    </ListItem>
  );
};
