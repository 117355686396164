import { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { QueryKey } from '../constants/query-key';
import {
  useBoardControllerFindAll,
  useNewsLettersControllerFindAll,
  useNoticesControllerFindAll,
} from '../generated/endpoint';
import { Role } from '../generated/model';
import { childState, meStateNew } from '../store';
import { TabType } from '../types';

export const useStudentNotice = (tabType: TabType) => {
  const child = useRecoilValue(childState);
  const me = useRecoilValue(meStateNew);

  const grade =
    me?.role === Role.PARENT
      ? +(child?.klassGroupName?.charAt(0) || '0')
      : +(me?.klassGroupName?.charAt(0) || '0');

  const [pageInfo, setPageInfo] = useState({ page: 1, limit: 500 });

  const { data: noticeList, isLoading: isNoticeListLoading } = useNoticesControllerFindAll(
    { ...pageInfo },
    {
      query: {
        queryKey: [...QueryKey.teacher.noticeList, pageInfo],
        enabled: tabType === TabType.NOTICE,
      },
    },
  );

  const { data: newsLetterList, isLoading: isNewsLetterListLoading } =
    useNewsLettersControllerFindAll(
      { ...pageInfo, userKlass: [grade] },
      {
        query: {
          enabled: !!grade && tabType === TabType.NEWSLETTER,
        },
      },
    );

  const { data: boardList, isLoading: isBoardListLoading } = useBoardControllerFindAll(
    { ...pageInfo, groupId: child?.klassGroupId || me?.klassGroupId || 0 },
    {
      query: {
        enabled: (!!child?.klassGroupId || !!me?.klassGroupId) && tabType === TabType.BOARD,
      },
    },
  );

  const isLoading = !me || isNoticeListLoading || isNewsLetterListLoading || isBoardListLoading;

  return {
    noticeList: noticeList?.items,
    newsLetterList: newsLetterList?.items,
    boardList: boardList?.items,
    isLoading,
  };
};
