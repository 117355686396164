import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { BackButton, BottomFixedView, Button, Label, Section, TopNavbar } from '../../../reusable';
import { Routes } from '../../../routes';

interface FindIdSuccessPageProps {
  name: string;
  email: string;
}

export const FindIdSuccessPage = ({ name, email }: FindIdSuccessPageProps) => {
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <TopNavbar
        title="아이디 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <Section>
        <h1 className="text-2xl font-bold">{name}님이 가입한 계정</h1>
        <div>
          <Label htmlFor="foundEmail" text="이메일" />
          <div className="w-full flex items-center justify-between">
            <div className="w-full">
              <input
                id="foundEmail"
                type="text"
                className="border-gray-200 rounded-md border block h-12 px-4 placeholder-gray-400 w-full"
                ref={ref}
                value={email}
                readOnly
              />
            </div>
            <div
              className="flex items-center space-x-2 p-2 min-w-max text-brand-1 cursor-pointer"
              onClick={() => {
                ref.current?.select();
                document.execCommand('copy');
              }}
            >
              복사
            </div>
          </div>
        </div>
      </Section>
      <BottomFixedView>
        <Section>
          <Button onClick={() => history.push(Routes.auth.login)}>로그인하기</Button>
        </Section>
      </BottomFixedView>
    </>
  );
};
