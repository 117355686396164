import { ChangeEvent, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as Edit } from '../assets/svg/edit.svg';
import { Constants } from '../constants';
import { useUserControllerUpdateMe } from '../generated/endpoint';
import { ResponseUserDto, UpdateUserDto, UploadFileTypeEnum } from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';
import { Blank, Button, CloseButton, IconButton, Section } from '../reusable';
import { makeDateToString } from '../utils';
import { DateUtil } from '../utils/date-util';
import { checkFileSizeLimit20MB } from '../utils/file-util';

interface StudentIDCardProps {
  meRecoil: ResponseUserDto;
}

export const StudentIDCard = ({ meRecoil }: StudentIDCardProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [customProfile, setCustomProfile] = useState<File | string | null | undefined>(
    meRecoil.customProfile,
  );
  const [background, setBackground] = useState<File | string | null | undefined>(
    meRecoil.customBackground,
  );

  const { handleUploadFile } = useFileUpload();

  const handleBackgroundImageAdd = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedImageFiles = (e.target as HTMLInputElement).files;
    if (!selectedImageFiles || !selectedImageFiles.length) {
      return;
    }

    if (!checkFileSizeLimit20MB([selectedImageFiles[0]])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다.');
      return;
    }

    setBackground(selectedImageFiles[0]);
  };

  const handleProfileImageAdd = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedImageFiles = (e.target as HTMLInputElement).files;
    if (!selectedImageFiles || !selectedImageFiles.length) {
      return;
    }

    if (!checkFileSizeLimit20MB([selectedImageFiles[0]])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다.');
      return;
    }

    setCustomProfile(selectedImageFiles[0]);
  };

  const { mutate: updateBackgroundImagMutate, isLoading } = useUserControllerUpdateMe({
    mutation: {
      onSuccess: () => {
        setEditMode(false);
      },
    },
  });
  const handleUpdateBackgroundImage = async () => {
    if (background instanceof File) {
      const imageFileNames = await handleUploadFile(
        UploadFileTypeEnum['profiles/custombackground'],
        [background],
      );
      const payload = {
        customBackground: imageFileNames[0],
      } as UpdateUserDto;
      updateBackgroundImagMutate({
        data: payload,
      });
    }
    setEditMode(false);
  };

  const handleUpdateProfile = async () => {
    if (customProfile instanceof File) {
      const imageFileNames = await handleUploadFile(UploadFileTypeEnum['profiles'], [
        customProfile,
      ]);
      console.log('imageFileNames', imageFileNames);
      const payload = {
        customProfile: imageFileNames[0],
      } as UpdateUserDto;
      updateBackgroundImagMutate({
        data: payload,
      });
    }
    setModalOpen(false);
  };

  return (
    <>
      {isLoading && <Blank />}
      <Swiper slidesPerView={1.05} spaceBetween={10} className="mySwiper px-6 pb-5 pt-6">
        <SwiperSlide>
          <div className="card_shadow overflow-hidden relative">
            <div className="absolute left-0 right-0 z-10">
              <div className="flex items-center justify-end space-x-2 rounded-xl">
                <input
                  type="file"
                  id="bg-upload"
                  className="hidden"
                  accept=".png, .jpeg, .jpg"
                  onChange={(e) =>
                    e.target.validity.valid && setBackground(e.target.files?.item(0))
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-col relative bg-transparent bg-gradient-to-t from-littleblack h-80">
              {/* 뒷배경 */}
              {background ? (
                typeof background === 'string' ? (
                  <img
                    className="absolute -z-10 w-full h-full object-cover"
                    src={`${Constants.imageUrl}${background}`}
                    alt=""
                    loading="lazy"
                  />
                ) : (
                  <img
                    className="absolute -z-10 w-full h-full object-cover"
                    src={URL.createObjectURL(background)}
                    alt=""
                    loading="lazy"
                  />
                )
              ) : (
                <div
                  className="absolute -z-10 w-full h-full bg-center bg-no-repeat bg-cover"
                  style={{ backgroundColor: '#333D4B' }}
                />
              )}

              {/* 프로필 이미지 */}
              <div className="grid place-content-center my-12">
                <label htmlFor="profile-upload" className="z-20">
                  {customProfile ? (
                    <img
                      className="w-32 h-32 rounded-full object-cover cursor-pointer"
                      src={
                        typeof customProfile === 'string'
                          ? `${Constants.imageUrl}${customProfile}`
                          : URL.createObjectURL(customProfile)
                      }
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <div
                      className="w-32 h-32 rounded-full bg-white bg-center bg-no-repeat bg-cover cursor-pointer z-10"
                      style={{
                        backgroundImage: `url(${Constants.imageUrl}${
                          meRecoil?.customProfile || ''
                        } || ''})`,
                      }}
                    />
                  )}
                </label>
                {editMode && (
                  <input
                    id="profile-upload"
                    type="file"
                    accept=".png, .jpeg, .jpg"
                    className="hidden"
                    onChange={(e) =>
                      e.target.validity.valid && setCustomProfile(e.target.files?.item(0))
                    }
                  />
                )}
              </div>

              {/* 히딘 프로필 이름 edit */}
              <div className="flex w-full justify-between items-end p-6">
                <div>
                  <p className="text-sm text-white mb-1">
                    {meRecoil && `${meRecoil.klassGroupName || ''} ${meRecoil.studentNumber}번`}
                  </p>
                  <h1 className="text-md font-semibold text-white text-overflow-">
                    {meRecoil?.name}
                  </h1>
                </div>
                <div className="flex items-center space-x-2 pb-0.5">
                  <input
                    type="file"
                    id="bg-upload"
                    className="hidden"
                    accept=".png, .jpeg, .jpg"
                    onChange={handleBackgroundImageAdd}
                  />
                  {editMode ? (
                    <>
                      <label htmlFor="bg-upload">
                        <div className="p-2 text-white text-sm cursor-pointer bg-littleblack rounded-xl">
                          배경 업로드
                        </div>
                      </label>

                      <div
                        className="p-2 text-white text-sm cursor-pointer bg-littleblack rounded-xl"
                        onClick={handleUpdateBackgroundImage}
                      >
                        완료
                      </div>
                    </>
                  ) : (
                    <Edit className="p-0.5" onClick={() => setEditMode(true)} />
                  )}
                </div>
              </div>
            </div>
            {/* 학교 마크 */}
            <div className="px-6 h-30 flex justify-center">
              <div className="flex space-x-2 items-center justify-center font-semibold">
                {meRecoil?.school.mark && (
                  <IconButton
                    className="w-8 h-8"
                    icon={<img src={meRecoil?.school.mark} alt="" loading="lazy" />}
                  />
                )}

                <p className="text-black">{meRecoil?.school.name}</p>
                {meRecoil?.school?.stamp && (
                  <IconButton
                    className="w-8 h-8"
                    icon={<img src={meRecoil?.school.stamp} alt="" loading="lazy" />}
                  />
                )}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card_shadow overflow-hidden w-full">
            <div>
              <div
                style={{
                  backgroundColor: '#FAFAFA',
                }}
                className="w-full flex flex-col items-center h-80"
              >
                {meRecoil && (
                  <div
                    className="mt-12 mb-2 w-32 h-32 rounded-md bg-white bg-center bg-no-repeat bg-cover cursor-pointer z-10"
                    style={{ backgroundImage: `url(${meRecoil.profile})` }}
                  />
                )}

                <table>
                  <tbody>
                    <tr>
                      <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                        <p>성</p>
                        <p>명</p>
                      </td>
                      <td className="font-bold text-black tracking-widest text-sm">
                        {meRecoil?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                        <p>학</p>
                        <p>번</p>
                      </td>
                      <td className="font-bold text-black text-sm">
                        {meRecoil && `${meRecoil.klassGroupName || ''} ${meRecoil.studentNumber}번`}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="text-xs text-grey-4 pr-3">생년월일</td>
                      <td className="text-black text-sm">
                        {meRecoil?.birthDate && makeDateToString(meRecoil.birthDate, '.')}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="text-xs text-grey-4 pr-3">유효기간</td>
                      <td className="text-gray-500 text-sm">{DateUtil.getYear()}.02.28</td>
                    </tr>
                    <tr>
                      <td className="text-xs text-grey-4 pr-3 flex justify-between py-1">
                        <p>발</p>
                        <p>급</p>
                        <p>일</p>
                      </td>
                      <td className="text-gray-500 text-sm">{DateUtil.getSchoolYear()}.03.02</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="px-6 h-30 flex flex-col justify-center">
                <div>
                  <div className="text-xs text-gray-700 mb-4">
                    <p>* 위 학생은 본교 학생임을 증명합니다.</p>
                    <p>* 본 학생증을 타인에게 대여 및 양도할 수 없습니다.</p>
                  </div>

                  <div className="flex space-x-2 items-center justify-center font-semibold">
                    {meRecoil?.school.mark && (
                      <IconButton
                        className="w-8 h-8"
                        icon={
                          <img
                            src={`${Constants.imageUrl}${meRecoil?.school.mark}`}
                            alt=""
                            loading="lazy"
                          />
                        }
                      />
                    )}
                    <p className="text-black">{meRecoil?.school.name}</p>
                    {meRecoil?.school.stamp && (
                      <IconButton
                        className="w-8 h-8"
                        icon={
                          <img
                            src={`${Constants.imageUrl}${meRecoil?.school.stamp}`}
                            alt=""
                            loading="lazy"
                          />
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      {modalOpen && (
        <div
          className={`fixed inset-0 w-full h-screen z-60 bg-littleblack flex items-center justify-center`}
        >
          <div className={`w-80 bg-white opacity-100 rounded-lg relative`}>
            <div className="absolute top-3 right-3">
              <CloseButton onClick={() => setModalOpen(false)} />
            </div>
            <Section>
              <div className="w-full text-lg font-bold text-center text-gray-900">
                프로필 이미지 업데이트
              </div>

              <label htmlFor="image-upload">
                <div className="h-24 w-24 rounded-full cursor-pointer overflow-hidden relative mx-auto">
                  {customProfile ? (
                    <img
                      className="absolute w-full h-full rounded object-cover"
                      src={
                        typeof customProfile === 'string'
                          ? `${Constants.imageUrl}${customProfile}`
                          : URL.createObjectURL(customProfile)
                      }
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <div
                      className="h-24 w-24 rounded-full bg-center bg-no-repeat bg-cover bg-gray-400 cursor-pointer"
                      style={{ backgroundImage: `url(${meRecoil?.profile || ''})` }}
                    />
                  )}
                </div>
              </label>
              <div className="w-full text-center">이미지를 업로드해주세요!</div>
              <input
                type="file"
                id="image-upload"
                className="hidden"
                accept=".png, .jpeg, .jpg"
                onChange={handleProfileImageAdd}
              />
              {customProfile && (
                <Button tw={{ width: 'w-full' }} onClick={handleUpdateProfile}>
                  프로필 변경하기
                </Button>
              )}
            </Section>
          </div>
        </div>
      )}
    </>
  );
};
