import { useQueryClient } from 'react-query';

import { useSetRecoilState } from 'recoil';

import { QueryKey } from '../constants/query-key';
import { useUserControllerUpdateMe } from '../generated/endpoint';
import { UpdateUserDto } from '../generated/model';
import { isUpdateMeState } from '../store';

export const useTeacherInfoUpdate = () => {
  const queryClient = useQueryClient();
  const setIsUpdateMe = useSetRecoilState(isUpdateMeState);

  const { mutateAsync: updateMeMutateAsync, isLoading: isUpdateMeLoading } =
    useUserControllerUpdateMe({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.me);
          setIsUpdateMe(false);
        },
      },
    });

  const updateMe = async (data: Partial<UpdateUserDto>) => {
    const newData = {
      name: data.name,
      phone: data.phone,
      password: data.password,
    };
    if (!data.password) {
      delete newData.password;
    }

    await updateMeMutateAsync({ data: newData as UpdateUserDto });
  };

  return {
    isUpdateMeLoading,
    updateMe,
  };
};
