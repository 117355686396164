import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { AbsentAddPage } from './AbsentAddPage';
import { ErrorBlank } from '../../../components';
import { Constants } from '../../../constants';
import { useStudentAbsentDetail } from '../../../container/student-absent-detail';
import { UserContainer } from '../../../container/user';
import { BackButton, Blank, Button, Section, TextField, TopNavbar } from '../../../reusable';
import { makeStartEndToString } from '../../../utils';

interface MatchParams {
  id: string;
}

export const AbsentDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const history = useHistory();
  const { id } = match.params;
  const { me } = UserContainer.useContext();

  const isNotParent = me?.role !== 'PARENT';

  const { absent, error, isLoading, deleteAbsent, errorMessage } = useStudentAbsentDetail(
    Number(id),
  );

  const parentsName = me?.role === 'USER' ? me?.nokName : me?.name;

  const isReturned = absent?.absentStatus && absent.absentStatus === 'RETURNED';

  const isApprovedByParent =
    absent?.absentStatus && absent.absentStatus !== 'BEFORE_PARENT_CONFIRM';

  const isApprovedByTeacher =
    absent?.absentStatus &&
    absent.absentStatus !== 'RETURNED' &&
    absent.absentStatus !== 'BEFORE_PARENT_CONFIRM' &&
    absent.absentStatus !== 'BEFORE_TEACHER_APPROVAL';

  const isApprovedBySchool = absent?.absentStatus && absent.absentStatus === 'PROCESSED';

  const [mode, setMode] = useState(false);

  if (mode) {
    return <AbsentAddPage absentData={absent} />;
  }

  return (
    <>
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      {isLoading && <Blank />}

      <TopNavbar
        title="출결신고서 상세"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div className="pb-6">
          {isReturned && (
            <div
              className={
                'inline-block bg-red-500 text-white font-bold rounded-md text-sm px-4 py-2 mr-2 my-1'
              }
            >
              반려됨
            </div>
          )}
          <div
            className={
              isApprovedByParent
                ? 'inline-block bg-gray-500 text-white rounded-md text-sm px-4 py-2 mr-2 my-1'
                : 'inline-block bg-gray-100 text-darkgray rounded-md text-sm px-4 py-2 mr-2 my-1'
            }
          >
            학부모 승인
          </div>
          <div
            className={
              isApprovedByTeacher
                ? 'inline-block bg-gray-500 text-white rounded-md text-sm px-4 py-2 mr-2 my-1'
                : 'inline-block bg-gray-100 text-darkgray rounded-md text-sm px-4 py-2 mr-2 my-1'
            }
          >
            담임 승인
          </div>
          <div
            className={
              isApprovedBySchool
                ? 'inline-block bg-gray-500 text-white rounded-md text-sm px-4 py-2'
                : 'inline-block bg-gray-100 text-darkgray rounded-md text-sm px-4 py-2 mr-2 my-1'
            }
          >
            학교 승인
          </div>
          {isReturned && (
            <div className="mt-3">
              <div className="text-red-500">반려 이유</div>
              <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
                {absent?.notApprovedReason}
              </div>
            </div>
          )}
          {absent?.absentStatus === 'DELETE_APPEAL' && (
            <div className="mt-3">
              <div className="text-red-500">삭제 이유</div>
              <div className="rounded-lg w-full bg-red-200 px-5 py-3 whitespace-pre-line border-red-500">
                {absent?.deleteReason}
              </div>
            </div>
          )}
        </div>

        <div className="w-full">
          <TextField label="*보호자 이름" id="parentsName" value={parentsName} disabled />
        </div>
        <div className="w-full">
          <TextField label="*신고유형" value={absent?.reportType} disabled />
        </div>
        <div className="w-full">
          <label className="mb-1 text-sm text-gray-800">*발생일</label>
          <div className="flex items-center mb-3">
            {absent?.startAt &&
              absent?.endAt &&
              absent?.reportType &&
              makeStartEndToString(absent.startAt, absent.endAt, absent.reportType)}
          </div>
        </div>
        <div className="w-full">
          <TextField label="*신고사유 선택" value={absent?.reason} disabled />
        </div>
        <div className="w-full">
          <TextField
            label="*상세 내용"
            id="description"
            placeholder="상세 내용을 입력해주세요"
            value={absent?.description}
            disabled
          />
        </div>
        <h1 className="text-xl font-semibold pt-6">증빙 서류</h1>
        {absent?.evidenceType === '학부모 확인서' ? (
          '학부모 확인서'
        ) : absent?.evidenceFiles?.length ? (
          absent.evidenceFiles.map((evidenceFile: string) => (
            <img
              key={evidenceFile}
              className="w-full flex flex-col justify-center items-center space-y-1"
              src={`${Constants.imageUrl}${evidenceFile}`}
              alt=""
            />
          ))
        ) : (
          <div className="text-gray-500 pb-5">제출한 서류가 없습니다.</div>
        )}
        <br />

        {isNotParent && (
          <>
            <Button
              disabled={isApprovedByParent && !isReturned}
              onClick={() => setMode(true)}
              tw={{
                backgroundColor: isApprovedByParent && !isReturned ? 'bg-gray-300' : 'bg-brand-1',
              }}
            >
              수정하기
            </Button>

            <div className="text-red-500">{errorMessage}</div>
            {(absent?.absentStatus === 'DELETE_APPEAL' ||
              absent?.absentStatus === 'BEFORE_PARENT_CONFIRM') && (
              <Button
                onClick={() => {
                  deleteAbsent();
                }}
                tw={{
                  backgroundColor: 'bg-red-500',
                }}
              >
                삭제하기
              </Button>
            )}
          </>
        )}

        <input type="file" id="fileupload" className="hidden" accept="thumbnail/*" disabled />

        <div className="w-full h-32" />
      </Section>
    </>
  );
};
