import { useState } from 'react';

import {
  useFieldtripResultControllerApproveResultByParent,
  useFieldtripsControllerFindOneByUUID,
} from '../generated/endpoint';

type Props = {
  uuid: string;
  sigPadData: string;
};

export const useParentFieldtripResultApprove = ({ uuid, sigPadData }: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowSignModal, setIsShowSignModal] = useState(false);

  const {
    data: fieldtrip,
    isLoading: isGetFieldtrip,
    isError: isGetFieldtripError,
  } = useFieldtripsControllerFindOneByUUID(uuid, {
    query: {
      onError: (err) => {
        const message = err.message?.includes('Could not find any entity')
          ? '해당하는 체험학습 결과보고서를 찾을 수 없습니다.'
          : err.message;
        setErrorMessage(message);
      },
    },
  });

  const {
    mutateAsync: approveResultMutate,
    isLoading: isAppoveResultLoading,
    isError: isAppoveResultError,
    isSuccess: isApproveResultSuccess,
  } = useFieldtripResultControllerApproveResultByParent({
    mutation: {
      onError: (err) => {
        setErrorMessage(err.message);
      },
    },
  });

  const isLoading = isGetFieldtrip || isAppoveResultLoading;
  const isError = isGetFieldtripError || isAppoveResultError;

  const approveResult = async () => {
    await approveResultMutate({
      uuid,
      data: {
        signature: sigPadData,
      },
    });
  };

  const hideSignModal = () => {
    setIsShowSignModal(false);
  };

  const openSignModal = () => {
    setIsShowSignModal(true);
  };

  return {
    isLoading,
    isError,
    fieldtrip,
    errorMessage,
    approveResult,
    isApproveResultSuccess,
    hideSignModal,
    openSignModal,
    isShowSignModal,
  };
};
