import { UserContainer } from './user';
import { useFieldtripsControllerFindOneByUUID } from '../generated/endpoint';

export const useParentFieldtripNotice = (uuid: string) => {
  const { data: fieldtrip, isLoading, error } = useFieldtripsControllerFindOneByUUID(uuid);
  const { me } = UserContainer.useContext();
  return {
    data: fieldtrip,
    isLoading,
    error,
    me,
  };
};
