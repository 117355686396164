import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useStudentNewsletterControllerUpsert } from '../generated/endpoint';
import { RequestUpsertStudentNewsletterDto } from '../generated/model';

export const useStudentNewsletterAdd = (id: number | undefined) => {
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState('');

  const { mutate: upsertStudentNewsletterMutate, isLoading } = useStudentNewsletterControllerUpsert(
    {
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(['newsletter', id]);
          queryClient.invalidateQueries(['studentNewsletter', id]);
        },
        onError: () => {
          setErrorMessage('제출을 실패했습니다.');
        },
      },
    },
  );

  const upsertStudentNewsletter = (data: RequestUpsertStudentNewsletterDto) => {
    upsertStudentNewsletterMutate({ data });
  };

  return { upsertStudentNewsletter, isLoading, errorMessage };
};
