import { AxiosRequestConfig } from 'axios';
import { AXIOS_INSTANCE } from './custom-instance';

const getPresignedImageUrlApi = async (imageKey: string, options?: AxiosRequestConfig) => {
  const res = await AXIOS_INSTANCE.request<string>({
    url: `/api/images/presigned-url?url=${imageKey}`,
    ...options,
  });

  return res.data;
};

export { getPresignedImageUrlApi };
