import React from 'react';

import { Group } from '../generated/model';
import { Checkbox } from '../reusable';

interface AllSelectCheckboxProps {
  selectedGroups: Group[];
  setSelectedGroups: (groups: Group[]) => void;
  groups: Group[];
  label: string;
}

export const AllSelectCheckbox: React.FC<AllSelectCheckboxProps> = ({
  selectedGroups,
  setSelectedGroups,
  groups,
  label,
}) => (
  <Checkbox
    label={label}
    checked={!groups.filter((el) => !selectedGroups.map((el) => el.id).includes(el.id)).length}
    onClick={() =>
      !groups.filter((el) => !selectedGroups.map((el) => el.id).includes(el.id)).length
        ? setSelectedGroups(
            selectedGroups.filter((el) => !groups.map((el) => el.id).includes(el.id)),
          )
        : setSelectedGroups(
            selectedGroups.concat(
              groups.filter((el) => !selectedGroups.map((el) => el.id).includes(el.id)),
            ),
          )
    }
  />
);
