import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { ReactComponent as DownArrow } from '../../../assets/svg/icon-chevron-down.svg';
import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { Bridge } from '../../../plugins';
import { Section, tokenState, TopNavbar, useResetRecoilState } from '../../../reusable';
import { meStateNew } from '../../../store';

enum selectedType {
  none = 1,
  myinfo,
  question,
  manual,
  superstudy,
  logout,
}

export const TeacherMyPage = () => {
  const history = useHistory();
  const resetToken = useResetRecoilState(tokenState);
  const meRecoil = useRecoilValue(meStateNew);

  const [selectedItem, setSelectedItem] = useState(selectedType.myinfo);

  const selectedCheck = (item: selectedType) => {
    if (item === selectedItem) {
      setSelectedItem(selectedType.none);
    } else {
      setSelectedItem(item);
    }
  };

  return (
    <div className="overflow-hidden">
      <TopNavbar title="더보기" right={<div className="h-10 w-10"></div>} />

      <div className="px-3 py-3">
        <div className="py-4 px-6 mx-4 bg-gray-50 rounded-lg space-y-3">
          <div>
            <div className="font-semibold text-lg">{meRecoil?.name} 선생님</div>
            <div className="font-light text-base text-gray-500">{meRecoil?.email}</div>
          </div>
        </div>
      </div>

      <Section className="space-y-0 px-4">
        <Link to="/teacher/update">
          <div
            className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
            onClick={() => selectedCheck(selectedType.myinfo)}
          >
            <div className="text-gray-1 font-sfpro font-bold">내 정보</div>
            <RightArrow />
          </div>
        </Link>
        {/* <Link to="/student/attend">
          <div className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer">
            <div className="text-gray-1 font-sfpro font-bold">출결 신고서</div>
            <RightArrow />
          </div>
        </Link> */}
        <a href="https://superstudy.channel.io/lounge" target="blank">
          <div
            className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
            onClick={() => selectedCheck(selectedType.question)}
          >
            <div className="text-gray-1 font-sfpro font-bold">문의하기</div>
            <RightArrow />
          </div>
        </a>
        <div
          className=" py-3 border-b-2 border-gray-6 cursor-pointer"
          onClick={() => selectedCheck(selectedType.manual)}
        >
          <div className="flex justify-between items-center">
            <div className="text-gray-1 font-sfpro font-bold">슈퍼스쿨 사용법</div>
            {selectedItem === selectedType.manual ? <DownArrow /> : <RightArrow />}
          </div>
        </div>
        {selectedItem === selectedType.manual && (
          <div className=" bg-gray-100 border-b-2 border-gray-6">
            <a
              href={`https://www.notion.so/signed/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fb072a08c-df63-4eb5-84fa-2e084026f390%2Fstarting.pdf?table=block&id=9873b81d-5059-4043-9471-ad187bfd8f46&spaceId=8612a4a1-fd50-4ddd-9f79-e0df30e67f9e&name=starting.pdf&userId=625b3019-b1d4-48ee-a73f-fc5f93cb5b0a&cache=v2`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="flex justify-between items-center cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">시작하기</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%EC%B6%9C%EA%B2%B0%281%29_2022.11.01.pdf`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="flex justify-between items-center cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">출결관리</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%EC%B6%9C%EA%B2%B0%282%29_%EC%98%A8%EB%9D%BC%EC%9D%B8%EC%B6%9C%EC%84%9D%EB%B6%80_2022.11.01.pdf`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="flex justify-between items-center cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">출석부</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%EC%B2%B4%ED%97%98%ED%95%99%EC%8A%B5_2022.11.01.pdf`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="flex justify-between items-center  cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">체험학습</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%99%9C%EB%8F%99%281%29_2022.11.01.pdf`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="flex justify-between items-center  cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">활동기록부</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://superstudy-image.s3.ap-northeast-2.amazonaws.com/tutorials/%ED%8A%9C%ED%86%A0%EB%A6%AC%EC%96%BC%20%ED%95%99%EB%B6%80%EB%AA%A8%EC%9A%A9_2022.10.31.pdf`}
              target="_blank"
              rel="noreferrer"
              download
            >
              <div className="flex justify-between items-center  cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">학부모용</div>
                <RightArrow />
              </div>
            </a>
          </div>
        )}
        <div
          className="py-3 border-b-2 border-gray-6 cursor-pointer"
          onClick={() => selectedCheck(selectedType.superstudy)}
        >
          <div className="flex justify-between items-center">
            <div className="text-gray-1 font-sfpro font-bold">슈퍼스터디</div>
            {selectedItem === selectedType.superstudy ? <DownArrow /> : <RightArrow />}
          </div>
        </div>
        {selectedItem === selectedType.superstudy && (
          <div className=" bg-gray-100 border-b-2 border-gray-6">
            <a href={`https://superstudy.kr`} target="_blank" rel="noreferrer">
              <div className="flex justify-between items-center cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">홈페이지</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://www.youtube.com/channel/UCuUvswD4AMOlBnRE1jTkznA`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex justify-between items-center cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro  pl-4 font-bold">유투브</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://www.instagram.com/superstudy_official_/`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex justify-between items-center cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro  pl-4 font-bold">인스타그램</div>
                <RightArrow />
              </div>
            </a>
            <a
              href={`https://m.facebook.com/profile.php?id=100083550129006&_rdr`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex justify-between items-center  cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro  pl-4 font-bold">페이스북</div>
                <RightArrow />
              </div>
            </a>
            <a href={`https://blog.naver.com/superschool-do`} target="_blank" rel="noreferrer">
              <div className="flex justify-between items-center  cursor-pointer py-1">
                <div className="text-gray-1 font-sfpro pl-4 font-bold">블로그</div>
                <RightArrow />
              </div>
            </a>
          </div>
        )}

        <div
          className="flex justify-between items-center py-3 border-b-2 border-gray-6 cursor-pointer"
          onClick={() => {
            resetToken();
            localStorage.removeItem('token');
            Bridge.flareLane('setTags', {});
            history.push('/');
          }}
        >
          <div className="text-gray-1 font-sfpro font-bold">로그아웃</div>
          <RightArrow />
        </div>
      </Section>
    </div>
  );
};
