import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useGroupsControllerFindAllKlassBySchool } from '../generated/endpoint';
import { Group, TeacherGroup } from '../generated/model';
import { tokenState } from '../reusable';

export const useTeacherKlassGroup = () => {
  const token = useRecoilValue(tokenState);

  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(undefined);

  const { data: userGroupsData, isLoading: isUserGroupDataLoading } =
    useGroupsControllerFindAllKlassBySchool({
      query: {
        enabled: !!token,
      },
    });

  const groups = userGroupsData?.sort((a, b) => {
    const aData = a?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
    const bData = b?.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

    if (!aData || !bData) {
      return 0;
    }

    if (aData[1] && bData[1]) {
      if (aData[1] === bData[1]) {
        return Number(aData[2]) - Number(bData[2]);
      } else {
        return Number(aData[1]) - Number(bData[1]);
      }
    } else {
      return 0;
    }
  });

  const homeKlass = groups?.filter((group: Group) =>
    group.teacherGroups?.some((teacherGroup: TeacherGroup) => teacherGroup.subject === '우리반'),
  )[0];

  useEffect(() => {
    if (!selectedGroup && groups?.length) {
      if (homeKlass) {
        setSelectedGroup(homeKlass);
      } else {
        setSelectedGroup(groups[0]);
      }
    }
  }, [groups, homeKlass]);

  return {
    groups,
    selectedGroup,
    homeKlass,
    isUserGroupDataLoading,
    setSelectedGroup,
  };
};
