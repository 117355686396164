import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Board } from '../../generated/model';
import { utcToLocalDatetime } from '../../reusable';

interface BoardCardProps {
  board: Board;
  page?: number;
  limit?: number;
}

export const BoardCard = ({ board }: BoardCardProps) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  return (
    <>
      <div
        className={
          pathname === `/teacher/board/${board.id}`
            ? 'px-6 py-4 cursor-pointer bg-gray-50'
            : 'px-6 py-4 cursor-pointer'
        }
        onClick={() => {
          history.push(`/teacher/board/${board.id}${search}`);
        }}
      >
        <div className="flex justify-between">
          <div className="rounded-md px-3 py-1 bg-red-50 text-sm text-red-500 w-max">
            {board.category || '학급게시판'}
          </div>
          <div className="text-sm text-gray-500 font-light">
            {board.createdAt && utcToLocalDatetime(board.createdAt)}
          </div>
        </div>

        <div className="flex items-center justify-between space-x-2">
          <div className="text-lg font-semibold mt-2 break-all whitespace-pre-line">
            {board.title}
          </div>
          <div className="text-sm text-gray-500 min-w-max">{board.writer?.name}</div>
        </div>
      </div>
      <div className="h-0.5 bg-gray-100 w-full"></div>
    </>
  );
};
