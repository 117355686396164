import { useState } from 'react';

import { useFieldtripResultControllerUpdateResultByTeacher } from '../generated/endpoint';
import { Fieldtrip } from '../generated/model';

type UseTeacherFieldtripResultUpdateProps = {
  fieldtripId: Fieldtrip['id'] | undefined;
  destination: string;
  resultText: string;
  resultTitle: string;
  updateReason: string;
  refetchAll: () => void;
  setReadState: () => void;
};

export const useTeacherFieldtripResultUpdate = ({
  fieldtripId,
  destination,
  resultText,
  resultTitle,
  updateReason,
  refetchAll,
  setReadState,
}: UseTeacherFieldtripResultUpdateProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const { mutate, isLoading } = useFieldtripResultControllerUpdateResultByTeacher({
    mutation: {
      onSuccess: () => {
        alert('체험학습 결과보고서를 수정하였습니다.');
        refetchAll();
        setReadState();
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  const updateFieldtripResultByTeacher = () => {
    if (!fieldtripId) return;
    mutate({
      id: fieldtripId,
      data: {
        destination,
        resultText,
        resultTitle,
        updateReason,
      },
    });
  };

  return {
    errorMessage,
    updateFieldtripResultByTeacher,
    isLoading,
  };
};
