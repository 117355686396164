import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { TeacherInfoUpdatePage } from './TeacherInfoUpdatePage';
import { ErrorBlank } from '../../../components';
import { GroupContainer } from '../../../container/group';
import { BackButton, Button, Section, TextField, TopNavbar } from '../../../reusable';
import { meStateNew } from '../../../store';

export const TeacherInfoPage = () => {
  const meRecoil = useRecoilValue(meStateNew);
  const { teacherKlubGroups, errorGroups } = GroupContainer.useContext();

  const [isUpdateMe, setIsUpdateMe] = useState(false);

  if (!meRecoil || errorGroups) {
    return <ErrorBlank />;
  }

  if (isUpdateMe) {
    return <TeacherInfoUpdatePage me={meRecoil} setIsUpdateMe={setIsUpdateMe} />;
  }

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        <TopNavbar title="내 정보" left={<BackButton />} />
      </div>
      <div className="col-span-6 max-w-lg h-screen overflow-auto px-6 py-6">
        <Section>
          <h1 className="text-2xl font-semibold">내 정보</h1>
          <div />
          <div>
            <div className="text-lg font-bold text-gray-800">이름</div>
            <div className="text-grey-2">{meRecoil?.name}</div>
          </div>
          <div>
            <div className="text-lg font-bold text-gray-800">이메일</div>
            <div className="text-grey-2">{meRecoil?.email}</div>
          </div>
          <div>
            <div className="text-lg font-bold text-gray-800">전화번호</div>
            <div className="text-grey-2">{meRecoil?.phone}</div>
          </div>
          <div>
            <div className="text-lg font-bold text-gray-800">학교</div>
            <div className="text-grey-2">{meRecoil?.school.name}</div>
          </div>
          <div className="text-lg font-bold text-gray-800">과목/담당 학년</div>

          {teacherKlubGroups?.map((tg) => (
            <div key={tg.id} className="w-full flex justify-between space-x-2 items-center">
              <TextField readOnly value={tg.teacherGroupSubject || undefined} />
              <TextField readOnly value={tg.name || undefined} />
            </div>
          ))}
          <br />
          <Button onClick={() => setIsUpdateMe(true)}>수정하기</Button>
        </Section>
      </div>
    </>
  );
};
