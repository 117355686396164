import {
  useNewsLettersControllerFindOne,
  useStudentNewsletterControllerFindOne,
} from '../generated/endpoint';

export const useTeacherNewsletterSubmitDetail = (id: number, snid: number) => {
  const { data: studentNewsletter, isLoading: isStudentNewsletterLoading } =
    useStudentNewsletterControllerFindOne(snid, {
      query: {
        enabled: !!snid,
      },
    });

  const { data: newsletter, isLoading: isNewsletterLoading } = useNewsLettersControllerFindOne(id, {
    query: {
      enabled: !!id,
    },
  });

  const isLoading = isStudentNewsletterLoading || isNewsletterLoading;

  return { studentNewsletter, newsletter, isLoading };
};
