import { useRecoilValue } from 'recoil';

import { useUserControllerMeWithChildren } from '../generated/endpoint';
import { Role } from '../generated/model';
import { meStateNew } from '../store';

export const useParentGetChildrenInfo = () => {
  const meRecoil = useRecoilValue(meStateNew);

  const { data: childrenInfos } = useUserControllerMeWithChildren({
    query: {
      enabled: meRecoil?.role === Role.PARENT,
    },
  });

  return { childrenInfoList: childrenInfos?.children || [] };
};
