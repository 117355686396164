import React, { ButtonHTMLAttributes, FC } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '../IconButton';
import { BackIcon } from '../icons';

interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
}

export const BackButton = ({ onClick, ...props }: BackButtonProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return <IconButton data-cy="back" icon={<BackIcon />} onClick={handleClick} {...props} />;
};
