import React, { useState } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';

import RecordDetailPage from './RecordDetailPage';
import { ErrorBlank, SelectMenus } from '../../../components';
import { useTeacherRecord } from '../../../container/teacher-record';
import { Button } from '../../../reusable';
import { downloadExcel } from '../../../utils/download-excel-util';

const RecordPage: React.FC = () => {
  const history = useHistory();
  const [selectedSGId, setSelectedSGId] = useState(0);

  const {
    studentGroups,
    selectedGroup,
    setSelectedGroup,
    refetchStudentGroups,
    teacherKlubGroups,
    errorGroups,
    downloadRecordSummary,
    downloadRecordActivity,
  } = useTeacherRecord();

  if (errorGroups) return <ErrorBlank />;

  return (
    <div className="col-span-6 grid grid-cols-6">
      <div className="col-span-2 h-screen border-r border-gray-200 hidden md:block">
        <div className="px-6 py-6">
          <div className="flex justify-between mb-5">
            <h1 className="text-2xl font-semibold">생활기록부</h1>
          </div>
          <div className="w-max">
            <div className="mb-5 ml-3 mr-3 flex space-x-2 items-center justify-between">
              <SelectMenus
                items={teacherKlubGroups.map((tg) => ({ id: tg.id, name: tg.name }))}
                value={selectedGroup?.id?.toString()}
                allText="{학년/반}"
                onChange={(group) => setSelectedGroup(group)}
              />
            </div>
            <div>
              <div className="text-center text-gray-500">일괄 다운로드</div>
              <div className="flex space-x-2 items-center">
                <Button
                  onClick={() => {
                    downloadRecordSummary();
                  }}
                >
                  총정리
                </Button>
                <Button onClick={() => downloadRecordActivity()}>활동내역</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {/* 생기부 이름 리스트 */}
        <div className="w-full h-screen-12 overflow-y-auto scroll-box">
          <div className="w-full grid grid-flow-row grid-cols-2 gap-2 px-5">
            {studentGroups?.map((sg) => (
              <div
                key={sg.id}
                className={`rounded-lg border-2 text-center py-2 cursor-pointer ${
                  sg.id === selectedSGId && 'bg-light_orange border-brand-1'
                }`}
                onClick={() => {
                  localStorage.setItem('recordSummary', ' ');
                  setSelectedSGId(sg.id);
                  history.push(`/teacher/record/${sg?.user?.id}`);
                }}
              >
                {sg?.user?.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-4 h-screen scroll-box overflow-y-scroll">
        <Switch>
          <Route
            path="/teacher/record/:id"
            component={() => (
              <RecordDetailPage
                selectedGroup={selectedGroup}
                studentGroups={studentGroups}
                refetch={() => refetchStudentGroups()}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

// @ts-ignore
export default withRouter(RecordPage);
