import React, { useState } from 'react';
import Linkify from 'react-linkify';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';

import { useRecoilValue } from 'recoil';

import { SuperSurveyComponent } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { Constants } from '../../../constants';
import { useStudentNewsletterAdd } from '../../../container/student-newsletter-add';
import {
  Newsletter,
  RequestUpsertStudentNewsletterDto,
  StudentNewsletter,
} from '../../../generated/model';
import { useSignature } from '../../../hooks/useSignature';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  CloseButton,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { meStateNew } from '../../../store';
import { makeDateToString } from '../../../utils';

interface NewsletterAddPageProps {
  newsletterData: Newsletter;
  studentNewsletterData?: StudentNewsletter;
  setUpdateState: (b: boolean) => void;
}

export const NewsletterAddPage = ({
  studentNewsletterData,
  newsletterData,
  setUpdateState,
}: NewsletterAddPageProps) => {
  const me = useRecoilValue(meStateNew);

  const { canvasRef, sigPadData, clearSignature } = useSignature();

  const { upsertStudentNewsletter, isLoading, errorMessage } = useStudentNewsletterAdd(
    newsletterData.id,
  );

  const [content, setContent] = useState<any>(() => {
    return studentNewsletterData?.content ? JSON.parse(studentNewsletterData.content) : {};
  });
  const [nokName, setNokName] = useState(me?.nokName || ''); // 학부모 이름
  const [nokPhone, setNokPhone] = useState(me?.nokPhone || ''); // 학부모 전화번호
  const [openSignModal, setOpenSignModal] = useState(false);

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const viewerImages: ImageDecorator[] = [];
  for (const image of newsletterData?.images || []) {
    viewerImages.push({
      src: `${Constants.imageUrl}${image}`,
    });
  }

  const buttonDisabled =
    !nokPhone ||
    (newsletterData?.surveyContent
      ? Object.keys(content).length !== JSON.parse(newsletterData.surveyContent).length
      : false);

  const handleSubmit = () => {
    const data: RequestUpsertStudentNewsletterDto = {
      nokName: nokName,
      nokPhone: nokPhone,
      newsletterId: newsletterData.id,
      content: JSON.stringify(content),
      studentSignature: sigPadData,
    };

    if (studentNewsletterData) {
      upsertStudentNewsletter(data);
      setUpdateState(false);
    } else {
      setOpenSignModal(true);
    }
  };

  const handleSubmitWithSign = () => {
    const data: RequestUpsertStudentNewsletterDto = {
      nokName: nokName,
      nokPhone: nokPhone,
      newsletterId: newsletterData.id,
      content: JSON.stringify(content),
      studentSignature: sigPadData,
    };
    upsertStudentNewsletter(data);
    setUpdateState(false);
  };

  return (
    <>
      {isLoading && <Blank />}
      <TopNavbar
        title="가정통신문"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div>
          <h1 className="text-2xl font-semibold">{newsletterData?.title}</h1>
          <div className="text-gray-500 text-sm">
            {newsletterData?.createdAt && makeDateToString(new Date(newsletterData.createdAt), '.')}
          </div>
        </div>
      </Section>
      <div className="h-0.5 w-full bg-gray-100" />
      <Section>
        {newsletterData?.images?.length ? (
          <>
            {newsletterData.images.map((image, index) => (
              <div
                key={index}
                className="w-full"
                onClick={() => {
                  setActiveIndex(index);
                  setImagesModalOpen(true);
                }}
              >
                <ImageObjectComponent id={index} imageObjet={{ image, isDelete: false }} />
              </div>
            ))}
          </>
        ) : null}

        {newsletterData?.files?.length ? (
          <>
            {newsletterData.files.map((file, index) => (
              <DocumentObjectComponent
                key={index}
                id={index}
                documentObjet={{ document: file, isDelete: false }}
              />
            ))}
          </>
        ) : null}

        <div className={`text-grey-2 break-words whitespace-pre-line text-sm feedback_space`}>
          <Linkify>{newsletterData?.content}</Linkify>
        </div>

        {newsletterData?.type === 'STUDENT_PARENTS_SURVEY' && (
          <>
            <div className="w-full">
              <TextField
                disabled
                label="보호자 이름"
                value={nokName}
                onChange={(e) => setNokName(e.target.value)}
                tw={{
                  borderColor: nokName ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div>
            <div className="w-full">
              <PhoneNumberField
                disabled
                label="보호자 연락처"
                value={nokPhone}
                onChange={(e) => setNokPhone(e.target.value)}
                style={{
                  borderColor: !nokPhone ? 'rgba(185, 28, 28)' : '',
                }}
              />
            </div>
            <SuperSurveyComponent
              surveyContent={newsletterData?.surveyContent || ''}
              setContent={(c: any) => setContent(c)}
              content={content}
            />
            <Button
              disabled={buttonDisabled}
              tw={{
                backgroundColor: buttonDisabled ? 'bg-gray-400' : 'bg-brand-1',
              }}
              onClick={handleSubmit}
            >
              제출하기
            </Button>
            {errorMessage && <div className="text-red-600">{errorMessage}</div>}
          </>
        )}
      </Section>
      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div className="absolute top-2 right-3" onClick={() => setOpenSignModal(false)}>
            <CloseButton
              onClick={() => {
                setOpenSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button tw={{ width: 'w-full' }} onClick={handleSubmitWithSign}>
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          //drag={false}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};
