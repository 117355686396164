import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useTeacherNewsletterDetail } from './teacher-newsletter-detail';
import { QueryKey } from '../constants/query-key';
import {
  useNewsLettersControllerCreate,
  useNewsLettersControllerUpdate,
} from '../generated/endpoint';
import {
  Newsletter,
  NewsletterCategoryEnum,
  NewsletterType,
  UploadFileTypeEnum,
} from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';
import { useImageAndDocumentManagement } from '../hooks/useImageAndDocumentManagement';
import { Routes } from '../routes';
import { ImageObject } from '../type';
import { DocumentObject } from '../type/document-file-object';

const initNewsletter: Newsletter = {
  id: 0,
  createdAt: '',
  updatedAt: '',
  title: '',
  content: '',
  surveyContent: '',
  category: NewsletterCategoryEnum['교무'],
  images: [],
  files: [],
  klasses: [],
  isPublished: false,
  type: NewsletterType.NOTICE,
  schoolId: 0,
};

export const useTeacherNewsletterAdd = (id: string) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { newsletter: editData } = useTeacherNewsletterDetail({ id: +id });

  const [newsletter, setNewsletter] = useState<Newsletter>(editData || initNewsletter);
  const [surveyContent, setSurveyContent] = useState<any[]>(
    JSON.parse(editData?.surveyContent || '[]'),
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [category, setCategory] = useState<NewsletterCategoryEnum | undefined>(undefined);

  const {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
  } = useImageAndDocumentManagement({
    initImageObjectMapFn: () => {
      const urlImageMap = new Map<number, ImageObject>();
      editData?.images.forEach((image, index) => {
        urlImageMap.set(index, { image, isDelete: false });
      });
      return new Map(urlImageMap);
    },
    initDocumentObjectMapFn: () => {
      const urlDocumentMap = new Map<number, DocumentObject>();
      editData?.files.forEach((file, index) => {
        urlDocumentMap.set(index, { document: file, isDelete: false });
      });
      return new Map(urlDocumentMap);
    },
  });

  const { isUploadLoading, handleUploadFile } = useFileUpload();

  const { mutate: createNewsletterMutate, isLoading: isCreateNewsletterLoading } =
    useNewsLettersControllerCreate({
      mutation: {
        onSuccess: (data) => {
          queryClient.invalidateQueries(QueryKey.teacher.newsletterList);
          history.push(`${Routes.teacher.newsletter}/${data.id}`);
          console.log('useTeacherNewsletterAdd - createNewsletterMutate - onSuccess', data);
        },
        onError: () => {
          setErrorMessage('가정통신문 생성에 실패했습니다.');
        },
      },
    });

  const { mutate: updateNewsletterMutate, isLoading: isUpdateNewsletterLoading } =
    useNewsLettersControllerUpdate({
      mutation: {
        onSuccess: (data) => {
          queryClient.invalidateQueries(QueryKey.teacher.newsletterList);
          history.push(`${Routes.teacher.newsletter}/${data.id}`);
          console.log('useTeacherNewsletterAdd - updateNewsletterMutate - onSuccess', data);
        },
        onError: () => {
          setErrorMessage('가정통신문 생성에 실패했습니다.');
        },
      },
    });

  const buttonDisabled =
    !newsletter?.title?.length ||
    !newsletter?.content?.length ||
    !newsletter?.type?.length ||
    !category?.length ||
    !newsletter?.category?.length ||
    !newsletter?.klasses?.length;
  const isLoading = isCreateNewsletterLoading || isUpdateNewsletterLoading || isUploadLoading;

  const handleCheckboxToggle = (klass: '1' | '2' | '3') => {
    if (newsletter.klasses.includes(klass)) {
      setNewsletter((prevState) => ({
        ...prevState,
        klasses: prevState.klasses.filter((el) => el !== klass),
      }));
    } else {
      setNewsletter((prevState) => ({ ...prevState, klasses: [...prevState.klasses, klass] }));
    }
  };

  const handleSubmit = async ({
    newsletter,
    surveyContent,
    imageObjectMapParam,
    documentObjectMapParam,
  }: {
    newsletter: Newsletter | undefined;
    surveyContent: string;
    imageObjectMapParam: Map<number, ImageObject>;
    documentObjectMapParam: Map<number, DocumentObject>;
  }) => {
    try {
      if (!newsletter) {
        return;
      }
      // file image 처리
      const imageFiles = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.image instanceof File)
        .map((value) => value.image) as File[];
      const imageFileNames = await handleUploadFile(
        UploadFileTypeEnum['newsletters/images'],
        imageFiles,
      );

      // url image 처리
      const imageUrlNames = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.image === 'string')
        .map((value) => value.image) as string[];

      const allImageNames = [...imageUrlNames, ...imageFileNames];

      // file document 처리
      const documentFiles = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.document instanceof File)
        .map((value) => value.document) as File[];
      const documentFileNames = await handleUploadFile(
        UploadFileTypeEnum['newsletters/files'],
        documentFiles,
      );

      const documentUrlNames = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.document === 'string')
        .map((value) => value.document) as string[];

      const allDocumentNames = [...documentUrlNames, ...documentFileNames];

      if (newsletter.id) {
        const { id, title, content, category, klasses } = newsletter;
        updateNewsletterMutate({
          id,
          data: {
            title,
            content,
            surveyContent: surveyContent,
            category,
            images: allImageNames,
            files: allDocumentNames,
            klasses,
          },
        });
      } else {
        const { title, content, category, klasses, type } = newsletter;
        createNewsletterMutate({
          data: {
            title,
            content,
            surveyContent: surveyContent,
            category,
            images: allImageNames,
            files: allDocumentNames,
            klasses,
            type,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    newsletter,
    category,
    surveyContent,
    imageObjectMap,
    documentObjectMap,
    buttonDisabled,
    isLoading,
    errorMessage,
    setNewsletter,
    setCategory,
    setSurveyContent,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    handleCheckboxToggle,
    handleSubmit,
  };
};
