import { FC } from 'react';

import { useTeacherRecordCard } from '../../container/teacher-record-card';
import { StudentActivity } from '../../generated/model';
import { Blank, Button, Checkbox, Section, TextArea } from '../../reusable';
import { makeDateToString } from '../../utils';
import { ErrorBlank } from '../ErrorBlank';

interface RecordCardProps {
  record: StudentActivity;
  refetch: () => void;
  selectedSAIds: number[];
  setSelectedSAIds: (ids: number[]) => void;
}

export const RecordCard: FC<RecordCardProps> = ({
  record,
  refetch,
  selectedSAIds,
  setSelectedSAIds,
}) => {
  const activity = record?.activity;

  const { errorText, feedback, setFeedback, updateFeedback, isLoading } = useTeacherRecordCard({
    record,
    refetch,
  });

  return (
    <div className="border-gray-200 border-2 rounded-lg overflow-hidden">
      {isLoading && <Blank />}
      {errorText && <ErrorBlank text={errorText} />}
      <div className="px-6 py-3 bg-gray-50 flex items-center justify-between">
        <div>
          <div className="flex items-center space-x-2 font-semibold">
            {record.isSubmitted ? (
              <div className="min-w-7">제출</div>
            ) : (
              <div className="min-w-11 text-red-500">미제출</div>
            )}

            <a className=" text-blue-600" href={`/teacher/activity/${activity?.id}`}>
              [{activity?.subject}] {activity?.title}
            </a>
          </div>

          <h3 className="text-sm text-grey-3 mt-2">
            {makeDateToString(new Date(activity?.createdAt || ''), '.')}
          </h3>
        </div>
        <Checkbox
          className="w-6 h-6"
          checked={selectedSAIds.includes(record?.id || 0)}
          onChange={() => {
            if (record?.id) {
              if (selectedSAIds.includes(record.id)) {
                setSelectedSAIds(selectedSAIds.filter((id) => id !== record.id));
              } else {
                setSelectedSAIds(selectedSAIds.concat(record.id));
              }
            }
          }}
        />
      </div>
      <Section>
        <div>
          <h2 className="font-semibold">공통문구</h2>
          <h2 className="text-sm mt-2">{activity?.commonText}</h2>
        </div>

        <div>
          <h2 className="font-semibold">활동요약</h2>
          {record.summary && (
            <TextArea tw={{ height: 'h-30' }} readOnly value={record.summary}></TextArea>
          )}
        </div>

        <div>
          <TextArea
            label="피드백"
            placeholder="선생님이 직접 입력하는 섹션입니다."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          ></TextArea>
        </div>
        <div className="text-center mt-6">
          <Button
            text="등록하기"
            tw={{
              height: 'h-11',
            }}
            style={{ width: '70%' }}
            onClick={() => {
              updateFeedback();
            }}
          />
        </div>
      </Section>
    </div>
  );
};
