import React, { useEffect, useState } from 'react';

import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { SurveyComponent } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { useStudentActivityDetailSubmit } from '../../../container/student-activity-detail-submit';
import { Activity, StudentActivity } from '../../../generated/model';
import { RequestUpdateStudentActivityDto } from '../../../generated/model';
import { Button, Label, TextArea } from '../../../reusable';

interface ActivityDetailSubmitPageProps {
  studentActivity?: StudentActivity;
  activity?: Activity;
  setReadState: () => void;
  refetch: () => void;
  setLoading: (state: boolean) => void;
}

export const ActivityDetailSubmitPage: React.FC<ActivityDetailSubmitPageProps> = ({
  studentActivity,
  activity,
  setReadState,
  refetch,
  setLoading,
}) => {
  if (!studentActivity) {
    return null;
  }

  const {
    imageObjectMap,
    documentObjectMap,
    handleStudentActivityUpdate,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    uploadFiles,
  } = useStudentActivityDetailSubmit(studentActivity, refetch, setReadState, setLoading);

  const [content, setContent] = useState(studentActivity?.content || '');
  const [summary, setSummary] = useState(studentActivity?.summary || '');

  useEffect(() => {
    if (studentActivity?.content) {
      setContent(studentActivity?.content);
    } else if (activity) {
      const activityContent = localStorage.getItem('activityContent');
      const activityId = localStorage.getItem('activityId');
      if (activityContent && String(activity.id) === activityId) {
        setContent(activityContent);
      }
    }
  }, []);

  useEffect(() => {
    if (activity && content) {
      localStorage.setItem('activityId', String(activity.id));
      localStorage.setItem('activityContent', content);
    }
  }, [activity, content]);

  const updateSA = async () => {
    handleStudentActivityUpdate(studentActivity?.id || 0, {
      activityId: activity?.id,
      content: content.length ? content : ' ',
      isSubmitted: true,
      summary,
    } as RequestUpdateStudentActivityDto);
  };

  if (activity?.type === 'SURVEY') {
    return (
      <div className="flex flex-col items-stretch space-y-2 px-5 py-4 bg-gray-50">
        {activity?.isRecord && (
          <div className="w-full px-4">
            <Label text="활동요약" htmlFor="textarea1" />
            <div className="text-base font-semibold text-gray-600">{activity.explainText}</div>
            <textarea
              id="textarea1"
              className="border-gray-200 rounded-md border block h-48 px-4 py-2 placeholder-gray-400 w-full disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0"
              value={summary}
              onChange={(e) => {
                setSummary(e.target.value);
              }}
            />
          </div>
        )}
        <SurveyComponent
          content={activity?.content || '{}'}
          updateSA={() => {
            updateSA();
          }}
          setContent={(c: string) => setContent(JSON.stringify(c))}
          uploadFiles={(files: File[]) => {
            return uploadFiles(files);
          }}
          value={content || '[]'}
        />
      </div>
    );
  }

  const buttonDisabled = !documentObjectMap.size && !imageObjectMap.size && !content;

  return (
    <>
      <div className="flex flex-col items-stretch space-y-4 px-5 py-4 bg-gray-50">
        {activity?.isRecord && (
          <div className="w-full px-4">
            <Label text="활동요약" htmlFor="textarea1" />
            <div className="text-base font-semibold text-gray-600">{activity.explainText}</div>
            <textarea
              id="textarea1"
              className="border-gray-200 rounded-md border block h-48 px-4 py-2 placeholder-gray-400 w-full disabled:bg-gray-100 disabled:text-gray-400 focus:border-brand-1 focus:ring-0"
              value={summary}
              onChange={(e) => {
                setSummary(e.target.value);
              }}
            />
          </div>
        )}
        {activity?.isImage && (
          <div>
            <Label className="font-bold text-gray-800 text-lg">이미지 업로드</Label>
            <div className="w-full mt-5">
              {[...imageObjectMap]?.map(([key, value]) => (
                <div key={key}>
                  <ImageObjectComponent
                    id={key}
                    imageObjet={value}
                    onDeleteClick={handleImageDeleteToggle}
                  />
                </div>
              ))}
              {/* 이미지 업로드 컴포넌트 */}
              <div>
                <label htmlFor={`image-upload`}>
                  <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                    <div className="absolute w-full h-full rounded object-cover bg-white">
                      <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                        <FileUploadImg />
                        <div className="text-brand-1">이미지를 업로드해주세요!!</div>
                      </div>
                    </div>
                  </div>
                </label>
                <input
                  type="file"
                  id={`image-upload`}
                  className="hidden"
                  accept=".pdf, .png, .jpeg, .jpg"
                  onChange={handleImageAdd}
                />
              </div>
            </div>
          </div>
        )}
        {activity?.isFile && (
          <div>
            <Label className="font-bold text-gray-800 text-lg">파일 업로드</Label>
            <div className="bg-white rounded border-2 border-dashed border-grey-5 mt-5">
              {[...documentObjectMap]?.map(([key, value]) => (
                <div key={key}>
                  <DocumentObjectComponent
                    id={key}
                    documentObjet={value}
                    onDeleteClick={handleDocumentDeleteToggle}
                  />
                </div>
              ))}
              <label htmlFor="file-upload">
                <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                  <span className="text-2xl text-grey-3 mb-1">+</span>
                  <div className="text-brand-1">파일을 업로드해주세요 (다중 업로드 가능)</div>
                </div>
              </label>
            </div>
            <input
              type="file"
              id="file-upload"
              className="hidden"
              multiple
              onChange={handleDocumentAdd}
            />
          </div>
        )}
        {activity?.isContent && (
          <>
            <div className="font-bold text-gray-800 text-lg">내용 입력</div>
            <TextArea
              placeholder="내용을 입력해주세요. 작성된 활동기록부는 담당 선생님께만 노출됩니다."
              rows={5}
              style={{ height: 'auto', borderWidth: '1px' }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </>
        )}
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            setLoading(true);
            updateSA();
          }}
        >
          제출하기
        </Button>
        <br />
      </div>
    </>
  );
};
