import { useState } from 'react';

import { useActivityControllerFindActivitySubmitUsers } from '../generated/endpoint';

enum FilterType {
  All = 0,
  Submitted = 1,
  NotSubmitted = 2,
}

export const useTeacherActivitySubmit = (activityId: number) => {
  const selectedFilterIndex = Number(localStorage.getItem('selectedFilter'));
  const [filterIndex, setFilterIndex] = useState(selectedFilterIndex || 0);

  const { data, refetch, isError, isLoading } = useActivityControllerFindActivitySubmitUsers(
    activityId,
    {
      query: {
        enabled: !!activityId,
        keepPreviousData: true,
      },
    },
  );

  const filteredUser = data?.filter((user) =>
    filterIndex === FilterType.Submitted
      ? user.studentActivitySubmitted
      : filterIndex === FilterType.NotSubmitted
      ? !user.studentActivitySubmitted
      : true,
  );

  return {
    filterIndex,
    setFilterIndex,
    refetch,
    filteredUser,
    isLoading,
    isError,
  };
};
