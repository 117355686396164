/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import type {
  Absent,
  ResponsePaginatedAbsentDto,
  AbsentsControllerGetAbsentsByTeacherParams,
  AbsentsControllerGetAbsentsByGroupIdParams,
  AbsentsControllerGetAbsentsForCSVParams,
  AbsentsControllerDownloadAbsentsParams,
  AbsentsControllerDownloadAttendeeParams,
  RequestCreateAbsentDto,
  RequestUpdateAbsentDto,
  AbsentsControllerRequestDeleteBody,
  AbsentsControllerDenyBody,
  AbsentsControllerApproveByParentBody,
  AbsentsControllerApproveBody,
  AbsentsControllerSubmittedBody,
  AbsentsControllerNiceSubmittedBody,
  ResponseActivityWithSubmittedDto,
  ActivityControllerFindByTeacherParams,
  Activity,
  RequestCreateActivityDto,
  ResponseActivitySubmitUserDto,
  Attendance,
  AttendanceControllerFindAttendanceAbsentParams,
  RequestCreateAttendanceAbsentDto,
  AttendanceControllerDownloadAbsentsParams,
  ResponsePaginatedBoardDto,
  BoardControllerFindAllParams,
  Board,
  RequestCreateBoardDto,
  RequestUpdateBoardDto,
  Canteen,
  CanteenControllerFindByYearMonthParams,
  RequestUpsertCanteenDto,
  Fieldtrip,
  ResponsePaginatedFieldtripDto,
  FieldtripsControllerGetFieldtripsByTeacherParams,
  RequestCreateFieldtripDto,
  FieldtripsControllerGetResultsByTeacherParams,
  RequestUpdateFieldtripDto,
  FieldtripsControllerDenyBody,
  FieldtripsControllerApproveByParentBody,
  FieldtripsControllerApproveBody,
  RequestUpdateFieldtripResultDto,
  RequestUpdateFieldtripResultByTeacherDto,
  FieldtripResultControllerDenyResultBody,
  FieldtripResultControllerApproveResultByParentBody,
  FieldtripResultControllerApproveResultBody,
  Group,
  GroupsControllerFindAllParams,
  RequestCreateGroupDto,
  GroupsControllerFindAllBySchoolIdParams,
  ResponseGroupByIdsDto,
  GroupsControllerFindAllByIdsParams,
  ResponseGroupDto,
  GroupsControllerFindGroupByStudentIdParams,
  ImagesControllerUploadImageBody,
  UploadFileTypeEnum,
  LifeRecordControllerGetLifeActivityByStudentParams,
  ResponsePaginatedNewsletterDto,
  NewsLettersControllerFindAllParams,
  Newsletter,
  RequestCreateNewsletterDto,
  RequestUpdateNewsletterDto,
  ResponsePaginatedNoticeDto,
  NoticesControllerFindAllParams,
  Notice,
  RequestCreateNoticeDto,
  ResponsePaginatedOutingDto,
  OutingsControllerFindAllByTeacherParams,
  Outing,
  RequestCreateOutingDto,
  RequestUpdateOutingDto,
  OutingsControllerRequestDeleteBody,
  OutingsControllerDenyBody,
  OutingsControllerApproveBody,
  StudentGroup,
  StudentGroupsControllerFindAllByKlassesParams,
  Schedule,
  SchedulesControllerFindAllParams,
  RequestCreateScheduleDto,
  SchedulesControllerFindRejectScheduleParams,
  School,
  Summary,
  SummariesControllerFindByStudentGroupIdParams,
  RequestCreateSummaryDto,
  SummariesControllerUpdateBody,
  RequestUpsertStudentRoleDto,
  StudentActivity,
  StudentActivityControllerFindByUserIdParams,
  RequestUpdateStudentActivityDto,
  StudentActivityControllerUpdateFeedbackBody,
  Comment,
  RequestCreateActivityCommentDto,
  StudentActivityCommentControllerUpdateBody,
  StudentNewsletter,
  RequestApproveStudentNewsletterDto,
  RequestUpsertStudentNewsletterDto,
  TeacherGroupsControllerGetTeacherGroupsParams,
  TeacherGroup,
  Timetable,
  RequestCreateTimetableDto,
  TimetableV2,
  ResponseTimetableV2TeacherDto,
  TimetablesV2ControllerGetAllTeacherParams,
  TimetableV2Student,
  TimetablesV2ControllerFindOneByStudentParams,
  TimetablesV2ControllerUpdateSeatPositionBody,
  ResponseFindIdDto,
  UserControllerFindIdParams,
  UserControllerFindPasswordParams,
  RequestResetPasswordDto,
  AuthResponse,
  RequestLoginDto,
  ParentDto,
  ResponseUserDto,
  UpdateUserDto,
  RequestUpdateMyInfoAtFirstLoginDto,
  UserControllerSendParentSignUpStudentIdParams,
  RequestParentSignUpDto,
  ResponseUserAttendanceDto,
  UserControllerGetUserAttendanceParams,
  UserControllerUpdateStudentParams,
  UserControllerUpdateparentParams,
  UserControllerGetAttendanceBookParams,
  UserControllerGetStudentInfoParams,
  UserControllerGetparentInfoParams,
  CoulselingControllerGetBoardsParams,
  SaveChatDto,
  SaveChatRoomDto,
  SaveChatSettingDto,
  CodesControllerGetBoardsParams,
} from './model';
import { customInstance } from '../plugins/custom-instance';
import type { ErrorType } from '../plugins/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 출결 신고서 목록(학생용)
 */
export const absentsControllerFindAllByStudent = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Absent[]>(
    { url: `/api/absents/list-by-student`, method: 'get', signal },
    options,
  );
};

export const getAbsentsControllerFindAllByStudentQueryKey = () => [`/api/absents/list-by-student`];

export type AbsentsControllerFindAllByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerFindAllByStudent>>
>;
export type AbsentsControllerFindAllByStudentQueryError = ErrorType<unknown>;

export const useAbsentsControllerFindAllByStudent = <
  TData = Awaited<ReturnType<typeof absentsControllerFindAllByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof absentsControllerFindAllByStudent>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsControllerFindAllByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsControllerFindAllByStudent>>> = ({
    signal,
  }) => absentsControllerFindAllByStudent(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof absentsControllerFindAllByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 목록(선생님용), 레거시 거의 그대로 유지
 */
export const absentsControllerGetAbsentsByTeacher = (
  params: AbsentsControllerGetAbsentsByTeacherParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedAbsentDto>(
    { url: `/api/absents/teacher`, method: 'get', params, signal },
    options,
  );
};

export const getAbsentsControllerGetAbsentsByTeacherQueryKey = (
  params: AbsentsControllerGetAbsentsByTeacherParams,
) => [`/api/absents/teacher`, ...(params ? [params] : [])];

export type AbsentsControllerGetAbsentsByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerGetAbsentsByTeacher>>
>;
export type AbsentsControllerGetAbsentsByTeacherQueryError = ErrorType<unknown>;

export const useAbsentsControllerGetAbsentsByTeacher = <
  TData = Awaited<ReturnType<typeof absentsControllerGetAbsentsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsControllerGetAbsentsByTeacherParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof absentsControllerGetAbsentsByTeacher>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAbsentsControllerGetAbsentsByTeacherQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof absentsControllerGetAbsentsByTeacher>>
  > = ({ signal }) => absentsControllerGetAbsentsByTeacher(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof absentsControllerGetAbsentsByTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 목록 그룹아이디로 조회(선생님용), 레거시 거의 그대로 유지
 */
export const absentsControllerGetAbsentsByGroupId = (
  params: AbsentsControllerGetAbsentsByGroupIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedAbsentDto>(
    { url: `/api/absents/groupid`, method: 'get', params, signal },
    options,
  );
};

export const getAbsentsControllerGetAbsentsByGroupIdQueryKey = (
  params: AbsentsControllerGetAbsentsByGroupIdParams,
) => [`/api/absents/groupid`, ...(params ? [params] : [])];

export type AbsentsControllerGetAbsentsByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerGetAbsentsByGroupId>>
>;
export type AbsentsControllerGetAbsentsByGroupIdQueryError = ErrorType<unknown>;

export const useAbsentsControllerGetAbsentsByGroupId = <
  TData = Awaited<ReturnType<typeof absentsControllerGetAbsentsByGroupId>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsControllerGetAbsentsByGroupIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof absentsControllerGetAbsentsByGroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAbsentsControllerGetAbsentsByGroupIdQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof absentsControllerGetAbsentsByGroupId>>
  > = ({ signal }) => absentsControllerGetAbsentsByGroupId(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof absentsControllerGetAbsentsByGroupId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 출결 신고서 csv 목록(선생님용), 레거시 거의 그대로 유지
 */
export const absentsControllerGetAbsentsForCSV = (
  params: AbsentsControllerGetAbsentsForCSVParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedAbsentDto>(
    { url: `/api/absents/teacher/csv`, method: 'get', params, signal },
    options,
  );
};

export const getAbsentsControllerGetAbsentsForCSVQueryKey = (
  params: AbsentsControllerGetAbsentsForCSVParams,
) => [`/api/absents/teacher/csv`, ...(params ? [params] : [])];

export type AbsentsControllerGetAbsentsForCSVQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerGetAbsentsForCSV>>
>;
export type AbsentsControllerGetAbsentsForCSVQueryError = ErrorType<unknown>;

export const useAbsentsControllerGetAbsentsForCSV = <
  TData = Awaited<ReturnType<typeof absentsControllerGetAbsentsForCSV>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsControllerGetAbsentsForCSVParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof absentsControllerGetAbsentsForCSV>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsControllerGetAbsentsForCSVQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsControllerGetAbsentsForCSV>>> = ({
    signal,
  }) => absentsControllerGetAbsentsForCSV(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof absentsControllerGetAbsentsForCSV>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 엑셀 다운로드(선생님용)
 */
export const absentsControllerDownloadAbsents = (
  params: AbsentsControllerDownloadAbsentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    { url: `/api/absents/download`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getAbsentsControllerDownloadAbsentsQueryKey = (
  params: AbsentsControllerDownloadAbsentsParams,
) => [`/api/absents/download`, ...(params ? [params] : [])];

export type AbsentsControllerDownloadAbsentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerDownloadAbsents>>
>;
export type AbsentsControllerDownloadAbsentsQueryError = ErrorType<unknown>;

export const useAbsentsControllerDownloadAbsents = <
  TData = Awaited<ReturnType<typeof absentsControllerDownloadAbsents>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsControllerDownloadAbsentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof absentsControllerDownloadAbsents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsControllerDownloadAbsentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsControllerDownloadAbsents>>> = ({
    signal,
  }) => absentsControllerDownloadAbsents(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof absentsControllerDownloadAbsents>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급별 출결 현황 다운로드(선생님용)
 */
export const absentsControllerDownloadAttendee = (
  params: AbsentsControllerDownloadAttendeeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    {
      url: `/api/absents/teacher/download/attendee`,
      method: 'get',
      params,
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getAbsentsControllerDownloadAttendeeQueryKey = (
  params: AbsentsControllerDownloadAttendeeParams,
) => [`/api/absents/teacher/download/attendee`, ...(params ? [params] : [])];

export type AbsentsControllerDownloadAttendeeQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerDownloadAttendee>>
>;
export type AbsentsControllerDownloadAttendeeQueryError = ErrorType<unknown>;

export const useAbsentsControllerDownloadAttendee = <
  TData = Awaited<ReturnType<typeof absentsControllerDownloadAttendee>>,
  TError = ErrorType<unknown>,
>(
  params: AbsentsControllerDownloadAttendeeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof absentsControllerDownloadAttendee>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsControllerDownloadAttendeeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsControllerDownloadAttendee>>> = ({
    signal,
  }) => absentsControllerDownloadAttendee(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof absentsControllerDownloadAttendee>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * TODO /teacher/:id endpoint 제거 필요
 * @summary 출결 신고서 단건 조회
 */
export const absentsControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Absent>({ url: `/api/absents/${id}`, method: 'get', signal }, options);
};

export const getAbsentsControllerFindOneQueryKey = (id: number) => [`/api/absents/${id}`];

export type AbsentsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerFindOne>>
>;
export type AbsentsControllerFindOneQueryError = ErrorType<void>;

export const useAbsentsControllerFindOne = <
  TData = Awaited<ReturnType<typeof absentsControllerFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof absentsControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsControllerFindOne>>> = ({
    signal,
  }) => absentsControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 수정
 */
export const absentsControllerUpdate = (
  id: number,
  requestCreateAbsentDto: RequestCreateAbsentDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Absent>(
    {
      url: `/api/absents/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAbsentDto,
    },
    options,
  );
};

export type AbsentsControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerUpdate>>
>;
export type AbsentsControllerUpdateMutationBody = RequestCreateAbsentDto;
export type AbsentsControllerUpdateMutationError = ErrorType<void>;

export const useAbsentsControllerUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerUpdate>>,
    { id: number; data: RequestCreateAbsentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 삭제
 */
export const absentsControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/absents/${id}`, method: 'delete' }, options);
};

export type AbsentsControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerDelete>>
>;

export type AbsentsControllerDeleteMutationError = ErrorType<unknown>;

export const useAbsentsControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return absentsControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 단건 조회(by UUID)
 */
export const absentsControllerFindOneByUUID = (
  uuid: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Absent>(
    { url: `/api/absents/by-uuid/${uuid}`, method: 'get', signal },
    options,
  );
};

export const getAbsentsControllerFindOneByUUIDQueryKey = (uuid: string) => [
  `/api/absents/by-uuid/${uuid}`,
];

export type AbsentsControllerFindOneByUUIDQueryResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerFindOneByUUID>>
>;
export type AbsentsControllerFindOneByUUIDQueryError = ErrorType<unknown>;

export const useAbsentsControllerFindOneByUUID = <
  TData = Awaited<ReturnType<typeof absentsControllerFindOneByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof absentsControllerFindOneByUUID>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAbsentsControllerFindOneByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof absentsControllerFindOneByUUID>>> = ({
    signal,
  }) => absentsControllerFindOneByUUID(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof absentsControllerFindOneByUUID>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!uuid, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출결 신고서 추가
 */
export const absentsControllerCreate = (
  requestCreateAbsentDto: RequestCreateAbsentDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Absent>(
    {
      url: `/api/absents`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAbsentDto,
    },
    options,
  );
};

export type AbsentsControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerCreate>>
>;
export type AbsentsControllerCreateMutationBody = RequestCreateAbsentDto;
export type AbsentsControllerCreateMutationError = ErrorType<void>;

export const useAbsentsControllerCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerCreate>>,
    TError,
    { data: RequestCreateAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerCreate>>,
    { data: RequestCreateAbsentDto }
  > = (props) => {
    const { data } = props ?? {};

    return absentsControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerCreate>>,
    TError,
    { data: RequestCreateAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 수정(선생님용)
 */
export const absentsControllerUpdateByTeacher = (
  id: number,
  requestUpdateAbsentDto: RequestUpdateAbsentDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Absent>(
    {
      url: `/api/absents/update-by-teacher/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateAbsentDto,
    },
    options,
  );
};

export type AbsentsControllerUpdateByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerUpdateByTeacher>>
>;
export type AbsentsControllerUpdateByTeacherMutationBody = RequestUpdateAbsentDto;
export type AbsentsControllerUpdateByTeacherMutationError = ErrorType<void>;

export const useAbsentsControllerUpdateByTeacher = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerUpdateByTeacher>>,
    { id: number; data: RequestUpdateAbsentDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerUpdateByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 삭제요청
 */
export const absentsControllerRequestDelete = (
  id: number,
  absentsControllerRequestDeleteBody: AbsentsControllerRequestDeleteBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Absent>(
    {
      url: `/api/absents/request-delete/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsControllerRequestDeleteBody,
    },
    options,
  );
};

export type AbsentsControllerRequestDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerRequestDelete>>
>;
export type AbsentsControllerRequestDeleteMutationBody = AbsentsControllerRequestDeleteBody;
export type AbsentsControllerRequestDeleteMutationError = ErrorType<void>;

export const useAbsentsControllerRequestDelete = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerRequestDelete>>,
    TError,
    { id: number; data: AbsentsControllerRequestDeleteBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerRequestDelete>>,
    { id: number; data: AbsentsControllerRequestDeleteBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerRequestDelete(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerRequestDelete>>,
    TError,
    { id: number; data: AbsentsControllerRequestDeleteBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 반려
 */
export const absentsControllerDeny = (
  id: number,
  absentsControllerDenyBody: AbsentsControllerDenyBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/absents/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsControllerDenyBody,
    },
    options,
  );
};

export type AbsentsControllerDenyMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerDeny>>
>;
export type AbsentsControllerDenyMutationBody = AbsentsControllerDenyBody;
export type AbsentsControllerDenyMutationError = ErrorType<unknown>;

export const useAbsentsControllerDeny = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerDeny>>,
    TError,
    { id: number; data: AbsentsControllerDenyBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerDeny>>,
    { id: number; data: AbsentsControllerDenyBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerDeny(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerDeny>>,
    TError,
    { id: number; data: AbsentsControllerDenyBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 승인(부모님)
 */
export const absentsControllerApproveByParent = (
  uuid: string,
  absentsControllerApproveByParentBody: AbsentsControllerApproveByParentBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/absents/approve-by-parent/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsControllerApproveByParentBody,
    },
    options,
  );
};

export type AbsentsControllerApproveByParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerApproveByParent>>
>;
export type AbsentsControllerApproveByParentMutationBody = AbsentsControllerApproveByParentBody;
export type AbsentsControllerApproveByParentMutationError = ErrorType<unknown>;

export const useAbsentsControllerApproveByParent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerApproveByParent>>,
    TError,
    { uuid: string; data: AbsentsControllerApproveByParentBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerApproveByParent>>,
    { uuid: string; data: AbsentsControllerApproveByParentBody }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return absentsControllerApproveByParent(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerApproveByParent>>,
    TError,
    { uuid: string; data: AbsentsControllerApproveByParentBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 승인
 */
export const absentsControllerApprove = (
  id: number,
  absentsControllerApproveBody: AbsentsControllerApproveBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/absents/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsControllerApproveBody,
    },
    options,
  );
};

export type AbsentsControllerApproveMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerApprove>>
>;
export type AbsentsControllerApproveMutationBody = AbsentsControllerApproveBody;
export type AbsentsControllerApproveMutationError = ErrorType<unknown>;

export const useAbsentsControllerApprove = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerApprove>>,
    TError,
    { id: number; data: AbsentsControllerApproveBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerApprove>>,
    { id: number; data: AbsentsControllerApproveBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerApprove(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerApprove>>,
    TError,
    { id: number; data: AbsentsControllerApproveBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 증빙서류 확인 체크
 */
export const absentsControllerSubmitted = (
  id: number,
  absentsControllerSubmittedBody: AbsentsControllerSubmittedBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/absents/submitted/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsControllerSubmittedBody,
    },
    options,
  );
};

export type AbsentsControllerSubmittedMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerSubmitted>>
>;
export type AbsentsControllerSubmittedMutationBody = AbsentsControllerSubmittedBody;
export type AbsentsControllerSubmittedMutationError = ErrorType<unknown>;

export const useAbsentsControllerSubmitted = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerSubmitted>>,
    TError,
    { id: number; data: AbsentsControllerSubmittedBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerSubmitted>>,
    { id: number; data: AbsentsControllerSubmittedBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerSubmitted(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerSubmitted>>,
    TError,
    { id: number; data: AbsentsControllerSubmittedBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출결 신고서 나이스 확인 체크
 */
export const absentsControllerNiceSubmitted = (
  id: number,
  absentsControllerNiceSubmittedBody: AbsentsControllerNiceSubmittedBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/absents/nice-submitted/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: absentsControllerNiceSubmittedBody,
    },
    options,
  );
};

export type AbsentsControllerNiceSubmittedMutationResult = NonNullable<
  Awaited<ReturnType<typeof absentsControllerNiceSubmitted>>
>;
export type AbsentsControllerNiceSubmittedMutationBody = AbsentsControllerNiceSubmittedBody;
export type AbsentsControllerNiceSubmittedMutationError = ErrorType<unknown>;

export const useAbsentsControllerNiceSubmitted = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof absentsControllerNiceSubmitted>>,
    TError,
    { id: number; data: AbsentsControllerNiceSubmittedBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof absentsControllerNiceSubmitted>>,
    { id: number; data: AbsentsControllerNiceSubmittedBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return absentsControllerNiceSubmitted(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof absentsControllerNiceSubmitted>>,
    TError,
    { id: number; data: AbsentsControllerNiceSubmittedBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 조회(학생용)
 */
export const activityControllerFindByStudent = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseActivityWithSubmittedDto[]>(
    { url: `/api/activities/by-student`, method: 'get', signal },
    options,
  );
};

export const getActivityControllerFindByStudentQueryKey = () => [`/api/activities/by-student`];

export type ActivityControllerFindByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerFindByStudent>>
>;
export type ActivityControllerFindByStudentQueryError = ErrorType<void>;

export const useActivityControllerFindByStudent = <
  TData = Awaited<ReturnType<typeof activityControllerFindByStudent>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof activityControllerFindByStudent>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityControllerFindByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityControllerFindByStudent>>> = ({
    signal,
  }) => activityControllerFindByStudent(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof activityControllerFindByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(선생님용)
 */
export const activityControllerFindByTeacher = (
  params: ActivityControllerFindByTeacherParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseActivityWithSubmittedDto[]>(
    { url: `/api/activities/by-teacher`, method: 'get', params, signal },
    options,
  );
};

export const getActivityControllerFindByTeacherQueryKey = (
  params: ActivityControllerFindByTeacherParams,
) => [`/api/activities/by-teacher`, ...(params ? [params] : [])];

export type ActivityControllerFindByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerFindByTeacher>>
>;
export type ActivityControllerFindByTeacherQueryError = ErrorType<void>;

export const useActivityControllerFindByTeacher = <
  TData = Awaited<ReturnType<typeof activityControllerFindByTeacher>>,
  TError = ErrorType<void>,
>(
  params: ActivityControllerFindByTeacherParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityControllerFindByTeacher>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityControllerFindByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityControllerFindByTeacher>>> = ({
    signal,
  }) => activityControllerFindByTeacher(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof activityControllerFindByTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 조회(그룹 아이디로)
 */
export const activityControllerFindByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Activity[]>(
    { url: `/api/activities/by-group-id/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getActivityControllerFindByGroupIdQueryKey = (groupId: number) => [
  `/api/activities/by-group-id/${groupId}`,
];

export type ActivityControllerFindByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerFindByGroupId>>
>;
export type ActivityControllerFindByGroupIdQueryError = ErrorType<void>;

export const useActivityControllerFindByGroupId = <
  TData = Awaited<ReturnType<typeof activityControllerFindByGroupId>>,
  TError = ErrorType<void>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityControllerFindByGroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityControllerFindByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityControllerFindByGroupId>>> = ({
    signal,
  }) => activityControllerFindByGroupId(groupId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof activityControllerFindByGroupId>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!groupId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 단건 조회
 */
export const activityControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Activity>({ url: `/api/activities/${id}`, method: 'get', signal }, options);
};

export const getActivityControllerFindOneQueryKey = (id: number) => [`/api/activities/${id}`];

export type ActivityControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerFindOne>>
>;
export type ActivityControllerFindOneQueryError = ErrorType<void>;

export const useActivityControllerFindOne = <
  TData = Awaited<ReturnType<typeof activityControllerFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof activityControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityControllerFindOne>>> = ({
    signal,
  }) => activityControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof activityControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 수정
 */
export const activityControllerUpdate = (
  id: number,
  requestCreateActivityDto: RequestCreateActivityDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Activity>(
    {
      url: `/api/activities/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityDto,
    },
    options,
  );
};

export type ActivityControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerUpdate>>
>;
export type ActivityControllerUpdateMutationBody = RequestCreateActivityDto;
export type ActivityControllerUpdateMutationError = ErrorType<void>;

export const useActivityControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateActivityDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityControllerUpdate>>,
    { id: number; data: RequestCreateActivityDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return activityControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateActivityDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 삭제
 */
export const activityControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/activities/${id}`, method: 'delete' }, options);
};

export type ActivityControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerDelete>>
>;

export type ActivityControllerDeleteMutationError = ErrorType<unknown>;

export const useActivityControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return activityControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 제출자 목록 조회
 */
export const activityControllerFindActivitySubmitUsers = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseActivitySubmitUserDto[]>(
    { url: `/api/activities/get-submit-users/${id}`, method: 'get', signal },
    options,
  );
};

export const getActivityControllerFindActivitySubmitUsersQueryKey = (id: number) => [
  `/api/activities/get-submit-users/${id}`,
];

export type ActivityControllerFindActivitySubmitUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerFindActivitySubmitUsers>>
>;
export type ActivityControllerFindActivitySubmitUsersQueryError = ErrorType<unknown>;

export const useActivityControllerFindActivitySubmitUsers = <
  TData = Awaited<ReturnType<typeof activityControllerFindActivitySubmitUsers>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityControllerFindActivitySubmitUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getActivityControllerFindActivitySubmitUsersQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof activityControllerFindActivitySubmitUsers>>
  > = ({ signal }) => activityControllerFindActivitySubmitUsers(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof activityControllerFindActivitySubmitUsers>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 활동 추가
 */
export const activityControllerCreate = (
  requestCreateActivityDto: RequestCreateActivityDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Activity>(
    {
      url: `/api/activities`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityDto,
    },
    options,
  );
};

export type ActivityControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerCreate>>
>;
export type ActivityControllerCreateMutationBody = RequestCreateActivityDto;
export type ActivityControllerCreateMutationError = ErrorType<void>;

export const useActivityControllerCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activityControllerCreate>>,
    TError,
    { data: RequestCreateActivityDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activityControllerCreate>>,
    { data: RequestCreateActivityDto }
  > = (props) => {
    const { data } = props ?? {};

    return activityControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof activityControllerCreate>>,
    TError,
    { data: RequestCreateActivityDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 활동 다운로드
 */
export const activityControllerDownloadSubmitters = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    { url: `/api/activities/download/${id}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getActivityControllerDownloadSubmittersQueryKey = (id: number) => [
  `/api/activities/download/${id}`,
];

export type ActivityControllerDownloadSubmittersQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityControllerDownloadSubmitters>>
>;
export type ActivityControllerDownloadSubmittersQueryError = ErrorType<unknown>;

export const useActivityControllerDownloadSubmitters = <
  TData = Awaited<ReturnType<typeof activityControllerDownloadSubmitters>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityControllerDownloadSubmitters>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActivityControllerDownloadSubmittersQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof activityControllerDownloadSubmitters>>
  > = ({ signal }) => activityControllerDownloadSubmitters(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof activityControllerDownloadSubmitters>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출석부 출결 조회
 */
export const attendanceControllerFindAttendanceAbsent = (
  params: AttendanceControllerFindAttendanceAbsentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Attendance[]>(
    { url: `/api/attendance/absent`, method: 'get', params, signal },
    options,
  );
};

export const getAttendanceControllerFindAttendanceAbsentQueryKey = (
  params: AttendanceControllerFindAttendanceAbsentParams,
) => [`/api/attendance/absent`, ...(params ? [params] : [])];

export type AttendanceControllerFindAttendanceAbsentQueryResult = NonNullable<
  Awaited<ReturnType<typeof attendanceControllerFindAttendanceAbsent>>
>;
export type AttendanceControllerFindAttendanceAbsentQueryError = ErrorType<unknown>;

export const useAttendanceControllerFindAttendanceAbsent = <
  TData = Awaited<ReturnType<typeof attendanceControllerFindAttendanceAbsent>>,
  TError = ErrorType<unknown>,
>(
  params: AttendanceControllerFindAttendanceAbsentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof attendanceControllerFindAttendanceAbsent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAttendanceControllerFindAttendanceAbsentQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof attendanceControllerFindAttendanceAbsent>>
  > = ({ signal }) => attendanceControllerFindAttendanceAbsent(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof attendanceControllerFindAttendanceAbsent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 출석부 출결 등록(마지막 등록된게 유효)
 */
export const attendanceControllerCreateAttendanceAbsent = (
  requestCreateAttendanceAbsentDto: RequestCreateAttendanceAbsentDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<boolean>(
    {
      url: `/api/attendance/absent`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateAttendanceAbsentDto,
    },
    options,
  );
};

export type AttendanceControllerCreateAttendanceAbsentMutationResult = NonNullable<
  Awaited<ReturnType<typeof attendanceControllerCreateAttendanceAbsent>>
>;
export type AttendanceControllerCreateAttendanceAbsentMutationBody =
  RequestCreateAttendanceAbsentDto;
export type AttendanceControllerCreateAttendanceAbsentMutationError = ErrorType<unknown>;

export const useAttendanceControllerCreateAttendanceAbsent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof attendanceControllerCreateAttendanceAbsent>>,
    TError,
    { data: RequestCreateAttendanceAbsentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof attendanceControllerCreateAttendanceAbsent>>,
    { data: RequestCreateAttendanceAbsentDto }
  > = (props) => {
    const { data } = props ?? {};

    return attendanceControllerCreateAttendanceAbsent(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof attendanceControllerCreateAttendanceAbsent>>,
    TError,
    { data: RequestCreateAttendanceAbsentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 출석부 엑셀 다운로드(선생님용)
 */
export const attendanceControllerDownloadAbsents = (
  params: AttendanceControllerDownloadAbsentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    { url: `/api/attendance/download`, method: 'get', params, responseType: 'blob', signal },
    options,
  );
};

export const getAttendanceControllerDownloadAbsentsQueryKey = (
  params: AttendanceControllerDownloadAbsentsParams,
) => [`/api/attendance/download`, ...(params ? [params] : [])];

export type AttendanceControllerDownloadAbsentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof attendanceControllerDownloadAbsents>>
>;
export type AttendanceControllerDownloadAbsentsQueryError = ErrorType<unknown>;

export const useAttendanceControllerDownloadAbsents = <
  TData = Awaited<ReturnType<typeof attendanceControllerDownloadAbsents>>,
  TError = ErrorType<unknown>,
>(
  params: AttendanceControllerDownloadAbsentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof attendanceControllerDownloadAbsents>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAttendanceControllerDownloadAbsentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof attendanceControllerDownloadAbsents>>> = ({
    signal,
  }) => attendanceControllerDownloadAbsents(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof attendanceControllerDownloadAbsents>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 목록 조회
 */
export const boardControllerFindAll = (
  params: BoardControllerFindAllParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedBoardDto>(
    { url: `/api/boards`, method: 'get', params, signal },
    options,
  );
};

export const getBoardControllerFindAllQueryKey = (params: BoardControllerFindAllParams) => [
  `/api/boards`,
  ...(params ? [params] : []),
];

export type BoardControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof boardControllerFindAll>>
>;
export type BoardControllerFindAllQueryError = ErrorType<unknown>;

export const useBoardControllerFindAll = <
  TData = Awaited<ReturnType<typeof boardControllerFindAll>>,
  TError = ErrorType<unknown>,
>(
  params: BoardControllerFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof boardControllerFindAll>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBoardControllerFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof boardControllerFindAll>>> = ({ signal }) =>
    boardControllerFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof boardControllerFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 글 생성
 */
export const boardControllerCreate = (
  requestCreateBoardDto: RequestCreateBoardDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Board>(
    {
      url: `/api/boards`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateBoardDto,
    },
    options,
  );
};

export type BoardControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof boardControllerCreate>>
>;
export type BoardControllerCreateMutationBody = RequestCreateBoardDto;
export type BoardControllerCreateMutationError = ErrorType<unknown>;

export const useBoardControllerCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boardControllerCreate>>,
    TError,
    { data: RequestCreateBoardDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boardControllerCreate>>,
    { data: RequestCreateBoardDto }
  > = (props) => {
    const { data } = props ?? {};

    return boardControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof boardControllerCreate>>,
    TError,
    { data: RequestCreateBoardDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학급 게시판 단건 조회
 */
export const boardControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Board>({ url: `/api/boards/${id}`, method: 'get', signal }, options);
};

export const getBoardControllerFindOneQueryKey = (id: number) => [`/api/boards/${id}`];

export type BoardControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof boardControllerFindOne>>
>;
export type BoardControllerFindOneQueryError = ErrorType<unknown>;

export const useBoardControllerFindOne = <
  TData = Awaited<ReturnType<typeof boardControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof boardControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBoardControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof boardControllerFindOne>>> = ({ signal }) =>
    boardControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof boardControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학급 게시판 글 수정
 */
export const boardControllerUpdate = (
  id: number,
  requestUpdateBoardDto: RequestUpdateBoardDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<boolean>(
    {
      url: `/api/boards/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateBoardDto,
    },
    options,
  );
};

export type BoardControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof boardControllerUpdate>>
>;
export type BoardControllerUpdateMutationBody = RequestUpdateBoardDto;
export type BoardControllerUpdateMutationError = ErrorType<void>;

export const useBoardControllerUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boardControllerUpdate>>,
    TError,
    { id: number; data: RequestUpdateBoardDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boardControllerUpdate>>,
    { id: number; data: RequestUpdateBoardDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return boardControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof boardControllerUpdate>>,
    TError,
    { id: number; data: RequestUpdateBoardDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학급 게시판 글 삭제
 */
export const boardControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/boards/${id}`, method: 'delete' }, options);
};

export type BoardControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof boardControllerDelete>>
>;

export type BoardControllerDeleteMutationError = ErrorType<unknown>;

export const useBoardControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof boardControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boardControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return boardControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof boardControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 급식표 목록
 */
export const canteenControllerFindByYearMonth = (
  params: CanteenControllerFindByYearMonthParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Canteen[]>(
    { url: `/api/canteens`, method: 'get', params, signal },
    options,
  );
};

export const getCanteenControllerFindByYearMonthQueryKey = (
  params: CanteenControllerFindByYearMonthParams,
) => [`/api/canteens`, ...(params ? [params] : [])];

export type CanteenControllerFindByYearMonthQueryResult = NonNullable<
  Awaited<ReturnType<typeof canteenControllerFindByYearMonth>>
>;
export type CanteenControllerFindByYearMonthQueryError = ErrorType<unknown>;

export const useCanteenControllerFindByYearMonth = <
  TData = Awaited<ReturnType<typeof canteenControllerFindByYearMonth>>,
  TError = ErrorType<unknown>,
>(
  params: CanteenControllerFindByYearMonthParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof canteenControllerFindByYearMonth>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCanteenControllerFindByYearMonthQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof canteenControllerFindByYearMonth>>> = ({
    signal,
  }) => canteenControllerFindByYearMonth(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof canteenControllerFindByYearMonth>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 급식표 추가/수정
 */
export const canteenControllerCreateOrUpdate = (
  requestUpsertCanteenDto: RequestUpsertCanteenDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Canteen>(
    {
      url: `/api/canteens`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertCanteenDto,
    },
    options,
  );
};

export type CanteenControllerCreateOrUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof canteenControllerCreateOrUpdate>>
>;
export type CanteenControllerCreateOrUpdateMutationBody = RequestUpsertCanteenDto;
export type CanteenControllerCreateOrUpdateMutationError = ErrorType<void>;

export const useCanteenControllerCreateOrUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof canteenControllerCreateOrUpdate>>,
    TError,
    { data: RequestUpsertCanteenDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof canteenControllerCreateOrUpdate>>,
    { data: RequestUpsertCanteenDto }
  > = (props) => {
    const { data } = props ?? {};

    return canteenControllerCreateOrUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof canteenControllerCreateOrUpdate>>,
    TError,
    { data: RequestUpsertCanteenDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 목록(학생용)
 */
export const fieldtripsControllerFindAllByStudent = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Fieldtrip[]>(
    { url: `/api/fieldtrips/list-by-student`, method: 'get', signal },
    options,
  );
};

export const getFieldtripsControllerFindAllByStudentQueryKey = () => [
  `/api/fieldtrips/list-by-student`,
];

export type FieldtripsControllerFindAllByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerFindAllByStudent>>
>;
export type FieldtripsControllerFindAllByStudentQueryError = ErrorType<unknown>;

export const useFieldtripsControllerFindAllByStudent = <
  TData = Awaited<ReturnType<typeof fieldtripsControllerFindAllByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fieldtripsControllerFindAllByStudent>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsControllerFindAllByStudentQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fieldtripsControllerFindAllByStudent>>
  > = ({ signal }) => fieldtripsControllerFindAllByStudent(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof fieldtripsControllerFindAllByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 목록(선생님용), 레거시 거의 그대로 유지
 */
export const fieldtripsControllerGetFieldtripsByTeacher = (
  params: FieldtripsControllerGetFieldtripsByTeacherParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedFieldtripDto>(
    { url: `/api/fieldtrips`, method: 'get', params, signal },
    options,
  );
};

export const getFieldtripsControllerGetFieldtripsByTeacherQueryKey = (
  params: FieldtripsControllerGetFieldtripsByTeacherParams,
) => [`/api/fieldtrips`, ...(params ? [params] : [])];

export type FieldtripsControllerGetFieldtripsByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerGetFieldtripsByTeacher>>
>;
export type FieldtripsControllerGetFieldtripsByTeacherQueryError = ErrorType<unknown>;

export const useFieldtripsControllerGetFieldtripsByTeacher = <
  TData = Awaited<ReturnType<typeof fieldtripsControllerGetFieldtripsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: FieldtripsControllerGetFieldtripsByTeacherParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fieldtripsControllerGetFieldtripsByTeacher>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFieldtripsControllerGetFieldtripsByTeacherQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fieldtripsControllerGetFieldtripsByTeacher>>
  > = ({ signal }) => fieldtripsControllerGetFieldtripsByTeacher(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof fieldtripsControllerGetFieldtripsByTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 추가
 */
export const fieldtripsControllerCreate = (
  requestCreateFieldtripDto: RequestCreateFieldtripDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Fieldtrip>(
    {
      url: `/api/fieldtrips`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateFieldtripDto,
    },
    options,
  );
};

export type FieldtripsControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerCreate>>
>;
export type FieldtripsControllerCreateMutationBody = RequestCreateFieldtripDto;
export type FieldtripsControllerCreateMutationError = ErrorType<void>;

export const useFieldtripsControllerCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerCreate>>,
    TError,
    { data: RequestCreateFieldtripDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerCreate>>,
    { data: RequestCreateFieldtripDto }
  > = (props) => {
    const { data } = props ?? {};

    return fieldtripsControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerCreate>>,
    TError,
    { data: RequestCreateFieldtripDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 목록(선생님용), 레거시 그대로 유지
 */
export const fieldtripsControllerGetResultsByTeacher = (
  params: FieldtripsControllerGetResultsByTeacherParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedFieldtripDto>(
    { url: `/api/fieldtrips/result`, method: 'get', params, signal },
    options,
  );
};

export const getFieldtripsControllerGetResultsByTeacherQueryKey = (
  params: FieldtripsControllerGetResultsByTeacherParams,
) => [`/api/fieldtrips/result`, ...(params ? [params] : [])];

export type FieldtripsControllerGetResultsByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerGetResultsByTeacher>>
>;
export type FieldtripsControllerGetResultsByTeacherQueryError = ErrorType<unknown>;

export const useFieldtripsControllerGetResultsByTeacher = <
  TData = Awaited<ReturnType<typeof fieldtripsControllerGetResultsByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: FieldtripsControllerGetResultsByTeacherParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fieldtripsControllerGetResultsByTeacher>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFieldtripsControllerGetResultsByTeacherQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fieldtripsControllerGetResultsByTeacher>>
  > = ({ signal }) => fieldtripsControllerGetResultsByTeacher(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof fieldtripsControllerGetResultsByTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * TODO /result/:id endpoint 제거 필요
 * @summary 체험학습 신청서 단건 조회
 */
export const fieldtripsControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Fieldtrip>(
    { url: `/api/fieldtrips/${id}`, method: 'get', signal },
    options,
  );
};

export const getFieldtripsControllerFindOneQueryKey = (id: number) => [`/api/fieldtrips/${id}`];

export type FieldtripsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerFindOne>>
>;
export type FieldtripsControllerFindOneQueryError = ErrorType<void>;

export const useFieldtripsControllerFindOne = <
  TData = Awaited<ReturnType<typeof fieldtripsControllerFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsControllerFindOne>>> = ({
    signal,
  }) => fieldtripsControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 수정
 */
export const fieldtripsControllerUpdate = (
  id: number,
  requestCreateFieldtripDto: RequestCreateFieldtripDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Fieldtrip>(
    {
      url: `/api/fieldtrips/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateFieldtripDto,
    },
    options,
  );
};

export type FieldtripsControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerUpdate>>
>;
export type FieldtripsControllerUpdateMutationBody = RequestCreateFieldtripDto;
export type FieldtripsControllerUpdateMutationError = ErrorType<void>;

export const useFieldtripsControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateFieldtripDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerUpdate>>,
    { id: number; data: RequestCreateFieldtripDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateFieldtripDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 삭제
 */
export const fieldtripsControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/fieldtrips/${id}`, method: 'delete' }, options);
};

export type FieldtripsControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerDelete>>
>;

export type FieldtripsControllerDeleteMutationError = ErrorType<unknown>;

export const useFieldtripsControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return fieldtripsControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 단건 조회(by UUID)
 */
export const fieldtripsControllerFindOneByUUID = (
  uuid: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Fieldtrip>(
    { url: `/api/fieldtrips/by-uuid/${uuid}`, method: 'get', signal },
    options,
  );
};

export const getFieldtripsControllerFindOneByUUIDQueryKey = (uuid: string) => [
  `/api/fieldtrips/by-uuid/${uuid}`,
];

export type FieldtripsControllerFindOneByUUIDQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerFindOneByUUID>>
>;
export type FieldtripsControllerFindOneByUUIDQueryError = ErrorType<unknown>;

export const useFieldtripsControllerFindOneByUUID = <
  TData = Awaited<ReturnType<typeof fieldtripsControllerFindOneByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fieldtripsControllerFindOneByUUID>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsControllerFindOneByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsControllerFindOneByUUID>>> = ({
    signal,
  }) => fieldtripsControllerFindOneByUUID(uuid, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof fieldtripsControllerFindOneByUUID>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!uuid, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 신청서 수정(선생님용)
 */
export const fieldtripsControllerUpdateByTeacher = (
  id: number,
  requestUpdateFieldtripDto: RequestUpdateFieldtripDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Fieldtrip>(
    {
      url: `/api/fieldtrips/update-by-teacher/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateFieldtripDto,
    },
    options,
  );
};

export type FieldtripsControllerUpdateByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerUpdateByTeacher>>
>;
export type FieldtripsControllerUpdateByTeacherMutationBody = RequestUpdateFieldtripDto;
export type FieldtripsControllerUpdateByTeacherMutationError = ErrorType<void>;

export const useFieldtripsControllerUpdateByTeacher = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerUpdateByTeacher>>,
    { id: number; data: RequestUpdateFieldtripDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsControllerUpdateByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 삭제요청
 */
export const fieldtripsControllerRequestDelete = (
  id: number,
  fieldtripsControllerRequestDeleteBody: unknown,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Fieldtrip>(
    {
      url: `/api/fieldtrips/request-delete/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsControllerRequestDeleteBody,
    },
    options,
  );
};

export type FieldtripsControllerRequestDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerRequestDelete>>
>;
export type FieldtripsControllerRequestDeleteMutationBody = unknown;
export type FieldtripsControllerRequestDeleteMutationError = ErrorType<void>;

export const useFieldtripsControllerRequestDelete = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerRequestDelete>>,
    TError,
    { id: number; data: unknown },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerRequestDelete>>,
    { id: number; data: unknown }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsControllerRequestDelete(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerRequestDelete>>,
    TError,
    { id: number; data: unknown },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 반려
 */
export const fieldtripsControllerDeny = (
  id: number,
  fieldtripsControllerDenyBody: FieldtripsControllerDenyBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/fieldtrips/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsControllerDenyBody,
    },
    options,
  );
};

export type FieldtripsControllerDenyMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerDeny>>
>;
export type FieldtripsControllerDenyMutationBody = FieldtripsControllerDenyBody;
export type FieldtripsControllerDenyMutationError = ErrorType<unknown>;

export const useFieldtripsControllerDeny = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerDeny>>,
    TError,
    { id: number; data: FieldtripsControllerDenyBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerDeny>>,
    { id: number; data: FieldtripsControllerDenyBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsControllerDeny(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerDeny>>,
    TError,
    { id: number; data: FieldtripsControllerDenyBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 승인(부모님)
 */
export const fieldtripsControllerApproveByParent = (
  uuid: string,
  fieldtripsControllerApproveByParentBody: FieldtripsControllerApproveByParentBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/fieldtrips/approve-by-parent/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsControllerApproveByParentBody,
    },
    options,
  );
};

export type FieldtripsControllerApproveByParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerApproveByParent>>
>;
export type FieldtripsControllerApproveByParentMutationBody =
  FieldtripsControllerApproveByParentBody;
export type FieldtripsControllerApproveByParentMutationError = ErrorType<unknown>;

export const useFieldtripsControllerApproveByParent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerApproveByParent>>,
    TError,
    { uuid: string; data: FieldtripsControllerApproveByParentBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerApproveByParent>>,
    { uuid: string; data: FieldtripsControllerApproveByParentBody }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return fieldtripsControllerApproveByParent(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerApproveByParent>>,
    TError,
    { uuid: string; data: FieldtripsControllerApproveByParentBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 승인
 */
export const fieldtripsControllerApprove = (
  id: number,
  fieldtripsControllerApproveBody: FieldtripsControllerApproveBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/fieldtrips/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripsControllerApproveBody,
    },
    options,
  );
};

export type FieldtripsControllerApproveMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerApprove>>
>;
export type FieldtripsControllerApproveMutationBody = FieldtripsControllerApproveBody;
export type FieldtripsControllerApproveMutationError = ErrorType<unknown>;

export const useFieldtripsControllerApprove = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripsControllerApprove>>,
    TError,
    { id: number; data: FieldtripsControllerApproveBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripsControllerApprove>>,
    { id: number; data: FieldtripsControllerApproveBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripsControllerApprove(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripsControllerApprove>>,
    TError,
    { id: number; data: FieldtripsControllerApproveBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청서 부모님 사인 재요청
 */
export const fieldtripsControllerResend = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/fieldtrips/resend/${id}`, method: 'get', signal },
    options,
  );
};

export const getFieldtripsControllerResendQueryKey = (id: number) => [
  `/api/fieldtrips/resend/${id}`,
];

export type FieldtripsControllerResendQueryResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripsControllerResend>>
>;
export type FieldtripsControllerResendQueryError = ErrorType<unknown>;

export const useFieldtripsControllerResend = <
  TData = Awaited<ReturnType<typeof fieldtripsControllerResend>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fieldtripsControllerResend>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFieldtripsControllerResendQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fieldtripsControllerResend>>> = ({
    signal,
  }) => fieldtripsControllerResend(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fieldtripsControllerResend>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 체험학습 결과보고서 제출
 */
export const fieldtripResultControllerUpdateResult = (
  id: number,
  requestUpdateFieldtripResultDto: RequestUpdateFieldtripResultDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Fieldtrip>(
    {
      url: `/api/fieldtrips/result/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateFieldtripResultDto,
    },
    options,
  );
};

export type FieldtripResultControllerUpdateResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultControllerUpdateResult>>
>;
export type FieldtripResultControllerUpdateResultMutationBody = RequestUpdateFieldtripResultDto;
export type FieldtripResultControllerUpdateResultMutationError = ErrorType<void>;

export const useFieldtripResultControllerUpdateResult = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultControllerUpdateResult>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultControllerUpdateResult>>,
    { id: number; data: RequestUpdateFieldtripResultDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultControllerUpdateResult(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultControllerUpdateResult>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 제출 수정(선생님용)
 */
export const fieldtripResultControllerUpdateResultByTeacher = (
  id: number,
  requestUpdateFieldtripResultByTeacherDto: RequestUpdateFieldtripResultByTeacherDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Fieldtrip>(
    {
      url: `/api/fieldtrips/result/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateFieldtripResultByTeacherDto,
    },
    options,
  );
};

export type FieldtripResultControllerUpdateResultByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultControllerUpdateResultByTeacher>>
>;
export type FieldtripResultControllerUpdateResultByTeacherMutationBody =
  RequestUpdateFieldtripResultByTeacherDto;
export type FieldtripResultControllerUpdateResultByTeacherMutationError = ErrorType<void>;

export const useFieldtripResultControllerUpdateResultByTeacher = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultControllerUpdateResultByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultByTeacherDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultControllerUpdateResultByTeacher>>,
    { id: number; data: RequestUpdateFieldtripResultByTeacherDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultControllerUpdateResultByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultControllerUpdateResultByTeacher>>,
    TError,
    { id: number; data: RequestUpdateFieldtripResultByTeacherDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 반려
 */
export const fieldtripResultControllerDenyResult = (
  id: number,
  fieldtripResultControllerDenyResultBody: FieldtripResultControllerDenyResultBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/fieldtrips/result/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultControllerDenyResultBody,
    },
    options,
  );
};

export type FieldtripResultControllerDenyResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultControllerDenyResult>>
>;
export type FieldtripResultControllerDenyResultMutationBody =
  FieldtripResultControllerDenyResultBody;
export type FieldtripResultControllerDenyResultMutationError = ErrorType<unknown>;

export const useFieldtripResultControllerDenyResult = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultControllerDenyResult>>,
    TError,
    { id: number; data: FieldtripResultControllerDenyResultBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultControllerDenyResult>>,
    { id: number; data: FieldtripResultControllerDenyResultBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultControllerDenyResult(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultControllerDenyResult>>,
    TError,
    { id: number; data: FieldtripResultControllerDenyResultBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 승인(부모님)
 */
export const fieldtripResultControllerApproveResultByParent = (
  uuid: string,
  fieldtripResultControllerApproveResultByParentBody: FieldtripResultControllerApproveResultByParentBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/fieldtrips/result/approve-by-parent/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultControllerApproveResultByParentBody,
    },
    options,
  );
};

export type FieldtripResultControllerApproveResultByParentMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultControllerApproveResultByParent>>
>;
export type FieldtripResultControllerApproveResultByParentMutationBody =
  FieldtripResultControllerApproveResultByParentBody;
export type FieldtripResultControllerApproveResultByParentMutationError = ErrorType<unknown>;

export const useFieldtripResultControllerApproveResultByParent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultControllerApproveResultByParent>>,
    TError,
    { uuid: string; data: FieldtripResultControllerApproveResultByParentBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultControllerApproveResultByParent>>,
    { uuid: string; data: FieldtripResultControllerApproveResultByParentBody }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return fieldtripResultControllerApproveResultByParent(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultControllerApproveResultByParent>>,
    TError,
    { uuid: string; data: FieldtripResultControllerApproveResultByParentBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 승인
 */
export const fieldtripResultControllerApproveResult = (
  id: number,
  fieldtripResultControllerApproveResultBody: FieldtripResultControllerApproveResultBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/fieldtrips/result/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldtripResultControllerApproveResultBody,
    },
    options,
  );
};

export type FieldtripResultControllerApproveResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultControllerApproveResult>>
>;
export type FieldtripResultControllerApproveResultMutationBody =
  FieldtripResultControllerApproveResultBody;
export type FieldtripResultControllerApproveResultMutationError = ErrorType<unknown>;

export const useFieldtripResultControllerApproveResult = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultControllerApproveResult>>,
    TError,
    { id: number; data: FieldtripResultControllerApproveResultBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultControllerApproveResult>>,
    { id: number; data: FieldtripResultControllerApproveResultBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return fieldtripResultControllerApproveResult(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultControllerApproveResult>>,
    TError,
    { id: number; data: FieldtripResultControllerApproveResultBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 결과보고서 부모님 사인 재요청
 */
export const fieldtripResultControllerResend = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/fieldtrips/result/resend/${id}`, method: 'post' },
    options,
  );
};

export type FieldtripResultControllerResendMutationResult = NonNullable<
  Awaited<ReturnType<typeof fieldtripResultControllerResend>>
>;

export type FieldtripResultControllerResendMutationError = ErrorType<unknown>;

export const useFieldtripResultControllerResend = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fieldtripResultControllerResend>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fieldtripResultControllerResend>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return fieldtripResultControllerResend(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof fieldtripResultControllerResend>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * 선생님인 경우 선생님 그룹, 학생인 경우 학생 그룹, 부모님인 경우 학생들의 그룹
 * @summary 그룹 조회(선생님, 부모, 학생 공통)
 */
export const groupsControllerFindAll = (
  params: GroupsControllerFindAllParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Group[]>({ url: `/api/groups`, method: 'get', params, signal }, options);
};

export const getGroupsControllerFindAllQueryKey = (params: GroupsControllerFindAllParams) => [
  `/api/groups`,
  ...(params ? [params] : []),
];

export type GroupsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindAll>>
>;
export type GroupsControllerFindAllQueryError = ErrorType<unknown>;

export const useGroupsControllerFindAll = <
  TData = Awaited<ReturnType<typeof groupsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(
  params: GroupsControllerFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsControllerFindAll>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsControllerFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsControllerFindAll>>> = ({
    signal,
  }) => groupsControllerFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsControllerFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 추가
 */
export const groupsControllerCreate = (
  requestCreateGroupDto: RequestCreateGroupDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Group>(
    {
      url: `/api/groups`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateGroupDto,
    },
    options,
  );
};

export type GroupsControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerCreate>>
>;
export type GroupsControllerCreateMutationBody = RequestCreateGroupDto;
export type GroupsControllerCreateMutationError = ErrorType<unknown>;

export const useGroupsControllerCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsControllerCreate>>,
    TError,
    { data: RequestCreateGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsControllerCreate>>,
    { data: RequestCreateGroupDto }
  > = (props) => {
    const { data } = props ?? {};

    return groupsControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupsControllerCreate>>,
    TError,
    { data: RequestCreateGroupDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * 아이디찾기에서 사용
 * @summary 학교의 KLASS 그룹 조회
 */
export const groupsControllerFindAllBySchoolId = (
  params: GroupsControllerFindAllBySchoolIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Group[]>(
    { url: `/api/groups/klass-groups-by-school-id`, method: 'get', params, signal },
    options,
  );
};

export const getGroupsControllerFindAllBySchoolIdQueryKey = (
  params: GroupsControllerFindAllBySchoolIdParams,
) => [`/api/groups/klass-groups-by-school-id`, ...(params ? [params] : [])];

export type GroupsControllerFindAllBySchoolIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindAllBySchoolId>>
>;
export type GroupsControllerFindAllBySchoolIdQueryError = ErrorType<unknown>;

export const useGroupsControllerFindAllBySchoolId = <
  TData = Awaited<ReturnType<typeof groupsControllerFindAllBySchoolId>>,
  TError = ErrorType<unknown>,
>(
  params: GroupsControllerFindAllBySchoolIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupsControllerFindAllBySchoolId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsControllerFindAllBySchoolIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsControllerFindAllBySchoolId>>> = ({
    signal,
  }) => groupsControllerFindAllBySchoolId(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupsControllerFindAllBySchoolId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 조회(id array)
 */
export const groupsControllerFindAllByIds = (
  params: GroupsControllerFindAllByIdsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseGroupByIdsDto>(
    { url: `/api/groups/groups-by-ids`, method: 'get', params, signal },
    options,
  );
};

export const getGroupsControllerFindAllByIdsQueryKey = (
  params: GroupsControllerFindAllByIdsParams,
) => [`/api/groups/groups-by-ids`, ...(params ? [params] : [])];

export type GroupsControllerFindAllByIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindAllByIds>>
>;
export type GroupsControllerFindAllByIdsQueryError = ErrorType<unknown>;

export const useGroupsControllerFindAllByIds = <
  TData = Awaited<ReturnType<typeof groupsControllerFindAllByIds>>,
  TError = ErrorType<unknown>,
>(
  params: GroupsControllerFindAllByIdsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupsControllerFindAllByIds>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsControllerFindAllByIdsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsControllerFindAllByIds>>> = ({
    signal,
  }) => groupsControllerFindAllByIds(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsControllerFindAllByIds>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학교의 klass 그룹 조회(선생님용)
 */
export const groupsControllerFindAllKlassBySchool = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseGroupDto[]>(
    { url: `/api/groups/get-klass-by-school`, method: 'get', signal },
    options,
  );
};

export const getGroupsControllerFindAllKlassBySchoolQueryKey = () => [
  `/api/groups/get-klass-by-school`,
];

export type GroupsControllerFindAllKlassBySchoolQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindAllKlassBySchool>>
>;
export type GroupsControllerFindAllKlassBySchoolQueryError = ErrorType<unknown>;

export const useGroupsControllerFindAllKlassBySchool = <
  TData = Awaited<ReturnType<typeof groupsControllerFindAllKlassBySchool>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof groupsControllerFindAllKlassBySchool>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsControllerFindAllKlassBySchoolQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof groupsControllerFindAllKlassBySchool>>
  > = ({ signal }) => groupsControllerFindAllKlassBySchool(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupsControllerFindAllKlassBySchool>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 klass 그룹 조회(선생님용)
 */
export const groupsControllerFindKlassByUser = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseGroupDto[]>(
    { url: `/api/groups/get-klass-by-user`, method: 'get', signal },
    options,
  );
};

export const getGroupsControllerFindKlassByUserQueryKey = () => [`/api/groups/get-klass-by-user`];

export type GroupsControllerFindKlassByUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindKlassByUser>>
>;
export type GroupsControllerFindKlassByUserQueryError = ErrorType<unknown>;

export const useGroupsControllerFindKlassByUser = <
  TData = Awaited<ReturnType<typeof groupsControllerFindKlassByUser>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof groupsControllerFindKlassByUser>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsControllerFindKlassByUserQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsControllerFindKlassByUser>>> = ({
    signal,
  }) => groupsControllerFindKlassByUser(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupsControllerFindKlassByUser>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 klub 그룹 조회(선생님용)
 */
export const groupsControllerFindKlubBySchoolIdAndUserId = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseGroupDto[]>(
    { url: `/api/groups/get-klub-by-user`, method: 'get', signal },
    options,
  );
};

export const getGroupsControllerFindKlubBySchoolIdAndUserIdQueryKey = () => [
  `/api/groups/get-klub-by-user`,
];

export type GroupsControllerFindKlubBySchoolIdAndUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindKlubBySchoolIdAndUserId>>
>;
export type GroupsControllerFindKlubBySchoolIdAndUserIdQueryError = ErrorType<unknown>;

export const useGroupsControllerFindKlubBySchoolIdAndUserId = <
  TData = Awaited<ReturnType<typeof groupsControllerFindKlubBySchoolIdAndUserId>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof groupsControllerFindKlubBySchoolIdAndUserId>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGroupsControllerFindKlubBySchoolIdAndUserIdQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof groupsControllerFindKlubBySchoolIdAndUserId>>
  > = ({ signal }) => groupsControllerFindKlubBySchoolIdAndUserId(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupsControllerFindKlubBySchoolIdAndUserId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary studentId로 그룹 조회
 */
export const groupsControllerFindGroupByStudentId = (
  params: GroupsControllerFindGroupByStudentIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/groups/get-group-by-studentid`, method: 'get', params, signal },
    options,
  );
};

export const getGroupsControllerFindGroupByStudentIdQueryKey = (
  params: GroupsControllerFindGroupByStudentIdParams,
) => [`/api/groups/get-group-by-studentid`, ...(params ? [params] : [])];

export type GroupsControllerFindGroupByStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindGroupByStudentId>>
>;
export type GroupsControllerFindGroupByStudentIdQueryError = ErrorType<unknown>;

export const useGroupsControllerFindGroupByStudentId = <
  TData = Awaited<ReturnType<typeof groupsControllerFindGroupByStudentId>>,
  TError = ErrorType<unknown>,
>(
  params: GroupsControllerFindGroupByStudentIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof groupsControllerFindGroupByStudentId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGroupsControllerFindGroupByStudentIdQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof groupsControllerFindGroupByStudentId>>
  > = ({ signal }) => groupsControllerFindGroupByStudentId(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof groupsControllerFindGroupByStudentId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 단건 조회
 */
export const groupsControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Group>({ url: `/api/groups/${id}`, method: 'get', signal }, options);
};

export const getGroupsControllerFindOneQueryKey = (id: number) => [`/api/groups/${id}`];

export type GroupsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerFindOne>>
>;
export type GroupsControllerFindOneQueryError = ErrorType<unknown>;

export const useGroupsControllerFindOne = <
  TData = Awaited<ReturnType<typeof groupsControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof groupsControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGroupsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof groupsControllerFindOne>>> = ({
    signal,
  }) => groupsControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof groupsControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 그룹 수정
 */
export const groupsControllerUpdate = (
  id: number,
  requestCreateGroupDto: RequestCreateGroupDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Group>(
    {
      url: `/api/groups/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateGroupDto,
    },
    options,
  );
};

export type GroupsControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerUpdate>>
>;
export type GroupsControllerUpdateMutationBody = RequestCreateGroupDto;
export type GroupsControllerUpdateMutationError = ErrorType<void>;

export const useGroupsControllerUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateGroupDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsControllerUpdate>>,
    { id: number; data: RequestCreateGroupDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return groupsControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateGroupDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 그룹 삭제
 */
export const groupsControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/groups/${id}`, method: 'delete' }, options);
};

export type GroupsControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof groupsControllerDelete>>
>;

export type GroupsControllerDeleteMutationError = ErrorType<unknown>;

export const useGroupsControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof groupsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof groupsControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return groupsControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof groupsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 파일 업로드
 */
export const imagesControllerUploadImage = (
  uploadFileType: UploadFileTypeEnum,
  imagesControllerUploadImageBody: ImagesControllerUploadImageBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  if (imagesControllerUploadImageBody.file !== undefined) {
    formData.append('file', imagesControllerUploadImageBody.file);
  }

  return customInstance<string>(
    {
      url: `/api/images/${uploadFileType}`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export type ImagesControllerUploadImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof imagesControllerUploadImage>>
>;
export type ImagesControllerUploadImageMutationBody = ImagesControllerUploadImageBody;
export type ImagesControllerUploadImageMutationError = ErrorType<unknown>;

export const useImagesControllerUploadImage = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof imagesControllerUploadImage>>,
    TError,
    { uploadFileType: UploadFileTypeEnum; data: ImagesControllerUploadImageBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof imagesControllerUploadImage>>,
    { uploadFileType: UploadFileTypeEnum; data: ImagesControllerUploadImageBody }
  > = (props) => {
    const { uploadFileType, data } = props ?? {};

    return imagesControllerUploadImage(uploadFileType, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof imagesControllerUploadImage>>,
    TError,
    { uploadFileType: UploadFileTypeEnum; data: ImagesControllerUploadImageBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @deprecated
 * @summary http://localhost:8000/api/docs#/%ED%95%99%EC%83%9D%ED%99%9C%EB%8F%99/StudentActivityController_findByUserId
 */
export const lifeRecordControllerGetLifeActivityByStudent = (
  studentId: number,
  params: LifeRecordControllerGetLifeActivityByStudentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/life_record/student/${studentId}`, method: 'get', params, signal },
    options,
  );
};

export const getLifeRecordControllerGetLifeActivityByStudentQueryKey = (
  studentId: number,
  params: LifeRecordControllerGetLifeActivityByStudentParams,
) => [`/api/life_record/student/${studentId}`, ...(params ? [params] : [])];

export type LifeRecordControllerGetLifeActivityByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivityByStudent>>
>;
export type LifeRecordControllerGetLifeActivityByStudentQueryError = ErrorType<unknown>;

export const useLifeRecordControllerGetLifeActivityByStudent = <
  TData = Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivityByStudent>>,
  TError = ErrorType<unknown>,
>(
  studentId: number,
  params: LifeRecordControllerGetLifeActivityByStudentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivityByStudent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getLifeRecordControllerGetLifeActivityByStudentQueryKey(studentId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivityByStudent>>
  > = ({ signal }) =>
    lifeRecordControllerGetLifeActivityByStudent(studentId, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivityByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!studentId, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록부 총정리 다운로드
 */
export const lifeRecordControllerDownloadRecordSummary = (
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    {
      url: `/api/life_record/download/summary/${groupId}`,
      method: 'get',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getLifeRecordControllerDownloadRecordSummaryQueryKey = (groupId: number) => [
  `/api/life_record/download/summary/${groupId}`,
];

export type LifeRecordControllerDownloadRecordSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordSummary>>
>;
export type LifeRecordControllerDownloadRecordSummaryQueryError = ErrorType<unknown>;

export const useLifeRecordControllerDownloadRecordSummary = <
  TData = Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordSummary>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordSummary>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLifeRecordControllerDownloadRecordSummaryQueryKey(groupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordSummary>>
  > = ({ signal }) => lifeRecordControllerDownloadRecordSummary(groupId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordSummary>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!groupId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록부 활동내역 다운로드
 */
export const lifeRecordControllerDownloadRecordActivity = (
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    {
      url: `/api/life_record/download/activity/${groupId}`,
      method: 'get',
      responseType: 'blob',
      signal,
    },
    options,
  );
};

export const getLifeRecordControllerDownloadRecordActivityQueryKey = (groupId: number) => [
  `/api/life_record/download/activity/${groupId}`,
];

export type LifeRecordControllerDownloadRecordActivityQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordActivity>>
>;
export type LifeRecordControllerDownloadRecordActivityQueryError = ErrorType<unknown>;

export const useLifeRecordControllerDownloadRecordActivity = <
  TData = Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordActivity>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordActivity>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLifeRecordControllerDownloadRecordActivityQueryKey(groupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordActivity>>
  > = ({ signal }) => lifeRecordControllerDownloadRecordActivity(groupId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof lifeRecordControllerDownloadRecordActivity>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!groupId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary http://localhost:8000/api/docs#/%ED%95%99%EC%83%9D%20%EA%B7%B8%EB%A3%B9/StudentGroupsController_findByGroupId
 */
export const lifeRecordControllerGetLifeActivity = (
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/life_record/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getLifeRecordControllerGetLifeActivityQueryKey = (groupId: number) => [
  `/api/life_record/${groupId}`,
];

export type LifeRecordControllerGetLifeActivityQueryResult = NonNullable<
  Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivity>>
>;
export type LifeRecordControllerGetLifeActivityQueryError = ErrorType<unknown>;

export const useLifeRecordControllerGetLifeActivity = <
  TData = Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivity>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivity>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getLifeRecordControllerGetLifeActivityQueryKey(groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivity>>> = ({
    signal,
  }) => lifeRecordControllerGetLifeActivity(groupId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof lifeRecordControllerGetLifeActivity>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!groupId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 목록 조회
 */
export const newsLettersControllerFindAll = (
  params?: NewsLettersControllerFindAllParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedNewsletterDto>(
    { url: `/api/newsletters`, method: 'get', params, signal },
    options,
  );
};

export const getNewsLettersControllerFindAllQueryKey = (
  params?: NewsLettersControllerFindAllParams,
) => [`/api/newsletters`, ...(params ? [params] : [])];

export type NewsLettersControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerFindAll>>
>;
export type NewsLettersControllerFindAllQueryError = ErrorType<unknown>;

export const useNewsLettersControllerFindAll = <
  TData = Awaited<ReturnType<typeof newsLettersControllerFindAll>>,
  TError = ErrorType<unknown>,
>(
  params?: NewsLettersControllerFindAllParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof newsLettersControllerFindAll>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersControllerFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersControllerFindAll>>> = ({
    signal,
  }) => newsLettersControllerFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersControllerFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 추가
 */
export const newsLettersControllerCreate = (
  requestCreateNewsletterDto: RequestCreateNewsletterDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Newsletter>(
    {
      url: `/api/newsletters`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNewsletterDto,
    },
    options,
  );
};

export type NewsLettersControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerCreate>>
>;
export type NewsLettersControllerCreateMutationBody = RequestCreateNewsletterDto;
export type NewsLettersControllerCreateMutationError = ErrorType<void>;

export const useNewsLettersControllerCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newsLettersControllerCreate>>,
    TError,
    { data: RequestCreateNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newsLettersControllerCreate>>,
    { data: RequestCreateNewsletterDto }
  > = (props) => {
    const { data } = props ?? {};

    return newsLettersControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof newsLettersControllerCreate>>,
    TError,
    { data: RequestCreateNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문 단건 조회
 */
export const newsLettersControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Newsletter>(
    { url: `/api/newsletters/${id}`, method: 'get', signal },
    options,
  );
};

export const getNewsLettersControllerFindOneQueryKey = (id: number) => [`/api/newsletters/${id}`];

export type NewsLettersControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerFindOne>>
>;
export type NewsLettersControllerFindOneQueryError = ErrorType<unknown>;

export const useNewsLettersControllerFindOne = <
  TData = Awaited<ReturnType<typeof newsLettersControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof newsLettersControllerFindOne>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersControllerFindOne>>> = ({
    signal,
  }) => newsLettersControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 발행안된 설문일때만 수정가능
 * @summary 가정통신문 수정
 */
export const newsLettersControllerUpdate = (
  id: number,
  requestUpdateNewsletterDto: RequestUpdateNewsletterDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Newsletter>(
    {
      url: `/api/newsletters/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateNewsletterDto,
    },
    options,
  );
};

export type NewsLettersControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerUpdate>>
>;
export type NewsLettersControllerUpdateMutationBody = RequestUpdateNewsletterDto;
export type NewsLettersControllerUpdateMutationError = ErrorType<void>;

export const useNewsLettersControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newsLettersControllerUpdate>>,
    TError,
    { id: number; data: RequestUpdateNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newsLettersControllerUpdate>>,
    { id: number; data: RequestUpdateNewsletterDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return newsLettersControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof newsLettersControllerUpdate>>,
    TError,
    { id: number; data: RequestUpdateNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문 삭제
 */
export const newsLettersControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/newsletters/${id}`, method: 'delete' }, options);
};

export type NewsLettersControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerDelete>>
>;

export type NewsLettersControllerDeleteMutationError = ErrorType<unknown>;

export const useNewsLettersControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newsLettersControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newsLettersControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return newsLettersControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof newsLettersControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문 발행
 */
export const newsLettersControllerPublish = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/newsletters/publish/${id}`, method: 'get', signal },
    options,
  );
};

export const getNewsLettersControllerPublishQueryKey = (id: number) => [
  `/api/newsletters/publish/${id}`,
];

export type NewsLettersControllerPublishQueryResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerPublish>>
>;
export type NewsLettersControllerPublishQueryError = ErrorType<unknown>;

export const useNewsLettersControllerPublish = <
  TData = Awaited<ReturnType<typeof newsLettersControllerPublish>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof newsLettersControllerPublish>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersControllerPublishQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof newsLettersControllerPublish>>> = ({
    signal,
  }) => newsLettersControllerPublish(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof newsLettersControllerPublish>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문 다운로드
 */
export const newsLettersControllerDownloadSubmitters = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    { url: `/api/newsletters/download/${id}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getNewsLettersControllerDownloadSubmittersQueryKey = (id: number) => [
  `/api/newsletters/download/${id}`,
];

export type NewsLettersControllerDownloadSubmittersQueryResult = NonNullable<
  Awaited<ReturnType<typeof newsLettersControllerDownloadSubmitters>>
>;
export type NewsLettersControllerDownloadSubmittersQueryError = ErrorType<unknown>;

export const useNewsLettersControllerDownloadSubmitters = <
  TData = Awaited<ReturnType<typeof newsLettersControllerDownloadSubmitters>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof newsLettersControllerDownloadSubmitters>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNewsLettersControllerDownloadSubmittersQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof newsLettersControllerDownloadSubmitters>>
  > = ({ signal }) => newsLettersControllerDownloadSubmitters(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof newsLettersControllerDownloadSubmitters>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 목록 조회
 */
export const noticesControllerFindAll = (
  params?: NoticesControllerFindAllParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedNoticeDto>(
    { url: `/api/notices`, method: 'get', params, signal },
    options,
  );
};

export const getNoticesControllerFindAllQueryKey = (params?: NoticesControllerFindAllParams) => [
  `/api/notices`,
  ...(params ? [params] : []),
];

export type NoticesControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof noticesControllerFindAll>>
>;
export type NoticesControllerFindAllQueryError = ErrorType<unknown>;

export const useNoticesControllerFindAll = <
  TData = Awaited<ReturnType<typeof noticesControllerFindAll>>,
  TError = ErrorType<unknown>,
>(
  params?: NoticesControllerFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof noticesControllerFindAll>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoticesControllerFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof noticesControllerFindAll>>> = ({
    signal,
  }) => noticesControllerFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof noticesControllerFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 추가
 */
export const noticesControllerCreate = (
  requestCreateNoticeDto: RequestCreateNoticeDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Notice>(
    {
      url: `/api/notices`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNoticeDto,
    },
    options,
  );
};

export type NoticesControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof noticesControllerCreate>>
>;
export type NoticesControllerCreateMutationBody = RequestCreateNoticeDto;
export type NoticesControllerCreateMutationError = ErrorType<void>;

export const useNoticesControllerCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof noticesControllerCreate>>,
    TError,
    { data: RequestCreateNoticeDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof noticesControllerCreate>>,
    { data: RequestCreateNoticeDto }
  > = (props) => {
    const { data } = props ?? {};

    return noticesControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof noticesControllerCreate>>,
    TError,
    { data: RequestCreateNoticeDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 공지사항 단건 조회
 */
export const noticesControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Notice>({ url: `/api/notices/${id}`, method: 'get', signal }, options);
};

export const getNoticesControllerFindOneQueryKey = (id: number) => [`/api/notices/${id}`];

export type NoticesControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof noticesControllerFindOne>>
>;
export type NoticesControllerFindOneQueryError = ErrorType<unknown>;

export const useNoticesControllerFindOne = <
  TData = Awaited<ReturnType<typeof noticesControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof noticesControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNoticesControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof noticesControllerFindOne>>> = ({
    signal,
  }) => noticesControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof noticesControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 공지사항 수정
 */
export const noticesControllerUpdate = (
  id: number,
  requestCreateNoticeDto: RequestCreateNoticeDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Notice>(
    {
      url: `/api/notices/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateNoticeDto,
    },
    options,
  );
};

export type NoticesControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof noticesControllerUpdate>>
>;
export type NoticesControllerUpdateMutationBody = RequestCreateNoticeDto;
export type NoticesControllerUpdateMutationError = ErrorType<void>;

export const useNoticesControllerUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof noticesControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateNoticeDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof noticesControllerUpdate>>,
    { id: number; data: RequestCreateNoticeDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return noticesControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof noticesControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateNoticeDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 공지사항 삭제
 */
export const noticesControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/notices/${id}`, method: 'delete' }, options);
};

export type NoticesControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof noticesControllerDelete>>
>;

export type NoticesControllerDeleteMutationError = ErrorType<unknown>;

export const useNoticesControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof noticesControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof noticesControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return noticesControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof noticesControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 목록(선생님용), 레거시 거의 그대로 유지
 */
export const outingsControllerFindAllByTeacher = (
  params: OutingsControllerFindAllByTeacherParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponsePaginatedOutingDto>(
    { url: `/api/outings/teacher`, method: 'get', params, signal },
    options,
  );
};

export const getOutingsControllerFindAllByTeacherQueryKey = (
  params: OutingsControllerFindAllByTeacherParams,
) => [`/api/outings/teacher`, ...(params ? [params] : [])];

export type OutingsControllerFindAllByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerFindAllByTeacher>>
>;
export type OutingsControllerFindAllByTeacherQueryError = ErrorType<unknown>;

export const useOutingsControllerFindAllByTeacher = <
  TData = Awaited<ReturnType<typeof outingsControllerFindAllByTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: OutingsControllerFindAllByTeacherParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof outingsControllerFindAllByTeacher>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsControllerFindAllByTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsControllerFindAllByTeacher>>> = ({
    signal,
  }) => outingsControllerFindAllByTeacher(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof outingsControllerFindAllByTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 조퇴/외출/확인 목록(학생용)
 */
export const outingsControllerFindAllByStudent = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Outing[]>(
    { url: `/api/outings/list-by-student`, method: 'get', signal },
    options,
  );
};

export const getOutingsControllerFindAllByStudentQueryKey = () => [`/api/outings/list-by-student`];

export type OutingsControllerFindAllByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerFindAllByStudent>>
>;
export type OutingsControllerFindAllByStudentQueryError = ErrorType<unknown>;

export const useOutingsControllerFindAllByStudent = <
  TData = Awaited<ReturnType<typeof outingsControllerFindAllByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof outingsControllerFindAllByStudent>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsControllerFindAllByStudentQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsControllerFindAllByStudent>>> = ({
    signal,
  }) => outingsControllerFindAllByStudent(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof outingsControllerFindAllByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 조퇴/외출/확인 단건 조회
 */
export const outingsControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Outing>({ url: `/api/outings/${id}`, method: 'get', signal }, options);
};

export const getOutingsControllerFindOneQueryKey = (id: number) => [`/api/outings/${id}`];

export type OutingsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerFindOne>>
>;
export type OutingsControllerFindOneQueryError = ErrorType<void>;

export const useOutingsControllerFindOne = <
  TData = Awaited<ReturnType<typeof outingsControllerFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof outingsControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOutingsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof outingsControllerFindOne>>> = ({
    signal,
  }) => outingsControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof outingsControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 조퇴/외출/확인 수정
 */
export const outingsControllerUpdate = (
  id: number,
  requestCreateOutingDto: RequestCreateOutingDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Outing>(
    {
      url: `/api/outings/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateOutingDto,
    },
    options,
  );
};

export type OutingsControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerUpdate>>
>;
export type OutingsControllerUpdateMutationBody = RequestCreateOutingDto;
export type OutingsControllerUpdateMutationError = ErrorType<void>;

export const useOutingsControllerUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateOutingDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerUpdate>>,
    { id: number; data: RequestCreateOutingDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateOutingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 삭제
 */
export const outingsControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/outings/${id}`, method: 'delete' }, options);
};

export type OutingsControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerDelete>>
>;

export type OutingsControllerDeleteMutationError = ErrorType<unknown>;

export const useOutingsControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return outingsControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 추가
 */
export const outingsControllerCreate = (
  requestCreateOutingDto: RequestCreateOutingDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Outing>(
    {
      url: `/api/outings`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateOutingDto,
    },
    options,
  );
};

export type OutingsControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerCreate>>
>;
export type OutingsControllerCreateMutationBody = RequestCreateOutingDto;
export type OutingsControllerCreateMutationError = ErrorType<void>;

export const useOutingsControllerCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerCreate>>,
    TError,
    { data: RequestCreateOutingDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerCreate>>,
    { data: RequestCreateOutingDto }
  > = (props) => {
    const { data } = props ?? {};

    return outingsControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerCreate>>,
    TError,
    { data: RequestCreateOutingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 수정(선생님용)
 */
export const outingsControllerUpdateByTeacher = (
  id: number,
  requestUpdateOutingDto: RequestUpdateOutingDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Outing>(
    {
      url: `/api/outings/update-by-teacher/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateOutingDto,
    },
    options,
  );
};

export type OutingsControllerUpdateByTeacherMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerUpdateByTeacher>>
>;
export type OutingsControllerUpdateByTeacherMutationBody = RequestUpdateOutingDto;
export type OutingsControllerUpdateByTeacherMutationError = ErrorType<void>;

export const useOutingsControllerUpdateByTeacher = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateOutingDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerUpdateByTeacher>>,
    { id: number; data: RequestUpdateOutingDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsControllerUpdateByTeacher(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerUpdateByTeacher>>,
    TError,
    { id: number; data: RequestUpdateOutingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 삭제요청
 */
export const outingsControllerRequestDelete = (
  id: number,
  outingsControllerRequestDeleteBody: OutingsControllerRequestDeleteBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Outing>(
    {
      url: `/api/outings/request-delete/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outingsControllerRequestDeleteBody,
    },
    options,
  );
};

export type OutingsControllerRequestDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerRequestDelete>>
>;
export type OutingsControllerRequestDeleteMutationBody = OutingsControllerRequestDeleteBody;
export type OutingsControllerRequestDeleteMutationError = ErrorType<void>;

export const useOutingsControllerRequestDelete = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerRequestDelete>>,
    TError,
    { id: number; data: OutingsControllerRequestDeleteBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerRequestDelete>>,
    { id: number; data: OutingsControllerRequestDeleteBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsControllerRequestDelete(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerRequestDelete>>,
    TError,
    { id: number; data: OutingsControllerRequestDeleteBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 반려
 */
export const outingsControllerDeny = (
  id: number,
  outingsControllerDenyBody: OutingsControllerDenyBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/outings/deny/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outingsControllerDenyBody,
    },
    options,
  );
};

export type OutingsControllerDenyMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerDeny>>
>;
export type OutingsControllerDenyMutationBody = OutingsControllerDenyBody;
export type OutingsControllerDenyMutationError = ErrorType<unknown>;

export const useOutingsControllerDeny = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerDeny>>,
    TError,
    { id: number; data: OutingsControllerDenyBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerDeny>>,
    { id: number; data: OutingsControllerDenyBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsControllerDeny(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerDeny>>,
    TError,
    { id: number; data: OutingsControllerDenyBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 조퇴/외출/확인 승인
 */
export const outingsControllerApprove = (
  id: number,
  outingsControllerApproveBody: OutingsControllerApproveBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/outings/approve/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: outingsControllerApproveBody,
    },
    options,
  );
};

export type OutingsControllerApproveMutationResult = NonNullable<
  Awaited<ReturnType<typeof outingsControllerApprove>>
>;
export type OutingsControllerApproveMutationBody = OutingsControllerApproveBody;
export type OutingsControllerApproveMutationError = ErrorType<unknown>;

export const useOutingsControllerApprove = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outingsControllerApprove>>,
    TError,
    { id: number; data: OutingsControllerApproveBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outingsControllerApprove>>,
    { id: number; data: OutingsControllerApproveBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return outingsControllerApprove(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof outingsControllerApprove>>,
    TError,
    { id: number; data: OutingsControllerApproveBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 그룹 목록(+유저정보) 조회(선생님용)
 */
export const studentGroupsControllerFindByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentGroup[]>(
    { url: `/api/student_groups/by-group/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getStudentGroupsControllerFindByGroupIdQueryKey = (groupId: number) => [
  `/api/student_groups/by-group/${groupId}`,
];

export type StudentGroupsControllerFindByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsControllerFindByGroupId>>
>;
export type StudentGroupsControllerFindByGroupIdQueryError = ErrorType<unknown>;

export const useStudentGroupsControllerFindByGroupId = <
  TData = Awaited<ReturnType<typeof studentGroupsControllerFindByGroupId>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentGroupsControllerFindByGroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentGroupsControllerFindByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentGroupsControllerFindByGroupId>>
  > = ({ signal }) => studentGroupsControllerFindByGroupId(groupId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentGroupsControllerFindByGroupId>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!groupId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 명렬표 다운로드(선생님용)
 */
export const studentGroupsControllerDownloadStudentListByGroupId = (
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Blob>(
    { url: `/api/student_groups/download/${groupId}`, method: 'get', responseType: 'blob', signal },
    options,
  );
};

export const getStudentGroupsControllerDownloadStudentListByGroupIdQueryKey = (groupId: number) => [
  `/api/student_groups/download/${groupId}`,
];

export type StudentGroupsControllerDownloadStudentListByGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsControllerDownloadStudentListByGroupId>>
>;
export type StudentGroupsControllerDownloadStudentListByGroupIdQueryError = ErrorType<unknown>;

export const useStudentGroupsControllerDownloadStudentListByGroupId = <
  TData = Awaited<ReturnType<typeof studentGroupsControllerDownloadStudentListByGroupId>>,
  TError = ErrorType<unknown>,
>(
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentGroupsControllerDownloadStudentListByGroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStudentGroupsControllerDownloadStudentListByGroupIdQueryKey(groupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentGroupsControllerDownloadStudentListByGroupId>>
  > = ({ signal }) =>
    studentGroupsControllerDownloadStudentListByGroupId(groupId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentGroupsControllerDownloadStudentListByGroupId>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!groupId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 klass history(학생 마이페이지에서 사용)
 */
export const studentGroupsControllerFindKlassHistoryByStudent = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentGroup[]>(
    { url: `/api/student_groups/klass-history-by-student`, method: 'get', signal },
    options,
  );
};

export const getStudentGroupsControllerFindKlassHistoryByStudentQueryKey = () => [
  `/api/student_groups/klass-history-by-student`,
];

export type StudentGroupsControllerFindKlassHistoryByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsControllerFindKlassHistoryByStudent>>
>;
export type StudentGroupsControllerFindKlassHistoryByStudentQueryError = ErrorType<unknown>;

export const useStudentGroupsControllerFindKlassHistoryByStudent = <
  TData = Awaited<ReturnType<typeof studentGroupsControllerFindKlassHistoryByStudent>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof studentGroupsControllerFindKlassHistoryByStudent>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentGroupsControllerFindKlassHistoryByStudentQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentGroupsControllerFindKlassHistoryByStudent>>
  > = ({ signal }) => studentGroupsControllerFindKlassHistoryByStudent(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentGroupsControllerFindKlassHistoryByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 학년 그룹 목록 조회
 */
export const studentGroupsControllerFindAllByKlasses = (
  params: StudentGroupsControllerFindAllByKlassesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentGroup[]>(
    { url: `/api/student_groups/by-klasses`, method: 'get', params, signal },
    options,
  );
};

export const getStudentGroupsControllerFindAllByKlassesQueryKey = (
  params: StudentGroupsControllerFindAllByKlassesParams,
) => [`/api/student_groups/by-klasses`, ...(params ? [params] : [])];

export type StudentGroupsControllerFindAllByKlassesQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentGroupsControllerFindAllByKlasses>>
>;
export type StudentGroupsControllerFindAllByKlassesQueryError = ErrorType<unknown>;

export const useStudentGroupsControllerFindAllByKlasses = <
  TData = Awaited<ReturnType<typeof studentGroupsControllerFindAllByKlasses>>,
  TError = ErrorType<unknown>,
>(
  params: StudentGroupsControllerFindAllByKlassesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentGroupsControllerFindAllByKlasses>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentGroupsControllerFindAllByKlassesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentGroupsControllerFindAllByKlasses>>
  > = ({ signal }) => studentGroupsControllerFindAllByKlasses(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentGroupsControllerFindAllByKlasses>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 레거시 캘린더 목록
 */
export const schedulesControllerGetGroupAdd = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/schedules/legacy`, method: 'get', signal }, options);
};

export const getSchedulesControllerGetGroupAddQueryKey = () => [`/api/schedules/legacy`];

export type SchedulesControllerGetGroupAddQueryResult = NonNullable<
  Awaited<ReturnType<typeof schedulesControllerGetGroupAdd>>
>;
export type SchedulesControllerGetGroupAddQueryError = ErrorType<unknown>;

export const useSchedulesControllerGetGroupAdd = <
  TData = Awaited<ReturnType<typeof schedulesControllerGetGroupAdd>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof schedulesControllerGetGroupAdd>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchedulesControllerGetGroupAddQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulesControllerGetGroupAdd>>> = ({
    signal,
  }) => schedulesControllerGetGroupAdd(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schedulesControllerGetGroupAdd>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 캘린더 목록
 */
export const schedulesControllerFindAll = (
  params: SchedulesControllerFindAllParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Schedule[]>(
    { url: `/api/schedules`, method: 'get', params, signal },
    options,
  );
};

export const getSchedulesControllerFindAllQueryKey = (params: SchedulesControllerFindAllParams) => [
  `/api/schedules`,
  ...(params ? [params] : []),
];

export type SchedulesControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof schedulesControllerFindAll>>
>;
export type SchedulesControllerFindAllQueryError = ErrorType<unknown>;

export const useSchedulesControllerFindAll = <
  TData = Awaited<ReturnType<typeof schedulesControllerFindAll>>,
  TError = ErrorType<unknown>,
>(
  params: SchedulesControllerFindAllParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schedulesControllerFindAll>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchedulesControllerFindAllQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulesControllerFindAll>>> = ({
    signal,
  }) => schedulesControllerFindAll(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schedulesControllerFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 캘린더 추가
 */
export const schedulesControllerCreate = (
  requestCreateScheduleDto: RequestCreateScheduleDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Schedule>(
    {
      url: `/api/schedules`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateScheduleDto,
    },
    options,
  );
};

export type SchedulesControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof schedulesControllerCreate>>
>;
export type SchedulesControllerCreateMutationBody = RequestCreateScheduleDto;
export type SchedulesControllerCreateMutationError = ErrorType<void>;

export const useSchedulesControllerCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof schedulesControllerCreate>>,
    TError,
    { data: RequestCreateScheduleDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof schedulesControllerCreate>>,
    { data: RequestCreateScheduleDto }
  > = (props) => {
    const { data } = props ?? {};

    return schedulesControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof schedulesControllerCreate>>,
    TError,
    { data: RequestCreateScheduleDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 체험학습 신청불가 캘린더 목록(학생용)
 */
export const schedulesControllerFindRejectSchedule = (
  params: SchedulesControllerFindRejectScheduleParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Schedule[]>(
    { url: `/api/schedules/reject`, method: 'get', params, signal },
    options,
  );
};

export const getSchedulesControllerFindRejectScheduleQueryKey = (
  params: SchedulesControllerFindRejectScheduleParams,
) => [`/api/schedules/reject`, ...(params ? [params] : [])];

export type SchedulesControllerFindRejectScheduleQueryResult = NonNullable<
  Awaited<ReturnType<typeof schedulesControllerFindRejectSchedule>>
>;
export type SchedulesControllerFindRejectScheduleQueryError = ErrorType<unknown>;

export const useSchedulesControllerFindRejectSchedule = <
  TData = Awaited<ReturnType<typeof schedulesControllerFindRejectSchedule>>,
  TError = ErrorType<unknown>,
>(
  params: SchedulesControllerFindRejectScheduleParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof schedulesControllerFindRejectSchedule>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSchedulesControllerFindRejectScheduleQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof schedulesControllerFindRejectSchedule>>
  > = ({ signal }) => schedulesControllerFindRejectSchedule(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof schedulesControllerFindRejectSchedule>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 캘린더 수정
 */
export const schedulesControllerUpdate = (
  id: number,
  requestCreateScheduleDto: RequestCreateScheduleDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Schedule>(
    {
      url: `/api/schedules/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateScheduleDto,
    },
    options,
  );
};

export type SchedulesControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof schedulesControllerUpdate>>
>;
export type SchedulesControllerUpdateMutationBody = RequestCreateScheduleDto;
export type SchedulesControllerUpdateMutationError = ErrorType<void>;

export const useSchedulesControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof schedulesControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateScheduleDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof schedulesControllerUpdate>>,
    { id: number; data: RequestCreateScheduleDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return schedulesControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof schedulesControllerUpdate>>,
    TError,
    { id: number; data: RequestCreateScheduleDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 캘린더 삭제
 */
export const schedulesControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/schedules/${id}`, method: 'delete' }, options);
};

export type SchedulesControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof schedulesControllerDelete>>
>;

export type SchedulesControllerDeleteMutationError = ErrorType<unknown>;

export const useSchedulesControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof schedulesControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof schedulesControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return schedulesControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof schedulesControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학교 목록
 */
export const schoolsControllerFindAll = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<School[]>({ url: `/api/schools`, method: 'get', signal }, options);
};

export const getSchoolsControllerFindAllQueryKey = () => [`/api/schools`];

export type SchoolsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolsControllerFindAll>>
>;
export type SchoolsControllerFindAllQueryError = ErrorType<unknown>;

export const useSchoolsControllerFindAll = <
  TData = Awaited<ReturnType<typeof schoolsControllerFindAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof schoolsControllerFindAll>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolsControllerFindAllQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolsControllerFindAll>>> = ({
    signal,
  }) => schoolsControllerFindAll(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolsControllerFindAll>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학교 단건 조회
 */
export const schoolsControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<School>({ url: `/api/schools/${id}`, method: 'get', signal }, options);
};

export const getSchoolsControllerFindOneQueryKey = (id: number) => [`/api/schools/${id}`];

export type SchoolsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof schoolsControllerFindOne>>
>;
export type SchoolsControllerFindOneQueryError = ErrorType<unknown>;

export const useSchoolsControllerFindOne = <
  TData = Awaited<ReturnType<typeof schoolsControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof schoolsControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSchoolsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof schoolsControllerFindOne>>> = ({
    signal,
  }) => schoolsControllerFindOne(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof schoolsControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록 목록 조회
 */
export const summariesControllerFindByStudentGroupId = (
  params: SummariesControllerFindByStudentGroupIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Summary[]>(
    { url: `/api/summaries`, method: 'get', params, signal },
    options,
  );
};

export const getSummariesControllerFindByStudentGroupIdQueryKey = (
  params: SummariesControllerFindByStudentGroupIdParams,
) => [`/api/summaries`, ...(params ? [params] : [])];

export type SummariesControllerFindByStudentGroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof summariesControllerFindByStudentGroupId>>
>;
export type SummariesControllerFindByStudentGroupIdQueryError = ErrorType<unknown>;

export const useSummariesControllerFindByStudentGroupId = <
  TData = Awaited<ReturnType<typeof summariesControllerFindByStudentGroupId>>,
  TError = ErrorType<unknown>,
>(
  params: SummariesControllerFindByStudentGroupIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof summariesControllerFindByStudentGroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSummariesControllerFindByStudentGroupIdQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof summariesControllerFindByStudentGroupId>>
  > = ({ signal }) => summariesControllerFindByStudentGroupId(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof summariesControllerFindByStudentGroupId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 생활기록 생성
 */
export const summariesControllerCreate = (
  requestCreateSummaryDto: RequestCreateSummaryDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Summary>(
    {
      url: `/api/summaries`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateSummaryDto,
    },
    options,
  );
};

export type SummariesControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof summariesControllerCreate>>
>;
export type SummariesControllerCreateMutationBody = RequestCreateSummaryDto;
export type SummariesControllerCreateMutationError = ErrorType<unknown>;

export const useSummariesControllerCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summariesControllerCreate>>,
    TError,
    { data: RequestCreateSummaryDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof summariesControllerCreate>>,
    { data: RequestCreateSummaryDto }
  > = (props) => {
    const { data } = props ?? {};

    return summariesControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof summariesControllerCreate>>,
    TError,
    { data: RequestCreateSummaryDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 생활기록 수정
 */
export const summariesControllerUpdate = (
  id: number,
  summariesControllerUpdateBody: SummariesControllerUpdateBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<boolean>(
    {
      url: `/api/summaries/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: summariesControllerUpdateBody,
    },
    options,
  );
};

export type SummariesControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof summariesControllerUpdate>>
>;
export type SummariesControllerUpdateMutationBody = SummariesControllerUpdateBody;
export type SummariesControllerUpdateMutationError = ErrorType<void>;

export const useSummariesControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summariesControllerUpdate>>,
    TError,
    { id: number; data: SummariesControllerUpdateBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof summariesControllerUpdate>>,
    { id: number; data: SummariesControllerUpdateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return summariesControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof summariesControllerUpdate>>,
    TError,
    { id: number; data: SummariesControllerUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 생활기록 삭제
 */
export const summariesControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/summaries/${id}`, method: 'delete' }, options);
};

export type SummariesControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof summariesControllerDelete>>
>;

export type SummariesControllerDeleteMutationError = ErrorType<unknown>;

export const useSummariesControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summariesControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof summariesControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return summariesControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof summariesControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생 역할 등록/수정
 */
export const studentRolesControllerUpsertStudentRole = (
  requestUpsertStudentRoleDto: RequestUpsertStudentRoleDto[],
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<boolean>(
    {
      url: `/api/student-role/upsert`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertStudentRoleDto,
    },
    options,
  );
};

export type StudentRolesControllerUpsertStudentRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentRolesControllerUpsertStudentRole>>
>;
export type StudentRolesControllerUpsertStudentRoleMutationBody = RequestUpsertStudentRoleDto[];
export type StudentRolesControllerUpsertStudentRoleMutationError = ErrorType<unknown>;

export const useStudentRolesControllerUpsertStudentRole = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentRolesControllerUpsertStudentRole>>,
    TError,
    { data: RequestUpsertStudentRoleDto[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentRolesControllerUpsertStudentRole>>,
    { data: RequestUpsertStudentRoleDto[] }
  > = (props) => {
    const { data } = props ?? {};

    return studentRolesControllerUpsertStudentRole(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentRolesControllerUpsertStudentRole>>,
    TError,
    { data: RequestUpsertStudentRoleDto[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동 목록 조회(선생님용)
 */
export const studentActivityControllerFindByUserId = (
  params: StudentActivityControllerFindByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentActivity[]>(
    { url: `/api/student-activities`, method: 'get', params, signal },
    options,
  );
};

export const getStudentActivityControllerFindByUserIdQueryKey = (
  params: StudentActivityControllerFindByUserIdParams,
) => [`/api/student-activities`, ...(params ? [params] : [])];

export type StudentActivityControllerFindByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityControllerFindByUserId>>
>;
export type StudentActivityControllerFindByUserIdQueryError = ErrorType<void>;

export const useStudentActivityControllerFindByUserId = <
  TData = Awaited<ReturnType<typeof studentActivityControllerFindByUserId>>,
  TError = ErrorType<void>,
>(
  params: StudentActivityControllerFindByUserIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentActivityControllerFindByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentActivityControllerFindByUserIdQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentActivityControllerFindByUserId>>
  > = ({ signal }) => studentActivityControllerFindByUserId(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentActivityControllerFindByUserId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생활동 단건 조회(선생님용)
 */
export const studentActivityControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentActivity>(
    { url: `/api/student-activities/${id}`, method: 'get', signal },
    options,
  );
};

export const getStudentActivityControllerFindOneQueryKey = (id: number) => [
  `/api/student-activities/${id}`,
];

export type StudentActivityControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityControllerFindOne>>
>;
export type StudentActivityControllerFindOneQueryError = ErrorType<void>;

export const useStudentActivityControllerFindOne = <
  TData = Awaited<ReturnType<typeof studentActivityControllerFindOne>>,
  TError = ErrorType<void>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentActivityControllerFindOne>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentActivityControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentActivityControllerFindOne>>> = ({
    signal,
  }) => studentActivityControllerFindOne(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentActivityControllerFindOne>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생활동 수정(없으면 추가함)
 */
export const studentActivityControllerUpdate = (
  id: number,
  requestUpdateStudentActivityDto: RequestUpdateStudentActivityDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<StudentActivity>(
    {
      url: `/api/student-activities/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateStudentActivityDto,
    },
    options,
  );
};

export type StudentActivityControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityControllerUpdate>>
>;
export type StudentActivityControllerUpdateMutationBody = RequestUpdateStudentActivityDto;
export type StudentActivityControllerUpdateMutationError = ErrorType<void>;

export const useStudentActivityControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityControllerUpdate>>,
    TError,
    { id: number; data: RequestUpdateStudentActivityDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityControllerUpdate>>,
    { id: number; data: RequestUpdateStudentActivityDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentActivityControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityControllerUpdate>>,
    TError,
    { id: number; data: RequestUpdateStudentActivityDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동 단건 조회(학생용)
 */
export const studentActivityControllerFindOneByActivityId = (
  activityId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentActivity>(
    { url: `/api/student-activities/by-student/${activityId}`, method: 'get', signal },
    options,
  );
};

export const getStudentActivityControllerFindOneByActivityIdQueryKey = (activityId: number) => [
  `/api/student-activities/by-student/${activityId}`,
];

export type StudentActivityControllerFindOneByActivityIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityControllerFindOneByActivityId>>
>;
export type StudentActivityControllerFindOneByActivityIdQueryError = ErrorType<void>;

export const useStudentActivityControllerFindOneByActivityId = <
  TData = Awaited<ReturnType<typeof studentActivityControllerFindOneByActivityId>>,
  TError = ErrorType<void>,
>(
  activityId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentActivityControllerFindOneByActivityId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentActivityControllerFindOneByActivityIdQueryKey(activityId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentActivityControllerFindOneByActivityId>>
  > = ({ signal }) =>
    studentActivityControllerFindOneByActivityId(activityId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentActivityControllerFindOneByActivityId>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!activityId, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 피드백 업데이트
 */
export const studentActivityControllerUpdateFeedback = (
  id: number,
  studentActivityControllerUpdateFeedbackBody: StudentActivityControllerUpdateFeedbackBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<StudentActivity>(
    {
      url: `/api/student-activities/update-feedback/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: studentActivityControllerUpdateFeedbackBody,
    },
    options,
  );
};

export type StudentActivityControllerUpdateFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityControllerUpdateFeedback>>
>;
export type StudentActivityControllerUpdateFeedbackMutationBody =
  StudentActivityControllerUpdateFeedbackBody;
export type StudentActivityControllerUpdateFeedbackMutationError = ErrorType<void>;

export const useStudentActivityControllerUpdateFeedback = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityControllerUpdateFeedback>>,
    TError,
    { id: number; data: StudentActivityControllerUpdateFeedbackBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityControllerUpdateFeedback>>,
    { id: number; data: StudentActivityControllerUpdateFeedbackBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentActivityControllerUpdateFeedback(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityControllerUpdateFeedback>>,
    TError,
    { id: number; data: StudentActivityControllerUpdateFeedbackBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동댓글 목록 조회
 */
export const studentActivityCommentControllerFindAll = (
  studentActivityId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Comment[]>(
    { url: `/api/student-activity-comment/${studentActivityId}`, method: 'get', signal },
    options,
  );
};

export const getStudentActivityCommentControllerFindAllQueryKey = (studentActivityId: number) => [
  `/api/student-activity-comment/${studentActivityId}`,
];

export type StudentActivityCommentControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentControllerFindAll>>
>;
export type StudentActivityCommentControllerFindAllQueryError = ErrorType<unknown>;

export const useStudentActivityCommentControllerFindAll = <
  TData = Awaited<ReturnType<typeof studentActivityCommentControllerFindAll>>,
  TError = ErrorType<unknown>,
>(
  studentActivityId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentActivityCommentControllerFindAll>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentActivityCommentControllerFindAllQueryKey(studentActivityId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentActivityCommentControllerFindAll>>
  > = ({ signal }) =>
    studentActivityCommentControllerFindAll(studentActivityId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentActivityCommentControllerFindAll>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!studentActivityId, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생활동댓글 추가
 */
export const studentActivityCommentControllerCreate = (
  requestCreateActivityCommentDto: RequestCreateActivityCommentDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Comment>(
    {
      url: `/api/student-activity-comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateActivityCommentDto,
    },
    options,
  );
};

export type StudentActivityCommentControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentControllerCreate>>
>;
export type StudentActivityCommentControllerCreateMutationBody = RequestCreateActivityCommentDto;
export type StudentActivityCommentControllerCreateMutationError = ErrorType<void>;

export const useStudentActivityCommentControllerCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityCommentControllerCreate>>,
    TError,
    { data: RequestCreateActivityCommentDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityCommentControllerCreate>>,
    { data: RequestCreateActivityCommentDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentActivityCommentControllerCreate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityCommentControllerCreate>>,
    TError,
    { data: RequestCreateActivityCommentDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동댓글 수정
 */
export const studentActivityCommentControllerUpdate = (
  id: number,
  studentActivityCommentControllerUpdateBody: StudentActivityCommentControllerUpdateBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Comment>(
    {
      url: `/api/student-activity-comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: studentActivityCommentControllerUpdateBody,
    },
    options,
  );
};

export type StudentActivityCommentControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentControllerUpdate>>
>;
export type StudentActivityCommentControllerUpdateMutationBody =
  StudentActivityCommentControllerUpdateBody;
export type StudentActivityCommentControllerUpdateMutationError = ErrorType<void>;

export const useStudentActivityCommentControllerUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityCommentControllerUpdate>>,
    TError,
    { id: number; data: StudentActivityCommentControllerUpdateBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityCommentControllerUpdate>>,
    { id: number; data: StudentActivityCommentControllerUpdateBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return studentActivityCommentControllerUpdate(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityCommentControllerUpdate>>,
    TError,
    { id: number; data: StudentActivityCommentControllerUpdateBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생활동댓글 삭제
 */
export const studentActivityCommentControllerDelete = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/api/student-activity-comment/${id}`, method: 'delete' },
    options,
  );
};

export type StudentActivityCommentControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentActivityCommentControllerDelete>>
>;

export type StudentActivityCommentControllerDeleteMutationError = ErrorType<unknown>;

export const useStudentActivityCommentControllerDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentActivityCommentControllerDelete>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentActivityCommentControllerDelete>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return studentActivityCommentControllerDelete(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentActivityCommentControllerDelete>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문(학생설문) 단건 조회(선생님용)
 */
export const studentNewsletterControllerFindOne = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentNewsletter>(
    { url: `/api/student-newsletters/${id}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterControllerFindOneQueryKey = (id: number) => [
  `/api/student-newsletters/${id}`,
];

export type StudentNewsletterControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterControllerFindOne>>
>;
export type StudentNewsletterControllerFindOneQueryError = ErrorType<unknown>;

export const useStudentNewsletterControllerFindOne = <
  TData = Awaited<ReturnType<typeof studentNewsletterControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentNewsletterControllerFindOne>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getStudentNewsletterControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof studentNewsletterControllerFindOne>>> = ({
    signal,
  }) => studentNewsletterControllerFindOne(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentNewsletterControllerFindOne>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 단건 조회(학생용)
 */
export const studentNewsletterControllerFindOneByStudent = (
  newsletterId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentNewsletter>(
    { url: `/api/student-newsletters/by-student/${newsletterId}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterControllerFindOneByStudentQueryKey = (newsletterId: number) => [
  `/api/student-newsletters/by-student/${newsletterId}`,
];

export type StudentNewsletterControllerFindOneByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterControllerFindOneByStudent>>
>;
export type StudentNewsletterControllerFindOneByStudentQueryError = ErrorType<unknown>;

export const useStudentNewsletterControllerFindOneByStudent = <
  TData = Awaited<ReturnType<typeof studentNewsletterControllerFindOneByStudent>>,
  TError = ErrorType<unknown>,
>(
  newsletterId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentNewsletterControllerFindOneByStudent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentNewsletterControllerFindOneByStudentQueryKey(newsletterId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentNewsletterControllerFindOneByStudent>>
  > = ({ signal }) =>
    studentNewsletterControllerFindOneByStudent(newsletterId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentNewsletterControllerFindOneByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!newsletterId, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 목록 조회(by newsletterId)
 */
export const studentNewsletterControllerFindAllByNewsletterId = (
  newsletterId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentNewsletter[]>(
    { url: `/api/student-newsletters/by-newsletter-id/${newsletterId}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterControllerFindAllByNewsletterIdQueryKey = (
  newsletterId: number,
) => [`/api/student-newsletters/by-newsletter-id/${newsletterId}`];

export type StudentNewsletterControllerFindAllByNewsletterIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterControllerFindAllByNewsletterId>>
>;
export type StudentNewsletterControllerFindAllByNewsletterIdQueryError = ErrorType<void>;

export const useStudentNewsletterControllerFindAllByNewsletterId = <
  TData = Awaited<ReturnType<typeof studentNewsletterControllerFindAllByNewsletterId>>,
  TError = ErrorType<void>,
>(
  newsletterId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentNewsletterControllerFindAllByNewsletterId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStudentNewsletterControllerFindAllByNewsletterIdQueryKey(newsletterId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentNewsletterControllerFindAllByNewsletterId>>
  > = ({ signal }) =>
    studentNewsletterControllerFindAllByNewsletterId(newsletterId, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentNewsletterControllerFindAllByNewsletterId>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!newsletterId, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 단건 조회(by UUID)
 */
export const studentNewsletterControllerFindOneByUUID = (
  uuid: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<StudentNewsletter>(
    { url: `/api/student-newsletters/by-uuid/${uuid}`, method: 'get', signal },
    options,
  );
};

export const getStudentNewsletterControllerFindOneByUUIDQueryKey = (uuid: string) => [
  `/api/student-newsletters/by-uuid/${uuid}`,
];

export type StudentNewsletterControllerFindOneByUUIDQueryResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterControllerFindOneByUUID>>
>;
export type StudentNewsletterControllerFindOneByUUIDQueryError = ErrorType<unknown>;

export const useStudentNewsletterControllerFindOneByUUID = <
  TData = Awaited<ReturnType<typeof studentNewsletterControllerFindOneByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof studentNewsletterControllerFindOneByUUID>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getStudentNewsletterControllerFindOneByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof studentNewsletterControllerFindOneByUUID>>
  > = ({ signal }) => studentNewsletterControllerFindOneByUUID(uuid, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof studentNewsletterControllerFindOneByUUID>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!uuid, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 가정통신문(학생설문) 승인
 */
export const studentNewsletterControllerApprove = (
  uuid: string,
  requestApproveStudentNewsletterDto: RequestApproveStudentNewsletterDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/student-newsletters/approve/${uuid}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestApproveStudentNewsletterDto,
    },
    options,
  );
};

export type StudentNewsletterControllerApproveMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterControllerApprove>>
>;
export type StudentNewsletterControllerApproveMutationBody = RequestApproveStudentNewsletterDto;
export type StudentNewsletterControllerApproveMutationError = ErrorType<unknown>;

export const useStudentNewsletterControllerApprove = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentNewsletterControllerApprove>>,
    TError,
    { uuid: string; data: RequestApproveStudentNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentNewsletterControllerApprove>>,
    { uuid: string; data: RequestApproveStudentNewsletterDto }
  > = (props) => {
    const { uuid, data } = props ?? {};

    return studentNewsletterControllerApprove(uuid, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentNewsletterControllerApprove>>,
    TError,
    { uuid: string; data: RequestApproveStudentNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 가정통신문(학생설문) 답변
 */
export const studentNewsletterControllerUpsert = (
  requestUpsertStudentNewsletterDto: RequestUpsertStudentNewsletterDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<StudentNewsletter>(
    {
      url: `/api/student-newsletters`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpsertStudentNewsletterDto,
    },
    options,
  );
};

export type StudentNewsletterControllerUpsertMutationResult = NonNullable<
  Awaited<ReturnType<typeof studentNewsletterControllerUpsert>>
>;
export type StudentNewsletterControllerUpsertMutationBody = RequestUpsertStudentNewsletterDto;
export type StudentNewsletterControllerUpsertMutationError = ErrorType<unknown>;

export const useStudentNewsletterControllerUpsert = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof studentNewsletterControllerUpsert>>,
    TError,
    { data: RequestUpsertStudentNewsletterDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof studentNewsletterControllerUpsert>>,
    { data: RequestUpsertStudentNewsletterDto }
  > = (props) => {
    const { data } = props ?? {};

    return studentNewsletterControllerUpsert(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof studentNewsletterControllerUpsert>>,
    TError,
    { data: RequestUpsertStudentNewsletterDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @deprecated
 * @summary 교사 그룹 목록 조회, group controller 조회 사용하도록 변경
 */
export const teacherGroupsControllerGetTeacherGroups = (
  params: TeacherGroupsControllerGetTeacherGroupsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/teacher-groups`, method: 'get', params, signal },
    options,
  );
};

export const getTeacherGroupsControllerGetTeacherGroupsQueryKey = (
  params: TeacherGroupsControllerGetTeacherGroupsParams,
) => [`/api/teacher-groups`, ...(params ? [params] : [])];

export type TeacherGroupsControllerGetTeacherGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherGroupsControllerGetTeacherGroups>>
>;
export type TeacherGroupsControllerGetTeacherGroupsQueryError = ErrorType<unknown>;

export const useTeacherGroupsControllerGetTeacherGroups = <
  TData = Awaited<ReturnType<typeof teacherGroupsControllerGetTeacherGroups>>,
  TError = ErrorType<unknown>,
>(
  params: TeacherGroupsControllerGetTeacherGroupsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof teacherGroupsControllerGetTeacherGroups>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTeacherGroupsControllerGetTeacherGroupsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teacherGroupsControllerGetTeacherGroups>>
  > = ({ signal }) => teacherGroupsControllerGetTeacherGroups(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof teacherGroupsControllerGetTeacherGroups>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 선생님의 그룹 조회
 */
export const teacherGroupsControllerFindAllByTeacher = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TeacherGroup[]>(
    { url: `/api/teacher-groups/by-teacher`, method: 'get', signal },
    options,
  );
};

export const getTeacherGroupsControllerFindAllByTeacherQueryKey = () => [
  `/api/teacher-groups/by-teacher`,
];

export type TeacherGroupsControllerFindAllByTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof teacherGroupsControllerFindAllByTeacher>>
>;
export type TeacherGroupsControllerFindAllByTeacherQueryError = ErrorType<unknown>;

export const useTeacherGroupsControllerFindAllByTeacher = <
  TData = Awaited<ReturnType<typeof teacherGroupsControllerFindAllByTeacher>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof teacherGroupsControllerFindAllByTeacher>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTeacherGroupsControllerFindAllByTeacherQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof teacherGroupsControllerFindAllByTeacher>>
  > = ({ signal }) => teacherGroupsControllerFindAllByTeacher(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof teacherGroupsControllerFindAllByTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 단건 조회
 */
export const timetablesControllerFindOne = (
  year: number,
  semester: number,
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Timetable>(
    { url: `/api/timetable-v1/${year}/${semester}/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getTimetablesControllerFindOneQueryKey = (
  year: number,
  semester: number,
  groupId: number,
) => [`/api/timetable-v1/${year}/${semester}/${groupId}`];

export type TimetablesControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablesControllerFindOne>>
>;
export type TimetablesControllerFindOneQueryError = ErrorType<unknown>;

export const useTimetablesControllerFindOne = <
  TData = Awaited<ReturnType<typeof timetablesControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  year: number,
  semester: number,
  groupId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof timetablesControllerFindOne>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTimetablesControllerFindOneQueryKey(year, semester, groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesControllerFindOne>>> = ({
    signal,
  }) => timetablesControllerFindOne(year, semester, groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(year && semester && groupId), ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 추가/수정
 */
export const timetablesControllerCreateOrUpdate = (
  requestCreateTimetableDto: RequestCreateTimetableDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Timetable>(
    {
      url: `/api/timetable-v1`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestCreateTimetableDto,
    },
    options,
  );
};

export type TimetablesControllerCreateOrUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetablesControllerCreateOrUpdate>>
>;
export type TimetablesControllerCreateOrUpdateMutationBody = RequestCreateTimetableDto;
export type TimetablesControllerCreateOrUpdateMutationError = ErrorType<void>;

export const useTimetablesControllerCreateOrUpdate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetablesControllerCreateOrUpdate>>,
    TError,
    { data: RequestCreateTimetableDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetablesControllerCreateOrUpdate>>,
    { data: RequestCreateTimetableDto }
  > = (props) => {
    const { data } = props ?? {};

    return timetablesControllerCreateOrUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetablesControllerCreateOrUpdate>>,
    TError,
    { data: RequestCreateTimetableDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 시간표 v2 단건 조회
 */
export const timetablesV2ControllerFindOne = (
  year: number,
  semester: number,
  groupId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TimetableV2>(
    { url: `/api/timetable/${year}/${semester}/${groupId}`, method: 'get', signal },
    options,
  );
};

export const getTimetablesV2ControllerFindOneQueryKey = (
  year: number,
  semester: number,
  groupId: number,
) => [`/api/timetable/${year}/${semester}/${groupId}`];

export type TimetablesV2ControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2ControllerFindOne>>
>;
export type TimetablesV2ControllerFindOneQueryError = ErrorType<unknown>;

export const useTimetablesV2ControllerFindOne = <
  TData = Awaited<ReturnType<typeof timetablesV2ControllerFindOne>>,
  TError = ErrorType<unknown>,
>(
  year: number,
  semester: number,
  groupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof timetablesV2ControllerFindOne>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTimetablesV2ControllerFindOneQueryKey(year, semester, groupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesV2ControllerFindOne>>> = ({
    signal,
  }) => timetablesV2ControllerFindOne(year, semester, groupId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof timetablesV2ControllerFindOne>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(year && semester && groupId), ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 v2 선생님 목록
 */
export const timetablesV2ControllerGetAllTeacher = (
  params: TimetablesV2ControllerGetAllTeacherParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseTimetableV2TeacherDto[]>(
    { url: `/api/timetable/allteacher`, method: 'get', params, signal },
    options,
  );
};

export const getTimetablesV2ControllerGetAllTeacherQueryKey = (
  params: TimetablesV2ControllerGetAllTeacherParams,
) => [`/api/timetable/allteacher`, ...(params ? [params] : [])];

export type TimetablesV2ControllerGetAllTeacherQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2ControllerGetAllTeacher>>
>;
export type TimetablesV2ControllerGetAllTeacherQueryError = ErrorType<unknown>;

export const useTimetablesV2ControllerGetAllTeacher = <
  TData = Awaited<ReturnType<typeof timetablesV2ControllerGetAllTeacher>>,
  TError = ErrorType<unknown>,
>(
  params: TimetablesV2ControllerGetAllTeacherParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof timetablesV2ControllerGetAllTeacher>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTimetablesV2ControllerGetAllTeacherQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof timetablesV2ControllerGetAllTeacher>>> = ({
    signal,
  }) => timetablesV2ControllerGetAllTeacher(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof timetablesV2ControllerGetAllTeacher>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생 시간표 v2
 */
export const timetablesV2ControllerFindOneByStudent = (
  params: TimetablesV2ControllerFindOneByStudentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TimetableV2Student>(
    { url: `/api/timetable/by-student`, method: 'get', params, signal },
    options,
  );
};

export const getTimetablesV2ControllerFindOneByStudentQueryKey = (
  params: TimetablesV2ControllerFindOneByStudentParams,
) => [`/api/timetable/by-student`, ...(params ? [params] : [])];

export type TimetablesV2ControllerFindOneByStudentQueryResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2ControllerFindOneByStudent>>
>;
export type TimetablesV2ControllerFindOneByStudentQueryError = ErrorType<unknown>;

export const useTimetablesV2ControllerFindOneByStudent = <
  TData = Awaited<ReturnType<typeof timetablesV2ControllerFindOneByStudent>>,
  TError = ErrorType<unknown>,
>(
  params: TimetablesV2ControllerFindOneByStudentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof timetablesV2ControllerFindOneByStudent>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTimetablesV2ControllerFindOneByStudentQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof timetablesV2ControllerFindOneByStudent>>
  > = ({ signal }) => timetablesV2ControllerFindOneByStudent(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof timetablesV2ControllerFindOneByStudent>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 시간표 v2 자리배치도 업데이트
 */
export const timetablesV2ControllerUpdateSeatPosition = (
  id: number,
  timetablesV2ControllerUpdateSeatPositionBody: TimetablesV2ControllerUpdateSeatPositionBody,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TimetableV2>(
    {
      url: `/api/timetable/update-seat-position/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: timetablesV2ControllerUpdateSeatPositionBody,
    },
    options,
  );
};

export type TimetablesV2ControllerUpdateSeatPositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof timetablesV2ControllerUpdateSeatPosition>>
>;
export type TimetablesV2ControllerUpdateSeatPositionMutationBody =
  TimetablesV2ControllerUpdateSeatPositionBody;
export type TimetablesV2ControllerUpdateSeatPositionMutationError = ErrorType<unknown>;

export const useTimetablesV2ControllerUpdateSeatPosition = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof timetablesV2ControllerUpdateSeatPosition>>,
    TError,
    { id: number; data: TimetablesV2ControllerUpdateSeatPositionBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof timetablesV2ControllerUpdateSeatPosition>>,
    { id: number; data: TimetablesV2ControllerUpdateSeatPositionBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return timetablesV2ControllerUpdateSeatPosition(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof timetablesV2ControllerUpdateSeatPosition>>,
    TError,
    { id: number; data: TimetablesV2ControllerUpdateSeatPositionBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 아이디 찾기
 */
export const userControllerFindId = (
  params: UserControllerFindIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseFindIdDto>(
    { url: `/api/users/find-id`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerFindIdQueryKey = (params: UserControllerFindIdParams) => [
  `/api/users/find-id`,
  ...(params ? [params] : []),
];

export type UserControllerFindIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerFindId>>
>;
export type UserControllerFindIdQueryError = ErrorType<unknown>;

export const useUserControllerFindId = <
  TData = Awaited<ReturnType<typeof userControllerFindId>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerFindIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerFindId>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerFindIdQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerFindId>>> = ({ signal }) =>
    userControllerFindId(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerFindId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 비밀번호 찾기
 */
export const userControllerFindPassword = (
  params: UserControllerFindPasswordParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/users/find-password`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerFindPasswordQueryKey = (params: UserControllerFindPasswordParams) => [
  `/api/users/find-password`,
  ...(params ? [params] : []),
];

export type UserControllerFindPasswordQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerFindPassword>>
>;
export type UserControllerFindPasswordQueryError = ErrorType<unknown>;

export const useUserControllerFindPassword = <
  TData = Awaited<ReturnType<typeof userControllerFindPassword>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerFindPasswordParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerFindPassword>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerFindPasswordQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerFindPassword>>> = ({
    signal,
  }) => userControllerFindPassword(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerFindPassword>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 비밀번호 재설정
 */
export const userControllerResetPassword = (
  requestResetPasswordDto: RequestResetPasswordDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<boolean>(
    {
      url: `/api/users/reset-password`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestResetPasswordDto,
    },
    options,
  );
};

export type UserControllerResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerResetPassword>>
>;
export type UserControllerResetPasswordMutationBody = RequestResetPasswordDto;
export type UserControllerResetPasswordMutationError = ErrorType<void>;

export const useUserControllerResetPassword = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResetPassword>>,
    TError,
    { data: RequestResetPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerResetPassword>>,
    { data: RequestResetPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerResetPassword(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerResetPassword>>,
    TError,
    { data: RequestResetPasswordDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * 회원의 accessToken 을 얻어냅니다.
 * @summary 로그인 API
 */
export const userControllerLogin = (
  requestLoginDto: RequestLoginDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AuthResponse>(
    {
      url: `/api/users/login`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestLoginDto,
    },
    options,
  );
};

export type UserControllerLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerLogin>>
>;
export type UserControllerLoginMutationBody = RequestLoginDto;
export type UserControllerLoginMutationError = ErrorType<void>;

export const useUserControllerLogin = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerLogin>>,
    TError,
    { data: RequestLoginDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerLogin>>,
    { data: RequestLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerLogin(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerLogin>>,
    TError,
    { data: RequestLoginDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * 각 api header 의 child-user-id 사용 하도록 변경
 * @deprecated
 * @summary 자녀 계정으로 로그인 API(부모용)
 */
export const userControllerChildLoginByUUID = (
  uuid: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AuthResponse>(
    { url: `/api/users/children/login/${uuid}`, method: 'get', signal },
    options,
  );
};

export const getUserControllerChildLoginByUUIDQueryKey = (uuid: string) => [
  `/api/users/children/login/${uuid}`,
];

export type UserControllerChildLoginByUUIDQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerChildLoginByUUID>>
>;
export type UserControllerChildLoginByUUIDQueryError = ErrorType<unknown>;

export const useUserControllerChildLoginByUUID = <
  TData = Awaited<ReturnType<typeof userControllerChildLoginByUUID>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerChildLoginByUUID>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerChildLoginByUUIDQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerChildLoginByUUID>>> = ({
    signal,
  }) => userControllerChildLoginByUUID(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerChildLoginByUUID>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!uuid, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * 각 api header 의 child-user-id 사용 하도록 변경
 * @deprecated
 * @summary 자녀 계정으로 로그인 API(부모용), Deprecated /children/login/:uuid 사용
 */
export const userControllerChildLogin = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<AuthResponse>(
    { url: `/api/users/children/login`, method: 'get', signal },
    options,
  );
};

export const getUserControllerChildLoginQueryKey = () => [`/api/users/children/login`];

export type UserControllerChildLoginQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerChildLogin>>
>;
export type UserControllerChildLoginQueryError = ErrorType<unknown>;

export const useUserControllerChildLogin = <
  TData = Awaited<ReturnType<typeof userControllerChildLogin>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerChildLogin>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerChildLoginQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerChildLogin>>> = ({
    signal,
  }) => userControllerChildLogin(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerChildLogin>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 로그인 유저정보(부모) + 자녀조회
 */
export const userControllerMeWithChildren = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ParentDto>(
    { url: `/api/users/me/children`, method: 'get', signal },
    options,
  );
};

export const getUserControllerMeWithChildrenQueryKey = () => [`/api/users/me/children`];

export type UserControllerMeWithChildrenQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerMeWithChildren>>
>;
export type UserControllerMeWithChildrenQueryError = ErrorType<unknown>;

export const useUserControllerMeWithChildren = <
  TData = Awaited<ReturnType<typeof userControllerMeWithChildren>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerMeWithChildren>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerMeWithChildrenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerMeWithChildren>>> = ({
    signal,
  }) => userControllerMeWithChildren(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerMeWithChildren>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @deprecated
 * @summary 로그인 유저정보 조회2
 */
export const userControllerMeGrades = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserDto>(
    { url: `/api/users/me/grades`, method: 'get', signal },
    options,
  );
};

export const getUserControllerMeGradesQueryKey = () => [`/api/users/me/grades`];

export type UserControllerMeGradesQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerMeGrades>>
>;
export type UserControllerMeGradesQueryError = ErrorType<unknown>;

export const useUserControllerMeGrades = <
  TData = Awaited<ReturnType<typeof userControllerMeGrades>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerMeGrades>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerMeGradesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerMeGrades>>> = ({ signal }) =>
    userControllerMeGrades(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerMeGrades>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 마이페이지 내 정보 조회(+학생그룹)
 */
export const userControllerDetailMe = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserDto>(
    { url: `/api/users/me/detail`, method: 'get', signal },
    options,
  );
};

export const getUserControllerDetailMeQueryKey = () => [`/api/users/me/detail`];

export type UserControllerDetailMeQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerDetailMe>>
>;
export type UserControllerDetailMeQueryError = ErrorType<unknown>;

export const useUserControllerDetailMe = <
  TData = Awaited<ReturnType<typeof userControllerDetailMe>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerDetailMe>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerDetailMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerDetailMe>>> = ({ signal }) =>
    userControllerDetailMe(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerDetailMe>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 로그인 유저정보 조회
 */
export const userControllerMe = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserDto>({ url: `/api/users/me`, method: 'get', signal }, options);
};

export const getUserControllerMeQueryKey = () => [`/api/users/me`];

export type UserControllerMeQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerMe>>>;
export type UserControllerMeQueryError = ErrorType<unknown>;

export const useUserControllerMe = <
  TData = Awaited<ReturnType<typeof userControllerMe>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerMe>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerMeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerMe>>> = ({ signal }) =>
    userControllerMe(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerMe>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 마이페이지 내 정보 수정
 */
export const userControllerUpdateMe = (
  updateUserDto: UpdateUserDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ResponseUserDto>(
    {
      url: `/api/users/me`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserDto,
    },
    options,
  );
};

export type UserControllerUpdateMeMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateMe>>
>;
export type UserControllerUpdateMeMutationBody = UpdateUserDto;
export type UserControllerUpdateMeMutationError = ErrorType<unknown>;

export const useUserControllerUpdateMe = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateMe>>,
    TError,
    { data: UpdateUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateMe>>,
    { data: UpdateUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerUpdateMe(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerUpdateMe>>,
    TError,
    { data: UpdateUserDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 최초회원가입정보입력(first login)
 */
export const userControllerUpdateMyInfoAtFirstLogin = (
  requestUpdateMyInfoAtFirstLoginDto: RequestUpdateMyInfoAtFirstLoginDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ResponseUserDto>(
    {
      url: `/api/users/update-my-info-at-first-login`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestUpdateMyInfoAtFirstLoginDto,
    },
    options,
  );
};

export type UserControllerUpdateMyInfoAtFirstLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateMyInfoAtFirstLogin>>
>;
export type UserControllerUpdateMyInfoAtFirstLoginMutationBody = RequestUpdateMyInfoAtFirstLoginDto;
export type UserControllerUpdateMyInfoAtFirstLoginMutationError = ErrorType<void>;

export const useUserControllerUpdateMyInfoAtFirstLogin = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateMyInfoAtFirstLogin>>,
    TError,
    { data: RequestUpdateMyInfoAtFirstLoginDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateMyInfoAtFirstLogin>>,
    { data: RequestUpdateMyInfoAtFirstLoginDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerUpdateMyInfoAtFirstLogin(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerUpdateMyInfoAtFirstLogin>>,
    TError,
    { data: RequestUpdateMyInfoAtFirstLoginDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 부모 회원가입 요청(알림톡 발송)
 */
export const userControllerSendParentSignUp = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<boolean>(
    { url: `/api/users/send-parent-sign-up`, method: 'get', signal },
    options,
  );
};

export const getUserControllerSendParentSignUpQueryKey = () => [`/api/users/send-parent-sign-up`];

export type UserControllerSendParentSignUpQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerSendParentSignUp>>
>;
export type UserControllerSendParentSignUpQueryError = ErrorType<unknown>;

export const useUserControllerSendParentSignUp = <
  TData = Awaited<ReturnType<typeof userControllerSendParentSignUp>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof userControllerSendParentSignUp>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerSendParentSignUpQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerSendParentSignUp>>> = ({
    signal,
  }) => userControllerSendParentSignUp(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerSendParentSignUp>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 부모 회원가입 요청(알림톡 발송)
 */
export const userControllerSendParentSignUpStudentId = (
  params: UserControllerSendParentSignUpStudentIdParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<boolean>(
    { url: `/api/users/send-parent-sign-up-StudentId`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerSendParentSignUpStudentIdQueryKey = (
  params: UserControllerSendParentSignUpStudentIdParams,
) => [`/api/users/send-parent-sign-up-StudentId`, ...(params ? [params] : [])];

export type UserControllerSendParentSignUpStudentIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerSendParentSignUpStudentId>>
>;
export type UserControllerSendParentSignUpStudentIdQueryError = ErrorType<unknown>;

export const useUserControllerSendParentSignUpStudentId = <
  TData = Awaited<ReturnType<typeof userControllerSendParentSignUpStudentId>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerSendParentSignUpStudentIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerSendParentSignUpStudentId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerSendParentSignUpStudentIdQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerSendParentSignUpStudentId>>
  > = ({ signal }) => userControllerSendParentSignUpStudentId(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof userControllerSendParentSignUpStudentId>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 부모 회원가입
 */
export const userControllerParentSignUp = (
  schoolId: number,
  requestParentSignUpDto: RequestParentSignUpDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AuthResponse>(
    {
      url: `/api/users/parent-sign-up/${schoolId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: requestParentSignUpDto,
    },
    options,
  );
};

export type UserControllerParentSignUpMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerParentSignUp>>
>;
export type UserControllerParentSignUpMutationBody = RequestParentSignUpDto;
export type UserControllerParentSignUpMutationError = ErrorType<unknown>;

export const useUserControllerParentSignUp = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerParentSignUp>>,
    TError,
    { schoolId: number; data: RequestParentSignUpDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerParentSignUp>>,
    { schoolId: number; data: RequestParentSignUpDto }
  > = (props) => {
    const { schoolId, data } = props ?? {};

    return userControllerParentSignUp(schoolId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerParentSignUp>>,
    TError,
    { schoolId: number; data: RequestParentSignUpDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 부모 회원가입 직후 자녀 추가
 */
export const userControllerCreateChildren = (
  uuid: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AuthResponse>(
    { url: `/api/users/children/${uuid}`, method: 'post' },
    options,
  );
};

export type UserControllerCreateChildrenMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerCreateChildren>>
>;

export type UserControllerCreateChildrenMutationError = ErrorType<unknown>;

export const useUserControllerCreateChildren = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerCreateChildren>>,
    TError,
    { uuid: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerCreateChildren>>,
    { uuid: string }
  > = (props) => {
    const { uuid } = props ?? {};

    return userControllerCreateChildren(uuid, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerCreateChildren>>,
    TError,
    { uuid: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 시간표/출석체크 학생(출결,역할,자리) 조회
 */
export const userControllerGetUserAttendance = (
  params: UserControllerGetUserAttendanceParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserAttendanceDto[]>(
    { url: `/api/users/attendance`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerGetUserAttendanceQueryKey = (
  params: UserControllerGetUserAttendanceParams,
) => [`/api/users/attendance`, ...(params ? [params] : [])];

export type UserControllerGetUserAttendanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserAttendance>>
>;
export type UserControllerGetUserAttendanceQueryError = ErrorType<unknown>;

export const useUserControllerGetUserAttendance = <
  TData = Awaited<ReturnType<typeof userControllerGetUserAttendance>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerGetUserAttendanceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserAttendance>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerGetUserAttendanceQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUserAttendance>>> = ({
    signal,
  }) => userControllerGetUserAttendance(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof userControllerGetUserAttendance>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생정보 업데이트
 */
export const userControllerUpdateStudent = (
  params: UserControllerUpdateStudentParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/users/studentUpdate`, method: 'post', params }, options);
};

export type UserControllerUpdateStudentMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateStudent>>
>;

export type UserControllerUpdateStudentMutationError = ErrorType<unknown>;

export const useUserControllerUpdateStudent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateStudent>>,
    TError,
    { params: UserControllerUpdateStudentParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateStudent>>,
    { params: UserControllerUpdateStudentParams }
  > = (props) => {
    const { params } = props ?? {};

    return userControllerUpdateStudent(params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerUpdateStudent>>,
    TError,
    { params: UserControllerUpdateStudentParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 학생의 보호자정보 업데이트
 */
export const userControllerUpdateparent = (
  params: UserControllerUpdateparentParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/users/parentUpdate`, method: 'post', params }, options);
};

export type UserControllerUpdateparentMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateparent>>
>;

export type UserControllerUpdateparentMutationError = ErrorType<unknown>;

export const useUserControllerUpdateparent = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateparent>>,
    TError,
    { params: UserControllerUpdateparentParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateparent>>,
    { params: UserControllerUpdateparentParams }
  > = (props) => {
    const { params } = props ?? {};

    return userControllerUpdateparent(params, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userControllerUpdateparent>>,
    TError,
    { params: UserControllerUpdateparentParams },
    TContext
  >(mutationFn, mutationOptions);
};

export const userControllerGetAttendanceBook = (
  params: UserControllerGetAttendanceBookParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/api/users/attendancebook`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerGetAttendanceBookQueryKey = (
  params: UserControllerGetAttendanceBookParams,
) => [`/api/users/attendancebook`, ...(params ? [params] : [])];

export type UserControllerGetAttendanceBookQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetAttendanceBook>>
>;
export type UserControllerGetAttendanceBookQueryError = ErrorType<unknown>;

export const useUserControllerGetAttendanceBook = <
  TData = Awaited<ReturnType<typeof userControllerGetAttendanceBook>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerGetAttendanceBookParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAttendanceBook>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerGetAttendanceBookQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetAttendanceBook>>> = ({
    signal,
  }) => userControllerGetAttendanceBook(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof userControllerGetAttendanceBook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생정보 조회
 */
export const userControllerGetStudentInfo = (
  params: UserControllerGetStudentInfoParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserDto[]>(
    { url: `/api/users/studentinfo`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerGetStudentInfoQueryKey = (
  params: UserControllerGetStudentInfoParams,
) => [`/api/users/studentinfo`, ...(params ? [params] : [])];

export type UserControllerGetStudentInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetStudentInfo>>
>;
export type UserControllerGetStudentInfoQueryError = ErrorType<unknown>;

export const useUserControllerGetStudentInfo = <
  TData = Awaited<ReturnType<typeof userControllerGetStudentInfo>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerGetStudentInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetStudentInfo>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerGetStudentInfoQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetStudentInfo>>> = ({
    signal,
  }) => userControllerGetStudentInfo(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerGetStudentInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 학생의 부모정보 조회
 */
export const userControllerGetparentInfo = (
  params: UserControllerGetparentInfoParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserDto[]>(
    { url: `/api/users/parentinfo`, method: 'get', params, signal },
    options,
  );
};

export const getUserControllerGetparentInfoQueryKey = (
  params: UserControllerGetparentInfoParams,
) => [`/api/users/parentinfo`, ...(params ? [params] : [])];

export type UserControllerGetparentInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetparentInfo>>
>;
export type UserControllerGetparentInfoQueryError = ErrorType<unknown>;

export const useUserControllerGetparentInfo = <
  TData = Awaited<ReturnType<typeof userControllerGetparentInfo>>,
  TError = ErrorType<unknown>,
>(
  params: UserControllerGetparentInfoParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerGetparentInfo>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerGetparentInfoQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetparentInfo>>> = ({
    signal,
  }) => userControllerGetparentInfo(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerGetparentInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary 부모 회원가입때 자녀 조회
 */
export const userControllerGetUser = (
  uuid: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseUserDto>(
    { url: `/api/users/${uuid}`, method: 'get', signal },
    options,
  );
};

export const getUserControllerGetUserQueryKey = (uuid: string) => [`/api/users/${uuid}`];

export type UserControllerGetUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUser>>
>;
export type UserControllerGetUserQueryError = ErrorType<unknown>;

export const useUserControllerGetUser = <
  TData = Awaited<ReturnType<typeof userControllerGetUser>>,
  TError = ErrorType<unknown>,
>(
  uuid: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUser>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserControllerGetUserQueryKey(uuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUser>>> = ({ signal }) =>
    userControllerGetUser(uuid, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userControllerGetUser>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!uuid, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const coulselingControllerCreateCoulseling = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/coulseling`, method: 'post' }, options);
};

export type CoulselingControllerCreateCoulselingMutationResult = NonNullable<
  Awaited<ReturnType<typeof coulselingControllerCreateCoulseling>>
>;

export type CoulselingControllerCreateCoulselingMutationError = ErrorType<unknown>;

export const useCoulselingControllerCreateCoulseling = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingControllerCreateCoulseling>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingControllerCreateCoulseling>>,
    TVariables
  > = () => {
    return coulselingControllerCreateCoulseling(requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingControllerCreateCoulseling>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

export const coulselingControllerGetBoards = (
  params: CoulselingControllerGetBoardsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/coulseling`, method: 'get', params, signal }, options);
};

export const getCoulselingControllerGetBoardsQueryKey = (
  params: CoulselingControllerGetBoardsParams,
) => [`/api/coulseling`, ...(params ? [params] : [])];

export type CoulselingControllerGetBoardsQueryResult = NonNullable<
  Awaited<ReturnType<typeof coulselingControllerGetBoards>>
>;
export type CoulselingControllerGetBoardsQueryError = ErrorType<unknown>;

export const useCoulselingControllerGetBoards = <
  TData = Awaited<ReturnType<typeof coulselingControllerGetBoards>>,
  TError = ErrorType<unknown>,
>(
  params: CoulselingControllerGetBoardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof coulselingControllerGetBoards>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCoulselingControllerGetBoardsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof coulselingControllerGetBoards>>> = ({
    signal,
  }) => coulselingControllerGetBoards(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof coulselingControllerGetBoards>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const coulselingControllerDeleteCoulseling = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/coulseling/${id}`, method: 'delete' }, options);
};

export type CoulselingControllerDeleteCoulselingMutationResult = NonNullable<
  Awaited<ReturnType<typeof coulselingControllerDeleteCoulseling>>
>;

export type CoulselingControllerDeleteCoulselingMutationError = ErrorType<unknown>;

export const useCoulselingControllerDeleteCoulseling = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingControllerDeleteCoulseling>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingControllerDeleteCoulseling>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return coulselingControllerDeleteCoulseling(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingControllerDeleteCoulseling>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

export const coulselingControllerUpdateCoulseling = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/coulseling/${id}`, method: 'patch' }, options);
};

export type CoulselingControllerUpdateCoulselingMutationResult = NonNullable<
  Awaited<ReturnType<typeof coulselingControllerUpdateCoulseling>>
>;

export type CoulselingControllerUpdateCoulselingMutationError = ErrorType<unknown>;

export const useCoulselingControllerUpdateCoulseling = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof coulselingControllerUpdateCoulseling>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof coulselingControllerUpdateCoulseling>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return coulselingControllerUpdateCoulseling(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof coulselingControllerUpdateCoulseling>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

export const chatControllerGetChat = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/chat/${id}`, method: 'get', signal }, options);
};

export const getChatControllerGetChatQueryKey = (id: number) => [`/api/chat/${id}`];

export type ChatControllerGetChatQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatControllerGetChat>>
>;
export type ChatControllerGetChatQueryError = ErrorType<unknown>;

export const useChatControllerGetChat = <
  TData = Awaited<ReturnType<typeof chatControllerGetChat>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatControllerGetChat>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatControllerGetChatQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatControllerGetChat>>> = ({ signal }) =>
    chatControllerGetChat(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatControllerGetChat>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const chatControllerCreateChats = (
  id: number,
  saveChatDto: SaveChatDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/chat/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: saveChatDto,
    },
    options,
  );
};

export type ChatControllerCreateChatsMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatControllerCreateChats>>
>;
export type ChatControllerCreateChatsMutationBody = SaveChatDto;
export type ChatControllerCreateChatsMutationError = ErrorType<unknown>;

export const useChatControllerCreateChats = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatControllerCreateChats>>,
    TError,
    { id: number; data: SaveChatDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatControllerCreateChats>>,
    { id: number; data: SaveChatDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return chatControllerCreateChats(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatControllerCreateChats>>,
    TError,
    { id: number; data: SaveChatDto },
    TContext
  >(mutationFn, mutationOptions);
};

export const chatControllerGetLastChat = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/chat/${id}/last`, method: 'get', signal }, options);
};

export const getChatControllerGetLastChatQueryKey = (id: number) => [`/api/chat/${id}/last`];

export type ChatControllerGetLastChatQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatControllerGetLastChat>>
>;
export type ChatControllerGetLastChatQueryError = ErrorType<unknown>;

export const useChatControllerGetLastChat = <
  TData = Awaited<ReturnType<typeof chatControllerGetLastChat>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof chatControllerGetLastChat>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatControllerGetLastChatQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatControllerGetLastChat>>> = ({
    signal,
  }) => chatControllerGetLastChat(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatControllerGetLastChat>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const chatControllerSaveChat = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/chat`, method: 'post' }, options);
};

export type ChatControllerSaveChatMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatControllerSaveChat>>
>;

export type ChatControllerSaveChatMutationError = ErrorType<unknown>;

export const useChatControllerSaveChat = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatControllerSaveChat>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatControllerSaveChat>>,
    TVariables
  > = () => {
    return chatControllerSaveChat(requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatControllerSaveChat>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

export const chatroomControllerGetChatList = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/chatroom/list`, method: 'get', signal }, options);
};

export const getChatroomControllerGetChatListQueryKey = () => [`/api/chatroom/list`];

export type ChatroomControllerGetChatListQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatroomControllerGetChatList>>
>;
export type ChatroomControllerGetChatListQueryError = ErrorType<unknown>;

export const useChatroomControllerGetChatList = <
  TData = Awaited<ReturnType<typeof chatroomControllerGetChatList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof chatroomControllerGetChatList>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomControllerGetChatListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatroomControllerGetChatList>>> = ({
    signal,
  }) => chatroomControllerGetChatList(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatroomControllerGetChatList>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const chatroomControllerGetReceiveNewMessage = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/chatroom/newmsg`, method: 'get', signal }, options);
};

export const getChatroomControllerGetReceiveNewMessageQueryKey = () => [`/api/chatroom/newmsg`];

export type ChatroomControllerGetReceiveNewMessageQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatroomControllerGetReceiveNewMessage>>
>;
export type ChatroomControllerGetReceiveNewMessageQueryError = ErrorType<unknown>;

export const useChatroomControllerGetReceiveNewMessage = <
  TData = Awaited<ReturnType<typeof chatroomControllerGetReceiveNewMessage>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof chatroomControllerGetReceiveNewMessage>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomControllerGetReceiveNewMessageQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof chatroomControllerGetReceiveNewMessage>>
  > = ({ signal }) => chatroomControllerGetReceiveNewMessage(requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof chatroomControllerGetReceiveNewMessage>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const chatroomControllerCreateChatroom = (
  saveChatRoomDto: SaveChatRoomDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/chatroom/create`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: saveChatRoomDto,
    },
    options,
  );
};

export type ChatroomControllerCreateChatroomMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatroomControllerCreateChatroom>>
>;
export type ChatroomControllerCreateChatroomMutationBody = SaveChatRoomDto;
export type ChatroomControllerCreateChatroomMutationError = ErrorType<unknown>;

export const useChatroomControllerCreateChatroom = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomControllerCreateChatroom>>,
    TError,
    { data: SaveChatRoomDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomControllerCreateChatroom>>,
    { data: SaveChatRoomDto }
  > = (props) => {
    const { data } = props ?? {};

    return chatroomControllerCreateChatroom(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomControllerCreateChatroom>>,
    TError,
    { data: SaveChatRoomDto },
    TContext
  >(mutationFn, mutationOptions);
};

export const chatroomControllerDeleteChatList = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/chatroom/${id}`, method: 'delete' }, options);
};

export type ChatroomControllerDeleteChatListMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatroomControllerDeleteChatList>>
>;

export type ChatroomControllerDeleteChatListMutationError = ErrorType<unknown>;

export const useChatroomControllerDeleteChatList = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomControllerDeleteChatList>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomControllerDeleteChatList>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return chatroomControllerDeleteChatList(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomControllerDeleteChatList>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

export const chatroomControllerExpireChatlist = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/chatroom/${id}`, method: 'patch' }, options);
};

export type ChatroomControllerExpireChatlistMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatroomControllerExpireChatlist>>
>;

export type ChatroomControllerExpireChatlistMutationError = ErrorType<unknown>;

export const useChatroomControllerExpireChatlist = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatroomControllerExpireChatlist>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatroomControllerExpireChatlist>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return chatroomControllerExpireChatlist(id, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatroomControllerExpireChatlist>>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};

export const chatroomControllerGetChatroomDetailData = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/chatroom/${id}`, method: 'get', signal }, options);
};

export const getChatroomControllerGetChatroomDetailDataQueryKey = (id: number) => [
  `/api/chatroom/${id}`,
];

export type ChatroomControllerGetChatroomDetailDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatroomControllerGetChatroomDetailData>>
>;
export type ChatroomControllerGetChatroomDetailDataQueryError = ErrorType<unknown>;

export const useChatroomControllerGetChatroomDetailData = <
  TData = Awaited<ReturnType<typeof chatroomControllerGetChatroomDetailData>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof chatroomControllerGetChatroomDetailData>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatroomControllerGetChatroomDetailDataQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof chatroomControllerGetChatroomDetailData>>
  > = ({ signal }) => chatroomControllerGetChatroomDetailData(id, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof chatroomControllerGetChatroomDetailData>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const chatsettingControllerGetChat = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/chatsetting/setting`, method: 'get', signal }, options);
};

export const getChatsettingControllerGetChatQueryKey = () => [`/api/chatsetting/setting`];

export type ChatsettingControllerGetChatQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatsettingControllerGetChat>>
>;
export type ChatsettingControllerGetChatQueryError = ErrorType<unknown>;

export const useChatsettingControllerGetChat = <
  TData = Awaited<ReturnType<typeof chatsettingControllerGetChat>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof chatsettingControllerGetChat>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChatsettingControllerGetChatQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof chatsettingControllerGetChat>>> = ({
    signal,
  }) => chatsettingControllerGetChat(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof chatsettingControllerGetChat>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const chatsettingControllerCreateChats = (
  saveChatSettingDto: SaveChatSettingDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/chatsetting/setting`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: saveChatSettingDto,
    },
    options,
  );
};

export type ChatsettingControllerCreateChatsMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatsettingControllerCreateChats>>
>;
export type ChatsettingControllerCreateChatsMutationBody = SaveChatSettingDto;
export type ChatsettingControllerCreateChatsMutationError = ErrorType<unknown>;

export const useChatsettingControllerCreateChats = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsettingControllerCreateChats>>,
    TError,
    { data: SaveChatSettingDto },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatsettingControllerCreateChats>>,
    { data: SaveChatSettingDto }
  > = (props) => {
    const { data } = props ?? {};

    return chatsettingControllerCreateChats(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof chatsettingControllerCreateChats>>,
    TError,
    { data: SaveChatSettingDto },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary 카테고리별 코드 조회
 */
export const codesControllerGetBoards = (
  params: CodesControllerGetBoardsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>({ url: `/api/codes`, method: 'get', params, signal }, options);
};

export const getCodesControllerGetBoardsQueryKey = (params: CodesControllerGetBoardsParams) => [
  `/api/codes`,
  ...(params ? [params] : []),
];

export type CodesControllerGetBoardsQueryResult = NonNullable<
  Awaited<ReturnType<typeof codesControllerGetBoards>>
>;
export type CodesControllerGetBoardsQueryError = ErrorType<unknown>;

export const useCodesControllerGetBoards = <
  TData = Awaited<ReturnType<typeof codesControllerGetBoards>>,
  TError = ErrorType<unknown>,
>(
  params: CodesControllerGetBoardsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof codesControllerGetBoards>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCodesControllerGetBoardsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof codesControllerGetBoards>>> = ({
    signal,
  }) => codesControllerGetBoards(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof codesControllerGetBoards>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
