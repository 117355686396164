import { useEffect, useState } from 'react';

import { UserContainer } from './user';
import {
  useFieldtripResultControllerUpdateResult,
  useFieldtripsControllerFindOne,
} from '../generated/endpoint';

type Props = {
  id: number;
  sigPadData: string;
};

type HomePlan = Array<{
  [key: string]: string;
}>;

export const useStudentFieldtripHomeReportAdd = ({ id, sigPadData }: Props) => {
  const { me } = UserContainer.useContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [parentsName, setParentsName] = useState('');
  const [parentsPhone, setParentsPhone] = useState('');
  const [homePlan, setHomePlan] = useState<HomePlan>();

  const {
    data: fieldtrip,
    isLoading: isGetFieldtripLoading,
    error,
  } = useFieldtripsControllerFindOne(id);

  const {
    mutateAsync: updateFieldtripResultMutate,
    isLoading: isUpdateFieldtripLoading,
    isSuccess: isUpdateFieldtripSuccess,
  } = useFieldtripResultControllerUpdateResult({
    mutation: {
      onSuccess: (data) => {
        if (!data) return;
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    },
  });

  const updateFieldtripResult = () => {
    if (!fieldtrip) return;

    const { id, destination, resultTitle } = fieldtrip;
    updateFieldtripResultMutate({
      id,
      data: {
        resultText: JSON.stringify(homePlan),
        destination,
        resultFiles: [], // 가정학습은 input 파일 첨부하지 않기 때문에, 빈 배열로 세팅
        studentResultSignature: sigPadData,
        resultTitle,
      },
    }).catch((e) => console.error(e));
  };

  const isLoading = isGetFieldtripLoading || isUpdateFieldtripLoading;

  useEffect(() => {
    if (!parentsName && !parentsPhone) {
      if (me?.nokName && me?.nokPhone) {
        setParentsName(me.nokName);
        setParentsPhone(me.nokPhone);
      }
    }
  }, [me]);

  useEffect(() => {
    if (!homePlan && fieldtrip?.usedDays) {
      try {
        const _content = JSON.parse(fieldtrip?.content);
        setHomePlan(_content);
      } catch (err) {
        setHomePlan(
          new Array(fieldtrip.usedDays).fill(0).map(() => {
            return {};
          }),
        );
      }
    }
  }, [fieldtrip]);

  return {
    fieldtrip,
    isLoading,
    error,
    errorMessage,
    updateFieldtripResult,
    isUpdateFieldtripSuccess,
    homePlan,
    parentsName,
    parentsPhone,

    setHomePlan,
    setParentsName,
    setParentsPhone,
  };
};
