import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useAbsentsControllerApprove,
  useAbsentsControllerGetAbsentsByTeacher,
  useAbsentsControllerNiceSubmitted,
  useAbsentsControllerSubmitted,
} from '../generated/endpoint';
import { Absent } from '../generated/model';
import { useSignature } from '../hooks/useSignature';
import { useStamp } from '../hooks/useStamp';
import { getEndDate, getStartDate, makeDateToString, MonthAgo } from '../utils';

const filters = [
  { id: 1, name: '모두', value: 'ALL' },
  { id: 2, name: '승인 전', value: 'BEFORE_APPROVAL' },
  { id: 3, name: '승인 완료', value: 'PROCESSED' },
  { id: 4, name: '반려됨', value: 'RETURNED' },
  { id: 5, name: '외부서류 미제출', value: 'UNSUBMITTED' },
  { id: 6, name: '외부서류 제출', value: 'SUBMITTED' },
];

export const useTeacherAbsent = () => {
  const { search } = useLocation();
  const { stamp, stampImgUrl, stampMode, setStampMode, updateStamp, isUploadStampLoading } =
    useStamp();
  const { sigPadData, clearSignature, canvasRef } = useSignature();
  const signature = stampMode ? stamp : sigPadData;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [startDate, setStartDate] = useState(makeDateToString(MonthAgo(new Date())));
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [filter, setFilter] = useState<any>(filters[0]);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [sortNice, setSortNice] = useState<'DESC' | 'ASC' | ''>('');
  const [sortApproval, setSortApproval] = useState<'DESC' | 'ASC' | ''>('');
  const [sortSubmit, setSortSubmit] = useState<'DESC' | 'ASC' | ''>('');
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const limit = Number(params.get('limit') ?? '999') || 999;
  const username = params.get('username') || '';
  const [absentId, setAbsentId] = useState(0);

  const [isCsvData, setCsvData] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  // Query용 공통 파라미터
  const getDefaultParams = () => {
    return {
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      absentStatus: filter?.value === 'ALL' ? undefined : filter.value,
      NICE: sortNice || undefined,
      SUBMIT: sortSubmit || undefined,
      APPROVAL: sortApproval || undefined,
    };
  };

  /*************************** Query ********************************/
  const {
    error,
    data: absents,
    isLoading: isAbsentsLoading,
    refetch,
  } = useAbsentsControllerGetAbsentsByTeacher({
    ...getDefaultParams(),
    selectedGroupId: selectedGroup?.id,
    ...(username ? { username } : {}),
    page,
    limit,
  });

  /*************************** Mutation ********************************/
  const { mutateAsync: approveAbsentsMutate, isLoading: isApproveAbsentsLoading } =
    useAbsentsControllerApprove({
      mutation: {
        onSuccess: () => {
          setOpen(false);
          clearSignature();
          refetch();
        },
      },
    });

  const approveAbsents = () => {
    absents?.items.forEach(({ id }) => {
      approveAbsentsMutate({ id, data: { signature } }).catch((e) => console.error(e));
    });
  };

  const approveAbsent = () => {
    approveAbsentsMutate({ id: absentId, data: { signature } }).catch((e) => console.error(e));
  };

  const { mutateAsync: submitAbsentMutate, isLoading: isSubmitAbsentLoading } =
    useAbsentsControllerSubmitted({
      mutation: {
        onSuccess: () => {
          refetch();
        },
      },
    });

  // 출결 신고서 - 증빙서류 Checkbox
  const submitAbsent = ({
    id,
    submitted,
    callback,
  }: {
    id: Absent['id'];
    submitted: boolean;
    callback: () => void;
  }) => {
    submitAbsentMutate({ id, data: { submitted } }).then(() => {
      callback();
    }); // @example 체크박스 해제와 같�� 함수 실행
  };

  const { mutateAsync: submitNiceAbsentMutate, isLoading: isSubmitNiceAbsentLoading } =
    useAbsentsControllerNiceSubmitted({
      mutation: {
        onSuccess: () => {
          refetch();
        },
      },
    });

  // 출결 신고서 - 나이스 Checkbox
  const submitNiceAbsent = ({
    id,
    submitted,
    callback,
  }: {
    id: Absent['id'];
    submitted: boolean;
    callback: () => void;
  }) => {
    submitNiceAbsentMutate({ id, data: { submitted } }).then(() => {
      callback();
    });
  };

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  const isLoading =
    isAbsentsLoading ||
    isApproveAbsentsLoading ||
    isSubmitAbsentLoading ||
    isSubmitNiceAbsentLoading;

  return {
    absents,
    isLoading,
    error,
    filters,
    state: {
      startDate,
      endDate,
      filter,
      selectedGroup,
      page,
      absentId,
      isCsvData,
      open,
      limit,
    },
    setState: {
      setStartDate,
      setEndDate,
      setFilter,
      setSelectedGroup,
      setPage,
      setAbsentId,
      setCsvData,
      setOpen,
    },
    sign: {
      sigPadData,
      clearSignature,
      canvasRef,
    },
    stamp: {
      stamp,
      stampImgUrl,
      stampMode,
      setStampMode,
      updateStamp,
      isUploadStampLoading,
    },
    approveAbsents,
    approveAbsent,
    submitAbsent,
    submitNiceAbsent,
    refetch,
    sortApproval,
    setSortApproval,
    sortNice,
    setSortNice,
    sortSubmit,
    setSortSubmit,
  };
};
