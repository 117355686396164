import React, { FC, TdHTMLAttributes } from 'react';

interface Td2Props extends TdHTMLAttributes<HTMLTableDataCellElement> {}

export const Td2: FC<Td2Props> = ({ children, ...props }) => {
  return (
    <td className="border border-gray-900 w-16 text-center" {...props}>
      <div className="min-w-max">{children}</div>
    </td>
  );
};
