import { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useFileUpload } from './useFileUpload';
import { Constants } from '../constants';
import { QueryKey } from '../constants/query-key';
import { UserContainer } from '../container/user';
import { useUserControllerUpdateMe } from '../generated/endpoint';
import { UpdateUserDto, UploadFileTypeEnum } from '../generated/model';

export const useStamp = () => {
  const { me } = UserContainer.useContext();
  const [stamp, setStamp] = useState(me?.stamp);
  const [stampMode, setStampMode] = useState<boolean>(false);
  const { handleUploadFile, isUploadLoading } = useFileUpload();

  const queryClient = useQueryClient();

  const { mutateAsync: updateMeMutateAsync } = useUserControllerUpdateMe({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.me);
      },
    },
  });

  const updateMe = async ({ stamp }: Partial<UpdateUserDto>) => {
    await updateMeMutateAsync({ data: { stamp } as UpdateUserDto });
  };

  const updateStamp = async (file: File) => {
    const stampImagePath = await handleUploadFile(UploadFileTypeEnum['stamps'], [file]);
    updateMe({ stamp: stampImagePath[0] });
    setStamp(stampImagePath[0]);
  };

  return {
    stampImgUrl: stamp && `${Constants.imageUrl}${stamp}`,
    stamp,
    stampMode,
    setStampMode,
    updateStamp,
    isUploadStampLoading: isUploadLoading,
  };
};
