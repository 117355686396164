import React, { FC, TdHTMLAttributes } from 'react';

interface TdProps extends TdHTMLAttributes<HTMLTableDataCellElement> {
  innerClassName?: string;
}

export const Td: FC<TdProps> = ({ children, innerClassName, ...props }) => {
  return (
    <td className="border border-gray-900 p-2" {...props}>
      <div className={`whitespace-pre-line ${innerClassName}`}>{children}</div>
    </td>
  );
};
