import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Viewer from 'react-viewer';

import { ErrorBlank, SuperModal, SuperSurveyComponent } from '../../../components';
import { Constants } from '../../../constants';
import { useTeacherNewsletterDetail } from '../../../container/teacher-newsletter-detail';
import { NewsletterType } from '../../../generated/model';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TopNavbar,
  utcToLocalDatetime,
} from '../../../reusable';
import { Routes } from '../../../routes';
import { getFileNameFromUrl } from '../../../utils/file-util';

export const NewsletterDetailPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const {
    newsletter,
    isLoading,
    images,
    documents,
    viewerImages,
    isPublishModalOpen,
    errorMessage,
    setIsPublishModalOpen,
    handleNewsletterDelete,
    handleNewsletterPublish,
  } = useTeacherNewsletterDetail({ id: +id });

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  // 설문조사용도
  const [content, setContent] = useState<Record<string, string>>({});

  return (
    <>
      {/* Mobile V */}
      <div className="bg-white block md:hidden">
        {isLoading && <Blank />}
        {errorMessage && <ErrorBlank text={errorMessage} />}
        <TopNavbar title="가정통신문" left={<BackButton />} />

        <Section>
          <div className="w-full flex justify-between space-x-2 ">
            <div className="rounded-3xl px-3 py-1 bg-purple-100 text-sm font-bold text-purple-700 w-max">
              가정통신문
            </div>
          </div>
          <div className="text-lg font-bold text-grey-1">{newsletter?.title}</div>
          <div className="text-grey-3 text-sm">
            {utcToLocalDatetime(newsletter?.createdAt?.toString() || '')}
          </div>
          <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
            {images?.map((image: string, i: number) => (
              <div
                key={i}
                className="w-full"
                onClick={() => {
                  setActiveIndex(i);
                  setImagesModalOpen(true);
                }}
              >
                <div className={`relative pb-3/5 rounded bg-gray-50`}>
                  <LazyLoadImage
                    src={`${Constants.imageUrl}${image}`}
                    alt={''}
                    className={`absolute w-full h-full rounded object-cover`}
                    loading={'lazy'}
                  />
                </div>
              </div>
            ))}
          </div>
          <div>
            {documents?.map((fileUrl: string, index) => (
              <div
                key={index}
                className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
              >
                <span>{getFileNameFromUrl(fileUrl)}</span>
                <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                  <a
                    href={`${Constants.imageUrl}${fileUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    download={getFileNameFromUrl(fileUrl)}
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
            <Linkify>{newsletter?.content}</Linkify>
          </div>
          {newsletter?.type === NewsletterType.STUDENT_PARENTS_SURVEY && (
            <SuperSurveyComponent
              surveyContent={newsletter.surveyContent}
              setContent={(c: any) => setContent(c)}
              content={content}
            />
          )}
        </Section>
      </div>

      {/* Desktop V */}
      <div className="hidden md:block">
        <div className="bg-white border rounded-lg p-5">
          {isLoading && <Blank reversed />}
          {errorMessage && <ErrorBlank text={errorMessage} />}
          {isLoading && <Blank reversed />}
          <Section>
            <div className="w-full flex justify-between space-x-2">
              <div className="rounded-md px-3 py-1 bg-purple-100 text-sm font-bold text-purple-700 w-max">
                가정통신문
              </div>
              <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
                {!newsletter?.isPublished && (
                  <div
                    className="text-gray-700"
                    onClick={() => history.push(`${Routes.teacher.newsletter}/${id}/edit`)}
                  >
                    수정
                  </div>
                )}

                <div
                  className="text-red-400 cursor-pointer"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  삭제
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-3">
              {newsletter?.klasses
                ?.sort((a, b) => +a - +b)
                .map((klass) => (
                  <span
                    key={klass}
                    className="rounded-full py-2 px-3 border border-gray-400 font-semibold text-gray-500 text-sm mr-2 mb-2"
                  >
                    {klass}학년
                  </span>
                ))}
            </div>
            <div className="text-lg font-bold text-grey-1">{newsletter?.title}</div>
            <div className="text-grey-3 text-sm">
              {utcToLocalDatetime(newsletter?.createdAt?.toString() || '')}
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <div
                  key={i}
                  className="w-full"
                  onClick={() => {
                    setActiveIndex(i);
                    setImagesModalOpen(true);
                  }}
                >
                  <div className={`relative pb-3/5 rounded bg-gray-50`}>
                    <LazyLoadImage
                      src={`${Constants.imageUrl}${image}`}
                      alt={''}
                      className={`absolute w-full h-full rounded object-cover`}
                      loading={'lazy'}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>
              {documents?.map((fileUrl: string, index) => (
                <div
                  key={index}
                  className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
                >
                  <span>{getFileNameFromUrl(fileUrl)}</span>
                  <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                    <a
                      href={`${Constants.imageUrl}${fileUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      download={getFileNameFromUrl(fileUrl)}
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
              <Linkify>{newsletter?.content}</Linkify>
            </div>
            {newsletter?.type === NewsletterType.STUDENT_PARENTS_SURVEY && (
              <>
                <SuperSurveyComponent
                  surveyContent={newsletter.surveyContent}
                  setContent={(c: any) => setContent(c)}
                  content={content}
                />
                {!newsletter.isPublished && (
                  <Button
                    tw={{ width: 'w-full' }}
                    text="발행하기"
                    onClick={() => setIsPublishModalOpen(true)}
                  />
                )}
              </>
            )}
            <SuperModal
              modalOpen={isPublishModalOpen}
              setModalClose={() => setIsPublishModalOpen(false)}
              width="w-100"
            >
              <div className="px-12 py-6">
                <div className="w-full text-lg font-bold text-center text-gray-900 mb-4">
                  해당 가정통신문을 발행하시겠습니까?
                </div>
                <div className="w-full text-sm text-center mb-6">
                  가정통신문을 발행하면 학생이 열람하고 답변할 수 있으며 <br />
                  수정이 불가능해집니다.
                </div>
                <Button tw={{ width: 'w-full' }} onClick={handleNewsletterPublish}>
                  발행하기
                </Button>
              </div>
            </SuperModal>
            <SuperModal
              modalOpen={isDeleteModalOpen}
              setModalClose={() => setDeleteModalOpen(false)}
              width="w-max"
            >
              <Section tw={{ marginTop: 'mt-7' }}>
                <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
                  정말 해당 가정통신문을 삭제하시겠습니까?
                </div>
                <Button onClick={() => handleNewsletterDelete(+id)}>삭제하기</Button>
              </Section>
            </SuperModal>
          </Section>
        </div>
      </div>
      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};
