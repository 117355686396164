import { useEffect, useState } from 'react';

import { useStudentActivityControllerUpdateFeedback } from '../generated/endpoint';
import { StudentActivity } from '../generated/model';

type Props = {
  record: StudentActivity;
  refetch: () => void;
};

export const useTeacherRecordCard = ({ record, refetch }: Props) => {
  const [feedback, setFeedback] = useState(record.feedback);
  const [errorText, setErrorText] = useState('');

  const { mutate: updateFeedbackMutate, isLoading } = useStudentActivityControllerUpdateFeedback({
    mutation: {
      onSuccess: () => {
        refetch();
      },
      onError: (err) => {
        setErrorText(err?.message);
      },
    },
  });

  useEffect(() => {
    if (record?.feedback) {
      setFeedback(record.feedback);
    }
  }, [record]);
  const updateFeedback = () => {
    updateFeedbackMutate({ id: record.id, data: { feedback } });
  };
  return {
    errorText,
    feedback,
    setFeedback,
    updateFeedback,
    isLoading,
  };
};
