import React, { FC, useState } from 'react';

import { useTeacherRecordSummaryItem } from '../../container/teacher-record-summary-item';

import { forbiddenWords } from '../../pages/teacher/ForbiddenWords';
import { Blank, Button, TextArea, useRecoilValue } from '../../reusable';
import { meStateNew } from '../../store';
import { Summary } from '../../types';
import { SuperModal } from '../SuperModal';

interface SummaryItemProps {
  summary: Summary;
  refetch: () => void;
}

export const SummaryItem: FC<SummaryItemProps> = ({ summary, refetch }) => {
  const me = useRecoilValue(meStateNew);
  const [recordSummary, setRecordSummary] = useState<string>(summary?.content || '');
  const [updateState, setUpdateState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [forbiddenText, setForbiddenText] = useState('');

  const { errorText, updateRecordSummary, deleteRecordSummary, isLoading } =
    useTeacherRecordSummaryItem({
      id: summary?.id ?? 0,
      recordSummary,
      refetch,
    });

  // 로그인 유저와 작성자가 다른 경우
  if (!(me?.id === summary.userId)) {
    return (
      <>
        <div>
          <div className="flex items-center justify-between px-4 pb-2">
            <div className="font-bold text-lg ">{summary.subject}</div>
            <div className="text-gray-500">작성자 : {summary.teacherName}</div>
          </div>
          <TextArea
            tw={{ height: 'h-40', backgroundColor: 'bg-gray-50' }}
            value={summary.content}
            disabled
          />
        </div>

        <div className="w-full h-0 border-gray-50 border" />
      </>
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      <div>
        <div className="flex items-center justify-between px-4 pb-2">
          <div className="font-bold text-lg ">{summary.subject}</div>
          <div className="text-gray-500">작성자 : {summary.teacherName}</div>
        </div>
        {updateState ? (
          <>
            <TextArea
              tw={{ height: 'h-40' }}
              value={recordSummary}
              onChange={(e) => setRecordSummary(e.target.value)}
            />
            <div className="flex items-center justify-end space-x-2 pt-2">
              <Button
                tw={{ height: 'h-12' }}
                onClick={() => {
                  const match = recordSummary.match(new RegExp(forbiddenWords.join('|'), 'g'));

                  if (match?.length) {
                    setModalOpen(true);
                    setForbiddenText(match.join(', '));
                  } else {
                    updateRecordSummary();
                    setForbiddenText('');
                  }
                }}
              >
                수정 완료
              </Button>
              <Button
                tw={{
                  backgroundColor: 'bg-gray-500',
                  height: 'h-12',
                }}
                onClick={() => {
                  setUpdateState(false);
                  setRecordSummary(summary?.content || '');
                }}
              >
                취소
              </Button>
            </div>
          </>
        ) : (
          <>
            <TextArea tw={{ height: 'h-40' }} value={summary.content} disabled />
            <div className="flex items-center justify-end space-x-2 pt-2">
              <Button tw={{ height: 'h-12' }} onClick={() => setUpdateState(true)}>
                수정하기
              </Button>
              <Button
                tw={{
                  backgroundColor: 'bg-red-500',
                  height: 'h-12',
                }}
                onClick={() => setDeleteModalOpen(true)}
              >
                삭제
              </Button>
            </div>
          </>
        )}

        {errorText && <div className="text-red-500">{errorText}</div>}
      </div>

      <div className="w-full h-0 border-gray-50 border" />
      <SuperModal
        modalOpen={deleteModalOpen}
        setModalClose={() => setDeleteModalOpen(false)}
        width="w-max"
      >
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            해당 총정리 내용을 삭제하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={async () => {
              await deleteRecordSummary();
              await setDeleteModalOpen(false);
            }}
          >
            삭제하기
          </Button>
        </div>
      </SuperModal>
      <SuperModal modalOpen={modalOpen} setModalClose={() => setModalOpen(false)} width="w-max">
        <div className="px-12 py-6">
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            학생부 기재 유의어가 포함되어있습니다. <br />
            {forbiddenText} <br />
            <br />
            기재 내용을 다시 한번 확인해 주시기 바랍니다. 유의어를 포함하여 저장하시겠습니까?
          </div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => {
              updateRecordSummary();
              setForbiddenText('');
              setRecordSummary('');
            }}
          >
            저장하기
          </Button>
        </div>
      </SuperModal>
    </>
  );
};
