import { useState } from 'react';

import { Row } from 'read-excel-file';
import { useRecoilValue } from 'recoil';

import { ReactComponent as ChevronLeftIcon } from '../../../assets/svg/icon-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/svg/icon-chevron-right.svg';
import { GroupContainer } from '../../../container/group';
import { useTeacherAttendance } from '../../../container/teacher-attendance';
import {
  DayOfWeekEnum,
  useTeacherAttendanceBook1,
} from '../../../container/teacher-attendance-book-1';
import { useTeacherStudentNameMatrixDownload } from '../../../container/teacher-student-name-matrix-download';
import { Attendance } from '../../../generated/model';
import { Blank, Button, Chip, Divider, Select } from '../../../reusable';
import { meStateNew } from '../../../store';
import { AbsentSave, PeriodSubjectTeacher } from '../../../types';
import { getThisSemester, getThisYear, toLocaleDateFormatString, weekCount } from '../../../utils';
import { DateFormat, DateUtil } from '../../../utils/date-util';

const createNumberArray = (startNumber: number, lastNumber: number) => {
  if (lastNumber < startNumber) return [];
  return Array.from({ length: lastNumber - startNumber + 1 }, (_, i) => i + startNumber);
};

const nullSafeValue = (str: string | number | boolean | typeof Date | null | undefined) => {
  return (str as string) ?? '';
};

const getStudentIdList = (data: Row[] | undefined) => {
  return data?.map((row) => row[1] as string) || [];
};

const getRows = (rowsParam: Row[] | undefined) => {
  if (rowsParam && rowsParam?.length >= 5) {
    const [row0, row1, row2, row3, ...restTemp] = rowsParam;
    const last1 = restTemp[restTemp.length - 2];
    const last2 = restTemp[restTemp.length - 1];
    const rest = restTemp.slice(0, -2);
    return { row0, row1, row2, row3, rest, last1, last2 };
  }
  return { row0: [], row1: [], row2: [], row3: [], rest: [], last1: [], last2: [] };
};

const makeSubjectMap = (
  studentIdList: string[],
  row1: Row,
  row2: Row,
  row3: Row,
  rest: Row[],
  periodCount: number,
): Map<string, Map<number, PeriodSubjectTeacher[]>> => {
  if (!studentIdList) return new Map();
  const studentMap = new Map<string, Map<number, PeriodSubjectTeacher[]>>();

  studentIdList.forEach((studentId) => {
    const studentAttendanceList = rest.find((row) => row[1] === studentId);
    if (!studentAttendanceList) return;

    const studentSubjectMap = new Map<number, PeriodSubjectTeacher[]>(); // 요일별 과목
    for (const dayOfWeek of Object.values(DayOfWeekEnum)) {
      const classByDay: PeriodSubjectTeacher[] = [];
      for (let i = 0; i < periodCount; i++) {
        const subject = nullSafeValue(row1[3 + i + dayOfWeek * periodCount]).toString();
        const teacher = nullSafeValue(row2[3 + i + dayOfWeek * periodCount]).toString();
        const mark = nullSafeValue(
          studentAttendanceList[3 + i + dayOfWeek * periodCount],
        ).toString();
        classByDay.push({
          period: i,
          subject,
          teacher,
          mark,
        });
      }
      studentSubjectMap.set(dayOfWeek, classByDay);
    }
    studentMap.set(studentId, studentSubjectMap);
  });
  return studentMap;
};

const getPreviousContentByPeriod = (
  attendanceAbsentData: Attendance[] | undefined,
  day: Date,
  studentId: string,
): Map<number, AbsentSave> => {
  const previousAbsentData = attendanceAbsentData?.find((absentData) => {
    return (
      absentData.userId === +studentId &&
      absentData.attendanceDay === DateUtil.formatDate(day.toISOString(), DateFormat['YYYY-MM-DD'])
    );
  });
  const previousContent = JSON.parse(previousAbsentData?.content || '{ "attendance": [] }') as {
    attendance: AbsentSave[];
  };
  const previousContentByPeriod = new Map<number, AbsentSave>();
  previousContent.attendance.forEach((absentSave) => {
    previousContentByPeriod.set(absentSave.period, absentSave);
  });
  return previousContentByPeriod;
};

const getStudentAttendanceByDayOfWeek = (
  rowsParam: Row[] | undefined,
  studentNumber: string,
  dayOfWeek: keyof typeof DayOfWeekEnum, // 0: 월요일, 1: 화요일, 2: 수요일, 3: 목요일, 4: 금요일
  classCount: number,
) => {
  const studentData = rowsParam?.find((row) => row[1] === studentNumber);
  if (!studentData) return [];

  const dayOfWeekData = studentData.slice(
    3 + DayOfWeekEnum[dayOfWeek] * classCount,
    3 + DayOfWeekEnum[dayOfWeek] * classCount + classCount,
  );
  return dayOfWeekData;
};

const getStudentAttendanceHead = (rowsParam: Row[] | undefined, studentId: string) => {
  const studentData = rowsParam?.find((row) => row[1] === studentId);
  if (!studentData) return [];
  return [studentData[0], studentData[2]];
};

const setCommentColor = (last1: Row, last2: Row) => {
  const specialCommentList = last2.map((value, index) => {
    if (!value) return [];
    const requestCommentList = last1[index] ? last1[index].toString().split('\n') : [];

    const commentList = value.toString().split('\n');
    return commentList.map((comment) => {
      const frontPart = comment.split(':')[0];
      return { isColored: !requestCommentList.includes(frontPart), comment };
    });
  });
  return specialCommentList;
};

export const AttendancePage = () => {
  const me = useRecoilValue(meStateNew);
  const year = +getThisYear();
  const semester = +getThisSemester();
  const [selday, setSelday] = useState(new Date());
  const [tselweek, selmonday, seltuesday, selwednesday, selthursday, selfriday] = weekCount(selday);

  const { allKlassGroups } = GroupContainer.useContext();

  const { groupId, isHomeRoomTeacher, setSelectedGroupId } = useTeacherAttendance({
    year,
    semester,
  });

  const { downloadStudentNameMatrix } = useTeacherStudentNameMatrixDownload({ groupId });

  const { downloadAttendanceBook, rows, attendanceAbsentData, handleAbsent } =
    useTeacherAttendanceBook1({
      groupId,
      year,
      semester,
      startDate: selmonday.toISOString(),
      endDate: selfriday.toISOString(),
    });

  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState<{
    mark: string;
    type1: string;
    type2: string;
    absent: boolean;
  }>({
    mark: '.',
    type1: '',
    type2: '',
    absent: false,
  });

  const [isLoading, setLoading] = useState(false);

  const selectedKlass = allKlassGroups?.find((klass) => klass.id === groupId);
  const klassName = selectedKlass?.name || 'NO';
  const teacher = selectedKlass?.homeRoomTeacherName || '선생님';

  const { row0, row1, row2, row3, rest, last1, last2 } = getRows(rows);
  const studentIdList = getStudentIdList(rest);
  const studentMap = makeSubjectMap(studentIdList, row1, row2, row3, rest, 9);
  const coloredComment = setCommentColor(last1, last2);

  const handleDateChange = (offset: number) => {
    const tempDay = selday;
    tempDay.setDate(tempDay.getDate() + offset);
    setSelday(new Date(tempDay));
  };

  const setAbsent = (
    studentId: string,
    day: Date,
    period: number,
    dayOfWeek: keyof typeof DayOfWeekEnum,
  ) => {
    if (!editMode) return;

    // console.log('setAbsent', { studentId, day, period, dayOfWeek, editMode });
    // 특정 요일의 과목, 선생님, 교시
    const studentAttendanceByWeek = studentMap.get(studentId);
    // console.log('studentAttendanceByWeek', studentAttendanceByWeek);
    if (!studentAttendanceByWeek) return;

    const studentAttendanceByDayOfWeek = studentAttendanceByWeek.get(DayOfWeekEnum[dayOfWeek]);
    // console.log('studentAttendanceByDayOfWeek', studentAttendanceByDayOfWeek);
    if (!studentAttendanceByDayOfWeek) return;

    // console.log('attendanceAbsentData', attendanceAbsentData);
    const previousContentByPeriod = getPreviousContentByPeriod(
      attendanceAbsentData,
      day,
      studentId,
    );

    const attendance = studentAttendanceByDayOfWeek.map(
      (periodSubjectTeacher: PeriodSubjectTeacher) => {
        if (
          periodSubjectTeacher.period === period ||
          !editType.absent ||
          (editType.type1 === '지각' && periodSubjectTeacher.period < period) ||
          (editType.type1 === '조퇴' && periodSubjectTeacher.period > period) ||
          editType.type1 === '결석'
        ) {
          const previousData = previousContentByPeriod.get(period);
          return {
            subject: periodSubjectTeacher.subject,
            period: periodSubjectTeacher.period,
            creator: previousData?.creator ? previousData.creator : me?.name || '',
            createtime: previousData?.createtime
              ? previousData.createtime
              : new Date().toISOString(),
            editor: previousData?.creator ? me?.name || '' : '',
            edittime: previousData?.creator ? new Date().toISOString() : '',
            comment: '',
            absent: true,
            type1: editType.type1,
            type2: editType.type2,
          };
        }
        return {
          subject: periodSubjectTeacher.subject,
          period: periodSubjectTeacher.period,
          creator: '',
          createtime: '',
          editor: '',
          edittime: '',
          comment: '',
          absent: false,
          type1: '',
          type2: '',
        };
      },
    );

    handleAbsent({
      attendanceDay: toLocaleDateFormatString(day),
      absent: editType.absent,
      type1: editType.type1,
      type2: editType.type2,
      comment: '',
      content: JSON.stringify({ attendance }),
      year: 2023,
      semester: 1,
      userId: +studentId,
      schoolId: 1,
    });

    return;
  };

  return (
    <div className="col-span-6 ">
      {/*{isTimetableLoading && <Blank />}*/}
      {isLoading && <Blank />}
      <div className="w-full justify-center">
        <div className="px-6 py-6">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">
              출석부 {klassName && '(' + klassName + ' - 담임선생님 : ' + teacher + ')'}
            </h1>
            <Select
              value={groupId}
              onChange={(e) => setSelectedGroupId(Number(e.target.value))}
              placeholder="반 선택"
            >
              {allKlassGroups?.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <Divider />
        <div className="py-3 px-6 bg-white flex items-center  w-full ">
          <div className="w-30 text-center">
            <Button
              text="Excel"
              tw={{
                backgroundColor: 'bg-green-500',
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-11',
                paddingX: 'px-4',
                width: 'w-full',
              }}
              onClick={() => {
                setLoading(true);
                downloadAttendanceBook();
                setLoading(false);
              }}
            />
          </div>

          <div className="w-full flex items-center justify-center space-x-4">
            <div
              className={`rounded-full w-10 h-10 flex items-center justify-center border-2 cursor-pointer`}
              onClick={() => handleDateChange(-7)}
            >
              <ChevronLeftIcon />
            </div>
            <div className="text-center">
              <p className="text-xl font-semibold">
                {selday.getFullYear()}년 {tselweek}주차
              </p>
              <p className="text-sm">
                {selmonday.getMonth() + 1}월 {selmonday.getDate()}일 ~ {selfriday.getMonth() + 1}월{' '}
                {selfriday.getDate()}일
              </p>
            </div>
            <div
              className={`rounded-full w-10 h-10 flex items-center justify-center border-2 cursor-pointer`}
              onClick={() => handleDateChange(7)}
            >
              <ChevronRightIcon />
            </div>
          </div>

          <div className="w-30 text-center">
            <Button
              text="명렬표"
              tw={{
                backgroundColor: 'bg-green-500',
                minWidth: 'min-w-max',
                paddingY: 'py-2.5',
                height: 'h-11',
                paddingX: 'px-4',
                width: 'w-full',
              }}
              onClick={() => {
                setLoading(true);
                downloadStudentNameMatrix();
                setLoading(false);
              }}
            />
          </div>
        </div>
        <div className="h-screen-10 overflow-y-scroll w-full px-6 py-6 text-sm">
          <table className="pt-24 table-fixed text-center w-full border-collapse rounded-lg mb-16">
            <thead>
              {/* 날짜 row */}
              <tr className="h-2">
                <td
                  colSpan={2}
                  className="w-30 border-grey-6 border py-2  bg-gray-50 text-center"
                >{`${nullSafeValue(row0[0])} ${nullSafeValue(row0[2])}`}</td>
                <td
                  colSpan={9}
                  className="w-1/5 border-grey-6 border py-2 bg-orange-2"
                >{`${nullSafeValue(row0[4])} ${nullSafeValue(row0[3])}`}</td>
                <td
                  colSpan={9}
                  className="w-1/5 border-grey-6 border py-2 bg-pink-2"
                >{`${nullSafeValue(row0[13])} ${nullSafeValue(row0[12])}`}</td>
                <td
                  colSpan={9}
                  className="w-1/5 border-grey-6 border py-2 bg-lightblue-2"
                >{`${nullSafeValue(row0[22])} ${nullSafeValue(row0[21])}`}</td>
                <td
                  colSpan={9}
                  className="w-1/5 border-grey-6 border py-2 bg-lightgreen-2"
                >{`${nullSafeValue(row0[31])} ${nullSafeValue(row0[30])}`}</td>
                <td
                  colSpan={9}
                  className="w-1/5 border-grey-6 border py-2 bg-lightpurple-2"
                >{`${nullSafeValue(row0[40])} ${nullSafeValue(row0[39])}`}</td>
              </tr>

              {/* 과목 row */}
              <tr className={'h-2'}>
                <td
                  colSpan={2}
                  className="w-30 border-grey-6 border py-2  bg-gray-50 text-center"
                >{`${nullSafeValue(row1[2])}`}</td>
                {createNumberArray(3, 11).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-orange-1"
                  >{`${nullSafeValue(row1[i])}`}</td>
                ))}
                {createNumberArray(12, 20).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-pink-1"
                  >{`${nullSafeValue(row1[i])}`}</td>
                ))}
                {createNumberArray(21, 29).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightblue-1"
                  >{`${nullSafeValue(row1[i])}`}</td>
                ))}
                {createNumberArray(30, 38).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightgreen-1"
                  >{`${nullSafeValue(row1[i])}`}</td>
                ))}
                {createNumberArray(39, 47).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightpurple-1"
                  >{`${nullSafeValue(row1[i])}`}</td>
                ))}
              </tr>

              {/* 선생님 row */}
              <tr className={'h-2'}>
                <td
                  colSpan={2}
                  className="w-30 border-grey-6 border py-2  bg-gray-50 text-center"
                >{`${nullSafeValue(row2[2])}`}</td>
                {createNumberArray(3, 11).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-orange-1"
                  >{`${nullSafeValue(row2[i])}`}</td>
                ))}
                {createNumberArray(12, 20).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-pink-1"
                  >{`${nullSafeValue(row2[i])}`}</td>
                ))}
                {createNumberArray(21, 29).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightblue-1"
                  >{`${nullSafeValue(row2[i])}`}</td>
                ))}
                {createNumberArray(30, 38).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightgreen-1"
                  >{`${nullSafeValue(row2[i])}`}</td>
                ))}
                {createNumberArray(39, 47).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightpurple-1"
                  >{`${nullSafeValue(row2[i])}`}</td>
                ))}
              </tr>

              {/* 번호 이름 row */}
              <tr className={'h-2'}>
                <td className="border-grey-6 border py-2  bg-gray-50 text-center">{`${nullSafeValue(
                  row3[0],
                )}`}</td>
                <td className="border-grey-6 border py-2  bg-gray-50 text-center">{`${nullSafeValue(
                  row3[2],
                )}`}</td>
                {createNumberArray(3, 11).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-orange-1"
                  >{`${nullSafeValue(row3[i])}`}</td>
                ))}
                {createNumberArray(12, 20).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-pink-1"
                  >{`${nullSafeValue(row3[i])}`}</td>
                ))}
                {createNumberArray(21, 29).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightblue-1"
                  >{`${nullSafeValue(row3[i])}`}</td>
                ))}
                {createNumberArray(30, 38).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightgreen-1"
                  >{`${nullSafeValue(row3[i])}`}</td>
                ))}
                {createNumberArray(39, 47).map((i) => (
                  <td
                    key={i}
                    className="break-all border-grey-6 border text-sm bg-lightpurple-1"
                  >{`${nullSafeValue(row3[i])}`}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {studentIdList.map((studentId) => {
                return (
                  <tr key={studentId} className="h-2">
                    {getStudentAttendanceHead(rest, studentId).map((cell, index) => {
                      return (
                        <td key={index} className="border-grey-6 border truncate w-12 text-sm">
                          {nullSafeValue(cell)}
                        </td>
                      );
                    })}
                    {/* 월요일 */}
                    {getStudentAttendanceByDayOfWeek(rest, studentId, 'monday', 9).map(
                      (cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`border-grey-6 border py-2  bg-gray-50 text-center bg-orange-3
                             ${editMode ? 'cursor-pointer' : ''}
                             ${nullSafeValue(cell) === '.' ? '' : 'text-red-500'}`}
                            onClick={() => setAbsent(studentId, selmonday, index, 'monday')}
                          >
                            {nullSafeValue(cell)}
                          </td>
                        );
                      },
                    )}
                    {/* 화요일 */}
                    {getStudentAttendanceByDayOfWeek(rest, studentId, 'tuesday', 9).map(
                      (cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`border-grey-6 border py-2  bg-gray-50 text-center bg-pink-3
                             ${editMode ? 'cursor-pointer' : ''}
                             ${nullSafeValue(cell) === '.' ? '' : 'text-red-500'}`}
                            onClick={() => setAbsent(studentId, seltuesday, index, 'tuesday')}
                          >
                            {nullSafeValue(cell)}
                          </td>
                        );
                      },
                    )}
                    {/* 수요일 */}
                    {getStudentAttendanceByDayOfWeek(rest, studentId, 'wednesday', 9).map(
                      (cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`border-grey-6 border py-2  bg-gray-50 text-center bg-lightblue-3
                             ${editMode ? 'cursor-pointer' : ''}
                             ${nullSafeValue(cell) === '.' ? '' : 'text-red-500'}`}
                            onClick={() => setAbsent(studentId, selwednesday, index, 'wednesday')}
                          >
                            {nullSafeValue(cell)}
                          </td>
                        );
                      },
                    )}
                    {/* 목요일 */}
                    {getStudentAttendanceByDayOfWeek(rest, studentId, 'thursday', 9).map(
                      (cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`border-grey-6 border py-2  bg-gray-50 text-center bg-lightgreen-3
                             ${editMode ? 'cursor-pointer' : ''}
                             ${nullSafeValue(cell) === '.' ? '' : 'text-red-500'}`}
                            onClick={() => setAbsent(studentId, selthursday, index, 'thursday')}
                          >
                            {nullSafeValue(cell)}
                          </td>
                        );
                      },
                    )}
                    {/* 금요일 */}
                    {getStudentAttendanceByDayOfWeek(rest, studentId, 'friday', 9).map(
                      (cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`border-grey-6 border py-2  bg-gray-50 text-center bg-lightpurple-3
                             ${editMode ? 'cursor-pointer' : ''}
                             ${nullSafeValue(cell) === '.' ? '' : 'text-red-500'}`}
                            onClick={() => setAbsent(studentId, selfriday, index, 'friday')}
                          >
                            {nullSafeValue(cell)}
                          </td>
                        );
                      },
                    )}
                  </tr>
                );
              })}

              {/* 출결신청 */}
              <tr className="h-2">
                <td
                  colSpan={2}
                  className="w-30 border-grey-6 border py-2  bg-gray-50 text-center"
                >{`${nullSafeValue(last1[2])}`}</td>
                {Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <td
                      key={index}
                      colSpan={9}
                      className="border-grey-6 text-xs border text-left whitespace-pre-line"
                    >{`${nullSafeValue(last1[3 + index * 9])}`}</td>
                  ))}
              </tr>

              {/* 득기사항 */}
              <tr className="h-2">
                <td
                  colSpan={2}
                  className="w-30 border-grey-6 border py-2  bg-gray-50 text-center"
                >{`${nullSafeValue(last2[2])}`}</td>
                {Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <>
                      {coloredComment.length > 0 && (
                        <td
                          key={index}
                          colSpan={9}
                          className="border-grey-6 text-xs border text-left whitespace-pre-line"
                        >
                          {coloredComment[3 + index * 9].map(({ isColored, comment }, index) => {
                            return (
                              <p key={index} className={`${isColored ? 'text-red-500' : ''}`}>
                                {comment}
                              </p>
                            );
                          })}
                        </td>
                      )}
                    </>
                  ))}
              </tr>
            </tbody>
          </table>

          {isHomeRoomTeacher && !editMode ? (
            <div className="mt-6 w-full flex items-center justify-center flex-col">
              <Button
                onClick={() => {
                  setEditMode(true);
                }}
              >
                수정하기
              </Button>
            </div>
          ) : (
            <div className="mt-32"></div>
          )}

          {editMode && (
            <div className=" flex absolute bottom-4 bg-gray-600 rounded-lg p-5 mr-10 space-x-2 items-stretch">
              <div
                className={`grid place-items-center rounded-lg w-30 text-center cursor-pointer align-middle mt-2 ${
                  '.' === editType.mark ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'
                }
            `}
                onClick={() =>
                  setEditType({
                    mark: '.',
                    type1: '',
                    type2: '',
                    absent: false,
                  })
                }
              >
                출석
              </div>
              <div className="space-y-2 space-x-2">
                <Chip
                  text={'♡질병결석'}
                  onClick={() =>
                    setEditType({
                      mark: '♡',
                      type1: '결석',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'♡' === editType.mark}
                  tw={{
                    paddingY: 'py-0.5',
                    minWidth: 'min-w-27',
                  }}
                />
                <Chip
                  text={'♥미인정결석'}
                  onClick={() =>
                    setEditType({
                      mark: '♥',
                      type1: '결석',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'♥' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'▲기타결석'}
                  onClick={() =>
                    setEditType({
                      mark: '▲',
                      type1: '결석',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'▲' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'△인정결석'}
                  onClick={() =>
                    setEditType({
                      mark: '△',
                      type1: '결석',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'△' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />

                <Chip
                  text={'＃질병지각'}
                  onClick={() =>
                    setEditType({
                      mark: '＃',
                      type1: '지각',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'＃' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'Ｘ미인정지각'}
                  onClick={() =>
                    setEditType({
                      mark: 'Ｘ',
                      type1: '지각',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'Ｘ' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'≠기타지각'}
                  onClick={() =>
                    setEditType({
                      mark: '≠',
                      type1: '지각',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'≠' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'◁인정지각'}
                  onClick={() =>
                    setEditType({
                      mark: '◁',
                      type1: '지각',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'◁' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />

                <Chip
                  text={'＠질병조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '＠',
                      type1: '조퇴',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'＠' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'◎미인정조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '◎',
                      type1: '조퇴',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'◎' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'∞기타조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '∞',
                      type1: '조퇴',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'∞' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'▷인정조퇴'}
                  onClick={() =>
                    setEditType({
                      mark: '▷',
                      type1: '조퇴',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'▷' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'☆질병결과'}
                  onClick={() =>
                    setEditType({
                      mark: '☆',
                      type1: '결과',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'☆' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'◇미인정결과'}
                  onClick={() =>
                    setEditType({
                      mark: '◇',
                      type1: '결과',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'◇' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'＝기타결과'}
                  onClick={() =>
                    setEditType({
                      mark: '＝',
                      type1: '결과',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'＝' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'▽인정결과'}
                  onClick={() =>
                    setEditType({
                      mark: '▽',
                      type1: '결과',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'▽' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />

                <Chip
                  text={'v질병기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v1',
                      type1: '기타',
                      type2: '질병',
                      absent: true,
                    })
                  }
                  active={'v1' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'v미인정기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v2',
                      type1: '기타',
                      type2: '미인정',
                      absent: true,
                    })
                  }
                  active={'v2' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'v기타기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v3',
                      type1: '기타',
                      type2: '기타',
                      absent: true,
                    })
                  }
                  active={'v3' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
                <Chip
                  text={'v인정기타'}
                  onClick={() =>
                    setEditType({
                      mark: 'v4',
                      type1: '기타',
                      type2: '인정',
                      absent: true,
                    })
                  }
                  active={'v4' === editType.mark}
                  tw={{ paddingY: 'py-0.5', minWidth: 'min-w-27' }}
                />
              </div>
              <div
                className={`grid place-items-center rounded-lg w-30 text-center cursor-pointer align-middle mt-2 bg-brand-1 text-white`}
                onClick={() => {
                  setEditMode(false);
                }}
              >
                닫기
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
