import React, { FC, useEffect, useRef, useState } from 'react';
import Calendar from '@toast-ui/react-calendar';
import { Blank } from '../../../reusable';
import { CustomTuiModal, ErrorBlank, LnbCalendarsItem } from '../../../components';
import { DayAfter, DayAgo, makeDateToString } from '../../../utils';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/svg/icon-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/svg/icon-chevron-right.svg';

// If you use the default popups, use this.
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import './CalendarPage.css';
import { useTeacherCalendarDetail } from '../../../container/teacher-calendar-detail';
import { CalendarIdEnum } from '../../../generated/model';
import { Schedule } from '../../../types';

export const attendees = [
  {
    id: '1',
    name: '일반',
  },
  { id: '2', name: '지정불가' },
  { id: '3', name: '공휴일' },
];

const getDayName = (model: any) => {
  let dayName = '';
  switch (model.label) {
    case 'Sun':
      dayName = '일';
      break;
    case 'Mon':
      dayName = '월';
      break;
    case 'Tue':
      dayName = '화';
      break;
    case 'Wed':
      dayName = '수';
      break;
    case 'Thu':
      dayName = '목';
      break;
    case 'Fri':
      dayName = '금';
      break;
    case 'Sat':
      dayName = '토';
      break;
  }
  return (
    '<span class="tui-full-calendar-dayname-name" style="padding-left:calc(50% - 4px);">' +
    dayName +
    '</span>'
  );
};

const calendars = [
  {
    id: CalendarIdEnum.NUMBER_0,
    name: '학사 일정',
    bgColor: '#9e5fff',
    borderColor: '#9e5fff',
  },
  {
    id: CalendarIdEnum.NUMBER_1,
    name: '선생님 일정',
    bgColor: '#00a9ff',
    borderColor: '#00a9ff',
  },
];

export const CalendarPage: FC = () => {
  const {
    setDateRange,
    errorMessage,
    calendarData: schedules,
    isCalendarLoading,
    refetchCalendar,
    handleCalendarCreate,
    handleCalendarUpdate,
    handleCalendarDelete,
    calendarId: filterId,
    setCalendarId: setFilterId,
  } = useTeacherCalendarDetail();

  const [modalOpen, setModalOpen] = useState(false);
  const [event, setEvent] = useState<any>(null);
  const calendarRef = useRef<any>(null);
  const [currentDateString, setCurrentDateString] = useState('');
  const [isLoading, setLoading] = useState(false);

  const onClickNavi = (event: any) => {
    const calendar = calendarRef?.current?.getInstance();
    if (calendar) {
      const { target } = event;
      let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
      action = action?.replace('move-', '');

      const _date = calendar.getDate().toDate();
      _date.setDate(1);
      calendar.setDate(_date);

      typeof calendar[action] === 'function' && calendar[action]();
      calendar.render();

      setCurrentDateString(makeDateToString(calendar.getDate()) || '');
    }
  };

  const handleCreateSchedule = (scheduleData: any) => {
    try {
      setModalOpen(false);
      setEvent(null);
      handleCalendarCreate({
        title: scheduleData?.title,
        location: scheduleData?.location,
        isAllDay: scheduleData?.isAllDay,
        start: scheduleData?.start,
        end: scheduleData?.end,
        category: scheduleData?.isAllDay ? 'allday' : 'time',
        calendarId: scheduleData?.calendarId,
        attendee: scheduleData?.attendee,
      })
        .then(() => setLoading(false))
        .then(() => refetchCalendar());
    } catch (err: any) {}
  };

  const handleUpdateSchedule = (schedule: any) => {
    try {
      handleCalendarUpdate(event?.schedule?.id, {
        title: schedule.title,
        location: schedule.location,
        isAllDay: schedule.isAllDay,
        category: schedule.isAllDay ? 'allday' : 'time',
        calendarId: schedule.calendarId,
        attendee: schedule.attendee,
        start: schedule.start,
        end: schedule.end,
      })
        .then(() => setLoading(false))
        .then(() => refetchCalendar())
        .then(() => setEvent(null))
        .then(() => setModalOpen(false));
    } catch (err: any) {
      console.log(err?.message);
    }
  };

  useEffect(() => {
    const calendar = calendarRef?.current?.getInstance();

    if (calendar) {
      setDateRange({
        startDate: DayAgo(calendar.getDateRangeStart().toDate()),
        endDate: DayAfter(calendar.getDateRangeEnd().toDate()),
      });

      if (!calendar?.events?.beforeCreateSchedule?.length) {
        calendar.on('beforeCreateSchedule', (event: any) => {
          setModalOpen(true);
          setEvent(event);
          calendar.setDate();
        });
      }

      if (!calendar?.events?.beforeUpdateSchedule?.length) {
        calendar.on('beforeUpdateSchedule', (event: any) => {
          calendar.setDate();
          if (event?.triggerEventName === 'click') {
            refetchCalendar().then(() => {
              setModalOpen(true);
              setEvent(event);
            });
          } else {
            const { schedule, changes } = event;
            handleUpdateSchedule({
              title: schedule.title,
              location: schedule.location,
              isAllDay: schedule.isAllDay,
              category: schedule.isAllDay ? 'allday' : 'time',
              ...changes,
              calendarId: changes?.calendarId || schedule.calendarId,
              start: changes?.start?.toDate() || schedule.start?.toDate(),
              end: changes?.end?.toDate() || schedule.end?.toDate(),
            });
          }
        });
      }

      if (!calendar?.events?.beforeDeleteSchedule?.length) {
        calendar.on('beforeDeleteSchedule', (scheduleData: any) => {
          calendar.setDate();
          const { schedule } = scheduleData;
          try {
            refetchCalendar().then(() => handleCalendarDelete(schedule.id));
          } catch (err: any) {
            console.log(err?.message);
          }
        });
      }

      setCurrentDateString(makeDateToString(calendar.getDate()) || '');
    }
  }, []);

  useEffect(() => {
    const calendar = calendarRef?.current?.getInstance();

    if (calendar) {
      const _schedules = schedules && JSON.parse(JSON.stringify(schedules));
      calendar.clear();
      calendar.createSchedules(_schedules);
    }
  }, [schedules]);

  return (
    <>
      {(isCalendarLoading || isLoading) && <Blank />}
      {errorMessage && <ErrorBlank />}
      <div className="col-span-6 grid grid-cols-6">
        <div className="col-span-6 h-screen relative flex flex-col">
          <div className="hidden md:block">
            <div id="lnb">
              <div className="lnb-new-schedule">
                <button
                  id="btn-new-schedule"
                  type="button"
                  className="btn btn-default btn-block lnb-new-schedule-btn"
                  data-toggle="modal"
                  onClick={() => setModalOpen(true)}
                >
                  새 일정 추가
                </button>
              </div>
              <div id="lnb-calendars" className="lnb-calendars">
                <div>
                  <div className="lnb-calendars-item">
                    <label>
                      <input
                        className="tui-full-calendar-checkbox-square"
                        type="checkbox"
                        onChange={() => filterId && setFilterId(undefined)}
                        checked={!filterId}
                      />
                      <span></span>
                      <strong>모두 보기</strong>
                    </label>
                  </div>
                </div>
                <div id="calendarList" className="lnb-calendars-d1">
                  {calendars.map((el: any) => (
                    <LnbCalendarsItem
                      key={el.id}
                      value={el.id}
                      checked={false}
                      color={el.bgColor}
                      text={el.name}
                      onClick={() => setFilterId(el.id)}
                    />
                  ))}
                </div>
              </div>
              <div className="lnb-footer">© NHN Corp.</div>
            </div>
            <div id="right" className="overflow-y-scroll scroll-box">
              <div className="flex space-x-6 items-center" id="menu">
                <button
                  type="button"
                  className="border rounded-full px-6 py-2 border-gray-300 hover:border-gray-400 text-sm"
                  data-action="move-today"
                  onClick={(e) => onClickNavi(e)}
                >
                  오늘
                </button>
                <div className="flex space-x-2 items-center">
                  <button
                    className="border rounded-full p-2 border-gray-300 hover:border-gray-400"
                    data-action="move-prev"
                    onClick={(e) => onClickNavi(e)}
                  >
                    <ChevronLeftIcon className="w-4 h-4" data-action="move-prev" />
                  </button>
                  <button
                    className="border rounded-full p-2 border-gray-300 hover:border-gray-400"
                    data-action="move-next"
                    onClick={(e) => onClickNavi(e)}
                  >
                    <ChevronRightIcon className="w-4 h-4" data-action="move-next" />
                  </button>
                </div>
                <p>{currentDateString}</p>
              </div>
              {/* @ts-ignore */}
              <Calendar
                height="100%"
                calendars={calendars}
                disableDblClick={true}
                disableClick={false}
                isReadOnly={false}
                month={{
                  startDayOfWeek: 0,
                }}
                scheduleView
                taskView
                template={{
                  monthDayname: (model) => getDayName(model),
                  milestone(schedule) {
                    return `<span style="color:#fff;background-color: ${schedule.bgColor};">${schedule.title}</span>`;
                  },
                  milestoneTitle() {
                    return '<div class="w-full h-full flex items-center justify-end">Milestone</div>';
                  },
                  allday(schedule) {
                    return `<span style="color:#fff;">${schedule.title}<i class="fa fa-refresh"></i></span>`;
                  },
                  alldayTitle() {
                    return '<div class="w-full h-full flex items-center justify-end">All Day</div>';
                  },
                }}
                theme={{}}
                timezones={[
                  {
                    timezoneOffset: 540,
                    displayLabel: 'GMT+09:00',
                    tooltip: 'Seoul',
                    timezoneName: 'Asia/Seoul',
                  },
                ]}
                useDetailPopup
                defaultView="month"
                view="month"
                ref={calendarRef}
              />
              <CustomTuiModal
                {...{
                  isOpen: modalOpen,
                  onClose: () => {
                    setModalOpen(false);
                    setEvent(null);
                  },
                  onSubmit: !event?.schedule?.id ? handleCreateSchedule : handleUpdateSchedule,
                  submitText: event?.triggerEventName === 'mouseup' ? 'Save' : 'Update',
                  calendars: calendars,
                  schedule: schedules?.find((el: any) => el.id === event?.schedule?.id),
                  startDate:
                    event?.start?.toDate() || event?.schedule?.start?.toDate() || new Date(),
                  endDate: event?.end?.toDate() || event?.schedule?.end?.toDate() || new Date(),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
