import { useState } from 'react';

import { GroupContainer } from './group';
import {
  useLifeRecordControllerDownloadRecordActivity,
  useLifeRecordControllerDownloadRecordSummary,
  useStudentGroupsControllerFindByGroupId,
} from '../generated/endpoint';
import { Group } from '../generated/model';
import { downloadExcel } from '../utils/download-excel-util';

export const useTeacherRecord = () => {
  const { teacherKlubGroups, errorGroups } = GroupContainer.useContext();
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(teacherKlubGroups[0]);

  const { data: studentGroups, refetch: refetchStudentGroups } =
    useStudentGroupsControllerFindByGroupId(selectedGroup?.id ?? 0, {
      query: {
        enabled: !!selectedGroup?.id,
      },
    });

  const { refetch: refetchDownloadRecordSummary } = useLifeRecordControllerDownloadRecordSummary(
    selectedGroup?.id ?? 0,
    {
      query: {
        enabled: false,
        onSuccess: (data) => {
          downloadExcel(data, `생활기록부_총정리_${selectedGroup?.name}`);
        },
      },
    },
  );

  const { refetch: refetchDownloadRecordActivity } = useLifeRecordControllerDownloadRecordActivity(
    selectedGroup?.id ?? 0,
    {
      query: {
        enabled: false,
        onSuccess: (data) => {
          downloadExcel(data, `생활기록부_활동내역_${selectedGroup?.name}`);
        },
      },
    },
  );

  //     link.download = `생활기록부_활동내역_${selectedGroup?.name}}`;
  const downloadRecordSummary = () => {
    refetchDownloadRecordSummary();
  };

  //     link.download = `생활기록부_총정리_${selectedGroup?.name}`;
  const downloadRecordActivity = () => {
    refetchDownloadRecordActivity();
  };

  return {
    studentGroups,
    selectedGroup,
    setSelectedGroup,
    refetchStudentGroups,
    teacherKlubGroups,
    errorGroups,
    downloadRecordSummary,
    downloadRecordActivity,
  };
};
