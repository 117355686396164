import React, { FC, useEffect, useState } from 'react';

import { SetterOrUpdater } from 'recoil';

import { useStudentKlassHistory } from '../../../container/student-klass-history';
import { useStudentParentMyInfoUpdate } from '../../../container/student-parent-my-info-update';
import { ResponseUserDto, Role } from '../../../generated/model';
import {
  BackButton,
  Blank,
  Button,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { Validator } from '../../../utils/validator';

interface MyInfoUpdatePageProps {
  me: ResponseUserDto;
  setIsUpdateMe: SetterOrUpdater<boolean>;
}

export const MyInfoUpdatePage: FC<MyInfoUpdatePageProps> = ({ me, setIsUpdateMe }) => {
  const { handleParentMyInfoUpdate, handleStudentMyInfoUpdate, isLoading } =
    useStudentParentMyInfoUpdate();
  const { klassHistoryList } = useStudentKlassHistory();

  const [name, setName] = useState(me?.name);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState(me?.phone || '');
  const [nokName, setNokName] = useState('');
  const [nokPhone, setNokPhone] = useState('');
  const [birthDate, setBirthDate] = useState<any>('');
  const [hopePath, setHopePath] = useState('');
  const [hopeMajor, setHopeMajor] = useState('');

  const handleSubmit = () => {
    me.role === Role.PARENT
      ? handleParentMyInfoUpdate({ name, password, phone, nokName, nokPhone, birthDate })
      : handleStudentMyInfoUpdate({
          name,
          password,
          phone,
          birthDate,
          hopePath,
          hopeMajor,
        });
  };

  useEffect(() => {
    if (!name) {
      setName(me?.name || '');
    }
    if (!nokName) {
      setNokName(me?.nokName || '');
    }
    if (!nokPhone) {
      setNokPhone(me?.nokPhone || '');
    }
    if (!birthDate) {
      setBirthDate(me?.birthDate || '');
    }
    if (!hopePath) {
      setHopePath(me?.hopePath || '');
    }
    if (!hopeMajor) {
      setHopeMajor(me?.hopeMajor || '');
    }
  }, []);

  const spacere = / /g;

  const buttonDisabled =
    me && me?.role === 'PARENT'
      ? !name || !phone || password !== password2
      : !name || !phone || !nokName || !nokPhone || password !== password2;

  return (
    <div>
      <TopNavbar
        title="내 정보 수정"
        left={
          <div className="h-15">
            <BackButton className="h-15" onClick={() => setIsUpdateMe(false)} />
          </div>
        }
      />
      {/*{loading && <Blank />}*/}
      {isLoading && <Blank />}
      <Section>
        <div className="w-full">
          <TextField
            label="이름"
            id="name"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="w-full">
          <TextField
            label="이메일"
            value={me.email || ''}
            readOnly
            disabled
            tw={{ color: 'text-gray-400' }}
          />
        </div>
        <div className="w-full">
          <TextField
            type="password"
            label="비밀번호 - 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상"
            placeholder="비밀번호를 입력해주세요"
            value={password}
            onChange={(e) => setPassword(String(e.target.value).replace(spacere, ''))}
            style={{ marginBottom: '0.5rem' }}
          />
          <TextField
            type="password"
            placeholder="비밀번호 확인"
            value={password2}
            onChange={(e) => setPassword2(String(e.target.value).replace(spacere, ''))}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="전화번호"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              borderColor: !phone ? 'rgba(185, 28, 28)' : '',
            }}
          />
        </div>
        {klassHistoryList
          ?.sort((a, b) => +(a?.group?.year || 0) - +(b?.group?.year || 0))
          .map((klass) => (
            <div className="w-full flex items-center space-x-2" key={klass.id}>
              <div className="w-full">
                <TextField
                  label="학년 / 학교 / 학년 / 반 / 번호"
                  readOnly
                  value={`${klass?.group?.year} ${me?.school?.name} ${klass?.group?.name} ${klass?.studentNumber}번`}
                />
              </div>
              <br />
            </div>
          ))}

        {me?.role !== 'PARENT' && (
          <>
            <div className="w-full">
              <TextField
                type="date"
                label="*생년월일"
                onChange={(e) => setBirthDate(e.target.value)}
                value={birthDate}
                tw={{
                  borderColor: birthDate ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div>
            <div className="w-full">
              <TextField
                label="희망진로"
                value={hopePath}
                onChange={(e) => setHopePath(e.target.value)}
              />
            </div>
            <div className="w-full">
              <TextField
                label="희망학과"
                value={hopeMajor}
                onChange={(e) => setHopeMajor(e.target.value)}
              />
            </div>

            <div className="w-full">
              <TextField
                disabled
                label="보호자 이름"
                value={nokName}
                onChange={(e) => setNokName(e.target.value)}
                tw={{
                  borderColor: nokName ? 'border-gray-300' : 'border-red-700',
                }}
              />
            </div>
            <div className="w-full">
              <PhoneNumberField
                disabled
                label="보호자 연락처"
                value={nokPhone}
                onChange={(e) => setNokPhone(e.target.value)}
                style={{
                  borderColor: !nokPhone ? 'rgba(185, 28, 28)' : '',
                }}
              />
            </div>
          </>
        )}

        <Button
          disabled={buttonDisabled}
          onClick={() => {
            if (password.length === 0) {
              alert('비밀번호를 공백으로 기입할 시 기존 비밀번호가 유지됩니다.');
              handleSubmit();
            } else {
              if (password !== password2) {
                alert('비밀번호와 비밀번호 확인이 다릅니다.');
                return;
              }
              if (!Validator.passwordRule1(password) && !Validator.passwordRule2(password)) {
                alert(
                  '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
                );
                return;
              }
              handleSubmit();
            }
          }}
        >
          내 정보 수정하기
        </Button>
      </Section>
    </div>
  );
};
