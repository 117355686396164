import React from 'react';
import { Route, RouteComponentProps, Switch, useHistory, withRouter } from 'react-router-dom';

import ActivitySubmitDetailPage from './ActivitySubmitDetailPage';
import { ErrorBlank, SubmitterItem, Tab } from '../../../components';
import { useTeacherActivitySubmit } from '../../../container/teacher-activity-submit';
import { Blank } from '../../../reusable';

interface MatchParams {
  id: string;
}

interface ActivitySubmitPageProps extends RouteComponentProps<MatchParams> {
  groupId?: number;
}

const ActivitySubmitPage: React.FC<ActivitySubmitPageProps> = ({ match, groupId }) => {
  const history = useHistory();
  const { id } = match.params;
  const { isError, isLoading, filteredUser, refetch, filterIndex, setFilterIndex } =
    useTeacherActivitySubmit(+id);

  if (isError) {
    return <ErrorBlank />;
  }

  return (
    <div className="bg-white ml-0.5 grid grid-cols-7 h-screen">
      {isLoading && <Blank reversed />}
      <div className="col-span-4">
        <div className="w-full flex items-center justify-between p-4">
          <div className="space-x-2 flex items-center">
            <h3 className="text-lg">제출자 리스트</h3>
            <div className="text-gray-500 text-sm">총 {filteredUser?.length}명</div>
          </div>
          <div className="text-brand-1 cursor-pointer" onClick={() => refetch()}>
            새로고침
          </div>
        </div>
        <div className="h-0.5 bg-gray-100 "></div>
        <Tab
          filter={filterIndex}
          setFilter={(n) => {
            localStorage.setItem('selectedFilter', n.toString());
            setFilterIndex(n);
          }}
        />
        <div className="p-4 overflow-y-scroll h-screen-8">
          {filteredUser?.map((user) => (
            <SubmitterItem
              key={user.userId}
              user={user}
              id={id}
              onClick={() => {
                history.push(`/teacher/activity/submit/${id}/${user.studentActivityId}`);
              }}
            />
          ))}
        </div>
      </div>
      <div className="col-span-3">
        <Switch>
          <Route
            path={`/teacher/activity/submit/${id}/:said`}
            component={() => <ActivitySubmitDetailPage activityId={+id} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(ActivitySubmitPage);
