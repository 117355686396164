import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ActivityFilterEnum } from '../constants/activity-filter.enum';
import { useActivityControllerFindByStudent } from '../generated/endpoint';
import { childState, meState } from '../store';

export const useStudentActivity = () => {
  const history = useHistory();
  const me = useRecoilValue(meState);
  const child = useRecoilValue(childState);
  const { data, isError } = useActivityControllerFindByStudent({
    request: {
      headers: {
        'child-user-id': child?.id,
      },
    },
  });

  useEffect(() => {
    if (me?.role === 'PARENT') history.replace('/student');
  }, [me]);

  const [subject, setSubject] = useState<string>();
  const [blankOpen, setBlankOpen] = useState(false);
  const [filter, setFilter] = useState<ActivityFilterEnum>(ActivityFilterEnum.All);

  const activities = data
    ?.filter((a) => {
      if (filter === ActivityFilterEnum.All || filter === ActivityFilterEnum.Before) {
        return true;
      } else {
        return a.type === filter;
      }
    })
    ?.sort((a, b) =>
      filter === ActivityFilterEnum.Before
        ? new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        : 0,
    );

  return {
    activities,
    isError,
    blankOpen,
    setBlankOpen,
    subject,
    setSubject,
    filter,
    setFilter,
  };
};
