/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type NoticeCategoryEnum = typeof NoticeCategoryEnum[keyof typeof NoticeCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NoticeCategoryEnum = {
  공지: '공지',
  일정: '일정',
  자료: '자료',
  신청: '신청',
  평가: '평가',
} as const;
