import { Link, Route, Switch } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';

import { NewsletterAddPage } from './NewsletterAddPage';
import { NewsletterDetailPage } from './NewsletterDetailPage';
import { NewsletterDownloadPage } from './NewsletterDownloadPage';
import { NewsletterSubmitPage } from './NewsletterSubmitPage';
import { NewsletterCard } from '../../../components';
import { useTeacherNewsletter } from '../../../container/teacher-newsletter';
import { Newsletter, NewsletterCategoryEnum } from '../../../generated/model';
import { Blank, Chip, HorizontalScrollView, useRecoilValue } from '../../../reusable';
import { meStateNew, newsletterOpenedGroupState } from '../../../store';

const NewsletterPage = () => {
  const me = useRecoilValue(meStateNew);
  const setNewsletterOpenedGroup = useSetRecoilState(newsletterOpenedGroupState);
  const { newsletters, category, isLoading, setCategory } = useTeacherNewsletter();

  return (
    <>
      {/* Desktop V */}
      {isLoading && <Blank reversed />}
      {/*{error && <ErrorBlank />}*/}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="flex justify-between px-6 py-6">
          <h1 className="text-2xl font-semibold">가정통신문</h1>
          {me?.canEditNewsletter && (
            <Link to="/teacher/newsletter/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          )}
        </div>
        <div className="h-0.5 bg-gray-100 scroll-box"></div>
        <HorizontalScrollView twInner={{ marginY: 'my-4' }}>
          <Chip
            text={'전체'}
            onClick={() => setCategory(undefined)}
            active={!category}
            tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
          />
          {Object.keys(NewsletterCategoryEnum).map((newsletterCategory) => (
            <Chip
              key={newsletterCategory}
              text={newsletterCategory}
              onClick={() => setCategory(newsletterCategory as NewsletterCategoryEnum)}
              tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
              active={newsletterCategory === category}
            />
          ))}
        </HorizontalScrollView>
        <div className="h-screen overflow-y-auto scroll-box">
          {newsletters?.map((newsletter: Newsletter) => (
            <NewsletterCard
              key={newsletter.id}
              newsletter={newsletter}
              onClick={() => setNewsletterOpenedGroup([])}
            />
          ))}
        </div>
      </div>

      {/* Desktop V */}
      <div className="col-span-4 bg-white p-6 overflow-y-auto hidden md:block">
        <Switch>
          <Route path="/teacher/newsletter/add" component={NewsletterAddPage} />
          <Route path="/teacher/newsletter/:id/edit" component={NewsletterAddPage} />
          <Route path="/teacher/newsletter/submit/:id" component={NewsletterSubmitPage} />
          <Route path="/teacher/newsletter/download/:id" component={NewsletterDownloadPage} />
          <Route path="/teacher/newsletter/:id" component={() => <NewsletterDetailPage />} />
        </Switch>
      </div>

      {/* Mobile V */}
      <div className="block md:hidden">
        <Switch>
          <Route path="/teacher/newsletter/add" component={NewsletterAddPage} />
          <Route path="/teacher/newsletter/:id/edit" component={NewsletterAddPage} />
          <Route path="/teacher/newsletter/submit/:id" component={NewsletterSubmitPage} />
          <Route path="/teacher/newsletter/download/:id" component={NewsletterDownloadPage} />
          <Route path="/teacher/newsletter/:id" component={() => <NewsletterDetailPage />} />
        </Switch>
      </div>
    </>
  );
};

export default NewsletterPage;
