import React, { FC, useState } from 'react';

import { ReactComponent as Close } from '../../../assets/svg/Close_Icon.svg';
import { ErrorBlank, SelectMenus } from '../../../components';
import { Blank, Button, Checkbox, Divider, Label, Section, TextField } from '../../../reusable';
import { GroupContainer } from '../../../container/group';
import { GroupType, ResponseGroupDto, StudentGroup, User } from '../../../generated/model';
import { useTeacherGroupAdd } from '../../../container/teacher-group-add';

interface GroupAddPageProps {
  groupData?: ResponseGroupDto;
  groupStudentsData?: User[];
  onSubmit?: () => void;
}

export const GroupAddPage: FC<GroupAddPageProps> = ({ groupData, groupStudentsData, onSubmit }) => {
  const { allKlassGroups } = GroupContainer.useContext();
  const {
    studentGroups,
    handleSubmit,
    isCreateOrUpdateLoading,
    errorMessage,
    selectedGroup,
    setSelectedGroup,
  } = useTeacherGroupAdd({ groupId: groupData?.id, onSubmit });

  const [nowYear] = useState<any>(new Date().getFullYear().toString());
  const [subject, setSubject] = useState<string>(groupData?.teacherGroupSubject || '');
  const [name, setName] = useState(groupData?.name || '');

  const [selectedUsers, setSelectedUsers] = useState<User[]>(groupStudentsData || []);
  const userIds = selectedUsers.map((el) => el.id);

  // if (loading) return <LoadingScreen />;

  if (errorMessage) return <ErrorBlank />;

  return (
    <div className="p-4 h-screen overflow-auto bg-gray-50">
      {isCreateOrUpdateLoading && <Blank />}
      <Section>
        <TextField
          label="그룹명"
          placeholder="그룹명을 입력해주세요."
          onChange={(e) => setName(e.target.value)}
          value={name || undefined}
        />
        <TextField label="년도" value={nowYear} disabled />
        <TextField
          label="과목"
          placeholder="현재 접속한 유저가 이 과목으로 해당 그룹에 소속되게 됩니다."
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
        />
      </Section>

      <Divider />
      <Section>
        <div className="w-36">
          <SelectMenus
            items={allKlassGroups.map((tg) => ({ id: tg.id, name: tg.name }))}
            value={selectedGroup || undefined}
            allText="선택하기"
            onChange={({ id }: { id: number }) => {
              setSelectedGroup(allKlassGroups.find((tg) => tg.id === id) || null);
            }}
          />
        </div>
      </Section>
      <div className="w-full px-3 py-2">
        {!!studentGroups?.length && (
          <Checkbox
            label="전체 선택"
            checked={!studentGroups?.filter((el) => !userIds.includes(el.user?.id)).length}
            onChange={() =>
              !studentGroups?.filter((el) => !userIds.includes(el.user?.id)).length
                ? setSelectedUsers(
                    selectedUsers.filter(
                      (el) => !studentGroups?.map((sg) => sg.user?.id).includes(el.id),
                    ),
                  )
                : setSelectedUsers(
                    selectedUsers.concat(
                      studentGroups
                        ?.filter((el) => !selectedUsers.map((u) => u.id).includes(el.user?.id))
                        .map((el) => el.user) || [],
                    ),
                  )
            }
          />
        )}
      </div>
      <div className="w-full grid grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 pr-4 pb-4">
        {studentGroups
          ?.slice()
          ?.sort((a: any, b: any) => a.studentNumber - b.studentNumber)
          ?.map((el: StudentGroup) => (
            <div
              className={`w-full border-2 py-3 px-3 m-2 rounded-lg cursor-pointer flex items-center justify-between ${
                userIds.includes(el.user?.id) ? 'border-brand-1 bg-light_orange' : ' border-grey-6'
              }`}
              key={el.id}
              onClick={() => {
                if (el?.user) {
                  if (userIds.includes(el.user.id)) {
                    setSelectedUsers(selectedUsers.filter((u) => u.id !== el.user?.id));
                  } else {
                    setSelectedUsers(selectedUsers.concat(el.user));
                  }
                }
              }}
            >
              {selectedGroup?.type === GroupType.KLASS && (
                <div className="font-bold">{el.studentNumber}</div>
              )}
              <div className="font-base whitespace-pre overflow-hidden">{el.user?.name}</div>
            </div>
          ))}
      </div>
      <Divider />
      <Section>
        <div className="">
          <Label text="선택된 유저들" />
          <div className="flex flex-wrap mt-2">
            {selectedUsers.map((el) => (
              <div
                key={el.id}
                className="flex items-center space-x-2 w-max rounded-full px-2.5 py-1.5 m-1s mr-2 mt-2 bg-white border-2 border-brand-1 text-brand-1 whitespace-nowrap text-2sm font-bold cursor-pointer"
                onClick={() => {
                  setSelectedUsers(selectedUsers.filter((u) => u.id !== el.id));
                }}
              >
                <div className="whitespace-pre">{el.name}</div>
                <Close />
              </div>
            ))}
          </div>
        </div>
      </Section>
      <div className="text-center mt-6">
        <Button
          text="등록하기"
          tw={{
            height: 'h-11',
            backgroundColor: !name || !selectedUsers.length ? 'bg-gray-300' : 'bg-brand-1',
          }}
          disabled={!name || !selectedUsers.length}
          style={{ width: '70%' }}
          onClick={() => {
            handleSubmit({
              name,
              year: nowYear,
              subject,
              studentIds: userIds,
            });
          }}
        />
      </div>
    </div>
  );
};
