import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Viewer from 'react-viewer';

import { ErrorBlank, SuperModal } from '../../../components';
import { Constants } from '../../../constants';
import { useTeacherBoardDetail } from '../../../container/teacher-board-detail';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TopNavbar,
  utcToLocalDatetime,
} from '../../../reusable';
import { getFileNameFromUrl } from '../../../utils/file-util';

interface BoardDetailPageProps {
  page: number;
  limit: number;
}

export const BoardDetailPage = ({ page, limit }: BoardDetailPageProps) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const {
    board,
    isBoardLoading,
    images,
    documents,
    viewerImages,
    errorMessage,
    handleBoardDelete,
  } = useTeacherBoardDetail(+id);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <div className="md:hidden block">
        <TopNavbar title="학급게시판" left={<BackButton />} />
      </div>
      <div className="bg-white border rounded-lg p-5 h-full">
        {isBoardLoading && <Blank reversed text="게시글을 불러오는 중입니다." />}
        {errorMessage && <ErrorBlank text="해당 게시글을 불러오지 못했습니다." />}
        <SuperModal
          modalOpen={isDeleteModalOpen}
          setModalClose={() => setDeleteModalOpen(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              정말 해당 학급게시판 게시글을 삭제하시겠습니까?
            </div>
            <Button onClick={handleBoardDelete}>삭제하기</Button>
          </Section>
        </SuperModal>

        <div>
          <Section>
            <div className="flex justify-between items-center">
              <div className="flex space-x-2 items-center">
                <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
                  {board?.category || '학급게시판'}
                </div>
              </div>

              <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
                <div
                  className="text-gray-700"
                  onClick={() =>
                    history.push(`/teacher/board/${board?.id}/edit?page=${page}&limit=${limit}`)
                  }
                >
                  수정
                </div>
                <div
                  className="text-red-400 cursor-pointer"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  삭제
                </div>
              </div>
            </div>
            <div className="text-lg font-bold text-grey-1">{board?.title}</div>
            <div className="text-grey-3 text-sm">
              {board?.createdAt && utcToLocalDatetime(board.createdAt)}
            </div>

            <div className="w-full overflow-x-scroll scroll-box">
              <div className="flex space-x-2 items-center min-w-max">
                {board?.groupBoards?.map((groupBoard) => {
                  const newGroupBoard = groupBoard as unknown as {
                    id: number;
                    group: { id: number; name: string };
                  };
                  return (
                    <span
                      key={newGroupBoard.group.id}
                      className="rounded-full py-2 px-3 border border-gray-400 font-semibold text-gray-500 text-sm"
                    >
                      {newGroupBoard.group.name}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <div
                  key={i}
                  className="w-full"
                  onClick={() => {
                    setActiveIndex(i);
                    setImagesModalOpen(true);
                  }}
                >
                  <div className={`relative pb-3/5 rounded bg-gray-50`}>
                    <LazyLoadImage
                      src={`${Constants.imageUrl}${image}`}
                      alt={''}
                      loading={'lazy'}
                      className={`absolute w-full h-full rounded object-cover`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>
              {documents?.map((fileUrl: string, index) => (
                <div
                  key={index}
                  className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
                >
                  <span>{getFileNameFromUrl(fileUrl)}</span>
                  <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                    <a
                      href={`${Constants.imageUrl}${fileUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      download={getFileNameFromUrl(fileUrl)}
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
              <Linkify>{board?.content}</Linkify>
            </div>
          </Section>
        </div>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};
