import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import undoArrow from '../assets/images/undo-arrow.png';
import { Constants } from '../constants';
import { ImageObject } from '../type';

interface ImageObjectComponentProps {
  id: number;
  imageObjet: ImageObject;
  onDeleteClick?: (key: number) => void;
}
export const ImageObjectComponent = ({
  id,
  imageObjet,
  onDeleteClick,
}: ImageObjectComponentProps) => {
  const handleImageRemove = (key: number) => {
    if (typeof onDeleteClick === 'function') {
      onDeleteClick(key);
    }
  };

  return (
    <div
      className={`relative pb-3/5 rounded border-2 border-dashed border-grey-5 ${
        imageObjet.isDelete ? 'opacity-50' : ''
      }`}
    >
      {onDeleteClick && (
        <span className="absolute -top-3 -right-3 block w-6 h-6 rounded-full ring-2 ring-white bg-red-700 z-40">
          <div
            className="w-full h-full flex items-center justify-center text-white cursor-pointer"
            onClick={() => handleImageRemove(id)}
            style={{ transform: 'translate(0.1px, 0.1px)' }}
          >
            {imageObjet.isDelete ? <img src={undoArrow} alt="" className="w-4 h-4" /> : 'X'}
          </div>
        </span>
      )}
      {typeof imageObjet.image === 'string' ? (
        <LazyLoadImage
          src={`${Constants.imageUrl}${imageObjet.image}`}
          alt={''}
          loading={'lazy'}
          className={`absolute w-full h-full rounded object-cover`}
        />
      ) : (
        <LazyLoadImage
          src={URL.createObjectURL(imageObjet.image)}
          alt={''}
          loading={'lazy'}
          className={`absolute w-full h-full rounded object-cover`}
        />
      )}
      {imageObjet.isDelete && (
        <div className="absolute w-full h-full z-20">
          <div className="flex w-full h-full items-center justify-center">
            <div className="text-4xl text-gray-800 opacity-100">삭제</div>
          </div>
        </div>
      )}
    </div>
  );
};
