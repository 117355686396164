import React, { useState } from 'react';

import { useTeacherInfoUpdate } from '../../../container/teacher-info-update';
import { ResponseUserDto } from '../../../generated/model';
import {
  BackButton,
  Blank,
  Button,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { Validator } from '../../../utils/validator';

interface TeacherInfoUpdatePageProps {
  me: ResponseUserDto;
  setIsUpdateMe: (isUpdateMe: boolean) => void;
}

export const TeacherInfoUpdatePage = ({ me, setIsUpdateMe }: TeacherInfoUpdatePageProps) => {
  const [name, setName] = useState(me?.name);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState(me?.phone || '');

  const { isUpdateMeLoading, updateMe } = useTeacherInfoUpdate();

  const buttonDisabled = !name || !phone || password !== password2;

  const handleUpdate = () => {
    updateMe({
      name,
      phone,
      password,
    })
      .then(() => {
        setIsUpdateMe(false);
      })
      .catch((e) => {
        console.log('update error', e);
      });
  };

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        <TopNavbar title="내 정보" left={<BackButton />} />
      </div>
      <div className="col-span-6 max-w-lg h-screen  px-6 py-6">
        {isUpdateMeLoading && <Blank />}
        <Section>
          <div className="w-full">
            <TextField
              label="이름"
              id="name"
              placeholder="이름을 입력해주세요"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="w-full">
            <TextField
              label="이메일"
              value={me.email || ''}
              readOnly
              disabled
              tw={{ color: 'text-gray-400' }}
            />
          </div>
          <div className="w-full">
            <TextField
              type="password"
              label="비밀번호 - 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상"
              placeholder="비밀번호를 입력해주세요"
              value={password}
              onChange={(e) => setPassword(Validator.removeSpace(String(e.target.value)))}
              style={{ marginBottom: '0.5rem' }}
            />
            <TextField
              type="password"
              placeholder="비밀번호 확인"
              value={password2}
              onChange={(e) => setPassword2(Validator.removeSpace(String(e.target.value)))}
            />
          </div>
          <div className="w-full">
            <PhoneNumberField
              label="전화번호"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                borderColor: !phone ? 'rgba(185, 28, 28)' : '',
              }}
            />
          </div>
          <Button
            disabled={buttonDisabled}
            onClick={() => {
              if (password.length === 0) {
                alert('비밀번호를 공백으로 기입할 시 기존 비밀번호가 유지됩니다.');
                handleUpdate();
              } else {
                if (password !== password2) {
                  alert('비밀번호와 비밀번호 확인이 다릅니다.');
                  return;
                }
                if (!Validator.passwordRule1(password) && !Validator.passwordRule2(password)) {
                  alert(
                    '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
                  );
                  return;
                }
                handleUpdate();
              }
            }}
          >
            내 정보 수정하기
          </Button>
        </Section>
      </div>
    </>
  );
};
