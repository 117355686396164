import { useHistory } from 'react-router';

import { useRecoilValue } from 'recoil';

import { useOutingsControllerFindAllByStudent } from '../generated/endpoint';
import { childState } from '../store';

export const useStudentOuting = () => {
  const child = useRecoilValue(childState);
  const history = useHistory();
  const { data, error, isLoading } = useOutingsControllerFindAllByStudent({
    query: {
      onError: ({ message }) => {
        if (message === 'Unauthorized') {
          history.push('/login');
        }
      },
    },
    request: {
      headers: {
        'child-user-id': child?.id,
      },
    },
  });

  const outings = data
    ?.slice()
    .sort(
      (a, b) =>
        (a.outingStatus === 'DELETE_APPEAL' ? -1 : 0) -
        (b.outingStatus === 'DELETE_APPEAL' ? -1 : 0),
    )
    .sort(
      (a, b) => (a.outingStatus === 'RETURNED' ? -1 : 0) - (b.outingStatus === 'RETURNED' ? -1 : 0),
    );

  return {
    outings,
    error,
    isLoading,
  };
};
