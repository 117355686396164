import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useFieldtripsControllerGetFieldtripsByTeacher } from '../generated/endpoint';
import { FieldtripStatus } from '../generated/model';
import { getEndDate, getStartDate, makeDateToString, MonthAgo } from '../utils';

export const useTeacherFieldtripNotice = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [startDate, setStartDate] = useState(makeDateToString(MonthAgo(new Date())));
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const limit = Number(params.get('limit') ?? '25') || 25;
  const studentName = params.get('username') || '';

  const { data, error } = useFieldtripsControllerGetFieldtripsByTeacher({
    page,
    limit,
    fieldtripStatus: FieldtripStatus.PROCESSED,
    ...(studentName && { username: studentName }),
    startDate: getStartDate(startDate),
    endDate: getEndDate(endDate),
  });

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  return {
    error,
    params,
    startDate,
    endDate,
    data,
    limit,
    page,

    // set state
    setPage,
    setStartDate,
    setEndDate,
  };
};
