import React, { useState } from 'react';

import { every, get } from 'lodash';

import { SelectValues, SuperModal } from '../../../components';
import { useTeacherFieldtripUpdate } from '../../../container/teacher-fieldtrip-update';
import { ResponseUserDto, Fieldtrip } from '../../../generated/model';
import { Blank, Section, Button, TextArea, TextField } from '../../../reusable';
import { makeDateToString2 } from '../../../utils';

interface FieldtripUpdatePageProps {
  fieldtrip: Fieldtrip;
  refetchAll: () => void;
  me: ResponseUserDto;
  setReadState: () => void;
  isConfirmed: boolean;
}

const relationshipType = ['부', '모'];
const fieldtripTypes = ['가족동반여행', '친·인척 방문', '답사∙견학 활동', '체험활동'];

const FieldtripUpdatePage: React.FC<FieldtripUpdatePageProps> = ({
  fieldtrip,
  me,
  refetchAll,
  setReadState,
  isConfirmed,
}) => {
  const type = fieldtrip?.type;
  const [modalOpen, setModalOpen] = useState(false);
  const [updateReason, setUpdateReason] = useState('');
  const [fieldtripType, setFieldtripType] = useState(fieldtripTypes[0]);
  const {
    content,
    purpose,
    accommodation,
    destination,
    guideName,
    guidePhone,
    relationship,
    homePlan,
    setContent,
    setDestination,
    setPurpose,
    setAccommodation,
    setGuideName,
    setGuidePhone,
    setRelationship,
    setHomePlan,
    errorMessage,
    updateFieldtripByTeacher,
    isLoading,
  } = useTeacherFieldtripUpdate({
    fieldtrip,
    updateReason,
    refetchAll,
    setReadState,
    fieldtripType,
  });

  const _checkFillHomePlanContent = () => {
    const checkKeys = ['content1', 'subject1'];
    return every(homePlan, (plan) => {
      return every(checkKeys, (key) => {
        const value = get(plan, key, null);
        return !!value;
      });
    });
  };

  const buttonDisabled =
    type === 'HOME'
      ? !destination || !_checkFillHomePlanContent()
      : !content || !destination || !guideName || !guidePhone || !relationship;

  return (
    <div className="bg-white border rounded-lg py-5 h-screen-10 overflow-y-scroll scroll-box">
      {isLoading && <Blank reversed />}
      <Section>
        <h1 className="text-xl font-semibold">
          {makeDateToString2(fieldtrip?.startAt || '')} ~{' '}
          {makeDateToString2(fieldtrip?.endAt || '')} ({fieldtrip?.usedDays}) 일간{' '}
        </h1>

        <div className="space-y-3 pb-6">
          <div className="  text-lg whitespace-pre-line mb-2">
            남은 일수&nbsp;
            <span className="text-brand-1 underline">
              {fieldtrip ? fieldtrip.currentRemainDays : me?.remainDaysOfFieldtrip}일 중{' '}
              {fieldtrip?.usedDays}일을 신청
            </span>
            합니다.
          </div>
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2">
            ※<span className="font-bold">토,일, 개교기념일 등 학교 휴업일</span>
            은 체험학습 신청 일수에 넣지 않음. <br />※ 체험학습 신청은 신청일보다{' '}
            <span className="font-bold">3일 이전에 신청</span>해야함.
          </div>
          <div className="flex">
            <div className="font-bold text-gray-800 text-lg">
              *{type === 'HOME' ? '가정' : '교외 체험'}학습 계획 작성
            </div>
          </div>
          {type === 'SUBURBS' && (
            <div className="w-full">
              <SelectValues
                selectValues={fieldtripTypes}
                label="*체험학습 형태"
                onChange={(f) => setFieldtripType(f)}
                value={fieldtripType}
                borderColor={fieldtripType ? 'border-gray-300' : 'border-red-700'}
              />
            </div>
          )}

          <div className="w-full">
            <TextField
              label="*목적지"
              id="destination"
              placeholder="목적지를 입력해주세요."
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              readOnly={type === 'HOME'}
              tw={{
                borderColor: destination ? 'border-gray-300' : 'border-red-700',
              }}
            />
          </div>

          <div className="w-full">
            <TextField
              label="*목적"
              id="purpose"
              placeholder="목적을 입력해주세요."
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              tw={{
                borderColor: purpose ? 'border-gray-300' : 'border-red-700',
              }}
            />
          </div>
          {type === 'SUBURBS' && (
            <>
              <div className="w-full">
                <TextField
                  label="*숙박장소(숙박일정이 있는 경우)"
                  id="accommodation"
                  placeholder="숙박장소를 입력해주세요."
                  value={accommodation}
                  onChange={(e) => setAccommodation(e.target.value)}
                  tw={{
                    borderColor: accommodation ? 'border-gray-300' : 'border-red-700',
                  }}
                />
              </div>
              <div className="w-full">
                <TextField
                  label="*인솔자명"
                  id="guideName"
                  placeholder="보호자 이름을 입력해주세요."
                  value={guideName}
                  onChange={(e) => setGuideName(e.target.value)}
                  tw={{
                    borderColor: guideName ? 'border-gray-300' : 'border-red-700',
                  }}
                />
              </div>

              <div className="w-full">
                <SelectValues
                  label="*관계"
                  selectValues={relationshipType}
                  placeholder="선택 (직계 가족만 가능)"
                  value={relationship}
                  onChange={(g) => setRelationship(g)}
                  borderColor={relationship ? 'border-gray-300' : 'border-red-700'}
                />
                <div className="text-red-600 text-sm whitespace-pre-line mb-2">
                  *인솔자가 직계가족이 아닌 경우 현장체험학습을 불허합니다.
                </div>
              </div>
              <div className="w-full">
                <TextField
                  label="*인솔자 연락처"
                  id="guidePhone"
                  placeholder="보호자 연락처를 입력해주세요."
                  value={guidePhone}
                  onChange={(e) => setGuidePhone(e.target.value)}
                  tw={{
                    borderColor: guidePhone ? 'border-gray-300' : 'border-red-700',
                  }}
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="font-bold text-gray-800 text-lg">*현장학습 계획 작성</div>
              </div>
              <TextArea
                placeholder="예시를 보고 내용을 입력해주세요."
                rows={5}
                style={{ height: 'auto', borderWidth: '1px' }}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </>
          )}
          {type === 'HOME' && (
            <>
              <div className="flex justify-between items-center">
                <div className="font-bold text-gray-800 text-lg">*가정학습 계획 작성</div>
              </div>
              {homePlan.map((plan: any, i: number) => (
                <div className=" bg-white shadow-sm p-4 border border-gray-100 rounded-md space-y-4">
                  <div className="font-bold text-gray-800 text-lg text-center pt-10 pb-4">
                    {i + 1}일차
                  </div>
                  <TextField
                    label="*1교시 교과명"
                    id={`home_subject_${i}_1`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject1'] || ''}
                    tw={{
                      borderColor: plan['subject1'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject1'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_1`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content1'] || ''}
                    tw={{
                      borderColor: plan['content1'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content1'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*2교시 교과명"
                    id={`home_subject_${i}_2`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject2'] || ''}
                    tw={{
                      borderColor: plan['subject2'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject2'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_2`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content2'] || ''}
                    tw={{
                      borderColor: plan['content2'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content2'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*3교시 교과명"
                    id={`home_subject_${i}_3`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject3'] || ''}
                    tw={{
                      borderColor: plan['subject3'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject3'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_3`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content3'] || ''}
                    tw={{
                      borderColor: plan['content3'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content3'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*4교시 교과명"
                    id={`home_subject_${i}_4`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject4'] || ''}
                    tw={{
                      borderColor: plan['subject4'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject4'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_4`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content4'] || ''}
                    tw={{
                      borderColor: plan['content4'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content4'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*5교시 교과명"
                    id={`home_subject_${i}_5`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject5'] || ''}
                    tw={{
                      borderColor: plan['subject5'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject5'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_5`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content5'] || ''}
                    tw={{
                      borderColor: plan['content5'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content5'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*6교시 교과명"
                    id={`home_subject_${i}_6`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject6'] || ''}
                    tw={{
                      borderColor: plan['subject6'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject6'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_6`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content6'] || ''}
                    tw={{
                      borderColor: plan['content6'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content6'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*7교시 교과명"
                    id={`home_subject_${i}_7`}
                    placeholder="예시) 국어,영어,수학 등"
                    value={plan['subject7'] || ''}
                    tw={{
                      borderColor: plan['subject7'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['subject7'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                  <TextField
                    label="*학습할 내용"
                    id={`home_content_${i}_7`}
                    placeholder="예시) 3-1 훈민정음"
                    value={plan['content7'] || ''}
                    tw={{
                      borderColor: plan['content7'] ? 'border-gray-300' : 'border-red-700',
                    }}
                    onChange={(e) => {
                      const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                      newHomePlan[i]['content7'] = e.target.value;
                      setHomePlan(newHomePlan);
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            if (isConfirmed) {
              setModalOpen(true);
            } else {
              updateFieldtripByTeacher();
            }
          }}
        >
          수정하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
      <SuperModal modalOpen={modalOpen} setModalClose={() => setModalOpen(false)} width="w-max">
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 체험학습 신청서를 승인 후 수정하시는 이유를 적어주세요.
          </div>
          <TextArea
            placeholder="수정 이유"
            onChange={(e) => setUpdateReason(e.target.value)}
            value={updateReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              updateFieldtripByTeacher();
            }}
          >
            승인 후 수정하기
          </Button>
        </Section>
      </SuperModal>
    </div>
  );
};

export default FieldtripUpdatePage;
