import React from 'react';

interface TagProps {
  filter: number;
  setFilter: (n: number) => void;
}

export const Tab: React.FC<TagProps> = ({ filter, setFilter }) => {
  return (
    <div className="max-w-5xl justiify-center mx-auto mt-3">
      <nav className="flex space-x-0 ml-1" aria-label="Tabs">
        <div className="cursor-pointer text-gray-500 hover:text-gray-700 mx-3 my-2 font-medium text-sm rounded-md">
          <div
            onClick={() => setFilter(0)}
            className={
              filter === 0
                ? 'bg-brand-2 text-white px-3 py-2 font-medium text-sm rounded-md bg-gray-600'
                : 'text-gray-700 px-3 py-2 font-medium text-sm rounded-md bg-gray-100'
            }
          >
            All
          </div>
        </div>

        <div className="cursor-pointer text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md">
          <div
            onClick={() => setFilter(1)}
            className={
              filter === 1
                ? 'bg-brand-2 text-white px-3 py-2 font-medium text-sm rounded-md bg-gray-600'
                : 'text-gray-700 px-3 py-2 font-medium text-sm rounded-md bg-gray-100'
            }
          >
            제출
          </div>
        </div>
        <div className="cursor-pointer text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ">
          <div
            onClick={() => setFilter(2)}
            className={
              filter === 2
                ? 'bg-brand-2 text-white px-3 py-2 font-medium text-sm rounded-md bg-gray-600'
                : 'text-gray-700 px-3 py-2 font-medium text-sm rounded-md bg-gray-100'
            }
          >
            미제출
          </div>
        </div>
      </nav>
    </div>
  );
};
