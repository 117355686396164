import { useEffect, useState } from 'react';

import { UserContainer } from './user';
import {
  useFieldtripResultControllerUpdateResult,
  useFieldtripsControllerFindOne,
} from '../generated/endpoint';
import { UploadFileTypeEnum } from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';

type Props = {
  id: number;
  sigPadData: string;
};

type Images = Array<{
  file: File | null;
  url: string | null;
}>;

export const useStudentFieldtripSuburbsReportAdd = ({ id, sigPadData }: Props) => {
  const { me } = UserContainer.useContext();
  const { handleUploadFile, isUploadLoading } = useFileUpload();

  const [errorMessage, setErrorMessage] = useState('');
  const [parentsName, setParentsName] = useState(me?.nokName || '');
  const [parentsPhone, setParentsPhone] = useState(me?.nokPhone || '');
  const [destination, setDestination] = useState('');
  const [resultText, setResultText] = useState('');
  const [resultTitle, setResultTitle] = useState('');
  const [images, setImages] = useState<Images>([{ file: null, url: null }]);

  const {
    data: fieldtrip,
    isLoading: isGetFieldtripLoading,
    error,
  } = useFieldtripsControllerFindOne(id, {
    query: {
      onSuccess: ({ destination, resultTitle, resultText }) => {
        setDestination(destination);
        setResultTitle(resultTitle);
        setResultText(resultText);
      },
    },
  });

  const {
    mutateAsync: updateFieldtripResultMutate,
    isLoading: isUpdateFieldtripLoading,
    isSuccess: isUpdateFieldtripSuccess,
  } = useFieldtripResultControllerUpdateResult({
    mutation: {
      onSuccess: (data) => {
        if (!data) return;
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    },
  });

  const getResultImages = () => {
    return images.reduce((acc, el) => {
      return el.url ? [...acc, el.url] : acc;
    }, [] as string[]);
  };

  const updateFieldtripResult = () => {
    if (!fieldtrip) return;

    const { id } = fieldtrip;
    updateFieldtripResultMutate({
      id,
      data: {
        resultText,
        destination,
        resultFiles: getResultImages(),
        studentResultSignature: sigPadData,
        resultTitle,
      },
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    if (!parentsName && !parentsPhone && !destination) {
      if (me?.nokName && me?.nokPhone && fieldtrip?.destination) {
        setParentsName(me.nokName);
        setParentsPhone(me.nokPhone);
        setDestination(fieldtrip.destination);
      }
    }
  }, [fieldtrip]);

  const uploadImages = async (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    try {
      const {
        target: { files, validity },
      } = e;
      if (!validity.valid || !files?.[0]) return;
      const file = files[0];

      // 이미지를 서버에 upload
      const imageUrl = await handleUploadFile(UploadFileTypeEnum['fieldtrips/images'], [file]);

      setImages((prev) => {
        prev[i] = { file, url: imageUrl[0] };
        return [...prev];
      });

      // 업로드 input 하나 추가
      setImages((prev) => [...prev, { file: null, url: null }]);
    } catch (e) {
      console.error(e);
    }
  };

  const isLoading = isGetFieldtripLoading || isUpdateFieldtripLoading || isUploadLoading;

  return {
    fieldtrip,
    isLoading,
    error,
    errorMessage,
    updateFieldtripResult,
    isUpdateFieldtripSuccess,
    parentsPhone,
    parentsName,
    destination,
    resultText,
    resultTitle,
    images,

    setParentsPhone,
    setParentsName,
    setDestination,
    setResultText,
    setResultTitle,
    setImages,

    uploadImages,
  };
};
