import { useState } from 'react';

import { useAbsentsControllerUpdateByTeacher } from '../generated/endpoint';
import { Absent } from '../generated/model';
import { AbsentDescription } from '../types';
import { makeDateToString, makeTimeToString } from '../utils';

type Props = {
  absentData: Absent | undefined;
  reasonType: string[];
  desType: AbsentDescription;
  setChangeMode: (b: boolean) => void;
  refetch: () => void;
};

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

export const useTeacherAbsentUpdate = ({
  absentData,
  reasonType,
  desType,
  setChangeMode,
  refetch,
}: Props) => {
  const [updateReason, setUpdateReason] = useState('');
  const [reason, setReason] = useState(
    absentData ? (reasonType.includes(absentData?.reason) ? absentData?.reason : '기타') : '',
  );
  const [reasonText, setReasonText] = useState(absentData?.reason || '');
  const [report, setReport] = useState(absentData?.reportType || '');
  const [startAt, setStartAt] = useState(
    absentData?.startAt ? makeDateToString(new Date(absentData.startAt)) : '',
  );
  const [endAt, setEndAt] = useState(
    absentData?.endAt ? makeDateToString(new Date(absentData.endAt)) : '',
  );
  const [description, setDescription] = useState(absentData?.description || '');
  const [evidenceType, setEvidenceType] = useState(
    absentData && desType[description]?.evidenceFileType.includes(absentData?.evidenceType)
      ? absentData?.evidenceType
      : '기타',
  );
  const [evidenceTypeText, setEvidenceTypeText] = useState(absentData?.evidenceType);
  const [startHour, setStartHour] = useState(absentData ? getMeridiemHours(absentData.startAt) : 9);
  const [startMinute, setStartMinute] = useState(
    absentData?.startAt ? new Date(absentData.startAt).getMinutes() : 0,
  );
  const [endHour, setEndHour] = useState(absentData ? getMeridiemHours(absentData.endAt) : 16);
  const [endMinute, setEndMinute] = useState(
    absentData?.endAt ? new Date(absentData.endAt).getMinutes() : 40,
  );

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    const hour = Number(startHour);
    date.setHours(hour, Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (report !== '결석') {
      startAt && (date = new Date(startAt));
    } else {
      endAt && (date = new Date(endAt));
    }
    const hour = Number(endHour);
    date.setHours(hour, Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };

  const { mutate: absentUpdateMutate, isLoading } = useAbsentsControllerUpdateByTeacher({
    mutation: {
      onSuccess: async () => {
        await refetch();
        await setChangeMode(false);
      },
    },
  });
  const updateAbsent = () => {
    if (!absentData?.id) return;
    absentUpdateMutate({
      id: absentData?.id,
      data: {
        reportType: report,
        startAt: report !== '결석' ? makeStartAt() : startAt,
        endAt: report !== '결석' ? makeEndAt() : endAt,
        reason: reason === '기타' ? reasonText : reason,
        description: description,
        evidenceType: evidenceType === '기타' ? evidenceTypeText : evidenceType,
        updateReason,
      },
    });
  };

  return {
    updateReason,
    setUpdateReason,
    reason,
    setReason,
    reasonText,
    setReasonText,
    report,
    setReport,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    description,
    setDescription,
    evidenceTypeText,
    setEvidenceTypeText,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    evidenceType,
    setEvidenceType,
    isLoading,
    updateAbsent,
  };
};
