import { useState } from 'react';

import { useOutingsControllerDeny, useOutingsControllerRequestDelete } from '../generated/endpoint';

export const useTeacherOutingDetail = (id: number, refetch: () => void) => {
  const [deny, setDeny] = useState(false);
  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [errM, setErrM] = useState('');

  const { mutate: denyOutingMutate, isLoading: isDenyOutingLoading } = useOutingsControllerDeny({
    mutation: {
      onSuccess: () => {
        setDeny(false);
        setErrM('');
        refetch();
      },
      onError: (err) => {
        setErrM(err?.message);
      },
    },
  });

  // TODO: reason -> notApprovedReason 으로 변경필요
  const denyOuting = () => {
    denyOutingMutate({
      id,
      data: {
        reason: notApprovedReason,
      },
    });
  };

  const { mutate: requestDeleteOutingMutate, isLoading: isRequestDeleteOutingLoading } =
    useOutingsControllerRequestDelete({
      mutation: {
        onSuccess: () => {
          setDeleteAppeal(false);
          refetch();
        },
      },
    });

  const requestDeleteOuting = () => {
    requestDeleteOutingMutate({
      id,
      data: {
        deleteReason,
      },
    });
  };

  const isLoading = isDenyOutingLoading || isRequestDeleteOutingLoading;

  return {
    deny,
    setDeny,
    notApprovedReason,
    setNotApprovedReason,
    deleteReason,
    setDeleteReason,
    deleteAppeal,
    setDeleteAppeal,
    errM,
    setErrM,
    requestDeleteOuting,
    denyOuting,
    isLoading,
  };
};
