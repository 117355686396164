import { useEffect, useState } from 'react';

import readXlsxFile, { Row } from 'read-excel-file';

import { useStudentGroupsControllerDownloadStudentListByGroupId } from '../generated/endpoint';
import { downloadExcel } from '../utils/download-excel-util';

export const useTeacherStudentNameMatrixDownload = ({
  groupId,
}: {
  groupId: number | undefined;
}) => {
  const [rows, setRows] = useState<Row[]>();
  const { data: excelData } = useStudentGroupsControllerDownloadStudentListByGroupId(
    groupId as number,
    {
      query: {
        enabled: !!groupId,
      },
    },
  );

  const download = () => {
    const filename = '명명표';
    excelData && downloadExcel(excelData, filename);
  };

  useEffect(() => {
    if (excelData) {
      new Promise((r) => r(excelData))
        .then((blob) => readXlsxFile(blob as Blob))
        .then((rows) => setRows(rows))
        .catch((e) => console.log(e));
    }
  }, [groupId, excelData]);

  return { downloadStudentNameMatrix: download, rows };
};
