import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useGroupsControllerCreate,
  useGroupsControllerUpdate,
  useStudentGroupsControllerFindByGroupId,
} from '../generated/endpoint';
import { Routes } from '../routes';
import { RequestCreateGroupDto, ResponseGroupDto, StudentGroup } from '../generated/model';
import { GroupContainer } from './group';

export const useTeacherGroupAdd = ({
  groupId,
  onSubmit,
}: {
  groupId?: number;
  onSubmit?: () => void;
}) => {
  const history = useHistory();
  const { allKlassGroups, refetchGroups } = GroupContainer.useContext();
  const [selectedGroup, setSelectedGroup] = useState<ResponseGroupDto | null>(
    allKlassGroups[0] || null,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [studentGroups, setStudentGroups] = useState<StudentGroup[]>([]);

  useStudentGroupsControllerFindByGroupId<StudentGroup[]>(selectedGroup?.id as number, {
    query: {
      enabled: !!selectedGroup?.id,
      onSuccess: (res) => {
        if (!res?.length) {
          setStudentGroups([]);
          return;
        }

        setStudentGroups(res.sort((a, b) => a.studentNumber - b.studentNumber));
      },
    },
  });

  const { mutate: createGroupMutate, isLoading: isCreateGroupMutate } = useGroupsControllerCreate({
    mutation: {
      onSuccess: (result) => {
        refetchGroups();
        onSubmit?.();
        history.push(`${Routes.teacher.groups}/${result.id}`);
      },
      onError: () => {
        setErrorMessage('그룹 등록에 실패했습니다.');
      },
    },
  });

  const { mutate: updateGroupMutate, isLoading: isUpdateGroupMutate } = useGroupsControllerUpdate({
    mutation: {
      onSuccess: () => {
        refetchGroups();
        onSubmit?.();
        history.push(`${Routes.teacher.groups}/${groupId}`);
      },
      onError: () => {
        setErrorMessage('그룹 수정에 실패했습니다.');
      },
    },
  });

  const handleSubmit = async (groupPayload: RequestCreateGroupDto) => {
    try {
      if (!groupPayload || !!errorMessage) {
        return;
      }

      if (!groupPayload.subject) {
        alert('과목명을 입력해주세요.');
        return;
      }

      if (groupId) {
        updateGroupMutate({
          id: groupId,
          data: groupPayload,
        });
      } else {
        createGroupMutate({
          data: groupPayload,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    errorMessage,
    isCreateOrUpdateLoading: isCreateGroupMutate || isUpdateGroupMutate,
    selectedGroup,
    setSelectedGroup,
    studentGroups,
    handleSubmit,
  };
};
