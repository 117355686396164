import { useState } from 'react';
import { useParams } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';

import { useRecoilState } from 'recoil';

import { NewsletterSubmitDetailPage } from './NewsletterSubmitDetailPage';
import { NewsletterSubmitterItem, Tab } from '../../../components';
import { useTeacherNewsletterSubmit } from '../../../container/teacher-newsletter-submit';
import { ResponseGroupDto, StudentGroup, StudentNewsletter } from '../../../generated/model';
import { newsletterOpenedGroupState } from '../../../store';

export const NewsletterSubmitPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [newsletterOpenedGroup, setNewsletterOpenedGroup] = useRecoilState(
    newsletterOpenedGroupState,
  );

  const {
    result,
    studentNewsletters,
    studentsCount,
    submitCount,
    unSubmitCount,
    selectKlassGroup,
    refetch,
  } = useTeacherNewsletterSubmit(+id);

  const selectedFilter = Number(localStorage.getItem('selectedFilter'));

  const [filter, setFilter] = useState(selectedFilter || 0);

  const handleSelectKlassGroup = (klassGroup: ResponseGroupDto) => {
    newsletterOpenedGroup.includes(klassGroup.name as string)
      ? setNewsletterOpenedGroup(newsletterOpenedGroup.filter((el) => el !== klassGroup.name))
      : setNewsletterOpenedGroup((prevState) => [...prevState, klassGroup.name as string]);

    selectKlassGroup(klassGroup.id);
    history.push(`/teacher/newsletter/submit/${id}`);
  };

  const handleNewsletterSubmitterItemClick = (
    studentGroup: StudentGroup,
    studentNewsletters: StudentNewsletter[] | undefined,
  ) => {
    if (!studentNewsletters || !studentNewsletters.length) {
      return;
    }
    const studentNewsletter = studentNewsletters.filter(
      (sn: StudentNewsletter) => sn.student?.id === studentGroup.user?.id,
    )[0];

    if (studentNewsletter) {
      history.push(`/teacher/newsletter/submit/${id}/${studentNewsletter.id}`);
    } else {
      history.push(`/teacher/newsletter/submit/${id}`);
    }
  };

  return (
    <div className="bg-white ml-0.5 grid grid-cols-7 h-screen">
      <div className="col-span-4">
        <div className="w-full flex items-center justify-between p-4">
          <div className="space-x-2 flex items-center">
            <h3 className="text-lg">제출자 리스트</h3>
            <div className="text-gray-500 text-sm">
              {filter === 2 ? `미제출 ${unSubmitCount}명` : `제출 ${submitCount}명`} / 총{' '}
              {studentsCount}명
            </div>
          </div>
          <div className="text-brand-1 cursor-pointer" onClick={() => refetch()}>
            새로고침
          </div>
        </div>
        <div className="h-0.5 bg-gray-100 "></div>
        <Tab
          filter={filter}
          setFilter={(n: number) => {
            localStorage.setItem('selectedFilter', n.toString());
            setFilter(n);
          }}
        />
        <div className="p-4 overflow-y-scroll h-screen-8">
          {result?.map((group) => (
            <div key={group.id} className="my-5">
              <div
                className="w-full border-t border-gray-600 cursor-pointer flex justify-between items-center"
                onClick={() => handleSelectKlassGroup(group)}
              >
                <div className="font-bold text-lg">{group.name}</div>
                <div className="text-gray-500 text-sm">
                  {filter === 2
                    ? `미제출 ${group.studentCount - group.submitCount}명`
                    : `제출 ${group.submitCount}명`}{' '}
                  / 총{group.studentCount || 0}명
                </div>
              </div>
              {newsletterOpenedGroup.includes(group.name as string) &&
                group.studentGroups?.length > 0 &&
                group.studentGroups.map((studentGroup, index) => (
                  <NewsletterSubmitterItem
                    key={index}
                    filter={filter}
                    studentGroup={studentGroup}
                    studentNewsletter={studentNewsletters?.find(
                      (studentNewsletter) =>
                        studentNewsletter.student?.id === studentGroup.user?.id,
                    )}
                    onClick={() =>
                      handleNewsletterSubmitterItemClick(studentGroup, studentNewsletters)
                    }
                    id={id}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-3">
        <Switch>
          <Route
            path={`/teacher/newsletter/submit/:id/:snid`}
            component={() => <NewsletterSubmitDetailPage />}
          />
        </Switch>
      </div>
    </div>
  );
};
