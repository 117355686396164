import { useRecoilValue } from 'recoil';

import { useStudentGroupsControllerFindKlassHistoryByStudent } from '../generated/endpoint';
import { meStateNew } from '../store';

export const useStudentKlassHistory = () => {
  const meRecoil = useRecoilValue(meStateNew);

  const { data: klassHistoryList } = useStudentGroupsControllerFindKlassHistoryByStudent({
    query: {
      enabled: !!meRecoil,
    },
  });

  return { klassHistoryList };
};
