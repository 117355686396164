import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { ErrorBlank } from '../../../components';
import { useStudentAbsent } from '../../../container/student-absent';
import { BackButton, Blank, Section, TopNavbar } from '../../../reusable';
import { makeStartEndToString } from '../../../utils';

export const AbsentPage: React.FC = () => {
  const history = useHistory();

  const { isLoading, data, me, error } = useStudentAbsent();

  const [blankOpen, setBlankOpen] = useState(false);
  const isNotParent = me?.role !== 'PARENT';

  const absents = data
    ?.slice()
    .sort(
      (a, b) =>
        (a.absentStatus === 'DELETE_APPEAL' ? -1 : 0) -
        (b.absentStatus === 'DELETE_APPEAL' ? -1 : 0),
    )
    .sort(
      (a, b) => (a.absentStatus === 'RETURNED' ? -1 : 0) - (b.absentStatus === 'RETURNED' ? -1 : 0),
    );

  return (
    <>
      {isLoading && <Blank reversed />}
      {error && <ErrorBlank />}
      {blankOpen && <Blank />}
      <TopNavbar
        title="출결신고서"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />

      <Section>
        <div className="pb-12 space-y-4">
          {absents?.map((absent: any) => {
            const isDeleteAppealed =
              absent?.absentStatus && absent.absentStatus === 'DELETE_APPEAL';
            const isReturned = absent?.absentStatus && absent.absentStatus === 'RETURNED';

            const isNotApprovedByParent =
              absent?.absentStatus && absent.absentStatus === 'BEFORE_PARENT_CONFIRM';

            const isNotApprovedByTeacher =
              absent?.absentStatus &&
              (absent.absentStatus === 'RETURNED' ||
                absent.absentStatus === 'BEFORE_PARENT_CONFIRM' ||
                absent.absentStatus === 'BEFORE_TEACHER_APPROVAL');

            const isNotApprovedBySchool =
              absent?.absentStatus && absent.absentStatus !== 'PROCESSED';

            return (
              <div
                className="flex justify-between items-center pb-4 border-b border-grey-9 cursor-pointer"
                onClick={() => history.push(`/student/absent/${absent.id}`)}
              >
                <div>
                  <div className="text-gray-800 font-bold">
                    {absent?.startAt &&
                      absent?.endAt &&
                      absent?.reportType &&
                      makeStartEndToString(absent.startAt, absent.endAt, absent.reportType)}{' '}
                    {absent.reportType}
                  </div>
                  <div className="text-gray-600 text-sm">
                    {absent.reportedAt}{' '}
                    {isReturned ? (
                      <span className="text-brand-1 font-bold">반려됨</span>
                    ) : isNotApprovedByParent ? (
                      '학부모 승인 대기'
                    ) : isNotApprovedByTeacher ? (
                      '선생님 승인 대기'
                    ) : isDeleteAppealed ? (
                      <span className="text-red-500 font-bold">삭제 요청</span>
                    ) : isNotApprovedBySchool ? (
                      '학교 승인 대기'
                    ) : (
                      '처리됨'
                    )}
                  </div>
                </div>
                <RightArrow />
              </div>
            );
          })}
          {!absents?.length && (
            <div className="w-full h-screen-10 text-center flex items-center justify-center">
              <div className="text-gray-600">
                아직 출결 신고서 리스트가 없습니다. <br />
                아래 버튼을 눌러 신청해주세요.
              </div>
            </div>
          )}
        </div>
      </Section>
      {isNotParent && (
        <div className="bottom-16 fixed z-10 w-full px-4">
          <button
            className="bg-brand-1 text-white rounded-lg h-14 px-4 w-full"
            onClick={() => history.push('/student/absent/add')}
          >
            출결신고서 작성하기
          </button>
        </div>
      )}
    </>
  );
};
