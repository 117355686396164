import React, { FC } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import GroupDetailPage from './GroupDetailPage';
import { ErrorBlank } from '../../../components';
import { Divider } from '../../../reusable';
import { GroupContainer } from '../../../container/group';
import { GroupAddPage } from './GroupAddPage';

interface GroupPageProps {}

export const GroupPage: FC<GroupPageProps> = () => {
  const history = useHistory();
  const { teacherKlubGroups, errorGroups } = GroupContainer.useContext();

  return (
    <div className="col-span-7 grid grid-cols-7">
      {errorGroups && <ErrorBlank />}
      <div className="col-span-4 h-screen hidden md:block">
        <div className="px-6 py-6">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">그룹 만들기</h1>
            <Link to="/teacher/groups/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          </div>
        </div>
        <Divider />
        <div className="h-screen-6 w-full overflow-y-auto scroll-box">
          <div className="w-full grid grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 pr-4 2xl:gap-6 2xl:p-8 2xl:pr-16">
            {teacherKlubGroups.map((group) => (
              <div
                key={group.id}
                className="w-full border-2 border-grey-6 p-3 m-2 rounded-lg cursor-pointer"
                onClick={() => history.push(`/teacher/groups/${group.id}`)}
              >
                <div className="w-full font-semibold overflow-hidden whitespace-pre">
                  {group.name}
                </div>
                <div className="font-base">({group.studentCount}명)</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-3 bg-gray-50 scroll-box">
        <Switch>
          <Route path="/teacher/groups/add" component={() => <GroupAddPage />} />
          <Route path="/teacher/groups/:id" component={() => <GroupDetailPage />} />
        </Switch>
      </div>
    </div>
  );
};
