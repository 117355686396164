import { ChangeEvent, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import AbsentDetailPage from './AbsentDetailPage';
import { ReactComponent as AscImg } from '../../../assets/svg/icon-chevron-down.svg';
import { ReactComponent as DescImg } from '../../../assets/svg/icon-chevron-up.svg';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import {
  AbsentCard,
  AbsentsDownloadView,
  AbsentsExcelDownloadView,
  AttendeeInfoDownloadView,
  ErrorBlank,
  FrontPagination,
  SelectMenus,
  SuperModal,
} from '../../../components';
import { GroupContainer } from '../../../container/group';
import { useTeacherAbsent } from '../../../container/teacher-absent';
import { UserContainer } from '../../../container/user';
import { BackButton, Blank, Button, Section, TextField, TopNavbar } from '../../../reusable';
import { isValidDate, makeDateToString } from '../../../utils';

export const AbsentPage = () => {
  const history = useHistory();
  const { me } = UserContainer.useContext();
  const approvalLine = me?.school.approvalLine;

  const { allKlassGroups: groups } = GroupContainer.useContext();

  const [agreeAll, setAgreeAll] = useState(false);
  const [_studentName, set_studentName] = useState('');

  const {
    absents,
    isLoading,
    error,
    filters,
    sortNice,
    setSortNice,
    sortApproval,
    setSortApproval,
    sortSubmit,
    setSortSubmit,
    state: { startDate, endDate, filter, selectedGroup, page, isCsvData, open, limit },
    setState: {
      setStartDate,
      setEndDate,
      setFilter,
      setSelectedGroup,
      setPage,
      setAbsentId,
      setCsvData,
      setOpen,
    },
    sign: { sigPadData, clearSignature, canvasRef },
    stamp: { stampImgUrl, stampMode, setStampMode, updateStamp },

    approveAbsents,
    approveAbsent,
    submitAbsent,
    submitNiceAbsent,
    refetch,
  } = useTeacherAbsent();

  const preSignedStamp = stampImgUrl;

  const { pathname } = useLocation();
  const isDetail = !pathname.endsWith('/teacher/absent');

  const handleStampSetting = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;
    console.log('handleStampSetting - e.target.files: ', e.target.files[0]);
    updateStamp(e.target.files[0]);
    setStampMode(true);
  };

  return (
    <>
      {error && <ErrorBlank />}
      {isLoading && <Blank reversed />}

      {/* Mobile V */}
      <div className={`md:hidden ${isDetail ? 'hidden' : 'block'}`}>
        <TopNavbar title="출결 관리" left={<BackButton />} />
        <div className="px-6 py-6 overflow-x-scroll scroll-box flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <div
              className="text-lg text-brand-1 cursor-pointer"
              onClick={() => {
                refetch();
              }}
            >
              새로고침
            </div>
          </div>
          <div className="flex space-x-3 mb-4 items-center">
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="font-bold text-xs">~</div>
            <TextField
              className="cursor-pointer"
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          {absents && (me?.role === 'PRINCIPAL' || me?.role === 'SECURITY') && (
            <div className="flex space-x-3 items-center">
              <div className="w-full cursor-pointer">
                <SelectMenus
                  allText="모두"
                  items={filters}
                  value={filters ? filter : null}
                  onChange={(group) => setFilter(group)}
                />
              </div>

              <div className="w-full cursor-pointer">
                <SelectMenus
                  allText="반"
                  items={groups}
                  value={selectedGroup}
                  onChange={(group) => setSelectedGroup(group)}
                />
              </div>
            </div>
          )}
          <div className="flex space-x-3 items-center">
            {absents && me?.role !== 'PRINCIPAL' && me?.role !== 'SECURITY' && (
              <div className="min-w-min cursor-pointer">
                <SelectMenus
                  allText="모두"
                  items={filters}
                  value={filters ? filter : null}
                  onChange={(group) => setFilter(group)}
                />
              </div>
            )}

            <div className="flex items-center space-x-2 w-full">
              <form className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/absent`);
                    }
                    setPage(1);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (_studentName) {
                        history.replace(`/teacher/absent?username=${_studentName}`);
                      }
                    }
                  }}
                />
              </form>

              {/* 돋보기 아이콘 - 검색 버튼 */}
              <UploadIcon
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    history.replace(`/teacher/absent?username=${_studentName}`);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <table className="w-full  table-fixed">
          <tr className="text-center bg-gray-100 ">
            <td colSpan={8}>세부 내용</td>
            <td colSpan={3}>승인 절차</td>
            <td colSpan={2} align="center">
              <button
                className="min-w-max flex flex-row justify-center cursor-pointer"
                onClick={() => {
                  const sort = sortNice === 'DESC' ? 'ASC' : sortNice === 'ASC' ? '' : 'DESC';
                  setSortNice(sort);
                }}
              >
                나이스
                {sortNice === 'DESC' ? <DescImg /> : sortNice === 'ASC' ? <AscImg /> : <></>}
              </button>
            </td>
            <td colSpan={3} align="center">
              <button
                className="min-w-max  flex flex-row justify-center cursor-pointer"
                onClick={() => {
                  const sort = sortSubmit === 'DESC' ? 'ASC' : sortSubmit === 'ASC' ? '' : 'DESC';
                  setSortSubmit(sort);
                }}
              >
                증빙 서류
                {sortSubmit === 'DESC' ? <DescImg /> : sortSubmit === 'ASC' ? <AscImg /> : <></>}
              </button>
            </td>
          </tr>
        </table>

        <div className="h-screen-22 overflow-y-auto scroll-box">
          {absents?.items.map((absent, index) => (
            <AbsentCard
              key={index}
              userRole={me?.role}
              absent={absent}
              submitAbsent={submitAbsent}
              submitNiceAbsent={submitNiceAbsent}
              page={page}
              limit={limit}
            />
          ))}
        </div>
        {absents && absents?.total > limit && (
          <div className="grid place-items-center min-w-max">
            <FrontPagination
              basePath="/teacher/absent"
              total={absents?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div
        className={`bg-gray-50 block md:hidden ${
          isDetail ? 'h-full' : 'col-span-3 p-6'
        } scroll-box`}
      >
        <Switch>
          <Route
            path={`/teacher/absent/:id`}
            component={() => (
              <AbsentDetailPage
                userRole={me?.role}
                refetch={() => refetch()}
                setOpen={(b: boolean) => setOpen(b)}
                setAbsentId={(n: number) => setAbsentId(n)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                approvalLine={approvalLine}
              />
            )}
          />
        </Switch>
      </div>

      {/* Desktop V */}
      <div className="col-span-3 h-screen overflow-y-auto hidden md:block">
        <div className="px-6 py-6 overflow-x-scroll scroll-box flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold">출결 신고서</h1>
            <div
              className="text-lg text-brand-1 cursor-pointer"
              onClick={async () => {
                await refetch();
              }}
            >
              새로고침
            </div>
          </div>
          <div className="flex space-x-3 mb-4 items-center">
            <TextField
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
              }}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              type="date"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className="flex space-x-3 items-center">
            <div className="min-w-min cursor-pointer">
              <SelectMenus
                allText="모두"
                items={filters}
                value={filters ? filter : null}
                onChange={(group) => setFilter(group)}
              />
            </div>

            {absents && (me?.role === 'PRINCIPAL' || me?.role === 'SECURITY') && (
              <>
                <div className="min-w-max cursor-pointer">
                  <SelectMenus
                    allText="반"
                    items={groups}
                    value={selectedGroup}
                    onChange={(group) => setSelectedGroup(group)}
                  />
                </div>
              </>
            )}
            <div className="flex items-center space-x-2 w-full cursor-pointer">
              <form
                className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  className="w-full"
                  placeholder="학생이름을 입력해주세요."
                  value={_studentName}
                  onChange={(e) => {
                    set_studentName(e.target.value);
                    if (e.target.value === '') {
                      history.replace(`/teacher/absent`);
                    }
                    setPage(1);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (_studentName) {
                        history.replace(`/teacher/absent?username=${_studentName}`);
                      }
                    }
                  }}
                />
              </form>
              <UploadIcon
                onClick={() => {
                  if (_studentName === '') {
                    alert('텍스트 내용을 입력해주세요.');
                  } else {
                    history.replace(`/teacher/absent?username=${_studentName}`);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex space-x-3 items-end w-full">
            <div className="w-full">
              {/* <div className="w-full text-center text-gray-500">
                결석계 일괄 다운로드
              </div> */}

              <div className="flex space-x-3 items-center w-full">
                {/* PDF 버튼 */}
                <AbsentsDownloadView
                  nowDate={`${makeDateToString(new Date(startDate))}~${makeDateToString(
                    new Date(endDate),
                  )}`}
                  absents={absents?.items
                    ?.slice()
                    .filter((absent) => absent?.absentStatus === 'PROCESSED')
                    .sort(
                      (a, b) =>
                        new Date(a?.startAt || '').getTime() - new Date(b?.startAt || '').getTime(),
                    )}
                  setCsvData={(b: boolean) => setCsvData(b)}
                  isCsvData={isCsvData}
                  approvalLine={approvalLine}
                />
                {/* Excel 버튼 */}
                <AbsentsExcelDownloadView startDate={startDate} endDate={endDate} />
              </div>
            </div>

            <div className="flex space-x-3 items-center w-full">
              {/* 학급별출결현황 버튼 */}
              <AttendeeInfoDownloadView startDate={startDate} endDate={endDate} />
              <Button
                text="일괄 승인하기"
                tw={{
                  minWidth: 'min-w-max',
                  paddingY: 'py-2.5',
                  height: 'h-auto',
                  paddingX: 'px-4',
                  width: 'w-full',
                }}
                onClick={() => {
                  setOpen(true);
                  setAgreeAll(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <table className="w-full  table-fixed">
          <tr className="text-center bg-gray-100">
            <td colSpan={8}>세부 내용</td>
            <td colSpan={3}>
              <button
                className="min-w-max flex flex-row justify-center cursor-pointer"
                onClick={() => {
                  const sort =
                    sortApproval === 'DESC' ? 'ASC' : sortApproval === 'ASC' ? '' : 'DESC';
                  setSortApproval(sort);
                }}
              >
                승인 절차
                {sortApproval === 'DESC' ? (
                  <DescImg />
                ) : sortApproval === 'ASC' ? (
                  <AscImg />
                ) : (
                  <></>
                )}
              </button>
            </td>
            <td colSpan={2} align="center">
              <button
                className="min-w-max flex flex-row justify-center cursor-pointer"
                onClick={() => {
                  const sort = sortNice === 'DESC' ? 'ASC' : sortNice === 'ASC' ? '' : 'DESC';
                  setSortNice(sort);
                }}
              >
                나이스
                {sortNice === 'DESC' ? <DescImg /> : sortNice === 'ASC' ? <AscImg /> : <></>}
              </button>
            </td>
            <td colSpan={3} align="center">
              <button
                className="min-w-max  flex flex-row justify-center cursor-pointer"
                onClick={() => {
                  const sort = sortSubmit === 'DESC' ? 'ASC' : sortSubmit === 'ASC' ? '' : 'DESC';
                  setSortSubmit(sort);
                }}
              >
                증빙 서류
                {sortSubmit === 'DESC' ? <DescImg /> : sortSubmit === 'ASC' ? <AscImg /> : <></>}
              </button>
            </td>
          </tr>
        </table>

        <div className="h-screen-20 overflow-y-auto scroll-box">
          {absents?.items.map((absent) => (
            <AbsentCard
              key={absent.id}
              userRole={me?.role}
              absent={absent}
              submitAbsent={submitAbsent}
              submitNiceAbsent={submitNiceAbsent}
              page={page}
              limit={limit}
            />
          ))}
        </div>
        {absents && absents?.total > limit && (
          <div className="grid place-items-center">
            <FrontPagination
              basePath="/teacher/absent"
              total={absents?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div className="hidden md:block col-span-3 bg-gray-50 p-6 overflow-y-auto scroll-box">
        <Switch>
          <Route
            path={`/teacher/absent/:id`}
            component={() => (
              <AbsentDetailPage
                userRole={me?.role}
                refetch={() => refetch()}
                setOpen={(b: boolean) => setOpen(b)}
                setAbsentId={(n: number) => setAbsentId(n)}
                setAgreeAll={(b: boolean) => setAgreeAll(b)}
                approvalLine={approvalLine}
              />
            )}
          />
        </Switch>
      </div>
      <SuperModal
        modalOpen={open}
        setModalClose={() => {
          clearSignature();
          setOpen(false);
        }}
        width="w-max"
        ablePropragation
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div>
            <div className="text-gray-700 text-xl font-bold">서명란</div>
            <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
          </div>
          <div className="relative">
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            {stampMode ? (
              preSignedStamp ? (
                <div
                  className="absolute inset-0 z-10 rounded overflow-hidden bg-center bg-no-repeat bg-contain"
                  style={{ backgroundImage: `url("${preSignedStamp}")` }}
                ></div>
              ) : (
                <div className="absolute inset-0 z-10 rounded overflow-hidden bg-grey-4">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="min-w-max text-center">도장을 등록해주세요.</div>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <label htmlFor="stamp-upload" className="w-1/2">
              <div className="w-full rounded-lg font-bold h-13 px-6 justify-center flex items-center bg-white border border-brandblue-1 text-current cursor-pointer">
                도장등록
              </div>
            </label>
            <input
              id="stamp-upload"
              name="stamp-upload"
              type="file"
              className="sr-only"
              accept=".pdf, .png, .jpeg, .jpg"
              // onChange={(e) => {
              //   if (!e.target?.files) return;
              //   updateStamp(e.target.files[0]);
              //   setStampMode(true);
              // }}
              onChange={(e) => handleStampSetting(e)}
            />
            {!stampMode ? (
              <Button
                tw={{
                  width: 'w-1/2',
                  borderWidth: 'border',
                  borderColor: 'border-brandblue-1',
                  backgroundColor: 'bg-white',
                  color: 'text-brandblue-1',
                  minWidth: 'min-w-max',
                }}
                onClick={() => {
                  setStampMode(true);
                  clearSignature();
                }}
              >
                도장 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-1/2',
                  minWidth: 'min-w-max',
                  backgroundColor: preSignedStamp ? 'bg-brandblue-1' : 'bg-brandblue-5',
                }}
                onClick={async () => {
                  if (!preSignedStamp) {
                    alert('도장이 등록되어 있지 않습니다.');
                  } else {
                    if (agreeAll) {
                      await approveAbsents();
                    } else {
                      await approveAbsent();
                    }
                    await setStampMode(false);
                  }
                }}
              >
                도장으로 승인
              </Button>
            )}
          </div>
          <div className="flex items-center justify-center space-x-2">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                borderWidth: 'border',
                borderColor: 'border-brand-1',
                color: 'text-current',
                width: 'w-full',
                minWidth: 'min-w-max',
              }}
              onClick={() => clearSignature()}
            >
              사인 다시하기
            </Button>
            {stampMode ? (
              <Button
                tw={{
                  width: 'w-full',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  backgroundColor: 'bg-white',
                  color: 'text-brand-1',
                  minWidth: 'min-w-max',
                }}
                onClick={() => setStampMode(false)}
              >
                사인 사용하기
              </Button>
            ) : (
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: sigPadData ? 'bg-brand-1' : 'bg-brand-5',
                }}
                onClick={() => {
                  if (!sigPadData) {
                    alert('사인 후 승인해 주세요.');
                  } else {
                    agreeAll ? approveAbsents() : approveAbsent();
                  }
                }}
              >
                사인으로 승인
              </Button>
            )}
          </div>
        </Section>
      </SuperModal>
    </>
  );
};
