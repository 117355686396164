import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OutingUpdatePage } from './OutingUpdatePage';
import { SuperModal } from '../../../components';
import { Constants } from '../../../constants';
import { useTeacherOutingDetail } from '../../../container/teacher-outing-detail';
import { BackButton, Blank, Button, Section, TextArea, TopNavbar } from '../../../reusable';
import { makeDateToString, makeStartEndToStringType2, makeTimeToString } from '../../../utils';

interface MatchParams {
  id: string;
}
interface MatchParams {
  id: string;
}

interface OutingDetailPageProps extends RouteComponentProps<MatchParams> {
  outings: any;
  userRole: string | undefined;
  setOpen: (b: boolean) => void;
  setOutingId: (n: number) => void;
  setAgreeAll: (b: boolean) => void;
  refetch: () => void;
}

const OutingDetailPage: React.FC<OutingDetailPageProps> = ({
  match,
  userRole,
  outings,
  setOpen,
  setOutingId,
  setAgreeAll,
  refetch,
}) => {
  const { id } = match.params;

  const [changeMode, setChangeMode] = useState(false);
  const outing: any = outings?.items?.filter((el: any) => el.id === Number(id))[0];

  const {
    deny,
    setDeny,
    notApprovedReason,
    setNotApprovedReason,
    deleteReason,
    setDeleteReason,
    deleteAppeal,
    setDeleteAppeal,
    errM,
    requestDeleteOuting,
    denyOuting,
    isLoading,
  } = useTeacherOutingDetail(Number(id), refetch);

  useEffect(() => {
    setOutingId(Number(id));
  }, [id, setOutingId]);

  if (!outing) {
    return <div className="bg-white border rounded-lg p-5 h-screen-14"></div>;
  }

  const isConfirmed = outing?.outingStatus === 'PROCESSED';

  if (changeMode) {
    return (
      <OutingUpdatePage
        outingData={outing}
        refetch={() => refetch()}
        isConfirmed={isConfirmed}
        setChangeMode={(b: boolean) => setChangeMode(b)}
      />
    );
  }

  const updatedAt = new Date(outing?.updatedAt?.split('.')[0] || '');

  return (
    <>
      {/* Mobile V */}
      <TopNavbar title="상세 보기" left={<BackButton />} />

      <div className="bg-white border rounded-lg p-5 h-full bg-scroll block md:hidden">
        {isLoading && <Blank reversed />}
        <Section>
          {outing?.updateReason && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1">{outing?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(updatedAt)} {makeTimeToString(updatedAt)}에 마지막으로 수정
              </div>
            </div>
          )}
          {outing?.outingStatus === 'RETURNED' && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1 text-sm">{outing?.notApprovedReason}</div>
              <div className="text-red-500">반려 이유</div>
            </div>
          )}

          <div className="w-full text-center font-bold text-3xl min-w-max">
            {outing?.type ? outing.type + '증' : ''}
          </div>
          <div className="text-xl font-bold">
            [{outing?.type}] {outing?.student?.name} {outing?.studentGradeKlass}{' '}
            {outing?.studentNumber}번
          </div>

          <table className="w-full">
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                이름
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.student?.name}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                학번
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.studentGradeKlass} {outing?.studentNumber}번
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                유형
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.type}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                사유
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.reason}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                일시
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.startAt &&
                  outing?.endAt &&
                  makeStartEndToStringType2(outing.startAt, outing.endAt)}
              </td>
            </tr>
          </table>

          <div className="my-6">
            <div className="w-full flex flex-col items-end">
              {outing?.teacherSignature && (
                <>
                  <div className="min-w-max font-bold mt-4 text-right">
                    담임: {outing?.teacher?.name} (인)
                  </div>
                  {outing.teacherSignature.startsWith('data:image') ? (
                    <img
                      style={{ width: '100px', marginTop: '0.5rem' }}
                      src={outing?.teacherSignature}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: '100px', marginTop: '0.5rem' }}
                      src={`${Constants.imageUrl}${outing?.teacherSignature}`}
                      alt=""
                    />
                  )}
                </>
              )}
            </div>
            {userRole !== 'SECURITY' && (
              <>
                <Button
                  tw={{
                    width: 'w-full',
                    minWidth: 'min-w-max',
                    paddingY: 'py-3',
                    marginBottom: 'mb-2',
                    height: 'h-auto',

                    backgroundColor:
                      outing?.outingStatus === 'PROCESSED' ? 'bg-gray-500' : 'bg-brand-1',
                  }}
                  disabled={outing?.outingStatus === 'PROCESSED'}
                  text={outing?.outingStatus === 'PROCESSED' ? '승인 완료' : '승인하기'}
                  onClick={() => {
                    setOpen(true);
                    setAgreeAll(false);
                  }}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    minWidth: 'min-w-max',
                    paddingY: 'py-3',
                    height: 'h-auto',
                    backgroundColor:
                      outing?.outingStatus === 'RETURNED' ? 'bg-gray-500' : 'bg-blue-500',
                  }}
                  disabled={outing?.outingStatus === 'RETURNED'}
                  text={outing?.outingStatus === 'RETURNED' ? '반려됨' : '반려하기'}
                  onClick={() => setDeny(true)}
                />
              </>
            )}
          </div>
        </Section>
        <SuperModal modalOpen={deny} setModalClose={() => setDeny(false)} width="w-max">
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 조퇴/외출/확인증을 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                denyOuting();
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>
      </div>

      {/* Desktop V */}
      <div className="bg-white border rounded-lg p-5 h-screen-14 hidden md:block">
        {isLoading && <Blank reversed />}
        <Section>
          {outing?.updateReason && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1">{outing?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(updatedAt)} {makeTimeToString(updatedAt)}에 마지막으로 수정
              </div>
            </div>
          )}
          {outing?.outingStatus === 'RETURNED' && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1 text-sm">{outing?.notApprovedReason}</div>
              <div className="text-red-500">반려 이유</div>
            </div>
          )}

          <div className="w-full text-center font-bold text-3xl min-w-max">
            {outing?.type ? outing.type + '증' : ''}
          </div>
          <div className="text-xl font-bold">
            [{outing?.type}] {outing?.student?.name} {outing?.studentGradeKlass}{' '}
            {outing?.studentNumber}번
          </div>

          <table>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                이름
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.student?.name}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                학번
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.studentGradeKlass} {outing?.studentNumber}번
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                유형
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.type}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                사유
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.reason}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-900 p-2 w-1/3 text-gray-800 text-center font-bold">
                일시
              </td>
              <td className="border border-gray-900 p-2 w-2/3 text-gray-500 text-center font-bold">
                {outing?.startAt &&
                  outing?.endAt &&
                  makeStartEndToStringType2(outing.startAt, outing.endAt)}
              </td>
            </tr>
          </table>

          <div className="my-6">
            <div className="w-full flex flex-col items-end">
              {outing?.teacherSignature && (
                <>
                  <div className="min-w-max font-bold mt-4 text-right">
                    담임: {outing?.teacher?.name} (인)
                  </div>
                  {outing.teacherSignature.startsWith('data:image') ? (
                    <img
                      style={{ width: '100px', marginTop: '0.5rem' }}
                      src={outing?.teacherSignature}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: '100px', marginTop: '0.5rem' }}
                      src={`${Constants.imageUrl}${outing?.teacherSignature}`}
                      alt=""
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {errM && <div className="text-red-500">{errM}</div>}

          <div className="pt-4 flex items-center space-x-2">
            {userRole !== 'SECURITY' && (
              <>
                <Button
                  tw={{
                    width: 'w-full',
                    minWidth: 'min-w-max',
                    backgroundColor: 'bg-red-500',
                  }}
                  text="삭제요청"
                  onClick={() => {
                    setDeleteAppeal(true);
                  }}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    backgroundColor:
                      outing?.outingStatus === 'RETURNED' ? 'bg-gray-500' : 'bg-blue-500',
                  }}
                  disabled={outing?.outingStatus === 'RETURNED'}
                  text={outing?.outingStatus === 'RETURNED' ? '반려됨' : '반려하기'}
                  onClick={() => setDeny(true)}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    backgroundColor: 'bg-yellow-500',
                  }}
                  text={isConfirmed ? '승인 후 수정' : '수정하기'}
                  onClick={() => setChangeMode(true)}
                />
                <Button
                  tw={{
                    width: 'w-full',
                    backgroundColor:
                      outing?.outingStatus === 'PROCESSED' ? 'bg-gray-500' : 'bg-brand-1',
                  }}
                  disabled={outing?.outingStatus === 'PROCESSED'}
                  text={outing?.outingStatus === 'PROCESSED' ? '승인 완료' : '승인'}
                  onClick={() => {
                    setOpen(true);
                    setAgreeAll(false);
                  }}
                />
              </>
            )}
          </div>
        </Section>
        <SuperModal modalOpen={deny} setModalClose={() => setDeny(false)} width="w-max">
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 조퇴/외출/확인증을 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                denyOuting();
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>
        <SuperModal
          modalOpen={deleteAppeal}
          setModalClose={() => setDeleteAppeal(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 조퇴/외출/확인증을 삭제하도록 요청하시겠습니까?
            </div>
            <TextArea
              placeholder="삭제 이유"
              onChange={(e) => setDeleteReason(e.target.value)}
              value={deleteReason}
            />
            <Button
              tw={{ backgroundColor: 'bg-red-500' }}
              onClick={() => {
                requestDeleteOuting();
              }}
            >
              삭제 요청하기
            </Button>
          </Section>
        </SuperModal>
      </div>
    </>
  );
};

export default withRouter(OutingDetailPage);
