import { NewsletterTeacherItem } from '../../../components';
import { useTeacherNewsletter } from '../../../container/teacher-newsletter';
import { Newsletter } from '../../../generated/model';
import { List } from '../../../reusable';

export const NewsletterMobilePage = () => {
  const { newsletters } = useTeacherNewsletter();
  return (
    <>
      <div className="w-full flex-col space-y-2 h-screen-9 overflow-y-auto scroll-box">
        <div className="whitespace-pre-line">
          <List>
            {newsletters?.map((newsletter: Newsletter) => (
              <NewsletterTeacherItem key={newsletter.id} newsletter={newsletter} />
            ))}
          </List>
        </div>
        <div className="w-full h-32" />
      </div>
    </>
  );
};
