import React, { useState, FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface StudentItemProps {
  studentid: number;
  studentname: string;
  klass: string;
  klassnum: string;
}

export const StudentItem: FC<StudentItemProps> = ({
  studentid,
  studentname,
  klass,
  klassnum,
}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const [clicked, setClicked] = useState(false);

  return (
    <div
      className={
        pathname.startsWith(`/teacher/studentcard/${studentid}`)
          ? 'w-full py-4 cursor-pointer border-b border-gray-100 bg-gray-100'
          : 'w-full py-4 cursor-pointer border-b border-gray-100'
      }
      onClick={() => history.push(`/teacher/studentcard/${studentid}`)}
    >
      <table className="w-full table-fixed">
        <tr>
          <td className="text-lg font-semibold pl-4">
            {studentname} {klass} {klassnum}
          </td>
        </tr>
      </table>
    </div>
  );
};
