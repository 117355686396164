import { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ErrorBlank, Td } from '../../../components';
import { useParentFieldtripApprove } from '../../../container/parent-fieldtrip-approve';
import { FieldtripType } from '../../../generated/model';
import { useSignature } from '../../../hooks/useSignature';
import {
  Blank,
  BottomFixedView,
  Button,
  Checkbox,
  CloseButton,
  Section,
  TopNavbar,
} from '../../../reusable';
import { makeStartEndToString } from '../../../utils';

interface MatchParams {
  uuid: string;
}

export const FieldtripApprovalPage: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { uuid } = match.params;

  const { clearSignature, canvasRef, sigPadData } = useSignature();
  const {
    hideSignModal,
    openSignModal,
    isShowSignModal,
    isSuccess,
    isLoading,
    errorMessage,
    approveFieldtrip,
    fieldtrip,
    fieldtripError,
  } = useParentFieldtripApprove({ sigPadData, uuid });
  const [agree, setAgree] = useState(false);

  const student = fieldtrip?.student;
  const school = student?.school;
  const type = fieldtrip?.type;

  const isSigned = () => {
    return fieldtrip?.parentSignature;
  };

  const getContent = () => {
    // content가 string이거나 Array<Object>인 경우가 있어서 분기
    if (type === FieldtripType.HOME) {
      return fieldtrip?.content ? JSON.parse(fieldtrip.content) : [];
    }

    return fieldtrip?.content;
  };

  return (
    <>
      {isLoading && <Blank />}
      {isSuccess && (
        <Blank>
          정상적으로 제출되었습니다.
          <br /> 감사합니다!
        </Blank>
      )}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {fieldtripError && (
        <ErrorBlank
          text={
            fieldtripError.message?.includes('Could not find any entity')
              ? '해당하는 체험학습 신청서를 찾을 수 없습니다.'
              : fieldtripError.message
          }
        />
      )}
      <TopNavbar title="체험학습 신청서" left={<div className="w-10 h-15" />} />
      <Section>
        {!isSigned() ? (
          <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
            {`[${school?.name || ''} 슈퍼스쿨의 서명 요청]
      ${student?.nokName || ''}님, 귀하의 자녀 ${student?.name || ''} 학생이
      체험학습 신청서를 신청하였습니다.
      내용확인 후 서명을 부탁드립니다.

      서명 요청자 :
      ${school?.name || ''} ${fieldtrip?.studentGradeKlass || ''} ${student?.name || ''} 학생

      서명 참여자 :
      ${student?.nokName || ''} 학부모님 (${student?.nokPhone || ''})
      `}
          </div>
        ) : (
          <>
            <div>
              <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
                {`${student?.name || ''} 학생의 체험학습 신청서가 ${
                  student?.nokName || ''
                }님의 서명을 받았습니다.
              체험학습 신청서의 처리 상태를 밑에서 확인 가능합니다.`}
              </div>
            </div>
            <div className="h-0.5 bg-gray-200" />
          </>
        )}
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">학생 이름</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{student?.name}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">학번</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.studentGradeKlass}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">신고종류</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">
            {fieldtrip?.type === 'HOME' ? '가정' : '교외'}
          </div>
        </div>
        {type === 'SUBURBS' && (
          <div className="flex">
            <div className="w-1/3 text-gray-800 flex-shrink-0">체험학습 종류</div>
            <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.form}</div>
          </div>
        )}
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">기간</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">
            {fieldtrip && makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">총 기간</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.usedDays}일</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">목적지</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.destination}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">목적</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.purpose}</div>
        </div>
        {type === 'SUBURBS' && (
          <>
            <div className="flex">
              <div className="w-1/3 text-gray-800 flex-shrink-0">인솔자명</div>
              <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.guideName}</div>
            </div>
            <div className="flex">
              <div className="w-1/3 text-gray-800 flex-shrink-0">관계</div>
              <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.relationship}</div>
            </div>
            <div className="flex">
              <div className="w-1/3 text-gray-800 flex-shrink-0">인솔자 연락처</div>
              <div className="w-2/3 text-gray-800 flex-shrink-0">{fieldtrip?.guidePhone}</div>
            </div>
            <div>
              <div className="text-base">*현장학습계획</div>
              <div className="whitespace-pre-line border border-black w-full p-3">
                {fieldtrip?.content}
              </div>
            </div>
          </>
        )}
        {type === 'HOME' && (
          <>
            {getContent()?.map((el: any, i: number) => (
              <table className="min-w-max inline-block">
                <>
                  <tr>
                    <Td
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#C4C4C4',
                      }}
                    >
                      {i + 1}일차
                    </Td>
                  </tr>
                  <tr>
                    <Td innerClassName="min-w-max">교시</Td>
                    <Td innerClassName="min-w-max">교과</Td>
                    <Td innerClassName="min-w-max">학습할 내용</Td>
                  </tr>
                  {new Array(7).fill('').map((dlldl: any, index: number) => (
                    <>
                      {el['subject' + (index + 1)] && (
                        <tr>
                          <Td>{index + 1}교시</Td>
                          <Td>{el['subject' + (index + 1)]}</Td>
                          <Td>{el['content' + (index + 1)]}</Td>
                        </tr>
                      )}
                    </>
                  ))}
                </>
              </table>
            ))}
          </>
        )}
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">보호자명</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{student?.nokName}</div>
        </div>
        <div className="flex">
          <div className="w-1/3 text-gray-800 flex-shrink-0">보호자 연락처</div>
          <div className="w-2/3 text-gray-800 flex-shrink-0">{student?.nokPhone}</div>
        </div>
        <div>
          <div className="flex items-center space-x-2 mb-1">
            <Checkbox id="agree" onChange={() => setAgree((prev) => !prev)} checked={agree} />
            <label htmlFor="agree">
              <span className="font-semibold text-lg cursor-pointer">
                아래 내용을 체크하셨습니까?
              </span>
            </label>
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            -보호자로 서명합니다.
          </div>
        </div>
        {isSigned() ? (
          <>
            <div className="text-gray-500 text-sm">승인 요청되었습니다.</div>
            <Button
              tw={{
                width: 'w-full',
                backgroundColor: 'bg-gray-500',
              }}
              disabled={!agree}
            >
              사인 다시하기
            </Button>
          </>
        ) : (
          <Button tw={{ width: 'w-full' }} onClick={openSignModal} disabled={!agree}>
            내용 확인하고 서명하기
          </Button>
        )}
      </Section>

      <div className={isShowSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div className="absolute top-2 right-3">
            <CloseButton
              onClick={() => {
                hideSignModal();
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학부모 서명란</div>
              <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button
                tw={{ width: 'w-full' }}
                onClick={() => {
                  approveFieldtrip();
                }}
                disabled={isLoading || !sigPadData || !agree}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
        </BottomFixedView>
      </div>
    </>
  );
};
