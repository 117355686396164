import { TextField } from '../../../reusable';
import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ManagerScheduleCard } from '../../../components';
import { makeDateToString, weekAgo } from '../../../utils';
import { ManagerScheduleDetailPage } from './ManagerScheduleDetailPage';

let manager = [
  {
    id: 1,
  },

  {
    id: 2,
  },
  {
    id: 3,
  },
];

export const ManagerScheduleMainPage: React.FC = () => {
  const [startDate, setStartDate] = useState(
    makeDateToString(weekAgo(new Date())),
  );
  const userRole = 'teacher';

  return (
    <>
      <div className="col-span-3 h-screen hidden md:block">
        <div className=" px-6 py-6">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">학사일정 관리</h1>
          </div>
          <div className="flex space-x-3 my-4 items-center">
            <TextField
              type="date"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <h1 className="text-2xl font-semibold">2021.10.01(금)</h1>
        </div>
        <div className="h-screen-5 overflow-y-auto scroll-box">
          {manager?.map((manager: any) => (
            <ManagerScheduleCard userRole={userRole} manager={manager} />
          ))}
        </div>
      </div>
      <div className="col-span-3 bg-gray-50 p-6 scroll-box">
        <Switch>
          {/* <Route path="/teacher/fieldtrip/add" component={AddNoticePage} /> */}
          <Route
            path="/teacher/manager/:id"
            component={ManagerScheduleDetailPage}
          />
        </Switch>
      </div>
    </>
  );
};
