import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import {
  SelectMenus,
  SuperModal,
  TimetableAtdCard,
  TimetableStudentRole,
} from '../../../components';
import { GroupContainer } from '../../../container/group';
import { useTeacherSeatPosition } from '../../../container/teacher-seat-position';
import { useTimeTableAttendancePageV2 } from '../../../container/teacher-timetable-attendance-page-v2';
import { RequestUpsertStudentRoleDto, ResponseUserAttendanceDto } from '../../../generated/model';
import { Button, Section, Select, Tab, Tabs, TextField, useRecoilValue } from '../../../reusable';
import { meStateNew } from '../../../store';
import { KlassInfo } from '../../../types';
import { getThisSemester, getThisYear } from '../../../utils';

const groups = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
];

enum contentType {
  list = 1,
  seat,
  role,
}

enum listType {
  total = 1,
  in,
  out,
  del,
}

interface AbsentUser {
  id: number;
  profile: string;
  klassname: string;
  student_number: string;
  name: string;
  hope: string;
  major: string;
  absent: boolean;
  content: string;
  comment: string;
  type1: string;
  type2: string;
  role: string;
}

const lastPeriod = 8; // 동적으로 바꿔야 함.

const defaultSelectedUser = {
  id: -1,
  profile: '',
  klassname: '',
  student_number: '',
  name: '',
  hope: '',
  major: '',
  absent: false,
  content: '',
  comment: '',
  type1: '',
  type2: '',
  role: '',
};

interface SeatType2 {
  id: number;
  name: string;
}

export interface AttendanceContent {
  absent: boolean;
  comment: string;
  createtime: string;
  creator: string;
  editor: string;
  edittime: string;
  period: number;
  subject: string;
  type1: string;
  type2: string;
}

interface RoleInfoType {
  id: number;
  klassname: string;
  student_number: number;
  name: string;
  role: string;
  job: string;
  displayOrder: number;
}

const getTargetDay = (dayOfWeek: string) => {
  const currentDate = new Date();
  const startOfWeek = moment(currentDate).startOf('week').add(1, 'day');
  const endOfWeek = moment(currentDate).endOf('week').subtract(1, 'day');
  const datesOfWeek = [];
  let tempDate = startOfWeek;
  while (tempDate <= endOfWeek) {
    datesOfWeek.push(tempDate.format('YYYY-MM-DD'));
    tempDate = moment(tempDate).add(1, 'day');
  }

  let targetDay = '';

  switch (dayOfWeek) {
    case 'monday':
      targetDay = datesOfWeek[0];
      break;
    case 'tuesday':
      targetDay = datesOfWeek[1];
      break;
    case 'wednesday':
      targetDay = datesOfWeek[2];
      break;
    case 'thursday':
      targetDay = datesOfWeek[3];
      break;
    case 'friday':
      targetDay = datesOfWeek[4];
      break;
    default:
      break;
  }

  return targetDay;
};

interface TimetableAttendancePageProps {
  klassInfo: KlassInfo;
}

export const TimetableAttendancePageV2 = ({ klassInfo }: TimetableAttendancePageProps) => {
  const year = +getThisYear();
  const semester = +getThisSemester();

  const me = useRecoilValue(meStateNew);
  const { allKlassGroups } = GroupContainer.useContext();
  const selectedKlassInfo = allKlassGroups.find((item) => item.name === klassInfo.name);

  // klassInfo.timeCode = 요일_몇교시 ex: "monday_1", "wednesday_3"
  const [dayOfWeek, selectedPeriodString] = klassInfo.timeCode.split('_');
  const targetDay = getTargetDay(dayOfWeek);
  const selectedPeriod = Number(selectedPeriodString);

  const teacherName = selectedKlassInfo?.homeRoomTeacherName;
  const klassName = klassInfo?.name || '';

  const { seatPositionId, seatPosition, refetchSeatPosition } = useTeacherSeatPosition({
    year,
    semester,
    klassId: selectedKlassInfo?.id,
  });

  const {
    userAttendance,
    refetchUserAttendance,
    createAttendanceAbsent,
    updateSeatPosition,
    updateStudentRole,
  } = useTimeTableAttendancePageV2({
    klassname: klassName,
    year: year.toString(),
    day: targetDay,
    subject: klassInfo.subject,
    teachername: teacherName || '',
  });

  const students: ResponseUserAttendanceDto[] = userAttendance; // me 데이터와 유사

  const tempRoleInfo = userAttendance
    .filter((student) => {
      return !student.expired;
    })
    .map((student) => {
      return {
        id: student.id,
        klassname: student.klassname as string,
        student_number: student.student_number as number,
        name: student.name,
        role: student.role ? student.role : '',
        job: student.job ? student.job : '',
        displayOrder: student?.display_order ? student.display_order : 0,
        // displayOrder: 0,
      };
    })
    .sort((a, b) => a.displayOrder - b.displayOrder);

  const [roleInfo, setRoleInfo] = useState<RoleInfoType[]>([...tempRoleInfo]);

  const [showAbsent, setShowAbsent] = useState(listType.total);

  const [showSeat, setShowSeat] = useState(contentType.list);
  const [selectedUserId, setSelectedUserId] = useState<AbsentUser>(defaultSelectedUser);
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState<boolean>(false);

  const [seatSizeRows, setSeatSizeRows] = useState<SeatType2>({ id: 0, name: '0' });
  const [seatSizeCols, setSeatSizeCols] = useState<SeatType2>({ id: 6, name: '6' });

  const [seatEditMode, setSeatEditMode] = useState(false);
  const [roleEditMode, setRoleEditMode] = useState(false);

  const removeStudents = new Map<number, boolean>();
  const absentOfSelectedPeriod = new Map<number, boolean>();
  const absentCommentOfSelectedPeriod = new Map<number, string>();

  const studentsAbsent: Array<number> = [];
  if (students) {
    students.map((student: ResponseUserAttendanceDto) => {
      if (student.expired) {
        removeStudents.set(student.id, true);
        absentCommentOfSelectedPeriod.set(student.id, '');
      } else {
        if (student.content) {
          const contentJson: { attendance: AttendanceContent[] } = JSON.parse(student.content);

          absentOfSelectedPeriod.set(student.id, contentJson.attendance[selectedPeriod].absent);
          absentCommentOfSelectedPeriod.set(
            student.id,
            contentJson.attendance[selectedPeriod].comment,
          );

          if (contentJson.attendance[selectedPeriod].absent) {
            studentsAbsent.push(student.student_number as number);
          }

          if (
            contentJson.attendance[selectedPeriod].type1 !== '' &&
            contentJson.attendance[selectedPeriod].type2 !== ''
          ) {
            student.type1 = contentJson.attendance[selectedPeriod].type1;
            student.type2 = contentJson.attendance[selectedPeriod].type2;
          }
        } else {
          absentOfSelectedPeriod.set(student.id, false);
          absentCommentOfSelectedPeriod.set(student.id, '');
        }
      }
    });
  }

  const handleModalOpen = (student: ResponseUserAttendanceDto) => {
    setSelectedUserId({
      id: student.id,
      profile: student.profile,
      klassname: student.klassname || '',
      student_number: student.student_number ? student.student_number.toString() : '',
      name: student.name,
      hope: student.hopepath,
      major: student.hopemajor,
      absent: absentOfSelectedPeriod.get(student.id) ? true : false,
      content: student.content || '',
      comment: absentCommentOfSelectedPeriod.get(student?.id) as string,
      type1: student.type1 || '',
      type2: student.type2 || '',
      role: student?.role || '',
    });
    setIsAttendanceModalOpen(true);
  };

  /// userId : 결석처리할 학색,
  /// absent : true : 미출석, false : 출석
  /// content : 이전시간 출석 기록
  const submitAbsentUser = (submit: boolean) => {
    if (!submit || selectedUserId.id <= 0) {
      setIsAttendanceModalOpen(false);
      return;
    }

    let contentJson: { attendance: AttendanceContent[] } = { attendance: [] };

    let type1 = '';
    let type2 = '';

    if (selectedUserId.absent) {
      type1 = !selectedUserId.type1 || selectedUserId.type1 === '' ? '기타' : selectedUserId.type1;
      type2 = !selectedUserId.type2 || selectedUserId.type2 === '' ? '기타' : selectedUserId.type2;
    }

    // 기초데이터 생성
    if (selectedUserId.content) {
      contentJson = JSON.parse(selectedUserId.content) as { attendance: AttendanceContent[] };
    } else {
      for (let i = 0; i <= lastPeriod; i++) {
        const t = {
          subject: i === 0 ? '조회' : '',
          period: i,
          creator: me?.name ? me?.name : '',
          createtime: new Date().toLocaleString(),
          editor: '',
          edittime: '',
          comment: '',
          absent: i < selectedPeriod ? false : selectedUserId.absent, // 교과반에서 수정시에는 이후시간에 출결만 반영
          type1: '',
          type2: '',
        };

        contentJson.attendance.push(t);
      }
    }

    // 선택한 다음시간부터  동일한 상태로 만든다.
    for (let i = selectedPeriod + 1; i <= lastPeriod; i++) {
      contentJson.attendance[i].type1 = type1;
      contentJson.attendance[i].type2 = type2;
      contentJson.attendance[i].absent = selectedUserId.absent;
    }

    contentJson.attendance[selectedPeriod].subject = klassInfo.subject;
    contentJson.attendance[selectedPeriod].comment = selectedUserId.comment;
    contentJson.attendance[selectedPeriod].type1 = type1;
    contentJson.attendance[selectedPeriod].type2 = type2;
    contentJson.attendance[selectedPeriod].editor = me?.name ? me?.name : '';
    contentJson.attendance[selectedPeriod].edittime = new Date().toLocaleString();
    contentJson.attendance[selectedPeriod].absent = selectedUserId.absent;

    createAttendanceAbsent({
      attendanceDay: targetDay,
      absent: selectedUserId.absent,
      comment: selectedUserId.comment,
      type1: type1,
      type2: type2,
      content: JSON.stringify(contentJson),
      year: year,
      semester: semester,
      userId: selectedUserId.id,
      schoolId: me?.schoolId ? me.schoolId : 0,
    })
      .then(() => refetchUserAttendance())
      .then(() => setIsAttendanceModalOpen(false))
      .then(() => {
        setSelectedUserId(defaultSelectedUser);
      });
  };

  let moveKlass = false;

  students?.map((student: any) => {
    if (student.klassname !== klassInfo.name) {
      moveKlass = true;
    }
  });

  let seatPositionMap: { studentid: number; seat: string }[] = seatPosition;

  let maxCol = 6;
  let maxRow = 0;

  // 설정된 자리 크기를 가져온다. to-do
  if (seatPositionMap.length > 0) {
    let tmpRow = 1;
    let tmpCol = 1;
    seatPositionMap.map((seatInfo: { studentid: number; seat: string }) => {
      const row = Math.floor(Number(seatInfo?.seat) / 10);
      const col = Number(seatInfo?.seat) % 10;

      tmpRow = row > tmpRow ? row : tmpRow;
      tmpCol = col > tmpCol ? col : tmpCol;
    });

    maxCol = tmpCol + 1;
    maxRow = tmpRow;
  }

  let newSeatCount = 0;
  // 누락된 학생 찾아서 넣기 start
  const getNewSeat = () => {
    const newRow = maxRow + Math.floor(newSeatCount / maxCol);
    const newCol = newSeatCount % maxCol;

    newSeatCount++;
    return newRow.toString() + newCol.toString();
  };

  students?.map((student: ResponseUserAttendanceDto) => {
    if (!student.expired) {
      const rst = seatPositionMap?.find(
        (seatinfo: { studentid: number; seat: string }) => seatinfo.studentid === student.id,
      );
      if (rst === undefined) {
        seatPositionMap.push({
          studentid: student.id,
          seat: `${getNewSeat()}`,
        });
      }
    }
  });
  maxRow = maxRow + Math.floor(newSeatCount / maxCol);

  // dom 에서 자리의 학생 가져오기
  const seatSudentMap = new Map<string, any>();
  const getStudentSeat = (row: number, col: number): ResponseUserAttendanceDto | undefined => {
    const seat = row.toString() + col.toString();

    let rststudent: ResponseUserAttendanceDto | undefined;

    if (!seatSudentMap.has(seat)) {
      const rst = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === seat);
      const studentId = rst?.studentid;

      rststudent = students?.find((student: any) => student.id === studentId);

      if (rststudent?.expired) {
        rststudent = undefined;
      }

      if (rststudent) {
        seatSudentMap.set(seat, rststudent);
      } else {
        // 자리설정은 되었지만 삭제된 학생
        seatPositionMap = seatPositionMap.filter((obj: any) => {
          return obj !== rst;
        });
      }
    } else {
      rststudent = seatSudentMap.get(seat);
    }

    return rststudent;
  };

  const [selSeat, setSelSeat] = useState('');

  const swapSeat = (row: number, col: number) => {
    const seat = row.toString() + col.toString();

    if (selSeat === '') {
      setSelSeat(seat);
    } else {
      // selSeat 와 seat 변경
      const selInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === selSeat);
      const nowInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === seat);

      if (selInfo !== undefined) selInfo.seat = seat;
      if (nowInfo !== undefined) nowInfo.seat = selSeat;

      saveStudentSeat();
      setSelSeat('');
    }
  };

  const swapSeatDrop = (selSeat: string, nowSeat: string) => {
    // selSeat 와 seat 변경
    const selInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === selSeat);
    const nowInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === nowSeat);

    if (selInfo !== undefined) selInfo.seat = nowSeat;
    if (nowInfo !== undefined) nowInfo.seat = selSeat;

    saveStudentSeat();
  };

  const saveStudentSeat = () => {
    if (seatEditMode) {
      const seatPosition = JSON.stringify(seatPositionMap);

      updateSeatPosition({
        id: seatPositionId || 0,
        seatPosition,
      }).then(() => refetchSeatPosition());
    }
  };

  const checkSeatSize = (isCol: boolean, size: number) => {
    if ((isCol && maxCol > size) || (!isCol && maxRow + 1 > size)) {
      alert('학생이 설정되어 있는 자리는 삭제할 수 없습니다.');
      return false;
    } else {
      return true;
    }
  };

  const saveRole = () => {
    if (!roleEditMode) {
      return;
    }

    const roleInfos: RequestUpsertStudentRoleDto[] = roleInfo.map(
      (student: RoleInfoType, i: number) => {
        return {
          userId: student.id,
          role: student.role,
          job: student.job,
          displayOrder: i,
          year: year,
        };
      },
    );

    updateStudentRole(roleInfos).then(() => refetchUserAttendance());
  };

  let from = -1;

  const disabledSaveButton = selectedUserId.absent
    ? !selectedUserId.type1 || !selectedUserId.type2 || !selectedUserId.comment
    : false;

  useEffect(() => {
    if (maxRow >= 0) {
      setSeatSizeRows(groups[maxRow]);
      setSeatSizeCols(groups[maxCol - 1]); // 인덱스가 0부터 시작하므로,
    }
  }, [maxRow]);

  useEffect(() => {
    setRoleEditMode(false);
    setRoleInfo(tempRoleInfo);
  }, [students]);

  return (
    <div className="w-full border-red-600">
      {/* {loading && <Blank reversed />} */}
      {/*{error && <ErrorBlank />}*/}
      <div>
        <div>{klassInfo.name}</div>
        <div className="md:text-xl font-semibold my-3 flex justify-between">
          <p>
            {klassInfo.time} | {klassInfo.subject}
          </p>
          <p> {teacherName ? `담임 : ${teacherName} 선생님` : ''}</p>
        </div>
        <div className="bg-grey-100 border rounded-lg p-5 flex justify-between items-center">
          <p
            className={`cursor-pointer ${
              showAbsent === listType.total && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.total)}
          >
            총원 : {students?.length - removeStudents.size}명
          </p>
          <p
            className={`cursor-pointer ${
              showAbsent === listType.in && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.in)}
          >
            출석 : {students?.length - removeStudents.size - studentsAbsent?.length}명
          </p>
          <p
            className={`cursor-pointer ${
              showAbsent === listType.out && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.out)}
          >
            미출석 : {studentsAbsent?.length}명
          </p>
          <p
            className={`hidden md:inline cursor-pointer ${
              showAbsent === listType.del && 'font-extrabold text-red-500'
            }`}
            onClick={() => setShowAbsent(listType.del)}
          >
            제적
          </p>
        </div>
      </div>
      <Tabs>
        <Tab
          text="출석부"
          active={showSeat === contentType.list}
          onClick={() => {
            setSeatEditMode(false);
            setRoleEditMode(false);
            setShowSeat(contentType.list);
          }}
        />
        <Tab
          text="자리"
          active={showSeat === contentType.seat}
          onClick={() => {
            setSeatEditMode(false);
            setRoleEditMode(false);
            setShowSeat(contentType.seat);
          }}
        />
        <Tab
          text="일인일역"
          active={showSeat === contentType.role}
          onClick={() => {
            setSeatEditMode(false);
            setRoleEditMode(false);
            setShowSeat(contentType.role);
          }}
        />
      </Tabs>

      {/* 출석부 tab */}
      {showSeat === contentType.list && (
        <div className="mb-10 -mx-4">
          {students
            ?.filter((student: ResponseUserAttendanceDto) =>
              showAbsent === listType.in
                ? removeStudents.get(student.id) === false ||
                  absentOfSelectedPeriod.get(student.id) === false
                : showAbsent === listType.out
                ? absentOfSelectedPeriod.get(student.id)
                : showAbsent === listType.del
                ? removeStudents.get(student.id)
                : true,
            )
            .map((student: ResponseUserAttendanceDto, i: number) => (
              <TimetableAtdCard
                key={i}
                student={student}
                comment={absentCommentOfSelectedPeriod.get(student.id) as string}
                attendance={!absentOfSelectedPeriod.get(student.id)}
                setModalOpen={() => handleModalOpen(student)}
              />
            ))}
        </div>
      )}

      {/* 자리 tab */}
      {showSeat === contentType.seat && (
        <div className="-mx-5 overflow-x-auto scroll-box">
          <table
            className="text-center py-4 mx-auto border-separate"
            style={{ borderSpacing: '0.5rem 0.5rem' }}
          >
            <tbody>
              {Array.from(Array(seatSizeRows.id).keys())
                .reverse()
                .map((r: number) => (
                  <tr key={r}>
                    {Array(seatSizeCols.id)
                      .fill(0)
                      .map((_: any, c: number) => (
                        <td
                          key={c}
                          className={`border border-grey-7 ${
                            selSeat === r.toString() + c.toString()
                              ? 'bg-blue-300'
                              : getStudentSeat(r, c)?.profile
                              ? absentOfSelectedPeriod.get(getStudentSeat(r, c)?.id || 0)
                                ? 'bg-red-300'
                                : 'bg-white'
                              : 'bg-grey-6'
                          } cursor-pointer w-16 h-24 min-h-24 rounded-md ${
                            seatEditMode ? '' : 'not-draggable'
                          }`}
                          draggable
                          onClick={() => {
                            if (seatEditMode) {
                              swapSeat(r, c);
                            } else {
                              const student = getStudentSeat(r, c) as ResponseUserAttendanceDto;
                              setSelectedUserId({
                                id: student.id,
                                profile: student.profile,
                                klassname: student.klassname || '',
                                student_number: student.student_number
                                  ? student.student_number.toString()
                                  : '',
                                name: student?.name,
                                hope: student?.hopepath,
                                major: student?.hopemajor,
                                absent: absentOfSelectedPeriod.get(student?.id) ? true : false,
                                content: student.content || '',
                                comment: absentCommentOfSelectedPeriod.get(student?.id) as string,
                                type1: student?.type1 || '',
                                type2: student?.type2 || '',
                                role: student?.role || '',
                              });
                            }
                          }}
                          onDragStart={(ev) => {
                            if (seatEditMode) {
                              ev.dataTransfer.setData('location', r.toString() + c.toString());
                            }
                          }}
                          onDrop={(ev) => {
                            if (seatEditMode) {
                              ev.preventDefault();
                              swapSeatDrop(
                                ev.dataTransfer.getData('location'),
                                r.toString() + c.toString(),
                              );
                            }
                          }}
                          onDragOver={(ev) => {
                            if (seatEditMode) {
                              ev.preventDefault();
                            }
                          }}
                        >
                          {getStudentSeat(r, c)?.profile && (
                            <>
                              <p
                                className=" w-16 h-16 rounded-md bg-white bg-top bg-no-repeat bg-cover z-10 "
                                style={{
                                  backgroundImage: `url(${getStudentSeat(r, c)?.profile})`,
                                }}
                              ></p>
                              <p>
                                {getStudentSeat(r, c)?.name}
                                {/* {r}
                                {c} */}
                              </p>
                            </>
                          )}
                          {!getStudentSeat(r, c)?.profile && <p className="w-16"></p>}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>

          {moveKlass && (
            <div className="w-full flex items-center justify-center space-x-2 mb-6 text-red-500">
              이동수업은 학번 순으로 자리가 표시됩니다.
            </div>
          )}
          <br />
          <div className="w-full flex items-center justify-center space-x-2 mb-6">
            <div className="border border-grey-7 bg-grey-6 px-3 py-2 rounded-md">교탁</div>
          </div>

          {klassInfo.myClass && (
            <div className="w-full flex items-center justify-center flex-col">
              {seatEditMode && (
                <div className="flex items-center space-x-2">
                  <div className=" min-w-max cursor-pointer flex items-center space-x-2">
                    <SelectMenus
                      allText="열 선택"
                      items={groups}
                      onChange={(e) => checkSeatSize(true, e.id) && setSeatSizeCols(e)}
                      value={seatSizeCols}
                    ></SelectMenus>
                    <div>열</div>
                  </div>

                  <div className=" min-w-max cursor-pointer flex items-center space-x-2">
                    <SelectMenus
                      allText="줄 선택"
                      items={groups}
                      onChange={(e) => checkSeatSize(false, e.id) && setSeatSizeRows(e)}
                      value={seatSizeRows}
                    ></SelectMenus>
                    <div>줄</div>
                  </div>
                </div>
              )}

              {me?.name === teacherName && (
                <div className=" cursor-pointer mt-6">
                  <Button
                    onClick={() => {
                      setSelSeat('');
                      setSeatEditMode(!seatEditMode);
                    }}
                  >
                    {seatEditMode ? '변경완료' : '자리변경'}
                  </Button>
                </div>
              )}
              {me?.name !== teacherName && '* 담임선생님만 수정이 가능합니다. '}
            </div>
          )}
          <br />
          <br />
        </div>
      )}

      {/* 일인일역 tab */}
      {showSeat === contentType.role && (
        <>
          <div className="mb-10 -mx-4">
            {roleInfo?.map((student: RoleInfoType, i: number) => (
              <div
                className={`cursor-pointer w-full`}
                draggable={roleEditMode}
                data-id={i}
                key={i}
                onDragStart={(e) => {
                  if (roleEditMode) {
                    const item = e.currentTarget;
                    from = Number(item.dataset.id);
                  }
                }}
                onDrop={(e) => {
                  if (roleEditMode) {
                    if (from >= 0) {
                      const item = e.currentTarget;
                      const to = Number(item.dataset.id);

                      const tempList = roleInfo;

                      tempList.splice(to, 0, tempList.splice(from, 1)[0]);
                      setRoleInfo(tempList?.map((el) => el));
                    }
                  }
                }}
                onDragOver={(ev) => {
                  if (roleEditMode) {
                    ev.preventDefault();
                  }
                }}
              >
                <TimetableStudentRole
                  student={student}
                  editmode={roleEditMode}
                  order={i}
                  setOrder={(order: number, isUpDir: boolean) => {
                    const from = order;
                    const to = isUpDir ? from - 1 : from + 1;

                    if (to >= 0) {
                      const tempList = roleInfo;

                      tempList.splice(to, 0, tempList.splice(from, 1)[0]);
                      setRoleInfo(tempList?.map((el) => el));
                    }
                  }}
                />
              </div>
            ))}
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            {me?.name === teacherName && (
              <div className=" cursor-pointer mt-6">
                <Button
                  onClick={() => {
                    if (roleEditMode) saveRole();
                    setRoleEditMode(!roleEditMode);
                  }}
                >
                  {roleEditMode ? '저장하기' : '수정하기'}
                </Button>
              </div>
            )}
            {me?.name !== teacherName && '* 담임선생님만 수정이 가능합니다. '}
          </div>
          <br />
          <br />
        </>
      )}

      {/* 상태변경 */}

      <SuperModal
        modalOpen={isAttendanceModalOpen}
        setModalClose={() => submitAbsentUser(false)}
        ablePropragation
      >
        <Section tw={{ spaceY: 'space-y-2' }}>
          <div className="font-semibold text-lg">출결관리</div>
          <div className="flex justify-between">
            <div
              className=" w-40 h-40 rounded-md bg-white bg-top-1 bg-no-repeat bg-cover z-10 "
              style={{ backgroundImage: `url(${selectedUserId.profile})` }}
            />
            <div className="w-25 h-40 text-lg font-semibold align-text-bottom text-ellipsis">
              {selectedUserId.role}
            </div>
          </div>
          <div>
            <div className=" text-lg font-bold">
              {selectedUserId.klassname} {selectedUserId.student_number}번 {selectedUserId.name}
            </div>
            <div className="text-lg font-bold"></div>

            <div className="text-sm text-grey-2">희망학과 | {selectedUserId.major}</div>
            <div className="text-sm text-grey-2">장래희망 | {selectedUserId.hope}</div>
          </div>
          <div className="w-full border-b border-gray-100" />
          {/* 출결관리 모달의 버튼 */}
          <div className="w-full flex items-center justify-between space-x-2">
            <Button
              tw={{
                backgroundColor: selectedUserId.absent ? 'bg-gray-100' : 'bg-blue-500',
                color: selectedUserId.absent ? 'text-gray-500' : 'text-white',
                width: 'w-full',
              }}
              onClick={() =>
                setSelectedUserId((prevState) => ({
                  ...prevState,
                  type1: '',
                  type2: '',
                  comment: '',
                  absent: false,
                }))
              }
            >
              출석
            </Button>
            <Button
              tw={{
                backgroundColor: selectedUserId.absent ? 'bg-red-50' : 'bg-gray-100',
                color: selectedUserId.absent ? 'text-red-500' : 'text-gray-500',
                width: 'w-full',
              }}
              onClick={() =>
                setSelectedUserId((prevState) => ({
                  ...prevState,
                  absent: true,
                }))
              }
            >
              미출석
            </Button>
          </div>
          <TextField
            label="사유"
            placeholder="특기사항를 입력해주세요."
            onChange={(e) => setSelectedUserId({ ...selectedUserId, comment: e.target.value })}
            value={selectedUserId.comment}
          />
          <div className="w-full flex items-center justify-between space-x-2">
            <Select
              label="신고유형"
              disabled={!selectedUserId.absent}
              twOuter={{ width: 'w-full' }}
              value={selectedUserId.type2}
              onChange={(e) => setSelectedUserId({ ...selectedUserId, type2: e.target.value })}
              tw={{ backgroundColor: !selectedUserId.absent ? 'bg-gray-200' : 'bg-white' }}
            >
              <option selected hidden className={'bg-gray-400'}>
                구분
              </option>
              {['인정', '질병', '미인정', '기타'].map((subject: string) => (
                <option value={subject} key={subject}>
                  {subject}
                </option>
              ))}
            </Select>
            <Select
              label="구분"
              disabled={!selectedUserId.absent}
              twOuter={{ width: 'w-full' }}
              value={selectedUserId.type1}
              onChange={(e) => setSelectedUserId({ ...selectedUserId, type1: e.target.value })}
              tw={{ backgroundColor: !selectedUserId.absent ? 'bg-gray-200' : 'bg-white' }}
            >
              <option selected hidden>
                유형
              </option>
              {['결석', '지각', '조퇴', '결과', '기타'].map((subject: string) => (
                <option value={subject} key={subject}>
                  {subject}
                </option>
              ))}
            </Select>
          </div>
          <div className={'text-sm'}>미출석 시 신고유형 / 구분 필수</div>
          <Button
            tw={{ width: 'w-full' }}
            onClick={() => submitAbsentUser(true)}
            disabled={disabledSaveButton}
          >
            저장하기
          </Button>
        </Section>
      </SuperModal>
    </div>
  );
};
