import React, { FC } from 'react';
import { textColor } from 'tailwindcss/defaultTheme';
import { Button } from '../../reusable';

interface TimetableAtdCardProps {
  attendance: boolean;
  student: any;
  comment: string;
  setModalOpen: () => void;
}

export const TimetableAtdCard: FC<TimetableAtdCardProps> = ({
  attendance,
  student,
  comment,
  setModalOpen,
}) => {
  return (
    <div>
      <div className="w-full flex justify-between items-center px-4 py-2">
        <div className="flex items-center space-x-2">
          {student.expired ? (
            <div className="text-sm md:text-base bg-red-50 text-red-600 px-2.5 py-1.5 rounded">
              {student.expired_reason}
            </div>
          ) : attendance ? (
            <div className="text-sm md:text-base bg-blue-100 text-blue-600 px-2.5 py-1.5 rounded">
              출석
            </div>
          ) : (
            <div className="text-sm md:text-base bg-red-100 text-red-600 px-2.5 py-1.5 rounded">
              {student.type1}
            </div>
          )}
          <div
            className={`text-sm md:text-lg ${
              student.expired && 'text-gray-400'
            }`}
          >
            {student.klassname} {student.student_number}번
          </div>
          <div
            className={`text-sm md:text-lg ${
              student.expired && 'text-gray-400'
            }`}
          >
            {student.name}
          </div>
        </div>
        <Button
          tw={{ height: 'h-10', fontSize: 'text-sm' }}
          onClick={() => student.expired === false && setModalOpen()}
        >
          출결관리
        </Button>
      </div>
      {/* {student.comment && !attendance && (
        <div className="text-sm md:text-base px-4">{student.comment}</div>
      )} */}
      <div className="text-sm md:text-base px-4">{comment}</div>
    </div>
  );
};
