import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useUserControllerCreateChildren } from '../../../generated/endpoint';

interface MatchParams {
  uuid: string;
}

export const AddChildrenPage = () => {
  const history = useHistory();

  const { uuid } = useParams<MatchParams>();

  const { mutateAsync: addChild } = useUserControllerCreateChildren();

  useEffect(() => {
    if (uuid) {
      addChild({ uuid })
        .then((res) => {
          if (!res?.token) {
            throw new Error('token is undefined');
          }
          alert('자녀 추가 성공');
          history.replace('/student');
        })
        .catch(() => {
          alert('자녀 추가 실패');
          localStorage.setItem('childError', 'true');
          history.replace('/student');
        });
    }
  }, [uuid]);

  return <div></div>;
};
