import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useTeacherBoardDetail } from './teacher-board-detail';
import { QueryKey } from '../constants/query-key';
import { useBoardControllerCreate, useBoardControllerUpdate } from '../generated/endpoint';
import {
  BoardCategoryEnum,
  Group,
  RequestCreateBoardDto,
  UploadFileTypeEnum,
} from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';
import { useImageAndDocumentManagement } from '../hooks/useImageAndDocumentManagement';
import { Routes } from '../routes';
import { ImageObject } from '../type';
import { DocumentObject } from '../type/document-file-object';

export const useTeacherBoardAdd = ({
  homeKlass,
  groups,
  boardId,
}: {
  homeKlass?: Group;
  groups?: Group[];
  boardId?: number;
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const { board } = useTeacherBoardDetail(boardId);

  const [title, setTitle] = useState(board?.title || '');
  const [content, setContent] = useState(board?.content || '');
  const [selectedCategory, setSelectedCategory] = useState<BoardCategoryEnum | undefined>(
    board?.category || undefined,
  );

  const {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
  } = useImageAndDocumentManagement({
    initImageObjectMapFn: () => {
      const urlImageMap = new Map<number, ImageObject>();
      board?.images.forEach((image, index) => {
        urlImageMap.set(index, { image, isDelete: false });
      });
      return new Map(urlImageMap);
    },
    initDocumentObjectMapFn: () => {
      const urlDocumentMap = new Map<number, DocumentObject>();
      board?.files.forEach((file, index) => {
        urlDocumentMap.set(index, { document: file, isDelete: false });
      });
      return new Map(urlDocumentMap);
    },
  });

  const { isUploadLoading, handleUploadFile } = useFileUpload();

  const { mutate: createBoardMutate, isLoading: isCreateBoardMutate } = useBoardControllerCreate({
    mutation: {
      onSuccess: (result) => {
        queryClient.invalidateQueries(QueryKey.teacher.boardList);
        queryClient.invalidateQueries(QueryKey.teacher.board);
        history.push(`${Routes.teacher.board}/${result.id}`);
      },
      onError: () => {
        setErrorMessage('학급게시판 등록에 실패했습니다.');
      },
    },
  });

  const { mutate: updateBoardMutate, isLoading: isUpdateBoardMutate } = useBoardControllerUpdate({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.teacher.boardList);
        queryClient.invalidateQueries(QueryKey.teacher.board);
        history.push(`${Routes.teacher.board}/${board?.id}`);
      },
      onError: () => {
        setErrorMessage('학급게시판 수정에 실패했습니다.');
      },
    },
  });

  const firstGroup: Group[] = [];
  const secondGroup: Group[] = [];
  const thirdGroup: Group[] = [];
  const restGroup: Group[] = [];

  const groupList: Group[] | undefined = board
    ? ((
        board?.groupBoards as unknown as {
          id: number;
          group: { id: number; name: string };
        }[]
      ).map((groupBoard) => groupBoard.group) as Group[])
    : undefined;
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(
    groupList ? groupList : homeKlass ? [homeKlass] : [],
  );

  groups?.map((group) => {
    if (group.name?.includes('1학년')) {
      firstGroup.push(group);
    } else if (group.name?.includes('2학년')) {
      secondGroup.push(group);
    } else if (group.name?.includes('3학년')) {
      thirdGroup.push(group);
    } else {
      restGroup.push(group);
    }
    return group;
  });

  const selectedGroupIds = selectedGroups?.map((el) => el.id) || [];
  const isLoading = isUploadLoading || isCreateBoardMutate || isUpdateBoardMutate;
  const buttonDisabled = !title || !content || !selectedCategory || !selectedGroups.length;

  const handleSubmit = async ({
    boardPayload,
    imageObjectMapParam,
    documentObjectMapParam,
  }: {
    boardPayload: RequestCreateBoardDto | undefined;
    imageObjectMapParam: Map<number, ImageObject>;
    documentObjectMapParam: Map<number, DocumentObject>;
  }) => {
    try {
      if (!boardPayload) {
        return;
      }
      // file image 처리
      const imageFiles = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.image instanceof File)
        .map((value) => value.image) as File[];
      const imageFileNames = await handleUploadFile(UploadFileTypeEnum['board/images'], imageFiles);

      // url image 처리
      const imageUrlNames = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.image === 'string')
        .map((value) => value.image) as string[];

      const allImageNames = [...imageUrlNames, ...imageFileNames];

      // file document 처리
      const documentFiles = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.document instanceof File)
        .map((value) => value.document) as File[];
      const documentFileNames = await handleUploadFile(
        UploadFileTypeEnum['board/files'],
        documentFiles,
      );

      const documentUrlNames = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.document === 'string')
        .map((value) => value.document) as string[];

      const allDocumentNames = [...documentUrlNames, ...documentFileNames];

      const { title, content, category, targetGroupIds } = boardPayload;
      if (board?.id) {
        updateBoardMutate({
          id: board.id,
          data: {
            title,
            content,
            category,
            images: allImageNames,
            files: allDocumentNames,
            targetGroupIds,
          },
        });
      } else {
        createBoardMutate({
          data: {
            title,
            content,
            category,
            images: allImageNames,
            files: allDocumentNames,
            targetGroupIds,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    title,
    content,
    selectedGroups,
    selectedCategory,
    firstGroup,
    secondGroup,
    thirdGroup,
    restGroup,
    selectedGroupIds,
    imageObjectMap,
    documentObjectMap,
    buttonDisabled,
    errorMessage,
    isLoading,
    setTitle,
    setContent,
    setSelectedGroups,
    setSelectedCategory,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    handleSubmit,
  };
};
