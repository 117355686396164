import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { jsPDF } from 'jspdf';

import { FieldtripResultUpdatePage } from './FieldtripResultUpdatePage';
import {
  SuperModal,
  FieldtripPaper,
  FieldtripSeparatePaper,
  FieldtripSuburbsSeparatePaper,
} from '../../../components';
import { useTeacherFieldtripResultDetail } from '../../../container/teacher-fieldtrip-result-detail';
import { ResponseUserDto, Role } from '../../../generated/model';
import { Blank, Section, Button, TextArea } from '../../../reusable';
import {
  makeDateToString,
  makeStartEndToString,
  makeTimeToString,
  extractReactData,
} from '../../../utils';

interface MatchParams {
  id: string;
}

interface FieldtripResultDetailPageProps {
  school: ResponseUserDto['school'] | undefined;
  refetchFieldtrips: () => void;
  setOpen: (b: boolean) => void;
  setAgreeAll: (b: boolean) => void;
  userRole: Role | undefined;
  approvalLine?: string[];
  setFieldtripId?: (n: number) => void;
}

const calculateStatus = (role: string, order: 'before' | 'after' | 'show') => {
  const roles = ['TEACHER', 'HEAD', 'PRINCIPAL'];
  const _fieldtripStatus = [
    'BEFORE_TEACHER_APPROVAL',
    'TEACHER',
    'BEFORE_HEAD_APPROVAL',
    'HEAD',
    'BEFORE_PRINCIPAL_APPROVAL',
    'PRINCIPAL',
    'PROCESSED',
  ];

  if (role === 'SECURITY') return ['PROCESSED'];

  const index = _fieldtripStatus.findIndex((el) => el === role);

  if (index === -1) return [];

  if (order === 'before') {
    return _fieldtripStatus.slice(index - 1, index).filter((el) => !roles.includes(el));
  } else if (order === 'after') {
    return _fieldtripStatus.slice(index).filter((el) => !roles.includes(el));
  } else if (order === 'show') {
    return _fieldtripStatus.slice(index - 1).filter((el) => !roles.includes(el));
  }
};

export const FieldtripResultDetailPage: React.FC<FieldtripResultDetailPageProps> = ({
  school,
  refetchFieldtrips,
  setOpen,
  setAgreeAll,
  userRole,
  approvalLine,
  setFieldtripId,
}) => {
  const history = useHistory();
  const { id } = useParams<MatchParams>();
  const ref = useRef(null);
  const planRef = useRef(null);
  const separatePaperRefs = useRef<any[]>([]);

  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [clicked, setClicked] = useState(false);
  const [readState, setReadState] = useState(true);

  const {
    denyFieldtripResult,
    deleteAppealFieldtripResult,
    isLoading,
    fieldtrip,
    deleteAppeal,
    loading,
    deny,
    setDeleteAppeal,
    setDeny,
    setLoading,
    refetchAll,
  } = useTeacherFieldtripResultDetail({ refetchFieldtrips, id });

  useEffect(() => {
    setFieldtripId && setFieldtripId(Number(id));
  }, [id]);

  let homeplans: any = [];
  let content;
  const resultFilesWithTwo: any = [];

  try {
    if (fieldtrip?.type === 'HOME') {
      const _content = JSON.parse(fieldtrip?.resultText || '[]');
      content = _content[0];
      homeplans = _content?.slice(1);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (fieldtrip?.resultFiles instanceof Array) {
      let chunk = [];
      if (fieldtrip?.resultFiles?.length === 1) {
        resultFilesWithTwo.push(fieldtrip?.resultFiles);
      } else {
        for (let i = 0; i < fieldtrip?.resultFiles?.length; i++) {
          chunk.push(fieldtrip?.resultFiles[i]);
          if (i % 2 === 1) {
            resultFilesWithTwo.push(chunk);
            chunk = [];
          }
        }
      }
    }
  } catch (err) {
    console.log(err);
  }

  const isConfirmed =
    (userRole &&
      calculateStatus(userRole, 'after')?.includes(fieldtrip?.fieldtripResultStatus || '')) ||
    false;

  const buttonDisabled =
    userRole &&
    calculateStatus(userRole, 'after')
      ?.slice(1)
      ?.includes(fieldtrip?.fieldtripResultStatus || '');

  if (!fieldtrip || fieldtrip?.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM') {
    return (
      <div className="relative bg-white border rounded-lg py-5 h-screen-7 flex items-center justify-center text-center">
        <div className="absolute top-5 left-0">
          <div className="w-full flex items-center justify-start space-x-2 px-5">
            <div
              className="text-brand-1 underline cursor-pointer"
              onClick={() => fieldtrip && history.push(`/teacher/fieldtrip/${fieldtrip.id}`)}
            >
              신청서
            </div>
            <div
              className="text-brand-1 underline cursor-pointer"
              onClick={() => fieldtrip && history.push(`/teacher/fieldtrip/notice/${fieldtrip.id}`)}
            >
              통보서
            </div>
            <div className="text-brand-1 underline cursor-pointer">결과보고서</div>
          </div>
        </div>
        <div className="bg-white p-5 text-lg">아직 결과보고서가 작성되지 않았습니다.</div>
      </div>
    );
  }

  if (!readState) {
    return (
      <FieldtripResultUpdatePage
        school={school}
        fieldtrip={fieldtrip}
        refetchAll={refetchAll}
        setReadState={() => setReadState(true)}
        isConfirmed={isConfirmed}
      />
    );
  }

  return (
    <div className="h-screen-4">
      {loading && <Blank reversed />}
      {isLoading && <Blank reversed />}

      <div className="bg-white border rounded-lg py-5 h-screen-7">
        <div className="w-auto h-full overflow-scroll relative">
          <div className="w-full flex items-center justify-start space-x-2 px-5 ">
            <div
              className="text-brand-1 underline cursor-pointer"
              onClick={() => history.push(`/teacher/fieldtrip/${fieldtrip.id}`)}
            >
              신청서
            </div>
            <div
              className="text-brand-1 underline cursor-pointer"
              onClick={() => history.push(`/teacher/fieldtrip/notice/${fieldtrip.id}`)}
            >
              통보서
            </div>
            <div className="text-brand-1 underline cursor-pointer">결과보고서</div>
          </div>
          {fieldtrip?.fieldtripResultStatus === 'RETURNED' &&
            fieldtrip?.notApprovedReason &&
            fieldtrip?.updatedAt && (
              <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 mx-5 py-2">
                <div className="text-brand-1">{fieldtrip?.notApprovedReason}</div>
                <div className="text-gray-500 text-sm">
                  {makeDateToString(new Date(fieldtrip?.updatedAt))}{' '}
                  {makeTimeToString(new Date(fieldtrip?.updatedAt))}에 마지막으로 수정
                </div>
              </div>
            )}
          {fieldtrip?.updateReason && fieldtrip?.updatedAt && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2">
              <div className="text-brand-1">{fieldtrip?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(fieldtrip?.updatedAt)} {makeTimeToString(fieldtrip?.updatedAt)}에
                마지막으로 수정
              </div>
            </div>
          )}

          <div
            ref={ref}
            className="bg-white"
            style={{
              width: '778px',
              height: '1100px',
              padding: '20px',
            }}
          >
            <FieldtripPaper
              school={school}
              fieldtrip={fieldtrip}
              content={content}
              type="결과보고서"
              approvalLine={approvalLine}
            />
          </div>
          {fieldtrip?.type === 'HOME' && (
            <>
              {homeplans?.map((content: any, i: number) => (
                <div
                  ref={(el) => (separatePaperRefs.current[i] = el)}
                  className="bg-white"
                  style={{
                    width: '760px',
                    height: '1058px',
                    padding: '20px',
                  }}
                >
                  <FieldtripSeparatePaper
                    studentName={fieldtrip?.student?.name}
                    studentGradeKlass={
                      fieldtrip?.studentGradeKlass + ' ' + fieldtrip?.studentNumber + '번'
                    }
                    fieldtrip={fieldtrip}
                    index={i + 1}
                    content={content}
                    type="결과보고서"
                  />
                </div>
              ))}
            </>
          )}
          {fieldtrip?.type === 'SUBURBS' && (
            <>
              {resultFilesWithTwo.map((el: any, i: number) => (
                <div
                  ref={(el) => (separatePaperRefs.current[i] = el)}
                  className="bg-white"
                  style={{
                    width: '760px',
                    height: '1058px',
                    padding: '20px',
                  }}
                >
                  <FieldtripSuburbsSeparatePaper
                    studentName={fieldtrip?.student?.name || ''}
                    fieldtrip={fieldtrip}
                    resultFile1={el[0]}
                    resultFile2={el[1]}
                  />
                </div>
              ))}
            </>
          )}
        </div>

        <div className="flex items-center w-full overflow-x-auto space-x-2 mt-3">
          <Button
            disabled={clicked}
            tw={{
              width: 'w-full',
              backgroundColor: clicked ? 'bg-gray-400' : 'bg-blue-500',
              minWidth: 'min-w-max',
            }}
            onClick={async () => {
              if (ref?.current) {
                setClicked(true);
                const doc: any = new jsPDF('p', 'mm', 'a4');
                //@ts-ignore
                const imgData = await extractReactData(ref.current);

                await doc.addImage(imgData, 'PNG', 0, 0, 210, 297);

                if (planRef?.current) {
                  const planImgData = await extractReactData(planRef.current);
                  await doc.addPage();
                  await doc.addImage(planImgData, 'PNG', 0, 0, 210, 297);
                }

                for (const ref of separatePaperRefs.current) {
                  if (ref) {
                    const paperImgData = await extractReactData(ref);
                    await doc.addPage();
                    await doc.addImage(paperImgData, 'PNG', 0, 0, 210, 297);
                  }
                }
                await doc.save(
                  `체험학습 결과보고서(${fieldtrip?.student?.name}, ${
                    fieldtrip?.startAt &&
                    fieldtrip?.endAt &&
                    makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)
                  }).pdf`,
                );
                setClicked(false);
              }
            }}
          >
            다운로드
          </Button>
          <Button
            tw={{
              width: 'w-full',
              minWidth: 'min-w-max',
              backgroundColor: buttonDisabled ? 'bg-gray-500' : 'bg-lightpurple-4',
            }}
            text="반려"
            disabled={buttonDisabled}
            onClick={() => {
              setDeny(true);
            }}
          />
          <Button
            tw={{ width: 'w-full', minWidth: 'min-w-max' }}
            text={isConfirmed ? '승인 후 수정' : '수정'}
            onClick={() => {
              setReadState(false);
            }}
          />
          <Button
            tw={{
              width: 'w-full',
              minWidth: 'min-w-max',
              backgroundColor: buttonDisabled ? 'bg-gray-500' : 'bg-brand-1',
            }}
            text={buttonDisabled ? '승인 완료' : '승인'}
            disabled={buttonDisabled}
            onClick={() => {
              setOpen(true);
              setAgreeAll(false);
            }}
          />
        </div>
        <SuperModal modalOpen={deny} setModalClose={() => setDeny(false)} width="w-max">
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 체험학습 결과보고서를 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                setLoading(true);
                denyFieldtripResult({ id: Number(id), data: { reason: notApprovedReason } });
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>

        {/* 삭제요청 버튼이 없기 때문에 아래 SuperModal은 실제로 사용되지는 않음, 코드 베이스 유지를 위해서 삭제는 하지않음 */}
        <SuperModal
          modalOpen={deleteAppeal}
          setModalClose={() => setDeleteAppeal(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 체험학습 결과보고서를 삭제하도록 요청하시겠습니까?
            </div>
            <TextArea
              placeholder="삭제 이유"
              onChange={(e) => setDeleteReason(e.target.value)}
              value={deleteReason}
            />
            <Button
              tw={{ backgroundColor: 'bg-red-500' }}
              onClick={() => {
                setLoading(true);
                deleteAppealFieldtripResult({ id: Number(id), data: { reason: deleteReason } });
              }}
            >
              삭제 요청하기
            </Button>
          </Section>
        </SuperModal>
      </div>
    </div>
  );
};
