import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useFieldtripResultControllerResend,
  useFieldtripsControllerFindOne,
} from '../generated/endpoint';

type Props = {
  id: number;
};

export const useFieldtripResultDetail = ({ id }: Props) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    data: fieldtrip,
    isLoading: isGetFieldtrip,
    isError: isGetFieldtripError,
  } = useFieldtripsControllerFindOne(id);

  const {
    mutateAsync: resendAlimtalkMutate,
    isLoading: isResendAlimtalk,
    isError: isResendAlimtalkError,
  } = useFieldtripResultControllerResend({
    mutation: {
      onSuccess: () => {
        alert('결과보고서 알림톡이 재전송되었습니다.');
        history.push('/student/fieldtrip');
      },
      onError: (err) => {
        setErrorMessage(err.message);
      },
    },
  });

  const resendAlimtalk = async () => {
    if (!fieldtrip) return;

    const { id } = fieldtrip;
    await resendAlimtalkMutate({ id });
  };

  const isLoading = isGetFieldtrip || isResendAlimtalk;
  const isError = isGetFieldtripError || isResendAlimtalkError;
  return {
    isLoading,
    isError,
    resendAlimtalk,
    fieldtrip,
    errorMessage,
  };
};
