import React from 'react';

interface SelectValuesProps {
  label?: string;
  selectValues: any[];
  placeholder?: string;
  value?: any;
  onChange?: (group: any) => void;
  border?: string;
  borderColor?: string;
}

export const SelectValues: React.FC<SelectValuesProps> = ({
  label,
  selectValues,
  placeholder,
  value,
  onChange,
  border = 'border',
  borderColor = 'border-gray-300',
}) => {
  return (
    <div>
      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        className={`mt-1 block w-full pl-1 pr-1 py-3 text-base border ${border} ${borderColor} focus:outline-none focus:ring-indigo-500 focus:border-black sm:text-sm rounded-md`}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
      >
        {placeholder && (
          <option selected hidden>
            {placeholder}
          </option>
        )}

        {selectValues?.map((value: any) => {
          if (typeof value === 'string') {
            return <option value={value || ''}>{value}</option>;
          } else if (typeof value === 'object') {
            return <option value={value.value}>{value.text}</option>;
          }
        })}
      </select>
    </div>
  );
};
