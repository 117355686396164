export type NotNullable<Type> = {
  [Key in keyof Type]-?: NotNullable<NonNullable<Type[Key]>>;
};

export enum UserRole {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  USER = 'USER',
  HEAD = 'HEAD',
  PRINCIPAL = 'PRINCIPAL',
  SECURITY = 'SECURITY',
  PARENT = 'PARENT',
  VICEPRINCIPAL = 'VICEPRINCIPAL',
  HEADPRINCIPAL = 'HEADPRINCIPAL',
  PREHEAD = 'PREHEAD',
  PREPRINCIPAL = 'PREPRINCIPAL',
}

export enum TabType {
  NOTICE = 'NOTICE', // 공지사항
  NEWSLETTER = 'NEWSLETTER', // 가정통신문
  BOARD = 'BOARD', // 학급별 게시판
  COULSELING = 'COULSELING', // 상담
  CHAT = 'CHAT', // 채팅
}

export enum FileType {
  FILE = 'FILE',
  IMAGE = 'IMAGE',
}

export interface User {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
  uuid?: string;
  role?: UserRole;
  notices?: Notice[];
  studentGroups?: StudentGroup[];
  teacherGroups?: TeacherGroup[];
  studentActivity?: StudentActivity;
  studentActivities?: StudentActivity[];
  studentNewsletter?: StudentNewsletter;
  studentNewsletters?: StudentNewsletter[];
  comments?: Comment[];
  schoolId?: number;
  school?: School;
  createdAt?: string;
  updatedAt?: string;
  profile?: string;
  customProfile?: string;
  customBackground?: string;
  customMessage?: string;
  nokName?: string;
  nokPhone?: string;
  manual?: string;
  klassGroup?: StudentGroup;
  headNumber?: number;
  remainDaysOfFieldtrip?: number;
  firstVisit?: boolean;
  birthDate?: Date | string;
  canEditNotice?: boolean;
  canEditNewsletter?: boolean;
  canEditTimetable?: boolean;
  canEditCanteen?: boolean;
  hopePath?: string;
  hopeMajor?: string;
  children?: User[];
  parents?: User[];
}

export interface Notice {
  id?: number;
  title?: string;
  content?: string;
  category?: string;
  userId?: number;
  user?: User;
  createdAt?: string;
  updatedAt?: string;
}
export interface Newsletter {
  id?: number;
  title?: string;
  content?: string;
  category?: string;
  surveyContent?: string;
  userId?: number;
  user?: User;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  images?: string[];
  files?: string[];
  klasses?: number[];
  isPublished?: boolean;
  studentNewsletter?: StudentNewsletter;
  studentNewsletters?: StudentNewsletter[];
}

export interface Activity {
  id?: number;
  title?: string;
  content?: string;
  type?: string;
  subject?: string;
  fileUrl?: string;
  endDate?: string;
  commonText?: string;
  isRecord?: boolean;
  isImage?: boolean;
  isFile?: boolean;
  isContent?: boolean;
  group?: Group;
  studentActivity?: StudentActivity;
  studentActivities?: StudentActivity[];
  groupActivities?: GroupActivity[];
  createdAt?: string;
  updatedAt?: string;
  explainText?: string;
}

export interface Group {
  id?: number;
  name?: string;
  type?: string;
  year?: string;
  schoolId: number;
  school: School;
  activities?: Activity[];
  groupActivities?: GroupActivity[];
  studentGroups?: StudentGroup[];
  teacherGroups?: TeacherGroup[];
  teacherGroup?: TeacherGroup;
  createdAt?: string;
  updatedAt?: string;
}

export interface Comment {
  id?: number;
  name?: string;
  content?: string;
  userId?: number;
  user?: User;
  studentActivityId?: number;
  studentActivity?: StudentActivity;
  createdAt?: string;
  updatedAt?: string;
}

export interface School {
  id?: number;
  name?: string;
  region?: string;
  stage?: string;
  mark?: string;
  stamp?: string;
  fieldtripDays?: number;
  privacyPolicyLink?: string;
  users?: User[];
  createdAt?: string;
  updatedAt?: string;
}

export interface GroupActivity {
  id?: number;
  name?: string;
  content?: string;
  groupId?: number;
  group?: Group;
  activityId?: number;
  activity?: Activity;
  createdAt?: string;
  updatedAt?: string;
}

export interface StudentGroup {
  id?: number;
  studentNumber?: number;
  userId?: number;
  user?: User;
  groupId?: number;
  group?: Group;
  createdAt?: string;
  updatedAt?: string;
  summaries?: Summary[];
}

export interface Summary {
  id?: number;
  content?: string;
  subject?: string;
  teacherName?: string;
  studentGroupId?: number;
  studentGroup?: StudentGroup;
  userId?: number;
  user?: User;
  createdAt?: string;
  updatedAt?: string;
}

export interface TeacherGroup {
  id?: number;
  subject?: string;
  userId?: number;
  user?: User;
  groupId?: number;
  group?: Group;
  createdAt?: string;
  updatedAt?: string;
}

export interface StudentActivity {
  id?: number;
  image?: string;
  content?: string;
  files?: string[];
  images?: string[];
  isSubmitted?: boolean;
  summary?: string;
  feedback?: string;
  userId?: number;
  user?: User;
  activityId?: number;
  activity?: Activity;
  comments?: Comment[];
  createdAt?: string;
  updatedAt?: string;
}

export interface StudentNewsletter {
  id?: number;
  studentGradeKlass?: string;
  studentNumber?: number;
  content?: string;
  files?: string[];
  images?: string[];
  studentNewsletterStatus?: string;
  isSubmitted?: boolean;
  studentId?: number;
  student?: User;
  newsletterId?: number;
  newsletter?: Newsletter;
  studentSignature?: string;
  parentSignature?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Canteen {
  id?: number;
  lunch?: string;
  dinner?: string;
  image?: string;
  date?: string;
}

export interface Schedule {
  id?: number;
  calendarId?: string;
  attendee?: string;
  title?: string;
  category?: string;
  location?: string;
  dueDateClass?: string;
  isAllDay?: boolean;
  start: Date | string;
  end: Date | string;
  isReadOnly?: boolean;
  userId?: number;
  user?: User;
  schoolId?: number;
  school?: School;
}

export interface Timetable {
  id?: number;
  content?: string;
  year?: number;
  semester?: number;
  groupId?: number;
  group?: Group;
}

export enum AbsentStatus {
  RETURNED = 'RETURNED',
  BEFORE_PARENT_CONFIRM = 'BEFORE_PARENT_CONFIRM',
  BEFORE_TEACHER_APPROVAL = 'BEFORE_TEACHER_APPROVAL',
  BEFORE_HEAD_APPROVAL = 'BEFORE_HEAD_APPROVAL',
  BEFORE_PRINCIPAL_APPROVAL = 'BEFORE_PRINCIPAL_APPROVAL',
  PROCESSED = 'PROCESSED',
  DELETE_APPEAL = 'DELETE_APPEAL',
}

export interface Absent {
  id?: number;
  studentGradeKlass?: string;
  studentNumber?: number;
  reportType?: string;
  absentStatus?: string;
  startAt?: string;
  endAt?: string;
  reason?: string;
  description?: string;
  evidenceType?: string;
  evidenceFile?: string;
  evidenceFiles?: string[];
  reportedAt?: string;
  submitted?: boolean;
  niceSubmitted?: boolean;
  studentSignature?: string;
  teacherSignature?: string;
  parentSignature?: string;
  parentComment?: string;
  headSignature?: string;
  principalSignature?: string;
  notApprovedReason?: string;
  deleteReason?: string;
  updateReason?: string;
  uuid?: string;
  studentId?: number;
  student?: User;
  teacherId?: number;
  teacher?: User;
  createdAt?: string;
  updatedAt?: string;
}

export interface Fieldtrip {
  fieldStatus: string;
  id?: number;
  studentGradeKlass?: string;
  studentNumber?: number;
  reportType?: string;
  form?: string;
  fieldtripStatus?: string;
  fieldtripResultStatus?: string;
  startAt?: string;
  endAt?: string;
  reason?: string;
  description?: string;
  evidenceType?: string;
  evidenceFile?: string;
  reportedAt?: string;
  resultReportedAt?: string;
  resultText?: string;
  resultTitle?: string;
  resultFile?: string;
  resultFiles?: string[];
  currentRemainDays?: number;
  usedDays?: number;
  purpose?: string;
  studentSignature?: string;
  teacherSignature?: string;
  parentSignature?: string;
  headSignature?: string;
  principalSignature?: string;
  studentResultSignature?: string;
  teacherResultSignature?: string;
  headResultSignature?: string;
  parentResultSignature?: string;
  principalResultSignature?: string;
  notApprovedReason?: string;
  deleteReason?: string;
  updateReason?: string;
  uuid?: string;
  studentId?: number;
  student?: User;
  teacherId?: number;
  teacher?: User;
  createdAt?: string;
  updatedAt?: string;
  noticedAt?: string;
}

export interface Manager {
  managerStatus: string;
  id?: number;
  studentGradeKlass?: string;
  studentNumber?: number;
  reportType?: string;
  startAt?: string;
  endAt?: string;
  reason?: string;
  description?: string;
  evidenceType?: string;
  evidenceFile?: string;
  reportedAt?: string;
  studentSignature?: string;
  teacherSignature?: string;
  parentSignature?: string;
  headSignature?: string;
  principalSignature?: string;
  notApprovedReason?: string;
  deleteReason?: string;
  updateReason?: string;
  uuid?: string;
  studentId?: number;
  student?: User;
  teacherId?: number;
  teacher?: User;
  createdAt?: string;
  updatedAt?: string;
}

export interface Fieldtrip {
  id?: number;
  type?: string;
  startAt?: string;
  endAt?: string;
  destination?: string;
  accommodation?: string;
  guideName?: string;
  guidePhone?: string;
  content?: string;
  relationship?: string;
  studentGradeKlass?: string;
  studentNumber?: number;
  fieldtripStatus?: string;
  studentSignature?: string;
  teacherSignature?: string;
  parentSignature?: string;
  principalSignature?: string;
  notApprovedReason?: string;
  deleteReason?: string;
  updateReason?: string;
  uuid?: string;
  student?: User;
  teacher?: User;
  reportedAt?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface FieldtripNotice {
  id?: number;
  type?: string;
  startAt?: string;
  endAt?: string;
  destination?: string;
  accommodation?: string;
  guideName?: string;
  guidePhone?: string;
  content?: string;
  relationship?: string;
  studentGradeKlass?: string;
  studentNumber?: number;
  fieldtripStatus?: string;
  studentSignature?: string;
  teacherSignature?: string;
  parentSignature?: string;
  headSignature?: string;
  principalSignature?: string;
  notApprovedReason?: string;
  deleteReason?: string;
  updateReason?: string;
  uuid?: string;
  student?: User;
  teacher?: User;
  reportedAt?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Board {
  id: number;
  createdAt?: string;
  updatedAt?: string;
  category?: string;
  title?: string;
  content?: string;
  isImage?: boolean;
  isFile?: boolean;
  isHidden?: boolean;
  writer?: User;
  writerId?: number;
  images?: string[];
  files?: string[];
  uuid?: string;
  groupBoards: GroupBoard[];
}

export interface CreateBoardType {
  category?: string;
  title?: string;
  content?: string;
  images?: string[];
  files?: string[];
  targetGroupIds?: number[];
}

export interface UpdateBoardType {
  category?: string;
  title?: string;
  content?: string;
  images?: string[];
  files?: string[];
  targetGroupIds?: number[];
  willRemoveImages?: string[];
  willRemoveFiles?: string[];
}

export interface GroupBoard {
  id: number;
  createdAt?: string;
  updatedAt?: string;
  boardId: number;
  board: Board;
  groupId: number;
  group: Group;
}

export interface Image {
  id: number;
  createdAt: string;
  updatedAt: string;
  key: string;
  fileName: string;
  type: FileType;
  boardId: number;
}

export interface SaveBoardDto {
  title: string;
  content: string;
  targetGroupId: number;
  fileUrls?: string[];
  imageUrls?: string[];
}

export interface PresignedUrlResponse {
  uploadUrl: string;
  fields: {
    bucket: string;
    key: string;
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
  };
}

export interface UploadFileDto {
  file: File;
  presignedUrlResponse: PresignedUrlResponse;
}

export interface CreateAndUploadFileDto {
  fileName: string;
  file: File;
}

export interface CreatePresignedUrlDto {
  fileName: string;
}

export interface GetPaginatedBoards {
  items: Board[];
  total: number;
}

export interface UploadFileType {
  fileUrl: string;
  fileName: string;
}

export type UploadImageType = UploadFileType;

export interface TimetableContent {
  type: string;
  subject: string;
  teacher?: string;
  class?: string;
}

export interface KlassInfo {
  time: string;
  timeCode: string;
  subject: string;
  myClass: boolean;
  name: string;
  semester: number;
  teacherName: string;
}

export interface AttendanceType {
  attendanceDay: string;
  absent: boolean;
  comment: string;
  type1: string;
  type2: string;
  content: string;
  year: number;
  semester: number;
  userId: number;
}

export interface AbsentSave {
  period: number;
  subject: string;
  creator: string;
  createtime: string;
  editor: string;
  edittime: string;
  comment: string;
  absent: boolean;
  type1: string;
  type2: string;
}

export type AbsentDescription = {
  [key: string]: {
    reasonType: string[];
    evidenceFileType: string[];
  };
};

export interface PeriodSubjectTeacher {
  subject: string;
  teacher: string;
  period: number;
  mark: string;
}

export interface ChatRoom {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  expired?: boolean;
  createduserid?: number;
  attendeeid?: number;
  name?: string;
  profile?: string;
  content?: string;
  lastmsgtime?: string;
  msgcnt?: number;
}

export interface Chat {
  chatroomid?: number;
  content?: string;
  createdAt?: string;
  id?: number;
  isread?: boolean;
  receiverid?: number;
  senderid?: number;
  updatedAt?: string;
}
