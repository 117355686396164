import { useState } from 'react';

import { QueryKey } from '../constants/query-key';
import { useNewsLettersControllerFindAll } from '../generated/endpoint';
import { Newsletter, NewsletterCategoryEnum } from '../generated/model';

export const useTeacherNewsletter = () => {
  const [pageIngo, setPageInfo] = useState({ page: 1, limit: 500 });
  const [category, setCategory] = useState<NewsletterCategoryEnum | undefined>(undefined);

  const { data: newsletterList, isLoading } = useNewsLettersControllerFindAll(
    { ...pageIngo, userKlass: [1, 2, 3] },
    {
      query: {
        queryKey: [...QueryKey.teacher.newsletterList],
      },
    },
  );

  const newsletters = category
    ? newsletterList?.items.filter((newsletter: Newsletter) => newsletter.category === category)
    : newsletterList?.items;
  return { newsletters, category, isLoading, setCategory };
};
