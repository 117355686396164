/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type FieldtripStatus = typeof FieldtripStatus[keyof typeof FieldtripStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldtripStatus = {
  RETURNED: 'RETURNED',
  BEFORE_PARENT_CONFIRM: 'BEFORE_PARENT_CONFIRM',
  BEFORE_TEACHER_APPROVAL: 'BEFORE_TEACHER_APPROVAL',
  BEFORE_PREHEAD_APPROVAL: 'BEFORE_PREHEAD_APPROVAL',
  BEFORE_HEAD_APPROVAL: 'BEFORE_HEAD_APPROVAL',
  BEFORE_PREPRINCIPAL_APPROVAL: 'BEFORE_PREPRINCIPAL_APPROVAL',
  BEFORE_PRINCIPAL_APPROVAL: 'BEFORE_PRINCIPAL_APPROVAL',
  BEFORE_VICEPRINCIPAL_APPROVAL: 'BEFORE_VICEPRINCIPAL_APPROVAL',
  BEFORE_HEADPRINCIPAL_APPROVAL: 'BEFORE_HEADPRINCIPAL_APPROVAL',
  PROCESSED: 'PROCESSED',
  DELETE_APPEAL: 'DELETE_APPEAL',
} as const;
