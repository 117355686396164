import { useRef, useState } from 'react';
import { useParams } from 'react-router';

import { jsPDF } from 'jspdf';

import { AbsentUpdatePage } from './AbsentUpdatePage';
import { SuperModal, AbsentPaper, ParentConfirmPaper } from '../../../components';
import { AbsentEvidenceType } from '../../../components/types';
import { Constants } from '../../../constants';
import { useTeacherAbsentDeatil } from '../../../container/teacher-absent-detail';
import { BackButton, Blank, Button, Section, TextArea, TopNavbar } from '../../../reusable';
import { AbsentStatus } from '../../../types';
import {
  makeDateToString,
  makeTimeToString,
  extractReactData,
  extractImageData,
  _getPdfImageSize,
} from '../../../utils';

interface AbsentDetailPageProps {
  refetch: () => void;
  setOpen: (b: boolean) => void;
  setAbsentId: (n: number) => void;
  setAgreeAll: (b: boolean) => void;
  userRole: string | undefined;
  approvalLine?: string[];
}

const calculateStatus = (role: string | undefined, order: 'before' | 'after' | 'show') => {
  const roles = ['TEACHER', 'HEAD', 'PRINCIPAL'];
  const _absentStatus = [
    'BEFORE_TEACHER_APPROVAL',
    'TEACHER',
    'BEFORE_HEAD_APPROVAL',
    'HEAD',
    'BEFORE_PRINCIPAL_APPROVAL',
    'PRINCIPAL',
    'PROCESSED',
  ];

  if (role === 'SECURITY') return ['PROCESSED'];

  const index = _absentStatus.findIndex((el) => el === role);

  if (index === -1) return [];

  if (order === 'before') {
    return _absentStatus.slice(index - 1, index).filter((el) => !roles.includes(el));
  } else if (order === 'after') {
    return _absentStatus.slice(index).filter((el) => !roles.includes(el));
  } else if (order === 'show') {
    return _absentStatus.slice(index - 1).filter((el) => !roles.includes(el));
  }
};

const AbsentDetailPage = ({
  refetch,
  setOpen,
  setAbsentId,
  setAgreeAll,
  userRole,
  approvalLine,
}: AbsentDetailPageProps) => {
  const { id } = useParams<{ id: string }>();
  const ref = useRef(null);
  const parentRef = useRef(null);

  const [changeMode, setChangeMode] = useState(false);
  const [clicked, setClicked] = useState(false);

  const {
    deny,
    setDeny,
    deleteAppeal,
    setDeleteAppeal,
    notApprovedReason,
    setNotApprovedReason,
    deleteReason,
    setDeleteReason,

    absent,
    denyAbsent,
    deleteAppealAbsent,
    isLoading,
  } = useTeacherAbsentDeatil({ id: Number(id), setAbsentId, refetch });

  if (changeMode) {
    return (
      <AbsentUpdatePage
        absentData={absent}
        isConfirmed={absent?.absentStatus === AbsentStatus.PROCESSED}
        refetch={() => refetch()}
        setChangeMode={(b: boolean) => setChangeMode(b)}
      />
    );
  }

  // console.log('userRole', userRole);
  // userRole = 'TEACHER';
  const buttonDisabled = !calculateStatus(userRole, 'before')?.includes(absent?.absentStatus || '');

  return (
    <>
      <div className="bg-white border rounded-lg py-5 relative h-full">
        {isLoading && <Blank reversed />}
        {/* Desktop V */}
        <div className="w-auto mb-24 md:mb-0 md:h-full md:overflow-scroll md:relative">
          <div className="block md:hidden">
            <TopNavbar title="상세보기" left={<BackButton />} />
          </div>
          {absent?.updateReason && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2 m-2">
              <div className="text-brand-1">{absent?.updateReason}</div>
              <div className="text-gray-500 text-sm">
                {makeDateToString(absent?.updatedAt)} {makeTimeToString(absent?.updatedAt)}에
                마지막으로 수정
              </div>
            </div>
          )}
          {absent?.absentStatus === 'RETURNED' && (
            <div className="justify-between flex items-center bg-brand-5 rounded-lg px-5 py-2 m-2">
              <div className="text-brand-1 text-sm">{absent?.notApprovedReason}</div>
              <div className="text-red-500">반려 이유</div>
            </div>
          )}
          <div className="w-full md:w-auto overflow-x-scroll md:overflow-auto">
            <AbsentPaper ref={ref} absent={absent} approvalLine={approvalLine} />
            {absent?.evidenceType === '학부모 확인서' && (
              <ParentConfirmPaper ref={parentRef} absent={absent} />
            )}
          </div>
          {absent?.evidenceFiles &&
            absent.evidenceFiles.map((evidenceFile: string, i: number) => (
              <div
                key={i}
                style={{ width: '560px', height: '792px' }}
                className="bg-white overflow-hidden"
              >
                <img className="object-cover" src={`${Constants.imageUrl}${evidenceFile}`} alt="" />
              </div>
            ))}
        </div>
        <div className="fixed md:absolute w-full bottom-16 md:bottom-0 bg-gray-50">
          <div
            className="block md:sticky bottom-0 -ml-1 md:ml-0"
            style={{ width: 'inherit', maxWidth: 'inherit' }}
          >
            <div className="px-3 my-3 h-16 overflow-y-scroll scroll-box">
              {absent?.evidenceType !== '학부모 확인서' &&
                absent?.evidenceFiles?.map((evidenceFile: string, index) => (
                  <div key={index}>
                    <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <a
                        href={`${Constants.imageUrl}${evidenceFile}`}
                        target="_blank"
                        rel="noreferrer"
                        download={true}
                      >
                        <ul className="border border-gray-200 rounded-md bg-white">
                          <li className="px-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <svg
                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                              >
                                <path d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" />
                              </svg>
                              <span className="ml-2 flex-1 w-0 truncate">
                                {absent?.evidenceType}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <span className="font-medium text-indigo-600 hover:text-indigo-500">
                                Download
                              </span>
                            </div>
                          </li>
                        </ul>
                      </a>
                    </div>
                    <span className="font-semibold text-lg"></span>
                  </div>
                ))}
            </div>
            <div className="flex items-center w-full overflow-x-auto space-x-2 mt-3">
              <div className="hidden md:block w-full min-w-max">
                <Button
                  disabled={clicked}
                  tw={{
                    width: 'w-full',
                    backgroundColor: clicked ? 'bg-gray-400' : 'bg-blue-500',
                    minWidth: 'min-w-max',
                  }}
                  onClick={async () => {
                    setClicked(true);
                    const doc: any = new jsPDF('p', 'mm', 'a4');

                    if (ref?.current) {
                      const absentPdfData = await extractReactData(ref.current);
                      await doc.addImage(absentPdfData, 'PNG', 0, 0, 210, 297);
                    }
                    if (absent?.evidenceType === AbsentEvidenceType.PARENT && parentRef?.current) {
                      const parentPdfData = await extractReactData(parentRef.current);
                      await doc.addPage();
                      await doc.addImage(parentPdfData, 'PNG', 0, 0, 210, 297);
                    }
                    if (
                      absent?.evidenceType !== AbsentEvidenceType.PARENT &&
                      absent?.evidenceFiles?.length
                    ) {
                      for (const ef of absent.evidenceFiles) {
                        const evidenceFile = `${Constants.imageUrl}${ef}`;
                        const imgData = await extractImageData(evidenceFile);
                        if (!imgData) continue;
                        const { width: imageWidth = 0, height: imageHeight = 0, data } = imgData;
                        const [width, height] = _getPdfImageSize(imageWidth, imageHeight);
                        await doc.addPage();
                        await doc.addImage(data, 'JPEG', 0, 0, width, height, undefined, 'FAST');
                      }
                    }

                    await doc.save(`${absent?.reportedAt}-${absent?.student?.name}.pdf`);
                    setClicked(false);
                  }}
                >
                  다운로드
                </Button>
              </div>
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: 'bg-red-500',
                }}
                text="삭제요청"
                onClick={() => {
                  setDeleteAppeal(true);
                }}
              />
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: buttonDisabled ? 'bg-gray-500' : 'bg-green-500',
                }}
                text="반려"
                disabled={buttonDisabled}
                onClick={() => {
                  setDeny(true);
                }}
              />
              {absent?.absentStatus === AbsentStatus.PROCESSED ? (
                <Button
                  tw={{ width: 'w-full', minWidth: 'min-w-max' }}
                  text="승인 후 수정"
                  onClick={() => {
                    setChangeMode(true);
                  }}
                />
              ) : (
                <div className="w-full min-w-max">
                  <Button
                    tw={{
                      width: 'w-full',
                      minWidth: 'min-w-max',
                      backgroundColor: 'bg-indigo-500',
                    }}
                    text="수정"
                    onClick={() => {
                      setChangeMode(true);
                    }}
                  />
                </div>
              )}
              <Button
                tw={{
                  width: 'w-full',
                  minWidth: 'min-w-max',
                  backgroundColor: buttonDisabled ? 'bg-gray-500' : 'bg-brand-1',
                }}
                text={buttonDisabled ? '승인 완료' : '승인'}
                disabled={buttonDisabled}
                onClick={() => {
                  setOpen(true);
                  setAgreeAll(false);
                }}
              />
            </div>
          </div>
        </div>
        <div className="h-24" />
        <SuperModal modalOpen={deny} setModalClose={() => setDeny(false)} width="w-max">
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 학생의 출결신고서를 반려하시겠습니까?
            </div>
            <TextArea
              placeholder="반려 이유"
              onChange={(e) => setNotApprovedReason(e.target.value)}
              value={notApprovedReason}
            />
            <Button
              onClick={() => {
                denyAbsent();
              }}
            >
              반려하기
            </Button>
          </Section>
        </SuperModal>
        <SuperModal
          modalOpen={deleteAppeal}
          setModalClose={() => setDeleteAppeal(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              이 출결신고서를 삭제하도록 요청하시겠습니까?
            </div>
            <TextArea
              placeholder="삭제 이유"
              onChange={(e) => setDeleteReason(e.target.value)}
              value={deleteReason}
            />
            <Button
              tw={{ backgroundColor: 'bg-red-500' }}
              onClick={() => {
                deleteAppealAbsent();
              }}
            >
              삭제 요청하기
            </Button>
          </Section>
        </SuperModal>
      </div>
    </>
  );
};

export default AbsentDetailPage;
