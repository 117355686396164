import moment from 'moment';
import 'moment/locale/ko';

export enum DateFormat {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
}

export const DateUtil = {
  formatDate: (date: string, format: DateFormat = DateFormat['YYYY-MM-DD']) => {
    return moment(date).format(format);
  },
  getYear: () => {
    const now = moment();
    return now.year();
  },
  getSchoolYear: () => {
    const now = moment();
    const month = now.month() + 1;
    const year = now.year();
    return month >= 3 ? year : year - 1;
  },
  getLastSchoolYear: () => {
    const now = moment();
    const month = now.month() + 1;
    const year = now.year();
    return month >= 3 ? year - 1 : year - 2;
  },
};
