import React from 'react';

import undoArrow from '../assets/images/undo-arrow.png';
import { ReactComponent as FileItemIcon } from '../assets/svg/file-item-icon.svg';
import { Constants } from '../constants';
import { DocumentObject } from '../type/document-file-object';
import { getFileNameFromUrl } from '../utils/file-util';

interface DocumentObjectComponentProps {
  id: number;
  documentObjet: DocumentObject;
  onDeleteClick?: (key: number) => void;
}

export const DocumentObjectComponent = ({
  id,
  documentObjet,
  onDeleteClick,
}: DocumentObjectComponentProps) => {
  return (
    <div
      className={`m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden ${
        documentObjet.isDelete ? 'opacity-50' : ''
      }`}
    >
      {typeof documentObjet.document === 'string' ? (
        <>
          <div className="flex space-x-2 items-center">
            <FileItemIcon />
            <div className="overflow-x-hidden whitespace-pre w-full">
              {getFileNameFromUrl(documentObjet.document)}
            </div>
          </div>
          <div className={'flex justify-center items-center'}>
            <div className="bg-white px-2 text-lightpurple-4 min-w-max">
              <a
                href={`${Constants.imageUrl}${documentObjet.document}`}
                target="_blank"
                rel="noreferrer"
                download={getFileNameFromUrl(documentObjet.document)}
              >
                Download
              </a>
            </div>
            {onDeleteClick && (
              <div className="block w-5 h-5 text-sm rounded-full ring-2 text-center ring-white bg-red-700 z-40">
                <div
                  className="text-white cursor-pointer w-full h-full flex items-center justify-center"
                  onClick={() => onDeleteClick(id)}
                >
                  {documentObjet.isDelete ? (
                    <img
                      src={undoArrow}
                      alt=""
                      className="w-3 h-3"
                      style={{ transform: 'translate(0px, 1px)' }}
                    />
                  ) : (
                    'X'
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex space-x-2 items-center">
          <FileItemIcon />
          <div className="overflow-x-hidden whitespace-pre w-full">
            {documentObjet.document.name}
          </div>
        </div>
      )}
    </div>
  );
};
