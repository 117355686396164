import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';

import { Constants } from '../constants';
import { QueryKey } from '../constants/query-key';
import {
  useNewsLettersControllerDelete,
  useNewsLettersControllerFindOne,
  useNewsLettersControllerPublish,
} from '../generated/endpoint';
import { NewsletterCategoryEnum } from '../generated/model';
import { Routes } from '../routes';

export const useTeacherNewsletterDetail = ({ id }: { id: number | undefined }) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [category, setCategory] = useState<NewsletterCategoryEnum | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: newsletter, isLoading: isNewsletterLoading } = useNewsLettersControllerFindOne(
    id as number,
    {
      query: {
        enabled: !!id,
      },
    },
  );

  const { mutate: deleteNewsletterMutate, isLoading: isDeleteNewsletterMutate } =
    useNewsLettersControllerDelete({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.teacher.newsletterList);
          history.push(Routes.teacher.newsletter);
        },
      },
    });

  useNewsLettersControllerPublish(id as number, {
    query: {
      enabled: !!id && isPublish,
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.teacher.newsletterList);
      },
      onError: () => {
        setErrorMessage('발행에 실패했습니다.');
      },
    },
  });

  const isLoading = isNewsletterLoading || isDeleteNewsletterMutate;

  const images = newsletter?.images || [];
  const documents = newsletter?.files || [];
  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    viewerImages.push({
      src: `${Constants.imageUrl}${image}`,
    });
  }

  const handleNewsletterDelete = (id: number) => {
    deleteNewsletterMutate({ id });
  };

  const handleNewsletterPublish = () => {
    setIsPublishModalOpen(false);
    setIsPublish(true);
  };

  return {
    newsletter,
    category,
    isLoading,
    images,
    documents,
    viewerImages,
    isPublishModalOpen,
    errorMessage,
    setCategory,
    setIsPublishModalOpen,
    handleNewsletterDelete,
    handleNewsletterPublish,
  };
};
