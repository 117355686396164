import { useImagesControllerUploadImage } from '../generated/endpoint';
import { UploadFileTypeEnum } from '../generated/model';

export const useFileUpload = () => {
  const { mutateAsync: uploadImageMutate, isLoading: isUploadLoading } =
    useImagesControllerUploadImage({});

  const handleUploadFile = async (key: UploadFileTypeEnum, files: File[]): Promise<string[]> => {
    const fileNames: string[] = [];
    try {
      for await (const file of files) {
        const fileName = await uploadImageMutate({
          uploadFileType: key,
          data: { file },
        });
        fileNames.push(fileName as unknown as string);
      }
    } catch (e) {
      console.log(e);
    }
    return fileNames;
  };

  return { handleUploadFile, isUploadLoading };
};
