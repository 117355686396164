/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type Role = typeof Role[keyof typeof Role];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
  PREHEAD: 'PREHEAD',
  HEAD: 'HEAD',
  PREPRINCIPAL: 'PREPRINCIPAL',
  PRINCIPAL: 'PRINCIPAL',
  VICEPRINCIPAL: 'VICEPRINCIPAL',
  HEADPRINCIPAL: 'HEADPRINCIPAL',
  USER: 'USER',
  SECURITY: 'SECURITY',
  PARENT: 'PARENT',
} as const;
