import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useGroupsControllerDelete,
  useStudentGroupsControllerFindByGroupId,
} from '../generated/endpoint';
import { Routes } from '../routes';
import { GroupContainer } from './group';
import { ResponseGroupDto, StudentGroup } from '../generated/model';

export const useTeacherGroupDetail = (groupId: number | undefined) => {
  const history = useHistory();
  const { refetchGroups, teacherKlubGroups } = GroupContainer.useContext();

  const [group, setGroup] = useState<ResponseGroupDto>();
  const [studentGroups, setStudentGroups] = useState<StudentGroup[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useStudentGroupsControllerFindByGroupId<StudentGroup[]>(groupId as number, {
    query: {
      enabled: !!groupId,
      onSuccess: (res) => {
        if (!res?.length) {
          setStudentGroups([]);
          return;
        }

        setStudentGroups(res.sort((a, b) => a.studentNumber - b.studentNumber));
      },
    },
  });

  useEffect(() => {
    const group = teacherKlubGroups.find((group) => group.id === Number(groupId));
    setGroup(group);
  }, [teacherKlubGroups, groupId]);

  const { mutate: deleteGroup } = useGroupsControllerDelete({
    mutation: {
      onSuccess: () => {
        refetchGroups;
        history.push(Routes.teacher.groups);
      },
      onError: () => {
        setErrorMessage('그룹 삭제에 실패했습니다.');
      },
    },
  });

  const handleGroupDelete = () => {
    deleteGroup({ id: groupId as number });
  };

  return {
    group,
    studentGroups,
    errorMessage,
    handleGroupDelete,
  };
};
