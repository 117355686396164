import { FC } from 'react';

import { useAbsentsControllerDownloadAbsents } from '../../generated/endpoint';
import { Button } from '../../reusable';
import { makeDateToString } from '../../utils';
import { downloadExcel } from '../../utils/download-excel-util';

interface AbsentsExcelDownloadViewProps {
  startDate: string;
  endDate: string;
}

export const AbsentsExcelDownloadView: FC<AbsentsExcelDownloadViewProps> = ({
  startDate,
  endDate,
}) => {
  const { refetch: refetchExcelData } = useAbsentsControllerDownloadAbsents(
    {
      startDate,
      endDate,
    },
    {
      query: {
        enabled: false,
        onSuccess: (data) => {
          downloadExcel(
            data,
            `결석계(${makeDateToString(new Date(startDate))}~${makeDateToString(
              new Date(endDate),
            )})`,
          );
        },
      },
    },
  );

  return (
    <Button
      tw={{
        backgroundColor: 'bg-green-500',
        minWidth: 'min-w-max',
        paddingY: 'py-2.5',
        height: 'h-auto',
        paddingX: 'px-4',
        width: 'w-full',
      }}
      onClick={() => refetchExcelData()}
    >
      Excel
    </Button>
  );
};
