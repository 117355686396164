import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { useFindPassword } from '../../../container/find-password';
import {
  Alert,
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  TopNavbar,
} from '../../../reusable';

interface FindPasswordSuccessPageProps {
  email: string;
}

export const FindPasswordSuccessPage: FC<FindPasswordSuccessPageProps> = ({ email }) => {
  const history = useHistory();

  const { refetch, isLoading, errorMessage, setErrorMessage } = useFindPassword(email);
  return (
    <>
      <TopNavbar
        title="비밀번호 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isLoading && <Blank />}
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Section>
        <h1 className="text-2xl font-bold">
          비밀번호 재설정 <br /> 이메일을 보냈습니다.
        </h1>
        <div className="text-gray-400">
          받은편지함을 확인해 주세요. <br />
          이메일이 오지 않으면, 스팸메일함을 확인해 주시거나 아래 버튼을 눌러 이메일을 다시
          보내주세요.
        </div>
      </Section>
      <BottomFixedView tw={{ paddingBottom: 'pb-20' }}>
        <Section>
          <Button
            tw={{
              backgroundColor: 'bg-white',
              color: 'text-brand-1',
              borderColor: 'border-brand-1',
            }}
            onClick={() => refetch()}
          >
            다시 보내기
          </Button>
          <Button onClick={() => history.push('/')}>홈으로 가기</Button>
        </Section>
      </BottomFixedView>
    </>
  );
};
