import { useState } from 'react';

import { addYears, format } from 'date-fns';

import { UserContainer } from './user';
import {
  useFieldtripsControllerCreate,
  useFieldtripsControllerUpdate,
  useSchedulesControllerFindRejectSchedule,
} from '../generated/endpoint';
import { Fieldtrip, FieldtripType } from '../generated/model';
import { usePrevious } from '../reusable';

type Props<T> = {
  startAt: Date | null;
  endAt: Date | null;
  clearSignature: () => void;
  sigPadData: string;
  selectOptions: string[];
  fieldtripData?: Fieldtrip;
  returnToDetail?: () => void;
  params: T;
};

enum ModeState {
  create = 'create',
  update = 'update',
}

export const useStudentFieldtripAddSuburbs = <T extends { [key: string]: string }>({
  fieldtripData,
  startAt,
  endAt,
  clearSignature,
  sigPadData,
  returnToDetail,
  selectOptions,
  params,
}: Props<T>) => {
  const matchParamsType = params?.type;
  const { me } = UserContainer.useContext();
  // Form State
  const [success, setSuccess] = useState<number>();
  const [content, setContent] = useState(fieldtripData?.content || '');
  const [accommodation, setAccommodation] = useState(
    matchParamsType?.toUpperCase() === FieldtripType.HOME
      ? '자택'
      : fieldtripData?.accommodation || '',
  );
  const [agree, setAgree] = useState(false);
  const [destination, setDestination] = useState(
    matchParamsType?.toUpperCase() === FieldtripType.HOME
      ? '자택'
      : fieldtripData?.destination || '',
  );
  const [purpose, setPurpose] = useState(fieldtripData?.purpose || '');
  const [guideName, setGuideName] = useState(fieldtripData?.guideName || '');
  const [guidePhone, setGuidePhone] = useState(fieldtripData?.guidePhone || '');
  const [parentsName, setParentsName] = useState(me?.nokName || '');
  const [parentsPhone, setParentsPhone] = useState(me?.nokPhone || '');
  const [usedDays, setUsedDays] = useState(fieldtripData?.usedDays || 0);
  const prevUsedDays = usePrevious(usedDays);
  const [relationship, setRelationship] = useState(fieldtripData?.relationship || '');
  const [suburbsModalopen, setSuburbsModalopen] = useState(false);
  const [homeModalopen, setHomeModalopen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [homePlan, setHomePlan] = useState<any>(
    fieldtripData?.type === FieldtripType.HOME ? JSON.parse(fieldtripData?.content || '[]') : [],
  );
  const [selectOption, setSelectOption] = useState(selectOptions[0]);

  const [isOpenSignModal, setIsOpenSignModal] = useState(false);
  const {
    data: cannotSchedules,
    error,
    isLoading: isGetRejectScheduleLoading,
  } = useSchedulesControllerFindRejectSchedule({
    startDate: startAt ? format(startAt, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
    endDate: format(addYears(new Date(), 1), 'yyyy-MM-dd'),
  });

  const { mutate: createFieldtripMutate, isLoading: isCreateFieldtripLoading } =
    useFieldtripsControllerCreate({
      mutation: {
        onSuccess: (data) => {
          hideModal();
          alert('체험학습 신청서가 제출되었습니다.');
          clearSignature();
          setSuccess(data.id);
        },
        onError: (error) => {
          hideModal();
          clearSignature();
          setErrorMessage(error.message);
        },
      },
    });

  const { mutate: updateFieldtripMutate, isLoading: isUpdateFieldtripLoading } =
    useFieldtripsControllerUpdate({
      mutation: {
        onSuccess: () => {
          hideModal();
          alert('체험학습 신청서를 수정하였습니다.');
          clearSignature();
          returnToDetail?.();
        },
        onError: (error) => {
          hideModal();
          clearSignature();
          setErrorMessage(error.message);
        },
      },
    });

  const hideModal = () => {
    setIsOpenSignModal(false);
  };

  const openModal = () => {
    setIsOpenSignModal(true);
  };

  const getTypeFromParams = () => {
    return matchParamsType === FieldtripType.SUBURBS.toLowerCase()
      ? FieldtripType.SUBURBS
      : FieldtripType.HOME;
  };

  // 신청서 생성
  const createFieldtrip = () => {
    if (!startAt || !endAt) return;

    createFieldtripMutate({
      data: {
        type: getTypeFromParams(),
        content:
          matchParamsType?.toUpperCase() === FieldtripType.HOME
            ? JSON.stringify(homePlan)
            : content,
        accommodation,
        destination,
        guideName,
        guidePhone,
        parentsName,
        parentsPhone,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        relationship,
        form: selectOption,
        purpose,
        usedDays,
        studentSignature: sigPadData,
      },
    });
  };

  // 신청서 수정
  const updateFieldtrip = () => {
    if (!fieldtripData || !startAt || !endAt) return;

    updateFieldtripMutate({
      id: fieldtripData.id,
      data: {
        type: fieldtripData.type,
        content: fieldtripData?.type === FieldtripType.HOME ? JSON.stringify(homePlan) : content,
        accommodation,
        destination,
        guideName,
        guidePhone,
        parentsName,
        parentsPhone,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        relationship,
        form: selectOption,
        purpose,
        usedDays,
        studentSignature: sigPadData,
      },
    });
  };

  const isHomePlanType = (): boolean => {
    const mode = params.type ? ModeState.create : ModeState.update; // mode: 생성하기, 수정하기 모드
    // 생성하기 페이지인 경우 params.type이 home인지 확인
    return mode === ModeState.create
      ? matchParamsType === 'home'
      : fieldtripData?.type === FieldtripType.HOME;
  };

  const isLoading =
    isGetRejectScheduleLoading || isCreateFieldtripLoading || isUpdateFieldtripLoading;

  return {
    cannotSchedules,
    error,
    isLoading,
    openModal,
    hideModal,
    isOpenSignModal,
    updateFieldtrip,
    createFieldtrip,
    prevUsedDays,
    isHomePlanType,
    setState: {
      setPurpose,
      setContent,
      setAccommodation,
      setAgree,
      setDestination,
      setGuideName,
      setGuidePhone,
      setParentsName,
      setParentsPhone,
      setUsedDays,
      setRelationship,
      setSuburbsModalopen,
      setHomeModalopen,
      setHomePlan,
      setSelectOption,
    },
    state: {
      purpose,
      content,
      accommodation,
      agree,
      destination,
      guideName,
      guidePhone,
      parentsName,
      parentsPhone,
      usedDays,
      relationship,
      suburbsModalopen,
      homeModalopen,
      homePlan,
      success,
      errorMessage,
    },
  };
};
