import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';
import { TextField } from './TextField';

interface PhoneNumberFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneNumberField: FC<PhoneNumberFieldProps> = ({
  label,
  onChange,
  value,
  ...props
}) => {
  const [id] = useId(1, 'phonenumber');
  const [number1, setNumber1] = useState<string>('');
  const [number2, setNumber2] = useState<string>('');
  const [number3, setNumber3] = useState<string>('');

  useEffect(() => {
    if (!number1 && !number2 && !number3 && value) {
      setNumber1(value.substring(0, 3));
      setNumber2(value.substring(3, 7));
      setNumber3(value.substring(7, 11));
    }
  }, [value]);

  return (
    <div>
      {label && <Label htmlFor={id} text={label} />}
      <div className="grid grid-cols-3 gap-4 pb-4">
        <TextField
          id={id}
          type="tel"
          pattern="[0-9]{3}"
          maxLength={3}
          placeholder={'010'}
          value={number1}
          onChange={(e) => {
            setNumber1(e.target.value);
            e.target.value = `${e.target.value}${number2}${number3}`;
            onChange?.(e);
          }}
          {...props}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={'0000'}
          value={number2}
          onChange={(e) => {
            setNumber2(e.target.value);
            e.target.value = `${number1}${e.target.value}${number3}`;
            onChange?.(e);
          }}
          {...props}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={'0000'}
          value={number3}
          onChange={(e) => {
            setNumber3(e.target.value);
            e.target.value = `${number1}${number2}${e.target.value}`;
            onChange?.(e);
          }}
          {...props}
        />
      </div>
    </div>
  );
};
