import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { isWeekday } from '../../utils';
import { getDay, isToday } from 'date-fns';

interface FieldtripDatePickerProps {
  disabled?: boolean;
  selectedDate: Date | null;
  excludeDates: Date[];
  minDate?: Date | null;
  maxDate?: Date | null;
  placeholderText: string;
  onChange: (date: Date | null) => void;
}

export function FieldtripDatePicker({
  disabled,
  selectedDate,
  excludeDates,
  minDate,
  maxDate,
  placeholderText,
  onChange,
}: FieldtripDatePickerProps) {
  const CustomInput = (props: any) => {
    return (
      <input
        className="px-4 py-3 border
      border-gray-200 rounded-md sm:text-sm
        focus:ring-0 focus:border-brand-1 placeholder-gray-400
        disabled:bg-gray-100 disabled:text-gray-400 flex flex-1 w-full"
        disabled={props.disabled}
        placeholder={props.placeholder}
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
      />
    );
  };
  return (
    <DatePicker
      disabled={!!disabled}
      locale={ko}
      dateFormat="yyyy-MM-dd"
      wrapperClassName="flex flex-1 mr-4"
      minDate={minDate}
      maxDate={maxDate}
      filterDate={isWeekday}
      closeOnScroll={true}
      placeholderText={placeholderText}
      selected={selectedDate}
      excludeDates={excludeDates}
      onChange={onChange}
      customInput={<CustomInput />}
      dayClassName={(date) => {
        const day = getDay(date);
        if (isToday(date)) {
          return '';
        }
        if (day === 0) {
          return 'text-red-500 text-opacity-50';
        }
        if (day === 6) {
          return 'text-blue-500 text-opacity-50';
        }
        return '';
      }}
    />
  );
}
