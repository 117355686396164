import { useState } from 'react';
import { Link } from 'react-router-dom';

//@ts-ignore
import preval from 'preval.macro';
import { useRecoilState } from 'recoil';

import { UserContainer } from '../../../container/user';
import { BackButton, Blank, Button, Section, TextField, TopNavbar } from '../../../reusable';
import { isStayLoggedInState } from '../../../store';
import { globalEnv } from '../../../utils/global-env';

export const Login = () => {
  const [isStayLoggedIn, setIsStayLoggedIn] = useRecoilState(isStayLoggedInState);
  const { handleLogin, errorMessage } = UserContainer.useContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [blankOpen, setBlankOpen] = useState(false);

  const spacere = / /g;

  const handleStayLoggedIn = () => {
    setIsStayLoggedIn((prevState) => !prevState);
  };

  return (
    <div className="flex items-center justify-center w-full box-border">
      <div className="max-w-xl w-full">
        {blankOpen && <Blank />}
        <TopNavbar
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
          title="로그인"
          right={
            <div
              className="text-brand-1 text-sm"
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
            >
              새로고침
            </div>
          }
        />
        <Section>
          <div className="w-full">
            <TextField
              id="email"
              label="이메일주소"
              placeholder="이메일주소를 입력해주세요"
              value={email}
              onChange={(e) => setEmail(String(e.target.value).replace(spacere, ''))}
            />
          </div>

          <div className="w-full mt-6">
            <TextField
              label="비밀번호"
              type="password"
              id="password"
              placeholder="비밀번호를 입력해주세요"
              value={password}
              onChange={(e) => setPassword(String(e.target.value).replace(spacere, ''))}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin(email, password);
                }
              }}
            />
          </div>

          <label>
            <input type="checkbox" onChange={handleStayLoggedIn} checked={isStayLoggedIn} />
            <span className={'ml-2'}>자동 로그인</span>
          </label>

          <div className="w-full mt-6">
            <Link to="/find-password">
              <div className="text-gray-4 underline">비밀번호를 잃어버리셨나요?</div>
            </Link>
            <Link to="/find-id">
              <div className="text-gray-4 mt-1">이메일 찾기</div>
            </Link>
          </div>

          <div className="text-red-600">{errorMessage}</div>

          <Button
            disabled={!email || !password}
            tw={{
              backgroundColor: !email || !password ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={() => {
              handleLogin(email, password);
            }}
          >
            로그인
          </Button>

          <a href="https://superstudy.channel.io/lounge" target="blank">
            <div className="text-brand-1 bg-white rounded-lg border-brand-1 p-5 text-center ">
              문의하기
            </div>
          </a>
        </Section>

        <div className="fixed bottom-3 w-full text-center text-gray-600 left-0 right-0">
          슈퍼스쿨 v{globalEnv.version} <br />
          build at {preval`module.exports = new Date().toLocaleString().split("├")[0]`}
        </div>
      </div>
    </div>
  );
};
