import { useTimetablesControllerFindOne } from '../generated/endpoint';
import { useRecoilValue } from 'recoil';
import { meStateNew } from '../store';
import { useEffect } from 'react';

export const useTeacherTimetableDetail = ({
  year,
  semester,
  groupId,
}: {
  year: number;
  semester: number;
  groupId: number;
}) => {
  const meRecoil = useRecoilValue(meStateNew);

  const {
    data: timetable,
    refetch: refetchTimetable,
    isLoading: isLoadingTimetable,
    error: errorTimetable,
  } = useTimetablesControllerFindOne(year, semester, groupId, {
    query: { enabled: !!year && !!semester && !!groupId },
  });

  useEffect(() => {
    refetchTimetable();
  }, [year, semester, groupId]);

  return {
    canEditTimetable: !!meRecoil?.canEditTimetable,
    timetable,
    refetchTimetable,
    errorTimetable,
    isLoadingTimetable,
  };
};
