import { Button, Divider, Section, useRecoilValue } from '../../../reusable';
import React, { FC, useState } from 'react';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import Viewer from 'react-viewer';
import { Canteen } from '../../../types';
import { meStateNew } from '../../../store';
import { Constants } from '../../../constants';

interface CanteenDetailPageProps {
  selectedDate: Date;
  canteen?: Canteen;
  setSubmitState: () => void;
}

export const CanteenDetailPage: FC<CanteenDetailPageProps> = ({
  selectedDate,
  canteen,
  setSubmitState,
}) => {
  const me = useRecoilValue(meStateNew);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  return (
    <>
      <div className="w-full h-screen-4.5 overflow-y-scroll scroll-box relative bg-white border-l border-grey-6">
        <div className="relative w-full py-4 px-6 border-b border-grey-6 flex items-center justify-between">
          <div className="font-bold text-xl">
            {selectedDate?.getFullYear()}년 {selectedDate?.getMonth() + 1}월{' '}
            {selectedDate?.getDate()}일
          </div>
          {me?.canEditCanteen && <Button onClick={() => setSubmitState()}> 수정</Button>}
        </div>
        <Section tw={{ marginBottom: 'mb-6' }}>
          <div className="w-full" onClick={() => setImageModalOpen(true)}>
            <div className={`relative pb-3/5 rounded bg-gray-50`}>
              {canteen?.image ? (
                <ExifOrientationImg
                  className={`absolute w-full h-full rounded object-cover`}
                  src={`${Constants.imageUrl}${canteen.image}`}
                  alt=""
                />
              ) : (
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                    <div className="text-brand-1">이미지 없음</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="absolute">
            {canteen?.image && (
              <Viewer
                visible={isImageModalOpen}
                rotatable={true}
                noImgDetails={true}
                //drag={false}
                scalable={false}
                images={[
                  {
                    src: canteen.image,
                    alt: '',
                  },
                ]}
                onClose={() => setImageModalOpen(false)}
              />
            )}
          </div>
          <div className="flex items-start space-x-2">
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">중식</div>
              <div className="whitespace-pre-line">{canteen?.lunch}</div>
            </div>
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">{/* 석식 */}</div>
              <div className="whitespace-pre-line">{canteen?.dinner}</div>
            </div>
          </div>
        </Section>
        <Divider />
      </div>
    </>
  );
};
