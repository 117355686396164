import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { RouteComponentProps } from 'react-router-dom';
import Viewer from 'react-viewer';

import { Constants } from '../../../constants';
import { useStudentNoticeDetail } from '../../../container/student-notice-detail';
import { BackButton, Blank, Section, TopNavbar, utcToLocalDatetime } from '../../../reusable';
import { getFileNameFromUrl } from '../../../utils/file-util';

interface MatchParams {
  id: string;
}

export const NoticeDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const { id } = match.params;
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { notice, isNoticeLoading, images, files, viewerImages } = useStudentNoticeDetail(+id);

  return (
    <div>
      {isNoticeLoading && <Blank />}
      <TopNavbar
        title="공지사항"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <Section>
        <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
          학교 공지
        </div>
        <div>
          <div className="text-xl font-bold text-grey-1">{notice?.title}</div>
          <div className="text-grey-5 text-sm mb-2">
            {utcToLocalDatetime(notice?.createdAt?.toString() || '')}
          </div>
        </div>
        <Section>
          {images?.map((image: string, i: number) => (
            <>
              <div
                className="w-full"
                onClick={() => {
                  setActiveIndex(i);
                  setImagesModalOpen(true);
                }}
              >
                <div className={`relative pb-3/5 rounded bg-gray-50`}>
                  <LazyLoadImage
                    src={`${Constants.imageUrl}${image}`}
                    alt={''}
                    loading={'lazy'}
                    className={`absolute w-full h-full rounded object-cover`}
                  />
                </div>
              </div>
            </>
          ))}
        </Section>
        <div>
          {files?.map((fileUrl: string, index) => (
            <div
              key={index}
              className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
            >
              <span>{getFileNameFromUrl(fileUrl)}</span>
              <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                <a
                  href={`${Constants.imageUrl}${fileUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  download={getFileNameFromUrl(fileUrl)}
                >
                  Download
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className={`text-grey-2 break-words whitespace-pre-line feedback_space`}>
          <Linkify>{notice?.content}</Linkify>
        </div>

        <div className="absolute">
          <Viewer
            visible={hasImagesModalOpen}
            rotatable={true}
            noImgDetails={true}
            scalable={false}
            images={viewerImages}
            onClose={() => {
              setImagesModalOpen(false);
            }}
            activeIndex={activeIndex}
          />
        </div>
      </Section>
    </div>
  );
};
