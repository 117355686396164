import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextArea,
  TopNavbar,
  useMutation,
} from '../../../reusable';
import {
  StudentInfoCard,
  ParentInfoCard,
  GroupInfoCard,
  CoulselingCard,
  TimeTableCard,
} from '../../../components';
import { fetcher } from '../../../plugins';

interface StudentCardDetailPageProps {
  id: string;
  groupId: string | undefined;
}

// interface StudentCardDetailPageProps extends RouteComponentProps<MatchParams> {
//   refetch: () => void;
// }

export const StudentCardDetailPage: React.FC<StudentCardDetailPageProps> = ({ id, groupId }) => {
  // const { id } = match.params;

  return (
    <>
      <div className="relative h-full">
        {/* Desktop V */}
        <div className="w-auto h-full relative bg-gray">
          <div className="block md:hidden">
            <TopNavbar title="학생카드" left={<BackButton />} />
          </div>
          <div className="p-4 pb-20">
            <StudentInfoCard studentId={id} />
            <ParentInfoCard studentId={id} />
            <GroupInfoCard studentId={id} />
            <CoulselingCard studentId={id} />
            {/* <TimeTableCard studentId={id} groupId={groupId} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

//export default withRouter(StudentCardDetailPage);
