export const Routes = {
  fieldTrip: {
    result: '/fieldtrip/result/approve/:uuid',
    approve: '/fieldtrip/approve/:uuid',
    parent: '/fieldtrip/parent/notice/:uuid',
  },
  studentNewsletter: {
    approve: '/studentnewsletter/approve/:uuid',
  },
  absent: {
    approve: '/absent/approve/:uuid',
  },
  student: {
    info: '/student/info',
    notice: '/student/notice/:id',
    activity: '/student/activity/:id',
  },
  parent: '/parent-signup',
  auth: {
    student: '/student',
    teacher: '/teacher',
    parent: {
      addChild: '/add-child/:uuid',
    },
    firstLogin: '/first-login',
    login: '/login',
  },
  password: {
    reset: '/reset-password/:id',
    find: '/find-password',
  },
  findId: '/find-id',
  home: '/',
  teacher: {
    canteen: '/teacher/canteen',
    timetable: '/teacher/timetable',
    v2TimeTable: '/teacher/v2timetable',
    attendance: '/teacher/attendance',
    absent: '/teacher/absent',
    update: '/teacher/update',
    firstLogin: '/teacher/first-login',
    fieldTrip: {
      notice: '/teacher/fieldtrip/notice',
      result: '/teacher/fieldtrip/result',
      index: '/teacher/fieldtrip',
    },
    board: '/teacher/board',
    calendar: '/teacher/calendar',
    activity: {
      index: '/teacher/activity',
      detail: '/teacher/activity/:id',
    },
    record: '/teacher/record',
    outing: '/teacher/outing',
    manager: '/teacher/manager',
    groups: '/teacher/groups',
    notice: '/teacher/notice',
    newsletter: '/teacher/newsletter',
    myPage: '/teacher/mypage',
    login: '/teacher/login',
    index: '/teacher',
  },
} as const;
