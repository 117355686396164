import { useTimetablesV2ControllerFindOne } from '../generated/endpoint';

export const useTeacherSeatPosition = ({
  year,
  semester,
  klassId,
}: {
  year: number;
  semester: number;
  klassId: number | undefined;
}) => {
  const {
    data: timetable,
    isLoading: isSeatPositionLoading,
    error: errorSeatPosition,
    refetch: refetchSeatPosition,
  } = useTimetablesV2ControllerFindOne(year, semester, klassId as number, {
    query: {
      queryKey: ['seat-position', year, semester, klassId],
      enabled: !!klassId,
    },
  });

  const seatPositionJson = timetable?.seatPosition || '';
  const seatPosition: { studentid: number; seat: string }[] = seatPositionJson
    ? JSON.parse(seatPositionJson)
    : [];

  return {
    seatPositionId: timetable?.id,
    seatPosition,
    isSeatPositionLoading,
    errorSeatPosition,
    refetchSeatPosition,
  };
};
