import { useEffect, useState } from 'react';

import readXlsxFile, { Row } from 'read-excel-file';

import { useNewsLettersControllerDownloadSubmitters } from '../generated/endpoint';
import { downloadExcel } from '../utils/download-excel-util';

export const useTeacherNewsletterDownload = ({
  newsletterId,
  surveyTitle,
}: {
  newsletterId: number;
  surveyTitle: string | undefined;
}) => {
  const [rows, setRows] = useState<Row[]>();
  const { data: excelData } = useNewsLettersControllerDownloadSubmitters(newsletterId);

  const download = () => {
    const filename = surveyTitle || '가정통신문';
    excelData && downloadExcel(excelData, filename);
  };

  useEffect(() => {
    if (excelData) {
      new Promise((r) => r(excelData))
        .then((blob) => readXlsxFile(blob as Blob))
        .then((rows) => setRows(rows))
        .catch((e) => console.log(e));
    }
  }, [newsletterId, excelData]);

  return { download, rows };
};
