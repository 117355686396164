import { useEffect, useState } from 'react';

import { BoardItem, NewsletterItem, NoticeItem } from '../../../components';
import { useStudentNotice } from '../../../container/student-notice';
import { Board, Newsletter, Notice } from '../../../generated/model';
import { Blank, List, Tab, Tabs, TopNavbar } from '../../../reusable';
import { TabType } from '../../../types';

// COMPLETE
export const NoticePage = () => {
  const [tabType, setTabType] = useState<TabType>(TabType.NOTICE);
  const [blankOpen, setBlankOpen] = useState(false);

  const { noticeList, newsLetterList, boardList, isLoading } = useStudentNotice(tabType);

  useEffect(() => {
    const getLocal = localStorage.getItem('tabType');
    if (!getLocal) {
      return;
    } else if (getLocal === 'BOARD') {
      setTabType(TabType.BOARD);
    } else if (getLocal === 'NOTICE') {
      setTabType(TabType.NOTICE);
    } else if (getLocal === 'NEWSLETTER') {
      setTabType(TabType.NEWSLETTER);
    }
  }, [tabType]);

  return (
    <>
      {isLoading && <Blank />}
      {blankOpen && <Blank />}

      <TopNavbar
        title="공지"
        left={<div className="w-10 h-15" />}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />

      <Tabs>
        <Tab
          active={tabType === TabType.NOTICE}
          text="공지사항"
          full
          onClick={() => {
            setTabType(TabType.NOTICE);
            localStorage.setItem('tabType', TabType.NOTICE);
          }}
        />
        <Tab
          active={tabType === TabType.BOARD}
          text="학급 게시판"
          full
          onClick={() => {
            setTabType(TabType.BOARD);
            localStorage.setItem('tabType', TabType.BOARD);
          }}
        />
        <Tab
          active={tabType === TabType.NEWSLETTER}
          text="가정통신문"
          full
          onClick={() => {
            setTabType(TabType.NEWSLETTER);
            localStorage.setItem('tabType', TabType.NEWSLETTER);
          }}
        />
      </Tabs>

      {tabType === TabType.NOTICE && (
        <div className="w-full flex-col space-y-2">
          <div className="whitespace-pre-line">
            <List>
              {noticeList?.map((notice: Notice) => (
                <NoticeItem key={notice.id} notice={notice} />
              ))}
            </List>
          </div>
        </div>
      )}

      {tabType === TabType.NEWSLETTER && (
        <div className="w-full flex-col space-y-2">
          <div className="whitespace-pre-line">
            <List>
              {newsLetterList?.map((newsletter: Newsletter) => (
                <NewsletterItem key={newsletter.id} newsletter={newsletter} />
              ))}
            </List>
          </div>
        </div>
      )}

      {tabType === TabType.BOARD && (
        <div className="w-full flex-col space-y-2">
          <div className="whitespace-pre-line">
            <List>
              {boardList?.map((board: Board) => (
                <BoardItem key={board.id} board={board} />
              ))}
            </List>
          </div>
        </div>
      )}
    </>
  );
};
