import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Section } from '../../../reusable';
import { ErrorBlank, SuperModal } from '../../../components';
import { useParams } from 'react-router';
import { GroupType } from '../../../generated/model';
import { useTeacherGroupDetail } from '../../../container/teacher-group-detail';
import { GroupAddPage } from './GroupAddPage';

const GroupDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const { group, studentGroups, errorMessage, handleGroupDelete } = useTeacherGroupDetail(+id);

  const [updateState, setUpdateState] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  if (errorMessage) return <ErrorBlank />;

  if (updateState) {
    return (
      <GroupAddPage
        groupData={group}
        groupStudentsData={studentGroups.map((el) => el.user)}
        onSubmit={() => {
          setUpdateState(false);
        }}
      />
    );
  }

  return (
    <div className="p-4 h-screen overflow-auto bg-gray-50">
      <SuperModal
        modalOpen={isDeleteModalOpen}
        setModalClose={() => setDeleteModalOpen(false)}
        width="w-max"
      >
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 그룹을 삭제하면 이 그룹에 올라온 {group?.activityCount} 개의 활동기록도 같이
            삭제됩니다. <br />
            정말 선택된 그룹을 삭제하시겠습니까?
          </div>
          <Button onClick={() => handleGroupDelete()}>삭제하기</Button>
        </Section>
      </SuperModal>
      <div className="py-6 px-3 flex items-center justify-between">
        <div className="text-xl font-semibold">{group?.name}</div>
        <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
          <div className="text-gray-700" onClick={() => setUpdateState(true)}>
            수정
          </div>
          {/* <div
            className="text-red-400 cursor-pointer"
            onClick={() => setDeleteModalOpen(true)}
          >
            삭제
          </div> */}
        </div>
      </div>
      <div className="w-full grid grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 pr-4">
        {studentGroups.map((student) => (
          <div className="flex items-center space-x-2 py-3 px-3 w-full border-2 border-grey-6 m-2 rounded-lg cursor-pointer ">
            {group?.type === GroupType.KLASS && (
              <div className="font-bold">{student.studentNumber}</div>
            )}
            <div className="font-base overflow-hidden whitespace-pre">{student.user.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(GroupDetailPage);
