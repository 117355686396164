import {
  useNewsLettersControllerFindOne,
  useStudentNewsletterControllerFindOneByStudent,
} from '../generated/endpoint';

export const useStudentNewsletterDetail = (id: number | undefined) => {
  const { data: newsletter, isLoading: isNewsletterLoading } = useNewsLettersControllerFindOne(
    id as number,
    {
      query: {
        queryKey: ['newsletter', id],
        enabled: !!id,
      },
    },
  );

  const { data: studentNewsletter, isLoading: isStudentNewsletterLoading } =
    useStudentNewsletterControllerFindOneByStudent(id as number, {
      query: {
        queryKey: ['studentNewsletter', id],
        enabled: !!id,
      },
    });

  const isLoading = isNewsletterLoading || isStudentNewsletterLoading;

  return { newsletter, studentNewsletter, isLoading };
};
