import { FC } from 'react';
import { useLocation } from 'react-router';

import { ResponseActivitySubmitUserDto } from '../../generated/model';

interface SubmitterItemProps {
  user?: ResponseActivitySubmitUserDto;
  onClick: () => void;
  id: string;
}

export const SubmitterItem: FC<SubmitterItemProps> = ({ user, onClick, id }) => {
  const { pathname } = useLocation();
  return (
    <div className="min-w-1/2-2 p-1 text-center inline-block cursor-pointer" onClick={onClick}>
      <div
        className={
          user?.studentActivityId
            ? pathname.includes(
                `/teacher/activity/submit/${id}/${user.studentActivityId}`, // id도 response에 있어서 필요없음
              )
              ? 'border border-brand-1 bg-light_orange rounded-md p-2 flex items-center justify-between space-x-2'
              : 'border rounded-md p-2 flex items-center justify-between space-x-2'
            : 'border rounded-md p-2 flex items-center justify-between space-x-2'
        }
      >
        {user?.studentActivitySubmitted ? (
          <>
            <div className="flex items-center">
              <span className="bg-brand-1 px-2 py-1 text-white rounded-md text-sm">제출</span>
              <div className="ml-2 flex space-x-2">
                <span className="font-semibold">{user.studentGradeKlassNumber}</span>
                <span>{user.studentName}</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center cursor-pointer">
            <span
              className={
                pathname.startsWith(`/teacher/activity/submit/${id}/${user?.studentActivityId}`)
                  ? 'bg-gray-100 px-2 py-1 border border-gray-200 text-gray-500 rounded-md text-sm'
                  : 'bg-gray-100 px-2 py-1 text-gray-500 rounded-md text-sm'
              }
            >
              미제출
            </span>
            <div className="ml-2 flex space-x-2">
              <span className="font-semibold">{user?.studentGradeKlassNumber}</span>
              <span>{user?.studentName}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
