import React, { useState } from 'react';

import { SuperModal, SelectValues } from '../../../components';
import { useTeacherOutingUpdate } from '../../../container/teacher-outing-update';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TextArea,
  TextField,
  TopNavbar,
} from '../../../reusable';

interface OutingUpdatePageProps {
  outingData: any;
  setChangeMode: (b: boolean) => void;
  refetch: () => void;
  isConfirmed: boolean;
}
const reportType = ['조퇴', '외출', '확인'];

export const OutingUpdatePage: React.FC<OutingUpdatePageProps> = ({
  outingData,
  setChangeMode,
  refetch,
  isConfirmed,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    updateReason,
    setUpdateReason,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    report,
    setReport,
    reason,
    setReason,
    errorMessage,
    setErrorMessage,
    isLoading,
    updateOuting,
  } = useTeacherOutingUpdate({
    outingData,
    setChangeMode,
    refetch,
  });

  return (
    <>
      {isLoading && <Blank />}
      <TopNavbar title="조퇴 / 외출 / 확인 신청" left={<BackButton />} />
      <Section>
        <div className="w-full">
          <TextField
            label="학생 이름(본인)"
            id="studentName"
            value={outingData?.student?.name}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <TextField
            label="학생 학년/반/번호"
            value={`${outingData?.studentGradeKlass} ${outingData?.studentNumber}번`}
            tw={{ backgroundColor: 'bg-gray-100' }}
            readOnly
          />
        </div>
        <div className="w-full">
          <SelectValues
            label="*유형"
            placeholder="유형을 선택해주세요."
            selectValues={reportType}
            value={report}
            onChange={(group: any) => {
              setReport(group);
              if (group === '외출') {
                setStartHour(12);
                setStartMinute(50);
                setEndHour(1);
                setEndMinute(50);
              }
            }}
          />
        </div>
        <div className="w-full pb-6">
          <label className="mb-1 text-sm text-gray-800">*기간</label>
          <div className="space-y-3 pb-6">
            <div>
              <input
                type="date"
                value={startAt}
                className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                onChange={(e) => setStartAt(e.target.value)}
              />
            </div>
            <div className="flex items-center space-x-2">
              <select
                value={startHour}
                onChange={(e) => setStartHour(Number(e.target.value))}
                className="px-4 w-16 h-12 border
               border-gray-200 rounded-md sm:text-sm
               focus:ring-0 focus:border-brand-1 placeholder-gray-400
               disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((item: any, num: number) => (
                  <option value={num}>{num}</option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border
        border-gray-200 rounded-md sm:text-sm
        focus:ring-0 focus:border-brand-1 placeholder-gray-400
        disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => setStartMinute(Number(e.target.value))}
                value={startMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 부터</span>
            </div>
            <div className="flex items-center space-x-2">
              {report === '확인' && (
                <div>
                  <input
                    type="date"
                    lang="ko-KR"
                    value={endAt}
                    className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400"
                    onChange={(e) => setEndAt(e.target.value)}
                  />
                </div>
              )}
              <select
                value={endHour}
                onChange={(e) => setEndHour(Number(e.target.value))}
                className="px-4 w-16 h-12 border
               border-gray-200 rounded-md sm:text-sm
               focus:ring-0 focus:border-brand-1 placeholder-gray-400
               disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
              >
                {new Array(24).fill(null).map((item: any, num: number) => (
                  <option value={num}>{num}</option>
                ))}
              </select>
              <span>시</span>
              <select
                className="px-4 w-16 h-12 border
        border-gray-200 rounded-md sm:text-sm
        focus:ring-0 focus:border-brand-1 placeholder-gray-400
        disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                onChange={(e) => setEndMinute(Number(e.target.value))}
                value={endMinute}
              >
                <option value={0}>0</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
              </select>
              <span>분 까지</span>
            </div>
          </div>
        </div>
        <div className="w-full">
          <TextField
            label="*사유"
            id="reason"
            placeholder="조퇴/외출/확인 사유를 자세하게 입력해주세요"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        <Button
          disabled={!reason || !report || !startAt}
          tw={{
            backgroundColor: !reason || !report || !startAt ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          제출하기
        </Button>
      </Section>
      <SuperModal modalOpen={modalOpen} setModalClose={() => setModalOpen(false)} width="w-max">
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 조퇴/외출/확인증을 승인 후 수정하시는 이유를 적어주세요.
          </div>
          <TextArea
            placeholder="수정 이유"
            onChange={(e) => setUpdateReason(e.target.value)}
            value={updateReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              updateOuting();
            }}
          >
            승인 후 수정하기
          </Button>
        </Section>
      </SuperModal>
    </>
  );
};
