import { AbsentStatus, UserRole } from '../types';

export const getStatusByOrder = (
  role: string,
  order: 'before' | 'after' | 'show',
  approvalLine: UserRole[] = [UserRole.TEACHER, UserRole.HEAD, UserRole.PRINCIPAL],
) => {
  const roles = approvalLine;
  const _absentStatus: any[] = [];

  approvalLine.forEach((role: string) => {
    switch (role) {
      case UserRole.TEACHER:
        _absentStatus.push(AbsentStatus.BEFORE_TEACHER_APPROVAL, UserRole.TEACHER);
        return;
      case UserRole.HEAD:
        _absentStatus.push(AbsentStatus.BEFORE_HEAD_APPROVAL, UserRole.HEAD);
        return;
      case UserRole.PRINCIPAL:
        _absentStatus.push(AbsentStatus.BEFORE_PRINCIPAL_APPROVAL, UserRole.PRINCIPAL);
        return;
    }
  });

  _absentStatus.push(AbsentStatus.PROCESSED);

  if (role === 'SECURITY') return [AbsentStatus.PROCESSED];

  const index = _absentStatus.findIndex((el) => el === role);

  if (index === -1) return [];

  if (order === 'before') {
    return _absentStatus.slice(index - 1, index).filter((el) => !roles.includes(el));
  } else if (order === 'after') {
    return _absentStatus.slice(index).filter((el) => !roles.includes(el));
  } else if (order === 'show') {
    return _absentStatus.slice(index - 1).filter((el) => !roles.includes(el));
  }
};

export const padLeft = (number: number, length: number, character: string = '0'): string => {
  let result = String(number);
  for (let i = result.length; i < length; ++i) {
    result = character + result;
  }
  return result;
};

export const padLeftstr = (number: string, length: number, character: string = '0'): string => {
  let result = number;
  if (result) {
    for (let i = result.length; i < length; ++i) {
      result = character + result;
    }
    return result;
  } else {
    return '';
  }
};

export const makeStudNum5 = (klass: string) => {
  const result = klass.replace('학년', '$').replace('반', '$').replace(' ', '').split('$');

  return result[0] + padLeftstr(result[1], 2) + padLeftstr(result[2], 2);
};
