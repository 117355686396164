import { FC, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { useResetPassword } from '../../../container/reset-password';
import {
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { Validator } from '../../../utils/validator';

interface MatchParams {
  id: string;
}

const ResetPasswordPage: FC<RouteComponentProps<MatchParams>> = () => {
  const { id } = useParams<MatchParams>();
  const [newPassword, setNewPassword] = useState('');
  const { mutate, isLoading, errorMessage } = useResetPassword();

  const handleResetPassword = (password: string) => {
    if (!password) {
      alert(
        '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
      );
      return;
    }
    if (!Validator.passwordRule1(password) && !Validator.passwordRule2(password)) {
      alert(
        '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
      );
      return;
    }
    mutate({ data: { token: id, newPassword } });
  };

  return (
    <>
      <TopNavbar
        title="비밀번호 변경"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isLoading && <Blank />}
      <Section>
        <h1 className="text-2xl font-bold">새로운 비밀번호 입력</h1>
        <TextField
          type="password"
          id="password"
          label="새 비밀번호"
          placeholder="새로운 비밀번호를 입력해주세요"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
      <BottomFixedView>
        <Section>
          <Button
            disabled={!newPassword}
            tw={{
              backgroundColor: !newPassword ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={() => handleResetPassword(newPassword)}
          >
            비밀번호 재설정하기
          </Button>
        </Section>
      </BottomFixedView>
    </>
  );
};

export default withRouter(ResetPasswordPage);
