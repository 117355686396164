import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ResponsePaginatedFieldtripDto } from '../../generated/model';
import { Badge } from '../../reusable';
import { makeStartEndToString } from '../../utils';

interface FieldtripCardProps {
  fieldtrip: ResponsePaginatedFieldtripDto['items'][number];
}

export const FieldtripCard: React.FC<FieldtripCardProps> = ({ fieldtrip }) => {
  const { pathname, search } = useLocation();
  let text;
  let textResult;

  switch (fieldtrip?.fieldtripStatus) {
    case 'BEFORE_PARENT_CONFIRM':
      text = <div className="text-sm text-red-500">학부모 승인 전</div>;
      break;
    case 'BEFORE_TEACHER_APPROVAL':
      text = <div className="text-sm text-red-500">선생님 승인 전</div>;
      break;
    case 'BEFORE_PREHEAD_APPROVAL':
      text = <div className="text-sm text-red-500">학년계 승인 전</div>;
      break;
    case 'BEFORE_HEAD_APPROVAL':
      text = <div className="text-sm text-red-500">학년부장 승인 전</div>;
      break;
    case 'BEFORE_PREPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교무계 승인 전</div>;
      break;
    case 'BEFORE_PRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교무부장 승인 전</div>;
      break;
    case 'BEFORE_VICEPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교감 승인 전</div>;
      break;
    case 'BEFORE_HEADPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교장 승인 전</div>;
      break;
    case 'PROCESSED':
      text = <div className="text-sm text-gray-600">처리 완료</div>;
      break;
    case 'RETURNED':
      text = <div className="text-sm text-brand-1">반려됨</div>;
      break;
    case 'DELETE_APPEAL':
      text = <div className="text-sm text-red-800">삭제 요청</div>;
      break;
  }

  if (fieldtrip?.fieldtripStatus === 'PROCESSED') {
    switch (fieldtrip?.fieldtripResultStatus) {
      case 'BEFORE_PARENT_CONFIRM':
        textResult = <div className="text-xs text-red-500">결과보고서 : 학부모 승인 전</div>;
        break;
      case 'BEFORE_TEACHER_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 선생님 승인 전</div>;
        break;
      case 'BEFORE_PREHEAD_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 학년계 승인 전</div>;
        break;
      case 'BEFORE_HEAD_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 학년부장 승인 전</div>;
        break;
      case 'BEFORE_PREPRINCIPAL_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 교무계 승인 전</div>;
        break;
      case 'BEFORE_PRINCIPAL_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 교무부장 승인 전</div>;
        break;
      case 'BEFORE_VICEPRINCIPAL_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 교감 승인 전</div>;
        break;
      case 'BEFORE_HEADPRINCIPAL_APPROVAL':
        textResult = <div className="text-xs text-red-500">결과보고서 : 교장 승인 전</div>;
        break;
      case 'PROCESSED':
        textResult = <div className="text-xs text-gray-600">결과보고서 : 처리 완료</div>;
        break;
      case 'RETURNED':
        textResult = <div className="text-xs text-brand-1">결과보고서 : 반려됨</div>;
        break;
      case 'DELETE_APPEAL':
        textResult = <div className="text-xs text-red-800">결과보고서 : 삭제 요청</div>;
        break;
      default:
        textResult = <div className="text-xs text-grey-3">결과보고서 : 작성 전</div>;
        break;
    }
  }

  return (
    <>
      <Link to={`/teacher/fieldtrip/${fieldtrip.id}${search}`}>
        <div
          className={
            pathname.startsWith(`/teacher/fieldtrip/${fieldtrip.id}`)
              ? 'w-full p-4 relative flex items-center justify-between bg-gray-50'
              : 'w-full p-4 relative flex items-center justify-between'
          }
        >
          <div>
            <h3 className="text-lg font-semibold">
              [신청서] {fieldtrip?.student?.name} {fieldtrip?.studentGradeKlass}{' '}
              {fieldtrip?.studentNumber}번
            </h3>
            <div className="text-sm font-semibold my-1 flex space-x-2">
              {fieldtrip?.type === 'HOME' ? (
                <Badge text="가정" tw={{ color: 'text-brand-1', backgroundColor: 'bg-brand-5' }} />
              ) : (
                <Badge text="교외" tw={{ color: 'text-brand-1', backgroundColor: 'bg-brand-5' }} />
              )}

              <div>
                {fieldtrip.type === 'SUBURBS' ? fieldtrip?.form || '교외체험학습' : '가정학습'}
              </div>
            </div>
            <div className="text-xs text-gray-500 pt-1">
              기간 :{' '}
              {fieldtrip?.startAt &&
                fieldtrip?.endAt &&
                makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}
            </div>
            <div className="text-xs text-gray-500 pt-1">신청일 : {fieldtrip.reportedAt}</div>
          </div>
          <div className="text-right">
            {text}
            <br />
            {textResult}
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
      </Link>
    </>
  );
};
