import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';
import Viewer from 'react-viewer';

import { useRecoilState, useRecoilValue } from 'recoil';

import { NoticeAddPage } from './NoticeAddPage';
import { ErrorBlank, SuperModal } from '../../../components';
import { Constants } from '../../../constants';
import { useTeacherNoticeDetail } from '../../../container/teacher-notice-detail';
import {
  BackButton,
  Blank,
  Button,
  Section,
  TopNavbar,
  utcToLocalDatetime,
} from '../../../reusable';
import { isUpdateNoticeState, meStateNew } from '../../../store';
import { getFileNameFromUrl } from '../../../utils/file-util';

export const NoticeDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const me = useRecoilValue(meStateNew);
  const [isUpdateNotice, setIsUpdateNotice] = useRecoilState(isUpdateNoticeState);
  const { notice, isNoticeLoading, images, files, viewerImages, errorMessage, handleNoticeDelete } =
    useTeacherNoticeDetail(+id);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  if (isUpdateNotice) {
    return <NoticeAddPage noticeData={notice} />;
  }

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden bg-white">
        <div>
          {isNoticeLoading && <Blank />}
          {errorMessage && <ErrorBlank text={errorMessage} />}
          <TopNavbar left={<BackButton />} title="공지" />

          <Section>
            <div className="rounded-3xl px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
              학교 공지
            </div>
            <div>
              <div className="text-lg font-bold text-grey-1 ">{notice?.title}</div>
              <div className="text-grey-5 text-sm mb-2">
                {utcToLocalDatetime(notice?.createdAt.toString() || '')}
              </div>
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <div
                  key={i}
                  className="w-full"
                  onClick={() => {
                    setActiveIndex(i);
                    setImagesModalOpen(true);
                  }}
                >
                  <div className={`relative pb-3/5 rounded bg-gray-50`}>
                    <LazyLoadImage
                      src={`${Constants.imageUrl}${image}`}
                      alt={''}
                      loading={'lazy'}
                      className={`absolute w-full h-full rounded object-cover`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>
              {files?.map((fileUrl: string, index) => (
                <div
                  key={index}
                  className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
                >
                  <span>{getFileNameFromUrl(fileUrl)}</span>
                  <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                    <a
                      href={`${Constants.imageUrl}${fileUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      download={getFileNameFromUrl(fileUrl)}
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className={`text-grey-2 break-words whitespace-pre-line text-sm feedback_space`}>
              <Linkify>{notice?.content}</Linkify>
            </div>
          </Section>
        </div>
      </div>

      {/* Desktop V */}
      <div className="bg-white border rounded-lg p-5">
        {isNoticeLoading && <Blank reversed />}
        {errorMessage && <ErrorBlank text={errorMessage} />}
        <SuperModal
          modalOpen={isDeleteModalOpen}
          setModalClose={() => setDeleteModalOpen(false)}
          width="w-max"
        >
          <Section tw={{ marginTop: 'mt-7' }}>
            <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
              정말 해당 공지사항을 삭제하시겠습니까?
            </div>
            <Button onClick={handleNoticeDelete}>삭제하기</Button>
          </Section>
        </SuperModal>

        <div className="hidden md:block">
          <Section>
            <div className="flex justify-between">
              <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
                학교 공지
              </div>
              {me?.canEditNotice && (
                <div className="flex space-x-4 font-base text-gray-500 cursor-pointer">
                  <div className="text-gray-700" onClick={() => setIsUpdateNotice(true)}>
                    수정
                  </div>
                  <div
                    className="text-red-400 cursor-pointer"
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    삭제
                  </div>
                </div>
              )}
            </div>
            <div className="text-lg font-bold text-grey-1">{notice?.title}</div>
            <div className="text-grey-3 text-sm">
              {utcToLocalDatetime(notice?.createdAt.toString() || '')}
            </div>
            <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <div key={image}>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <LazyLoadImage
                        src={`${Constants.imageUrl}${image}`}
                        alt={''}
                        loading={'lazy'}
                        className={`absolute w-full h-full rounded object-cover`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {files?.map((fileUrl: string, index) => (
                <div
                  key={index}
                  className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
                >
                  <span>{getFileNameFromUrl(fileUrl)}</span>
                  <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                    <a
                      href={`${Constants.imageUrl}${fileUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      download={getFileNameFromUrl(fileUrl)}
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className={`text-grey-1 whitespace-pre-line feedback_space`}>
              <Linkify>{notice?.content}</Linkify>
            </div>
          </Section>
        </div>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};
