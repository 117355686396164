import { useCanteenControllerCreateOrUpdate } from '../generated/endpoint';
import { RequestUpsertCanteenDto } from '../generated/model';

export const useTeacherCanteenSubmit = (refetch: () => void) => {
  const { mutateAsync: upsertCanteen } = useCanteenControllerCreateOrUpdate({
    mutation: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  const handleCanteenUpsert = (data: RequestUpsertCanteenDto) => {
    return upsertCanteen({ data });
  };

  return {
    handleCanteenUpsert,
  };
};
