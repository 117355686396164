import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  useFieldtripsControllerDelete,
  useFieldtripsControllerFindOne,
  useFieldtripsControllerResend,
} from '../generated/endpoint';

export const useStudentFieldtripDetail = (id: number) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    data: fieldtrip,
    isLoading: isGetFieldtripLoading,
    error,
    refetch: refetchFieldtrip,
  } = useFieldtripsControllerFindOne(id);

  const { mutate: deleteFieldtripMutate, isLoading: isDeleteFieldtripLoading } =
    useFieldtripsControllerDelete({
      mutation: {
        onSuccess: () => {
          alert('삭제되었습니다.');
          history.push('/student/fieldtrip');
        },
        onError: (e) => {
          setErrorMessage(e.message);
        },
      },
    });

  const deleteFieldtrip = () => {
    deleteFieldtripMutate({ id });
  };

  const { refetch: resendAlimtalk, isLoading: isResendAlimtalkLoading } =
    useFieldtripsControllerResend(id, {
      query: {
        enabled: false,
        onSuccess: () => {
          alert('신청서 알림톡이 재전송되었습니다.');
          history.push('/student/fieldtrip');
        },
        onError: (e) => {
          setErrorMessage(e.message);
        },
      },
    });

  const isFieldtripLoading =
    isGetFieldtripLoading || isDeleteFieldtripLoading || isResendAlimtalkLoading;

  return {
    isFieldtripLoading,
    fieldtrip,
    error,
    errorMessage,
    refetchFieldtrip,
    deleteFieldtrip,
    resendAlimtalk,
  };
};
