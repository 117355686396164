import { useRecoilState, useRecoilValue } from 'recoil';

import { MyInfoUpdatePage } from './MyInfoUpdatePage';
import { useStudentKlassHistory } from '../../../container/student-klass-history';
import { useStudentSendParentSignUp } from '../../../container/student-send-parent-sign-up';
import { Role } from '../../../generated/model';
import { BackButton, Button, LoadingScreen, Section, TopNavbar } from '../../../reusable';
import { isUpdateMeState, meStateNew } from '../../../store';

export const MyInfoPage = () => {
  const me = useRecoilValue(meStateNew);
  const [isUpdateMe, setIsUpdateMe] = useRecoilState(isUpdateMeState);
  const { handleSendParentSignUp } = useStudentSendParentSignUp();
  const { klassHistoryList } = useStudentKlassHistory();

  const isNotParent = me?.role !== Role.PARENT;

  if (!me) return <LoadingScreen />;

  if (isUpdateMe) {
    return <MyInfoUpdatePage me={me} setIsUpdateMe={setIsUpdateMe} />;
  }

  return (
    <div>
      <TopNavbar
        title="내 정보"
        left={
          <div className="h-15">
            <BackButton className="h-15" onClick={() => setIsUpdateMe(false)} />
          </div>
        }
      />
      <Section>
        <div>
          <div className="text-lg font-bold text-gray-800">이름</div>
          <div className="text-grey-2">{me?.name}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">이메일</div>
          <div className="text-grey-2">{me?.email}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">전화번호</div>
          <div className="text-grey-2">{me?.phone}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">학교</div>
          <div className="text-grey-2">{me?.school?.name}</div>
        </div>
        {isNotParent && (
          <div>
            <div className="text-lg font-bold text-gray-800">반/번호</div>
            <div className="text-grey-2">
              {klassHistoryList
                ?.sort((a, b) => +(a?.group?.year || 0) - +(b?.group?.year || 0))
                .map((klass) => (
                  <>
                    {klass?.group?.year} {me?.school?.name} {klass?.group?.name}{' '}
                    {klass?.studentNumber}번
                    <br />
                  </>
                ))}
            </div>
          </div>
        )}
        {isNotParent && (
          <>
            <div>
              <div className="text-lg font-bold text-gray-800">생년월일</div>
              <div className="text-grey-2">{me?.birthDate}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">희망진로</div>
              <div className="text-grey-2">{me?.hopePath}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">희망학과</div>
              <div className="text-grey-2">{me?.hopeMajor}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">보호자 이름</div>
              <div className="text-grey-2">{me?.nokName}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">보호자 연락처</div>
              <div className="text-grey-2">{me?.nokPhone}</div>
            </div>
            <Button
              tw={{
                borderColor: 'border-brand-1',
                backgroundColor: 'white',
                color: 'text-brand-1',
              }}
              onClick={handleSendParentSignUp}
            >
              학부모님 가입 알림톡 발송하기
            </Button>
          </>
        )}
        <br />
        <Button onClick={() => setIsUpdateMe(true)}>수정하기</Button>
        <div className="w-full h-24" />
      </Section>
    </div>
  );
};
