import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Notice } from '../../generated/model';
import { Badge, utcToLocalDatetime } from '../../reusable';
import { Routes } from '../../routes';

interface NoticeCardProps {
  notice: Notice;
  onClick: () => void;
}

export const NoticeCard: React.FC<NoticeCardProps> = ({ notice, onClick }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      <div
        className={
          pathname.startsWith(`${Routes.teacher.notice}/${notice.id}`)
            ? 'px-6 py-4 cursor-pointer bg-gray-50'
            : 'px-6 py-4 cursor-pointer'
        }
        onClick={() => {
          onClick();
          history.push(`${Routes.teacher.notice}/${notice.id}`);
        }}
      >
        <div className="flex justify-between">
          <Badge
            tw={{
              backgroundColor: 'bg-red-50',
              color: 'text-red-500',
              borderRadius: 'rounded-md',
            }}
          >
            {notice.category || '공지사항'}
          </Badge>
          <div className="text-sm text-gray-500 font-light">
            {notice.createdAt && utcToLocalDatetime(notice.createdAt)}
          </div>
        </div>
        <div className="text-lg font-semibold mt-2">{notice.title}</div>
      </div>
      <div className="h-0.5 bg-gray-100 w-full"></div>
    </>
  );
};
