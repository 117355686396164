import { useState } from 'react';
import { useHistory } from 'react-router';

import { useOutingsControllerDelete, useOutingsControllerFindOne } from '../generated/endpoint';

export const useStudentOutingDetail = (id: number) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const childUserId = localStorage.getItem('child-user-id');

  const {
    data: outing,
    refetch: refetchOuting,
    isLoading,
    error,
  } = useOutingsControllerFindOne(id, {
    query: {
      enabled: !!id,
    },
    request: {
      headers: { 'child-user-id': childUserId },
    },
  });

  const { mutate: deleteOutingMutate } = useOutingsControllerDelete({
    mutation: {
      onSuccess: () => {
        alert('삭제되었습니다');
        history.push('/student/outing');
      },
      onError: (e) => {
        setErrorMessage(e.message);
      },
    },
  });
  const deleteOuting = () => {
    deleteOutingMutate({
      id,
    });
  };

  return { outing, refetchOuting, isLoading, error, deleteOuting, errorMessage };
};
