import { useState } from 'react';
import Calendar from 'react-calendar';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import Viewer from 'react-viewer';

import { ReactComponent as ClockIcon } from '../../../assets/svg/clock.svg';
import { ErrorBlank } from '../../../components';
import { Constants } from '../../../constants';
import { useStudentCanteen } from '../../../container/student-canteen';
import { Role } from '../../../generated/model';
import { Blank, Section, TopNavbar, useRecoilValue } from '../../../reusable';
import { meStateNew } from '../../../store';
import { makeDateToString, makeMonthDayToString } from '../../../utils';
import { TimetableDetailPage } from '../timetable/TimetableDetailPage';

export const CanteenPage = () => {
  const me = useRecoilValue(meStateNew);
  const {
    daysWithSchedule,
    errorMessage,
    selectedDate,
    setSelectedDate,
    selectedCanteen,
    selectedSchedules,
    isLoading,
  } = useStudentCanteen(me?.role === Role.PARENT);

  const [showTimetablePage, setShowTimetablePage] = useState(false);

  const [blankOpen, setBlankOpen] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  if (!me?.id || isLoading) {
    return <Blank />;
  }

  return (
    <>
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {blankOpen && <Blank />}
      <TopNavbar
        title="일정"
        left={<div className="h-15 w-10" />}
        right={
          <div
            className="text-brand-1"
            onClick={() => {
              setBlankOpen(true);
              window?.location?.reload();
            }}
          >
            새로고침
          </div>
        }
      />
      <div className="px-4 py-4">
        <Calendar
          onChange={(e: any) => {
            setSelectedDate(new Date(e));
          }}
          calendarType="US"
          locale="kr"
          prev2Label={null}
          next2Label={null}
          value={selectedDate}
          className="text-center"
          onActiveStartDateChange={(e) => setSelectedDate(e.activeStartDate)}
          //@ts-ignore
          formatDay={(locale, date) => date.getDate()}
          tileContent={({ date }) => (
            <div
              className="absolute left-1/2 min-w-max flex justify-center space-x-0.5"
              style={{ transform: 'translateX(-51%)' }}
            >
              {daysWithSchedule.includes(makeDateToString(date)) && (
                <div className="rounded-full w-1 h-1 bg-red-500 bg-opacity-100" />
              )}
            </div>
          )}
        />
      </div>
      <div className="bg-gray-50 h-0.5 w-full" />
      <div className="py-4 px-6">
        <div className="text-sm text-grey-5 pb-3">{makeMonthDayToString(selectedDate)}</div>
        <div className="flex flex-col space-y-3">
          {selectedSchedules?.map((schedule: any) => (
            <div className="w-full flex space-x-2 items-center">
              <div className="w-2 h-2 rounded-full bg-brand-1" />
              <div className="font-bold">{schedule.title}</div>
            </div>
          ))}
        </div>
      </div>
      <Section>
        {selectedCanteen?.image && (
          <div onClick={() => setImageModalOpen(true)}>
            <div className={`relative pb-3/5 rounded bg-gray-50`}>
              <ExifOrientationImg
                className={`absolute w-full h-full rounded-lg object-cover`}
                src={`${Constants.imageUrl}${selectedCanteen.image}`}
                alt=""
              />
            </div>

            <div className="text-gray-400 text-sm ml-4">이미지를 클릭해서 크게 볼 수 있습니다.</div>
          </div>
        )}
        <div className="absolute">
          {selectedCanteen?.image && (
            <Viewer
              visible={isImageModalOpen}
              rotatable={true}
              noImgDetails={true}
              //drag={false}
              scalable={false}
              //changeable={false}
              //loop={false}
              images={[
                {
                  src: Constants.imageUrl + selectedCanteen.image,
                  alt: '',
                },
              ]}
              onClose={() => setImageModalOpen(false)}
            />
          )}
        </div>
        <div className="bg-brand-1 w-screen -ml-5 bg-opacity-20 font-semibold text-brand-1 px-5 py-5">
          오늘의 급식표
        </div>
        {selectedCanteen && (
          <div className="flex items-start space-x-2">
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">중식</div>
              <div className="whitespace-pre-line">{selectedCanteen?.lunch}</div>
            </div>
            <div className="w-full flex-col space-y-2">
              <div className="text-lg font-bold">{/* 석식 */}</div>
              <div className="whitespace-pre-line">{selectedCanteen?.dinner}</div>
            </div>
          </div>
        )}
      </Section>

      <div className="fixed bottom-16 right-4 bg-grey-8 rounded-full w-16 h-16">
        <div
          className="w-full h-full flex flex-col justify-center items-center"
          onClick={() => setShowTimetablePage(true)}
        >
          <ClockIcon />
          <div className="text-white text-sm">시간표</div>
        </div>
      </div>
      {showTimetablePage && <TimetableDetailPage setShowTimetablePage={setShowTimetablePage} />}
    </>
  );
};
