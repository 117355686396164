import { ReactComponent as CanteenIconSelected } from '../../assets/svg/bottomview-canteen-selected.svg';
import { ReactComponent as CanteenIcon } from '../../assets/svg/bottomview-canteen.svg';
import { ReactComponent as MyPageIconSelected } from '../../assets/svg/bottomview-more-selected.svg';
import { ReactComponent as MyPageIcon } from '../../assets/svg/bottomview-more.svg';
import { ReactComponent as NoticeIconSelected } from '../../assets/svg/bottomview-notice-selected.svg';
import { ReactComponent as NoticeIcon } from '../../assets/svg/bottomview-notice.svg';
import { ReactComponent as PlannerIconSelected } from '../../assets/svg/bottomview-planner-selected.svg';
import { ReactComponent as PlannerIcon } from '../../assets/svg/bottomview-planner.svg';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/svg/bottomview-home.svg';
import { ReactComponent as HomeIconSelected } from '../../assets/svg/bottomview-home-selected.svg';
import { ReactComponent as ManageIcon } from '../../assets/svg/bottomview-manage.svg';
import { ReactComponent as ManageIconSelected } from '../../assets/svg/bottomview-manage-selected.svg';

export const Icons = {
  CanteenIconSelected,
  CanteenIcon,
  MyPageIconSelected,
  MyPageIcon,
  NoticeIcon,
  NoticeIconSelected,
  PlannerIconSelected,
  PlannerIcon,
  Logo,
  HomeIcon,
  HomeIconSelected,
  ManageIcon,
  ManageIconSelected,
};
