import React from 'react';
import { useParams } from 'react-router';

import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { AllSelectCheckbox, ErrorBlank } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { useTeacherBoardAdd } from '../../../container/teacher-board-add';
import { BoardCategoryEnum, Group, RequestCreateBoardDto } from '../../../generated/model';
import {
  BackButton,
  Blank,
  Button,
  Checkbox,
  Label,
  Section,
  Select,
  TextArea,
  TextField,
  TopNavbar,
} from '../../../reusable';

interface BoardAddProps {
  homeKlass?: Group;
  groups?: Group[];
}

export const BoardAddPage = ({ homeKlass, groups }: BoardAddProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    title,
    content,
    selectedGroups,
    selectedCategory,
    firstGroup,
    secondGroup,
    thirdGroup,
    restGroup,
    selectedGroupIds,
    imageObjectMap,
    documentObjectMap,
    buttonDisabled,
    errorMessage,
    isLoading,
    setTitle,
    setContent,
    setSelectedGroups,
    setSelectedCategory,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    handleSubmit,
  } = useTeacherBoardAdd({ homeKlass, groups, boardId: +id });

  return (
    <div className="md:p-6">
      {isLoading && <Blank />}
      {errorMessage && <ErrorBlank text={errorMessage} />}

      <div className="md:hidden block">
        <TopNavbar title="학급게시판 추가" left={<BackButton />} />
      </div>
      <Section>
        <div className="bg-white p-4 border rounded-md mt-3">
          <span>전달 대상 선택</span>
          <div className="flex w-full justify-between mt-1">
            <div>
              <div className="font-bold">
                {firstGroup.length && (
                  <AllSelectCheckbox
                    groups={firstGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) => setSelectedGroups(groups)}
                    label="1학년 전체"
                  />
                )}
              </div>
              {firstGroup.map((group: Group) => (
                <Checkbox
                  key={group.id}
                  label={group.name}
                  checked={selectedGroupIds.includes(group.id)}
                  onChange={() =>
                    selectedGroupIds.includes(group.id)
                      ? setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
                      : setSelectedGroups(selectedGroups.concat(group))
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {secondGroup.length && (
                  <AllSelectCheckbox
                    groups={secondGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) => setSelectedGroups(groups)}
                    label="2학년 전체"
                  />
                )}
              </div>
              {secondGroup.map((group) => (
                <Checkbox
                  key={group.id}
                  label={group.name}
                  checked={selectedGroupIds.includes(group.id)}
                  onChange={() =>
                    selectedGroupIds.includes(group.id)
                      ? setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
                      : setSelectedGroups(selectedGroups.concat(group))
                  }
                />
              ))}
            </div>
            <div>
              <div className="font-bold">
                {thirdGroup.length && (
                  <AllSelectCheckbox
                    groups={thirdGroup}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={(groups: Group[]) => setSelectedGroups(groups)}
                    label="3학년 전체"
                  />
                )}
              </div>
              {thirdGroup.map((group) => (
                <Checkbox
                  key={group.id}
                  label={group.name}
                  checked={selectedGroupIds.includes(group.id)}
                  onChange={() =>
                    selectedGroupIds.includes(group.id)
                      ? setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
                      : setSelectedGroups(selectedGroups.concat(group))
                  }
                />
              ))}
            </div>
          </div>
          <div>
            {restGroup.map((group) => (
              <Checkbox
                key={group.id}
                label={group.name}
                checked={selectedGroupIds.includes(group.id)}
                onChange={() =>
                  selectedGroupIds.includes(group.id)
                    ? setSelectedGroups(selectedGroups.filter((g) => g.id !== group.id))
                    : setSelectedGroups(selectedGroups.concat(group))
                }
              />
            ))}
          </div>
        </div>
        <div>
          <TextField
            label="제목"
            id="title"
            placeholder="제목을 입력해주세요"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </div>

        <div>
          <TextArea
            label="내용"
            placeholder="내용을 입력해주세요."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            style={{ borderWidth: '1px' }}
          />
        </div>

        <div>
          <Select
            label="구분"
            id="category"
            onChange={(e) => setSelectedCategory(e.target.value as BoardCategoryEnum)}
            value={selectedCategory}
          >
            <option selected hidden>
              구분을 선택해주세요
            </option>
            {Object.keys(BoardCategoryEnum).map((el) => (
              <option id={el} value={el} key={el}>
                {el}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Label>이미지</Label>
          <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
            {[...imageObjectMap]?.map(([key, value]) => (
              <div key={key}>
                <ImageObjectComponent
                  id={key}
                  imageObjet={value}
                  onDeleteClick={handleImageDeleteToggle}
                />
              </div>
            ))}
            {/* 이미지 업로드 컴포넌트 */}
            <div>
              <label htmlFor={`image-upload`}>
                <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                      <FileUploadImg />
                      <div className="text-brand-1">이미지를 업로드해주세요!!</div>
                    </div>
                  </div>
                </div>
              </label>
              <input
                type="file"
                id={`image-upload`}
                className="hidden"
                accept=".pdf, .png, .jpeg, .jpg"
                onChange={handleImageAdd}
              />
            </div>
          </div>
        </div>
        <div>
          <Label>파일</Label>
          <div className="bg-white rounded border-2 border-dashed border-grey-5">
            {[...documentObjectMap]?.map(([key, value]) => (
              <div key={key}>
                <DocumentObjectComponent
                  id={key}
                  documentObjet={value}
                  onDeleteClick={handleDocumentDeleteToggle}
                />
              </div>
            ))}
            <label htmlFor="file-upload">
              <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
                <span className="text-2xl text-grey-3 mb-1">+</span>
                <div className="text-brand-1">파일을 업로드해주세요 (다중 업로드 가능)</div>
              </div>
            </label>
          </div>
          <input
            type="file"
            id="file-upload"
            className="hidden"
            multiple
            onChange={handleDocumentAdd}
          />
        </div>
      </Section>
      <div className="text-center mt-6">
        <Button
          text={!!id && id !== 'add' ? '수정하기' : '등록하기'}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          disabled={buttonDisabled}
          onClick={() => {
            const board: RequestCreateBoardDto = {
              title,
              content,
              category: selectedCategory!,
              images: [],
              files: [],
              targetGroupIds: selectedGroupIds,
            };
            handleSubmit({
              boardPayload: board,
              imageObjectMapParam: imageObjectMap,
              documentObjectMapParam: documentObjectMap,
            });
          }}
        />
      </div>
      <div className="h-24" />
    </div>
  );
};
