import { useState } from 'react';

import { useRecoilValue } from 'recoil';

import {
  useStudentActivityControllerFindByUserId,
  useSummariesControllerCreate,
  useSummariesControllerFindByStudentGroupId,
  useSummariesControllerUpdate,
} from '../generated/endpoint';
import { StudentGroup } from '../generated/model';
import { Summary } from '../generated/model';
import { meStateNew } from '../store';

type Props = {
  userId: number;
  groupId: number;
  studentGroups?: StudentGroup[];
};

export const useTeacherRecordDetail = ({ userId, groupId, studentGroups }: Props) => {
  const me = useRecoilValue(meStateNew);
  const [errorText, setErrorText] = useState('');
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [recordSummary, setRecordSummary] = useState('');
  const [summaries, setSummaries] = useState<Summary[] | undefined>();
  const selectedStudentGroup = studentGroups?.filter((sg) => sg.user?.id === userId)[0];
  const selectedUser = selectedStudentGroup?.user;

  const { data: studentActivities, refetch: refetchStudentActivity } =
    useStudentActivityControllerFindByUserId(
      {
        userId,
      },
      {
        query: {
          enabled: !!(userId && groupId),
          keepPreviousData: true,
        },
      },
    );

  const { data, refetch: refetchSummaries } = useSummariesControllerFindByStudentGroupId(
    { studentGroupId: selectedStudentGroup?.id ?? 0 },
    {
      query: {
        enabled: !!selectedStudentGroup?.id,
        onSuccess: (res) => {
          setSummaries(res);
        },
      },
    },
  );

  const { mutate: createRecordSummaryMutate, isLoading: isCreateSummaryLoading } =
    useSummariesControllerCreate({
      mutation: {
        onSuccess: (res) => {
          setErrorText('');
          refetchSummaries();
        },
        onError: (err) => {
          setErrorText(err.message);
        },
      },
    });

  const createRecordSummary = () => {
    if (!selectedStudentGroup?.id || !me?.id) return;

    createRecordSummaryMutate({
      data: {
        content: recordSummary,
        subject: selectedSubject,
        studentGroupId: selectedStudentGroup.id,
      },
    });
  };

  const { mutate: updateRecordSummaryMutate } = useSummariesControllerUpdate({
    mutation: {
      onSuccess: () => {
        setErrorText('');
        refetchSummaries();
      },
      onError: (err) => {
        setErrorText(err.message);
      },
    },
  });

  const updateRecordSummary = () => {
    if (!summaryBySubject?.id) return;

    updateRecordSummaryMutate({
      id: summaryBySubject.id,
      data: {
        recordSummary,
      },
    });
  };

  const createOrUpdateRecordSummary = () => {
    return summaryBySubject ? updateRecordSummary() : createRecordSummary();
  };

  const summaryBySubject = summaries?.find((s) => s.subject === selectedSubject);
  const subjects = [...new Set(studentActivities?.map((sa) => sa?.activity?.subject))];
  const isLoading = isCreateSummaryLoading;

  return {
    studentActivities,
    refetchStudentActivity,
    refetchSummaries,
    isLoading,
    subjects,
    errorText,
    selectedSubject,
    selectedStudentGroup,
    selectedUser,
    setSelectedSubject,
    recordSummary,
    setRecordSummary,
    createOrUpdateRecordSummary,
    summaries,
  };
};
