import React, { useState } from 'react';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';

import { ErrorBlank, SuperSurveyComponent } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { useParentStudentNewsletterApproval } from '../../../container/parent-student-newsletter-approval';
import { useSignature } from '../../../hooks/useSignature';
import { Blank, BottomFixedView, Button, CloseButton, Section, TopNavbar } from '../../../reusable';
import { makeDateToString } from '../../../utils';

export const StudentNewsletterApprovalPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { studentNewsletter, approveStudentNewsletter, isLoading, errorMessage } =
    useParentStudentNewsletterApproval(uuid);

  const { canvasRef, sigPadData, clearSignature } = useSignature();

  const student = studentNewsletter?.student;
  const school = student?.school;
  const newsletter = studentNewsletter?.newsletter;

  const [openSign, setSign] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const handleApproveSubmit = () => {
    setSign(false);
    approveStudentNewsletter({ uuid, data: { parentSignature: sigPadData } })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        console.log('error');
      });
  };

  return (
    <>
      {isSuccess && (
        <Blank>
          정상적으로 제출되었습니다.
          <br /> 감사합니다!
        </Blank>
      )}
      {errorMessage && <ErrorBlank text={errorMessage} />}
      {/*{error && (*/}
      {/*  <ErrorBlank*/}
      {/*    text={*/}
      {/*      error.message?.includes('Could not find any entity')*/}
      {/*        ? '해당하는 출결신고서를 찾을 수 없습니다.'*/}
      {/*        : error.message*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      <TopNavbar title={'가정통신문'} left={<div className="w-10 h-10" />} />
      <Section>
        {!studentNewsletter?.parentSignature ? (
          <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
            {`해당 가정통신문의 설문 내용에 귀하의 자녀 ${
              student?.name || ''
            } 학생이 답변하였습니다.
      내용확인 후 서명을 부탁드립니다.

      서명 요청자 :
      ${school?.name || ''} ${studentNewsletter?.studentGradeKlass || ''} ${
              student?.name || ''
            } 학생

      서명 참여자 :
      ${student?.nokName || ''} 학부모님 (${student?.nokPhone || ''})
      `}
          </div>
        ) : (
          <>
            <div>
              <div className="rounded-lg bg-light_orange whitespace-pre-line mb-4 p-4">
                {`이 가정통신문의 답변 내용이 ${student?.nokName || ''}님의 서명을 받았습니다.`}
              </div>
            </div>
            <div className="h-0.5 bg-gray-200" />
          </>
        )}
        <div>
          <h1 className="text-2xl font-semibold">{newsletter?.title}</h1>
          <div className="text-gray-500 text-sm">
            {newsletter?.createdAt && makeDateToString(new Date(newsletter.createdAt), '.')}
          </div>
        </div>
        <div className="h-0.5 w-full bg-gray-100" />

        {newsletter?.images?.length ? (
          <>
            {newsletter.images.map((image, index) => (
              <div key={index} className="w-full">
                <ImageObjectComponent id={index} imageObjet={{ image, isDelete: false }} />
              </div>
            ))}
          </>
        ) : null}

        {newsletter?.files?.length ? (
          <>
            {newsletter.files.map((file, index) => (
              <DocumentObjectComponent
                key={index}
                id={index}
                documentObjet={{ document: file, isDelete: false }}
              />
            ))}
          </>
        ) : null}

        <div className={`text-grey-2 break-words whitespace-pre-line text-sm feedback_space`}>
          <Linkify>{newsletter?.content}</Linkify>
        </div>
      </Section>
      <SuperSurveyComponent
        surveyContent={newsletter?.surveyContent || ''}
        setContent={(c: any) => {}}
        content={JSON.parse(studentNewsletter?.content || '{}')}
      />
      <Section>
        {studentNewsletter?.parentSignature && (
          <div className="w-full flex flex-col items-end">
            <div className="min-w-max font-bold mt-4 text-right">
              보호자: {student?.nokName} (인)
            </div>
            <img
              src={studentNewsletter?.parentSignature}
              alt=""
              style={{ width: '100px', marginTop: '0.5rem' }}
            />
          </div>
        )}

        <div className="whitespace-pre-line pb-12">
          {/* {`*해당 가정통신문의 학생 답변 내용입니다.`} */}
        </div>
        {studentNewsletter?.parentSignature ? (
          <>
            <div className="text-gray-500 text-sm">승인 요청되었습니다.</div>
            <Button
              tw={{
                width: 'w-full',
                backgroundColor: 'bg-gray-500',
              }}
            >
              사인 다시하기
            </Button>
          </>
        ) : (
          <Button tw={{ width: 'w-full' }} onClick={() => setSign(true)}>
            내용 확인하고 서명하기
          </Button>
        )}
      </Section>
      <div className={openSign ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div className="absolute top-2 right-3" onClick={() => setSign(false)}>
            <CloseButton
              onClick={() => {
                setSign(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학부모 서명란</div>
              <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button tw={{ width: 'w-full' }} onClick={handleApproveSubmit} disabled={isLoading}>
                서명 제출하기
              </Button>
            </div>
          </Section>
        </BottomFixedView>
      </div>
    </>
  );
};
