import { useState } from 'react';

import { SelectValues, SuperModal } from '../../../components';
import { Constants } from '../../../constants';
import { useTeacherAbsentUpdate } from '../../../container/teacher-absent-update';
import { Absent } from '../../../generated/model';
import { Blank, Button, Section, TextArea, TextField } from '../../../reusable';
import { AbsentDescription } from '../../../types';

interface AbsentUpdatePageProps {
  absentData: Absent | undefined;
  setChangeMode: (b: boolean) => void;
  refetch: () => void;
  isConfirmed: boolean;
}

const reportType = ['결석', '지각', '조퇴', '결과'];
const descriptionType = ['인정', '질병', '기타'];

const reasonType = [
  '상고',
  '코로나 19 관련',
  '생리',
  '학교장 출석인정',
  '병원진료',
  '가정에서의 안정',
  '보건실 방문',
  '기타',
];

const desType: AbsentDescription = {
  인정: {
    reasonType: ['상고', '코로나 19 관련', '생리', '학교장 출석인정'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
  질병: {
    reasonType: ['병원진료', '가정에서의 안정'],
    evidenceFileType: ['학부모 확인서', '진료확인서', '처방전', '담임확인서'],
  },
  기타: {
    reasonType: ['기타'],
    evidenceFileType: ['학부모 확인서', '기타'],
  },
};

export const AbsentUpdatePage = ({ absentData, setChangeMode, refetch }: AbsentUpdatePageProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    updateReason,
    setUpdateReason,
    reason,
    setReason,
    reasonText,
    setReasonText,
    report,
    setReport,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    description,
    setDescription,
    evidenceTypeText,
    setEvidenceTypeText,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    evidenceType,
    setEvidenceType,
    isLoading,
    updateAbsent,
  } = useTeacherAbsentUpdate({
    setChangeMode,
    absentData,
    desType,
    reasonType,
    refetch,
  });

  return (
    <div className="bg-white border rounded-lg p-5">
      {isLoading && <Blank />}
      <div className="w-auto h-full">
        <Section>
          <div className="w-full flex items-center justify-between">
            <div className="text-2xl font-bold">
              [{absentData?.reportType}] {absentData?.student?.name} {absentData?.studentGradeKlass}{' '}
              {absentData?.studentNumber}번
            </div>
          </div>

          {[
            {
              label: '학번',
              title: `${absentData?.studentGradeKlass || ''} ${absentData?.studentNumber || ''}번`,
            },
            { label: '학생이름', title: absentData?.student?.name },
            { label: '보호자이름', title: absentData?.student?.nokName },
            { label: '제출일', title: absentData?.reportedAt },
          ].map((item, index) => (
            <div key={index}>
              <div className="text-sm text-gray-500">{item.label}</div>
              <span className="font-semibold text-lg">{item.title}</span>
            </div>
          ))}
          <SelectValues
            label="*신고유형"
            placeholder="선택"
            selectValues={reportType}
            value={report}
            onChange={(group: string) => setReport(group)}
          />
          <SelectValues
            placeholder="선택"
            selectValues={descriptionType}
            value={description}
            onChange={(group: '인정') => {
              setDescription(group);
              if (!desType[group]?.reasonType?.includes(reason)) {
                setReason('');
              }
              if (!desType[group]?.evidenceFileType?.includes(evidenceType)) {
                setEvidenceType('');
              }
            }}
            borderColor={description ? 'border-gray-300' : 'border-red-700'}
          />
          <div>
            {report === '결석' && (
              <div className="w-full pb-6">
                <label className="mb-1 text-sm text-gray-800">*발생일</label>
                <div className="flex items-center mb-3">
                  <input
                    type="date"
                    value={startAt}
                    className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                  <span className="flex-shrink-0 ml-3">일 부터</span>
                </div>
                <div className="flex items-center">
                  <input
                    type="date"
                    value={endAt}
                    className="px-4 w-full h-12 border
                 border-gray-200 rounded-md sm:text-sm
                 focus:ring-0 focus:border-brand-1 placeholder-gray-400
                 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                    onChange={(e) => setEndAt(e.target.value)}
                  />
                  <span className="flex-shrink-0 ml-3">일 까지</span>
                </div>
              </div>
            )}
            {report !== '결석' && (
              <h1 className="space-y-3 pb-6">
                <div>
                  <label className="mb-1 text-sm text-gray-800">*발생일</label>
                  <input
                    type="date"
                    value={startAt}
                    defaultValue={absentData?.startAt}
                    className={`${
                      startAt ? 'border-gray-300' : 'border-red-700'
                    } px-4 w-full h-12 border rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max`}
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <select
                    value={startHour}
                    onChange={(e) => {
                      const _startHour = Number(e.target.value);
                      if (_startHour > endHour) {
                        setEndHour(_startHour);
                      }
                      if (_startHour === endHour && startMinute > endMinute) {
                        setEndMinute(startMinute);
                      }
                      setStartHour(_startHour);
                    }}
                    className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                  >
                    {new Array(24).fill(null).map((_, num: number) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span>시</span>
                  <select
                    className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                    onChange={(e) => {
                      const _startMinute = Number(e.target.value);
                      if (startHour === endHour && _startMinute > endMinute) {
                        setEndMinute(_startMinute);
                      }
                      setStartMinute(_startMinute);
                    }}
                    value={startMinute}
                  >
                    <option value={0}>0</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </select>
                  <span>분 부터</span>
                </div>
                <div className="flex items-center space-x-2">
                  <select
                    value={endHour}
                    onChange={(e) => {
                      const _endHour = Number(e.target.value);
                      if (startHour > _endHour) {
                        setStartHour(_endHour);
                      }
                      if (startHour === _endHour && startMinute > endMinute) {
                        setEndMinute(startMinute);
                      }
                      setEndHour(_endHour);
                    }}
                    className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                  >
                    {new Array(24).fill(null).map((_, num: number) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span>시</span>
                  <select
                    className="px-4 w-16 h-12 border border-gray-200 rounded-md sm:text-sm focus:ring-0 focus:border-brand-1 placeholder-gray-400 disabled:bg-gray-100 disabled:text-gray-400 min-w-max"
                    onChange={(e) => {
                      const _endMinute = Number(e.target.value);
                      if (startHour === endHour && startMinute > _endMinute) {
                        setStartMinute(_endMinute);
                      }
                      setEndMinute(_endMinute);
                    }}
                    value={endMinute}
                  >
                    <option value={0}>0</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </select>
                  <span>분 까지</span>
                </div>
              </h1>
            )}
          </div>
          <div>
            <SelectValues
              label="*신고사유 선택"
              placeholder="선택"
              selectValues={desType[description]?.reasonType || []}
              value={reason}
              onChange={(group: string) => setReason(group)}
            />
            {(reason === '학교장 출석인정' || reason === '기타') && (
              <TextField
                tw={{ marginTop: 'mt-1' }}
                placeholder="신고 사유를 입력해주세요."
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
              />
            )}
          </div>
          <div>
            <SelectValues
              label="*증빙서류 선택"
              selectValues={desType[description]?.evidenceFileType || []}
              value={evidenceType}
              onChange={(group: string) => setEvidenceType(group)}
            />
            {evidenceType === '기타' ? (
              <TextField
                placeholder="서류 종류를 입력해주세요."
                value={evidenceTypeText}
                onChange={(e) => setEvidenceTypeText(e.target.value)}
                tw={{
                  borderColor: evidenceTypeText ? 'border-gray-300' : 'border-red-700',
                }}
              />
            ) : null}
          </div>
          {absentData?.evidenceFiles?.length
            ? absentData.evidenceFiles.map((evidenceFile: string, index) => (
                <div key={index} className="w-full">
                  <a href={evidenceFile} target="_blank" rel="noreferrer">
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <img
                        className={`absolute w-full h-full rounded object-cover`}
                        src={`${Constants.imageUrl}${evidenceFile}`}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              ))
            : null}
        </Section>
      </div>

      {/* 버튼 */}
      <div className="flex items-center w-full space-x-2 mt-3">
        <Button
          tw={{ width: 'w-full' }}
          text="완료하기"
          onClick={() => {
            setModalOpen(true);
          }}
        />
        <Button
          tw={{ width: 'w-full', backgroundColor: 'bg-gray-500' }}
          text="취소하기"
          onClick={() => {
            setChangeMode(false);
          }}
        />
      </div>
      <SuperModal modalOpen={modalOpen} setModalClose={() => setModalOpen(false)} width="w-max">
        <Section tw={{ marginTop: 'mt-7' }}>
          <div className="w-full text-lg font-bold text-center text-gray-900 mb-6">
            이 출결신고서를 승인 후 수정하시는 이유를 적어주세요.
          </div>
          <TextArea
            placeholder="수정 이유"
            onChange={(e) => setUpdateReason(e.target.value)}
            value={updateReason}
          />
          <Button
            tw={{ backgroundColor: 'bg-red-500' }}
            onClick={() => {
              updateAbsent();
            }}
          >
            승인 후 수정하기
          </Button>
        </Section>
      </SuperModal>
      <div className="w-full h-24" />
    </div>
  );
};
