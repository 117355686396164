import { Link, Route, Switch } from 'react-router-dom';

import { BoardAddPage } from './BoardAddPage';
import { BoardDetailPage } from './BoardDetailPage';
import { BoardCard, SelectMenus } from '../../../components';
import { useTeacherBoard } from '../../../container/teacher-board';
import { useTeacherKlassGroup } from '../../../container/teacher-klass-groups';
import { Board, BoardCategoryEnum, Group } from '../../../generated/model';
import { Blank } from '../../../reusable';

export const BoardsPage = () => {
  const { groups, selectedGroup, homeKlass, setSelectedGroup } = useTeacherKlassGroup();
  const { boards, selectedCategory, isLoading, setSelectedCategory } = useTeacherBoard(
    selectedGroup?.id,
  );

  return (
    <>
      {isLoading && <Blank reversed />}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="flex justify-between px-6 pt-6 pb-3 place-items-center">
          <h1 className="text-2xl font-semibold">학급게시판</h1>

          <Link to="/teacher/board/add">
            <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
              추가하기
            </button>
          </Link>
        </div>

        <div className="flex px-6 pt-3 pb-6 items-center space-x-2">
          <div className="cursor-pointer">
            <SelectMenus
              items={groups}
              value={selectedGroup}
              onChange={(value: Group) => {
                setSelectedGroup(value);
              }}
            />
          </div>
          <div className="cursor-pointer">
            <SelectMenus
              items={Object.keys(BoardCategoryEnum)}
              value={selectedCategory as string}
              allText="전체"
              onChange={(group) => setSelectedCategory(group as BoardCategoryEnum)}
              allTextVisible
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100 scroll-box"></div>
        <div className="h-screen-5 overflow-y-auto scroll-box">
          {boards?.map((board: Board) => (
            <BoardCard key={board.id} board={board} />
          ))}

          {/* TODO Pagination 필요 */}
        </div>
      </div>

      <div
        className={`bg-gray-50 col-span-4 p-0 scroll-box overflow-y-scroll h-screen-4 md:h-screen`}
      >
        <Switch>
          <Route
            path="/teacher/board/add"
            render={() => <BoardAddPage homeKlass={homeKlass} groups={groups} />}
          />
          <Route
            path="/teacher/board/:id/edit"
            render={() => <BoardAddPage homeKlass={homeKlass} groups={groups} />}
          />
          <Route path="/teacher/board/:id" render={() => <BoardDetailPage page={1} limit={1} />} />
        </Switch>
      </div>
    </>
  );
};
