import React, { useState } from 'react';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { ErrorBlank, Td, Td2 } from '../../../components';
import { useFieldtripResultDetail } from '../../../container/student-fieldtrip-result-detail';
import { UserContainer } from '../../../container/user';
import { BackButton, Blank, Button, Section, TextField, TopNavbar } from '../../../reusable';

interface MatchParams {
  id: string;
}

export const FieldtripResultDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { id } = match.params;
  const history = useHistory();
  const { me } = UserContainer.useContext();

  const { isLoading, isError, resendAlimtalk, fieldtrip, errorMessage } = useFieldtripResultDetail({
    id: Number(id),
  });

  const approvalLine = me?.school.approvalLine;

  const [hasImagesModalOpen, setImagesModalOpen] = useState<boolean[]>(
    fieldtrip?.resultFiles?.map((el: string) => false) || [],
  );

  let name = '가정';

  switch (fieldtrip?.type) {
    case 'HOME':
      name = '가정';
      break;
    case 'SUBURBS':
      name = '교외 체험';
      break;
  }

  let content = [];

  try {
    content = JSON.parse(fieldtrip?.resultText || '[]');
  } catch (err) {
    console.log(err);
  }

  return (
    <>
      {isLoading && <Blank />}
      {isError && <ErrorBlank />}
      {isLoading && <Blank />}
      <TopNavbar
        title="체험학습 결과보고서 상세"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <h1 className="text-xl font-semibold"> {name}학습 결과보고서</h1>
        <div className=" w-full flex items-center justify-center">
          <table className="min-w-max">
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return <Td2 style={{ height: '16px' }}>담임</Td2>;
                  case 'HEAD':
                    return <Td2 style={{ height: '16px' }}>학년부장</Td2>;
                  case 'PRINCIPAL':
                    return <Td2 style={{ height: '16px' }}>교무부장</Td2>;
                }
              })}
            </tr>
            <tr>
              {approvalLine?.map((role: string) => {
                switch (role) {
                  case 'TEACHER':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.teacherResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.teacherResultSignature}
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'HEAD':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.headResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.headResultSignature}
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                  case 'PRINCIPAL':
                    return (
                      <Td2 style={{ height: '40px' }}>
                        {fieldtrip?.principalResultSignature && (
                          <img
                            style={{ width: '50px', margin: 'auto' }}
                            src={fieldtrip?.principalResultSignature}
                            alt=""
                          />
                        )}
                      </Td2>
                    );
                }
              })}
            </tr>
          </table>
        </div>
        {fieldtrip?.fieldtripResultStatus === 'RETURNED' && (
          <div className="mt-3">
            <div className="text-red-500">반려 이유</div>
            <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
              {fieldtrip?.notApprovedReason}
            </div>
          </div>
        )}
        {fieldtrip?.fieldtripResultStatus === 'DELETE_APPEAL' && (
          <div className="mt-3">
            <div className="text-red-500">삭제 이유</div>
            <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
              {fieldtrip?.deleteReason}
            </div>
          </div>
        )}

        <div
          className="rounded-lg border border-gray-800 bg-white px-5 py-3"
          onClick={() => history.push(`/student/fieldtrip/${id}`)}
        >
          해당 신청서 보기
        </div>
        <div
          className="rounded-lg border border-gray-800 bg-white px-5 py-3"
          onClick={() => history.push(`/student/fieldtrip/notice/${id}`)}
        >
          해당 통보서 보기
        </div>

        <div className="w-full">
          <TextField label="목적지" value={fieldtrip?.destination} readOnly />
        </div>

        <div className="w-full">
          <TextField label="목적" value={fieldtrip?.purpose} readOnly />
        </div>

        {fieldtrip?.type === 'SUBURBS' && (
          <>
            <div className="w-full">
              <TextField label="제목" value={fieldtrip?.resultTitle} readOnly />
            </div>
            <div className="whitespace-pre-line">{fieldtrip?.resultText}</div>
            <Section tw={{ backgroundColor: 'bg-gray-50' }}>
              {fieldtrip?.resultFiles?.map((image: string, i: number) => (
                <>
                  <div
                    className="w-full"
                    onClick={() => {
                      const newOpens = hasImagesModalOpen.slice();
                      newOpens[i] = true;
                      setImagesModalOpen(newOpens);
                    }}
                  >
                    {/* <a href={image} target="_blank" rel="noreferrer"> */}
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <img
                        className={`absolute w-full h-full rounded object-cover`}
                        src={image}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    {/* </a> */}
                  </div>
                  <div className="absolute">
                    {hasImagesModalOpen[i] ? (
                      <div
                        className={`fixed inset-0 w-full h-screen z-100 bg-black flex items-center justify-center`}
                        onClick={() => {
                          const newOpens = hasImagesModalOpen.slice();
                          newOpens[i] = false;
                          setImagesModalOpen(newOpens);
                        }}
                      >
                        <ExifOrientationImg src={image} alt="" className="w-full" loading="lazy" />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ))}
            </Section>

            {(fieldtrip?.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM' ||
              fieldtrip?.fieldtripResultStatus === 'RETURNED') && (
              <Button
                onClick={() =>
                  history.push(`/student/fieldtrip/add/report/suburbs/${fieldtrip.id}`)
                }
              >
                수정하기
              </Button>
            )}
            {fieldtrip?.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM' && (
              <Button tw={{ backgroundColor: 'bg-blue-500' }} onClick={() => resendAlimtalk()}>
                알림톡 재전송하기
              </Button>
            )}
            <div className="text-red-500">{errorMessage}</div>
          </>
        )}
        {fieldtrip?.type === 'HOME' && (
          <>
            {content?.map((el: any, i: number) => (
              <table className="min-w-max inline-block">
                <>
                  <tr>
                    <Td
                      colSpan={3}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#C4C4C4',
                      }}
                    >
                      {i + 1}일차
                    </Td>
                  </tr>
                  <tr>
                    <Td innerClassName="min-w-max">교시</Td>
                    <Td innerClassName="min-w-max">교과</Td>
                    <Td innerClassName="min-w-max">학습할 내용</Td>
                  </tr>
                  {new Array(7).fill('').map((dlldl: any, index: number) => (
                    <>
                      {el['subject' + (index + 1)] && (
                        <tr>
                          <Td>{index + 1}교시</Td>
                          <Td>{el['subject' + (index + 1)]}</Td>
                          <Td>{el['content' + (index + 1)]}</Td>
                        </tr>
                      )}
                    </>
                  ))}
                </>
              </table>
            ))}

            {(fieldtrip?.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM' ||
              fieldtrip?.fieldtripResultStatus === 'RETURNED') && (
              <Button
                onClick={() => history.push(`/student/fieldtrip/add/report/home/${fieldtrip.id}`)}
              >
                수정하기
              </Button>
            )}
            {fieldtrip?.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM' && (
              <Button tw={{ backgroundColor: 'bg-blue-500' }} onClick={() => resendAlimtalk()}>
                알림톡 재전송하기
              </Button>
            )}
            <div className="text-red-500">{errorMessage}</div>
          </>
        )}

        <br />

        <div className="w-full h-32" />
      </Section>
    </>
  );
};
