import React, { ChangeEvent, FC, useState } from 'react';

import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { Constants } from '../../../constants';
import { useTeacherCanteenSubmit } from '../../../container/teacher-canteen-submit';
import { UploadFileTypeEnum } from '../../../generated/model';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { Blank, Button, Section, TextArea } from '../../../reusable';
import { Canteen } from '../../../types';
import { makeDateToString } from '../../../utils';
import { checkFileSizeLimit20MB } from '../../../utils/file-util';

interface CanteenSubmitPageProps {
  selectedDate: Date;
  canteenData?: Canteen;
  refetch: () => void;
}

export const CanteenSubmitPage: FC<CanteenSubmitPageProps> = ({
  selectedDate,
  canteenData,
  refetch,
}) => {
  const { handleCanteenUpsert } = useTeacherCanteenSubmit(refetch);
  const { handleUploadFile } = useFileUpload();

  const [image, setImage] = useState<string>(canteenData?.image || '');
  const [lunch, setLunch] = useState(canteenData?.lunch || '');
  const [dinner, setDinner] = useState(canteenData?.dinner || '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    handleCanteenUpsert({
      lunch,
      dinner,
      date: makeDateToString(selectedDate),
      image,
    }).then(() => setLoading(false));
  };

  const handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedImageFiles = (e.target as HTMLInputElement).files;
    if (!selectedImageFiles || !selectedImageFiles.length) {
      return;
    }

    if (!checkFileSizeLimit20MB([selectedImageFiles[0]])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다.');
      return;
    }

    const imageFileNames = await handleUploadFile(UploadFileTypeEnum['test/canteens/images'], [
      selectedImageFiles[0],
    ]);

    setImage(imageFileNames[0]);
  };

  return (
    <div className="w-full h-screen relative bg-white border-l border-grey-6">
      {loading && <Blank reversed />}
      <div className="w-full py-4 px-6 border-b border-grey-6 flex items-center justify-between">
        <div className="font-bold text-xl">
          {selectedDate?.getFullYear()}년 {selectedDate?.getMonth() + 1}월 {selectedDate?.getDate()}
          일
        </div>
        <div className="invisible border border-grey-6 px-4 rounded py-2 text-gray-700 cursor-pointer">
          수정
        </div>
      </div>
      <Section>
        <label htmlFor="imageupload">
          <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
            {image ? (
              <img
                className="absolute w-full h-full rounded object-cover"
                src={`${Constants.imageUrl}${image}`}
                alt=""
              />
            ) : (
              <div className="absolute w-full h-full rounded object-cover bg-white">
                <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                  <FileUploadImg />
                  <div className="text-brand-1">이미지를 업로드해주세요!</div>
                </div>
              </div>
            )}
          </div>
        </label>
        <input
          type="file"
          id="imageupload"
          className="hidden"
          accept=".pdf, .png, .jpeg, .jpg"
          onChange={handleChangeImage}
        />

        <div className="w-full flex items-start space-x-2">
          <div className="w-full">
            <TextArea
              label="중식"
              placeholder="내용을 입력해주세요"
              style={{ borderWidth: '1px' }}
              value={lunch}
              onChange={(e) => setLunch(e.target.value)}
            />
          </div>
          <div className="w-full">
            <TextArea
              label="전달사항"
              placeholder="내용을 입력해주세요"
              style={{ borderWidth: '1px' }}
              value={dinner}
              onChange={(e) => setDinner(e.target.value)}
            />
          </div>
        </div>
      </Section>
      <div className="text-center mt-6">
        <Button
          text="저장하기"
          tw={{
            backgroundColor: 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
};
