import React, { FC } from 'react';

interface LnbCalendarsItemProps {
  value: any;
  checked: boolean;
  color: string;
  text: string;
  onClick?: () => void;
}

export const LnbCalendarsItem: FC<LnbCalendarsItemProps> = ({
  value,
  checked,
  color,
  text,
  onClick,
}) => {
  return (
    <div className="lnb-calendars-item" onClick={onClick}>
      <label>
        <input
          type="checkbox"
          className="tui-full-calendar-checkbox-round"
          value={value}
          checked={checked}
        />
        <span
          style={{
            borderColor: color,
            backgroundColor: color,
          }}
        ></span>
        <span style={{ lineHeight: '1.5rem' }}>{text}</span>
      </label>
    </div>
  );
};
