import React from 'react';
import { useParams } from 'react-router';

import { SuperSurveyComponent } from '../../../components';
import { useTeacherNewsletterSubmitDetail } from '../../../container/teacher-newsletter-submit-detail';
import { Blank, utcToLocalDatetime, utcToLocalFromNow } from '../../../reusable';

export const NewsletterSubmitDetailPage = () => {
  const { snid } = useParams<{ snid: string }>();
  const { id } = useParams<{ id: string }>();

  const { newsletter, studentNewsletter, isLoading } = useTeacherNewsletterSubmitDetail(+id, +snid);

  return (
    <>
      {isLoading && <Blank reversed />}
      <div className="border border-gray-100 h-screen-4.5 overflow-y-scroll overflow-x-hidden relative">
        <div className="bg-gray-50 p-4">
          <div className="space-y-0.5">
            <div className="text-lg font-semibold mt-2">{newsletter?.title}</div>
            <div className="text-sm text-gray-500 font-light">
              {newsletter?.createdAt && utcToLocalDatetime(newsletter.createdAt)}
            </div>
          </div>
        </div>
        {newsletter?.surveyContent && studentNewsletter?.content && (
          <SuperSurveyComponent
            surveyContent={newsletter?.surveyContent || ''}
            setContent={(c: any) => {}}
            content={JSON.parse(studentNewsletter?.content || '{}')}
          />
        )}

        {newsletter?.updatedAt &&
          studentNewsletter?.updatedAt &&
          studentNewsletter?.isSubmitted &&
          studentNewsletter.updatedAt !== newsletter?.updatedAt && (
            <div className="pl-4 mt-3 text-brandblue-1">
              제출 완료 일시 :{' '}
              {utcToLocalDatetime(new Date(studentNewsletter.updatedAt).toISOString())} (
              {utcToLocalFromNow(new Date(studentNewsletter.updatedAt).toISOString())})
            </div>
          )}
      </div>
    </>
  );
};
