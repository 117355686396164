import { useState } from 'react';

import { QueryKey } from '../constants/query-key';
import { useBoardControllerFindAll } from '../generated/endpoint';
import { Board, BoardCategoryEnum } from '../generated/model';

export const useTeacherBoard = (groupId: number | undefined) => {
  const [pageInfo, setPageInfo] = useState({ page: 1, limit: 500 });
  const [selectedCategory, setSelectedCategory] = useState<BoardCategoryEnum>();

  const { data: boardList, isLoading } = useBoardControllerFindAll(
    { ...pageInfo, groupId: groupId as number },
    {
      query: {
        enabled: !!groupId,
        queryKey: [...QueryKey.teacher.boardList, groupId],
      },
    },
  );

  const boards = selectedCategory
    ? boardList?.items.filter((el: Board) => el.category === selectedCategory)
    : boardList?.items;

  return {
    boards,
    selectedCategory,
    isLoading,
    setSelectedCategory,
  };
};
