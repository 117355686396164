import React, { FC, InputHTMLAttributes } from 'react';

interface SuperSearchFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch: () => void;
  outerStyle?: any;
}

export const SuperSearchField: FC<SuperSearchFieldProps> = ({
  onSearch,
  outerStyle,
  ...props
}) => {
  return (
    <div
      className="bg-gray-50 items-center border-gray-200 rounded-full border flex h-10 px-4 w-full focus-within:border-brand-1"
      style={outerStyle}
    >
      <input
        className="bg-gray-50 flex-1 text-sm placeholder-grey-400 focus:ring-0"
        {...props}
        onKeyPress={(e) => e.key === 'Enter' && onSearch()}
      />
    </div>
  );
};
