import { useEffect, useState } from 'react';

import {
  useAbsentsControllerApproveByParent,
  useAbsentsControllerFindOneByUUID,
} from '../generated/endpoint';
import { useSignature } from '../hooks/useSignature';

export const useStudentAbsentApprove = (uuid: string) => {
  const { sigPadData, clearSignature, canvasRef } = useSignature();
  const [openSign, setSign] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [comment, setComment] = useState('');

  const {
    data: absent,
    isError: isGetAbsentError,
    refetch,
    isLoading: isGetAbsentLoading,
  } = useAbsentsControllerFindOneByUUID(uuid, {
    query: {
      onSuccess: (res) => {
        setComment(res.parentComment);
      },
    },
  });

  const {
    mutate: signAbsentMutate,
    isSuccess,
    isLoading: isSignAbsentLoading,
  } = useAbsentsControllerApproveByParent({
    mutation: {
      onSuccess: () => {
        setSign(false);
        refetch();
      },
      onError: (e) => {
        setErrorMessage(e.message);
      },
    },
  });

  const signAbsent = () => {
    signAbsentMutate({
      uuid,
      data: {
        comment,
        signature: sigPadData,
      },
    });
  };

  useEffect(() => {
    absent?.parentComment && setComment(absent.parentComment);
  }, [absent]);

  const isLoading = isGetAbsentLoading || isSignAbsentLoading;

  return {
    clearSignature,
    canvasRef,
    absent,
    signAbsent,
    isSuccess,
    errorMessage,
    isGetAbsentError,
    comment,
    setComment,
    setSign,
    openSign,
    isLoading,
  };
};
