import { useState } from 'react';

import { useUserControllerUpdateMyInfoAtFirstLogin } from '../generated/endpoint';
import { RequestUpdateMyInfoAtFirstLoginDto } from '../generated/model';

export const useStudentFirstLogin = () => {
  const [isChannelTalk, setChannelTalk] = useState(false);

  const { mutate: studentFirstLoginMutate, isLoading: isStudentFirstLoginMutateLoading } =
    useUserControllerUpdateMyInfoAtFirstLogin({
      mutation: {
        onSuccess: () => {
          alert('정보 설정이 완료되었습니다!');
          window.location.reload();
        },
        onError: () => {
          setChannelTalk(true);
          alert('정보 설정에 실패했습니다.');
        },
      },
    });

  const isLoading = isStudentFirstLoginMutateLoading;

  const handleStudentFirstLogin = ({
    name,
    password,
    phone,
    nokName,
    nokPhone,
    hopeMajor,
    hopePath,
    birthDate,
  }: RequestUpdateMyInfoAtFirstLoginDto) => {
    studentFirstLoginMutate({
      data: {
        password,
        name,
        phone,
        nokName,
        nokPhone,
        hopeMajor,
        hopePath,
        birthDate,
      },
    });
  };

  return {
    isLoading,
    isChannelTalk,
    handleStudentFirstLogin,
  };
};
