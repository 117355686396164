import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/link-error';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'cross-fetch';

import { inMemoryCache } from './cache';

const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const uri = baseUrl.charAt(baseUrl.length - 1) === '/' ? baseUrl + 'graphql' : baseUrl + '/graphql';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.some((error) => error.message === 'Unauthorized')) {
    if (localStorage.length > 0) {
      localStorage.clear();
      window.location.reload();
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const childToken = undefined;
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: childToken ? `Bearer ${childToken} ` : token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = createUploadLink({ uri, fetch });

// 2023-03-06 사용하지 않음을 확인
export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: inMemoryCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
