import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Badge } from '../../reusable';
import { Fieldtrip } from '../../types';
import { makeStartEndToString } from '../../utils';

interface FieldtripNoticeCardProps {
  fieldtrip: Fieldtrip;
  userRole: string | undefined;
}

export const FieldtripNoticeCard: React.FC<FieldtripNoticeCardProps> = ({
  fieldtrip,
  userRole,
}) => {
  const { pathname, search } = useLocation();
  let role = '선생님';

  if (userRole === 'PREHEAD') {
    role = '학년계';
  }
  if (userRole === 'HEAD') {
    role = '학년부장';
  }
  if (userRole === 'PREPRINCIPAL') {
    role = '교무계';
  }
  if (userRole === 'PRINCIPAL') {
    role = '교무부장';
  }
  if (userRole === 'VICEPRINCIPAL') {
    role = '교감';
  }
  if (userRole === 'HEADPRINCIPAL') {
    role = '교장';
  }

  let text = <div className="text-sm text-red-500">{role} 승인 전</div>;

  switch (fieldtrip?.fieldtripStatus) {
    case 'BEFORE_PARENT_CONFIRM':
      text = <div className="text-sm text-red-500">학부모 승인 전</div>;
      break;
    case 'BEFORE_TEACHER_APPROVAL':
      text = <div className="text-sm text-red-500">선생님 승인 전</div>;
      break;
    case 'BEFORE_PREHEAD_APPROVAL':
      text = <div className="text-sm text-red-500">학년계 승인 전</div>;
      break;
    case 'BEFORE_HEAD_APPROVAL':
      text = <div className="text-sm text-red-500">학년부장 승인 전</div>;
      break;
    case 'BEFORE_PREPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교무계 승인 전</div>;
      break;
    case 'BEFORE_PRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교무부장 승인 전</div>;
      break;
    case 'BEFORE_VICEPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교감 승인 전</div>;
      break;
    case 'BEFORE_HEADPRINCIPAL_APPROVAL':
      text = <div className="text-sm text-red-500">교장 승인 전</div>;
      break;
    case 'PROCESSED':
      text = <div className="text-sm text-gray-600">처리 완료</div>;
      break;
    case 'RETURNED':
      text = <div className="text-sm text-brand-1">반려됨</div>;
      break;
    case 'DELETE_APPEAL':
      text = <div className="text-sm text-red-800">삭제 요청</div>;
      break;
  }
  return (
    <>
      <Link to={`/teacher/fieldtrip/notice/${fieldtrip.id}${search}`}>
        <div
          className={
            pathname.startsWith(`/teacher/fieldtrip/notice/${fieldtrip.id}`)
              ? 'w-full p-4 relative flex items-center justify-between bg-gray-50'
              : 'w-full p-4 relative flex items-center justify-between'
          }
        >
          <div>
            <h3 className="text-lg font-semibold">
              [통보서] {fieldtrip?.student?.name} {fieldtrip?.studentGradeKlass}{' '}
              {fieldtrip?.studentNumber}번
            </h3>
            <div className="text-sm font-semibold my-1 flex space-x-2">
              {fieldtrip?.type === 'HOME' ? (
                <Badge text="가정" tw={{ color: 'text-brand-1', backgroundColor: 'bg-brand-5' }} />
              ) : (
                <Badge text="교외" tw={{ color: 'text-brand-1', backgroundColor: 'bg-brand-5' }} />
              )}

              <div>
                {fieldtrip.type === 'SUBURBS' ? fieldtrip?.form || '교외체험학습' : '가정학습'}
              </div>
            </div>
            <div className="text-xs text-gray-500 pt-1">
              기간 :{' '}
              {fieldtrip?.startAt &&
                fieldtrip?.endAt &&
                makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}
            </div>
            <div className="text-xs text-gray-500 pt-1">신청일 : {fieldtrip.reportedAt}</div>
          </div>
          <div>{text}</div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
      </Link>
    </>
  );
};
