import React, { Component } from 'react';

import * as Survey from 'survey-react';

import 'survey-react/modern.css';
import { Constants } from '../../constants';

Survey.StylesManager.applyTheme('modern');
Survey.Serializer.findProperty('survey', '');

interface SurveyComponentProps {
  display?: boolean;
  content: string;
  btnHide?: boolean;
  value?: any;
  uploadFiles?: (files: any) => any;
  updateSA?: () => void;
  setContent: (content: string) => void;
}

type SurveyComponentState = {
  survey: any; // like this
};

export class SurveyComponent extends Component<SurveyComponentProps, SurveyComponentState> {
  constructor(props: any) {
    super(props);
    const json = JSON.parse(this.props.content);
    const survey = new Survey.Model(json);

    survey.focusFirstQuestionAutomatic = false;
    survey.focusOnFirstError = false;
    survey.locale = 'my';

    if (this.props.display) {
      survey.mode = 'display';
    }
    if (this.props.value) {
      try {
        for (const v of Object.entries(JSON.parse(this.props.value))) {
          const val: any = v;
          survey.setValue(val[0], val[1]);
        }
      } catch (err) {
        console.error(err);
      }
    }

    function updatePreview(newValue: any, container: HTMLDivElement) {
      container.innerHTML = '';
      newValue?.map((f: any) => {
        const aTag = document.createElement('a');
        aTag.target = '_blank';
        aTag.rel = 'noreferrer';
        aTag.download = 'true';
        aTag.href = f.content;

        const button = document.createElement('div');
        button.className = 'btn sv-btn sv-file__choose-btn';
        button.style.margin = '0.5rem';
        button.innerText = 'Download ' + f.name;
        aTag.appendChild(button);
        container.appendChild(aTag);
      });
    }

    survey.onComplete.add(async () => {
      await this.props.setContent(survey.getAllValues());
      if (this.props.updateSA) await this.props.updateSA();
    });

    survey.onAfterRenderQuestion.add(function (sender: any, options: any) {
      if (options.question.getType() === 'file') {
        // console.log(options.question);
        const container = document.createElement('div');
        container.className = 'my-preview-container';
        let fileElement = options.htmlElement.getElementsByClassName('sv_q_file')[0];
        if (!fileElement) {
          fileElement = options.htmlElement.getElementsByClassName('sv-file__decorator')[0];
        }
        fileElement.appendChild(container);
        options.question.onPropertyChanged.add(function (question: any, options: any) {
          if (options.name === 'value') {
            updatePreview(options.newValue, container);
          }
        });
        updatePreview(options.question.value, container);
      }
      if (options.question.getType() === 'image') {
        const container = document.createElement('a');
        container.className = 'sv-image';
        container.href = options.question.imageLink;
        container.target = '_blank';
        container.rel = 'noreferrer';
        const imageDivElement = options.htmlElement.getElementsByClassName('sv-image')[0];
        if (imageDivElement) {
          const parent = imageDivElement.parentNode;
          parent.replaceChild(container, imageDivElement);
          container.appendChild(imageDivElement);
        }
      }
    });

    // 참고: options는 console로 확인한 값으로 임의로 지정한 것으로 실제 타입과 상이할 수 있음
    survey.onUploadFiles.add(
      async (
        survey: any,
        options: {
          callback: any;
          files: File[];
          name: string;
          question: Survey.QuestionFileModel;
        },
      ) => {
        if (!this.props.uploadFiles) return;

        // 파일을 업로드
        const imagePaths = await this.props.uploadFiles(options.files); // res : 기존 uploadFiles mutation의 Promise return 값
        const newFiles = options.files.map((file, i) => ({
          name: file.name,
          file,
          content: `${Constants.imageUrl}${imagePaths?.[i]}`,
        }));

        // callback 함수내에서 survey 객체에 파일데이터를 저장함
        options.callback(
          'success',
          newFiles.map((f) => ({ file: f.file, content: f.content })),
        );

        const fileElement =
          document.getElementsByClassName('sv_q_file')[0] ||
          document.getElementsByClassName('sv-file__decorator')[0];
        const container = document.createElement('div');
        container.className = 'my-preview-container';
        updatePreview(
          newFiles.map((f) => ({ name: f.name, content: f.content })),
          container,
        );
        fileElement.appendChild(container);
        this.props.setContent(survey.getAllValues());
      },
    );

    this.state = {
      survey,
    };
  }

  componentDidMount() {
    const btn: any = document.getElementsByClassName('sv-footer__complete-btn')[0];
    if (btn?.style) {
      btn.style.width = '100%';
      btn.style.borderRadius = '1rem';
      btn.style.height = '3rem';
      btn.style.textAlign = 'center';
      btn.style.fontSize = '1rem';
      btn.style.backgroundColor = '#EE853A';
      btn.value = '제출하기';
      if (this.props.btnHide) {
        btn.style.display = 'none';
      }
    }
    const body: any = document.getElementsByTagName('body')[0];

    if (body.style) {
      body.style =
        '--primary-color:#EE853A; --secondary-color:#EE853A; --primary-text-color:#676a6c; --secondary-text-color:#a7a7a7; --inverted-text-color:#ffffff; --primary-hover-color:#EC7623; --selection-border-color:#EE853A; --primary-icon-color:#3d4d5d; --primary-bg-color:#fff; --secondary-bg-color:#f4f4f4; --primary-border-color:#e7eaec; --secondary-border-color:#ddd; --error-color:#ed5565;';
    }

    const questionTitles: any = document.getElementsByClassName('sv-title sv-question__title');

    if (questionTitles && questionTitles.length) {
      for (const questionTitle of questionTitles) {
        questionTitle.style.backgroundColor = 'rgba(238, 133, 58, 0.2)';
      }
    }
  }

  render() {
    return <Survey.Survey model={this.state.survey} />;
  }
}
