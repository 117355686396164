import { useSchoolsControllerFindOne } from '../generated/endpoint';

export const useSchools = (id: number | undefined) => {
  const { data: schoolInfo } = useSchoolsControllerFindOne(id as number, {
    query: {
      enabled: !!id,
    },
  });

  const privacyPolicyManagerArray = schoolInfo?.privacyPolicyManager.split('|') || [];

  let privacyPolicyManager = '';
  let privacyPolicyOrgName = '';
  let privacyPolicyManagerEmail = '';

  if (privacyPolicyManagerArray?.length === 3) {
    privacyPolicyManager = privacyPolicyManagerArray[0];
    privacyPolicyOrgName = privacyPolicyManagerArray[1];
    privacyPolicyManagerEmail = privacyPolicyManagerArray[2];
  }

  return { schoolInfo, privacyPolicyManager, privacyPolicyOrgName, privacyPolicyManagerEmail };
};
