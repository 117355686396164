import { useState } from 'react';

import { useOutingsControllerUpdateByTeacher } from '../generated/endpoint';
import { makeDateToString, makeTimeToString } from '../utils';

type Props = {
  outingData: any;
  setChangeMode: (b: boolean) => void;
  refetch: () => void;
};

const getMeridiemHours = (date: string | undefined) => {
  if (!date) return 0;
  return new Date(date).getHours();
};

export const useTeacherOutingUpdate = ({ outingData, setChangeMode, refetch }: Props) => {
  const [updateReason, setUpdateReason] = useState('');
  const [startAt, setStartAt] = useState(
    outingData?.startAt ? makeDateToString(new Date(outingData.startAt)) : '',
  );
  const [endAt, setEndAt] = useState(
    outingData?.endAt ? makeDateToString(new Date(outingData.endAt)) : '',
  );
  const [startHour, setStartHour] = useState(outingData ? getMeridiemHours(outingData.startAt) : 9);
  const [startMinute, setStartMinute] = useState(
    outingData?.startAt ? new Date(outingData.startAt).getMinutes() : 0,
  );
  const [endHour, setEndHour] = useState(outingData ? getMeridiemHours(outingData.endAt) : 16);
  const [endMinute, setEndMinute] = useState(
    outingData?.endAt ? new Date(outingData.endAt).getMinutes() : 40,
  );
  const [report, setReport] = useState(outingData?.type || '');
  const [reason, setReason] = useState(outingData?.reason || '');
  const [errorMessage, setErrorMessage] = useState('');

  const makeStartAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    const hour = Number(startHour);
    date.setHours(hour, Number(startMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const makeEndAt = () => {
    let date = new Date();
    if (startAt) {
      date = new Date(startAt);
    }
    if (report === '확인') {
      date = new Date(endAt);
    }
    const hour = Number(endHour);
    date.setHours(hour, Number(endMinute), 0);
    return makeDateToString(date) + ' ' + makeTimeToString(date);
  };
  const { mutate: updateOutingMutate, isLoading } = useOutingsControllerUpdateByTeacher({
    mutation: {
      onSuccess: () => {
        setChangeMode(false);
        refetch();
      },
      onError: (e) => {
        setErrorMessage(e.message);
      },
    },
  });

  const updateOuting = () => {
    updateOutingMutate({
      id: outingData.id,
      data: {
        type: report,
        startAt,
        endAt,
        reason,
        updateReason,
      },
    });
  };

  return {
    updateReason,
    setUpdateReason,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    report,
    setReport,
    reason,
    setReason,
    errorMessage,
    setErrorMessage,
    isLoading,
    updateOuting,
  };
};
