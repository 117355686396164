import FlareLane from '@flarelane/flarelane-web-sdk';
import { osState } from './ridge';

export enum Action {
  ERROR = 'error',
  LOG = 'log',
  FLARE_LANE = 'flareLane',
}

interface Command {
  kind?: 'webview';
  action: Exclude<
    keyof typeof Bridge,
    'prototype' | 'os' | 'postMessage' | 'handleMessageEvent'
  >;
  value?: any;
}

function tryParseJSON(jsonString: any) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

export class Bridge {
  static os: string;

  static flareLane(
    method: Exclude<
      keyof typeof FlareLane,
      'prototype' | '_directInitializeFromProxy' | '_proxySubscribe'
    >,
    value?: any,
  ) {
    Bridge.postMessage(Action.FLARE_LANE, { method, value });
    return;
  }

  static postMessage(action: Action, value?: any) {
    const data = JSON.stringify({ action, value });
    (window as any).ReactNativeWebView?.postMessage(data);
  }

  static handleMessageEvent({ data }: MessageEvent) {
    const command = tryParseJSON(data) as Command;
    if (command?.kind !== 'webview') return;
    if (Bridge[command.action]) {
      Bridge[command.action](command.value);
    } else {
      Bridge.postMessage(
        Action.ERROR,
        `(RN -> WebView) Invalid action: ${data}`,
      );
    }
  }

  static init({ os }: any) {
    osState.set(os ?? 'webView');
  }
}

// @ts-ignore
document.addEventListener('message', Bridge.handleMessageEvent);
window.addEventListener('message', Bridge.handleMessageEvent);
