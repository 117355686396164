import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import readXlsxFile, { Row } from 'read-excel-file';

import { ErrorBlank, Td } from '../../../components';
import {
  useActivityControllerDownloadSubmitters,
  useActivityControllerFindOne,
} from '../../../generated/endpoint';
import { Blank, Button, Section } from '../../../reusable';
import { downloadExcel } from '../../../utils/download-excel-util';

interface MatchParams {
  id: string;
}

interface ActivityDownloadPageProps extends RouteComponentProps<MatchParams> {
  groupName?: string | null;
}

const ActivityDownloadPage: FC<ActivityDownloadPageProps> = ({ match, groupName }) => {
  const { id } = match.params;
  const [rows, setRows] = useState<Row[]>();

  const {
    data: excelData,
    isLoading: isActivityDownloadLoading,
    isFetching,
    error,
  } = useActivityControllerDownloadSubmitters(Number(id));

  const { data: activity, isLoading: isGetActivityLoading } = useActivityControllerFindOne(+id);

  useEffect(() => {
    if (excelData) {
      new Promise((r) => r(excelData))
        .then((blob) => readXlsxFile(blob as Blob))
        .then((rows) => setRows(rows))
        .catch((e) => console.log(e));
    }
  }, [id, excelData]);

  if (error) {
    return <ErrorBlank />;
  }

  const handleDownload = () => {
    const filename = `${activity?.title ?? ''}-${groupName ?? ''}`;
    excelData && downloadExcel(excelData, filename);
  };

  const isLoading = isActivityDownloadLoading || isGetActivityLoading;

  return (
    <div className="bg-white ml-0.5 grid grid-cols-7 h-screen overflow-auto w-full">
      {isLoading && <Blank reversed />}
      {isFetching && <Blank reversed />}
      <div className="fixed top-0 py-3 px-5 pt-6 bg-white flex items-center space-x-2 w-full border-b border-gray-500">
        <div className="py-3 px-5 bg-white flex items-center space-x-2 w-full">
          <Button text="다운로드" tw={{ height: 'h-11' }} onClick={handleDownload} />
        </div>
      </div>

      <Section tw={{ marginTop: 'mt-24' }}>
        <table className="pt-24">
          <tbody>
            {rows?.map((row) => (
              <tr>
                {row.map((cell) => (
                  <Td innerClassName="min-w-max">{cell}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
    </div>
  );
};

export default withRouter(ActivityDownloadPage);
