import { useState } from 'react';

import { useFieldtripsControllerUpdateByTeacher } from '../generated/endpoint';
import { Fieldtrip, FieldtripType } from '../generated/model';

export const useTeacherFieldtripUpdate = ({
  fieldtrip,
  updateReason,
  refetchAll,
  setReadState,
  fieldtripType,
}: {
  fieldtrip: Fieldtrip;
  updateReason: string;
  refetchAll: () => void;
  setReadState: () => void;
  fieldtripType: string;
}) => {
  const [content, setContent] = useState(fieldtrip?.content || '');
  const [accommodation, setAccommodation] = useState(
    fieldtrip.type === 'HOME' ? '자택' : fieldtrip?.accommodation || '',
  );
  const [destination, setDestination] = useState(fieldtrip?.destination);
  const [guideName, setGuideName] = useState(fieldtrip?.guideName || '');
  const [guidePhone, setGuidePhone] = useState(fieldtrip?.guidePhone || '');

  const [relationship, setRelationship] = useState(fieldtrip?.relationship || '');
  const [purpose, setPurpose] = useState(fieldtrip?.purpose);
  const [homePlan, setHomePlan] = useState<any>(
    fieldtrip && fieldtrip?.type === 'HOME' ? JSON.parse(fieldtrip?.content || '[]') : [],
  );
  const [errorMessage, setErrorMessage] = useState('');

  const { mutate, isLoading } = useFieldtripsControllerUpdateByTeacher({
    mutation: {
      onSuccess: () => {
        alert('체험학습 신청서를 수정하였습니다.');
        refetchAll();
        setReadState();
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  const updateFieldtripByTeacher = () => {
    mutate({
      id: fieldtrip?.id,
      data: {
        content: fieldtrip.type === FieldtripType.HOME ? JSON.stringify(homePlan) : content,
        accommodation,
        destination,
        guideName,
        guidePhone,
        relationship,
        purpose,
        type: fieldtrip.type,
        form: fieldtripType,
        updateReason,
      },
    });
  };

  return {
    content,
    purpose,
    accommodation,
    destination,
    guideName,
    guidePhone,
    relationship,
    homePlan,
    setContent,
    setDestination,
    setPurpose,
    setAccommodation,
    setGuideName,
    setGuidePhone,
    setRelationship,
    setHomePlan,
    errorMessage,
    updateFieldtripByTeacher,
    isLoading,
  };
};
