import React, { useState } from 'react';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';

import { ReactComponent as SendIcon } from '../../../assets/svg/icon-send.svg';
import { CommentItem, FileItem, SuperSearchField, SurveyComponent } from '../../../components';
import { Constants } from '../../../constants';
import { useStudentActivityDetailRead } from '../../../container/student-activity-detail-read';
import { Activity, StudentActivity } from '../../../generated/model';

import {
  BottomFixedView,
  Divider,
  Label,
  List,
  Section,
  TextArea,
  utcToLocalDatetime,
  utcToLocalFromNow,
} from '../../../reusable';

interface ActivityDetailReadPageProps {
  studentActivity?: StudentActivity;
  activity?: Activity;
  type?: string;
  refetch: () => void;
  setSubmitState: () => void;
  setLoading: (state: boolean) => void;
  userId?: number;
}

export const ActivityDetailReadPage: React.FC<ActivityDetailReadPageProps> = ({
  studentActivity,
  activity,
  type,
  setLoading,
  userId,
}) => {
  if (!studentActivity) {
    return null;
  }

  const { text, setText, comments, handleCommentCreate, handleCommentUpdate, handleCommentDelete } =
    useStudentActivityDetailRead(studentActivity.id || 0, setLoading);

  const [hasImagesModalOpen, setImagesModalOpen] = useState<boolean[]>(
    studentActivity?.images?.map(() => false) || [],
  );

  const handleCommentCreateSubmit = async () => {
    if (text === '') {
      alert('텍스트 내용을 입력해주세요.');
      return;
    }

    handleCommentCreate({ content: text, studentActivityId: studentActivity.id || 0 });
  };

  // const comments = studentActivity?.comments?.sort(
  //   (a: any, b: any) => a.id - b.id,
  // );]

  let files: any[] = [];

  if (studentActivity?.files) {
    try {
      studentActivity?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      files = studentActivity?.files || [];
    }
  }

  let images: any[] = [];
  try {
    images = studentActivity?.images ? studentActivity?.images.map((el) => JSON.parse(el).url) : [];
  } catch (err) {
    images = studentActivity?.images || [];
  }

  return (
    <>
      <Section tw={{ backgroundColor: 'bg-gray-50' }}>
        {images?.map((image, i) => (
          <>
            <div
              className="w-full"
              onClick={() => {
                const newOpens = hasImagesModalOpen.slice();
                newOpens[i] = true;
                setImagesModalOpen(newOpens);
              }}
            >
              {/* <a href={image} target="_blank" rel="noreferrer"> */}
              <div className={`relative pb-3/5 rounded bg-gray-50`}>
                <img
                  className={`absolute w-full h-full rounded object-cover`}
                  src={`${Constants.imageUrl}${image}`}
                  loading="lazy"
                  alt=""
                />
              </div>
              {/* </a> */}
            </div>
            <div className="absolute">
              {hasImagesModalOpen[i] ? (
                <div
                  className={`fixed inset-0 w-full h-screen z-100 bg-black flex items-center justify-center`}
                  onClick={() => {
                    const newOpens = hasImagesModalOpen.slice();
                    newOpens[i] = false;
                    setImagesModalOpen(newOpens);
                  }}
                >
                  <ExifOrientationImg
                    src={`${Constants.imageUrl}${image}`}
                    alt=""
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ))}
        {files?.length ? (
          <div>
            {files?.map((f: any) => (
              <FileItem file={f} />
            ))}
          </div>
        ) : (
          <></>
        )}

        {activity?.isRecord && (
          <>
            <Label text="활동요약" />
            <div className="text-base font-semibold text-gray-600">{activity.explainText}</div>
            <TextArea value={studentActivity.summary} readOnly />
          </>
        )}
        {type === 'SURVEY' ? (
          <>
            <SurveyComponent
              content={activity?.content || '{}'}
              setContent={(c: string) => {}}
              display
              value={studentActivity?.content}
            />
          </>
        ) : (
          <div className="text-grey-3 whitespace-pre-line">{studentActivity?.content}</div>
        )}
        <Divider />
        {studentActivity?.updatedAt &&
          activity?.updatedAt &&
          studentActivity.updatedAt !== activity.updatedAt && (
            <div className="mt-3 text-brandblue-1">
              제출 완료 일시 :{' '}
              {utcToLocalDatetime(new Date(studentActivity.updatedAt).toISOString())} (
              {utcToLocalFromNow(new Date(studentActivity.updatedAt).toISOString())})
            </div>
          )}
      </Section>
      <List>
        {comments?.map((comment) => (
          <CommentItem
            comment={comment}
            userId={userId}
            updateComment={handleCommentUpdate}
            deleteComment={handleCommentDelete}
          />
        ))}
        <br />
        <br />
      </List>
      <BottomFixedView>
        <div className="flex items-center space-x-2 px-5 py-2">
          <SuperSearchField
            placeholder="댓글은 담당 선생님께만 노출됩니다."
            value={text}
            onChange={(e) => setText(e.target.value)}
            onSearch={handleCommentCreateSubmit}
          />
          <SendIcon onClick={handleCommentCreateSubmit} />
        </div>
      </BottomFixedView>
    </>
  );
};
