import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useOutingsControllerApprove,
  useOutingsControllerFindAllByTeacher,
} from '../generated/endpoint';
import { useSignature } from '../hooks/useSignature';
import { useStamp } from '../hooks/useStamp';
import { getEndDate, getStartDate, makeDateToString, twoMonthAgo } from '../utils';

const filters = [
  { id: 1, name: '모두', value: 'ALL' },
  { id: 2, name: '승인 전', value: 'BEFORE' },
  { id: 3, name: '승인 완료', value: 'AFTER' },
  { id: 4, name: '반려됨', value: 'RETURNED' },
];

export const useTeacherOutgoing = () => {
  const search = useLocation().search;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const limit = Number(params.get('limit') ?? '999');

  const { canvasRef, sigPadData, clearSignature } = useSignature();
  const { stamp, stampMode, stampImgUrl, updateStamp, setStampMode, isUploadStampLoading } =
    useStamp();

  const [studentName, setStudentName] = useState('');
  const [startDate, setStartDate] = useState(makeDateToString(twoMonthAgo(new Date())));
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const [filter, setFilter] = useState<any>(filters[0]);
  const [outingId, setOutingId] = useState(0);
  const [open, setOpen] = useState(false);

  const signature = stampMode ? stamp : sigPadData;
  const username = params.get('username') || '';

  const {
    data: outings,
    refetch: refetchOutgoings,
    error,
  } = useOutingsControllerFindAllByTeacher({
    startDate: getStartDate(startDate),
    endDate: getEndDate(endDate),
    outingStatus: filter?.value === 'ALL' ? undefined : filter.value,
    ...(username ? { username } : {}),
    limit,
  });

  // useEffect(() => {
  //   setPage(Number(params.get('page') ?? '1'));
  // }, [params]);

  const { mutate: approveOutingMutate } = useOutingsControllerApprove({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        clearSignature();
        refetchOutgoings();
      },
    },
  });

  const approveOuting = () => {
    approveOutingMutate({
      id: outingId,
      data: {
        signature,
      },
    });
  };

  const approveOutings = () => {
    outings?.items
      ?.filter((o) => !o.teacherSignature)
      .map((o) => {
        approveOutingMutate({
          id: o.id,
          data: { signature },
        });
      });
  };

  const isLoading = false;
  return {
    signature: { canvasRef, sigPadData, clearSignature },
    stamp: { stamp, stampMode, stampImgUrl, updateStamp, setStampMode, isUploadStampLoading },
    filters,
    filter,
    setFilter,
    studentName,
    setStudentName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    setPage,
    limit,
    open,
    setOpen,
    setOutingId,

    isLoading,
    outings,
    refetchOutgoings,
    error,
    approveOuting,
    approveOutings,
  };
};
