import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useFieldtripResultControllerApproveResult,
  useFieldtripsControllerApprove,
  useFieldtripsControllerGetFieldtripsByTeacher,
} from '../generated/endpoint';
import { FieldtripStatus, ResponsePaginatedFieldtripDto } from '../generated/model';
import { getEndDate, getStartDate, makeDateToString, MonthAgo } from '../utils';

// SelectMenus의 onChange parameter가 any로 받고 있어서 임시로 생성한 type임
export type SelectMenuFilterType = {
  id: number;
  name: string;
  value: FieldtripStatus | 'ALL';
};

type UseTeacherFieldTripProps = {
  clearSignature: () => void;
  sigPadData: string;
  stampMode: boolean;
  stampImgUrl: string | undefined;
};

export const useTeacherFieldtripResult = ({
  clearSignature,
  sigPadData,
  stampMode,
  stampImgUrl,
}: UseTeacherFieldTripProps) => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [startDate, setStartDate] = useState(makeDateToString(MonthAgo(new Date())));
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);
  const [fieldtripId, setFieldtripId] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [filter, setFilter] = useState<SelectMenuFilterType>({
    id: 1,
    name: '모두',
    value: 'ALL',
  });
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const [_studentName, set_studentName] = useState('');
  const limit = Number(params.get('limit') ?? '25') || 25;
  const studentName = params.get('username') || '';
  const [data, setData] = useState<ResponsePaginatedFieldtripDto>();

  const { refetch: refetchFieldtrips, error } = useFieldtripsControllerGetFieldtripsByTeacher(
    {
      page,
      limit,
      fieldtripStatus: filter?.value === 'ALL' ? undefined : filter.value,
      ...(studentName && { username: studentName }),
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
    },
    {
      query: {
        onSuccess: (res) => {
          const sorted = res?.items
            ?.slice()
            .sort(
              (a, b) =>
                (a.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM' ||
                a?.fieldtripResultStatus === 'BEFORE_TEACHER_APPROVAL'
                  ? -1
                  : 0) -
                (b.fieldtripResultStatus === 'BEFORE_PARENT_CONFIRM' ||
                b?.fieldtripResultStatus === 'BEFORE_TEACHER_APPROVAL'
                  ? -1
                  : 0),
            )
            .sort(
              (a, b) =>
                (a.fieldtripResultStatus === 'RETURNED' ? -1 : 0) -
                (b.fieldtripResultStatus === 'RETURNED' ? -1 : 0),
            );
          setData({
            items: sorted,
            total: res.total,
          });
        },
      },
    },
  );

  const { mutate, mutateAsync } = useFieldtripResultControllerApproveResult({
    mutation: {
      onSuccess: () => {
        setOpen(false);
        clearSignature();
        setLoading(false);
        setErrorMessage('');
        refetchFieldtrips();
      },
      onError: (error: any) => {
        setOpen(false);
        clearSignature();
        setLoading(false);
        setErrorMessage(error?.message);
        refetchFieldtrips();
      },
    },
  });

  const approveFieldtripResult = () => {
    mutate({
      id: fieldtripId,
      data: {
        signature: stampMode ? stampImgUrl : sigPadData,
      },
    });
  };

  // 일괄 승인하기 버튼
  const approveFieldtripResults = async () => {
    if (!data?.items.length) return;

    const approvePromiseList = data?.items.map(({ id }) => {
      return mutateAsync({
        id,
        data: {
          signature: stampMode ? stampImgUrl : sigPadData,
        },
      });
    });
    const result = await Promise.allSettled(approvePromiseList);
    result.forEach((settlement) => {
      // 승인 실패하는 경우 Error 번호 Alert으로 표시
      if (settlement.status === 'rejected') {
        const pathElements = settlement.reason.config.url.split('/') ?? []; // url 예시: fieldtrips/approve/22
        const id = pathElements[pathElements.length - 1];
        alert(`ID ${id}번의 승인을 실패하였습니다.\n(${settlement.reason.response.data.message})`);
      }
    });
  };

  return {
    params,
    error,
    errorMessage,
    isLoading,
    setLoading,
    setPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setFilter,
    setOpen,
    setAgreeAll,
    filter,
    set_studentName,
    _studentName,
    data,
    limit,
    page,
    setFieldtripId,
    agreeAll,
    open,

    refetchFieldtrips,
    approveFieldtripResult,
    approveFieldtripResults,
  };
};
