import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { GroupContainer } from '../../../container/group';
import { useTeacherFirstLogin } from '../../../container/teacher-first-login';
import { ResponseGroupDto } from '../../../generated/model';
import { Blank, Button, Checkbox, PhoneNumberField, Section, TextField } from '../../../reusable';
import { meStateNew } from '../../../store';
import { Validator } from '../../../utils/validator';

export const TeacherFirstLoginPage = () => {
  const history = useHistory();
  const meRecoil = useRecoilValue(meStateNew);

  const { teacherKlassGroups } = GroupContainer.useContext();
  const { isLoading, handleTeacherFirstLogin } = useTeacherFirstLogin();

  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');

  const [privacy, setPrivacy] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [showTeacherGroups, setShowTeacherGroups] = useState(true);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setName(meRecoil?.name || '');
    setPhone(meRecoil?.phone || '');
    if (meRecoil && !meRecoil?.firstVisit) {
      history.push('/teacher/canteen');
    }
  }, [history, meRecoil]);

  const spacere = / /g;

  const buttonDisabled =
    !password1 || !password2 || !name || !privacy || !policy || !phone || password1 !== password2;

  return (
    <div className="col-span-6 max-w-lg h-screen overflow-auto px-6 py-6">
      {isLoading && <Blank reversed />}
      <Section>
        <h1 className="text-2xl font-semibold">첫 로그인</h1>
        <span className="text-center flex justify-center text-sm sm:text-base">
          슈퍼스쿨에 오신 것을 환영합니다!
          <br />
          처음 로그인하시나요? 아래 정보를 입력해주세요.
        </span>
        <div />
        <div className="w-full">
          <TextField
            label="*이름"
            id="name"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={(e) => setName(String(e.target.value).replace(spacere, ''))}
            tw={{
              borderColor: name ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <TextField label="이메일" value={meRecoil?.email || ''} disabled />
        </div>
        <div className="w-full">
          <TextField label="학교" value={meRecoil?.school?.name || ''} readOnly />
        </div>
        <div className="w-full">
          <TextField
            label="*비밀번호"
            type="password"
            placeholder="비밀번호(6자 이상)"
            value={password1}
            onChange={(e) => setPassword1(String(e.target.value).replace(spacere, ''))}
            style={{ marginBottom: '0.5rem' }}
            tw={{
              borderColor:
                !password1 || password1 !== password2 ? 'border-red-700' : 'border-gray-300',
            }}
          />
          <TextField
            type="password"
            placeholder="비밀번호 확인(6자 이상)"
            value={password2}
            onChange={(e) => setPassword2(String(e.target.value).replace(spacere, ''))}
            tw={{
              borderColor:
                !password2 || password1 !== password2 ? 'border-red-700' : 'border-gray-300',
            }}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="*전화번호"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              borderColor: !phone ? 'rgba(185, 28, 28)' : '',
            }}
          />
        </div>
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => setShowTeacherGroups(!showTeacherGroups)}
        >
          <div className="text-4xl -mt-6 -mb-5">
            {showTeacherGroups ? <>&#x025BE;</> : <>&#x025B4;</>}
          </div>
          <div className="text-lg font-bold text-gray-800">과목/담당 학년</div>
        </div>
        {showTeacherGroups &&
          teacherKlassGroups?.map((group: ResponseGroupDto) => (
            <div key={group.id} className="w-full flex justify-between space-x-2 items-center">
              <TextField disabled value={group?.teacherGroupSubject || ''} />
              <TextField disabled value={group.name || ''} />
            </div>
          ))}
        <div className="space-y-2">
          <div className="font-bold">
            <Checkbox
              label="전체 동의"
              onChange={() => {
                const allChecked = privacy && policy;
                setPrivacy(!allChecked);
                setPolicy(!allChecked);
              }}
              checked={privacy && policy}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="policy" onChange={() => setPolicy(!policy)} checked={policy} />
            <Link to={`/terms-of-use`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                슈퍼스쿨이용약관 (필수)
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="privacy" onChange={() => setPrivacy(!privacy)} checked={privacy} />
            <Link to={`/privacy-policy/${meRecoil?.schoolId || ''}`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보처리방침 (필수)
              </span>
            </Link>
          </div>
        </div>
        <div className="text-pink-600">{errorMessage}</div>

        <Button
          // className="mt-20 bg-orange-2 text-white"
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            if (password1 !== password2) {
              setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
              return;
            }
            if (!Validator.passwordRule1(password1) && !Validator.passwordRule2(password1)) {
              setErrorMessage(
                '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
              );
              return;
            }
            handleTeacherFirstLogin({
              password: password1,
              phone: phone,
              name: name,
            });
          }}
        >
          정보 입력하고 시작하기
        </Button>
      </Section>
    </div>
  );
};
