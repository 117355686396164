import {
  useTimetablesV2ControllerFindOne,
  useTimetablesV2ControllerFindOneByStudent,
} from '../generated/endpoint';
import { getThisSemester, getThisYear } from '../utils';
import { UserContainer } from './user';
import { Role } from '../generated/model';
import { useRecoilValue } from 'recoil';
import { childState } from '../store';

export const useStudentTimetableDetail = () => {
  const { me, isMeLoading } = UserContainer.useContext();
  const child = useRecoilValue(childState);

  const year = +getThisYear();
  const semester = +getThisSemester();

  const {
    data: timetable,
    refetch: refetchTimetable,
    isLoading: isLoadingTimetable,
    error: errorTimetable,
  } = useTimetablesV2ControllerFindOne(
    year,
    semester,
    child?.klassGroupId || me?.klassGroupId || 0,
    {
      query: {
        enabled:
          !isMeLoading &&
          (me?.role !== Role.PARENT || !!child) &&
          !!year &&
          !!semester &&
          (!!child?.klassGroupId || !!me?.klassGroupId),
      },
      request: {
        headers: { 'child-user-id': child?.id },
      },
    },
  );

  const {
    data: studentTimetable,
    isLoading: isLoadingStudentTimetable,
    error: errorStudentTimetable,
  } = useTimetablesV2ControllerFindOneByStudent(
    { year, semester },
    {
      query: {
        enabled: !isMeLoading && (me?.role !== Role.PARENT || !!child) && !!year && !!semester,
      },
      request: {
        headers: { 'child-user-id': child?.id },
      },
    },
  );

  return {
    studentTimetable,
    timetable,
    refetchTimetable,
    error: errorTimetable || errorStudentTimetable,
    loading: isLoadingTimetable || isLoadingStudentTimetable,
  };
};
