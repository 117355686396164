import { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { GroupContainer } from './group';
import {
  useStudentGroupsControllerFindAllByKlasses,
  useTimetablesV2ControllerFindOne,
} from '../generated/endpoint';
import { meStateNew } from '../store';

export const useTeacherAttendance = ({ year, semester }: { year: number; semester: number }) => {
  const me = useRecoilValue(meStateNew);
  const { allKlassGroups } = GroupContainer.useContext();

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined);

  const groupId = selectedGroupId || me?.klassGroupId || undefined;

  const {
    data: timetable,
    isLoading: isTimetableLoading,
    error: errorTimetable,
  } = useTimetablesV2ControllerFindOne(year, semester, groupId as number, {
    query: {
      enabled: !!groupId,
    },
  });

  const klassInfo = allKlassGroups.find((group) => group.id === groupId);
  const grade = (klassInfo?.name && +klassInfo.name[0]) || 0;
  const isHomeRoomTeacher = klassInfo?.homeRoomTeacherId === me?.id;

  const { data: allKlassByGrade } = useStudentGroupsControllerFindAllByKlasses(
    { klasses: [grade] },
    {
      query: {
        enabled: !!grade,
      },
    },
  );

  return {
    timetable,
    groupId,
    isLoading: isTimetableLoading,
    error: errorTimetable,
    isHomeRoomTeacher,
    students: allKlassByGrade,
    setSelectedGroupId,
  };
};
