import { useQueryClient } from 'react-query';

import {
  useAttendanceControllerCreateAttendanceAbsent,
  useStudentRolesControllerUpsertStudentRole,
  useTimetablesV2ControllerUpdateSeatPosition,
  useUserControllerGetUserAttendance,
} from '../generated/endpoint';
import { RequestCreateAttendanceAbsentDto, RequestUpsertStudentRoleDto } from '../generated/model';

export const useTimeTableAttendancePageV2 = ({
  klassname,
  year,
  day,
  subject,
  teachername,
}: {
  klassname: string;
  year: string;
  day: string;
  subject: string;
  teachername: string;
}) => {
  const queryClient = useQueryClient();

  const { data: userAttendance, refetch: refetchUserAttendance } =
    useUserControllerGetUserAttendance({
      klassname,
      year,
      day,
      subject,
      teachername,
    });

  // console.log('userAttendance', userAttendance);

  const { mutateAsync: createAttendanceAbsentMutateAsync } =
    useAttendanceControllerCreateAttendanceAbsent({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(['timetable', year]);
        },
      },
    });

  const { mutateAsync: updateSeatPositionMutateAsync } =
    useTimetablesV2ControllerUpdateSeatPosition();

  const { mutateAsync: updateStudentRoleAsync } = useStudentRolesControllerUpsertStudentRole();

  const createAttendanceAbsent = (payload: RequestCreateAttendanceAbsentDto) => {
    return createAttendanceAbsentMutateAsync({ data: payload });
  };

  const updateSeatPosition = ({ id, seatPosition }: { id: number; seatPosition: string }) => {
    return updateSeatPositionMutateAsync({ id, data: { seatPosition } });
  };

  const updateStudentRole = (payload: RequestUpsertStudentRoleDto[]) => {
    return updateStudentRoleAsync({ data: payload });
  };

  return {
    userAttendance: userAttendance || [],
    refetchUserAttendance,
    createAttendanceAbsent,
    updateSeatPosition,
    updateStudentRole,
  };
};
