import { useState } from 'react';

import { useRecoilValue } from 'recoil';
import { useFieldtripsControllerFindAllByStudent } from '../generated/endpoint';
import { Fieldtrip } from '../generated/model';

import { childState } from '../store';

export const useStudentFieldtrip = () => {
  const child = useRecoilValue(childState);
  const [fieldtrips, setFieldtrips] = useState<Fieldtrip[]>();
  const { isLoading, error } = useFieldtripsControllerFindAllByStudent({
    query: {
      onSuccess: (res) => {
        const sorted = res
          ?.slice()
          ?.sort(
            (a: Fieldtrip, b: Fieldtrip) =>
              (a.fieldtripStatus === 'DELETE_APPEAL' ? -1 : 0) -
              (b.fieldtripStatus === 'DELETE_APPEAL' ? -1 : 0),
          )
          ?.sort(
            (a: Fieldtrip, b: Fieldtrip) =>
              (a.fieldtripStatus === 'RETURNED' ? -1 : 0) -
              (b.fieldtripStatus === 'RETURNED' ? -1 : 0),
          );
        setFieldtrips(sorted);
      },
    },
    request: {
      headers: {
        'child-user-id': child?.id,
      },
    },
  });

  return { fieldtrips, isLoading, error };
};
