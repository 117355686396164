import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';

import { QueryKey } from '../constants/query-key';
import { useNoticesControllerCreate, useNoticesControllerUpdate } from '../generated/endpoint';
import { Notice, NoticeCategoryEnum, UploadFileTypeEnum } from '../generated/model';
import { useFileUpload } from '../hooks/useFileUpload';
import { useImageAndDocumentManagement } from '../hooks/useImageAndDocumentManagement';
import { Routes } from '../routes';
import { isUpdateNoticeState } from '../store';
import { ImageObject } from '../type';
import { DocumentObject } from '../type/document-file-object';

const initNotice: Notice = {
  id: 0,
  title: '',
  content: '',
  category: NoticeCategoryEnum['공지'],
  images: [],
  files: [],
  userId: 0,
  createdAt: '',
  updatedAt: '',
  schoolId: 0,
};

export const useTeacherNoticeAdd = (noticeData: Notice | undefined) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const setIsNoticeUpdate = useSetRecoilState(isUpdateNoticeState);

  const [notice, setNotice] = useState<Notice>(noticeData || initNotice);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    imageObjectMap,
    documentObjectMap,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
  } = useImageAndDocumentManagement({
    initImageObjectMapFn: () => {
      const urlImageMap = new Map<number, ImageObject>();
      notice?.images.forEach((image, index) => {
        urlImageMap.set(index, { image, isDelete: false });
      });
      return new Map(urlImageMap);
    },
    initDocumentObjectMapFn: () => {
      const urlDocumentMap = new Map<number, DocumentObject>();
      notice?.files.forEach((file, index) => {
        urlDocumentMap.set(index, { document: file, isDelete: false });
      });
      return new Map(urlDocumentMap);
    },
  });

  const { mutate: createNoticeMutate, isLoading: isCreateNoticeLoading } =
    useNoticesControllerCreate({
      mutation: {
        onSuccess: (result) => {
          queryClient.invalidateQueries(QueryKey.teacher.notice);
          queryClient.invalidateQueries(QueryKey.teacher.noticeList);
          history.push(`${Routes.teacher.notice}/${result.id}`);
        },
        onError: () => {
          setErrorMessage('공지사항 등록에 실패했습니다.');
        },
      },
    });

  const { mutate: updateNoticeMutate, isLoading: isUpdateNoticeLoading } =
    useNoticesControllerUpdate({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.teacher.notice);
          queryClient.invalidateQueries(QueryKey.teacher.noticeList);
          setIsNoticeUpdate(false);
        },
        onError: () => {
          setErrorMessage('공지사항 수정에 실패했습니다.');
        },
      },
    });

  const { isUploadLoading, handleUploadFile } = useFileUpload();

  const isLoading = isCreateNoticeLoading || isUpdateNoticeLoading || isUploadLoading;
  const submitButtonDisabled =
    !notice?.title?.length || !notice?.content?.length || !notice?.category?.length;

  const handleSubmit = async ({
    notice,
    imageObjectMapParam,
    documentObjectMapParam,
  }: {
    notice: Notice | undefined;
    imageObjectMapParam: Map<number, ImageObject>;
    documentObjectMapParam: Map<number, DocumentObject>;
  }) => {
    try {
      if (!notice) {
        return;
      }
      // file image 처리
      const imageFiles = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.image instanceof File)
        .map((value) => value.image) as File[];
      const imageFileNames = await handleUploadFile(
        UploadFileTypeEnum['notices/images'],
        imageFiles,
      );

      // url image 처리
      const imageUrlNames = [...imageObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.image === 'string')
        .map((value) => value.image) as string[];

      const allImageNames = [...imageUrlNames, ...imageFileNames];

      // file document 처리
      const documentFiles = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && value.document instanceof File)
        .map((value) => value.document) as File[];
      const documentFileNames = await handleUploadFile(
        UploadFileTypeEnum['notices/files'],
        documentFiles,
      );

      const documentUrlNames = [...documentObjectMapParam.values()]
        .filter((value) => !value.isDelete && typeof value.document === 'string')
        .map((value) => value.document) as string[];

      const allDocumentNames = [...documentUrlNames, ...documentFileNames];

      if (notice.id) {
        const { id, title, content, category } = notice;
        updateNoticeMutate({
          id,
          data: {
            title,
            content,
            category,
            images: allImageNames,
            files: allDocumentNames,
          },
        });
      } else {
        const { title, content, category } = notice;
        createNoticeMutate({
          data: {
            title,
            content,
            category,
            images: allImageNames,
            files: allDocumentNames,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    notice,
    imageObjectMap,
    documentObjectMap,
    errorMessage,
    isLoading,
    submitButtonDisabled,
    setNotice,
    handleImageAdd,
    handleDocumentAdd,
    handleImageDeleteToggle,
    handleDocumentDeleteToggle,
    handleSubmit,
  };
};
