import React from 'react';

import { FindIdSuccessPage } from './FindIdSuccessPage';
import { StudentFindIdContainer } from '../../../container/student-find-id';
import {
  Alert,
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  Select,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { School } from '../../../types';

export const FindIdPageView = () => {
  const {
    schoolList,
    klassList,
    formData,
    errorMessage,
    isLoading,
    findIdResult,
    isSubmitButtonDisabled,
    setErrorMessage,
    findId,
    handleSchoolChange,
    handleGroupChange,
    handleStudentNumberChange,
  } = StudentFindIdContainer.useContext();

  if (findIdResult) {
    return <FindIdSuccessPage name={findIdResult.name} email={findIdResult.email || ''} />;
  }

  return (
    <>
      <TopNavbar
        title="아이디 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isLoading && <Blank />}
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Section>
        <div className="w-full">
          <Select
            label="*학교"
            placeholder="학교를 선택해주세요"
            onChange={(e) => handleSchoolChange(Number(e.target.value))}
          >
            {schoolList.map((school: School) => (
              <option key={school.id} value={school.id}>
                {school.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <Select
            label="*학년 / 반"
            placeholder="학년, 반을 선택해주세요"
            onChange={(e) => handleGroupChange(Number(e.target.value))}
          >
            {klassList?.map((group) => (
              <option key={group.id} value={group?.id}>
                {group?.name}
              </option>
            ))}
          </Select>
        </div>
        <TextField
          label="*출석번호"
          placeholder="출석번호를 입력해주세요"
          min={1}
          max={99}
          maxLength={2}
          value={formData.studentNumber ? +formData.studentNumber : ''}
          onChange={(e) => handleStudentNumberChange(Number(e.target.value))}
        />
        <div className="text-gray-400">
          *핸드폰 번호가 기억나지 않을 경우 고객센터로 문의 주시기 바랍니다.
        </div>
      </Section>
      <BottomFixedView>
        <Section>
          <a target="_blank" rel="noreferrer" href="http://superstudy.channel.io/">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
          <Button
            disabled={isSubmitButtonDisabled}
            tw={{
              backgroundColor: isSubmitButtonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            }}
            onClick={findId}
          >
            이메일 찾기
          </Button>
        </Section>
      </BottomFixedView>
    </>
  );
};

export const FindIdPage = () => {
  return (
    <StudentFindIdContainer.ContextProvider>
      <FindIdPageView />
    </StudentFindIdContainer.ContextProvider>
  );
};
