import { useParams } from 'react-router';

import { ReactComponent as FileUploadImg } from '../../../assets/svg/fileupload-image.svg';
import { SuperSurveyAddComponent } from '../../../components';
import { DocumentObjectComponent } from '../../../components/document-object-component';
import { ImageObjectComponent } from '../../../components/image-object-component';
import { useTeacherNewsletterAdd } from '../../../container/teacher-newsletter-add';
import { Newsletter, NewsletterCategoryEnum, NewsletterType } from '../../../generated/model';
import { Button, Checkbox, Label, Section, Select, TextArea, TextField } from '../../../reusable';

export const NewsletterAddPage = () => {
  const { id } = useParams<{ id: string }>();
  const {
    newsletter,
    category,
    surveyContent,
    imageObjectMap,
    documentObjectMap,
    buttonDisabled,
    errorMessage,
    setNewsletter,
    setCategory,
    setSurveyContent,
    handleImageAdd,
    handleImageDeleteToggle,
    handleDocumentAdd,
    handleDocumentDeleteToggle,
    handleCheckboxToggle,
    handleSubmit,
  } = useTeacherNewsletterAdd(id);

  return (
    <Section>
      <div className="bg-white p-4 border rounded-md mt-3">
        <span>전달 대상 선택</span>
        <div className="flex w-full justify-between mt-1">
          <Checkbox
            label="1학년"
            checked={newsletter.klasses.includes('1')}
            onChange={() => handleCheckboxToggle('1')}
          />
          <Checkbox
            label="2학년"
            checked={newsletter.klasses.includes('2')}
            onChange={() => handleCheckboxToggle('2')}
          />
          <Checkbox
            label="3학년"
            checked={newsletter.klasses.includes('3')}
            onChange={() => handleCheckboxToggle('3')}
          />
        </div>
      </div>

      <div>
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
          타입 선택
        </label>
        <div></div>
        <select
          placeholder="타입을 선택해주세요"
          value={newsletter.type}
          onChange={(e) =>
            setNewsletter((prevState) => ({ ...prevState, type: e.target.value as NewsletterType }))
          }
          className={`mt-1 block w-full pl-1 pr-1 py-3 text-base border border-gray-800 focus:outline-none focus:ring-indigo-500 focus:border-black sm:text-sm rounded-md`}
        >
          <option value={NewsletterType.NOTICE}>공지 타입</option>
          <option
            value={NewsletterType.STUDENT_PARENTS_SURVEY}
          >{`설문 타입 - 학생&학부모(서명)`}</option>
        </select>
      </div>
      <Select
        label="구분"
        id="category"
        value={category}
        onChange={(e) => {
          setNewsletter((prevState) => ({ ...prevState, category: e.target.value } as Newsletter));
          setCategory(e.target.value as NewsletterCategoryEnum);
        }}
      >
        <option selected hidden>
          구분을 선택해주세요
        </option>
        {Object.keys(NewsletterCategoryEnum).map((el) => (
          <option id={el} value={el} key={el}>
            {el}
          </option>
        ))}
      </Select>

      <div>
        <Label>이미지</Label>
        <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
          {[...imageObjectMap]?.map(([key, value]) => (
            <div key={key}>
              <ImageObjectComponent
                id={key}
                imageObjet={value}
                onDeleteClick={handleImageDeleteToggle}
              />
            </div>
          ))}
          {/* 이미지 업로드 컴포넌트 */}
          <div>
            <label htmlFor={`image-upload`}>
              <div className="relative pb-3/5 rounded border-2 border-dashed border-grey-5">
                <div className="absolute w-full h-full rounded object-cover bg-white">
                  <div className="flex flex-col justify-center items-center space-y-1 w-full h-full cursor-pointer">
                    <FileUploadImg />
                    <div className="text-brand-1">이미지를 업로드해주세요!!</div>
                  </div>
                </div>
              </div>
            </label>
            <input
              type="file"
              id={`image-upload`}
              className="hidden"
              accept=".pdf, .png, .jpeg, .jpg"
              onChange={handleImageAdd}
            />
          </div>
        </div>
      </div>
      <div>
        <Label>파일</Label>
        <div className="bg-white rounded border-2 border-dashed border-grey-5">
          {[...documentObjectMap]?.map(([key, value]) => (
            <div key={key}>
              <DocumentObjectComponent
                id={key}
                documentObjet={value}
                onDeleteClick={handleDocumentDeleteToggle}
              />
            </div>
          ))}
          <label htmlFor="file-upload">
            <div className="w-full flex space-x-1 justify-center items-center pt-0.5 pb-2.5 cursor-pointer">
              <span className="text-2xl text-grey-3 mb-1">+</span>
              <div className="text-brand-1">파일을 업로드해주세요 (다중 업로드 가능)</div>
            </div>
          </label>
        </div>
        <input
          type="file"
          id="file-upload"
          className="hidden"
          multiple
          onChange={handleDocumentAdd}
        />
      </div>

      <div>
        <TextField
          label="제목"
          id="title"
          placeholder="제목을 입력해주세요"
          value={newsletter.title}
          onChange={(e) => setNewsletter((prevState) => ({ ...prevState, title: e.target.value }))}
        />
      </div>

      <div>
        <TextArea
          label="내용"
          placeholder="내용을 입력해주세요."
          value={newsletter.content}
          onChange={(e) =>
            setNewsletter((prevState) => ({ ...prevState, content: e.target.value }))
          }
          style={{ borderWidth: '1px' }}
        />
      </div>
      {newsletter.type === NewsletterType.STUDENT_PARENTS_SURVEY && (
        <div>
          <SuperSurveyAddComponent
            setContent={(c: any) => setSurveyContent(c)}
            content={surveyContent}
          />
        </div>
      )}

      <div className="text-center mt-6">
        {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        <Button
          text={newsletter.id ? '수정하기' : '등록하기'}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
            height: 'h-11',
          }}
          style={{ width: '70%' }}
          disabled={buttonDisabled}
          onClick={() => {
            handleSubmit({
              newsletter,
              surveyContent: JSON.stringify(surveyContent),
              imageObjectMapParam: imageObjectMap,
              documentObjectMapParam: documentObjectMap,
            });
          }}
        />
      </div>
    </Section>
  );
};
