import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, withRouter } from 'react-router-dom';

import ActivityAddPage from './ActivityAdd';
import ActivityDetailPage from './ActivityDetailPage';
import ActivityDownloadPage from './ActivityDownloadPage';
import ActivitySubmitPage from './ActivitySubmitPage';
import { ActivitiesView, ErrorBlank, SelectMenus } from '../../../components';
import { Blank, Chip, HorizontalScrollView } from '../../../reusable';
import { GroupContainer } from '../../../container/group';
import { Group } from '../../../generated/model';

const ActivityPage: React.FC = () => {
  const history = useHistory();

  const selectedGroupId = Number(localStorage.getItem('selectedGroupId'));
  const { teacherKlubGroups, errorGroups, isLoadingGroups } = GroupContainer.useContext();

  const teacherGroupSubjects: string[] = [
    ...new Set(teacherKlubGroups.map((tg) => tg.teacherGroupSubject || '')),
  ];

  const [subject, setSubject] = useState<string>();

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [loadActivitiesView, setLoadActivitiesView] = useState(false);
  const [isUpdateState, setUpdateState] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedGroup) {
      setSelectedGroup(
        teacherKlubGroups.filter((g) => g.id === Number(selectedGroupId))?.[0] ||
          teacherKlubGroups[0] ||
          null,
      );
    }
  }, [teacherKlubGroups, selectedGroup, selectedGroupId]);

  if (errorGroups) return <ErrorBlank />;

  return (
    <div className="col-span-6 grid grid-cols-6">
      {isLoadingGroups && <Blank />}
      {/* 활동기록부 리스트 */}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="px-6 py-6">
          <div className="flex justify-between mb-5">
            <h1 className="text-2xl font-semibold">활동기록부</h1>
            <Link to="/teacher/activity/add">
              <button className="hover:bg-brand-1 hover:text-light_orange rounded-md bg-light_orange text-brand-1 text-sm px-4 py-2 focus:outline-none">
                추가하기
              </button>
            </Link>
          </div>
          <div className="w-36 mb-5 cursor-pointer">
            <SelectMenus
              items={teacherKlubGroups.map((tg) => ({ id: tg.id, name: tg.name }))}
              value={selectedGroup || undefined}
              allText="선택하기"
              onChange={(group: Group) => {
                if (group?.id) {
                  localStorage.setItem('selectedGroupId', group.id.toString());
                  setSelectedGroup(group);
                  setSubject(undefined);
                  history.replace('/teacher/activity');
                }
              }}
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        {/* 활동기록부 게시판 */}
        <div className="h-screen-10 overflow-y-auto scroll-box">
          <HorizontalScrollView twInner={{ marginY: 'my-4' }}>
            <Chip
              text={'전체'}
              onClick={() => setSubject(undefined)}
              active={!subject}
              tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
            />
            {teacherGroupSubjects.map((el: string) => (
              <Chip
                key={el}
                text={el}
                onClick={() => setSubject(el)}
                tw={{ paddingY: 'py-1.5', minWidth: 'min-w-max' }}
                active={el === subject}
              />
            ))}
          </HorizontalScrollView>
          {selectedGroup && (
            <ActivitiesView
              groupId={selectedGroup.id}
              subject={subject}
              loadActivitiesView={loadActivitiesView}
              setUpdateState={() => setUpdateState(false)}
              subjects={teacherGroupSubjects}
            />
          )}
        </div>
      </div>
      <div className="col-span-4 bg-gray-50 scroll-box">
        <Switch>
          <Route
            path="/teacher/activity/add"
            component={() => (
              <ActivityAddPage
                refetch={() => {
                  setLoadActivitiesView(!loadActivitiesView);
                }}
              />
            )}
          />
          <Route
            path="/teacher/activity/submit/:id"
            component={() => <ActivitySubmitPage groupId={selectedGroup?.id} />}
          />

          <Route
            path="/teacher/activity/download/:id"
            component={() => <ActivityDownloadPage groupName={selectedGroup?.name} />}
          />

          <Route
            path="/teacher/activity/:id"
            component={() => (
              <ActivityDetailPage
                isUpdateState={isUpdateState}
                setUpdateState={(b: boolean) => setUpdateState(b)}
                refetch={() => {
                  setLoadActivitiesView(!loadActivitiesView);
                }}
              />
            )}
          />
          <Route
            path="/teacher/activity"
            component={() => <div className="w-full h-full flex items-center justify-center"></div>}
          />
        </Switch>
      </div>
    </div>
  );
};

// @ts-ignore
export default withRouter(ActivityPage);
