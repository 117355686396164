import React, { FC, useEffect, useState } from 'react';
import { Blank, Select } from '../../../reusable';
import { ErrorBlank } from '../../../components';
import { TimetableDetailPage } from './TimetableDetailPage';
import { GroupContainer } from '../../../container/group';

export const TimetablePage: FC = () => {
  const { allKlassGroups, errorGroups, isLoadingGroups } = GroupContainer.useContext();

  const date = new Date();
  const [year, setYear] = useState(date.getFullYear());
  const [semester, setSemester] = useState(date.getMonth() + 1 > 6 ? 2 : 1);
  const [groupId, setGroupId] = useState<number>(allKlassGroups[0]?.id || 0);

  useEffect(() => {
    if (!groupId) {
      setGroupId(allKlassGroups[0]?.id || 0);
    }
  }, [allKlassGroups]);

  return (
    <div className="col-span-6 ">
      {isLoadingGroups && <Blank reversed />}
      {errorGroups && <ErrorBlank />}
      <div className="w-full h-screen flex justify-center overflow-y-scroll scroll-box">
        <div className="w-full my-8 hidden md:block">
          <div className="w-full flex items-center justify-center space-x-2 mb-12">
            <Select value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {[...Array(date.getFullYear() - 2021 + 1).keys()].map((i) => (
                <option value={2021 + i}>{2021 + i}년</option>
              ))}
            </Select>
            <Select
              value={groupId}
              onChange={(e) => setGroupId(Number(e.target.value))}
              placeholder="반 선택"
            >
              {allKlassGroups.map((group) => (
                <option value={group.id}>{group.name}</option>
              ))}
            </Select>
            <Select value={semester} onChange={(e) => setSemester(Number(e.target.value))}>
              <option value={1}>1학기</option>
              <option value={2}>2학기</option>
            </Select>
          </div>
          <TimetableDetailPage year={year} semester={semester} groupId={groupId || 0} />
        </div>
      </div>
    </div>
  );
};
