import { useState } from 'react';

import {
  useFieldtripsControllerDeny,
  useFieldtripsControllerFindOne,
  useFieldtripsControllerRequestDelete,
} from '../generated/endpoint';

type UseFieldtripDetailProps = {
  refetchFieldtrips: () => void;
  id: string;
};
export const useTeacherFieldtripDetail = ({ refetchFieldtrips, id }: UseFieldtripDetailProps) => {
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deny, setDeny] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const refetchAll = () => {
    refetchFieldtrips();
    return refetchFieldtrip();
  };

  const {
    data: fieldtrip,
    isLoading,
    refetch: refetchFieldtrip,
  } = useFieldtripsControllerFindOne(Number(id));

  const { mutate: denyFieldtrip } = useFieldtripsControllerDeny({
    mutation: {
      onSuccess: () => {
        setDeny(false);
        setLoading(false);
        return refetchAll();
      },
      onError: (error: any) => {
        setLoading(false);
        setErrorMessage(error?.message);
        return refetchAll();
      },
    },
  });

  const { mutate: deleteAppealFieldtrip } = useFieldtripsControllerRequestDelete({
    mutation: {
      onSuccess: () => {
        setDeleteAppeal(false);
        setLoading(false);
        return refetchAll();
      },
      onError: (error: any) => {
        setLoading(false);
        setErrorMessage(error?.message);
        return refetchAll();
      },
    },
  });

  return {
    // api
    denyFieldtrip,
    deleteAppealFieldtrip,
    isLoading,
    fieldtrip,

    // state
    deleteAppeal,
    loading,
    deny,
    errorMessage,

    // set state
    setDeleteAppeal,
    setDeny,
    setLoading,

    // function
    refetchAll,
  };
};
