import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { OutingAddPage } from './OutingAddPage';
import { ErrorBlank } from '../../../components';
import { useStudentOutingDetail } from '../../../container/student-outing-detail';
import { UserContainer } from '../../../container/user';
import { BackButton, Blank, Button, IconButton, Section, TopNavbar } from '../../../reusable';
import { makeDateToStringByFormat, makeStartEndToStringType2 } from '../../../utils';

interface MatchParams {
  id: string;
}

export const OutingDetailPage: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const history = useHistory();
  const { id } = match.params;

  const { me } = UserContainer.useContext();
  const { outing, refetchOuting, isLoading, error, deleteOuting, errorMessage } =
    useStudentOutingDetail(Number(id));

  const school = me?.school;
  const schoolName = me?.school?.name;

  const [mode, setMode] = useState(false);

  const isNotParent = me?.role !== 'PARENT';

  if (mode && outing) {
    return (
      <OutingAddPage
        outingData={outing}
        goDetail={() => {
          refetchOuting();
          setMode(false);
        }}
      />
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      {isLoading && <Blank />}
      <TopNavbar
        title="조퇴 / 외출 / 확인증"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <div className="relative" style={{ height: 'calc(15vh + 5rem' }}>
        <div className="w-full bg-grey-2" style={{ height: 'calc(15vh + 0rem' }} />
        <div className="absolute w-full h-auto" style={{ top: 'calc(15vh - 3rem)' }}>
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h1 className="text-white text-xl font-semibold pb-10 -m-4">{outing?.student?.name}</h1>
            <div
              className={`h-16 w-16 rounded-full bg-center bg-no-repeat bg-cover bg-gray-400 -m-5`}
              style={{ backgroundImage: `url(${outing?.student.profile})` }}
            />
            <div className="text-gray-400 mt-5">
              {outing ? `${outing.studentGradeKlass} ${outing.studentNumber}번` : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-1 bg-grey-6"></div>
      <div className="py-2">
        <div className="w-full flex justify-center space-x-2 items-center">
          {school?.mark && (
            <IconButton
              className="w-8 h-8"
              icon={<img src={school?.mark} alt="" loading="lazy" />}
            />
          )}
          <div className="font-bold">{schoolName}</div>
        </div>
      </div>
      <div className="w-full h-3 bg-grey-6"></div>
      <Section>
        {outing?.outingStatus === 'RETURNED' && (
          <div className="mt-3">
            <div className="text-red-500">반려 이유</div>
            <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
              {outing?.notApprovedReason}
            </div>
          </div>
        )}
        {outing?.outingStatus === 'DELETE_APPEAL' && (
          <div className="mt-3">
            <div className="text-red-500">삭제 이유</div>
            <div className="rounded-lg w-full bg-light_orange px-5 py-3 whitespace-pre-line">
              {outing?.deleteReason}
            </div>
          </div>
        )}
        <div className="flex">
          <div className="w-1/4 min-w-max text-gray-800 flex-shrink-0">유형</div>
          <div className="w-2/4 text-gray-500">{outing?.type}</div>
        </div>
        <div className="flex">
          <div className="w-1/4 min-w-max text-gray-800 flex-shrink-0">사유</div>
          <div className="w-2/4 text-gray-500">{outing?.reason}</div>
        </div>
        <div className="flex">
          <div className="w-1/4 min-w-max text-gray-800 flex-shrink-0">일시</div>
          <div className="w-2/4 text-gray-500 whitespace-pre-line">
            {outing?.startAt &&
              outing?.endAt &&
              makeStartEndToStringType2(outing.startAt, outing.endAt)}
          </div>
        </div>
        <div className="w-full flex justify-end space-x-2 items-center">
          <div>
            <div className="pb-3 font-bold">
              {outing?.reportedAt && makeDateToStringByFormat(new Date(outing.reportedAt))}
            </div>
            <div className="font-bold">담임: {outing?.teacher?.name} (인)</div>
          </div>

          {outing?.teacherSignature ? (
            <img style={{ width: '100px' }} src={outing?.teacherSignature} alt="" />
          ) : (
            '선생님 승인 전'
          )}
        </div>

        {isNotParent && (
          <>
            <Button
              disabled={
                outing?.outingStatus !== 'BEFORE_TEACHER_APPROVAL' &&
                outing?.outingStatus !== 'RETURNED'
              }
              onClick={() => setMode(true)}
              tw={{
                backgroundColor:
                  outing?.outingStatus === 'PROCESSED' ? 'bg-gray-300' : 'bg-brand-1',
              }}
            >
              수정하기
            </Button>
            <div className="text-red-500">{errorMessage}</div>
            {outing?.outingStatus === 'DELETE_APPEAL' && (
              <Button
                onClick={() => {
                  deleteOuting();
                }}
                tw={{
                  backgroundColor: 'bg-red-500',
                }}
              >
                삭제하기
              </Button>
            )}
          </>
        )}
      </Section>
    </>
  );
};
