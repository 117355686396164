import { FC, useState } from 'react';

import { FindPasswordSuccessPage } from './FindPasswordSuccessPage';
import { useFindPassword } from '../../../container/find-password';
import {
  Alert,
  BackButton,
  Blank,
  BottomFixedView,
  Button,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';

export const FindPasswordPage: FC = () => {
  const [email, setEmail] = useState('');
  const { refetch, isLoading, isSuccess, errorMessage, setErrorMessage } = useFindPassword(email);

  if (isSuccess) {
    return <FindPasswordSuccessPage email={email} />;
  }

  return (
    <>
      <TopNavbar
        title="비밀번호 찾기"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isLoading && <Blank />}
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Section>
        <h1 className="text-2xl font-bold">
          비밀번호 설정을 위해 <br /> 가입한 이메일을 입력해주세요.
        </h1>
        <div className="w-full">
          <TextField
            placeholder="you@example.com"
            label="이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="text-gray-400">
          *이메일이 수신되지 않을 경우 고객센터로 문의 주시기 바랍니다.
        </div>
      </Section>
      <BottomFixedView tw={{ paddingBottom: 'pb-20' }}>
        <Section>
          <a target="_blank" rel="noreferrer" href="http://superstudy.channel.io/">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
          <Button
            disabled={!email}
            tw={{ backgroundColor: !email ? 'bg-gray-300' : 'bg-brand-1' }}
            onClick={() => refetch()}
          >
            이메일로 발송
          </Button>
        </Section>
      </BottomFixedView>
    </>
  );
};
