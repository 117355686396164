import { useEffect, useState } from 'react';

import { Checkbox, Radio, RadioGroup, Section, TextField } from '../../reusable';

interface SuperSurveyComponentProps {
  surveyContent: string;
  setContent: (content: any) => void;
  content: any;
  readOnly?: boolean;
}

export const SuperSurveyComponent = ({
  surveyContent,
  setContent,
  content,
  readOnly,
}: SuperSurveyComponentProps) => {
  const [survey, setSurvey] = useState<any[]>([]);
  useEffect(() => {
    if (surveyContent) {
      try {
        setSurvey(JSON.parse(surveyContent));
      } catch (err) {
        console.error(err);
      }
    }
  }, [surveyContent]);
  if (survey.length === 0) {
    return null;
  }

  return (
    <Section>
      {survey.map((question: any) => {
        const onChangeValue = (value: string) => {
          const _content = JSON.parse(JSON.stringify(content));
          _content[question.id] = value;
          setContent(_content);
        };
        const onChangeCheckboxValue = (value: string) => {
          const _content = JSON.parse(JSON.stringify(content));
          if (_content[question.id]) {
            if (_content[question.id].includes(value)) {
              _content[question.id] = _content[question.id].filter((v: string) => v !== value);
            } else {
              _content[question.id].push(value);
            }
          } else {
            _content[question.id] = [value];
          }
          setContent(_content);
        };
        const value = content[question.id];
        //
        switch (question.type) {
          case 'text':
            return (
              <div className="w-full flex flex-col space-y-2" key={question.id}>
                <div className="text-lg font-bold">{question.title}</div>
                <TextField
                  onChange={(e) => onChangeValue(e.target.value)}
                  value={content[question.id]}
                  readOnly={readOnly}
                />
              </div>
            );
          case 'checkbox':
            return (
              <div className="flex flex-col space-y-2" key={question.id}>
                <div className="text-lg font-bold">{question.title}</div>
                {question?.choices?.map((c: any, index: number) => (
                  <div key={index} className="flex items-center justify-between space-x-2">
                    <Checkbox
                      label={c}
                      name={question.id}
                      onChange={() => onChangeCheckboxValue(c)}
                      checked={value?.includes(c)}
                      readOnly={readOnly}
                    />
                  </div>
                ))}
              </div>
            );
          case 'radiobutton':
            return (
              <div className="flex flex-col space-y-2" key={question.id}>
                <div className="text-lg font-bold">{question.title}</div>
                <RadioGroup onChange={(e) => onChangeValue(e.target.value)}>
                  {question?.choices?.map((c: any, index: number) => (
                    <Radio
                      key={index}
                      label={c}
                      name={question.id}
                      value={c}
                      checked={value === c}
                      readOnly={readOnly}
                    />
                  ))}
                </RadioGroup>
              </div>
            );
        }
      })}
    </Section>
  );
};
