import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Section, TopNavbar } from '../../../reusable';
import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { SuperModal } from '../../../components';

export const ApplyPage: FC = () => {
  const [modalopen, setModalopen] = useState(false);
  const history = useHistory();
  return (
    <>
      <TopNavbar title="출결" left={<div className="h-15 w-10" />} />

      <Section>
        {/* <div className=" border-b-2 border-gray-6 mt-3">
          <div className="w-full flex items-center justify-center my-2 ">
            <Button tw={{ width: 'w-full' }}>반려된 신청서 0건 →</Button>
          </div>
          <div className="w-full flex items-center justify-center my-2 mb-5">
            *반려된 신청서가 있을 경우, 신청서를 수정해주세요.
          </div>
        </div> */}
        <div
          className="flex justify-between items-center pb-4 border-b border-grey-9 cursor-pointer"
          onClick={() => history.push('/student/outing')}
        >
          <div className="text-gray-800 font-sfpro font-bold">
            조퇴/외출/확인증
          </div>
          <RightArrow />
        </div>
        <div
          className="flex justify-between items-center pb-4 border-b border-grey-9 cursor-pointer"
          onClick={() => history.push('/student/absent')}
        >
          <div className="text-gray-800 font-sfpro font-bold">출결 신고서</div>
          <RightArrow />
        </div>
        <div
          className="flex justify-between items-center pb-4 border-b border-grey-9 cursor-pointer"
          onClick={() => history.push('/student/fieldtrip')}
        >
          <div className="text-gray-800 font-sfpro font-bold">체험학습</div>
          <RightArrow />
        </div>
      </Section>
      <SuperModal
        modalOpen={modalopen}
        setModalClose={() => setModalopen(false)}
      >
        <div className="w-full flex items-center justify-center mt-14">
          <button className="bg-white border border-brand-1 text-brand-1 w-4/5 rounded-lg py-5 font-bold">
            🏔 교외 체험학습
          </button>
        </div>
        <div className="w-full flex items-center justify-center my-5 mb-10">
          <button className="bg-white border border-brandblue-1 text-brandblue-1 w-4/5 rounded-lg py-5 font-bold">
            🏠 가정학습
          </button>
        </div>
        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold">
            닫기
          </button>
        </div>
      </SuperModal>
    </>
  );
};
