import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SomeIcon } from '../../../assets/svg/edit.svg';
import { ReactComponent as RightArrow } from '../../../assets/svg/mypage-right-arrow.svg';
import { SuperModal, ErrorBlank } from '../../../components';
import { useStudentFieldtrip } from '../../../container/student-fieldtrip';
import { UserContainer } from '../../../container/user';
import { Role } from '../../../generated/model';
import {
  TopNavbar,
  BackButton,
  Section,
  Button,
  Badge,
  Blank,
  useRecoilValue,
} from '../../../reusable';
import { childState } from '../../../store';
import { makeStartEndToString } from '../../../utils';

export const FieldtripPage: React.FC = () => {
  const [modalopen, setModalopen] = useState(false);
  const { me } = UserContainer.useContext();
  const child = useRecoilValue(childState);
  const { fieldtrips, isLoading, error } = useStudentFieldtrip();

  const isNotParent = me?.role !== 'PARENT';
  const school = me?.school;
  const history = useHistory();

  return (
    <>
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title=" 체험학습"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section style={{ backgroundColor: '#F7F7F7' }}>
        <div>
          <h1 className="text-sm font-bold">체험학습 잔여일</h1>
          {school?.fieldtripDays}일 중
          <span className="text-brand-1">
            {' '}
            {me?.role === Role.PARENT
              ? child?.remainDaysOfFieldtrip
              : me?.remainDaysOfFieldtrip || 0}
            일{' '}
          </span>
          남았습니다.
        </div>
      </Section>
      <Section>
        {fieldtrips?.map((fieldtrip) => {
          let state;
          switch (fieldtrip.fieldtripStatus) {
            case 'DELETE_APPEAL':
              state = '삭제 요청';
              break;
            case 'RETURNED':
              state = '반려됨';
              break;
            case 'PROCESSED':
              state = '완료';
              break;
            default:
              state = '승인 중';
              break;
          }
          let resultState;
          switch (fieldtrip.fieldtripResultStatus) {
            case 'DELETE_APPEAL':
              resultState = '삭제 요청';
              break;
            case 'RETURNED':
              resultState = '반려됨';
              break;
            case 'PROCESSED':
              resultState = '완료';
              break;
            default:
              resultState = '승인 중';
              break;
          }
          return (
            <>
              <div>
                <div className="flex my-3 mb-3 text-md text-bold">
                  <Badge
                    tw={{
                      backgroundColor: 'bg-light_orange' as any,
                      color: 'text-brand-1',
                    }}
                  >
                    {fieldtrip.type === 'HOME' ? '가정' : '교외'}
                  </Badge>
                  {fieldtrip.type === 'HOME' ? '가정학습' : '교외 체험학습'}
                </div>
                <div className="text-sm text-gray-400 ">
                  {fieldtrip?.startAt &&
                    fieldtrip?.endAt &&
                    makeStartEndToString(fieldtrip.startAt, fieldtrip.endAt)}
                </div>
              </div>

              <div className="bg-white shadow-lg p-4 rounded-md space-y-4 ">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => history.push(`/student/fieldtrip/${fieldtrip.id}`)}
                >
                  <div className="text-gray-800 font-sfpro font-bold">
                    <Badge>{state}</Badge> 신청서 확인
                  </div>
                  <RightArrow />
                </div>
                {fieldtrip?.fieldtripStatus === 'PROCESSED' && (
                  <div
                    className="flex justify-between items-center pb-4 pt-4 border-b border-t border-grey-6 cursor-pointer"
                    onClick={() => history.push(`/student/fieldtrip/notice/${fieldtrip.id}`)}
                  >
                    <div className="text-gray-800 font-sfpro font-bold">
                      <Badge>완료</Badge> 통보서 확인
                    </div>
                    <RightArrow />
                  </div>
                )}
                {fieldtrip?.fieldtripStatus === 'PROCESSED' && (
                  <>
                    {fieldtrip?.resultText ? (
                      <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => history.push(`/student/fieldtrip/result/${fieldtrip.id}`)}
                      >
                        <div className="text-gray-800 font-sfpro font-bold">
                          <Badge>{resultState}</Badge> 결과보고서 확인
                        </div>
                        <RightArrow />
                      </div>
                    ) : (
                      <div className="w-full flex items-center justify-center my-2 ">
                        {isNotParent && (
                          <Button
                            tw={{
                              width: 'w-full',
                              backgroundColor: 'bg-gray-600',
                            }}
                            onClick={() =>
                              fieldtrip?.type === 'SUBURBS'
                                ? history.push(
                                    `/student/fieldtrip/add/report/suburbs/${fieldtrip.id}`,
                                  )
                                : history.push(`/student/fieldtrip/add/report/home/${fieldtrip.id}`)
                            }
                          >
                            결과보고서를 작성해주세요. 👆
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="w-full h-0.5 bg-gray-100" />
            </>
          );
        })}
        {isNotParent && (
          <button
            className="fixed bottom-20 right-4 bg-brand-1 rounded-full w-14 h-14"
            onClick={() => setModalopen(true)}
          >
            <SomeIcon className="ml-4" />
          </button>
        )}
      </Section>
      <SuperModal modalOpen={modalopen} setModalClose={() => setModalopen(false)}>
        <div className="w-full flex items-center justify-center mt-14">
          <button
            className="bg-white border border-brand-1 text-brand-1 w-4/5 rounded-lg py-5 font-bold"
            onClick={() => history.push('/student/fieldtrip/add/suburbs')}
          >
            🏔 교외 체험학습
          </button>
        </div>
        <div className="w-full flex items-center justify-center my-5 mb-10">
          <button
            className="bg-white border border-brandblue-1 text-brandblue-1 w-4/5 rounded-lg py-5 font-bold"
            onClick={() => history.push('/student/fieldtrip/add/home')}
          >
            🏠 가정학습
          </button>
        </div>
        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button
            className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold"
            onClick={() => setModalopen(false)}
          >
            닫기
          </button>
        </div>
      </SuperModal>
    </>
  );
};
