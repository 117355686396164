import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';
import Viewer from 'react-viewer';

import { ErrorBlank } from '../../../components';
import { Constants } from '../../../constants';
import { useStudentBoardDetail } from '../../../container/student-board-detail';
import { BackButton, Blank, Section, TopNavbar, utcToLocalDatetime } from '../../../reusable';
import { getFileNameFromUrl } from '../../../utils/file-util';

export const BoardDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const { board, isBoardLoading, images, documents, viewerImages, errorMessage } =
    useStudentBoardDetail(+id);

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      {isBoardLoading && <Blank />}
      {errorMessage && <ErrorBlank />}
      <TopNavbar
        title="학급 게시판"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <Section>
        <div className="flex items-center justify-between">
          <div className="rounded-md px-3 py-1 bg-red-50 text-sm font-bold text-red-500 w-max">
            {board?.category || '학급게시판'}
          </div>
          <div className="text-sm text-gray-500">{board?.writer?.name}</div>
        </div>
        <div>
          <div className="text-xl font-bold text-grey-1">{board?.title}</div>
          <div className="text-grey-5 text-sm mb-2">
            {utcToLocalDatetime(board?.createdAt?.toString() || '')}
          </div>
        </div>
        <div className="w-full grid grid-flow-row grid-cols-3 gap-2">
          {images?.map((image, i: number) => (
            <div
              key={i}
              className="w-full"
              onClick={() => {
                setActiveIndex(i);
                setImagesModalOpen(true);
              }}
            >
              <div className={`relative pb-3/5 rounded bg-gray-50`}>
                <LazyLoadImage
                  src={`${Constants.imageUrl}${image}`}
                  alt={''}
                  loading={'lazy'}
                  className={`absolute w-full h-full rounded object-cover`}
                />
              </div>
            </div>
          ))}
        </div>
        <div>
          {documents?.map((fileUrl: string, index) => (
            <div
              key={index}
              className="m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden"
            >
              <span>{getFileNameFromUrl(fileUrl)}</span>
              <div className="bg-white px-2 text-lightpurple-4 min-w-max">
                <a
                  href={`${Constants.imageUrl}${fileUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  download={getFileNameFromUrl(fileUrl)}
                >
                  Download
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className={`text-grey-2 break-words whitespace-pre-line feedback_space`}>
          <Linkify>{board?.content}</Linkify>
        </div>

        <div className="absolute">
          <Viewer
            visible={hasImagesModalOpen}
            rotatable={true}
            noImgDetails={true}
            scalable={false}
            images={viewerImages}
            onClose={() => {
              setImagesModalOpen(false);
            }}
            activeIndex={activeIndex}
          />
        </div>
      </Section>
    </div>
  );
};
