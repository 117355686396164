import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { every, get } from 'lodash';

import { SuperModal, ErrorBlank } from '../../../components';
import { useStudentFieldtripHomeReportAdd } from '../../../container/student-fieldtrip-home-report-add';
import { UserContainer } from '../../../container/user';
import { useSignature } from '../../../hooks/useSignature';
import {
  TopNavbar,
  BackButton,
  Section,
  TextField,
  Button,
  PhoneNumberField,
  Checkbox,
  Blank,
  BottomFixedView,
  CloseButton,
} from '../../../reusable';
import { makeDateToString } from '../../../utils';

interface MatchParams {
  id: string;
}

export const FieldtripHomeReportAddPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const history = useHistory();
  const { id } = match.params;
  const { sigPadData, canvasRef, clearSignature } = useSignature();
  const { me } = UserContainer.useContext();
  const {
    fieldtrip,
    isLoading,
    error,
    isUpdateFieldtripSuccess,
    errorMessage,
    updateFieldtripResult,

    homePlan,
    parentsName,
    parentsPhone,

    setHomePlan,
    setParentsName,
    setParentsPhone,
  } = useStudentFieldtripHomeReportAdd({
    id: Number(id),
    sigPadData,
  });

  const [agree, setAgree] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [openSignModal, setSignModal] = useState(false);

  const _checkFillHomePlanContent = () => {
    const checkKeys = ['content1', 'subject1'];
    return every(homePlan, (plan) => {
      return every(checkKeys, (key) => {
        const value = get(plan, key, null);
        return !!value;
      });
    });
  };

  if (fieldtrip && isUpdateFieldtripSuccess) {
    return (
      <>
        <TopNavbar
          title="체험학습 신청서"
          left={
            <div className="h-15">
              <BackButton className="h-15" />
            </div>
          }
        />
        <div className="w-full h-screen-15 flex flex-col items-center justify-center text-center">
          <div className="text-gray-600 font-bold text-xl">체험학습 결과보고서 제출 완료</div>
          <div className="text-gray-400 mt-4">
            결과보고서 제출이 완료되었습니다. 보호자, 담임, 학년부장, 교무부장의 서명 후 결재 승인이
            완료됩니다.
          </div>
          <BottomFixedView tw={{ bottom: 'bottom-16', paddingX: 'px-5' }}>
            <Button
              onClick={() => history.push(`/student/fieldtrip/result/${fieldtrip.id}`)}
              tw={{ width: 'w-full' }}
            >
              결과보고서 확인하기
            </Button>
            <button
              className="bg-white border border-brand-1 text-brand-1 w-full rounded-lg mt-4 px-6 h-12 font-bold"
              onClick={() => history.push(`/student/fieldtrip`)}
            >
              목록으로 가기
            </button>
          </BottomFixedView>
        </div>
      </>
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        title="가정학습 결과 보고서"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section style={{ backgroundColor: '#F7F7F7' }}>
        <h1 className="text-xl font-semibold ">가정학습 결과 보고서</h1>
        <label className="mb-1 text-sm text-gray-800">*기간</label>
        <div className="space-y-3 pb-6">
          <div>
            <div className="flex space-x-4">
              <div
                className="px-4 w-80 mb-5 h-12 border
              border-gray-200 rounded-md sm:text-sm
              focus:ring-0 focus:border-brand-1 placeholder-gray-400
              disabled:bg-gray-100 disabled:text-gray-400 flex items-center"
              >
                {fieldtrip && makeDateToString(fieldtrip?.startAt)}
              </div>
              <span className="pt-2">부터</span>
            </div>

            <div className="flex space-x-4">
              <div
                className="px-4 w-80 mb-5 h-12 border
              border-gray-200 rounded-md sm:text-sm
              focus:ring-0 focus:border-brand-1 placeholder-gray-400
              disabled:bg-gray-100 disabled:text-gray-400 flex items-center"
              >
                {fieldtrip && makeDateToString(fieldtrip?.endAt)}
              </div>
              <span className="pt-2">까지</span>
            </div>
          </div>
          <div className=" text-lg whitespace-pre-line mb-2 ">
            남은 일수{' '}
            <span className="text-brand-1 underline">
              {me?.school?.fieldtripDays}일 중 {fieldtrip?.usedDays}일을 신청
            </span>
            합니다.
          </div>
          <div className=" text-gray-600 text-xs whitespace-pre-line mb-2">
            ※<span className="font-bold">토,일, 개교기념일 등 학교 휴업일</span>
            은 체험학습 신청 일수에 넣지 않음. <br /> ※ 체험학습 신청은 신청일보다{' '}
            <span className="font-bold">3일 이전에 신청</span>해야함.
          </div>
          <div className="w-full">
            <TextField label="*체험학습 형태" value="가정학습" readOnly />
          </div>
          <div className="w-full">
            <TextField label="*목적지" value="자택" readOnly />
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-bold text-gray-800 text-lg">*가정학습 결과 보고서 작성</div>
          <Button
            text="예시보기"
            tw={{
              width: 'w-24',
              paddingY: 'py-2.5',
              height: 'h-10',
              paddingX: 'px-4',
            }}
            onClick={() => setModalopen(true)}
          />
        </div>
        <div className=" bg-white shadow-sm p-4 border border-gray-100 rounded-md space-y-4">
          {homePlan?.map((plan, i) => (
            <div
              key={i}
              className=" bg-white shadow-sm p-4 border border-gray-100 rounded-md space-y-4"
            >
              <div className="font-bold text-gray-800 text-lg text-center pt-10 pb-4">
                {i + 1}일차
              </div>
              <TextField
                label="*1교시 교과명"
                id={`home_subject_${i}_1`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject1'] || ''}
                tw={{
                  borderColor: plan['subject1'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject1'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_1`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content1'] || ''}
                tw={{
                  borderColor: plan['content1'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content1'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*2교시 교과명"
                id={`home_subject_${i}_2`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject2'] || ''}
                tw={{
                  borderColor: plan['subject2'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject2'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_2`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content2'] || ''}
                tw={{
                  borderColor: plan['content2'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content2'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*3교시 교과명"
                id={`home_subject_${i}_3`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject3'] || ''}
                tw={{
                  borderColor: plan['subject3'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject3'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_3`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content3'] || ''}
                tw={{
                  borderColor: plan['content3'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content3'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*4교시 교과명"
                id={`home_subject_${i}_4`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject4'] || ''}
                tw={{
                  borderColor: plan['subject4'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject4'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_4`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content4'] || ''}
                tw={{
                  borderColor: plan['content4'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content4'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*5교시 교과명"
                id={`home_subject_${i}_5`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject5'] || ''}
                tw={{
                  borderColor: plan['subject5'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject5'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_5`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content5'] || ''}
                tw={{
                  borderColor: plan['content5'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content5'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*6교시 교과명"
                id={`home_subject_${i}_6`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject6'] || ''}
                tw={{
                  borderColor: plan['subject6'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject6'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_6`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content6'] || ''}
                tw={{
                  borderColor: plan['content6'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content6'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*7교시 교과명"
                id={`home_subject_${i}_7`}
                placeholder="예시) 국어,영어,수학 등"
                value={plan['subject7'] || ''}
                tw={{
                  borderColor: plan['subject7'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['subject7'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
              <TextField
                label="*학습할 내용"
                id={`home_content_${i}_7`}
                placeholder="예시) 3-1 훈민정음"
                value={plan['content7'] || ''}
                tw={{
                  borderColor: plan['content7'] ? 'border-gray-300' : 'border-red-700',
                }}
                onChange={(e) => {
                  const newHomePlan = JSON.parse(JSON.stringify(homePlan));
                  newHomePlan[i]['content7'] = e.target.value;
                  setHomePlan(newHomePlan);
                }}
              />
            </div>
          ))}
        </div>
        <div className="w-full">
          <TextField
            disabled
            label="보호자 이름"
            placeholder="보호자 이름을 입력해주세요."
            value={parentsName}
            onChange={(e) => setParentsName(e.target.value)}
            tw={{
              borderColor: parentsName ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            disabled
            label="보호자 연락처"
            value={parentsPhone}
            onChange={(e) => setParentsPhone(e.target.value)}
            style={{
              borderColor: !parentsPhone ? 'rgba(185, 28, 28)' : '',
            }}
          />
          <div className="text-red-600 text-sm whitespace-pre-line mb-2">
            보호자 동의를 위해 보호자의 연락처를 입력해주세요. 입력한 번호로 서명 요청이 보내집니다.
          </div>
        </div>
        <div>
          <div className="flex items-center space-x-2 mb-1">
            <Checkbox id="agree" onChange={() => setAgree(!agree)} checked={agree} />
            <label htmlFor="agree">
              <span className="font-semibold text-lg cursor-pointer">
                아래 내용을 체크하셨습니까?
              </span>
            </label>
          </div>
          <div className="border border-gray-300 rounded-lg px-4 py-3 whitespace-pre-line">
            -신고자는 학생 본인입니다.
          </div>
        </div>
        <Button
          disabled={!agree || !_checkFillHomePlanContent()}
          tw={{
            backgroundColor: !agree || !_checkFillHomePlanContent() ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => setSignModal(true)}
        >
          동의하고 서명하기
        </Button>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
      </Section>
      <SuperModal modalOpen={modalopen} setModalClose={() => setModalopen(false)}>
        <div className="text-center font-smibold text-brand-1 mt-5 text-lg">
          가정 학습 계획 예시
        </div>
        <div className="text-sm ml-6 mr-6 mb-6 mt-6">
          작성 시 개인 시간표와 같게 작성해야 합니다.
          <br />
          1일 1장을 작성해야 합니다.
        </div>
        <div className=" bg-white shadow-xl p-4 border border-gray-100 rounded-md space-y-4 ml-7 mr-7 mt-4 mb-7">
          <div className="font-bold text-gray-800 text-lg text-center pt-10 pb-4">1일차</div>
          <TextField label="*1교시 교과명" id="reason" placeholder="국어" />
          <TextField label="*학습할 내용" id="reason" placeholder="3-1 훈민정음" />
        </div>

        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button
            className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold"
            onClick={() => setModalopen(false)}
          >
            닫기
          </button>
        </div>
      </SuperModal>

      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixedView
          tw={{
            borderRadius: 'rounded-xl',
            backgroundColor: 'bg-white',
            zIndex: 'z-100',
            bottom: '-bottom-4',
          }}
        >
          <div className="absolute top-2 right-3" onClick={() => setSignModal(false)}>
            <CloseButton
              onClick={() => {
                setSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-gray-700 text-xl font-bold">학생 서명란</div>
              <div className="text-gray-500">아래 네모칸에 서명을 해주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.6 > 420 ? 420 : window.innerWidth * 0.6}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              style={{
                borderRadius: '30px',
                background: '#F2F2F2',
                margin: 'auto',
              }}
            />
            <div className="flex items-center justify-between space-x-2">
              <Button
                tw={{
                  backgroundColor: 'bg-white',
                  borderWidth: 'border',
                  borderColor: 'border-brand-1',
                  color: 'text-current',
                  width: 'w-full',
                }}
                onClick={() => clearSignature()}
              >
                다시하기
              </Button>
              <Button tw={{ width: 'w-full' }} onClick={updateFieldtripResult}>
                서명 제출하기
              </Button>
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixedView>
      </div>
    </>
  );
};
