import { useHistory } from 'react-router-dom';

import { Newsletter } from '../../generated/model';
import { ListItem, utcToLocalDatetime } from '../../reusable';

interface NewsletterTeacherItemProps {
  newsletter: Newsletter;
}

export const NewsletterTeacherItem = ({ newsletter }: NewsletterTeacherItemProps) => {
  const history = useHistory();
  return (
    <ListItem onClick={() => history.push(`/teacher/newsletter/${newsletter.id}`)}>
      <div className="text-left flex flex-col space-y-1">
        <div className="rounded-md px-3 py-1 bg-purple-100 text-sm text-purple-700 w-max">
          가정통신문
        </div>
        <div className="text-lg font-bold text-grey-1">{newsletter.title}</div>
        <div className="text-grey-3 text-sm">
          {utcToLocalDatetime(newsletter?.createdAt?.toString() || '')}
        </div>
      </div>
    </ListItem>
  );
};
