import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { RecoilRoot } from 'recoil';

import App from './App';
import { GroupContainer } from './container/group';
import { UserContainer } from './container/user';
import reportWebVitals from './reportWebVitals';

import './tailwind.generated.css';
import './calendar.css';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={client}>
        <UserContainer.ContextProvider>
          <GroupContainer.ContextProvider>
            <App />
          </GroupContainer.ContextProvider>
        </UserContainer.ContextProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
