import { useEffect, useState } from 'react';

import readXlsxFile, { Row } from 'read-excel-file';

import {
  useAttendanceControllerCreateAttendanceAbsent,
  useAttendanceControllerDownloadAbsents,
  useAttendanceControllerFindAttendanceAbsent,
} from '../generated/endpoint';
import { RequestCreateAttendanceAbsentDto } from '../generated/model';
import { downloadExcel } from '../utils/download-excel-util';

export const DayOfWeekEnum = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
};

export const useTeacherAttendanceBook1 = ({
  groupId,
  year,
  semester,
  startDate,
  endDate,
}: {
  groupId: number | undefined;
  year: number;
  semester: number;
  startDate: string;
  endDate: string;
}) => {
  const [rows, setRows] = useState<Row[]>();

  const { data: attendanceBookData, refetch: refetchAttendanceBookData } =
    useAttendanceControllerDownloadAbsents(
      {
        groupId: groupId as number,
        year,
        semester,
        startDate,
        endDate,
      },
      {
        query: {
          queryKey: ['attendanceBookData', groupId, year, semester, startDate, endDate],
          enabled: !!groupId,
        },
      },
    );

  const { data: attendanceAbsentData } = useAttendanceControllerFindAttendanceAbsent(
    {
      groupId: groupId as number,
      year,
      semester,
      startDate,
      endDate,
    },
    {
      query: {
        enabled: !!groupId,
      },
    },
  );

  const { mutate: absentMutate } = useAttendanceControllerCreateAttendanceAbsent({
    mutation: {
      onSuccess: () => {
        refetchAttendanceBookData();
      },
    },
  });

  const download = () => {
    const filename = '출석부';
    attendanceBookData && downloadExcel(attendanceBookData, filename);
  };

  const handleAbsent = ({
    attendanceDay,
    absent,
    comment,
    type1,
    type2,
    content,
    year,
    semester,
    userId,
    schoolId,
  }: RequestCreateAttendanceAbsentDto) => {
    const absentData = {
      attendanceDay,
      absent,
      comment,
      type1,
      type2,
      content,
      year,
      semester,
      userId,
      schoolId,
    };
    absentMutate({ data: absentData });
  };

  useEffect(() => {
    if (attendanceBookData) {
      new Promise((r) => r(attendanceBookData))
        .then((blob) => readXlsxFile(blob as Blob))
        .then((rows) => setRows(rows))
        .catch((e) => console.log(e));
    }
  }, [attendanceBookData]);

  return {
    downloadAttendanceBook: download,
    rows,
    attendanceAbsentData,
    handleAbsent,
  };
};
