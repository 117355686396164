import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';

import { Constants } from '../constants';
import { useActivityControllerDelete, useActivityControllerFindOne } from '../generated/endpoint';
import { Routes } from '../routes';

export const useTeacherActivityDetail = (activityId?: number, refetchList?: () => void) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: activity, isLoading: isActivityLoading } = useActivityControllerFindOne(
    activityId as number,
    {
      query: {
        enabled: !!activityId,
        onError: () => {
          setErrorMessage('활동을 불러오는데 실패했습니다.');
        },
      },
    },
  );

  const { mutate: deleteActivity } = useActivityControllerDelete({
    mutation: {
      onSuccess: () => {
        refetchList?.();
        history.push(Routes.teacher.activity.index);
      },
      onError: () => {
        setErrorMessage('활동 삭제에 실패했습니다.');
      },
    },
  });

  const images = activity?.images || [];
  const documents = activity?.files || [];

  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    viewerImages.push({
      src: `${Constants.imageUrl}${image}`,
    });
  }

  const handleActivityDelete = () => {
    deleteActivity({ id: activityId as number });
  };

  return {
    activity,
    isActivityLoading,
    images,
    documents,
    viewerImages,
    errorMessage,
    handleActivityDelete,
  };
};
