import React, { useState } from 'react';
// @ts-ignore
import ExifOrientationImg from 'react-exif-orientation-img';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Viewer from 'react-viewer';

import { useRecoilValue } from 'recoil';

import { ReactComponent as Arrow } from '../../../assets/svg/arrow-up-circle.svg';
import {
  CommentItem,
  ErrorBlank,
  FileItem,
  SuperSearchField,
  SurveyComponent,
} from '../../../components';
import { Constants } from '../../../constants';
import { useTeacherActivitySubmitDetail } from '../../../container/teacher-activity-submit-detail';
import {
  Badge,
  Blank,
  List,
  Section,
  utcToLocalDatetime,
  utcToLocalFromNow,
} from '../../../reusable';
import { meStateNew } from '../../../store';

interface MatchParams {
  said: string;
}

interface ActivitySubmitDetailPageProps extends RouteComponentProps<MatchParams> {
  activityId: number;
}

const ActivitySubmitDetailPage: React.FC<ActivitySubmitDetailPageProps> = ({
  match,
  activityId,
}) => {
  const me = useRecoilValue(meStateNew);

  const { said } = match.params;

  const {
    text,
    setText,
    isLoading,
    activity,
    errorMessage,
    studentActivity,
    comments,
    handleCommentCreate,
    handleCommentDelete,
    handleCommentUpdate,
  } = useTeacherActivitySubmitDetail(activityId, +said);

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const files: any[] = [];
  if (studentActivity?.files) {
    try {
      studentActivity?.files.map((f: any) => files.push(JSON.parse(f)));
    } catch (err) {
      console.error(err);
    }
  }

  const images = studentActivity?.images;

  const viewerImages: any[] = [];

  if (images) {
    for (const image of images) {
      viewerImages.push({
        src: image,
        title: '',
      });
    }
  }

  const handleCommentCreateSubmit = async () => {
    if (text === '') {
      alert('텍스트 내용을 입력해주세요.');
      return;
    }

    handleCommentCreate({ content: text, studentActivityId: +said });
  };

  if (errorMessage) {
    return <ErrorBlank />;
  }

  return (
    <>
      {isLoading && <Blank reversed />}
      <div className="border border-gray-100 h-screen-4.5 overflow-y-scroll overflow-x-hidden relative">
        <div className="bg-gray-50 p-4">
          <div className="flex justify-between">
            <Badge
              tw={{
                backgroundColor: 'bg-light_orange' as any,
                color: 'text-brand-1',
              }}
            >
              {activity?.subject}
            </Badge>
            <div className="space-x-5 font-base text-gray-500"></div>
          </div>
          <div className="space-y-0.5">
            <div className="text-lg font-semibold mt-2">{activity?.title}</div>
            <div className="text-sm text-gray-500 font-light">
              {activity?.createdAt && utcToLocalDatetime(activity.createdAt)}
            </div>
            {activity?.endDate && (
              <div className="text-sm font-normal text-red-400 space-x-2">
                <span className="font-semibold">마감기한</span>
                <span>
                  {utcToLocalDatetime(activity.endDate)}
                  까지
                </span>
              </div>
            )}
          </div>
          {/* 스테이징에서는 설문조사인경우 내용을 보여주고 있지 않음 */}
          {activity?.type !== 'SURVEY' && (
            <div className="text-gray-500 text-sm mt-4">{activity?.content}</div>
          )}
        </div>
        <Section>
          {activity?.type === 'SURVEY' ? (
            studentActivity?.content && activity?.content ? (
              <SurveyComponent
                content={activity?.content || '{}'}
                setContent={(c: string) => {}}
                display
                value={studentActivity.content}
              />
            ) : (
              <></>
            )
          ) : (
            <>
              {images?.map((image: string, i: number) => (
                <>
                  <div
                    className="w-full"
                    onClick={() => {
                      setActiveIndex(i);
                      setImagesModalOpen(true);
                    }}
                  >
                    <div className={`relative pb-3/5 rounded bg-gray-50`}>
                      <ExifOrientationImg
                        className={`absolute w-full h-full rounded object-cover`}
                        src={`${Constants.imageUrl}${image}`}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ))}
              <div>
                {files.length ? (
                  <div className=" mt-1">
                    {files.map((f: any) => (
                      <FileItem file={f} />
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <p className="mt-6">{studentActivity?.content}</p>
            </>
          )}
          {activity?.updatedAt &&
            studentActivity?.updatedAt &&
            studentActivity?.isSubmitted &&
            studentActivity.updatedAt !== activity?.updatedAt && (
              <div className="mt-3 text-brandblue-1">
                제출 완료 일시 :
                {utcToLocalDatetime(new Date(studentActivity.updatedAt).toISOString())} (
                {utcToLocalFromNow(new Date(studentActivity.updatedAt).toISOString())})
              </div>
            )}
        </Section>
        <div>
          <div className="h-0.5 bg-gray-100"></div>
          <List>
            {comments?.map((comment) => (
              <CommentItem
                comment={comment}
                userId={me?.id || 0}
                updateComment={handleCommentUpdate}
                deleteComment={handleCommentDelete}
              />
            ))}
          </List>
        </div>
      </div>

      <div className="bottom-0 bg-white border-l border-gray-100">
        <div className="h-0.5 bg-gray-100 top-0"></div>
        <div className="flex space-x-2 p-4">
          <SuperSearchField
            placeholder="내용을 입력해주세요."
            value={text}
            onChange={(e) => setText(e.target.value)}
            onSearch={handleCommentCreateSubmit}
          />
          <Arrow className="h-10 w-10 cursor-pointer" onClick={handleCommentCreateSubmit} />
        </div>
      </div>

      <div className="absolute">
        <Viewer
          visible={hasImagesModalOpen}
          rotatable={true}
          noImgDetails={true}
          //drag={false}
          scalable={false}
          images={viewerImages}
          onClose={() => {
            setImagesModalOpen(false);
          }}
          activeIndex={activeIndex}
        />
      </div>
    </>
  );
};

export default withRouter(ActivitySubmitDetailPage);
