import { useState } from 'react';

import { useTeacherKlassGroup } from './teacher-klass-groups';
import {
  useGroupsControllerFindOne,
  useNewsLettersControllerFindOne,
  useStudentNewsletterControllerFindAllByNewsletterId,
} from '../generated/endpoint';
import { ResponseGroupDto, StudentGroup } from '../generated/model';

type NewData = ResponseGroupDto & {
  isSelected: boolean;
  submitCount: number;
  studentGroups: StudentGroup[];
};

export const useTeacherNewsletterSubmit = (newsletterId: number) => {
  const [result, setResult] = useState<NewData[]>([]);

  const { groups } = useTeacherKlassGroup();

  const [selectedKlassGroupId, setSelectedKlassGroupId] = useState<number | undefined>(undefined);

  const { data: newsletter } = useNewsLettersControllerFindOne(newsletterId, {
    query: {
      enabled: !!groups && !!newsletterId,
      queryKey: ['newsletter', newsletterId],
      onSuccess: (res) => {
        const newData =
          (groups?.map((group) => {
            return {
              ...group,
              isSelected: false,
              submitCount: 0,
              studentGroups: [] as StudentGroup[],
            };
          }) as NewData[]) || ([] as NewData[]);

        const newResult =
          newData?.filter((group) => {
            const id = group?.name?.[0] || '';
            return res?.klasses.includes(id);
          }) || [];
        setResult(newResult);
      },
    },
  });

  const { data: studentNewsletters, refetch } = useStudentNewsletterControllerFindAllByNewsletterId(
    newsletterId,
    {
      query: {
        enabled: !!newsletter && !!newsletterId,
        onSuccess: (res) => {
          const newResult = result?.map((group) => {
            const submitCount = res?.filter(
              (newsletter) => newsletter?.studentGradeKlass === group.name,
            ).length;
            return { ...group, submitCount };
          }) as NewData[];

          setResult(newResult);
        },
      },
    },
  );

  useGroupsControllerFindOne(selectedKlassGroupId as number, {
    query: {
      queryKey: ['klassGroup', selectedKlassGroupId],
      enabled: !!selectedKlassGroupId && !!studentNewsletters,
      onSuccess: (res) => {
        const newResult = result?.map((group) => {
          if (group.id === selectedKlassGroupId) {
            return {
              ...group,
              isSelected: !group.isSelected,
              studentGroups: res.studentGroups,
            };
          }
          return { ...group, studentGroups: group.studentGroups };
        }) as NewData[];

        setResult(newResult);
      },
    },
  });

  const studentsCount = groups?.reduce((acc, cur) => +acc + +cur.studentCount, 0) || 0;
  const submitCount = studentNewsletters?.length || 0;
  const unSubmitCount = studentsCount - submitCount < 0 ? 0 : studentsCount - submitCount;

  const selectKlassGroup = (groupId: number) => {
    setSelectedKlassGroupId(groupId);
  };

  return {
    result,
    studentNewsletters,
    studentsCount,
    submitCount,
    unSubmitCount,
    selectKlassGroup,
    refetch,
  };
};
