import { useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { useParentSignUp } from '../../../container/parent-sign-up';
import {
  Alert,
  Blank,
  Button,
  Checkbox,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
} from '../../../reusable';
import { meStateNew } from '../../../store';
import { Validator } from '../../../utils/validator';

export const ParentSignupPage = () => {
  const history = useHistory();
  const vh = use100vh();
  const height = vh ? `${vh}px` : '100vh';

  const meRecoil = useRecoilValue(meStateNew);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const uuid = searchParams.get('uuid');
  const name = searchParams.get('name');
  const nokName = searchParams.get('nokName');
  const phone = searchParams.get('phone');

  const {
    student,
    isLoading,
    errorMessage,
    setErrorMessage,
    handleSubmit,
    handleChildAddButtonClick,
  } = useParentSignUp(uuid);

  const [isShowModal, setIsShowModal] = useState(true);
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [parentName, setParentName] = useState(nokName || '');
  const [parentPhone, setParentPhone] = useState(phone || '');

  const [privacy, setPrivacy] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const emailre = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const spacere = / /g;

  const buttonDisabled =
    !email ||
    !password1 ||
    !password2 ||
    !parentName ||
    !privacy3 ||
    !privacy ||
    !policy ||
    !parentPhone;

  return (
    <div className="w-full box-border">
      {isLoading && <Blank />}

      {meRecoil && isShowModal && (
        <div
          className={`fixed inset-0 w-full h-screen z-100 bg-littleblack flex items-center justify-center`}
          style={{ height, margin: 0 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="bg-white opacity-100 rounded-lg shadow-sm relative">
            <Section>
              {meRecoil?.role === 'PARENT' ? (
                <>
                  <div className="text-2xl font-bold text-center">자녀 추가 안내</div>
                  <div className="text-lg">
                    <span className="font-bold">{meRecoil.name}</span> 계정에 이미 로그인되어
                    있습니다. <br />이 계정에 <span className="font-bold">{name}</span> 자녀를
                    추가하시겠습니까?
                  </div>
                  <div className="h-4"></div>
                  <Button
                    tw={{ backgroundColor: 'bg-blue-500' }}
                    onClick={handleChildAddButtonClick}
                  >
                    추가하기
                  </Button>
                  <Button
                    tw={{ backgroundColor: 'bg-red-500' }}
                    onClick={() => setIsShowModal(false)}
                  >
                    새 계정으로 회원가입하기
                  </Button>
                </>
              ) : (
                <>
                  <div className="text-2xl font-bold text-center">경고</div>
                  <div className="text-lg">
                    <span className="font-bold">{meRecoil?.name}</span> 계정에 이미 로그인되어
                    있지만, <br />이 계정은 부모 계정이 아니기 때문에{' '}
                    <span className="font-bold">{name}</span> 자녀를 추가할 수 없습니다.
                    <br />새 계정으로 회원가입해 주시기 바랍니다.
                  </div>
                  <div className="h-4"></div>
                  <Button
                    tw={{ backgroundColor: 'bg-red-500' }}
                    onClick={() => setIsShowModal(false)}
                  >
                    새 계정으로 회원가입하기
                  </Button>
                </>
              )}
            </Section>
          </div>
        </div>
      )}

      <TopNavbar title="학부모 회원가입" right={<div className="h-15 w-15" />} />
      <div className="mt-6 mb-3">
        <Logo className="mx-auto mb-4" />
        <div className="text-center flex flex-col items-center justify-center text-sm sm:text-base">
          <div className="font-bold text-lg">
            학생명 : {student?.name}
            <br />
            학번 : {student?.klassGroupName} {student?.studentNumber}번
          </div>
          <div className="text-gray-500 px-4 mt-2">
            해당 학생의 학부모가 맞는지 꼭 체크해주시고,
            <br /> 학생정보가 상이하다면{' '}
            <a href="http://superstudy.channel.io/" target="_blank" rel="noreferrer">
              고객센터
            </a>
            로 문의주세요.
          </div>
        </div>
      </div>
      <Section>
        <div className="w-full">
          <TextField
            label="*이름"
            id="name"
            placeholder="이름을 입력해주세요"
            value={parentName}
            onChange={(e) => setParentName(String(e.target.value).replace(spacere, ''))}
          />
        </div>

        <div className="w-full">
          <TextField
            label="*이메일"
            placeholder="이메일을 입력해주세요"
            value={email}
            onChange={(e) => setEmail(String(e.target.value).replace(spacere, ''))}
          />
        </div>
        <div className="w-full">
          <TextField
            label="*비밀번호 - 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상"
            type="password"
            placeholder="비밀번호"
            value={password1}
            onChange={(e) => setPassword1(String(e.target.value).replace(spacere, ''))}
            style={{ marginBottom: '0.5rem' }}
          />
          <TextField
            type="password"
            placeholder="비밀번호 확인"
            value={password2}
            onChange={(e) => setPassword2(String(e.target.value).replace(spacere, ''))}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="전화번호"
            value={parentPhone}
            onChange={(e) => setParentPhone(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <div className="font-bold">
            <Checkbox
              label="전체 동의"
              onChange={() => {
                const allChecked = privacy3 && privacy && policy && marketing;
                setPrivacy3(!allChecked);
                setPrivacy(!allChecked);
                setMarketing(!allChecked);
                setPolicy(!allChecked);
              }}
              checked={privacy3 && privacy && policy && marketing}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="privacy" onChange={() => setPrivacy(!privacy)} checked={privacy} />
            <Link to={`/consent-to-use-of-personal-information`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보/민감정보 수집 및 이용에 관한 동의(필수)
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="policy" onChange={() => setPolicy(!policy)} checked={policy} />
            <Link to={`/privacy-policy/${student?.schoolId}`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보처리방침 (필수)
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="privacy3" onChange={() => setPrivacy3(!privacy3)} checked={privacy3} />
            <Link to={`/consent-to-provide-personal-information-to-third-parties`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보/민감정보 제3자 제공에 관한 동의(필수)
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="marketing"
              onChange={() => setMarketing(!marketing)}
              checked={marketing}
            />
            <Link to={`/consent-for-promotional-and-marketing-purposes`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                홍보 및 마케팅을 위한 개인정보 제공 동의(선택)
              </span>
            </Link>
          </div>
        </div>
        <div className="text-pink-600">{errorMessage}</div>
        {errorMessage && (
          <a target="_blank" rel="noreferrer" href="http://superstudy.channel.io/">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
        )}
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={async () => {
            if (password1 !== password2) {
              alert('비밀번호와 비밀번호 확인이 다릅니다.');
              return;
            }
            if (!Validator.passwordRule1(password1) && !Validator.passwordRule2(password1)) {
              alert(
                '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
              );
              return;
            }
            if (!emailre.test(String(email))) {
              setErrorMessage('이메일이 형식에 맞지 않습니다!');
            } else if (!student?.school?.id) {
              setErrorMessage('학교 정보를 확인할 수 없습니다.');
            } else {
              const result = await handleSubmit({
                email,
                name: parentName,
                password: password1,
                phone: parentPhone,
                hopeMajor: '',
                hopePath: '',
                schoolId: student.school.id,
              });
              if (result) {
                return (
                  <Alert
                    onClose={() => history.push(`/add-kids/${uuid}`)}
                    text="학부모 회원가입이 완료되었습니다."
                  />
                );
              }
            }
          }}
        >
          회원가입
        </Button>
      </Section>
    </div>
  );
};
