import { atom } from 'recoil';

import { ResponseUserDto } from './generated/model';
import { User } from './types';

export const meState = atom<User | undefined>({
  key: 'meState',
  default: undefined,
});

export const meStateNew = atom<ResponseUserDto | undefined>({
  key: 'meStateNew',
  default: undefined,
});

export const childState = atom<ResponseUserDto | undefined>({
  key: 'childState',
  default: undefined,
});

export const selectedGroupIdState = atom<number | undefined>({
  key: 'selectedGroupIdState',
  default: 0,
});

export const isUpdateMeState = atom<boolean>({
  key: 'isUpdateMeState',
  default: false,
} as const);

export const isUpdateNoticeState = atom<boolean>({
  key: 'noticeState',
  default: false,
});

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const isStayLoggedInState = atom<boolean>({
  key: 'isStayLoggedInState',
  default: false,
  effects_UNSTABLE: [localStorageEffect('isStayLoggedInState')],
});

export const newsletterOpenedGroupState = atom<string[]>({
  key: 'newsletterOpenedGroupState',
  default: [],
});
