import { FC } from 'react';

import { Fieldtrip } from '../../generated/model';
import { makeStartEndToString } from '../../utils';

interface FieldtripSuburbsSeparatePaperProps {
  fieldtrip: Fieldtrip;
  studentName: string;
  resultFile1: string;
  resultFile2: string;
}

export const FieldtripSuburbsSeparatePaper: FC<FieldtripSuburbsSeparatePaperProps> = ({
  fieldtrip,
  studentName,
  resultFile1,
  resultFile2,
}) => {
  return (
    <>
      <div className="flex justify-center w-full py-5">
        <h5 className="font-bold text-2xl">「학교장허가 교외체험학습」 결과보고서(별지)</h5>
      </div>

      <div className="flex justify-center">
        <table className="table-fixed border-black border-2 w-full ">
          <thead>
            <tr className="h-12">
              <th className="border-black border bg-gray-200" colSpan={3}>
                성명
              </th>
              <td className="border-black border text-center" colSpan={6}>
                {studentName}
              </td>
              <th className="border-black border bg-gray-200" colSpan={4}>
                학년 반
              </th>
              <th className="border-black border" colSpan={8}>
                {fieldtrip.studentGradeKlass}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="border-black border border-dashed h-8 px-4 text-center" colSpan={9}>
                교외체험학습 기간
              </td>
              <td className="border-black border border-dashed h-8 px-4 text-center" colSpan={12}>
                {makeStartEndToString(fieldtrip.startAt || '', fieldtrip.endAt || '')}
              </td>
            </tr>

            <tr>
              <td colSpan={21} className="relative pb-3/5 border-black border border-dashed">
                {resultFile1 && (
                  <img
                    className="absolute w-full h-full rounded object-contain"
                    src={resultFile1}
                    alt=""
                  />
                )}
              </td>
            </tr>
            <tr className="pb-4">
              <td colSpan={21} className="relative pb-3/5 border-black border border-dashed">
                {resultFile2 && (
                  <img
                    className="absolute w-full h-full rounded object-contain"
                    src={resultFile2}
                    alt=""
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
