import { atom, AtomEffect } from 'recoil';

const localStorageEffect =
  (key: string): AtomEffect<string | null> =>
  ({ setSelf }) => {
    setSelf(localStorage.getItem(key) || sessionStorage.getItem(key));
  };

export const tokenState = atom<string | null>({
  key: 'tokenState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('token')],
});

export const kakaoIdState = atom<number | null>({
  key: 'kakaoIdState',
  default: null,
});

export const appleIdState = atom<string | null>({
  key: 'appleIdState',
  default: null,
});

interface AlertState {
  open: boolean;
  severity: 'error' | 'warning' | 'info' | 'success';
  text: string;
}

export const alertState = atom<AlertState>({
  key: 'alertState',
  default: { open: false, severity: 'success', text: '' },
});

export const newMsgCntState = atom<number>({
  key: 'newMsgCntState',
  default: 0,
});
