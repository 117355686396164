import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TimetableAttendancePageV2 } from './TimetableAttendancePageV2';
import { TimetableDetailPageV2 } from './TimetableDetailPageV2';
import { BackButton, Blank, TopNavbar } from '../../../reusable';
import { KlassInfo } from '../../../types';
import { getThisSemester, getThisYear } from '../../../utils';

export const TimetablePageV2: FC = () => {
  const history = useHistory();
  const nowDate = new Date();

  const [blankOpen, setBlankOpen] = useState(false);

  const year = +getThisYear();
  const semester = +getThisSemester();

  const [klassInfo, setKlassInfo] = useState<KlassInfo>({
    time: '',
    timeCode: '',
    myClass: false,
    subject: '',
    name: '',
    semester: 0,
    teacherName: '',
  });

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        {blankOpen && <Blank />}
        <TopNavbar
          title="시간표/출석체크"
          left={
            <BackButton
              onClick={() => {
                if (klassInfo.name) {
                  //history.goBack();
                  setKlassInfo((prevState) => ({
                    ...prevState,
                    name: '',
                  }));
                } else {
                  history.push(
                    `/teacher/canteen/${nowDate.getFullYear()}-${
                      nowDate.getMonth() + 1
                    }-${nowDate.getDate()}`,
                  );
                }
              }}
            />
          }
          right={
            <div
              className="text-brand-1 text-sm"
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
            >
              새로고침
            </div>
          }
        />

        <div className={` ${klassInfo.name === '' ? '' : 'hidden'}`}>
          <TimetableDetailPageV2
            year={year}
            semester={semester}
            setKlassInfo={(info: KlassInfo) => setKlassInfo(info)}
          />
        </div>
      </div>

      {/* Desktop V */}
      <div className="col-span-2 h-screen hidden md:block">
        <div className="px-6 py-6">
          <h1 className="text-2xl font-semibold">시간표/출석체크</h1>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="w-full my-2 hidden md:block">
          <TimetableDetailPageV2
            year={year}
            semester={semester}
            setKlassInfo={(info: KlassInfo) => setKlassInfo(info)}
          />
        </div>
      </div>

      {/* Mobile / Desktop V */}
      <div className="col-span-4 p-6 overflow-y-auto scroll-box  md:block">
        {klassInfo.name !== '' && <TimetableAttendancePageV2 klassInfo={klassInfo} />}
      </div>
    </>
  );
};
