import { useState } from 'react';

import { UserContainer } from './user';
import {
  useTimetablesV2ControllerFindOne,
  useTimetablesV2ControllerGetAllTeacher,
} from '../generated/endpoint';
import { ResponseTimetableV2TeacherDto } from '../generated/model';

export const useTeacherTimetableV2Detail = ({
  year,
  semester,
}: {
  year: number;
  semester: number;
}) => {
  const { me, isMeLoading } = UserContainer.useContext();

  const [groupId, setGroupId] = useState<number>(me?.klassGroupId || 0);
  const [teacher, setTeacher] = useState<ResponseTimetableV2TeacherDto>();
  const [teacherId, setTeacherId] = useState<number>(0);

  // 교사별 시간표
  const {
    data: teachers,
    isLoading: isTeacherLoading,
    error: errorTeacher,
  } = useTimetablesV2ControllerGetAllTeacher(
    { year, semester },
    {
      query: {
        enabled: !isMeLoading,
        onSuccess: (res) => {
          if (!res?.length) {
            return;
          }

          const t = res.find((teacher) => teacher.id === me?.id) || res[0];
          setTeacher(t);
          setTeacherId(t.id);
        },
      },
    },
  );

  // 학급별 시간표
  const {
    data: timetable,
    isLoading: isTimetableLoading,
    error: errorTimetable,
  } = useTimetablesV2ControllerFindOne(year, semester, groupId, {
    query: {
      queryKey: ['timetable', year, semester, groupId],
      enabled: !!groupId,
    },
  });

  const changeTeacher = (teacherId: number) => {
    setTeacherId(teacherId);
    setTeacher(teachers?.find((teacher) => teacher.id === teacherId));
  };

  const changeKlass = (klassId: number) => {
    setGroupId(klassId);
  };

  const teacherTimeTable = JSON.parse(teacher?.content || '[]');
  const classTimeTable = JSON.parse(timetable?.content || '[]');

  return {
    teachers,
    groupId,
    teacher,
    teacherId,
    classTimeTable,
    teacherTimeTable,
    isLoading: isTeacherLoading || isTimetableLoading,
    error: errorTeacher || errorTimetable,
    changeTeacher,
    changeKlass,
  };
};
