import { useEffect, useState } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { createContainer } from './createContainer';
import {
  useUserControllerLogin,
  useUserControllerMe,
  useUserControllerMeWithChildren,
} from '../generated/endpoint';
import { Role } from '../generated/model';
import { useBrowserStorage } from '../hooks/useBrowserStorage';
import { useLogoutOnIdle } from '../hooks/useLogoutOnIdle';
import { tokenState, useLogout } from '../reusable';
import { childState, isStayLoggedInState, meStateNew } from '../store';

export const userHook = () => {
  const logout = useLogout();
  const [me, setMe] = useRecoilState(meStateNew);
  const isStayLoggedIn = useRecoilValue(isStayLoggedInState);
  const { setStorage } = useBrowserStorage();

  const setChild = useSetRecoilState(childState);
  const [token, setToken] = useRecoilState(tokenState);
  const [errorMessage, setErrorMessage] = useState('');

  // 로그인 시 자동로그인 체크하지 않으면 30분 후 자동 로그아웃
  useLogoutOnIdle(logout, isStayLoggedIn, 30);

  const {
    data: meData,
    refetch: refetchMe,
    isLoading: isMeLoading,
  } = useUserControllerMe({
    query: {
      queryKey: ['me'],
      onSuccess: (res) => {
        setMe(res);
        if (res.role === Role.PARENT) {
          refetchMeWithChildren();
        }
      },
      enabled: !!token,
    },
  });

  const { refetch: refetchMeWithChildren } = useUserControllerMeWithChildren({
    query: {
      enabled: !!meData,
      onSuccess: (parent) => {
        if (meData?.role !== Role.PARENT) {
          return;
        }
        const localChildId = +(localStorage.getItem('child-user-id') || '0');
        const _child = !localChildId
          ? parent?.children?.[0]
          : parent?.children?.find((child) => child.id === localChildId);

        if (_child?.id) {
          localStorage.setItem('child-user-id', JSON.stringify(_child.id));
        }
        setChild(_child);
      },
    },
  });

  const { mutate: loginMutation } = useUserControllerLogin({
    mutation: {
      onSuccess: (res) => {
        if (!res.token) {
          throw new Error('No token');
        }
        setToken(res.token);
        setStorage('token', res.token);
      },
      onError: () => {
        setErrorMessage('이메일 혹은 비밀번호가 일치하지 않습니다!');
      },
    },
  });

  const handleLogin = (email: string, password: string) => {
    loginMutation({ data: { email, password } });
  };

  useEffect(() => {
    if (!me && token) {
      refetchMe();
    }
  }, []);

  return {
    me,
    refetchMe,
    isMeLoading,
    handleLogin,
    errorMessage,
  };
};

export const UserContainer = createContainer(userHook);
