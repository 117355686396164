import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import FieldtripNoticeDetailPage from './FieldtripNoticeDetailPage';
import { ReactComponent as UploadIcon } from '../../../assets/svg/icon-upload.svg';
import { FieldtripNoticeCard, FrontPagination, ErrorBlank } from '../../../components';
import { useTeacherFieldtripNotice } from '../../../container/teacher-fieldtrip-notice';
import { UserContainer } from '../../../container/user';
import { TextField } from '../../../reusable';
import { isValidDate, makeDateToString } from '../../../utils';

export const FieldtripNoticePage: React.FC = () => {
  const history = useHistory();
  const [studentName, setStudentName] = useState('');

  const { error, startDate, endDate, data, page, setPage, setStartDate, setEndDate, limit } =
    useTeacherFieldtripNotice();
  const { me } = UserContainer.useContext();

  return (
    <>
      {error && <ErrorBlank />}
      <div className="col-span-3 h-screen hidden md:block">
        <div className=" px-6 pb-4 pt-6">
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold">체험학습 통보서</h1>
          </div>
          <div className="flex space-x-3 my-3 items-center">
            <TextField
              type="date"
              className="cursor-pointer"
              value={makeDateToString(new Date(startDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (endDate && selectedDate > new Date(endDate)) {
                  setEndDate(e.target.value);
                }
                setStartDate(e.target.value);
                setPage(1);
              }}
            />
            <div className="px-4 font-bold text-xl">~</div>
            <TextField
              type="date"
              className="cursor-pointer"
              value={makeDateToString(new Date(endDate))}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                if (!isValidDate(selectedDate)) {
                  return;
                }
                if (startDate && selectedDate < new Date(startDate)) {
                  setStartDate(e.target.value);
                }
                setEndDate(e.target.value);
                setPage(1);
              }}
            />
          </div>
          <div className="flex items-center space-x-2 w-full">
            <div className="items-center border-gray-200 rounded-full border flex justify-between h-10 px-4 w-full">
              <input
                className="w-full"
                placeholder="학생이름을 입력해주세요."
                value={studentName}
                onChange={(e) => {
                  setStudentName(e.target.value);
                  if (e.target.value === '') {
                    history.replace(`/teacher/fieldtrip`);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    history.replace(`/teacher/fieldtrip/notice?username=${studentName}`);
                  }
                }}
              />
            </div>
            <UploadIcon
              onClick={() => {
                if (studentName === '') {
                  alert('텍스트 내용을 입력해주세요.');
                } else {
                  history.replace(`/teacher/fieldtrip/notice?username=${studentName}`);
                }
              }}
            />
          </div>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="h-screen-15 overflow-y-auto scroll-box">
          {data?.items?.map((fieldtrip: any) => (
            <FieldtripNoticeCard userRole={me?.role} fieldtrip={fieldtrip} />
          ))}
        </div>
        {data && data?.total > limit && (
          <div className="grid place-items-center min-w-max">
            <FrontPagination
              basePath="/teacher/fieldtrip/notice"
              total={data?.total}
              limit={limit}
              page={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>
      <div className="col-span-3 bg-gray-50 p-6">
        <Switch>
          <Route
            path="/teacher/fieldtrip/notice/:id"
            component={() => (
              <FieldtripNoticeDetailPage
                school={me?.school}
                approvalLine={me?.school?.approvalLine}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
};
