import React, { FC } from 'react';

import undoArrow from '../assets/images/undo-arrow.png';
import { ReactComponent as FileItemIcon } from '../assets/svg/file-item-icon.svg';

interface FileItemProps {
  file: File;
  canClose?: boolean;
  willRemoveFiles?: any[];
  setWillRemoveFiles?: () => void;
}

export const FileItem = ({
  file,
  canClose,
  willRemoveFiles,
  setWillRemoveFiles,
}: FileItemProps) => {
  console.log('FileItem - file', file);
  return (
    <div
      className={`m-2 p-2 relative bg-white flex justify-between items-center overflow-x-hidden ${
        ``
        // willRemoveFiles?.includes(file.data) ? 'opacity-50' : ''
      }`}
    >
      <div className="flex space-x-2 items-center">
        <FileItemIcon />
        <div className="overflow-x-hidden whitespace-pre w-full">{file.name}</div>
      </div>

      <div className="absolute right-0 flex space-x-1 items-center min-w-max">
        {/*{file?.url ? (*/}
        {/*  <div className="bg-white px-2 text-lightpurple-4 min-w-max">*/}
        {/*    <a href={file?.url} target="_blank" rel="noreferrer" download={true}>*/}
        {/*      Download*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
        {/*{canClose && (*/}
        {/*  <span className="block w-5 h-5 text-sm rounded-full ring-2 text-center ring-white bg-red-700 z-40">*/}
        {/*    <div*/}
        {/*      className="text-white cursor-pointer w-full h-full flex items-center justify-center"*/}
        {/*      onClick={() => (setWillRemoveFiles ? setWillRemoveFiles() : {})}*/}
        {/*    >*/}
        {/*      {willRemoveFiles?.includes(file.data) ? (*/}
        {/*        <img*/}
        {/*          src={undoArrow}*/}
        {/*          alt=""*/}
        {/*          className="w-3 h-3"*/}
        {/*          style={{ transform: 'translate(0px, 1px)' }}*/}
        {/*        />*/}
        {/*      ) : (*/}
        {/*        'X'*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </span>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
