import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { useStudentFirstLogin } from '../../../container/student-first-login';
import {
  BackButton,
  Blank,
  Button,
  Checkbox,
  PhoneNumberField,
  Section,
  TextField,
  TopNavbar,
  useLogout,
} from '../../../reusable';
import { meStateNew } from '../../../store';
import { Validator } from '../../../utils/validator';

export const FirstLoginPage = () => {
  const meRecoil = useRecoilValue(meStateNew);

  const { isLoading, isChannelTalk, handleStudentFirstLogin } = useStudentFirstLogin();

  const logout = useLogout();
  const history = useHistory();
  const [name, setName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');
  const [nokName, setNokName] = useState('');
  const [nokPhone, setNokPhone] = useState('');
  const [birthDate, setBirthDate] = useState('2006-01-01');

  const [privacy, setPrivacy] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [hopePath, setHopePath] = useState('');
  const [hopeMajor, setHopeMajor] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const spacere = / /g;

  useEffect(() => {
    setName(meRecoil?.name || '');
    setNokName(meRecoil?.nokName || '');
    setNokPhone(meRecoil?.nokPhone || '');
    if (meRecoil && !meRecoil?.firstVisit) {
      history.push('/student');
    }
  }, [meRecoil, history]);

  const buttonDisabled =
    !password1 ||
    !password2 ||
    !name ||
    !privacy ||
    !policy ||
    !phone ||
    !nokName ||
    !nokPhone ||
    !birthDate ||
    password1 !== password2;

  return (
    <div className="w-full box-border">
      {isLoading && <Blank />}

      <TopNavbar
        title="첫 로그인"
        left={
          <div className="h-15">
            <BackButton
              className="h-15"
              onClick={() => {
                logout();
                history.push('/');
              }}
            />
          </div>
        }
      />
      <div className="mt-6 mb-3">
        <Logo className="mx-auto mb-4" />
        <span className="text-center flex justify-center text-sm sm:text-base">
          슈퍼스쿨에 오신 것을 환영합니다!
          <br />
          처음 로그인하시나요? 아래 정보를 입력해주세요.
        </span>
      </div>
      <Section>
        <div className="w-full">
          <TextField
            label="*이름"
            id="name"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={(e) => setName(String(e.target.value).replace(spacere, ''))}
            tw={{
              borderColor: name ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>

        <div className="w-full">
          <TextField label="이메일" value={meRecoil?.email || ''} readOnly />
        </div>
        <div className="w-full">
          <TextField label="학교" value={meRecoil?.school?.name} readOnly />
        </div>
        <div className="w-full">
          <TextField label="학년 / 반" value={meRecoil?.klassGroupName || ''} readOnly />
        </div>

        <div className="w-full">
          <TextField label="출석번호" value={meRecoil?.studentNumber} readOnly />
        </div>
        <div className="w-full">
          <TextField
            label="*비밀번호 - 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상"
            type="password"
            placeholder="비밀번호"
            value={password1}
            onChange={(e) => setPassword1(String(e.target.value).replace(spacere, ''))}
            style={{ marginBottom: '0.5rem' }}
            tw={{
              borderColor:
                !password1 || password1 !== password2 ? 'border-red-700' : 'border-gray-300',
            }}
          />
          <TextField
            type="password"
            placeholder="비밀번호 확인"
            value={password2}
            onChange={(e) => setPassword2(String(e.target.value).replace(spacere, ''))}
            tw={{
              borderColor:
                !password2 || password1 !== password2 ? 'border-red-700' : 'border-gray-300',
            }}
          />
        </div>
        <div className="w-full">
          <PhoneNumberField
            label="*전화번호"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              borderColor: !phone ? 'rgba(185, 28, 28)' : '',
            }}
          />
        </div>

        <div className="w-full">
          <TextField
            type="date"
            label="*생년월일"
            onChange={(e) => setBirthDate(e.target.value)}
            value={birthDate}
            tw={{
              borderColor: birthDate ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>
        <div className="w-full">
          <TextField
            label="희망진로"
            value={hopePath}
            onChange={(e) => setHopePath(e.target.value)}
          />
        </div>
        <div className="w-full">
          <TextField
            label="희망학과"
            value={hopeMajor}
            onChange={(e) => setHopeMajor(e.target.value)}
          />
        </div>

        <div className="w-full">
          <TextField
            label="*보호자 이름"
            value={nokName}
            onChange={(e) => setNokName(e.target.value)}
            tw={{
              borderColor: nokName ? 'border-gray-300' : 'border-red-700',
            }}
          />
        </div>

        <div className="w-full">
          <PhoneNumberField
            label="*보호자 연락처"
            value={nokPhone}
            onChange={(e) => setNokPhone(e.target.value)}
            style={{
              borderColor: !nokPhone ? 'rgba(185, 28, 28)' : '',
            }}
          />
        </div>
        <div className="space-y-2">
          <div className="font-bold">
            <Checkbox
              label="전체 동의"
              onChange={() => {
                const allChecked = privacy && policy;
                setPrivacy(!allChecked);
                setPolicy(!allChecked);
              }}
              checked={privacy && policy}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="policy" onChange={() => setPolicy(!policy)} checked={policy} />
            <Link to={`/terms-of-use`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                슈퍼스쿨이용약관 (필수)
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id="privacy" onChange={() => setPrivacy(!privacy)} checked={privacy} />
            <Link to={`/privacy-policy/${meRecoil?.schoolId}`} target="_blank">
              <span className="font-semibold text-base cursor-pointer">
                개인정보처리방침 (필수)
              </span>
            </Link>
          </div>
        </div>
        <div className="text-pink-600">{errorMessage}</div>
        {isChannelTalk && (
          <a target="_blank" rel="noreferrer" href="http://superstudy.channel.io/">
            <Button
              tw={{
                backgroundColor: 'bg-white',
                color: 'text-black',
                width: 'w-full',
              }}
            >
              문의하기
            </Button>
          </a>
        )}
        <Button
          disabled={buttonDisabled}
          tw={{
            backgroundColor: buttonDisabled ? 'bg-gray-300' : 'bg-brand-1',
          }}
          onClick={() => {
            if (password1 !== password2) {
              setErrorMessage('비밀번호와 비밀번호 확인이 다릅니다!');
              return;
            }
            if (!Validator.passwordRule1(password1) && !Validator.passwordRule2(password1)) {
              setErrorMessage(
                '비밀번호는 영문, 숫자, 특수문자 포함 시 8자 이상, 2종 포함 시 10자 이상이어야 합니다.',
              );
              return;
            }
            handleStudentFirstLogin({
              name,
              password: password1,
              phone,
              nokName,
              nokPhone,
              hopeMajor,
              hopePath,
              birthDate,
            });
          }}
        >
          정보 입력하고 시작하기
        </Button>
      </Section>
    </div>
  );
};
